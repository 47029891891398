import { FC, useContext, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { LocalizationContext, PermissionContext } from 'contexts';
import { useWorkspace } from 'shared/hooks/useWorkspace';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { UserRow } from 'views/LoggedIn/Team/components/UserRow/UserRow';


export const TeamList: FC = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { getPermission } = useContext(PermissionContext);

  const { status, workspace: users } = useWorkspace();
  const hasEditPermission = useMemo(() => getPermission('user.crud.edit'), [getPermission]);
  const hasDeletePermission = useMemo(() => getPermission('user.crud.delete'), [getPermission]);

  if (status === 'loading' || !users) {
    return <BodyLoading height="100vh"/>;
  }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="team table">
        <TableHead color="primary">
          <TableRow>
            <TableCell sx={{ width: '399px' }}>{dictionary.team.nameLabel}</TableCell>
            <TableCell sx={{ width: '399px' }}>{dictionary.team.positionLabel}</TableCell>
            <TableCell>{dictionary.team.roleLabel}</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <UserRow
              key={user.id}
              {...user}
              hasEditPermission={!!hasEditPermission}
              hasDeletePermission={!!hasDeletePermission}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
