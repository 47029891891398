import React from 'react';
import { Box, Typography } from '@mui/material';
import { LocalizationContext } from 'contexts';
import { ReactComponent as NoResults } from 'shared/img/noResults.svg';


export const VendorsEmptyState = () => {
  const { dictionary: { marketplace: { emptyState } } } = React.useContext(LocalizationContext);

  return (
    <React.Fragment>
      <Box
        className="_d-flex-jc-center-ali-center-dir-col"
        mt={2}
        mb={7}
      >
        <NoResults/>
        <Typography variant="h3" mt={2} mb={1}>{emptyState.coming}</Typography>
        <Typography variant="body1" maxWidth="280px" textAlign="center">
          {emptyState.suggestions}
        </Typography>
      </Box>
      <Typography variant="h2" mb="40px">{emptyState.explore}</Typography>
    </React.Fragment>
  );
};