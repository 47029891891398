import React, { FC, useContext, useState } from 'react';
import {
  DateRange,
  StaticDateRangePicker as MuiStaticDateRangePicker,
} from '@mui/x-date-pickers-pro';
import { Box, Stack, Button } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { DateOption } from 'shared/components/interactive/DateRangeSelect/DateRangeSelect';


interface IStaticDateRangePicker {
  onSetCustomDateRange: (dateValue: DateOption) => void;
  minDate?: Date;
  maxDate?: Date;
}
export type StaticDateValue = DateRange<Date>;

export const StaticDateRangePicker: FC<IStaticDateRangePicker> = ({
  onSetCustomDateRange, minDate, maxDate,
}) => {
  const [dateValue, setDateValue] = useState<StaticDateValue>([null, null]);
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Box
      px={2}
      sx={{
        '& .Mui-selected:is(#_, *)': { bgcolor: 'rgb(0, 0, 0)' },
        '& .MuiDateRangePickerDay-rangeIntervalDayHighlight:is(#_, *)': {
          bgcolor: 'rgb(0, 0, 0, 0.05)',
        },
      }}
    >
      <MuiStaticDateRangePicker
        displayStaticWrapperAs="desktop"
        value={dateValue}
        onChange={(newDateValue) => setDateValue(newDateValue)}
        renderInput={() => <></>}
        defaultCalendarMonth={maxDate}
        minDate={minDate}
        maxDate={maxDate}
      />
      <Stack
        py={2}
        width="100%"
        gap={2}
        justifyContent="flex-end"
        direction="row"
      >
        <Button
          onClick={() =>
            onSetCustomDateRange({
              gteDate: dateValue[0]!,
              lteDate: dateValue[1]!,
            })
          }
        >
          {dictionary.datePickers.setCustomDate}
        </Button>
      </Stack>
    </Box>
  );
};
