// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Form = (theme: Theme) => {
  return {
    MuiFormControl: {
      styleOverrides: {
      },
    },
  };
};

export default Form;
