import { cloneDeep } from 'lodash';
import * as Yup from 'yup';
import { Surveys } from 'modules/Surveys/survey';
import { YupSchemaShape } from 'views/LoggedIn/Employees/Employees.types';


export interface ReplaceData<T> {
  keyStrToBeReplaced: keyof T;
  [key: string]: any;
}

export const replaceValueOnString = (keyStrToBeReplaced: any, replaceData: Record<string, object>) => {
  Object.entries(replaceData).forEach((entries) => {
    const [key, value] = entries;
    const replaceValue = `{${key}}`;
    keyStrToBeReplaced = keyStrToBeReplaced.replace(
      replaceValue,
      value,
    );
  });
  return keyStrToBeReplaced;
};

export const replaceValueOnStrings = (
  datasToBeReplaced: Surveys.Question[],
  valuesToReplaceList: ReplaceData<Surveys.Question>[],
) => {
  const clonedDatasToBeReplaced = cloneDeep(datasToBeReplaced);
  clonedDatasToBeReplaced.forEach((dataToBeReplaced) => {
    valuesToReplaceList.forEach((replaceData) => {
      const { keyStrToBeReplaced, ...restReplaceData } = replaceData;
      // @ts-ignore
      dataToBeReplaced[keyStrToBeReplaced] = replaceValueOnString(
        dataToBeReplaced[keyStrToBeReplaced],
        restReplaceData,
      );
    });
  });
  return clonedDatasToBeReplaced;
};


export const createYupSchema = (conditions: Record<string, Surveys.ValidationSchemaFrontend>): Yup.AnySchema => {
  let shape: Record<string, any> = {};
  for (const condition in conditions) {
    if (conditions[condition]) {
      //@ts-ignore
      shape[condition as keyof YupSchemaShape] = Yup[conditions[condition].type]();
      for (const method in conditions[condition].subsequentMethods) {
        shape[condition] = shape[condition][method](
          ...conditions[condition].subsequentMethods[method],
        );
      }
    }
  }
  const inputSchema = Yup.object().shape(shape);
  return inputSchema;
};