import { isSameYear } from 'date-fns';
import { isEmpty } from 'lodash';
import { Chart, Value } from 'modules/Charts/components/MultiAreaChart/MultiAreaChart.types';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';
import { LegendItemColorsParams, LegendItemParams, MappedPieChartData, TextWeightCategoryTableParams } from './types';


export const getCategoriesName = (name: string): string => {
  if (isEmpty(name)) {
    return 'Unknown';
  }
  return name;
};

export const getCategoriesString = (mappedData: MappedPieChartData): string => {
  if (mappedData.length === 1) {
    return ' ' + getCategoriesName(mappedData[0].name);
  }
  if (mappedData.length === 2) {
    return ` ${getCategoriesName(mappedData[0].name)} and ${getCategoriesName(mappedData[1].name)}`;
  }
  if (mappedData.length > 2) {
    return ` ${getCategoriesName(mappedData[0].name)}, ${getCategoriesName(mappedData[1].name)}, and ${getCategoriesName(mappedData[2].name)}`;
  }
  return '';
};

export const getComparisonKeys = ({ dateLte, dateGte }: any) => {
  let selectedPeriodIntervalKey = 'Selected period';
  let prevPeriodIntervalKey = 'Previous period';
  const gteDate = new Date(dateGte);
  const lteDate = new Date(dateLte);

  if (isSameYear(gteDate, lteDate)) {
    selectedPeriodIntervalKey = gteDate.getFullYear().toString();
    prevPeriodIntervalKey = (gteDate.getFullYear() - 1).toString();
  }
  return {
    selectedPeriodIntervalKey,
    prevPeriodIntervalKey,
  };
};

export const getLegendItemColors = ({ isComparingMonths, barChartDataPrev, mappedFilters, scopeColors }: LegendItemColorsParams) => {
  const { selectedPeriodIntervalKey, prevPeriodIntervalKey } = getComparisonKeys({ dateGte: mappedFilters.date_gte, dateLte: mappedFilters.date_lte });
  const dataComparissonColors = (isComparingMonths && barChartDataPrev) ? {
    [prevPeriodIntervalKey]: 'linear-gradient(135deg, rgba(36, 150, 131, 0.5) 21.88%, rgba(255, 174, 128, 0.5) 84.38%)',
    [selectedPeriodIntervalKey]: 'linear-gradient(135deg, rgba(36, 150, 131, 1) 21.88%, rgba(255, 174, 128, 1) 84.38%)',
  } : {};
  return { ...scopeColors, ...dataComparissonColors };
};

export const getLegendItems = ({ isComparingMonths, barChartDataPrev, mappedFilters, reloadChartsRef }: LegendItemParams) => {
  const legendLabels: string[] = reloadChartsRef?.current ? [] : [Scope.One, Scope.Two, Scope.Three];
  const { selectedPeriodIntervalKey, prevPeriodIntervalKey } = getComparisonKeys({ dateGte: mappedFilters.date_gte, dateLte: mappedFilters.date_lte });
  if (isComparingMonths && barChartDataPrev) {
    legendLabels.push(prevPeriodIntervalKey, selectedPeriodIntervalKey);
  }
  return legendLabels;
};

export const getBarChartDataByScope = (data: Value[]) => {
  if (!data) return null;
  const scope9BarChartData = [];
  const otherScopesBarChartData = [];

  for (let i = 0; i < data.length; i++) {
    const group = data[i].dimensions.group;
    if (group.toString() === Scope.Offset) {
      scope9BarChartData.push(data[i]);
    } else {
      otherScopesBarChartData.push(data[i]);
    }
  }
  return {
    otherScopesBarChartData,
    scope9BarChartData,
  };
};

export const isNegativeChartData = (mappedBarChartDataNegative: Chart, mappedBarPrevYearChartDataNegative: Chart, isComparingMonths: boolean) => (mappedBarChartDataNegative?.values?.length > 0 || (mappedBarPrevYearChartDataNegative?.values?.length > 0 && isComparingMonths));
export const getTextWeightCategoryTable = ({ emissionFactorCategory, slug }: TextWeightCategoryTableParams) => {
  return ((emissionFactorCategory?.slug === slug) || emissionFactorCategory?.parent?.slug === slug) ? 600 : 400;
};
export const getPercentageChangeColorCategoryTable = (value: number) => {
  if (value === 0) {
    return 'text.tertiary';
  }
  return value > 0 ? 'error.main' : 'success.main';
};