import { Fragment, ReactNode } from 'react';
import { IntegrationStatus } from 'modules/Expenses/helpers/integrationOptions';
import { Country } from 'types/Country';
import { AsyncProcessType } from 'contexts/WaitingAsyncProcessContext/types';
import { EmissionFactors } from 'clients/emissionFactors/emissionFactorsClient.types';


export const dictionary = {
  loading: 'Loading',
  results: 'Results',
  myProfile: 'My profile',
  myAccount: 'My account',
  subscriptions: 'Subscriptions',
  login: 'Log in',
  logOut: 'Log out',
  signUp: 'Sign up',
  signUpDescription: 'Welcome! Enter the details needed below to sign up.',
  fullName: 'Full name',
  name: 'Name',
  surname: 'Surname',
  email: 'Email',
  phone: 'Phone',
  password: 'Password',
  thePasswordMustBeAtLeast8CharactersLong: 'The password must be at least 8 characters long',
  repeatPassword: 'Repeat password',
  userWithThisEmailAlreadyExists: 'User with this email already exists',
  userNotFound: 'User not found',
  passwordsDoNotMatch: 'Passwords do not match',
  signUpWithGoogle: 'Sign up with Google',
  signUpWithMicrosoft: 'Sign up with Microsoft',
  signInWithGoogle: 'Sign in with Google',
  signInWithMicrosoft: 'Sign in with Microsoft',
  support: 'support',

  successGeneric: 'Job done successfully!',
  successChangeSaved: 'Changes saved successfully!',
  somethingWentWrong: 'Something went wrong!',
  oneOrMoreFieldsAreIncorrect: 'One or more fields are incorrect.',
  edit: 'Edit',
  open: 'Open',
  delete: 'Delete',
  close: 'Close',
  upload: 'Upload',
  date: 'Date',
  dueDate: 'Due Date',
  dates: 'Dates',
  time: 'Time',
  duration: 'Duration',
  comingSoon: 'Coming soon',
  toggleLighDarkMode: 'Toggle light/dark mode',
  review: 'Review',
  update: 'Update',
  connect: 'Connect',
  activate: 'Activate',
  minimize: 'Minimize',
  goBack: 'Go back',

  hour: 'Hour',
  hours: 'Hours',
  minutes: 'Minutes',

  resetPassword: 'Reset password',
  reset: 'Reset',
  notFound: 'Not found',
  noPermission: 'You do not have permissions to view this page',

  progress: 'Progress',
  complete: 'Complete',
  downloads: 'Downloads',

  customDateRange: 'Custom date range',

  join: 'Join',

  darkMode: 'Dark mode',
  lightMode: 'Light mode',

  switchToDarkMode: 'Switch to dark mode',
  switchToLightMode: 'Switch to light mode',

  month: 'Month',
  week: 'Week',
  day: 'Day',
  agenda: 'Agenda',
  today: 'Today',

  toDo: 'To do',
  inProgress: 'In progress',
  completed: 'Completed',
  deleted: 'Deleted',

  threeHoursAgo: '3 hours ago',
  twoDaysAgo: '2 days ago',
  oneWeekAgo: '1 week ago',

  download: 'Download',

  search: 'Search',
  notifications: 'Notifications',
  seeNotifications: 'See notifications',

  hello: 'Hello',
  assignee: 'Assignee',
  status: 'Status',

  cancel: 'Cancel',
  all: 'All',
  submit: 'Submit',
  remove: 'Remove',

  description: 'Description',
  attachments: 'Attachments',

  position: 'Position',
  dragAndDrop: 'Drag and drop',

  singleToPlural: (single: string) => (
    {
      journal: 'journals',
      ledger: 'ledgers',
    }[single] || single + 's'
  ),

  pageTitles: {
    activityInputs: 'Activity inputs',
    emissionFactors: 'Emission factors',
    emissionAnalysis: 'Emission analysis',
    transactions: 'Transactions',
    carbonFootprint: 'Carbon footprint',
    measurements: 'Measurements',
    knowledgeBase: 'Knowledge base',
  },

  feedback: {
    success: 'Success',
    error: 'Error',
    info: 'Info',
    warning: 'Warning',

    noTransactions: {
      title: 'We couldn\'t find any data for the selected filters',
      text: 'Add data through our Expenses and Activities pages to start measuring your carbon footprint. ',
      alreadyAdded: 'Already added your data?',
      hint: 'If you can\'t see your data, check if you have the right year and filters enabled.',
    },
  },

  confirmation: {
    defaultTitle: 'Are you sure you want to do this?',
    defaultContent: 'This action is irreversible.',
    no: 'No',
    yes: 'Yes',
  },

  speedDial: {
    task: 'Task',
    project: 'Project',
    company: 'Company',
    user: 'User',
  },

  datePickers: {
    setCustomDate: 'Set custom date',
  },

  auth: {
    divider: 'or',
    dontHaveAccount: 'Don\'t have an account?',
    alreadyHaveAccount: 'Already have an account?',
    buttonSignIn: 'Sign in',
    buttonSignUp: 'Sign up',

    validations: {
      emailOrPasswordWrong: 'Email or password wrong',
    },

    impersonate: {
      noToken: 'Token is not present',
      invalidToken: 'Token is invalid or expired',
    },
  },

  profile: {
    title: 'My profile',
    upgradePlan: 'Upgrade plan',
    downgradePlan: 'Downgrade plan',
    companyOverviewTitle: 'Annual overview',
  },

  alert: {
    openTranslations: {
      buttonCheckOpentranslations: (count: number) => `Check ${count} transactions`,
      tooltipSkipAllTransactions: 'If you skip transactions the value of the emissions is going to be the value of the spend based emission factor.',
      buttonSkipTransactions: 'Skip transactions',
      bodyText: (count: number) => `There are ${count} transactions that require additional information to increase your footprint’s accuracy`,
    },
  },

  misc: {
    tonsValue: (value: number | string) => `${value} t`,
  },

  recommendations: {
    exploreButton: 'Explore recommendations',
    drawerTitle: 'Recommendations',
    recommendationHeadings: {
      travel: 'Travel',
      transport: 'Transport',
      logistics: 'Logistics',
      food: 'Food',
      energy: 'Energy',
      digital: 'Digital',
      design: 'Design',
      waste: 'Waste',
      buildings: 'Buildings',
      products: 'Products',
    },
    [EmissionFactors.Travel]: {
      label: 'Travel',
      recommendation: 'Travel mode',
      content: {
        title1: 'Travel mode',
        description1: 'Prefer to travel by train rather than by plane or car when possible (on trips of >5 hours travel time for example). Trains are one of the least polluting modes of transportation available, emitting between 3x, to 40x less emissions than travelling by plane or car (ADEME, 2019).',
        title2: 'Online meetings & events',
        description2: 'Prefer remote meetings and events rather than flying when the distance is too great for train travel. A round trip from Paris to New York will emit 1.6 tCO2e (ADEME, 2022), which is 80% of the annual personal carbon budget to respect the Paris agreements.',
        title3: 'Prefer local tourism',
        description3: 'Encourage local tourism rather than tourism to the other side of the world (company trip, works council, etc.). A company trip to Bali will have a much higher carbon impact than going to the Baltic Sea coast by train. ',
      },
    },
    [EmissionFactors.TransportAndLogistics]: {
      label: 'Transport',
      recommendation: 'Fleet emissions',
      content: {
        title1: 'Fleet emissions',
        description1: 'Limit the use of company cars and if necessary, prefer smaller electric company vehicles. Encourage carpooling and the use of public transport to drive down emissions even further.',
        title2: 'Low-carbon mobility',
        description2: 'Encourage the use of bicycles, electric mini-cars, carpooling and public transportation for work commute. Offer performance-based compensation programs to incentivize employees to make the right choices.',
      },
    },
    [EmissionFactors.Logistics]: {
      label: 'Logistics',
      recommendation: '',
      content: {
        title1: 'Trucking alternatives',
        description1: 'Favour river and rail freight for the transportation of goods over conventional road transport. Often more economical than road transport, these modes of delivery also emit 3x and 9x less CO₂ (FranceAgriMer, 4F).',
        title2: 'Last-mile bycicle delivery',
        description2: 'Implement the use of bicycle logistics solutions for last-mile deliveries: 51% of all freight trips in cities could be replaced by cargo bikes. In general, bicycle deliveries in urban areas are 60% faster and emit 90% less than deliveries by van. (CycleLogistics, 2016)',
        title3: 'Low-carbon vehicles',
        description3: 'Invest in cleaner delivery vehicles. Electric vans or CNG vehicles are quieter, contribute less to air pollution and emit less CO₂ (TheShiftProject, 2020).',
      },
    },
    [EmissionFactors.FoodsAndBeverages]: {
      label: 'Food',
      recommendation: 'Local and organic',
      content: {
        title1: 'Reduce meat consumption',
        description1: 'Food production is responsible of 26% of annual global emissions (without land use), of which meat accounts for nearly 43%. Increasing the proportion of vegetables in meals can reduce carbon emissions and much more. Offer more vegetarian and vegan meals and reduce meat options. (OurWorldInData).',
        title2: 'Local and organic',
        description2: 'Prioritize local and agro-ecological foods for collective catering. This will reduce transportation emissions and the use of pesticides, while improving the health of your employees and conditions for farmers.',
      },
    },
    [EmissionFactors.Energy]: {
      label: 'Energy',
      recommendation: 'Low-carbon electricity',
      content: {
        title1: 'Low-carbon electricity ',
        description1: 'Choose an electricity supplier that relies on renewable and low-carbon energy. Coal-fired electricity emits 200x more carbon than wind power, and gas-fired plants emit up to 70x more compared to wind. (OurWordInData)',
        title2: 'Energy efficient equipment',
        description2: 'Invest in energy-efficient equipment and energy-saving devices. For example, switching lighting to LED can save 40% to 80% on office electricity consumption.',
        title3: 'Energy moderation',
        description3: 'Implement a rigorous energy conservation policy to encourage reduced consumption: turn down the heat, dont overuse the air conditioning, dont leave electronic devices on standby, and more.',
        title4: 'Invest in heat-pumps',
        description4: 'Heat-pumps transfer thermal energy from the outside to heat and/or cool buildings. They consume 3x less energy than other systems for the same output, and can be powered by renewable electricity.',
      },
    },
    [EmissionFactors.Digital]: {
      label: 'Digital',
      recommendation: 'Carbon-neutral services',
      content: {
        title1: 'Carbon-neutral services',
        description1: 'Migrate to carbon-neutral cloud hosting services. Prefer Google Cloud over Amazon Web Services or Microsoft Azure to drive down cloud emissions to zero.',
        title2: 'Electronic equipment',
        description2: 'Invest in maintenance and recycle obsolete equipment. Renew electronic equipment less often and purchase refurbished when possible.',
      },
    },
    [EmissionFactors.Design]: {
      label: 'Design',
      recommendation: '',
      content: {
        title1: 'Eco-design',
        description1: 'Design products that last longer and are easily repairable or recyclable by users. By doing so, you reduce the proportion of Scope 3 emissions over the long term.',
        title2: 'Eco-materials',
        description2: 'Opt for recycled and low-carbon materials. This reduces emissions from production and supports advancements in the sustainable materials industry.',
        title3: 'Sustainable packaging',
        description3: 'Prefer packaging with low carbon emissions and less impact on the environment. Choose biodegradable, compostable deposit systems or reduce the amount of volume, material and weight of packaging.',
      },
    },
    [EmissionFactors.Waste]: {
      label: 'Waste',
      recommendation: 'Valorize organic waste',
      content: {
        title1: 'Valorize organic waste',
        description1: 'Process your organic waste by setting up composting. You can either use collection services or manage your own compost. ',
        title2: 'Moderation',
        description2: 'The best way to reduce your emissions from waste is to consume less - try to implement a policy of sobriety.',
        title3: 'Set up a sorting system',
        description3: 'Set up a rigorous sorting system to better seperate waste and favor bulk products over individual packaging.',
      },
    },
    [EmissionFactors.Buildings]: {
      label: 'Buildings',
      recommendation: 'Energy efficient buildings',
      content: {
        title1: 'Energy efficient buildings',
        description1: 'Prefer buildings with a high energy score (A and higher). More than 50% of office energy consumption is dedicated to heating offices. (ADEME 2020)',
        title2: 'Strategic location',
        description2: 'Think strategically about the location of buildings to reduce the carbon footprint of employee transportation (e.g., accessible by public transit). This may not reduce your own emissions but will greatly contribute to lower your employees',
        title3: 'Heating & cooling use',
        description3: 'Reduce heating and air conditioning consumption. This reduces the number one energy consumption item for companies and cuts emissions from refridgerant fluids.',
        title4: 'Invest in insulation',
        description4: 'If the company owns buildings, investing in better insulation can result in significant energy savings, reducing the need for heating and cooling.',
        title5: 'Low-carbon heating',
        description5: 'Heating offices with fossil fuels (i.e. gas and oil) generates a significant amount of CO₂ emissions. When possible, choose an electric or renewable heating system (heat pump or geothermal) instead.',
        title6: 'Renewable energy production',
        description6: 'More and more buildings are becoming energy self-sufficient by investing in solar power generation. You may be able to do the same and massively reduce your emissions from Scope 2.',
        title7: 'Cool-roof installation',
        description7: 'Installing cool-roofs can reduce energy consumption of buildings, lowering both expenses and carbon emissions.',
      },
    },
    [EmissionFactors.Products]: {
      label: 'Products',
      recommendation: 'Moderate consumption',
      content: {
        title1: 'Moderate consumption',
        description1: 'An underestimated method of reducing emissions from the consumption of goods and services is to implement a moderation policy, meaning minimizing unnecessary purchases where possible.',
        title2: 'Refurbished equipment',
        description2: 'Prefer refurbished products and equipment over new, to drastically reduce emissions; especially for electronic equipment.',
        title3: 'Co-use products & services',
        description3: 'Where possible, share the use of products and services with other companies to increase utility and cut on unnecessary footprint.',
      },
    },
  },

  transaction: {
    statusPendingLabel: 'Pending',
    statusNewLabel: 'New',
    statusClassifiedLabel: 'Classified',
    statusNonClassifiedLabel: 'Non-classified',
    statusProcessedLabel: 'Processed',
    scopeOneLabel: 'Scope 1',
    scopeTwoLabel: 'Scope 2',
    scopeThreeLabel: 'Scope 3',
    scopeOffsetLabel: 'Offset',
    scopeAllLabel: 'All',
    highConfidenceLabel: 'Confident',
    lowConfidenceLabel: 'Needs attention',

    transactionList: {
      export: 'Export',
      advanced: 'Advanced',
      scope: 'Scope',
      uploadSuccess: 'Your transactions were successfully uploaded',
      selectAll: {
        totalIsSelected: (totalDatasetCount: number) => `All ${totalDatasetCount} transactions in this search are selected`,
        clearSelection: 'Clear selection',
        pageIsSelected: (pageCount: number) => `All ${pageCount} transactions on this page are selected`,
        selectAllDataset: (totalDatasetCount: number) => `Select all ${totalDatasetCount} transactions that match this search`,
      },
      saveAndContinue: 'Save and continue',
      uploadAndSave: 'Upload and save',
      addWithNewTags: 'Add with new tags',
      tagsLabel: 'Add company tags',
      locationPlaceholder: 'Write your location, example: Ijsbaanpad, Amsterdam',
      revertSkippedTransactions: 'Revert skipped transactions',
      revertCompletedTransactions: 'Revert completed transactions',
      toolTip: {
        skipTransactionsBtnLabel: 'Skip transactions',
        content: 'If you skip transactions the original spend-based estimate will remain in place.',
      },
      transactionTitle: (sectionTitle: string) => `Add ${sectionTitle.toLocaleLowerCase()} consumption`,
      completedTransactions: 'Completed transactions',
      skippedTransactions: 'Skipped transactions',
      openTransactions: 'Open transactions',
      numberOfActivityBasedTransactions: (type: string, count: number) => `Number of ${type} transactions: ${count}`,
      endDateTooltip: 'You can include an end date if you want to save consumption data that spans across multiple months.',
    },

    edit: {
      transactionsSaved: 'The selected transactions have been saved successfully.',
      skipAllTransactionsConfirmation: 'Are you sure you want to skip ALL open transactions?',
      transactionsSkipped: 'The selected transactions have been skipped successfully.',
      transactionsReverted: 'The selected transactions have been reverted successfully.',
    },

    export: {
      title: 'Export transactions',
      buttonExport: 'Export',
    },
    import: {
      dropSuccessful: ({ fileName, readableSize }: {fileName?: string, readableSize?: number}) => <>{fileName} <i>{readableSize}</i></>,
    },

    classify: {
      category: 'Category',
      subcategory: 'Subcategory',
      emissionFactors: 'Emission factors',
      insights: 'Insights',
      fieldSearchPlaceholder: 'What is the emission factor?',
      applyButton: 'Apply',
      noEmissionFactorsFound: 'No emission factors found',
      noEmissionFactorsFoundMessage: 'Try using a different keyword to get better results!',
      buttonLocationLabel: 'Location',
      buttonYearLabel: 'Year',
      countryAndYearDisabledTooltip: 'You need to select an emission factor to specify the corresponding location and year.',
      mode: (mode: 'manual' | 'certainty') => mode === 'manual' ? 'Manually classify' : 'Change certainty',
      rowSelected: (count: number) =>
        count !== 1
          ? `${count} transactions`
          : `${count} transaction`,
      buttonInsightMore: 'more',
      buttonInsightLess: 'less',
      noInsightsFound: 'No insights',
      noInsightsFoundMessage: 'We couldn’t find a description for vendors on the selected transactions.',
    },
  },

  company: {
    accountManagerLabel: 'Account manager',
    primaryContactLabel: 'Primary contact',
    selectCompanyLabel: 'View projects',
    companyInfo: 'Company info',

    create: {
      title: 'Create company',
      buttonCreate: 'Create company',
    },

    edit: {
      addUserTitle: (company: string) => `Add user to ${company}`,
      buttonEdit: 'Edit company',
      buttonDelete: 'Delete company',
      buttonAddUser: 'Add user',
      deleteConfirmation: 'Are you sure you want to delete this company?',
    },
  },

  dataGrid: {
    footer: {
      rowSelected: (count: number) =>
        count !== 1
          ? `${count} rows selected`
          : `${count} row selected`,
    },
    toolbar: {
      filters: {
        operators: {
          not: 'is not',
          is: 'is',
          after: 'after',
          onOrAfter: 'on or after',
          before: 'before',
          onOrBefore: 'on or before',
        },
      },
    },
  },

  companies: {
    title: 'Companies',
  },

  marketplace: {
    title: 'Marketplace',
    vendorList: {
      vendor: '1 vendor',
      vendors: 'vendors',
      discover: 'Discover',
      source: 'Source',
      details: 'Details about this vendor',
      maturity: 'Maturity',
      focusArea: 'Focus area',
      location: 'Location',
      lastReviewed: 'Latest review on',
      moreButton: 'more',
      showLessButton: 'Show less',
      moreDetailsButton: (amount: number, isMore: boolean) => `show ${isMore ? amount : ''} ${isMore ? 'more' : 'less'}`,
      vendorCommitment: (name: string) => `${name}’s commitment`,
      typeSus: 'Sustainable vendors',
      typeRed: 'Reduction tools',
    },
    filterOptions: {
      title: 'Filter by',
      emissions: 'Your emission breakdown',
      otherCategories: 'Other categories',
      type: 'Type',
      location: 'Location',
      showMore: 'Show more',
      showLess: 'Show less',
    },
    emptyState: {
      coming: 'Coming soon',
      suggestions: 'In the meantime, you can find a few suggestions below to help you get started ↓',
      explore: 'Explore more',
    },
  },

  snackbar: {
    statusText: (status: string, processType: string, progress?: number, isInactive?: boolean) => {
      if (status === 'uploading' && progress === 100) return 'Processing...';
      if (processType === AsyncProcessType.ImportingTransactions && isInactive) {
        return status === 'error' ? 'Connection failed' : 'Checking connection...';
      }

      const strObj: {[key: string]: string} = {
        uploading: 'Uploading...',
        importing: 'Importing...',
        success: 'Uploaded successfully',
        error: 'Upload failed',
      };

      if (processType === AsyncProcessType.ImportingTransactions) {
        Object.assign(strObj, {
          success: 'Imported successfully',
          error: 'Import or connection failed',
        });
      }

      return strObj[status] || status;
    },
  },

  expenses: {
    title: 'Expenses',
    manage: 'Manage',
    transactions: 'transactions',
    integration: 'integration',
    buttonAddExpenses: 'Add expenses',
    linkedAccounting: {
      title: 'Linked accounting',
      accountDisconnected: 'Account disconnected successfully',
      accountInactive: 'Account is disconnected',
      deleteBtn: 'Delete transactions',
      deleteDialogTitle: (transactionNum?: number) => `Deleting ${transactionNum ? transactionNum : ''} transactions from your emission analysis `,
      deleteDialogText: 'This cannot be undone. Are you sure you want to delete?',
      deleteDialogConfirm: 'Confirm deletion',
      disconnectDialogTitle: 'Are you sure you want to disconnect this Exact Online account?',
      disconnectDialogText: 'You will no longer have access to this Exact Online account in your Coolset app. Your previously synced transactions will not be affected, but you won’t be able to sync any new data. You can reconnect at any time point.',
      disconnectBtn: 'Disconnect account',
      manageBtn: 'Manage account',
      status: (status: string) => ({
        [IntegrationStatus.NEED_REV]: IntegrationStatus.NEED_REV,
        [IntegrationStatus.ANAL_RUN]: IntegrationStatus.ANAL_RUN,
        [IntegrationStatus.ANAL_DONE]: IntegrationStatus.ANAL_DONE,
      }[status] || status),
      needsReview: 'Your most recent accounting data has been imported. Please review and confirm all essential information is included.',
      reviewLink: 'Review data',
      manualCount: (count: number | string = 'NA') => `Manual upload · ${count} transactions synced`,
      noTransactionStatus: 'Connect your accounting data to start tracking spend-based emissions',
      integrationEmptyTitle: 'Start tracking spend-based emissions',
      integrationEmptyText: 'Connect your accounting data securely.',
    },
    uploadIntegration: {
      backButton: 'Go back',
      forwardButton: 'Continue',
      saveDraft: 'Save draft',
      uploading: 'Uploading your file, this can take a few minutes.',
      stepsDone: ['File uploaded', 'Data reviewed', 'Analysis started'],
      stepTitle: {
        intro: 'Ready to upload your accounting data?',
        uploadFile: 'Upload your accounting data',
        preview: 'Preview your import',
        selectColumns: 'Select columns',
        uploadDone: 'You’ve successfully uploaded your data!',
        introSync: 'Ready to link your accounting data?',
        selectAccounting: 'Connect your accounting',
        permissions: 'We need your permission',
        syncDone: 'You’ve successfully linked your “Exact” account',
        journal: 'Include in your emission analysis',
        ledger: 'Include in your emission analysis',
        error: 'Review errors',
      } as {[key: string]: string},
      stepSubTitles: {
        uploadFile: 'Export your accounting data from your accounting platform.',
        preview: 'Here\'s the data you\'re uploading — check to make sure it looks right.',
        selectColumns: 'Drag columns to the right position.',
        uploadDone: 'Your linked accounting data is almost ready to be analyzed. Confirm you have included all necessary data to run the analysis.',
        selectAccounting: 'Securely add your accounting data so that you can start tracking your spend based emissions. You can select one or multiple accounting platforms.',
        permissions: 'Keeping your data secure is our priority. To track your spend based emissions we need your permission to access the following account information.',
        syncDone: 'Your linked accounting data is almost ready to be analyzed. Review what’s included to make sure only the necessary entries are taken into account for the analysis.',
        journal: 'Move entries to the ‘ignore’ list if they contain the following: revenue/income, employee salaries, taxes, internal transactions, depreciations or amortizations.',
        ledger: 'Move entries to the ‘ignore’ list if they contain the following: revenue/income, employee salaries, taxes, internal transactions, depreciations or amortizations.',
        error: 'Make sure to update the file or double check you matched the columns correctly.',
      } as {[key: string]: string},
      intro: {
        weWillNeed: <>We will need your accounting data to track your company’s spend based emissions.<br/> In the next steps, we will ask you to:</>,
        weNeedList: [
          'Upload an export of your accounting data. You will find a template in the next step.',
          'Remove any unnecessary accounts that shouldn’t be included in the analysis. You will receive instructions in the last step.',
        ],
        backButton: 'Will do it later',
        forwardButton: 'Let’s start',
      },
      introSync: {
        weNeedList: [
          'Link your accounting platform',
          'Make sure to remove any unnecessary entries that shouldn’t be included in the analysis',
        ],
      },
      uploadFile: {
        chooseOrDrag: 'Choose file or drag & drop',
        error: (mbSize: number = 25) => `Please make sure the file has one of the supported formats and is maximum ${mbSize}MB.`,
        supported: (formats: string[] = ['CSV']) => 'Supported formats: ' + formats.join(', '),
        maxSize: (maxSize: number = 25) => `Maximum file size: ${maxSize}MB`,
        template: {
          title: 'Export template',
          desc: 'You can download the attached example and use it as a starting point for your own file.',
        },
      },
      previewData: {
        hasHeaders: 'Data has headers',
      },
      selectColumns: {
        backButton: 'Upload a different file',
        forwardButton: 'Upload',
        loadingLabel: 'Uploading',
        errorNumRows: (num: number) => num === 1 ? 'There is 1 invalid row.' : `There are ${num} rows that are invalid.`,
        rows: (num: number = 1) => num === 1 ? 'Row' : 'Rows',
      },
      asyncLoading: {
        title: 'Your file is being uploaded.',
        importingTitle: 'Your transactions are being imported.',
        checkConnection: 'Checking your connection.',
        text: <>This may take a few minutes.<br/>You can minimize this window and come back when it’s done.</>,
      },
      uploadDone: {
        review: 'Start review',
        title: 'You’ve successfully uploaded your file!',
        subTitle: 'Your linked accounting data is almost ready to be analyzed. Review what’s included to make sure only the necessary data are taken into account for the analysis.',
      },
      selectAcc: {
        available: 'Available integrations',
        notUsing: 'Not using the above?',
        upload: 'Upload your accounting data',
      },
      permissions: {
        details: 'Account details',
        detailsText: 'This includes your account name, account details and total company revenue',
        transactions: 'Transactions',
        transactionsText: 'This includes your outgoing transactions',
        howStoreTitle: 'How do we store and handle your data?',
        howStoreText: [
          <>Your data is stored and handled securely on <a href="https://cloud.google.com/" target="_blank" rel="noreferrer">Google Cloud</a> in compliance with the latest regulatory and security standards.</>,
          <>Coolset prohibits confidential or sensitive customer data, by policy,<br/> from being used or stored in non-production systems/environments.</>,
          'Coolset uses secure data transmission protocols to encrypt confidential and sensitive data when transmitted over public networks.',
        ],
        loading: 'Redirecting to 3rd party application',
        existingError: 'There is already an integration to the requested accounting software. If you\'d like to change the account, please disconnect the existing integration.',
      },
      syncDone: {
        loading: 'Checking your connection',
        title: (name: string) => `You’ve successfully linked your “${name}” account`,
        subTitle: 'Your linked accounting data is almost ready to be analyzed. Review what’s included to make sure only the necessary entries are taken into account for the analysis.',
      },
      ledgerAnalysis: {
        run: 'Run analysis',
        autoMove: 'were automatically moved to the “Ignore” list based on their title. Please review all suggestions and update them if necessary.',
        include: 'Include in analysis',
        ignore: 'Ignore in analysis',
        confirmTitle: 'Your data is ready for the emission analysis',
        confirmText: 'Ensure all essential accounting data is included to accurately track your spend-based emissions.',
        confirmTitleUpdate: 'Your spend-based emissions will be updated',
        confirmTextUpdate: 'A new emission analysis will run on the updated entries. Make sure you\'ve included all the necessary accounting data, so that we can track your spend based emissions accurately.',
        confirmRun: 'Yes, run analysis',
        unnamed: 'Unnamed journal',
        loadingUpload: (type: string = 'analysis') => `Confirming your ${type} selections`,
      },
      reviewDone: {
        title: 'Your data is ready for the emission analysis',
        subTitle: 'Our climate advisors will now analyze the data using Coolset\'s AI-powered software. They will reach out in the next weeks to present the results.',
        finish: 'Finish',
      },
    },
  },

  csrd: {
    esrs: {
      title: 'ESRS',
    },
  },

  carbonRemoval: {
    title: 'Carbon removal',
    purchases: {
      title: 'Purchases',
      certificate: 'Certificate',
      certificateTooltip: 'We are reserving your offset. A certification of purchase will be available shortly.',
    },
    emissionBox: {
      titleCarbon: 'Total emissions',
      titleOffset: 'Total emissions offset',
      netEmissions: 'Net emissions',
    },
    bundleSection: {
      selectProject: 'Select project',
      bundleIframeTitle: 'Offseting',
      noMatchingProjects: 'No matching projects found',
      title: 'Remove your carbon emissions',
      typeDropdownOptions: {
        type: 'Type',
        carbonRemoval: 'Carbon removal',
        emissionReduction: 'Emission reduction',
      },
      sortPrice: 'Price',
      regreenerBundle: {
        project1: {
          description: 'The Crow Lake Wind Project generates sufficient renewable wind power to supply energy to up to 129,000 households, preventing over 430,000 tonnes of CO2 emissions annually. Beyond just substituting traditional grid electricity produced from fossil fuels, the project serves as a practical training site for future renewable energy technicians. Students at Mitchell Technical Institute (MTI) in Mitchell, South Dakota, gain hands-on experience through this initiative.',
          name: 'Crow Lake Wind Project',
          countryName: 'United States of America',
        },
        project2: {
          description: 'The objective of the Katingan Peatland Restoration and Conservation Project is to safeguard and rejuvenate a 149,800-hectare expanse of peatland ecosystems. This initiative aims to create sustainable income opportunities for local communities and address the global challenge of climate change through a robust business model. The targeted area serves as a significant reservoir of carbon dioxide, playing a crucial role in regulating water patterns, averting destructive peat fires, enhancing soil fertility, and ensuring a supply of clean water.',
          name: 'Restoration and conservation peatland in Borneo',
          countryName: 'Indonesia',
        },
      },
    },
  },

  charts: {
    dateAggregation: {
      day: 'day',
      week: 'week',
      month: 'month',
      year: 'year',
    },
    percentageComparison: (percentage: number, timePeriod: string) => `${percentage > 0 ? '+' : ''}${percentage}% compared to the previous ${timePeriod}`,
  },

  scenarioBuilder: {
    title: 'Scenario builder',
    co2e: 'CO₂e',
    resetButtonTooltip: 'Resetting your target reduction plan will overwrite any edits you might already have made to reach your projected reduction target.',
    targetSetting: 'Target setting',
    reductionStrategy: 'Reduction strategy',
    startYear: 'Start year',
    baselineYear: 'Baseline year',
    targetYear: 'Target year',
    baseline: 'Baseline',
    reductionTarget: '% Reduction target',
    annualTarget: '% Annual target',
    willTargetBeHit: 'Will target be hit?',
    autofillTable: 'Autofill table',
    noData: 'No data',
    category: 'Category',
    projectedReduction: 'Projected reduction',
    autofillBtnConfirm: 'Autofill',
    autofillBtnCancel: 'Cancel',
    autofillTitle: 'Autofill table',
    autofillConfimation: 'Autofilling your target reduction plan will overwrite any edits you might already have made to reach your projected reduction target. Are you sure you want to continue?',
    autofillTooltip: 'Autofilling your target reduction plan will overwrite any edits you might already have made to reach your projected reduction target.',
    startYearTooltip: 'Select the year in which you plan to begin decarbonizing. The scenario builder will calculate how much you should reduce your emissions starting from this year.',
    baselineYearTooltip: 'The scenario builder will use data from this year as a baseline, to calculate how to reduce your emissions to reach your target. We recommend choosing the most recent year with complete data.',
    noTransactionsWarning: 'There are no transactions for the given baseline year. Please choose another year.',
  },

  user: {
    title: 'User',
    typeCustomerLabel: 'Customer',
    typeAdminLabel: 'Admin',

    create: {
      title: 'Create user',
      buttonCreate: 'Create user',
    },

    edit: {
      buttonEdit: 'Edit user',
      buttonDelete: 'Delete user',
      confirmChangeLabel: 'Confirm and change',
      editConfirmation: 'Are you sure you want to downgrade your role?',
      editWarningContent: 'This action can only be reverted by another admin.',
      editOwnerConfirmation: 'Are you sure you want to transfer ownership?',
      editOwnerWarningContent: 'This action can only be reverted by the new owner. Once you transfer ownership, you will remain an admin for this workspace. You will still be able to do everything within Coolset, expect deleting the workspace.',
      changeTo: 'Change to',
    },

    delete: {
      confirmRemoveLabel: 'Confirm and remove',
      remove: 'Remove',
      removeConfirmation: 'Are you sure you want to remove this team member?',
      removeContentA: 'This action cannot be undone.',
      removeContentB: 'will lose access to this workspace.',

      confirmLeaveLabel: 'Confirm and leave workspace',
      leaveConfirmation: 'Are you sure you want to leave this workspace?',
      leaveContent: 'This action cannot be undone. You will lose access to this workspace.',
      leave: 'Leave workspace',

      deleteConfirmation: 'Are you sure you want to delete this user?',
    },

    cancelLabel: 'Go back',
    companyLabel: 'Company',
    jobTitleLabel: 'Job title',
    adminLabel: 'Admin',
  },

  activityInputs: {
    buttonAddActivities: 'Add activities',
    endDateHelp: 'You can include an end date if you want to save consumption data that spans across multiple months.',
    uploadCsv: {
      stepElectricityTitle: {
        uploadFile: 'Upload your electricity consumption',
        preview: 'Preview your import',
        selectColumns: 'Select columns',
      } as {[key: string]: string},
      stepGasTitle: {
        uploadFile: 'Upload your gas consumption',
        preview: 'Preview your import',
        selectColumns: 'Select columns',
      } as {[key: string]: string},
      stepElectricitySubTitle: {
        uploadFile: 'Import your electricity consumption data from your provider.',
        preview: 'Here\'s the data you\'re uploading — check to make sure it looks right.',
        selectColumns: 'Drag columns to the right position.',
      } as {[key: string]: string},
      stepGasSubTitle: {
        uploadFile: 'Import your gas consumption data from your provider.',
        preview: 'Here\'s the data you\'re uploading — check to make sure it looks right.',
        selectColumns: 'Drag columns to the right position.',
      } as {[key: string]: string},
    },
  },

  users: {
    title: 'Users',
  },

  team: {
    team: 'Team',
    title: 'Team members',
    nameLabel: 'Name',
    positionLabel: 'Position',
    roleLabel: 'Role',
    openQueriesLabel: 'Open queries',
    notificationsLabel: 'Email notifications',
    unknownLabel: 'Unknown position',
    unknownRole: 'Unknown role',
    buttonInvite: 'Invite team members',
    inviteDialogPlaceholder: 'Select group to which users will be added',
    roleInfoLabel: 'Roles info',
  },

  invite: {
    dialog: {
      title: 'Invite team members',
      placeholderEmail: 'Email, comma separated',
      buttonInvite: 'Send invite',
      feedback: {
        allEmailsFailed: (numEmailsSent: number) => `Error! Invitation${numEmailsSent > 1 ? 's' : ''} not sent.`,
        allEmailSent: (numEmailsSent: number) => numEmailsSent === 1 ? 'Invitation was sent successfully.' : 'All invitations were sent successfully.',
        someEmailsSent: (numEmailsSent: number) => `${numEmailsSent} invitation${numEmailsSent > 1 ? 's were' : ' was'} sent successfully.`,
        succeededAndFailedError: (numEmailsSent: number) => `Error! Invitation${numEmailsSent > 1 ? 's' : ''} not sent to:`,
      },
    },

    title: 'Team invitation',
    subtitle: (company?: string) => company ? `You've been invited to join ${company}'s team` : 'You\'ve been invited to join a team',
    buttonAcceptInvitation: 'Create account',
  },

  filterSection: {
    report: 'Generate report',
    checkTransactions: 'Check transactions',
    compareDataCheckboxLabel: 'Compare with previous year',
    searchFor: (itemLabel: string) => `Search for a ${itemLabel}`,
    noResults: 'No results',
    toggleButtonLabels: {
      scope: {
        1: 'Scope 1',
        2: 'Scope 2',
        3: 'Scope 3',
        all: 'All',
      },
      date: {
        month: 'Monthly',
        year: 'Yearly',
      },
    },
    intensity: {
      absolute: 'Absolute emissions',
      perEmployee: 'Emissions per employee',
      perRevenueUnit: 'Emissions per unit of revenue',
      settings: 'Emission intensity settings',
    },
    scope: {
      autocompleteLabel: 'Scope',
    },
    companyTagsLabels: {
      autocompleteLabel: 'Tags',
      autocompleteSearchLabel: 'tag',
      tagsTooltip: (tags: string[]) => `All tags assigned to transaction: ${tags.join(', ')}`,
      emptyOptionLabel: 'Items without tags',
    },
    EFCategories: {
      autocompleteLabel: 'Categories',
      autocompleteSearchLabel: 'category',
    },
  },

  processing: 'Processing...',

  menu: {
    act: {
      title: 'Act',
      scenarioBuilder: 'Scenario builder',
      carbonRemoval: 'Carbon offsets',
      marketplace: 'Marketplace',
    },
    data: {
      title: 'Data',
      activityBasedTransactions: 'Activity inputs',
      employees: 'Employees',
      expenses: 'Expenses',
      emissionFactors: 'Emission factors',
      esrs: 'ESRS',
    },
    dashboard: 'Home',
    academy: 'Academy',
    feedback: 'Feedback',
    companies: 'Companies',
    users: 'Users',
    drivers: 'Drivers',
    queries: 'Queries',
    measurements: 'Measurements',
    scenarioBuilder: 'Scenario builder',
    knowledgeBase: 'Knowledge base',
    offsets: 'Offsets',
    socialAndGovernance: 'Social & Governance',
    team: 'Team',
    forecasts: 'Forecasts',
    activityLog: 'Activity log',
    transactions: {
      title: 'Transactions',
      amount: 'Net cost',
      classification: {
        classified: 'Classified',
        nonClassified: 'Non-classified',
        all: 'All',
      },
      textAnalysis: {
        title: 'Text analysis',
        word: 'Word analysis',
      },
      ledgerAnalysis: {
        title: 'Ledger analysis',
        ledger: 'Ledger',
      },
      vendorAnalysis: {
        title: 'Vendor analysis',
        vendor: 'Vendor',
      },
    },
    dataManager: 'Data manager',
    activityBasedTransactions: 'Activity inputs',
    emissionFactors: 'Emission factors',
    settings: 'Settings',
    integrations: 'Integrations',
    expand: 'Expand',
    collapse: 'Collapse',
  },

  workspaceMenu: {
    workspaces: 'Workspaces',
    currentWorkspace: 'Current workspace',
  },

  workspace: {
    cantFind: 'Can\'t find your workspace?',
    change: 'Change',
    closePage: 'You can close this page or go back to',
    differentEmail: 'Use a different email',
    homepage: 'coolset.com',
    members: 'members',
    leftWorkspace: 'You left a workspace',
    leftWorkspaceSuccess: 'Your account was removed successfully from this workspace.',
    signedInAs: 'Signed in as',
    yourWorkspaces: 'Your workspaces',
    changedWorkspace: 'You have changed workspaces in a different tab.',
    cancelWorkspaceChange: 'Stay in this one',
    confirmWorkspaceChange: 'Confirm change',
    noWorkspaceTitle: 'You have no workspaces',
    noWorkspaceContent: 'If you think this might be a mistake, you can contact our',
    changeSuccess: 'Workspace change successful',
  },

  filters: {
    label: 'Filters',
    fieldStatus: 'Status',
    scope: 'Scope',
    fieldDateFrom: 'Date from',
    fieldDateTo: 'Date to',
    buttonSort: 'Sort',
    buttonFilter: 'Filter',
    dateAggregation: 'Date aggregation',
    resetFilters: 'Reset',

    transaction: {
      fieldScope: 'Scope',
    },
  },

  tooltipTitles: {
    resetFilterTooltip: 'Reset filters',
    exportCSVTitle: 'Export CSV',
  },

  dialogs: {
    buttonCancel: 'Cancel',
  },

  forms: {
    fieldEmail: 'Email',
    fieldPassword: 'Password',
    fieldPhone: 'Phone',
    fieldCountry: 'Country',
    fieldCompany: 'Company',
    fieldTitle: 'Title',
    fieldSubtitle: 'Subtitle',
    fieldDescription: 'Description',

    signin: {
      title: 'Sign in',
      desc: 'Welcome again! Enter the details below to sign in.',
      btnLabel: 'Sign in',
    },

    resetPassword: {
      title: 'Reset password',
      btnLabel: 'Reset',
      emailSent: 'We have e-mailed your password reset link!',
      desc: 'Please enter your email address. Instructions for resetting the password will be emailed to you',
      backToLoginDesc: 'Remembered your password?',
      backToLoginCTA: 'Back to log in',
      emailNotFound: 'Email not found',
    },

    signup: {
      fieldRepeatPassword: 'Repeat password',

      placeholders: {
        name: 'Enter your name',
        surname: 'Enter your surname',
        company: 'ACME International',
        position: 'Chief of staff',
        email: 'Enter your email',
        password: 'Enter your password',
        repeatPassword: 'Repeat the password',

      },
    },

    company: {
      fieldName: 'Name',
      fieldNrOfEmployees: 'Nr. of employees',
      fieldNrOfLocations: 'Nr. of locations',
      fieldLogo: 'Logo',
    },

    user: {
      fieldName: 'Name',
      fieldSurname: 'Surname',
      fieldType: 'Type',
      fieldPosition: 'Position',
      fieldAdmin: 'Admin',
      fieldPicture: 'Upload picture',

      fieldEmailDisabledHelper: 'Please contact support to change the email.',
    },

    transaction: {
      fieldStatus: 'Status',
    },

    validations: {
      required: 'This field is required',
      requiredLowercase: 'This field is required',
      invalidDate: 'Date is invalid',
      invalidEmail: 'Email is invalid',
      invalidPhone: 'Phone number is invalid',
      invalidPassword: 'Password is invalid. At least one number is required.',
      invalidFileSize: (max: string) => `File is too big. Maximum is ${max}`,
      invalidFileType: 'File type is not valid',
      minLength: (length: number | string) => `Need to have at least ${length} characters`,
      maxLength: (length: number | string) => `Can't have more than ${length} characters`,

      memberInvitationAllEmailsValid: 'One or more emails is invalid.',
      companyContactRequired: 'Company requires an account manager and primary contact',
    },

    attach: 'Attach',
    upload: 'Upload',
    saveChanges: 'Save changes',
    save: 'Save',
  },

  emissionFactors: {
    emissionFactorsByScope: (scope?: string) => scope ? `Scope ${scope} emission factors` : 'Emission factors',
    labelSource: 'Source',
  },

  employees: {
    chartSection: {
      needsToBeCalculated: 'Needs to be calculated',
    },
    selectYear: 'Select year',
    pageTitle: 'Employee emissions',
    submitBackupButton: 'Submit all answers',
    surveyValidationError: 'You already have answered this questionary.',
    surveyAnswerSubmitted: 'Answer submitted',
    emissionBoxes: {
      totalEmployeeEmission: 'Employee commuting & WFH',
      totalEmployeeEmissionTooltip: 'Total employee emissions reflect total emissions generated by employees, including working from home and employee commuting.',
      workFromHome: 'Work from home emissions',
      employeeCommuting: 'Employee commuting emissions',
      emptyState: 'Emissions to be calculated',
    },
    errorPage: {
      loadFormError: 'Please, try again to load the form',
      reloadForm: 'Reload form',
    },
  },

  companyOverview: {
    survey: {
      title: 'Calculate your emission intensity',
      buttonCancel: 'Cancel',
      buttonSubmit: 'Submit',
    },
  },

  differencePercentageParagraph: {
    noLastYearData: '+100% from last year.',
    emissionPercentageComparisson: (percentage: string) => `${percentage}% from last year`,
  },

  home: {
    dashboard: {
      totalEmissions: 'Total emissions',
      totalTransactions: 'Total transactions',
      co2e: 'CO₂e',
      transactions: 'transactions',
      nrOfTransactions: 'No. of transactions',
      emissionsByScope: 'Emissions by scope',
      emissionsByAggregation: (dateAggregation?: string) => `Emissions by ${dateAggregation}`,
      topCategories: (scope?: string) => scope ? `Scope ${scope} top categories` : 'Top categories',
      viewAllEmissionFactors: 'View all emission categories',
      noAnalysis: 'Your emissions will show up once your transactions have been analyzed',
      noEmissions: 'You don\'t have any emissions',
    },
  },

  measurements: {
    transactionsTitle: 'Underlying transactions',
    emissionsPerType: 'Emissions by type',
    comparingMessageBox: {
      benefits: 'By comparing emissions between different months and years, you can gain valuable insights and identify areas for improvement. Understanding your company\'s emissions is crucial for reducing your environmental impact and promoting sustainability.',
      overviewCategories: (topEmissions: string) => <span>
      Your largest emission hotspots fall within
        <strong>{topEmissions}</strong>. You can start reducing
        your emissions today by implementing
        our suggested recommendations.
      </span>,
      overviewLedgers: (topEmissions: string) => <span>
      Your largest emission hotspots fall within
        <strong>{topEmissions}</strong>. You can start reducing
        your emissions today by working with our
        selection of alternative, net zero suppliers.
      </span>,
      overviewVendors: (topEmissions: string) => <span>
      Your largest emission hotspots fall within
        <strong>{topEmissions}</strong>. You can start reducing
        your emissions today by working with our
        selection of sustainable alternative vendors.
      </span>,
      overviewRecommendationsBtnLabel: 'Explore recommendations',
      overviewMarketplaceBtnLabel: 'Explore marketplace',
    },
    transactionCount: (count: number) => `${count} transactions`,
    categoryTable: {
      headers: {
        percentage: 'PERCENTAGE',
        impact: 'IMPACT',
        category: 'CATEGORY',
        change: 'YoY %',
      },
      cells: {
        open: 'Open',
        commingSoon: 'Coming soon',
      },
    },
    ledgerTable: {
      headers: {
        percentage: 'PERCENTAGE',
        impact: 'IMPACT',
        vendor: 'LEDGER',
        recommendations: 'RECOMMENDATIONS',
      },
      cells: {
        open: 'Open',
        commingSoon: 'Coming soon',
        unknown: 'Unknown',
      },
    },
    vendorTable: {
      headers: {
        percentage: 'PERCENTAGE',
        impact: 'IMPACT',
        vendor: 'VENDOR',
        recommendations: 'RECOMMENDATIONS',
      },
      cells: {
        open: 'Open',
        commingSoon: 'Coming soon',
        unknown: 'Unknown',
      },
    },
    reports: {
      reportForm: {
        title: 'Generate your annual sustainability report',
        subtitle: 'This tool will auto-generate a sustainability report for your business. It is based on OpenAI’s GPT-3 large language model and works on the basis of your real data and inputs.',
        languagePrompt: 'Select your language:',
        reportLanguageEnglish: 'English',
        reportLanguageNederlands: 'Nederlands',
        describeInitiatives: 'Describe up to three emission reduction initiatives your company has implemented in the last year:',
        warning: '* Please note that large language models are trained on past public data and can thus not always be relied upon to make accurate, factual statements. It is therefore your own responsibility to review the contents of the output before publishing.',
        initiativeInputOnePlaceholder: 'E.g.: We implemented a policy to limit air travel to Germany',
        initiativeInputTwoPlaceholder: 'E.g.: We migrated a large part of our cloud resources to Google Cloud Platform',
        initiativeInputThreePlaceholder: 'E.g.: We switched our vehicle fleet to full electric',
      },
      generateReportError: 'Please try generating your report again',
      regenerateReport: 'Regenerate report',
      rengenerateImage: 'Regenerate image',
      generateReports: 'Generate report',
      generatingReport: 'Generating report',
      regenerateSection: 'Regenerate section',
      exportPdf: 'Export report as PDF',
      exportDocx: 'Export report as DOCX',
    },
    totalEmissions: 'Total emissions',
    emissionsPerEmployee: 'Total emissions per employee',
    emissionsPerEmployeeInfo: (company: ReactNode, year: ReactNode) => <Fragment>Based on {company}'s employee number for {year}</Fragment>,
    emissionsPerRevenueUnitInfo: (company: ReactNode, year: ReactNode) => <Fragment>Based on {company}'s annual revenue for {year}</Fragment>,
    emissionsBy: (dateAggregation: string) => `Emissions by ${dateAggregation}`,
    co2e: 'CO₂e',
    co2ePerEmployee: 'CO₂e per employee',
    co2eUnitOfRevenue: 'CO₂e per unit of revenue',
    noEmissions: 'No emissions',
    emissionAnalysis: 'Emission analysis',
    toggleButtonLabels: {
      classification: {
        classified: 'Classified',
        new: 'New',
        all: 'All',
        highConfidence: 'Confident',
        lowConfidence: 'Needs attention',
      },
      scope: {
        1: 'Scope 1',
        2: 'Scope 2',
        3: 'Scope 3',
        all: 'All',
      },
      date: {
        month: 'Monthly',
        year: 'Yearly',
      },
    },
    datagrid: {
      headerNames: {
        date: 'Date',
        ledger: 'Ledger',
        vendor: 'Vendor',
        description: 'Description',
        netAmount: 'Net cost',
        kgCo2EMultiplier: (currencySymbol: string) => `kg CO₂e / ${currencySymbol}`,
        co2Kg: 'kg CO₂e',
        scope: 'Scope',
        emissionFactor: 'CO₂e emission factor',
        certainty: 'Certainty',
        tags: 'Tags',
        integrationName: 'Source',
      },
      headerDescriptions: {
        certainty: 'The certainty level reflects how accurately the algorithm has matched your transactions with the correct emission factor. \n\nIf the status is \'Needs attention\' we recommend reviewing the classification.',
      },
    },
  },

  routes: {
    activityLog: 'Activity log',
    transactions: 'Transactions',
    dataManager: 'Data manager',
    activityBasedTransactions: 'Activity inputs',
    activityBasedTransactionsOther: 'Activity inputs',
    emissionFactors: 'Emission factors',
    companies: 'Companies',
    drivers: 'Drivers',
    employees: 'Employees',
    marketplace: 'Marketplace',
    carbonRemoval: 'Carbon removal',
    carbonRemovalRedirect: 'Redirect',
    home: 'Home',
    invite: 'Invitation',
    impersonateUser: 'Impersonate',
    login: 'Login',
    myAccount: 'My account',
    profile: 'Profile',
    queries: 'queries',
    resetPassword: 'Reset password',
    settings: 'Settings',
    integrations: 'Integrations',
    measurements: 'Measurements',
    scenarioBuilder: 'Scenario builder',
    knowledgeBase: 'Knowledge base',
    signup: 'Signup',
    users: 'Users',
    team: 'Team',
    welcome: 'Welcome',
    expenses: 'Expenses',
    linkedAccounting: 'Linked accounting',
    leftWorkspace: 'Left workspace',
    noWorkspace: 'No workspace',
    esrs: 'ESRS',
  },

  tooltips: {
    emissionsDatePeriod: (metric: string, date: string) => `Your ${metric} emissions for ${date} are `,
    totalEmissions: 'Greenhouse gases, or GHGs, are compound gases that trap heat or longwave radiation in the atmosphere. Their presence in the atmosphere makes the Earth\'s surface warmer. Sunlight or shortwave radiation easily passes through these gases and the atmosphere.',
    emissionsByScope: 'Scope 1 covers direct emissions from owned or controlled sources. Scope 2 covers indirect emissions from the generation of purchased electricity, steam, heating and cooling consumed by the reporting company. Scope 3 includes all other indirect emissions that occur in a company\'s value chain.',
    totalTransactions: 'Total transactions represent the number of financial transactions analyzed to calculate your emissions.',
    teamRoles: (currencySymbol: string) => <>
      <b>Admin</b>: <br/>
      <li>Can do everything inside Coolset</li>
      <br/><b>Editor</b>: <br/>
      <li>{`Can view and edit transactions, except for ${currencySymbol} amounts`}</li>
      <li>Can invite new members inside the product</li>
      <li>Can not edit company information</li>
      <br/><b>Viewer</b>: <br/>
      <li>Can not edit or view transactions, either spend-based or activity-based</li>
      <li>Can not invite new members inside the product</li>
      <li>Can not edit company information</li>
    </>,
    leaveOwner: 'In order to change your role or leave this workspace, you will need to change the workspace owner.',
    teamActions: {
      Admin: (
        <>
          <b>Changing this member to admin means they:</b>
          <ul>
            <li>can do everything within Coolset, besides deleting this workspace.</li>
          </ul>
        </>
      ),
      Editor: (
        <>
          <b>Changing this member to editor means they:</b>
          <ul>
            <li>can view and edit transactions, besides financial data.</li>
            <li>can invite new members within Coolset.</li>
            <li>cannot edit company information.</li>
          </ul>
        </>
      ),
      Owner: (
        <>
          <b>Changing this member to owner means they:</b>
          <ul>
            <li>can do everything within Coolset, including deleting this workspace.</li>
            <p>Once you transfer ownership, you will remain an admin for this workspace.</p>
          </ul>
        </>
      ),
      Viewer: (
        <>
          <b>Changing this member to viewer means they:</b>
          <ul>
            <li>cannot view or edit transactions, either spend-based or activity-based.</li>
            <li>cannot invite new members within Coolset.</li>
            <li>cannot edit company information.</li>
          </ul>
        </>
      ),
    },
  },

  languages: {
    english: 'English',
    french: 'French',
    german: 'German',
  },

  countries: {
    [Country.Andorra]: 'Andorra',
    [Country.UnitedArabEmirates]: 'United Arab Emirates',
    [Country.Afghanistan]: 'Afghanistan',
    [Country.AntiguaAndBarbuda]: 'Antigua and Barbuda',
    [Country.Anguilla]: 'Anguilla',
    [Country.Albania]: 'Albania',
    [Country.Armenia]: 'Armenia',
    [Country.Angola]: 'Angola',
    [Country.Antarctica]: 'Antarctica',
    [Country.Argentina]: 'Argentina',
    [Country.AmericanSamoa]: 'American Samoa',
    [Country.Austria]: 'Austria',
    [Country.Australia]: 'Australia',
    [Country.Aruba]: 'Aruba',
    [Country.AlandIslands]: 'Alland Islands',
    [Country.Azerbaijan]: 'Azerbaijan',
    [Country.BosniaAndHerzegovina]: 'Bosnia and Herzegovina',
    [Country.Barbados]: 'Barbados',
    [Country.Bangladesh]: 'Bangladesh',
    [Country.Belgium]: 'Belgium',
    [Country.BurkinaFaso]: 'Burkina Faso',
    [Country.Bulgaria]: 'Bulgaria',
    [Country.Bahrain]: 'Bahrain',
    [Country.Burundi]: 'Burundi',
    [Country.Benin]: 'Benin',
    [Country.BonaireSintEustatiusSaba]: 'Bonaire, Sint Eustatius and Saba',
    [Country.SaintBarthelemy]: 'Saint Barthelemy',
    [Country.Bermuda]: 'Bermuda',
    [Country.BruneiDarussalam]: 'Brunei Darussalam',
    [Country.Bolivia]: 'Bolivia',
    [Country.Brazil]: 'Brazil',
    [Country.Bahamas]: 'Bahamas',
    [Country.Bhutan]: 'Bhutan',
    [Country.BouvetIsland]: 'Bouvet Island',
    [Country.Botswana]: 'Botswana',
    [Country.Belarus]: 'Belarus',
    [Country.Belize]: 'Belize',
    [Country.Canada]: 'Canada',
    [Country.CocosKeelingIslands]: 'Cocos (Keeling) Islands',
    [Country.CentralAfricanRepublic]: 'Central African Republic',
    [Country.Congo]: 'Congo',
    [Country.CongoDemocraticRepublic]: 'Congo, Democratic Republic of the',
    [Country.Switzerland]: 'Switzerland',
    [Country.CoteDIvoire]: 'Cote d\'Ivoire',
    [Country.CookIslands]: 'Cook Islands',
    [Country.Chile]: 'Chile',
    [Country.Cameroon]: 'Cameroon',
    [Country.China]: 'China',
    [Country.Colombia]: 'Colombia',
    [Country.CostaRica]: 'Costa Rica',
    [Country.Cuba]: 'Cuba',
    [Country.CapeVerde]: 'Cape Verde',
    [Country.Curacao]: 'Curacao',
    [Country.ChristmasIsland]: 'Christmas Island',
    [Country.Cyprus]: 'Cyprus',
    [Country.CzechRepublic]: 'Czech Republic',
    [Country.Germany]: 'Germany',
    [Country.Djibouti]: 'Djibouti',
    [Country.Denmark]: 'Denmark',
    [Country.Dominica]: 'Dominica',
    [Country.DominicanRepublic]: 'Dominican Republic',
    [Country.Algeria]: 'Algeria',
    [Country.Ecuador]: 'Ecuador',
    [Country.Estonia]: 'Estonia',
    [Country.Egypt]: 'Egypt',
    [Country.WesternSahara]: 'Western Sahara',
    [Country.Eritrea]: 'Eritrea',
    [Country.Spain]: 'Spain',
    [Country.Ethiopia]: 'Ethiopia',
    [Country.Finland]: 'Finland',
    [Country.Fiji]: 'Fiji',
    [Country.FalklandIslands]: 'Falkland Islands (Malvinas)',
    [Country.Micronesia]: 'Micronesia, Federated States of',
    [Country.FaroeIslands]: 'Faroe Islands',
    [Country.France]: 'France',
    [Country.Gabon]: 'Gabon',
    [Country.UnitedKingdom]: 'United Kingdom',
    [Country.Grenada]: 'Grenada',
    [Country.Georgia]: 'Georgia',
    [Country.FrenchGuiana]: 'French Guiana',
    [Country.Guernsey]: 'Guernsey',
    [Country.Ghana]: 'Ghana',
    [Country.Gibraltar]: 'Gibraltar',
    [Country.Greenland]: 'Greenland',
    [Country.Gambia]: 'Gambia',
    [Country.Guinea]: 'Guinea',
    [Country.Guadeloupe]: 'Guadeloupe',
    [Country.EquatorialGuinea]: 'Equatorial Guinea',
    [Country.Greece]: 'Greece',
    [Country.SouthGeorgiaAndSandwichIsl]: 'South Georgia and the South Sandwich Islands',
    [Country.Guatemala]: 'Guatemala',
    [Country.Guam]: 'Guam',
    [Country.GuineaBissau]: 'Guinea-Bissau',
    [Country.Guyana]: 'Guyana',
    [Country.HongKong]: 'Hong Kong',
    [Country.HeardIslandMcdonaldIslands]: 'Heard Island and McDonald Islands',
    [Country.Honduras]: 'Honduras',
    [Country.Croatia]: 'Croatia',
    [Country.Haiti]: 'Haiti',
    [Country.Hungary]: 'Hungary',
    [Country.Indonesia]: 'Indonesia',
    [Country.Ireland]: 'Ireland',
    [Country.Israel]: 'Israel',
    [Country.IsleOfMan]: 'Isle of Man',
    [Country.India]: 'India',
    [Country.BritishIndianOceanTerritory]: 'British Indian Ocean Territory',
    [Country.Iraq]: 'Iraq',
    [Country.Iran]: 'Iran, Islamic Republic of',
    [Country.Iceland]: 'Iceland',
    [Country.Italy]: 'Italy',
    [Country.Jersey]: 'Jersey',
    [Country.Jamaica]: 'Jamaica',
    [Country.Jordan]: 'Jordan',
    [Country.Japan]: 'Japan',
    [Country.Kenya]: 'Kenya',
    [Country.Kyrgyzstan]: 'Kyrgyzstan',
    [Country.Cambodia]: 'Cambodia',
    [Country.Kiribati]: 'Kiribati',
    [Country.Comoros]: 'Comoros',
    [Country.SaintKittsAndNevis]: 'Saint Kitts and Nevis',
    [Country.KoreaDemocraticPeoplesRepublic]: 'Korea, Democratic People\'s Republic of',
    [Country.Korea]: 'Korea, Republic of',
    [Country.Kuwait]: 'Kuwait',
    [Country.CaymanIslands]: 'Cayman Islands',
    [Country.Kazakhstan]: 'Kazakhstan',
    [Country.LaoPeoplesDemocraticRepublic]: 'Lao People\'s Democratic Republic',
    [Country.Lebanon]: 'Lebanon',
    [Country.SaintLucia]: 'Saint Lucia',
    [Country.Liechtenstein]: 'Liechtenstein',
    [Country.SriLanka]: 'Sri Lanka',
    [Country.Liberia]: 'Liberia',
    [Country.Lesotho]: 'Lesotho',
    [Country.Lithuania]: 'Lithuania',
    [Country.Luxembourg]: 'Luxembourg',
    [Country.Latvia]: 'Latvia',
    [Country.LibyanArabJamahiriya]: 'Libya',
    [Country.Morocco]: 'Morocco',
    [Country.Monaco]: 'Monaco',
    [Country.Moldova]: 'Moldova, Republic of',
    [Country.Montenegro]: 'Montenegro',
    [Country.SaintMartin]: 'Saint Martin (French part)',
    [Country.Madagascar]: 'Madagascar',
    [Country.MarshallIslands]: 'Marshall Islands',
    [Country.Macedonia]: 'Macedonia, the Former Yugoslav Republic of',
    [Country.Mali]: 'Mali',
    [Country.Myanmar]: 'Myanmar',
    [Country.Mongolia]: 'Mongolia',
    [Country.Macao]: 'Macao',
    [Country.NorthernMarianaIslands]: 'Northern Mariana Islands',
    [Country.Martinique]: 'Martinique',
    [Country.Mauritania]: 'Mauritania',
    [Country.Montserrat]: 'Montserrat',
    [Country.Malta]: 'Malta',
    [Country.Mauritius]: 'Mauritius',
    [Country.Maldives]: 'Maldives',
    [Country.Malawi]: 'Malawi',
    [Country.Mexico]: 'Mexico',
    [Country.Malaysia]: 'Malaysia',
    [Country.Mozambique]: 'Mozambique',
    [Country.Namibia]: 'Namibia',
    [Country.NewCaledonia]: 'New Caledonia',
    [Country.Niger]: 'Niger',
    [Country.NorfolkIsland]: 'Norfolk Island',
    [Country.Nigeria]: 'Nigeria',
    [Country.Nicaragua]: 'Nicaragua',
    [Country.Netherlands]: 'Netherlands',
    [Country.Norway]: 'Norway',
    [Country.Nepal]: 'Nepal',
    [Country.Nauru]: 'Nauru',
    [Country.Niue]: 'Niue',
    [Country.NewZealand]: 'New Zealand',
    [Country.Oman]: 'Oman',
    [Country.Panama]: 'Panama',
    [Country.Peru]: 'Peru',
    [Country.FrenchPolynesia]: 'French Polynesia',
    [Country.PapuaNewGuinea]: 'Papua New Guinea',
    [Country.Philippines]: 'Philippines',
    [Country.Pakistan]: 'Pakistan',
    [Country.Poland]: 'Poland',
    [Country.SaintPierreAndMiquelon]: 'Saint Pierre and Miquelon',
    [Country.Pitcairn]: 'Pitcairn',
    [Country.PuertoRico]: 'Puerto Rico',
    [Country.PalestinianTerritory]: 'Palestine, State of',
    [Country.Portugal]: 'Portugal',
    [Country.Palau]: 'Palau',
    [Country.Paraguay]: 'Paraguay',
    [Country.Qatar]: 'Qatar',
    [Country.Reunion]: 'Reunion',
    [Country.Romania]: 'Romania',
    [Country.Serbia]: 'Serbia',
    [Country.RussianFederation]: 'Russian Federation',
    [Country.Rwanda]: 'Rwanda',
    [Country.SaudiArabia]: 'Saudi Arabia',
    [Country.SolomonIslands]: 'Solomon Islands',
    [Country.Seychelles]: 'Seychelles',
    [Country.Sudan]: 'Sudan',
    [Country.Sweden]: 'Sweden',
    [Country.Singapore]: 'Singapore',
    [Country.SaintHelena]: 'Saint Helena',
    [Country.Slovenia]: 'Slovenia',
    [Country.SvalbardAndJanMayen]: 'Svalbard and Jan Mayen',
    [Country.Slovakia]: 'Slovakia',
    [Country.SierraLeone]: 'Sierra Leone',
    [Country.SanMarino]: 'San Marino',
    [Country.Senegal]: 'Senegal',
    [Country.Somalia]: 'Somalia',
    [Country.Suriname]: 'Suriname',
    [Country.SouthSudan]: 'South Sudan',
    [Country.SaoTomeAndPrincipe]: 'Sao Tome and Principe',
    [Country.ElSalvador]: 'El Salvador',
    [Country.SintMaarten]: 'Sint Maarten (Dutch part)',
    [Country.SyrianArabRepublic]: 'Syrian Arab Republic',
    [Country.Swaziland]: 'Swaziland',
    [Country.TurksAndCaicosIslands]: 'Turks and Caicos Islands',
    [Country.Chad]: 'Chad',
    [Country.FrenchSouthernTerritories]: 'French Southern Territories',
    [Country.Togo]: 'Togo',
    [Country.Thailand]: 'Thailand',
    [Country.Tajikistan]: 'Tajikistan',
    [Country.Tokelau]: 'Tokelau',
    [Country.TimorLeste]: 'Timor-Leste',
    [Country.Turkmenistan]: 'Turkmenistan',
    [Country.Tunisia]: 'Tunisia',
    [Country.Tonga]: 'Tonga',
    [Country.Turkey]: 'Turkey',
    [Country.TrinidadAndTobago]: 'Trinidad and Tobago',
    [Country.Tuvalu]: 'Tuvalu',
    [Country.Taiwan]: 'Taiwan, Province of China',
    [Country.Tanzania]: 'United Republic of Tanzania',
    [Country.Ukraine]: 'Ukraine',
    [Country.Uganda]: 'Uganda',
    [Country.UnitedStates]: 'United States',
    [Country.UnitedStatesOutlyingIslands]: 'United States Minor Outlying Islands',
    [Country.Uruguay]: 'Uruguay',
    [Country.Uzbekistan]: 'Uzbekistan',
    [Country.HolySeeVaticanCityState]: 'Holy See (Vatican City State)',
    [Country.SaintVincentAndGrenadines]: 'Saint Vincent and the Grenadines',
    [Country.Venezuela]: 'Venezuela',
    [Country.VirginIslandsBritish]: 'British Virgin Islands',
    [Country.VirginIslandsUS]: 'US Virgin Islands',
    [Country.Vietnam]: 'Vietnam',
    [Country.Vanuatu]: 'Vanuatu',
    [Country.WallisAndFutuna]: 'Wallis and Futuna',
    [Country.Samoa]: 'Samoa',
    [Country.Kosovo]: 'Kosovo',
    [Country.Yemen]: 'Yemen',
    [Country.Mayotte]: 'Mayotte',
    [Country.SouthAfrica]: 'South Africa',
    [Country.Zambia]: 'Zambia',
    [Country.Zimbabwe]: 'Zimbabwe',
  },
};
