import { useMemo } from 'react';
import { useTheme, SxProps } from '@mui/material/styles';


type IuseSxBreakPointsStyles = {
  maxBreakPointValue: number;
  breakPointStyle: SxProps;
};

export const useSxBreakPointsStyles = (
  breakPointStyleList: IuseSxBreakPointsStyles[],
) => {
  const theme = useTheme();

  const breakPointObj = useMemo(() => {
    const breakPointStyles: { [key: string]: SxProps } = {};
    breakPointStyleList.forEach(({ breakPointStyle, maxBreakPointValue }) => {
      breakPointStyles[theme.breakpoints.down(maxBreakPointValue) as keyof typeof breakPointObj] = breakPointStyle;
    });

    return breakPointStyles;
  }, [breakPointStyleList, theme.breakpoints]);

  return breakPointObj;
};
