// ** MUI Imports
import { Theme } from '@mui/material/styles';

import { textPrimary } from 'shared/styles/theme/palette';


const Tabs = (theme: Theme) => {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 32,
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: 32,
          minHeight: 32,
          borderRadius: 16,
          textTransform: 'none',
          fontWeight: 500,
          '&.Mui-selected': {
            backgroundColor: '#F0F3F6',
            color: textPrimary,
          },
        },
      },
    },
  };
};

export default Tabs;
