// ** MUI Imports
import { Theme } from '@mui/material/styles';
import {
  backgroundDark,
  backgroundLight,
  error,
  hoverOnDark,
  hoverOnError,
  hoverOnLight,
  hoverOnSelected,
  primary,
  primaryLight,
  secondary,
  textContrast,
  textContrastDisabled,
  textPlaceholder,
} from 'shared/styles/muiTheme';


declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
  interface ToggleButtonPropsSizeOverrides {
    fixed: true;
  }
}

const Button = (theme: Theme) => {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: 'contained',
        color: 'primary',
        type: 'button',
      },
      styleOverrides: {
        root: {
          fontSize: 13,
          lineHeight: '16px',
          textTransform: 'none',
          borderRadius: 4,
          padding: '4px 16px',
          minHeight: 32,
          gap: 4,
          outline: 'none',
          '& svg': { height: 16 },
        },
        containedPrimary: {
          backgroundColor: backgroundDark,
          color: textContrast,
          '&:hover, &:active, &:focus-within': {
            backgroundColor: hoverOnDark,
          },
          '&:disabled': {
            backgroundColor: backgroundDark,
            color: textContrastDisabled,
          },
          '& svg': {
            color: 'inherit',
          },
          '& .MuiLoadingButton-loadingIndicator': {
            color: textContrastDisabled,
          },
        },
        containedSecondary: {
          background: backgroundLight,
          color: secondary,
          border: '1px solid #E8E8E8',
          '&:hover, &:active, &:focus-within': {
            backgroundColor: hoverOnLight,
          },
          '&:disabled': {
            backgroundColor: backgroundLight,
            color: textPlaceholder,
            '--icon-color': textPlaceholder,
          },
          '& svg': {
            color: `var(--icon-color, ${primary})`,
          },
        },
        containedInfo: {
          background: backgroundLight,
          color: secondary,
          border: '1px solid #E8E8E8',
          '&:hover, &:active, &:focus-within': {
            backgroundColor: hoverOnLight,
          },
          '&:disabled': {
            backgroundColor: backgroundLight,
            color: textPlaceholder,
          },
          '& svg': {
            color: 'inherit',
          },
        },
        containedSuccess: {
          background: primaryLight,
          color: primary,
          '&:hover, &:active, &:focus-within:not(.nav-btn)': {
            backgroundColor: hoverOnSelected,
          },
          '&:disabled': {
            backgroundColor: primaryLight,
            color: textPlaceholder,
            '--icon-color': textPlaceholder,
          },
          '& svg': {
            color: `var(--icon-color, ${primary})`,
          },
        },
        containedError: {
          backgroundColor: error,
          color: textContrast,
          '&:hover, &:active, &:focus-within': {
            backgroundColor: hoverOnError,
          },
          '&:disabled': {
            backgroundColor: '#FFDDE6',
            color: '#FD9DB6',
          },
          '& svg': {
            color: 'inherit',
          },
        },
        textPrimary: {
          color: primary,
          '&:hover, &:active, &:focus-within': {
            backgroundColor: '#FAFCFF',
          },
          '&:disabled': {
            backgroundColor: 'none',
            color: textPlaceholder,
          },
          '& svg': {
            color: 'inherit',
          },
        },
      },
      variants: [
        {
          props: {
            variant: 'contained',
          },
          style: {
            boxShadow: '0px 1px 6px 0px #30313d08',
          },
        },
        {
          props: {
            size: 'small',
            variant: 'contained',
          },
          style: {
            padding: '2px 16px',
            minHeight: 24,
          },
        },
      ],
    },

    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          textDecoration: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '0.813rem',
          border: 'none',
          textTransform: 'none',
          borderRadius: '4px',
          paddingTop: theme.spacing(0.1),
          paddingBottom: theme.spacing(0.1),
          paddingLeft: theme.spacing(0.5),
          paddingRight: theme.spacing(0.5),
        },
        '& + &': {
          borderLeft: '1px solid #ccc',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          height: 34,
          alignItems: 'center',
          gap: 6,
          outlineStyle: 'solid',
          outlineWidth: '1px',
          outlineColor: theme.palette.tertiary.main,
          borderRadius: theme.shape.borderRadius,
          boxShadow: '0px 1px 6px 0px #30313d08',
          '& .MuiToggleButton-root.Mui-selected , .MuiToggleButton-root.Mui-selected:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'white ',
          },
        },
      },
    },
  };
};

export default Button;
