import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const CloudIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      d="M13.013 7.43195L12.9019 7.82169L13.2602 8.01105C14.118 8.46438 14.7008 9.36454 14.7008 10.4C14.7008 11.8912 13.4919 13.1 12.0008 13.1H4.40078C2.6887 13.1 1.30078 11.7121 1.30078 10C1.30078 8.63569 2.18246 7.47599 3.40854 7.06208L3.81087 6.92626L3.742 6.50724C3.71492 6.34251 3.70078 6.1731 3.70078 6.00002C3.70078 4.28794 5.0887 2.90002 6.80078 2.90002C7.93022 2.90002 8.91916 3.50373 9.46166 4.40846L9.66994 4.7558L10.053 4.62419C10.2868 4.54385 10.5381 4.50002 10.8008 4.50002C12.071 4.50002 13.1008 5.52977 13.1008 6.80002C13.1008 7.01989 13.0701 7.23175 13.013 7.43195Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </IconContainer>
);
