import React, { FC } from 'react';
import { IconContainer } from './IconContainer';


export const LightbulbIcon: FC<React.ComponentProps<typeof IconContainer>> = ({
  ...props
}) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      d="M8 0.5C4.96243 0.5 2.5 2.96243 2.5 6C2.5 8.03385 3.60421 9.80921 5.24344 10.7603C5.70041 11.0254 5.98657 11.4323 5.99952 11.8417C6.00666 12.0675 6.16438 12.2605 6.38427 12.3125C6.61852 12.3679 6.85723 12.4114 7.09973 12.4426C7.31546 12.4703 7.50012 12.2975 7.50012 12.08V8.97263C7.28727 8.94908 7.07869 8.91066 6.87549 8.85837C6.60807 8.78953 6.44707 8.51694 6.5159 8.24952C6.58473 7.98209 6.85732 7.8211 7.12475 7.88993C7.40396 7.96179 7.69717 8.00012 8.00012 8.00012C8.30307 8.00012 8.59628 7.96179 8.87549 7.88993C9.14292 7.8211 9.41551 7.98209 9.48434 8.24952C9.55317 8.51694 9.39218 8.78953 9.12475 8.85837C8.92156 8.91066 8.71297 8.94908 8.50012 8.97263V12.08C8.50012 12.2975 8.68479 12.4703 8.90053 12.4426C9.14293 12.4114 9.38156 12.3678 9.61573 12.3125C9.83563 12.2605 9.99334 12.0675 10.0005 11.8417C10.0134 11.4323 10.2996 11.0254 10.7566 10.7603C12.3958 9.80921 13.5 8.03385 13.5 6C13.5 2.96243 11.0376 0.5 8 0.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00876 13.2664C6.06022 12.9951 6.32188 12.8169 6.59318 12.8684C7.0485 12.9548 7.51873 13 8 13C8.48127 13 8.95151 12.9548 9.40682 12.8684C9.67812 12.8169 9.93978 12.9951 9.99124 13.2664C10.0427 13.5377 9.86449 13.7994 9.59319 13.8509C9.07675 13.9488 8.54412 14 8 14C7.45588 14 6.92326 13.9488 6.40682 13.8509C6.13551 13.7994 5.9573 13.5377 6.00876 13.2664Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.50271 14.896C6.53144 14.6213 6.77738 14.422 7.05202 14.4507C7.36341 14.4833 7.67967 14.5 8 14.5C8.32033 14.5 8.63659 14.4833 8.94798 14.4507C9.22262 14.422 9.46856 14.6213 9.49729 14.896C9.52602 15.1706 9.32667 15.4166 9.05202 15.4453C8.70613 15.4815 8.35515 15.5 8 15.5C7.64485 15.5 7.29387 15.4815 6.94798 15.4453C6.67333 15.4166 6.47398 15.1706 6.50271 14.896Z"
      fill="currentColor"
    />
  </IconContainer>
);

export const LightBulbMiniIcon: FC<React.ComponentProps<typeof IconContainer>> = ({
  ...props
}) => (
  <IconContainer {...props} viewBox="0 0 20 20">
    <path
      d="M10 1C6.68629 1 4 3.68629 4 7C4 8.86519 4.85197 10.532 6.18519 11.6313C7.23747 12.4989 8 13.4427 8 14.4557V15.1003C8 15.4459 8.23625 15.7468 8.57205 15.8289C9.03046 15.9408 9.50883 16 10 16C10.4912 16 10.9695 15.9408 11.428 15.8289C11.7637 15.7468 12 15.4459 12 15.1003V14.4557C12 13.4427 12.7625 12.4989 13.8148 11.6313C15.148 10.532 16 8.86519 16 7C16 3.68629 13.3137 1 10 1Z"
      fill="currentColor"
    />
    <path
      d="M8.8628 17.4144C8.45329 17.3522 8.07086 17.6337 8.00862 18.0432C7.94638 18.4527 8.22789 18.8352 8.6374 18.8974C9.08221 18.965 9.53731 19 10.0001 19C10.4629 19 10.918 18.965 11.3628 18.8974C11.7723 18.8352 12.0538 18.4527 11.9916 18.0432C11.9293 17.6337 11.5469 17.3522 11.1374 17.4144C10.7669 17.4708 10.3872 17.5 10.0001 17.5C9.61302 17.5 9.23326 17.4708 8.8628 17.4144Z"
      fill="currentColor"
    />
  </IconContainer>
);