import { useContext, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocalizationContext } from 'contexts';
import { FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';
import CategoryButtons from './CategoriesButtons';
import { dataKnowledgePoints } from './data';


export const KnowledgeBase = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const { dictionary } = useContext(LocalizationContext);


  const filteredItems = useMemo(() => {
    if (selectedCategory === 'all') {
      return dataKnowledgePoints;
    }

    const items = dataKnowledgePoints.filter((item) => {
      return item.category === selectedCategory;
    });
    return items;
  }, [selectedCategory]);

  return (
    <Grid container spacing={0} alignItems="left">
      <Grid item xs={12}>
        <FiltersSection title={dictionary.pageTitles.knowledgeBase}>
          {() => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              <Grid item xs={12} sx={{ my: 2 }}>
                <CategoryButtons
                  onSelect={setSelectedCategory}
                  selectedCategory={selectedCategory}
                />
              </Grid>
              {filteredItems.map((visibleItem) => {
                return (
                  <Accordion
                    key={visibleItem.id}
                    sx={{
                      '&.Mui-expanded': {
                        boxShadow: '0px 12px 32px rgb(153 153 153 / 16%)',
                      },
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                      <Typography>{visibleItem.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{visibleItem.desc}</Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          )}
        </FiltersSection>
      </Grid>
    </Grid>
  );
};
