import React, { FC, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';

import { routes } from 'config';
import { LocalizationContext, UserContext, LayoutContext } from 'contexts';
import { useQueryState } from 'shared/hooks/useQueryState';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';


export const Impersonate: FC = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { validationError } = useContext(LayoutContext);
  const { impersonate } = useContext(UserContext);
  const navigate = useNavigate();

  const [token] = useQueryState('token');

  const impersonateUser = useCallback(async (token: string) => {
    try {
      await impersonate(token);
      navigate(routes.home);
    } catch {
      validationError(dictionary.auth.impersonate.invalidToken);
      navigate(routes.login);
    }
  }, [impersonate, navigate, validationError, dictionary]);

  useEffect(() => {
    if (token) {
      impersonateUser(token);
    } else {
      validationError(dictionary.auth.impersonate.noToken);
      navigate(routes.login);
    }
  }, [impersonateUser, navigate, token, validationError, dictionary]);

  return <BodyLoading height="100vh"/>;
};
