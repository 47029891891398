import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { cacheKeys } from 'config';

import { offsettingClient } from 'clients/offsetting/offsettingClient';
import { transactionClient } from 'clients/transactions/transactionClient';
import { TransactionStatus } from 'clients/transactions/transactionClient.types';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';
import { ChartType } from 'clients/charts/chartClient.types';
import { useDateFilters } from 'shared/hooks/useDateFilters';
import { useCharts } from 'shared/hooks/useCharts';


export const useCarbonRemovalData = () => {
  const { lteDate, gteDate } = useDateFilters();
  const mappedEmissionParams = useMemo(() => ({
    dateGte: gteDate,
    dateLte: lteDate,
    status: TransactionStatus.Classified,
    scope: [Scope.One, Scope.Two, Scope.Three],
    type: ChartType.pie,
  }),
  [gteDate, lteDate],
  );
  const { data: emissionChartData, isLoadingCharts: isLoadingChartsEmissions } = useCharts(mappedEmissionParams);

  const mappedOffsetParams = useMemo(() => ({
    ...mappedEmissionParams,
    scope: [Scope.Offset],
  }),
  [mappedEmissionParams],
  );
  const { data: offsetChartData, isLoadingCharts: isLoadingChartsOffset } = useCharts(mappedOffsetParams);

  const {
    data: { data: luneBundles } = {},
    isLoading: isLoadingLuneBundles,
  } = useQuery(
    cacheKeys.offset.getLuneBundles,
    offsettingClient.getLuneBundles,
  );

  const { data: { data: luneOffsetKey } = {}, isLoading: isLoadingOffsetKeys } = useQuery(
    cacheKeys.offset.getOffsetApiKeys,
    offsettingClient.getOffsetLuneApiKeys,
  );

  const mappedTransactionParams = useMemo(() => ({
    dateGte: gteDate,
    dateLte: lteDate,
    category: 'Offsets',
    filters: [{ column: 'vendor_name',operator: 'contains', value: 'Coolset offsets' }],
  }),
  [gteDate, lteDate],
  );

  const { data: { data: offsetTransactions } = {}, isLoading: isLoadingOffsetTransactions } = useQuery(
    [cacheKeys.transactions.getTransactions, mappedTransactionParams],
    () => transactionClient.getTransactions(mappedTransactionParams),
  );

  return {
    emissionWeight: emissionChartData?.meta?.total_co2_kg || 0,
    luneBundles,
    luneOffsetKey: luneOffsetKey?.luneAccountId,
    isAnyDataLoading: (isLoadingOffsetKeys && !luneOffsetKey) || (isLoadingLuneBundles && !luneBundles) || (isLoadingChartsEmissions && !emissionChartData) || (isLoadingChartsOffset && !offsetChartData) || (isLoadingOffsetTransactions && !offsetTransactions),
    totalOffset: offsetChartData?.meta.total_co2_kg as number || 0,
    offsetOrders: [],
    offsetTransactions,
  };
};
