import React, {
  Fragment,
  useContext,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Skeleton,
} from '@mui/material';
import { subYears } from 'date-fns';

import { ClockIcon } from 'shared/icons/ClockIcon';
import { CongratsIcon } from 'shared/icons/CongratsIcon';
import { routes } from 'config';
import { LocalizationContext } from 'contexts';
import { ChartGroupBy, ChartType } from 'clients/charts/chartClient.types';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';
import { useCharts } from 'shared/hooks/useCharts';
import { useActivityBasedCategories } from 'shared/hooks/useActivityBasedSubCategories';

import { MessageFeedback } from 'shared/components/layout/Feedback/MessageFeedback';
import LoadingModal from 'shared/components/layout/Modal/LoadingModal/LoadingModal';
import RightArrowBtn from 'shared/components/layout/Buttons/RightArrowBtn';
import EmissionsComparingMessageBox from 'views/LoggedIn/Measurements/components/EmissionsComparingMessageBox';
import { getCategoriesString } from 'views/LoggedIn/Measurements/helpers';

import LedgersTable from './LedgersTable';


export const EmissionsByLedger = ({
  hasClassifiedTransactions,
  hasEmissions,
  emissionsContainerCategoryBreakdownRef,
  filters,
  isComparingMonths,
}: any) => {
  const {
    scope,
    gteDate,
    lteDate,
    emissionFactorCategory,
    isParent,
    tags,
    intensity,
  } = filters;
  const { dictionary } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const mappedFilters = useMemo(() => ({
    dateGte: gteDate,
    dateLte: lteDate,
    scope: scope !== Scope.All ? scope : [Scope.One, Scope.Two, Scope.Three],
    tags,
    relativeTo: intensity,
  }), [scope, gteDate, lteDate, tags, intensity]);
  const { isLoading: isLoadingActivityBased } = useActivityBasedCategories({ mandatory: false });

  const { data: ledgersChartData, isLoadingCharts: isLoadingLedgersChartData, isFetching: isFetchingLedgersChartData } = useCharts({
    groupBy: ChartGroupBy.ledger,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...(isParent
      ? { categoryId: emissionFactorCategory?.id }
      : { subCategoryId: emissionFactorCategory?.id }),
    ...mappedFilters,
  });

  const { data: prevLedgersChartData, isLoadingCharts: isLoadingPrevLedgersChartData, isFetching: isFetchingPrevLedgersChartData } = useCharts({
    groupBy: ChartGroupBy.ledger,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...(isParent
      ? { categoryId: emissionFactorCategory?.id }
      : { subCategoryId: emissionFactorCategory?.id }),
    ...mappedFilters,
    dateGte: subYears(gteDate, 1),
    dateLte: subYears(lteDate, 1),
  }, { enabled: isComparingMonths });


  const totalCo2 = useMemo(() => ledgersChartData?.meta?.total_co2_kg, [ledgersChartData?.meta?.total_co2_kg]);
  const prevTotalCo2 = useMemo(() => prevLedgersChartData?.meta?.total_co2_kg, [prevLedgersChartData?.meta?.total_co2_kg]);

  const data = React.useMemo(() => {
    const getValues = (data: any, totalCo2: number) => {
      const sumCo2Kg = data?.sum_co2_kg || 0;
      const value = sumCo2Kg * (sumCo2Kg > 0 ? 1 : -1);
      const co2Proportion = (value / totalCo2) || 0;
      const co2Percentage = (co2Proportion * 100).toFixed(2);
      const transactionCount = data?.classified_transaction_count || 0;

      return {
        value,
        co2Proportion,
        co2Percentage,
        transactionCount,
      };
    };

    return ( ledgersChartData && ledgersChartData?.values.map(({ metrics, dimensions }: any) => {

      const prevLedgerValue = prevLedgersChartData?.values.find((data: any) => dimensions?.group === data?.dimensions?.group);

      const values = getValues(metrics, totalCo2);
      const previousValues = getValues(prevLedgerValue?.metrics, prevTotalCo2);

      let percentageChange = ((values.value - previousValues.value) / (previousValues.value || 1)) * 100;

      if (previousValues.value === 0 && values.value > 0) {
        percentageChange = 100;
      }

      return {
        ...values,
        previousValues,
        percentageChange,
        name: dimensions?.group,
      };
    })
    );
  },
  [
    ledgersChartData,
    prevLedgersChartData,
    totalCo2,
    prevTotalCo2,
  ]);

  const isAnyChartsLoading = isLoadingActivityBased || isLoadingLedgersChartData || isLoadingPrevLedgersChartData;
  const isAnyChartsFetching = isFetchingLedgersChartData || isFetchingPrevLedgersChartData;

  return (
    <React.Fragment>
      <LoadingModal open={isAnyChartsFetching && !isAnyChartsLoading}/>
      {!isAnyChartsLoading && hasClassifiedTransactions && hasEmissions && (
        <Box my={3}>
          <EmissionsComparingMessageBox
            text={dictionary.measurements.comparingMessageBox.overviewLedgers(getCategoriesString(data))}
            rightComponent={
              <RightArrowBtn label={dictionary.measurements.comparingMessageBox.overviewMarketplaceBtnLabel} onClick={() => navigate(routes.marketplace)}/>
            }
          />
        </Box>
      )}
      <Box width="100%" pt={2}>
        {isAnyChartsLoading && <Skeleton width="100%" height="150px" variant="rectangular"/>}
        <Box width="100%">
          {isAnyChartsLoading ? (
            <Skeleton variant="rounded" width="100%" height={400}/>
          ) : (
            ledgersChartData && (
              <Fragment>
                <Box
                  ref={emissionsContainerCategoryBreakdownRef}
                  style={{ position: 'relative', width: '100%' }}
                >
                  {!isAnyChartsLoading && (!hasClassifiedTransactions || !hasEmissions) ? (
                    <Box className="_d-flex jc-center" width="100%" py={8}>
                      <MessageFeedback
                        icon={hasClassifiedTransactions ? <CongratsIcon/> : <ClockIcon/>}
                        message={hasClassifiedTransactions ? dictionary.home.dashboard.noEmissions : dictionary.home.dashboard.noAnalysis}
                      />
                    </Box>
                  ) : (
                    <LedgersTable data={data} isComparingMonths={isComparingMonths} date={gteDate}/>
                  )}
                </Box>
              </Fragment>
            )
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};
