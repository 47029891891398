import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const CoinsIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      d="M14 4.25C14 6.04467 11.3133 7.5 8 7.5C4.68667 7.5 2 6.04467 2 4.25C2 2.45533 4.68667 1 8 1C11.3133 1 14 2.45533 14 4.25Z"
      fill="currentColor"
    />
    <path
      d="M8 8.49997C9.79 8.49997 11.46 8.10931 12.7187 7.42731C13.1819 7.18137 13.6077 6.87069 13.9833 6.50464C13.994 6.58531 14 6.66731 14 6.74997C14 8.54464 11.3133 9.99997 8 9.99997C4.68667 9.99997 2 8.54464 2 6.74997C2 6.66731 2.006 6.58531 2.01667 6.50464C2.39228 6.87068 2.81811 7.18135 3.28133 7.42731C4.53933 8.10931 6.21 8.49997 8 8.49997Z"
      fill="currentColor"
    />
    <path
      d="M8 11C9.79 11 11.46 10.6093 12.7187 9.92731C13.1819 9.68138 13.6077 9.3707 13.9833 9.00464C13.994 9.08531 14 9.16731 14 9.24997C14 11.0446 11.3133 12.5 8 12.5C4.68667 12.5 2 11.0446 2 9.24997C2 9.16731 2.006 9.08531 2.01667 9.00464C2.39227 9.37068 2.8181 9.68136 3.28133 9.92731C4.53933 10.6093 6.21 11 8 11Z"
      fill="currentColor"
    />
    <path
      d="M8 13.5C9.79 13.5 11.46 13.1093 12.7187 12.4273C13.1819 12.1814 13.6077 11.8707 13.9833 11.5046C13.994 11.5853 14 11.6673 14 11.75C14 13.5446 11.3133 15 8 15C4.68667 15 2 13.5446 2 11.75C2 11.6673 2.006 11.5853 2.01667 11.5046C2.39227 11.8707 2.8181 12.1814 3.28133 12.4273C4.53933 13.1093 6.21 13.5 8 13.5Z"
      fill="currentColor"
    />
  </IconContainer>
);
