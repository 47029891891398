import React, { useCallback, useContext, useMemo } from 'react';
import {
  DataGridPro as DataGrid,
  GridColDef,
  GridRowModel,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { Box, Grid } from '@mui/material';
import { primaryLight } from 'shared/styles/muiTheme';

import { snakeCase } from 'lodash';
import { LocalizationContext } from 'contexts';
import { Dictionary } from 'contexts/LocalizationContext/types';
import { CustomFilter, GetTransactionsParams } from 'clients/transactions/transactionClient.types';
import { useTransactionsStatistics } from 'shared/hooks/useTransactionsStatistics';
import { useDataGridPagination } from 'shared/hooks/DataGrid/useDataGridPagination';


type Props = {
  allFilters: GetTransactionsParams;
  statisticsColumn: string;
  statisticsColumnName: string;
  setCustomFilters: React.Dispatch<React.SetStateAction<CustomFilter[]>>;
  advancedPanelRef?: any,
  showRowPerPageOption?: boolean,
  pageSize: number;
  handlePageSizeChange: (pageSize: number) => void;
}

const getColumns = (statColumn: string, statColumnName: string, dictionary: Dictionary): Array<GridColDef> => {

  const columns: Array<GridColDef> = [
    {
      field: statColumn,
      headerName: statColumnName,
      editable: false,
      sortable: false,
      resizable: true,
      valueGetter: ({ row }: GridValueGetterParams) => row?.[statColumn] || 'NULL',
      flex: 1,
      pinnable: false,
    },
    {
      field: 'count',
      headerName: dictionary.menu.transactions.title,
      type: 'number',
      editable: false,
      sortable: false,
      resizable: true,
      flex: 1,
      pinnable: false,
      valueFormatter: ({ value }: any) => value && `${(value as number).toFixed(0)}`,
    },
    {
      field: 'totalAmount',
      headerName: dictionary.menu.transactions.amount,
      type: 'number',
      editable: false,
      sortable: false,
      resizable: true,
      flex: 1,
      pinnable: false,
      valueGetter: ({ row }: any) => row?.localizedAmount,
    },
  ];
  return columns;
};

function TransactionStatisticsList(props: Props) {
  const {
    allFilters,
    statisticsColumn,
    statisticsColumnName,
    setCustomFilters,
    advancedPanelRef,
    showRowPerPageOption = false,
    pageSize,
    handlePageSizeChange,
  } = props;
  const { dictionary } = useContext(LocalizationContext);
  const { page, handlePageChange } = useDataGridPagination({
    defaultPage: advancedPanelRef.current[statisticsColumn].isSelected ? 0 : advancedPanelRef.current[statisticsColumn].page,
  });

  const { status, statistics, count } = useTransactionsStatistics({
    ...allFilters,
    offset: pageSize * page,
    limit: pageSize,
    groupBy: snakeCase(statisticsColumn),
  });

  const columns = useMemo(() => (
    getColumns(statisticsColumn, statisticsColumnName, dictionary)
  ), [statisticsColumn, statisticsColumnName, dictionary]);

  const getSelectedFilterIndex = useCallback((row: GridRowModel) => {
    return allFilters.filters ? allFilters.filters.findIndex(customFilter =>
      customFilter.column === snakeCase(statisticsColumn) &&
      customFilter.value === row[statisticsColumn],
    ) : -1;
  }, [allFilters, statisticsColumn]);

  const onRowClick = useCallback((e: GridRowParams<any>) => {
    advancedPanelRef.current[statisticsColumn] = {
      page: !advancedPanelRef.current[statisticsColumn].isSelected ? page : advancedPanelRef.current[statisticsColumn].page,
      isSelected: !advancedPanelRef.current[statisticsColumn].isSelected,
    };

    const value = e.row[statisticsColumn];

    setCustomFilters(prev => {
      let newFilters = [...prev];
      const filterIndex = getSelectedFilterIndex(e.row);
      if (filterIndex > -1) {
        newFilters.splice(filterIndex, 1);
      } else {
        newFilters.push({
          column: snakeCase(statisticsColumn),
          operator: !!value ? 'equals' : 'isEmpty',
          value: !!value ? value : undefined,
        });
      }
      return newFilters;
    });
  }, [advancedPanelRef, page, statisticsColumn, setCustomFilters, getSelectedFilterIndex]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box
          height={437}
          sx={{
            '& .MuiDataGrid--Selected': {
              bgcolor: primaryLight,
            },
            '& .MuiDataGrid-row': { cursor: 'pointer' },
          }}
        >
          <DataGrid
            loading={status === 'loading'}
            columns={columns}
            rowHeight={48}
            headerHeight={52}
            rows={statistics}
            disableSelectionOnClick
            disableColumnMenu
            pagination
            rowCount={count}
            page={page}
            rowsPerPageOptions={[10, 20, 50, 100, 1000]}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            pageSize={pageSize}
            paginationMode="server"
            disableColumnFilter
            onRowClick={onRowClick}
            getRowClassName={(params) => getSelectedFilterIndex(params.row) > -1 ? 'MuiDataGrid--Selected' : ''}
            density="compact"
            componentsProps={{
              footer: {
                sx: {
                  '& .MuiToolbar-root': {
                    paddingLeft: '0',
                    width: '100%',
                  },
                  '& .MuiTablePagination-displayedRows': {
                    ml: 'auto',
                  },
                  '& .MuiTablePagination-spacer': {
                    flex: 0,
                  },
                  '& .MuiTablePagination-root': {
                    width: '100%',
                  },
                  ...(!showRowPerPageOption && { '& .MuiInputBase-root': { display: 'none' } }),
                  ...(!showRowPerPageOption && { '& .MuiTablePagination-selectLabel': { display: 'none' } }),
                },
              },
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  [statisticsColumn]: true,
                  count: true,
                  totalAmount: true,
                },
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default TransactionStatisticsList;