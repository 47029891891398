import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { object as yupObj, string as yupStr, ref as yupRef } from 'yup';

import { featureFlags, routes } from 'config';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';

import { emailRegex, passwordRegex } from 'helpers/validation';
import { UserInvite } from 'clients/users/userClient.types';
import { useSignup } from 'shared/hooks/useSignup';
import { ReactComponent as GoogleLogo } from 'shared/img/google.svg';
import { ReactComponent as MicrosoftLogo } from 'shared/img/microsoft.svg';
import { LoggedOutActionsLayout } from 'shared/components/layout/LoggedOutActionsLayout/LoggedOutActionsLayout';
import { Form } from 'shared/components/form/Form/Form';

import { LoggedOutHeader } from './partials/LoggedOutHeader';
import { FormDivider } from './partials/FormDivider';
import { SwitchLoggedOutPage } from './partials/SwitchLoggedOutPage';


type SignUpProps = {
  invite?: UserInvite;
  token?: string;
}

export const SignUp: FC<SignUpProps> = (props) => {
  const { invite, token } = props;
  const navigate = useNavigate();
  const { genericError, validationError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { signupAndLogin, signupWithSSO } = useSignup();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSSOLogin = useCallback(async (provider: string) => {
    if (!token) {
      throw new Error('Invite is required');
    }
    try {
      await signupWithSSO(provider, token);
    } catch (e: any) {
      validationError(e.message);
    }
  }, [signupWithSSO, token, validationError]);

  const submitHandler = useCallback(
    async (data: any) => {
      setIsSubmitting(true);
      try {
        await signupAndLogin({ ...data, userInvitationId: token });
        navigate(routes.home);
      } catch (e) {
        console.error(e);
        genericError();
      } finally {
        setIsSubmitting(false);
      }
    },
    [genericError, navigate, signupAndLogin, token],
  );

  const validationSchema = yupObj().shape({
    password: yupStr()
      .required(dictionary.forms.validations.required)
      .min(6, dictionary.forms.validations.minLength(6))
      .matches(passwordRegex, dictionary.forms.validations.invalidPassword)
      .max(20, dictionary.forms.validations.maxLength(20)),
    repeatPassword: yupStr()
      .required(dictionary.forms.validations.required)
      .oneOf([yupRef('password')], dictionary.passwordsDoNotMatch),
    firstName: yupStr().required(dictionary.forms.validations.required),
    lastName: yupStr().required(dictionary.forms.validations.required),
    company: yupStr().required(dictionary.forms.validations.required),
    jobTitle: yupStr().required(dictionary.forms.validations.required),
    email: yupStr()
      .required(dictionary.forms.validations.required)
      .matches(emailRegex, dictionary.forms.validations.invalidEmail),
  });

  const inputList = useMemo(() => [
    {
      name: 'firstName',
      label: dictionary.forms.user.fieldName,
      placeholder: dictionary.forms.signup.placeholders.name,
      gridProps: { xs: 6 },
      autoComplete: 'given-name',
    },
    {
      name: 'lastName',
      label: dictionary.forms.user.fieldSurname,
      placeholder: dictionary.forms.signup.placeholders.surname,
      gridProps: { xs: 6 },
      autoComplete: 'family-name',
    },
    {
      name: 'company',
      label: dictionary.forms.fieldCompany,
      placeholder: dictionary.forms.signup.placeholders.company,
      gridProps: { xs: 6 },
      inputProps: {
        readOnly: !!invite,
        value: invite?.company?.name || '',
      },
    },
    {
      name: 'jobTitle',
      label: dictionary.forms.user.fieldPosition,
      placeholder: dictionary.forms.signup.placeholders.position,
      gridProps: { xs: 6 },
    },
    {
      name: 'email',
      label: dictionary.forms.fieldEmail,
      placeholder: dictionary.forms.signup.placeholders.email,
      inputProps: {
        readOnly: !!invite,
        value: invite?.email || '',
      },
      autoComplete: 'email',
    },
    {
      name: 'password',
      label: dictionary.forms.fieldPassword,
      placeholder: dictionary.forms.signup.placeholders.password,
      type: 'password',
      gridProps: { xs: 6 },
      autoComplete: !!invite ? 'current-password' : 'new-password',
    },
    {
      name: 'repeatPassword',
      label: dictionary.forms.signup.fieldRepeatPassword,
      placeholder: dictionary.forms.signup.placeholders.repeatPassword,
      type: 'password',
      gridProps: { xs: 6 },
      autoComplete: !!invite ? 'current-password' : 'new-password',
    },
  ], [dictionary, invite]);

  return (
    <LoggedOutActionsLayout>
      <LoggedOutHeader
        title={invite ? dictionary.invite.title : dictionary.signUp}
        subtitle={invite ? dictionary.invite.subtitle(invite?.company?.name) : dictionary.signUpDescription}
      />
      <Form
        validationSchema={validationSchema}
        buttonLabel={!invite ? dictionary.signUp : undefined}
        formOptions={{
          defaultValues: {
            company: invite?.company?.name || '',
            email: invite?.email || '',
            firstName: '',
            jobTitle: '',
            lastName: '',
            password: '',
            repeatPassword: '',
            userInvitationId: '',
          },
        }}
        onSubmit={submitHandler}
        inputList={inputList}
        submitButton={
          invite
            ?
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
              sx={{
                height: 48,
                borderRadius: 2,
                fontSize: 15,
                fontWeight: 600,
              }}
            >
              {dictionary.invite.buttonAcceptInvitation}
            </LoadingButton>
            : undefined
        }
      />
      <FormDivider/>
      <Box
        sx={(theme) => ({
          textAlign: 'center',
          '& svg': {
            width: 24,
            height: 24,
          },
          width: '100%',
        })}
      >
        {featureFlags.googleSSO && (
          <IconButton
            sx={{
              border: '1px solid #E8E8E8',
              width: '100%',
              borderRadius: '8px',
              gap: 1.4,
              height: 48,
            }}
            onClick={() => onSSOLogin('google')}
          >
            <GoogleLogo/>
            <Typography variant="buttonText">
              {dictionary.signUpWithGoogle}
            </Typography>
          </IconButton>
        )}
        {featureFlags.microsoftSSO && (
          <IconButton
            sx={{
              border: '1px solid #E8E8E8',
              width: '100%',
              borderRadius: '8px',
              gap: 1.4,
              height: 48,
            }}
            onClick={() => onSSOLogin('microsoft')}
          >
            <MicrosoftLogo/>
            <Typography variant="buttonText">
              {dictionary.signUpWithMicrosoft}
            </Typography>
          </IconButton>
        )}
      </Box>
      <SwitchLoggedOutPage
        text={dictionary.auth.alreadyHaveAccount}
        linkText={dictionary.auth.buttonSignIn}
        linkTo={routes.login}
      />
    </LoggedOutActionsLayout>
  );
};
