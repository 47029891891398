import React, { FC, useState } from 'react';
import { Autocomplete, AutocompleteProps, TextField, TextFieldProps, createFilterOptions } from '@mui/material';
import { useEmissionFactors } from 'shared/hooks/useEmissionFactors';


export type EmissionFactorsAutocompleteProps = Partial<AutocompleteProps<any, false, false, false>> & {
  TextFieldProps?: TextFieldProps;
  manualInputId?: number;
  handleOnChange: (value: any) => void;
}

export const EmissionFactorsAutocomplete: FC<EmissionFactorsAutocompleteProps> = (
  { TextFieldProps, manualInputId, defaultValue, handleOnChange: onChange, ...props },
) => {

  const [search, setSearch] = useState('');
  const [value, setValue] = useState(defaultValue);
  const { emissionFactors, status } = useEmissionFactors({ manualInputId, search, limit: 2000 });
  const filterOptions = createFilterOptions({
    limit: 2000,
  });

  const handleSetSearch = (e: any, value: any) => {
    if (!e) {
      return;
    }
    setSearch(value);
  };

  const handleOnChange = (e: any, value: any) => {
    setValue(value);
    onChange(value);
    setSearch('');
  };

  return (
    <Autocomplete<any, false, false, false>
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField {...params} {...TextFieldProps}/>
      )}
      value={value}
      getOptionLabel={(option) => option.title}
      onInputChange={(e, value) => handleSetSearch(e, value)}
      options={emissionFactors}
      onChange={handleOnChange}
      loading={status === 'loading'}
      componentsProps={{
        popper: { sx: { minWidth: 280 }, placement: 'bottom-start' },
      }}
      {...props}
    />
  );
};
