import TagManager from 'react-gtm-module';
import { User } from 'clients/users/userClient.types';


const gtmId = process.env.REACT_APP_GREENCAST_GOOGLE_TAG_MANAGER_ID;

const tagManagerArgs = {
  gtmId: gtmId!,
};

TagManager.initialize(tagManagerArgs);

export const trackPageView = () => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: 'pageview',
    page: {
      url: window.location.pathname,
      title: document.title,
    },
    gtm: {
      id: gtmId,
    },
  });
};

export const trackUserData = (user: User) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: 'userData',
    userData: { ...user },
    gtm: {
      id: gtmId,
    },
  });
};
