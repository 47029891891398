import { useCallback, useContext } from 'react';
import { trackSignUp } from 'tracking/tracking';
import { LocalizationContext, LayoutContext, UserContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';


export const useSignup = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { login, ssoLogin, logout } = useContext(UserContext);
  const { genericError, validationError } = useContext(LayoutContext);

  const signupAndLogin = useCallback(async (formData: any) => {
    try {
      await usersClient.signupWithEmailAndPassword(formData);
      trackSignUp();
      await login(formData?.email, formData?.password);
    } catch (e: any) {
      if (e.response?.status === 400) {
        validationError(dictionary.userWithThisEmailAlreadyExists);
      } else {
        genericError();
      }
    }
  }, [login, genericError, dictionary, validationError]);

  const signupWithSSO = useCallback(async (provider: string, invitationToken: string) => {
    const result = await ssoLogin(provider);
    if(result.success !== true) {
      return Promise.reject({ type: 'error', message: result.message });
    } else {
      try {
        await usersClient.signupWithSSO(invitationToken);
        trackSignUp();
      } catch (e: any) {
        if (e.response?.status === 400) {
          validationError(dictionary.userWithThisEmailAlreadyExists);
          logout();
        } else {
          genericError();
          logout();
        }
      }
    }
  }, [ssoLogin, genericError, logout, dictionary, validationError]);

  return {
    signupAndLogin,
    signupWithSSO,
  };
};
