import { FC, Fragment, useContext } from 'react';
import { Circle } from '@mui/icons-material';
import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { format } from 'date-fns';

import { LocalizationContext } from 'contexts';
import { Emissions } from 'shared/components/layout/Emissions/Emissions';
import { DiferencePercentageParagraph } from 'shared/components/layout/Emissions/DiferencePercentageParagraph';


const cardSxProps = { boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.12)', borderRadius: '4px' };
const stackSxProps = { p: '10px 10px 10px 10px', fontSize: '11px' };

const NewChartTooltip: FC<any> = (props) => {
  const {
    tooltipRef,
    active,
    payload,
    highlightedDimension,
    dimensionsMap,
    metricsMap,
  } = props;
  const dateDimensionKey = Object.keys(dimensionsMap).find(
    (key: any) => dimensionsMap?.[key]?.type === 'date',
  );

  const isHovering = highlightedDimension;
  const { dictionary } = useContext(LocalizationContext);

  if (!isHovering || !active || !payload) return null;

  return (
    <Card sx={cardSxProps}>
      <Stack
        width={219}
        gap={0.7}
        alignItems="center"
        direction="row"
        sx={stackSxProps}
      >
        {payload
          .sort((prev: any, next: any) => (prev?.name > next?.name ? 1 : -1)).map((props: any, i: number) => {
            const { dataKey, value, payload } = props;
            const metric = metricsMap[tooltipRef.current];
            const currentDataPayloadDate = payload[dateDimensionKey as keyof typeof payload];
            const prevDataPayloadDate = payload.prevDataDate;
            const previousYearValue = payload.prevYear[tooltipRef.current];

            if (dataKey === highlightedDimension) {
              let formattedDate = dateDimensionKey?.includes('month') ? format(new Date(currentDataPayloadDate), 'MMMM yyyy') : currentDataPayloadDate;
              if (
                typeof highlightedDimension === 'string' &&
                highlightedDimension.includes('currentYear') && previousYearValue
              ) {
                return (
                  <Fragment key={`${dataKey}-${value}`}>
                    <Circle sx={{ color: metric?.color, height: 16, width: 16 }}/>
                    <Stack direction="column" gap={0.4}>
                      <Typography lineHeight="14px" fontSize="11px">
                        {dictionary.tooltips.emissionsDatePeriod(metric?.label, formattedDate)}
                        <b>
                          <Emissions kg={value}/>
                        </b>
                      </Typography>
                      <DiferencePercentageParagraph
                        currentYearValue={value}
                        lastYearValue={previousYearValue}
                      />
                    </Stack>
                  </Fragment>
                );
              }
              if (typeof highlightedDimension === 'string' && (highlightedDimension.includes('prevYear') || highlightedDimension.includes('negativeValues') || highlightedDimension.includes('prevYearNegativeValues'))) {
                formattedDate = dateDimensionKey?.includes('month') ? format(new Date(highlightedDimension.includes('negativeValues') ? currentDataPayloadDate : prevDataPayloadDate), 'MMMM yyyy') : highlightedDimension.includes('negativeValues') ? currentDataPayloadDate : prevDataPayloadDate;
              }
              return (
                <Fragment key={`${dataKey}-${value}`}>
                  <Circle sx={{ color: metric?.color, height: 16, width: 16 }}/>
                  <Stack key={`${dataKey}-${value}`} direction="column">
                    <Typography lineHeight="14px" fontSize="11px">
                      {dictionary.tooltips.emissionsDatePeriod(metric?.label, formattedDate)}
                      <b>
                        <Emissions kg={value}/>
                      </b>
                    </Typography>
                  </Stack>
                </Fragment>
              );
            }
            return null;
          })}
      </Stack>
    </Card>
  );
};

export default NewChartTooltip;
