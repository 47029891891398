import { getFormDataFromObject } from 'helpers/form';
import { camelizeObject } from 'helpers/object';
import {
  User,
  UserApi,
  UserCrud,
  UserSignupWithEmailAndPassword,
  UserSignupWithEmailAndPasswordApi,
  SimpleUser,
  SimpleUserApi,
  UserCrudApi,
  UserInvite,
  UserInviteApi, PostUserInvite, UserInvitePartial,
  Workspace,
  WorkspaceApi,
  WorkspaceTeamApi,
  WorkspaceTeam,
} from './userClient.types';


export const mapSimpleUserData = (data: SimpleUserApi): SimpleUser => ({
  id: data.id,
  displayName: `${data.first_name} ${data.last_name}`,
  firstName: data.first_name,
  lastName: data.last_name,
  authGroup: data.auth_group,
  email: data.email,
  jobTitle: data.job_title,
  type: data.type || 'admin',
  profilePicture: data.profile_picture || undefined,
  activeUserCompanyId: data?.active_user_company_id,
});

export const mapUserData = (data: UserApi): User => ({
  id: data.id,
  firebaseUid: data.firebase_uid,
  displayName: `${data.first_name} ${data.last_name}`,
  firstName: data.first_name,
  lastName: data.last_name,
  email: data.email,
  authGroup: data.auth_group,
  company: data.company ? camelizeObject(data.company) : null,
  jobTitle: data.job_title,
  type: data.type || 'admin',
  profilePicture: data.profile_picture || undefined,
  activeUserCompanyId: data?.active_user_company_id,
});

export const mapSignUpData = (data: UserSignupWithEmailAndPassword): UserSignupWithEmailAndPasswordApi => ({
  first_name: data.firstName,
  last_name: data.lastName,
  email: data.email,
  company: data.company || undefined,
  job_title: data.jobTitle || undefined,
  password: data.password,
  repeated_password: data.repeatPassword,
  user_invitation_id: data.userInvitationId || undefined,
  auth_group_id: data.authGroupId,
});

export const mapUserCrudData = (data: UserCrud): FormData => {
  const apiData: UserCrudApi = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    job_title: data.jobTitle || null,
    company_id: data.company?.id,
    type: data.type,
    profile_picture: typeof data.profilePicture === 'string' ? undefined : data.profilePicture,
    auth_group: data.authGroupId,
  };

  const formData = getFormDataFromObject(apiData);

  return formData;
};

export const mapInviteData = (data: UserInviteApi): UserInvite => ({
  id: data.id,
  email: data.email,
  company: data.company ? camelizeObject(data.company) : undefined,
  authGroupId: data.auth_group_id,
  isExistingUser: data.is_existing_user,
});

export const mapPostInviteData = (data: Partial<UserInvitePartial>): PostUserInvite => ({
  email: data.email,
  auth_group: data.authGroup,
});

export const mapWorkspaceData = (data: WorkspaceApi): Workspace => ({
  id: data.workspace_id,
  logo: data.workspace_logo,
  membersCount: data.member_count,
  name: data.workspace_name,
});

export const mapWorkspaceTeamData = (data: WorkspaceTeamApi): WorkspaceTeam => ({
  id: data.id,
  displayName: `${data.user.first_name} ${data.user.last_name}`,
  firstName: data.user.first_name,
  lastName: data.user.last_name,
  jobTitle: data.user.job_title,
  email: data.user.email,
  authGroup: data.auth_group,
  authGroupName: data.auth_group_name,
});