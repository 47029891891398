// ** MUI Imports
import { Theme } from '@mui/material/styles';
import { primary, primaryLight } from 'shared/styles/theme/palette';


const Menu = (theme: Theme) => {
  return {
    MuiMenu: {
      styleOverrides: {
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          borderRadius: theme.shape.borderRadius,
          '& + &': {
            marginTop: theme.spacing(0.5),
          },
          '&.Mui-selected': {
            backgroundColor: primaryLight,
            color: primary,
          },
          '& .MuiListItemIcon-root': {
            minWidth: theme.spacing(2),
          },
        },
      },
    },
  };
};

export default Menu;
