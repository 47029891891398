import React from 'react';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';


interface LoadingModalProps {
  open: boolean | undefined;
}

const modalSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backdropFilter: 'blur(2px)',
  zIndex: 99,
};

const spinnerSx = {};

const LoadingModal: React.FC<LoadingModalProps> = ({ open }) => {
  if (!open) return null;
  return (
    <Box sx={modalSx}>
      <CircularProgress sx={spinnerSx}/>
    </Box>
  );
};

export default LoadingModal;
