import React, { FC, useContext, useMemo } from 'react';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import {
  Bar,
  ComposedChart,
  Tooltip as ReTooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
} from 'recharts';
import { format, set } from 'date-fns';

import { pastelPalette } from 'shared/styles/charts';
import { scopeColors } from 'shared/styles/muiTheme';
import { Chart } from 'modules/Charts/components/MultiAreaChart/MultiAreaChart.types';
import {
  getBarOpacity,
  mapData,
} from 'modules/Charts/components/MultiAreaChart/MultiAreaChartHelpers';
import { Tooltip } from 'modules/Charts/components/ChartTooltip/ChartTooltip';
import { textTertiary } from 'shared/styles/theme/palette';

import { LayoutContext } from 'contexts';
import { infiniteArrayIterator } from 'helpers/array';


type Props = {
  data: Chart;
  yAxisLabelFormatter?: (value: any, index: number) => string;
  xAxisLabelFormatter?: (value: any, index: number) => string;
  highlightedDimension?: any;
  setHighlightedDimension?: any;
  showAxisX?: boolean;
  showAxisY?: boolean;
  showChart?: boolean;
  showGrid?: boolean;
}


export const ReductionStrategyChart: FC<Props> = ({ showAxisX = true, showAxisY = true, showChart = true, showGrid = true, ...props }) => {

  const theme = useTheme();
  const { emissionFactorCategoriesColors } = useContext(LayoutContext);
  const {
    data: { values = [], metrics = [], dimensions = [] } = {},
    highlightedDimension,
    setHighlightedDimension = (value: any) => value,
    yAxisLabelFormatter = (value: any) => {
      if (value >= 1000) {
        return `${(value / 1000).toFixed(0)} t`;
      }
      return `${value.toFixed(0)} kg`;
    },
    xAxisLabelFormatter = (value: any) => {
      try {
        const [year, month = 1, day = 1] = value.split('-');
        if (!isNaN(year)) {
          const date = set(new Date(), { year: parseInt(year), month: parseInt(month), date: parseInt(day) - 1 });
          return format(date, ' yyyy ');
        }
      } catch(e) {
        console.error(e);
      }
      return value;
    },
  } = props || {};

  const { mode } = useContext(LayoutContext);
  const colors = useMemo(() => infiniteArrayIterator(pastelPalette), []);

  const aggregateBy = useMemo(() => {
    return dimensions.find((dimension: any) => !!dimension?.aggregation?.key);
  }, [dimensions]);

  const mappedMetrics = useMemo(() => {
    let _metrics = metrics;

    if (aggregateBy) {
      _metrics = [
        // @ts-ignore
        ...[...new Set(values.map(({ dimensions }: any) => dimensions[aggregateBy.aggregation.key]))].map((key: any) => ({
          key: key,
          label: `${metrics[0].label} ${key}`,
          // @ts-ignore
          chart: aggregateBy.aggregation.chart,
          opacity: highlightedDimension ? (highlightedDimension === key ? 1 : 0.3) : 1,
          ...(metrics[0].stack_id && { stack_id: metrics[0].stack_id }),
        })),
      ];
    }

    return _metrics.map((metric: any) => {
      const mappedMetric = {
        color: scopeColors?.[metric?.key as keyof typeof scopeColors] || emissionFactorCategoriesColors?.[metric?.key],
        ...metric,
      };
      if(!mappedMetric?.color) {
        mappedMetric.color = _metrics?.length > 1 ? colors.next().value : theme.palette.primary.main;
      }
      return mappedMetric;
    }).sort((a: any, b: any) => parseInt(a.key) > parseInt(b.key) ? -1 : 1 );
  }, [metrics, colors, aggregateBy, values, highlightedDimension, emissionFactorCategoriesColors, theme]);

  const dimensionsMap = useMemo(() => {
    return dimensions.reduce((map: any, dimension: any) => { map[dimension.key] = dimension; return map; }, {} as any);
  }, [dimensions]);

  const metricsMap = useMemo(() => {
    return mappedMetrics.reduce((map: any, metric: any) => { map[metric.key] = metric; return map; }, {} as any);
  }, [mappedMetrics]);

  const { xAxis, yAxis } = useMemo(() => {
      type axisProps = {
          key: string;
          type: 'category' | 'number'
      }
      let xAxis: axisProps = { key: dimensions?.[0]?.key, type: 'category' };
      let yAxis: undefined | axisProps = undefined;
      Object.keys(dimensionsMap).forEach(key => {
        if(dimensionsMap[key]?.type === 'date') {
          xAxis = { key, type: 'category' };
        }
        if(dimensionsMap[key]?.axis) {
          if(dimensionsMap[key]?.axis === 'x') {
            xAxis = { key, type: 'category' };
          }
          if(dimensionsMap[key]?.axis === 'y') {
            yAxis = { key, type: 'category' };
          }
        }
      });
      Object.keys(metricsMap).forEach(key => {
        if(metricsMap[key]?.axis) {
          if(metricsMap[key]?.axis === 'x') {
            xAxis = { key, type: 'number' };
          }
          if(metricsMap[key]?.axis === 'y') {
            yAxis = { key, type: 'number' };
          }
        }
      });
      return { xAxis, yAxis };
  }, [dimensionsMap, metricsMap, dimensions]);

  const mappedData = useMemo(() => (
    mapData({ values: values, aggregateBy, metrics })
  ), [aggregateBy, metrics, values]);

  return (
    <Box key={mode} sx={{ position: 'relative', height: '100%', width: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          style={{ userSelect: 'none' }}
          data={mappedData}
        >
          {showGrid && <CartesianGrid horizontal={true} vertical={false} strokeOpacity={0.35}/>}
          {showAxisX && (
            <XAxis
              dataKey={xAxis.key}
              type={xAxis.type as 'number' | 'category'}
              allowDataOverflow={true}
              stroke="#E8E8E8"
              tick={{ fill: textTertiary, fontSize: 12 }}
              tickLine={false}
              interval={mappedData.length > 12 ? 'preserveStartEnd' : 0}
              tickFormatter={xAxisLabelFormatter}
            />
          )}
          <ReTooltip
            cursor={false}
            content={(props: any) => (
              <Tooltip
                {...props}
                dimensionsMap={dimensionsMap}
                metricsMap={metricsMap}
                highlightedDimension={highlightedDimension}
                xAxisLabelFormatter={xAxisLabelFormatter}
              />
            )}
          />
          {showChart && mappedMetrics.map(({ chart, key, color, stack_id, opacity: _opacity }: any, i: number) => (
            <Bar
              key={i}
              yAxisId="1"
              dataKey={key}
              stroke="#ffffff"
              strokeWidth={2}
              fillOpacity={getBarOpacity(highlightedDimension, key) || _opacity}
              fill={!stack_id ? `url(#color${i})` : color}
              radius={[4, 4, 4, 4]}
              animationDuration={300}
              stackId={stack_id}
              onMouseEnter={() => {setHighlightedDimension(key); }}
              onMouseLeave={() => {setHighlightedDimension(undefined); }}
            >
              {
                mappedData.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} className={`bar-${index}`}/>
                ))
              }
            </Bar>
          ))}
          {showAxisY && (
            <YAxis
              yAxisId="1"
              orientation="right"
              style={{ pointerEvents: 'none' }}
              tick={{ fill: textTertiary, fontSize: 12 }}
              tickFormatter={yAxisLabelFormatter}
              tickLine={false}
              axisLine={false}
              // mirror
              enableBackground="true"
              {...(yAxis ? {
                //@ts-ignore
                dataKey: yAxis.key,
                //@ts-ignore
                type: yAxis.type as 'number' | 'category',
              } : { })}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
