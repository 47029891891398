import { AxiosResponse } from 'axios';

import { Emissions } from 'modules/Emissions/emissions';
import { PaginatedResults } from 'types/Query';
import { camelizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';


const emissionsApiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getElasticEmissionFactorCategories = (params: Emissions.Categories.GetElasticEmissionFactorCategoryListParams): Promise<AxiosResponse<PaginatedResults<Emissions.Categories.ElasticEmissionFactorCategory>>> => {
  return request({
    options: {
      url: `${emissionsApiBaseUrl}/emission-factor-categories/search/`,
      method: 'GET',
      params: {
        ordering: params.ordering,
        search: params.search || undefined,
        parent: params.parent,
        is_classification_category: params.isClassificationCategory,
        parent__isnull: params.isParent,
        limit: params.limit,
        offset: params.offset,
      },
    },
  }).then((data: AxiosResponse<PaginatedResults<Emissions.Categories.ElasticEmissionFactorCategoryApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(camelizeObject<Emissions.Categories.ElasticEmissionFactorCategory>),
    },
  }));
};

export const emissionFactorsClient = {
  getElasticEmissionFactorCategories,
};
