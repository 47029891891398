import { FC, PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react';
import Pusher, { Channel } from 'pusher-js';
import { UserContext } from 'contexts/UserContext/UserContext';
import { firebaseClient } from 'clients/firebaseClient';
import { BindEventProps, UnbindEventProps, WebsocketContextProps } from './types';
import { pusherGeneralChannel } from './constants';


const defaultContext = {
  bindEvent: () => console.error('WebsocketContextProvider not initialized'),
  channel: undefined,
  socketId: undefined,
  unbindEvent: () => console.error('WebsocketContextProvider not initialized'),
  websocket: undefined,
};

export const WebsocketContext = createContext<WebsocketContextProps>(defaultContext);

export const useWebsocket = () => useContext(WebsocketContext);

export const WebsocketContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const websocketRef = useRef<Pusher>();
  const websocket = websocketRef.current;

  const { user } = useContext(UserContext);
  const [channel, setChannel] = useState<Channel | undefined>(undefined);
  const [socketId, setSocketId] = useState<string | undefined>(undefined);
  const [userAuthenticationToken, setUserAuthenticationToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (user) {
      firebaseClient.getAuth().currentUser?.getIdToken().then(setUserAuthenticationToken);
    }
  }, [user]);

  useEffect(() => {
    const pusherKey = process.env.REACT_APP_WEBSOCKET_APP_KEY;
    const websocketBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;
    if (!pusherKey || !userAuthenticationToken) return;

    const initializeWebsocket = () => {
      if (!websocketRef.current) {
        websocketRef.current = new Pusher(pusherKey, {
          cluster: 'eu',
          channelAuthorization: {
            endpoint: `${websocketBaseUrl}/websocket/channel_auth/`,
            transport: 'ajax',
            headers: {
              'Authorization': 'Bearer ' + userAuthenticationToken,
            },
          },
        });
      }
    };

    initializeWebsocket();
  }, [userAuthenticationToken, websocketRef]);

  useEffect(() => {
    if (!websocket || !user) return;
    const channel = websocket.subscribe(pusherGeneralChannel(user.id));
    setChannel(channel);
    setSocketId(websocket.connection.socket_id);
    return () => {
      channel?.unsubscribe();
      setChannel(undefined);
      setSocketId(undefined);
    };
  }, [user, websocket]);

  const bindEvent = (eventName: BindEventProps['eventName'], callback: BindEventProps['callback'], customChannel: BindEventProps['customChannel']) => {
    if (!channel && !customChannel) return;
    if (customChannel) {
      customChannel.bind(eventName, callback);
    } else if (channel) {
      channel.bind(eventName, callback);
    }
  };

  const unbindEvent = (eventName: UnbindEventProps['eventName'], customChannel: UnbindEventProps['customChannel']) => {
    if (customChannel) {
      customChannel.unbind(eventName);
    } else if (channel) {
      channel.unbind(eventName);
    } else {
      console.error('No channel to unbind event from');
    }
  };

  return (
    <WebsocketContext.Provider value={{
      bindEvent,
      channel,
      socketId,
      unbindEvent,
      websocket,
    }}>
      {children}
    </WebsocketContext.Provider>
  );
};
