import React, { useState, useMemo } from 'react';

import { Emissions } from 'shared/components/layout/Emissions/Emissions';


const ElementsSizes = {
  icon: 20,
  iconRect: 26,
  title: 14,
  subTitle: 12,
};

export const TreeMapChartContent = (props: any) => {
  const [hovered, setHovered] = useState(false);
  const { x, y, width, height, root } = props;

  const showLabel = useMemo(() => {
    const valueLength = (<Emissions kg={root.realValue} string showCo2={false}/>).toString().length * 8;
    const titleLength = (root?.name || '').length * 10;
    const minWidthInPixels = Math.max(titleLength, valueLength);
    return width >= minWidthInPixels && height > 100;
  }, [root.realValue, root?.name, width, height]);

  const showIcon = useMemo(() => {
    const iconPlusPadding = ElementsSizes.iconRect + 8;
    return width >= iconPlusPadding && height >= iconPlusPadding;
  }, [width, height]);

  const halfTextWithPaddingSize = (ElementsSizes.title + ElementsSizes.subTitle + 8) / 2;

  return (
    <g
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => props?.onClick && props.onClick(props)}
      style={{ cursor: props?.onClick ? 'pointer' : undefined }}
    >
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke="#ffffff"
        strokeWidth={6}
        fill={root?.color }
        opacity={hovered ? 0.9 : 1}
      />
      {(showIcon && root?.icon) && (
        <g>
          <rect
            fill="#fff"
            fillOpacity={0.3}
            rx={2}
            ry={2}
            width={ElementsSizes.iconRect}
            height={ElementsSizes.iconRect}
            x={x + (width - ElementsSizes.iconRect) / 2}
            y={y + (height - ElementsSizes.iconRect) / 2 - (showLabel ? halfTextWithPaddingSize : 0)}
          />
          <image
            href={root?.icon}
            width={ElementsSizes.icon}
            height={ElementsSizes.icon}
            x={x + (width - ElementsSizes.icon) / 2}
            y={y + (height - ElementsSizes.icon) / 2 - (showLabel ? halfTextWithPaddingSize : 0)}
          />
        </g>
      )}
      {showLabel && (
        <g>
          <text
            key="name"
            fontWeight="bold"
            textAnchor="middle"
            fill="#000"
            fontSize={ElementsSizes.title}
            strokeOpacity={0}
            x={x + width / 2}
            y={y + height / 2 + halfTextWithPaddingSize}
          >
            {root?.name}
          </text>
          <text
            key="co2"
            textAnchor="middle"
            fill="#000"
            fontSize={ElementsSizes.subTitle}
            strokeOpacity={0}
            x={x + width / 2}
            y={y + height / 2 + ElementsSizes.title + halfTextWithPaddingSize}
          >
            <Emissions kg={root.realValue} showCo2={false} string/>
          </text>
        </g>
      )}
    </g>
  );
};
