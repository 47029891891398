import { AxiosResponse } from 'axios';
import { Expenses } from 'modules/Expenses/expenses';
import { PaginatedResults } from 'types/Query';
import { camelizeObject, snakizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';


const apiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getChiftIntegrationOptions = (): Promise<AxiosResponse<Expenses.Integrations.Chift.Options>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/integration/chift/consumer-creation`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Expenses.Integrations.Chift.OptionsApi>) => {
    return {
      ...data,
      data: {
        ...camelizeObject(data.data),
      },
    };
  });
};

const getChiftIntegrationUrl = (
  data: Expenses.Integrations.Chift.SelectedData,
): Promise<AxiosResponse<Expenses.Integrations.Chift.Url>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/integration/chift/consumer-creation`,
      method: 'POST',
      data: snakizeObject(data),
    },
  }).then((data: AxiosResponse<Expenses.Integrations.Chift.UrlApi>) => {
    return {
      ...data,
      data: {
        ...camelizeObject(data.data),
      },
    };
  });
};

const getChiftIntegration = (
  awaitingIntegration: string,
): Promise<AxiosResponse<Expenses.Integrations.Integration>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/integrations/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<PaginatedResults<Expenses.Integrations.IntegrationApi>>) => {
    const integration = data.data.results.find((int) => int.name === awaitingIntegration);
    if ( !integration ) {
      throw Error('Not connected to Chift');
    }

    return {
      ...data,
      data: {
        ...camelizeObject(integration),
      },
    };
  });
};

const updateChiftIntegrationUrl = (
  integrationId: number,
): Promise<AxiosResponse<Expenses.Integrations.Chift.Url>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/integration/${integrationId}/chift/connection`,
      method: 'PATCH',
    },
  }).then((data: AxiosResponse<Expenses.Integrations.Chift.UrlApi>) => {
    return {
      ...data,
      data: {
        ...camelizeObject(data.data),
      },
    };
  });;
};

const disconnectChiftIntegration = (
  integrationId: number,
): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${apiBaseUrl}/integration/${integrationId}/chift/connection`,
      method: 'DELETE',
    },
  });
};

export const chiftIntegrationsClient = {
  getChiftIntegrationOptions,
  getChiftIntegrationUrl,
  getChiftIntegration,
  disconnectChiftIntegration,
  updateChiftIntegrationUrl,
};
