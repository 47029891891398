import { FC, Fragment, useContext } from 'react';
import { Divider, Grid } from '@mui/material';

import { LayoutContext, LocalizationContext } from 'contexts';
import { ActivityBasedTransactionStatus } from 'clients/transactions/transactionClient.types';
import { useActivityBasedCategories } from 'shared/hooks/useActivityBasedSubCategories';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';

import { Filters } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { TransactionSection } from './TransactionSection';
import { TransactionGroup } from './TransactionGroup';


export const OtherABTransactions: FC<{ filters: Partial<Filters> }> = ({
  filters: {
    tags,
  },
}) => {

  const { activityBasedSubCategories, status, error } = useActivityBasedCategories({ mandatory: false });
  const { genericError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);

  if (status === 'loading' || !activityBasedSubCategories) {
    return <BodyLoading height="100vh"/>;
  }

  if (error) {
    genericError();
    return null;
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TransactionSection title={dictionary.transaction.transactionList.openTransactions} defaultIsExpanded>
          {activityBasedSubCategories.open.subCategories.map((subCategory, index: number) => (
            <Fragment key={subCategory.id}>
              <TransactionGroup
                title={subCategory.title}
                description={subCategory.description}
                status={ActivityBasedTransactionStatus.Open}
                category={subCategory}
                tags={tags}
              />
              {(index !== activityBasedSubCategories.open.subCategories.length - 1) && <Divider flexItem sx={{ mt: 5, mb: 5 }}/>}
            </Fragment>
          ))}
        </TransactionSection>
      </Grid>
      <Grid item>
        <TransactionSection
          title={dictionary.transaction.transactionList.completedTransactions}
          headerText={dictionary.transaction.transactionList.numberOfActivityBasedTransactions(ActivityBasedTransactionStatus.Completed, activityBasedSubCategories.completed.count)}
        >
          {activityBasedSubCategories.completed.subCategories.map((subCategory, index: number) => (
            <Fragment key={subCategory.id}>
              <TransactionGroup
                title={subCategory.title}
                description={subCategory.description}
                status={ActivityBasedTransactionStatus.Completed}
                category={subCategory}
                tags={tags}
              />
              {(index !== activityBasedSubCategories.completed.subCategories.length - 1) && <Divider flexItem sx={{ mt: 5, mb: 5 }}/>}
            </Fragment>
          ))}
        </TransactionSection>
      </Grid>
      <Grid item>
        <TransactionSection
          title={dictionary.transaction.transactionList.skippedTransactions}
          headerText={dictionary.transaction.transactionList.numberOfActivityBasedTransactions(ActivityBasedTransactionStatus.Skipped, activityBasedSubCategories.skipped.count)}
        >
          {activityBasedSubCategories.skipped.subCategories.map((subCategory, index: number) => (
            <Fragment key={subCategory.id}>
              <TransactionGroup
                title={subCategory.title}
                description={subCategory.description}
                status={ActivityBasedTransactionStatus.Skipped}
                category={subCategory}
                tags={tags}
              />
              {(index !== activityBasedSubCategories.skipped.subCategories.length - 1) && <Divider flexItem sx={{ mt: 5, mb: 5 }}/>}
            </Fragment>
          ))}
        </TransactionSection>
      </Grid>
    </Grid>
  );
};
