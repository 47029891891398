import React, { FC } from 'react';
import { Grid, useTheme } from '@mui/material';

import { primaryLight } from 'shared/styles/muiTheme';

import { menuItems } from './data';


type Props = {
  selectedCategory?: string,
  onSelect: (category: string) => void,
};

export const ALL = 'all';

const CategoryButtons: FC<Props> = ({ onSelect, selectedCategory = ALL }: any) => {
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <Grid container direction="row">
        {[...menuItems, ALL].map((menuItem: string, index: number) => {
          return (
            <button
              key={index}
              style={{
                backgroundColor: menuItem === selectedCategory ? primaryLight : '#F2F2F2',
                border: '1px solid #D1D1D1',
                borderRadius: '4px',
                borderColor: menuItem === selectedCategory ? theme.palette.primary.main : '#D1D1D1',
                color: menuItem === selectedCategory ? theme.palette.primary.main : '#121212',
                padding: '8px 16px',
                marginRight: '12px',
                marginBottom: '12px',
                cursor: 'pointer',
              }}
              onClick={() => onSelect(menuItem)}
            >
              {menuItem}
            </button>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default CategoryButtons;
