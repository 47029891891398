import React from 'react';
import { Box } from '@mui/material';
import { EmissionFactorCategory } from 'clients/emissionFactors/emissionFactorsClient.types';
import { MappedParentPieChartData } from 'views/LoggedIn/Measurements/types';
import { CategoryListParent } from './CategoryListParent';
import { CategoryListChild } from './CategoryListChild';


export const CategoriesList = (props: CategoriesListProps) => {
  const {
    mappedParentCategoryChartData,
    emissionFactorCategory,
    mappedChildCategoryChartData,
    onCategoryChange,
  } = props;

  return (
    <Box className="_d-grid">
      {mappedParentCategoryChartData.map((parent, i) => {
        const isChildSelected = emissionFactorCategory?.parent?.slug === parent.slug;
        const children = mappedChildCategoryChartData.filter((child: any) => child.parent.slug === parent.slug);

        return (
          <Box
            key={parent.slug}
            className="_d-grid"
          >
            <CategoryListParent
              icon={parent.icon}
              name={parent.name}
              color={parent.fill}
              totalCo2={parent.value}
              co2Percentage={parent.co2Percentage}
              onClick={() => onCategoryChange(
                parent.name === emissionFactorCategory?.title ? undefined : parent.name,
              )}
              selectedCategoryName={emissionFactorCategory?.title}
              isChildSelected={isChildSelected}
              isHaveChildren={children.length}
            />

            {(emissionFactorCategory?.title === parent.name || isChildSelected) &&
               children.map((child: any) =>
                 <CategoryListChild
                   key={child.name}
                   icon={child.icon}
                   name={child.name}
                   color={child.fill}
                   totalCo2={child.value}
                   co2Percentage={child.co2Percentage}
                   onClick={() => onCategoryChange(
                     child.name === emissionFactorCategory?.title ? undefined : child.name,
                   )}
                   selectedCategoryName={emissionFactorCategory?.title}
                 />,
               )}
          </Box>
        );})}
    </Box>
  );

};

interface CategoriesListProps {
    mappedParentCategoryChartData: MappedParentPieChartData;
    emissionFactorCategory?: EmissionFactorCategory;
    mappedChildCategoryChartData: any;
    onCategoryChange: (name?: string) => void;
}