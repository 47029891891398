import React, { FC, useContext } from 'react';
import { LocalizationContext } from 'contexts';
import { CompanyTag } from 'clients/companyTags/companyTagClient.types';
import { useDebouncedState } from 'shared/hooks/useDebouncedState';
import { useCompanyTags } from 'shared/hooks/useCompanyTags';
import { SelectWithSearch, SelectWithSearchOption } from 'shared/components/form/SelectWithSearch/SelectWithSearch';


interface Props {
    value: CompanyTag[];
    onChange: (value: CompanyTag[]) => void;
}

export const CompanyTagAutocomplete: FC<Props> = ({
  value,
  onChange,
}) => {
  const { dictionary } = useContext(LocalizationContext);
  const [, setSearch, debouncedSearch, isDebouncing] = useDebouncedState('');
  const { companyTags = [], status } = useCompanyTags({ search: debouncedSearch });

  const companyTagsWithNoTags = React.useMemo<SelectWithSearchOption[]>(() => (
    [{ id: 'null', name: dictionary.filterSection.companyTagsLabels.emptyOptionLabel }, ...companyTags]
  ), [companyTags, dictionary]);

  return (
    <SelectWithSearch
      options={companyTagsWithNoTags}
      label={dictionary.filterSection.companyTagsLabels.autocompleteLabel}
      value={value}
      onChange={onChange}
      onInputChange={setSearch}
      multiple
      isLoading={isDebouncing || status === 'loading'}
      placeholder={dictionary.filterSection.searchFor(dictionary.filterSection.companyTagsLabels.autocompleteSearchLabel)}
    />
  );
};