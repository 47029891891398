import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { chiftIntegrationsClient } from 'modules/Expenses/client/chiftIntegrationClient';
import { cacheKeys } from 'config';
import { LayoutContext } from 'contexts';


type Options = {
  enabled: boolean;
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useChiftIntegrations = (
  awaitingIntegration?: string, options: Partial<Options> = defaultOptions,
) => {

  const { genericError } = React.useContext(LayoutContext);
  const queryClient = useQueryClient();

  const {
    data: { data: chiftIntegrationOptionsData } = {},
    isLoading: isLoadingOptions,
  } = useQuery(
    [cacheKeys.integrations.getChiftIntegrationOptions],
    chiftIntegrationsClient.getChiftIntegrationOptions,
    {
      enabled: options.enabled,
    },
  );

  const onSuccessfulIntegrationMutation = React.useCallback(async () => {
    queryClient.invalidateQueries(cacheKeys.integrations.getIntegrations);
    queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
  }, [queryClient]);

  const disconnectChiftIntegration = useMutation(chiftIntegrationsClient.disconnectChiftIntegration, {
    mutationKey: cacheKeys.integrations.disconnectChiftIntegration,
    onSuccess: onSuccessfulIntegrationMutation,
    onError: genericError,
  });

  return {
    chiftIntegrationOptions: chiftIntegrationOptionsData || { activeIntegrations: [], inactiveIntegrations: [] },
    isLoadingOptions,
    disconnectChiftIntegration: disconnectChiftIntegration.mutateAsync,
    isDisconnectingChiftIntegration: disconnectChiftIntegration.isLoading,
  };
};
