import { FC, MouseEvent, useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Avatar,
  Box,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { backgroundDark, greyTextColor } from 'shared/styles/muiTheme';

import { EllipsisVerticalIcon } from 'shared/icons/EllipsisVerticalIcon';
import { cacheKeys, routes } from 'config';
import { DialogContext, LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { AuthGroup, WorkspaceTeam } from 'clients/users/userClient.types';
import { usersClient } from 'clients/users/usersClient';
import { useGroups } from 'shared/hooks/useGroups';
import { DropdownMenu } from 'shared/components/DropdownMenu/DropdownMenu';
import { DropdownMenuItems, DropdownMenuItemsProps } from 'shared/components/DropdownMenu/DropdownMenuItems';


export const UserRow: FC<WorkspaceTeam & { hasEditPermission: boolean, hasDeletePermission: boolean}> = (props) => {
  const {
    authGroupName,
    displayName,
    email,
    firstName,
    id: userWorkspaceId,
    jobTitle,
    lastName,
    profilePicture,
    hasEditPermission,
    hasDeletePermission,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { dictionary } = useContext(LocalizationContext);
  const { asyncConfirmation } = useContext(DialogContext);
  const { genericError, changesSaved } = useContext(LayoutContext);
  const { groups } = useGroups();
  const { isOwner, user: currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const isCurrentUser = useMemo(() => currentUser?.activeUserCompanyId === userWorkspaceId, [currentUser, userWorkspaceId]);
  const isCurrentOwner = isCurrentUser && isOwner;
  const showActions = useMemo(() => (hasEditPermission || hasDeletePermission || isCurrentUser) && !isCurrentOwner && authGroupName !== 'Owner', [authGroupName, hasDeletePermission, hasEditPermission, isCurrentOwner, isCurrentUser]);

  const changeAuthGroup = useMutation(usersClient.editUserRole, {
    mutationKey: cacheKeys.users.editUserRole,
    onSuccess: () => {
      const queriesToInvalidate = [
        queryClient.invalidateQueries(cacheKeys.users.getMe),
        queryClient.invalidateQueries(cacheKeys.workspaces.getWorkspace),
        queryClient.invalidateQueries(cacheKeys.users.getGroups),
      ];

      return Promise.all(queriesToInvalidate)
        .then(() => changesSaved())
        .catch(() => genericError());
    },
    onError: () => {
      genericError();
    },
  });

  const onAuthGroupChange = useCallback(async (authGroupId: AuthGroup['id']) => {
    // Only show if user will lose permissions - will need to be updated when owner role is introduced
    const isChangingOwner = authGroupId === 4 && isOwner;
    const userConfirmed = isCurrentUser || isChangingOwner
      ? await asyncConfirmation({
        cancelLabel: dictionary.goBack,
        confirmLabel: dictionary.user.edit.confirmChangeLabel,
        content: isChangingOwner ? dictionary.user.edit.editOwnerWarningContent : dictionary.user.edit.editWarningContent,
        isDelete: true,
        title: isChangingOwner ? dictionary.user.edit.editOwnerConfirmation : dictionary.user.edit.editConfirmation,
      })
      : true;

    if (!userConfirmed) {
      return false;
    }

    return changeAuthGroup.mutate({
      userWorkspaceId,
      authGroupId,
    });
  }, [asyncConfirmation, changeAuthGroup, dictionary, isCurrentUser, isOwner, userWorkspaceId]);

  const onRemove = useCallback(async () => {
    const userConfirmed = await asyncConfirmation({
      cancelLabel: dictionary.user.cancelLabel,
      confirmLabel: isCurrentUser ? dictionary.user.delete.confirmLeaveLabel : dictionary.user.delete.confirmRemoveLabel,
      content: isCurrentUser ? dictionary.user.delete.leaveContent : `${dictionary.user.delete.removeContentA} ${displayName} ${dictionary.user.delete.removeContentB}`,
      title: isCurrentUser ? dictionary.user.delete.leaveConfirmation : dictionary.user.delete.removeConfirmation,
      isDelete: true,
    });

    if (!userConfirmed) {
      return false;
    }

    usersClient.leaveWorkspace(userWorkspaceId)
      .then(async (message) => {
        if (isCurrentUser) {
          navigate(routes.leftWorkspace, { state: message });
        } else {
          await queryClient.invalidateQueries(cacheKeys.workspaces.getWorkspace);
          changesSaved();
        }
      })
      .catch(() => genericError());
  }, [asyncConfirmation, changesSaved, dictionary, displayName, genericError, isCurrentUser, navigate, queryClient, userWorkspaceId]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredGroups = useMemo(() => {
    const groupOrder = ['Owner', 'Admin', 'Editor', 'Viewer'];
    const filtered = groups.filter((group) => isOwner ? group.name !== authGroupName : group.name !== authGroupName && group.name !== 'Owner');
    const sorted = filtered.sort((a, b) => groupOrder.indexOf(a.name) - groupOrder.indexOf(b.name));

    return sorted;
  },
  [authGroupName, groups, isOwner]);

  const teamMemberOptions: DropdownMenuItemsProps[] = useMemo(
    () =>
      [
        {
          subItems: [
            ...filteredGroups.map((group) => {
              return {
                enabled: hasEditPermission,
                onClick: () => {
                  onAuthGroupChange(group.id);
                  handleClose();
                },
                title: `Change to ${group.name.toLowerCase()}`,
                tooltip: dictionary.tooltips.teamActions[group.name],
              };
            }),
            {
              enabled: isCurrentUser,
              onClick: () => {
                onRemove();
                handleClose();
              },
              title: <Typography color="error">{dictionary.user.delete.leave}</Typography>,
            },
            {
              enabled: hasDeletePermission && !isCurrentUser,
              onClick: () => {
                onRemove();
                handleClose();
              },
              title: <Typography color="error">{dictionary.user.delete.remove}</Typography>,
            },
          ],
        },
      ],
    [filteredGroups, isCurrentUser, dictionary, hasDeletePermission, hasEditPermission, onAuthGroupChange, onRemove],
  );

  return (
    <TableRow>
      <TableCell sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
        <Avatar
          alt={displayName}
          children={profilePicture ? undefined : `${firstName[0]}${lastName[0]}`}
          src={profilePicture}
          sx={{
            backgroundColor: profilePicture ? 'unset' : backgroundDark,
            fontSize: 8,
            fontWeight: 600,
            height: 24,
            width: 24,
          }}
        />
        <Box>
          <Typography variant="subtitle1">{displayName}</Typography>
          <Typography color={greyTextColor} variant="subtitle2">{email}</Typography>
        </Box>
      </TableCell>
      <TableCell>{jobTitle || dictionary.team.unknownLabel}</TableCell>
      <TableCell>
        <Box className="_d-flex-ali-center-md-gap">
          {changeAuthGroup.isLoading
            ? <CircularProgress size={16}/>
            : authGroupName || dictionary.team.unknownRole
          }
        </Box>
      </TableCell>
      <TableCell align="right">
        {showActions && (
          <>
            <IconButton onClick={handleClick} color="secondary" tabIndex={0}>
              <EllipsisVerticalIcon/>
            </IconButton>
            <DropdownMenu
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
              }}
              handleClose={handleClose}
              id="workspace-menu"
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              sx={{ p: 1 }}
            >
              {teamMemberOptions.map((props, index) => (
                <DropdownMenuItems key={index} {...props}/>
              ))}
            </DropdownMenu>
          </>
        )}
        {isCurrentOwner && (
          <Tooltip placement="left" title={dictionary.tooltips.leaveOwner}>
            <IconButton color="secondary" tabIndex={0}>
              <EllipsisVerticalIcon/>
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};
