export interface Chart {
  id: number;
  title: string;
  score: number;
  subtitle?: string;
  description?: string;
  chart: 'multi' | 'pie' | 'donut' | 'map' | 'table' | 'graph' | 'wordcloud';
  dimensions: Dimension[];
  metrics: Metric[];
  values: Value[];
  relationships?: Relationship[];
  created_at?: Date;
  updated_at?: Date;
  tags?: string[];
}

export interface Aggregation {
  key: string;
  chart: 'line' | 'bar' | 'area';
  label?: string;
}

export interface Dimension {
  key: string;
  label?: string;
  description?: string;
  stacked?: boolean;
  aggregation?: Aggregation;
  axis?: 'x' | 'y';
  available_aggregations?: Aggregation[];
  type?: string;
}

export interface Relationship {
  source_value_id: number | string;
  target_value_id: number | string;
  length?: number;
  thickness?: number;
  color?: string;
  dimensions?: {
    [key: string]: string | boolean;
  };
  metrics?: {
    [key: string]: number | number[];
  };
}

export interface Metric {
  key: string;
  label: string;
  chart?:
    | 'bar'
    | 'line'
    | 'line-dotted'
    | 'line-dashed'
    | 'area'
    | 'scatter'
    | 'pie'
    | 'donut'
    | 'marker'
    | 'heatpoint'
    | 'path'
    | 'shape'
    | 'network'
    | 'tree'
    | 'table';
  description?: string;
  stack_id?: number;
  min?: number;
  max?: number;
  axis?: 'x' | 'y';
  color?: string;
}

export type Connotation =
  | 'excellent'
  | 'positive'
  | 'concerning'
  | 'negative'
  | 'critical'
  | 'neutral';

export type Coordinates = {
  lat: number;
  lon: number;
};

export type MetricValue = number | number[] | Coordinates | Coordinates[];

export interface Value {
  id: string | number;
  connotation?: {
    dimensions?: {
      [key: string]: Connotation;
    };
    metrics?: {
      [key: string]: Connotation;
    };
  };
  description?: string;
  dimensions: {
    [key: string]: string | boolean;
  };
  metrics: {
    [key: string]: MetricValue;
  };
}

export enum ChartDataEnum {
  dateMonth = 'date_month',
  dateYear = 'date_year',
}

export interface ChartData {
  [ChartDataEnum.dateMonth]: string;
  [ChartDataEnum.dateYear]: string;
}

export interface MappedComparissonData {
  currentYear: string;
  [currentDataDate: string]: string;
  prevYear: string;
  prevDataDate: string;
}