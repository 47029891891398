// ** MUI Imports
import { common, grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';


const Switch = (theme: Theme) => {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 9,
        },
        thumb: {
          width: 16,
          height: 16,
        },
        switchBase: {
          padding: 11,
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
          },
          '&.Mui-checked': {
            color: common.white,
          },
        },
        track: {
          backgroundColor: grey['100'],
          borderRadius: 10,
          opacity: 1,
        },
      },
    },
  };
};

export default Switch;
