import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { backgroundMedium, outlineMedium } from 'shared/styles/muiTheme';
import { XMarkIcon } from 'shared/icons/XMarkIcon';


export const InsightCard = (props: InsightCardProps) => {
  const {
    image,
    title,
    text,
    actions = [],
    containerProps,
    canClose,
  } = props;

  const [isHidden, setIsHidden] = React.useState<boolean>(false);

  if (isHidden) {
    return null;
  }

  return (
    <Box
      {...containerProps}
      className={'_d-grid-ali-center ' + containerProps?.className}
      sx={{
        gridTemplateColumns: 'auto 1fr auto',
        background: backgroundMedium,
        border: `solid 1px ${outlineMedium}`,
        position: 'relative',
        borderRadius: 2,
        p: 3,
        ...containerProps?.sx,
      }}
    >
      {canClose && (
        <XMarkIcon
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            cursor: 'pointer',
          }}
          onClick={() => setIsHidden(true)}/>
      )}

      {typeof image === 'string' ? (<img src={image} alt="card-icon"/>) : (image)}

      <div>
        {title && <Typography variant="h4" mb={.5}>{title}</Typography>}
        <Typography
          variant="body1"
          sx={{ textWrap: 'balance' }}
          color="text.secondary"
        >
          {text}
        </Typography>
      </div>
      <Box className="_d-flex-md-gap">{actions}</Box>
    </Box>
  );
};

interface InsightCardProps {
    image?: string | ReactElement;
    title?: string;
    text: string | ReactElement;
    actions?: ReactElement;
    containerProps?: React.ComponentProps<typeof Box>;
    canClose?: boolean;
}