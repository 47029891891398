import React, { useContext } from 'react';
import { Box } from '@mui/system';
import { Skeleton, Tab, TablePagination, Tabs, Typography } from '@mui/material';
import { line } from 'shared/styles/muiTheme';
import { Marketplace } from 'modules/Marketplace/marketplace';
import { VendorTypes } from 'modules/Marketplace/helpers/general';
import { LocalizationContext } from 'contexts';
import { VendorCard } from './VendorCard';
import { VendorCardsSkeleton } from './VendorCardsSkeleton';
import { FilteredChip } from './FilterChips';
import { VendorsEmptyState } from './VendorsEmptyState';


export const VendorList = (props: VendorListProps) => {
  const {
    vendors,
    isLoading,
    vendorCount,
    page,
    pageSize,
    setPage,
    setPageSize,
    filteredChips = [],
    isEmptyState = false,
    vendorType,
    setVendorType,
  } = props;
  const { dictionary } = useContext(LocalizationContext);

  const vendorAmount = React.useMemo(() => isEmptyState ? 0 : vendorCount, [vendorCount, isEmptyState]);

  return (
    <Box
      className="_d-grid-no-gap"
      sx={{
        gridTemplateRows: 'auto minmax(0, 1fr)',
        borderRight: `solid 1px ${line}`,
      }}
    >
      <Tabs
        value={vendorType}
        onChange={(e, value) => setVendorType(value)}
        sx={{
          borderBottom: `solid 1px ${line}`,
          padding: '16px 0',
        }}
      >
        <Tab label={dictionary.marketplace.vendorList.typeSus} value={VendorTypes.SUS}/>
        <Tab label={dictionary.marketplace.vendorList.typeRed} value={VendorTypes.RED}/>
      </Tabs>
      <Box
        className="_d-grid-no-gap"
        sx={{
          gridTemplateRows: 'auto minmax(0, 1fr)',
          py: 2,
          overflow: 'auto',
        }}
      >
        <Box mb={5}>
          {!isLoading ? (
            <Typography variant="body1" color="text.secondary">
              {vendorAmount === 1 ? (
                dictionary.marketplace.vendorList.vendor
              ) : (
                `${vendorAmount} ${dictionary.marketplace.vendorList.vendors}`
              )}
            </Typography>
          ) : (
            <Skeleton variant="rounded" sx={{ width: '100px', height: 20, borderRadius: '100vmax' }}/>
          )}
          <Box className="_d-flex-sm-gap" mt={1.5}>
            {filteredChips.map(({ label, onDelete }, i) => (
              <FilteredChip key={i} label={label} onDelete={onDelete}/>
            ))}
          </Box>
        </Box>

        <Box
          className="_d-flex-dir-col-md-gap"
          sx={{ pr: 6, justifyContent: isEmptyState ? 'start' : 'space-between' }}
        >
          {!isLoading && isEmptyState && <VendorsEmptyState/>}

          <Box
            className="_d-grid-xl-gap"
            sx={{
              alignContent: 'start',
              pb: isEmptyState ? 2 : 0,
            }}
          >
            {isLoading ? (
              <VendorCardsSkeleton/>
            ) : (
              vendors.map((vendor) => (
                <VendorCard
                  key={vendor.id}
                  vendor={vendor}
                />
              ))
            )}
          </Box>

          {!isLoading && !isEmptyState &&
          <TablePagination
            component="div"
            count={vendorCount}
            page={page}
            onPageChange={(_e, page) => setPage(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value))}
            sx={{ flexShrink: 0 }}
          />
          }
        </Box>
      </Box>
    </Box>
  );
};

interface VendorListProps {
  vendors: Marketplace.Vendors.Vendor[];
  vendorCount: number;
  isLoading: boolean;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (size: number) => void;
  filteredChips?: {label: string, onDelete: () => void}[];
  isEmptyState?: boolean;
  vendorType: string;
  setVendorType: (vendorType: string) => void;
}