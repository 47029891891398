// ** Type Imports
import { PaletteMode } from '@mui/material';
import { common } from '@mui/material/colors';

// Primary colours
export const primary = '#1E78FF';
export const primaryLight = '#DFECFF';
export const primaryLightest = '#F0F9FF';
export const secondary = '#333333';
export const secondaryLight = '#E7EAEF';
export const tertiary = '#2F43FF';
export const tertiaryLight = '#E7E9FF';

//**** To remove
export const primaryAlt = '#A1DDDB';
export const textColor = '#333333';
export const subtextColor = '#64748B';
export const greyTextColor = '#757575';
export const lightBackground = '#D1D1D1';
export const defaultIconPink = '#FFDAF6';
export const tealColor = '#249683';
export const lightTealColor = '#2496830f';
export const redColor = '#F50F4A';
export const lightRedColor = '#ff00000a';
export const lightGreyColor = '#FAFAFA';
export const greyNavColor = '#9CA3AF';

// Text colours
export const textPrimary = '#26262B';
export const textSecondary = '#586478';
export const textTertiary = '#9CA3AF';
export const textPlaceholder = '#9CA3AF';
export const textDisabled = '#DADADA';
export const textContrast = '#FFFFFF';
export const textContrastDisabled = '#7B7B7B';

// Status colours
export const error = '#F50F4A';
export const errorLight = '#FDCFDB';
export const warning = '#E57F07';
export const warningLight = '#FFF5E9';
export const success = '#369987';
export const successLight = '#D7EBE7';
export const successLightest = '#EEFFFC';
export const info = '#1E78FF';
export const infoLight = '#DFECFF';
export const infoLightest = '#F1F7FF';


// Misc colours
export const dividerColor = '#E2E8F0';


declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['secondary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['secondary'];
  }
}

const DefaultPalette = (mode: PaletteMode) => {
  return {
    primary: {
      main: primary,
      light: primaryLight,
      contrastText: '#ececec',
    },
    secondary: {
      main: common.black,
      contrastText: common.white,
    },
    tertiary: {
      main: '#D1D5DB',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    error: {
      main: error,
      light: errorLight,
    },
    warning: {
      main: warning,
      light: warningLight,
    },
    success: {
      main: success,
      light: successLight,
    },
    info: {
      dark: info,
      main: infoLight,
      light: infoLightest,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
      tertiary: textTertiary,
      disabled: textDisabled,
    },
    selected: {
      main: primary,
      light: primaryLight,
      contrastText: '#ececec',
    },
  };
};

export default DefaultPalette;
