import ReactGA from 'react-ga';


export const initializeTracking = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || '', {
    debug: process.env.REACT_APP_GA_DEBUG === 'true',
  });
};

export const trackPageview = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const trackSignUp = () => {
  ReactGA.event({
    category: 'User',
    action: 'Created an Account',
  });
};

export const trackLogIn = () => {
  ReactGA.event({
    category: 'User',
    action: 'Logged in',
  });
};
