// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Tooltip = (theme: Theme) => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: '#1F2937',
          color: '#fff',
          fontSize: 12,
          fontWeight: 400,
          padding: theme.spacing(1),
          boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
          '& .MuiTooltip-arrow': {
            color: '#1F2937',
          },
          'ul': {
            margin: '4px 0 0',
            paddingInlineStart: '24px',
          },
        },
        popper: {
          boxShadow: 'none',
        },
      },
    },
  };
};

export default Tooltip;
