import { FunctionComponent, forwardRef } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps, SxProps } from '@mui/material';

import { primary } from 'shared/styles/muiTheme';
import CheckIcon from 'shared/components/layout/Icons/CheckIcon';

import { SquareBase } from './SquareBase';


interface ICheckBoxProps extends CheckboxProps {
  squareSxProps?: SxProps;
  checkIconSxProps?: SxProps;
  checkedSquareSxProps?: SxProps;
  muiSxCheckBox?: SxProps;
}


const CheckBox: FunctionComponent<ICheckBoxProps> = forwardRef((props, ref) => {
  const { squareSxProps, checkIconSxProps, checkedSquareSxProps, muiSxCheckBox, ...restProps } = props;
  return (
    <MuiCheckbox
      icon={<SquareBase squareSxProps={squareSxProps}/>}
      checkedIcon={
        <SquareBase squareSxProps={checkedSquareSxProps} selected>
          <CheckIcon strokeWidth={2} sx={{ color: primary, ...checkIconSxProps }}/>
        </SquareBase>
      }
      sx={{ '& .MuiSvgIcon-root': { fontSize: 17 }, ...muiSxCheckBox }}
      {...restProps}
      ref={ref}
    />
  );
});

export default CheckBox;
