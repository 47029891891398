import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Grid, InputAdornment, TextField, Link, Skeleton } from '@mui/material';
import { Business, Image } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { PermissionContext } from 'contexts';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { CompanyCrud } from 'clients/companies/companyClient.types';


interface Props {
    defaultValues?: Partial<CompanyCrud>;
    onSubmitRequest: (values: CompanyCrud) => void;
    onSubmitButtonText: string;
    isSubmitting?: boolean;
    isFetching?: boolean;
}

const DEFAULT_VALUES: Partial<CompanyCrud> = {
  name: '',
};

export const CompanyForm: FC<Props> = ({ defaultValues = DEFAULT_VALUES, onSubmitRequest, onSubmitButtonText, isSubmitting, isFetching }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { getPermission } = useContext(PermissionContext);

  const { handleSubmit, control, formState: { errors, isDirty }, reset } = useForm<CompanyCrud>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const hasViewPermission = useMemo(() => (
    getPermission('authGroup.crud.view')
  ), [getPermission]);

  const hasEditPermission = useMemo(() => (
    getPermission('company.crud.edit')
  ), [getPermission]);

  const onSubmit = useCallback((data: CompanyCrud) => {
    return onSubmitRequest(data);
  }, [onSubmitRequest]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {hasViewPermission && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="name"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => {
                return isFetching
                  ? <Skeleton variant="rounded" height={45}/>
                  : (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={dictionary.forms.company.fieldName}
                      name="name"
                      disabled={!hasEditPermission}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><Business/></InputAdornment>,
                      }}
                    />
                  );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="logo"
              control={control}
              render={({ field }) => {
                return isFetching
                  ? <Skeleton variant="rounded" height={45}/>
                  : (
                    <TextField
                      {...field}
                      fullWidth
                      type="file"
                      variant="outlined"
                      label={dictionary.forms.company.fieldLogo}
                      error={!!errors.logo}
                      disabled={!hasEditPermission}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><Image/></InputAdornment>,
                      }}
                      value={undefined}
                      defaultValue={undefined}
                      onChange={(e) => {
                        //@ts-ignore
                        field.onChange(e?.target?.files?.[0]);
                      }}
                    />
                  );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item>
                {hasEditPermission && (
                  <Link href="mailto:info@coolset.com?subject=Upgrade plan" target="_blank">
                    {dictionary.profile.upgradePlan}
                  </Link>
                )}
              </Grid>
              <Grid item>
                {hasEditPermission && (
                  <Link href="mailto:info@coolset.com?subject=Downgrade plan" target="_blank" color="error">
                    {dictionary.profile.downgradePlan}
                  </Link>
                )}
              </Grid>
              <Box ml="auto"/>
              <Grid item>
                {hasEditPermission && (
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    disabled={!isDirty}
                  >
                    {onSubmitButtonText}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </form>
  );
};
