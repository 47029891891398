import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';

import { CompanyOverviewSurvey } from 'modules/Surveys/partials/CompanyOverviewSurvey';
import { LocalizationContext } from 'contexts';


interface Props {
  open: boolean;
  onClose: () => void;
  data: {
    onSubmitCompleted: () => void;
  };
};

export const CompanyOverviewDialog: React.FC<Props> = ({ open, data, onClose }) => {

  const { dictionary } = React.useContext(LocalizationContext);

  const renderSubmit = ({ submitButton }: any) => (
    <Stack direction="row" spacing={2} pb={1}>
      <Button onClick={onClose} type="button" color="secondary">{dictionary.companyOverview.survey.buttonCancel}</Button>
      {submitButton}
    </Stack>
  );

  const _onSubmitCompleted = React.useCallback(() => {
    onClose();
    data?.onSubmitCompleted();
  }, [data, onClose]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 1,
          py: 2.5,
          minHeight: 520,
        },
      }}
    >
      <DialogTitle variant="h2" component="h1" fontWeight={500}>{dictionary.companyOverview.survey.title}</DialogTitle>
      <DialogContent>
        <CompanyOverviewSurvey
          name="emission_intensity"
          onSubmitButtonText={dictionary.companyOverview.survey.buttonSubmit}
          renderSubmit={renderSubmit}
          onSubmitCompleted={_onSubmitCompleted}
        />
      </DialogContent>
    </Dialog>
  );
};
