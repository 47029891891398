import React, { ReactElement } from 'react';
import { Box, IconButton, Skeleton, TextField, Typography } from '@mui/material';
import { MagnifyingGlassIcon } from 'shared/icons/MagnifyingGlassIcon';
import { LocalizationContext } from 'contexts';
import { ReactComponent as NotFound } from 'shared/img/notfound.svg';


export const DragAndDropColumn = (props: DragColumnProps) => {
  const {
    items,
    onDragStart,
    onDrop,
    title,
    itemName,
    isLoading,
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);

  const [isSearchOpen, setIsSearchOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>('');
  const [isCursorOver, setIsCursorOver] = React.useState<boolean>(false);
  const [selectedItems, setSelectedItems] = React.useState<number[]>([]);

  const filteredItems = !isSearchOpen ? items : items.filter((item) => item.key.toLowerCase().includes(search.toLowerCase()));
  const isNoItemsWithSearch = search && !filteredItems.length;

  const columnId = React.useMemo(() => `dragCol_${Math.round(Math.random() * 10000)}`, []);

  const handleOutsideClick: EventListener = React.useCallback((e: any) => {
    const isInside = !!e.target?.closest('#' + columnId) && !!e.target?.closest('.drop-col-item');
    if (!isInside) {
      setSelectedItems([]);
    }
  }, [columnId]);

  const handleItemSelect = React.useCallback((e: any, itemIndex: number) => {
    const idx = selectedItems.findIndex((n) => n === itemIndex);
    const temp = [...selectedItems];

    if (e.shiftKey) {
      const lastSelected = temp[temp.length - 1];
      const min = Math.min(lastSelected, itemIndex);
      const max = Math.max(lastSelected, itemIndex);
      const range = Array.from({ length: max - min + 1 }, (_, i) => i + min);
      const newSelected = range.filter((n) => !temp.includes(n));
      temp.push(...newSelected);
      setSelectedItems(temp);
      return;
    }

    if (e.metaKey) {
      if (idx >= 0) {
        temp.splice(idx, 1);
      }
      else {
        temp.push(itemIndex);
      }
      setSelectedItems(temp);
      return;
    }

    if (idx >= 0) {
      setSelectedItems(selectedItems.length > 1 ? [itemIndex] : []);
    }
    else {
      setSelectedItems([itemIndex]);
    }
  }, [selectedItems]);

  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [handleOutsideClick]);

  React.useEffect(() => {
    setSelectedItems([]);
  }, [items.length]);

  const handleDragStart = React.useCallback((itemIndex: number) => {
    let itemsToDrag = [...selectedItems];
    if (!selectedItems.find((n) => n === itemIndex)) {
      setSelectedItems([itemIndex]);
      itemsToDrag = [itemIndex];
    }
    onDragStart(itemsToDrag.map((n) => filteredItems[n].element));
  }, [filteredItems, onDragStart, selectedItems]);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if(!isCursorOver) {
      setIsCursorOver(true);
    }
  };

  const handleOnDrop = (e: React.DragEvent) => {
    setIsCursorOver(false);
    onDrop(e);
  };

  const itemsView = React.useMemo(() => isNoItemsWithSearch ? (
    <React.Fragment>
      <NotFound/>
      <Typography variant="body1">
        {dictionary.filterSection.noResults}
      </Typography>
    </React.Fragment>
  ) : (
    filteredItems.map((item, i) => (
      <DragItem
        key={i}
        onDragStart={() => handleDragStart(i)}
        onClick={(e) => handleItemSelect(e, i)}
        item={item}
        isSelected={selectedItems.includes(i)}
      />
    ))
  ), [dictionary, filteredItems, handleDragStart, handleItemSelect, isNoItemsWithSearch, selectedItems]);

  return (
    <Box
      className={`_d-grid-sm-gap drag-column ${isCursorOver ? 'is-drag-over' : ''}`}
      id={columnId}
    >
      <Box px={2}>
        <Box className="_d-flex-ali-center-jc-sb">
          <Box className="_d-flex-sm-gap">
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body1">{items.length} {itemName}</Typography>
          </Box>
          <IconButton
            onClick={() => setIsSearchOpen(!isSearchOpen)}
            color={isSearchOpen ? 'primary' : 'default'}
          >
            <MagnifyingGlassIcon/>
          </IconButton>
        </Box>

        {isSearchOpen && (
          <TextField
            value={search}
            placeholder={dictionary.search}
            variant="standard"
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        )}
      </Box>

      <Box
        className="_d-grid-sm-gap"
        onDrop={handleOnDrop}
        onDragOver={handleDragOver}
        onDragLeave={() => setIsCursorOver(false)}
        sx={{
          overflow: 'auto',
          alignContent: isNoItemsWithSearch ? 'center' : 'start',
          px: 2,
          placeItems: isNoItemsWithSearch ? 'center' : 'unset',
          '--selected-amount': selectedItems.length > 1 ? `"${selectedItems.length}"` : '',
        }}
      >
        {isLoading ? (
          <React.Fragment>
            <Skeleton variant="rounded" height={40}/>
            <Skeleton variant="rounded" height={40}/>
          </React.Fragment>
        ) : (
          itemsView
        )}
      </Box>
    </Box>
  );
};

const DragItem = (props: DragItemProps) => {
  const {
    onClick,
    onDragStart,
    isSelected,
    item,
  } = props;

  const [isDragged, setIsDragged] = React.useState<boolean>(false);

  const handleDragStart = React.useCallback(() => {
    setIsDragged(true);
    onDragStart();
  }, [onDragStart]);

  return (
    <Box
      className={`drop-col-item ${isSelected ? 'is-selected' : ''} ${isDragged ? 'is-dragged' : ''}`}
      draggable="true"
      onDragStart={handleDragStart}
      onDragEnd={() => setIsDragged(false)}
      onClick={onClick}
    >
      {item.element}
    </Box>
  );
};

interface DragItemProps {
    item: {element: ReactElement, key: string};
    onClick: (e: any) => void;
    onDragStart: () => void;
    isSelected: boolean;
}

  interface DragColumnProps {
    items: {element: ReactElement, key: string}[];
    onDragStart: (items: ReactElement[]) => void;
    onDrop: (e: React.DragEvent) => void;
    title: string,
    itemName: string,
    isLoading?: boolean,
}