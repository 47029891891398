import React, { CSSProperties } from 'react';
import { Box, Typography } from '@mui/material';
import { DocumentIcon } from 'shared/icons/DocumentIcon';
import { outlineLight } from 'shared/styles/muiTheme';
import { IntegrationOptions } from 'modules/Expenses/helpers/integrationOptions';
import { LocalizationContext } from 'contexts';


export const FileOrIntegrationName = ({ fileName }: {fileName?: string}) => {

  const { dictionary } = React.useContext(LocalizationContext);
  const integrationOption = IntegrationOptions.find((opt) => opt.key === fileName);

  if (!fileName) {
    return null;
  }

  const title = integrationOption ? (
    `${integrationOption.title} ${dictionary.expenses.integration}`
  ) : fileName;

  return (
    <Box
      className="_d-flex-ali-center-sm-gap dialog-file-name"
      mb={1}
    >
      <IntegrationIcon fileName={fileName}/>
      <Typography variant="body2">{title}</Typography>
    </Box>
  );
};

export const IntegrationIcon = ({ fileName = '', style }: {fileName?: string, style?: CSSProperties}) => {
  const integrationOption = IntegrationOptions.find((opt) => opt.key === fileName);

  return integrationOption ? (
    <Box
      component="img"
      src={integrationOption.img}
      alt={integrationOption.title}
      sx={{
        height: 24,
        aspectRatio: '1/1',
        borderRadius: '50%',
        objectFit: 'contain',
        border: `solid 1px ${outlineLight}`,
        ...style,
      }}
    />
  ) : (
    <Box
      className="_d-flex-ali-center-jc-center"
      color="text.tertiary"
      sx={{ p: .5, borderRadius: '50%', background: outlineLight, ...style }}
    >
      <DocumentIcon/>
    </Box>
  );
};