import { FunctionComponent, useContext, useMemo } from 'react';
import { ToggleButton, ToggleButtonGroup as MuiToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';

import { LocalizationContext } from 'contexts';
import {
  EmissionClassificationConfidenceStatus,
  EmissionClassificationStatus,
} from 'clients/transactions/transactionClient.types';


const ClassificationToggleButtonGroup: FunctionComponent<ToggleButtonGroupProps> = ({ ...props }) => {
  const { dictionary } = useContext(LocalizationContext);
  const defaults = {
    sx: { mr: 2 },
    exclusive: true,
  };

  const childrenToggleButtonList = useMemo(() => (
    [
      { value: EmissionClassificationConfidenceStatus.CONFIDENT, label: dictionary.measurements.toggleButtonLabels.classification.highConfidence },
      { value: EmissionClassificationConfidenceStatus.NEEDS_ATTENTION, label: dictionary.measurements.toggleButtonLabels.classification.lowConfidence },
    ]
  ), [dictionary]);

  const showClassifiedButtons = useMemo(() => {
    return [
      EmissionClassificationStatus.CLASSIFIED,
      EmissionClassificationConfidenceStatus.NEEDS_ATTENTION,
      EmissionClassificationConfidenceStatus.CONFIDENT,
    ].includes(props.value);
  }, [props.value]);

  return (
    <MuiToggleButtonGroup {...defaults} {...props}>
      <ToggleButton key={EmissionClassificationStatus.CLASSIFIED} value={EmissionClassificationStatus.CLASSIFIED}>
        {dictionary.menu.transactions.classification.classified}
      </ToggleButton>
      {
        showClassifiedButtons && (
          <MuiToggleButtonGroup {...defaults} {...props}>
            {childrenToggleButtonList.map((buttonContent) => (
              <ToggleButton key={buttonContent.value} value={buttonContent.value}>
                {buttonContent.label}
              </ToggleButton>
            ))}
          </MuiToggleButtonGroup>
        )}
      <ToggleButton key={EmissionClassificationStatus.NON_CLASSIFIED} value={EmissionClassificationStatus.NON_CLASSIFIED}>
        {dictionary.menu.transactions.classification.nonClassified}
      </ToggleButton>
      <ToggleButton key={EmissionClassificationStatus.ALL} value={EmissionClassificationStatus.ALL}>
        {dictionary.menu.transactions.classification.all}
      </ToggleButton>
    </MuiToggleButtonGroup>
  );
};

export default ClassificationToggleButtonGroup;
