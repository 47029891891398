import { dictionary as baseDictionary } from './en';

//TODO: Extend once multiple languages are to be supported
export const dictionary = {
  ...baseDictionary,
};

// import { warnOnMissingTranslations } from 'helpers/translationHelpers';
// import { dictionary as dictionaryType } from './en';
//
//
// export const de: Partial<typeof dictionaryType> = {
//   home: 'Home',
//   loading: 'Wird Geladen',
//   results: 'Results',
//   myProfile: 'Mein Konto',
//   myAccount: 'Mein Konto',
//   subscriptions: 'Subscriptions',
//   login: 'Einloggen',
//   logOut: 'Ausloggen',
//   signUp: 'Signup',
//   fullName: 'Full Name',
//   name: 'Name',
//   surname: 'Surname',
//   email: 'Email',
//   password: 'Passwort',
//   thePasswordMustBeAtLeast8CharactersLong: 'The password must be at least 8 characters long',
//   repeatPassword: 'Repeat Password',
//   userWithThisEmailAlreadyExists: 'User with this email already exists',
//   passwordsDoNotMatch: 'Passwords do not match',
//   signUpWithGoogle: 'Mit Google Einloggen',
//   signUpWithMicrosoft: 'Mit Microsoft Einloggen',
//   somethingWentWrong: 'Something went wrong!',
//   oneOrMoreFieldsAreIncorrect: 'One or more fields are incorrect.',
//   open: 'Offen',
//   date: 'Datum',
//   dueDate: 'Termin',
//   dates: 'Termine',
//   time: 'Zeit',
//   duration: 'Dauer',
//   comingSoon: 'Coming soon',
//   toggleLighDarkMode: 'Toggle Light/Dark Mode',
//
//   hour: 'Stunde',
//   hours: 'Stunden',
//   minutes: 'Minutes',
//
//   resetPassword: 'Passwort Zurücksetzen',
//   reset: 'Zurücksetzen',
//
//   notFound: 'Nicht gefunden',
//
//   progress: 'Fortschritt',
//   complete: 'Vollständig',
//   downloads: 'Downloads',
//
//   join: 'Join',
//
//   darkMode: 'Dunklermodus',
//   lightMode: 'Lichtmodus',
//
//   switchToDarkMode: 'Dunklermodus Aktivieren',
//   switchToLightMode: 'Lichtmodus Aktivieren',
//
//   month: 'Monat',
//   week: 'Woche',
//   day: 'Tag',
//   agenda: 'Agenda',
//   today: 'Heute',
//
//   toDo: 'Machen',
//   inProgress: 'Im Gange',
//   completed: 'Abgeschlossen',
//
//   threeHoursAgo: 'Vor 3 Stunden',
//   twoDaysAgo: 'Vor 2 Tagen',
//   oneWeekAgo: 'Vor 1 Woche',
//
//   download: 'Herunterladen',
//
//   search: 'Suche',
//   notifications: 'Benachrichtigungen',
//   seeNotifications: 'Siehe Benachrichtigungen',
//
//   hello: 'Hallo',
//   assignee: 'Bevollmächtigter',
//   status: 'Status',
//
//   all: 'Alle',
//   submit: 'Einreichen',
//
//   description: 'Beschreibung',
//   attachments: 'Anhänge',
// };
//
//
// export const dictionary = {
//   ...dictionaryType,
//   ...de,
// };
//
//
// warnOnMissingTranslations(de, 'de');
