import React, { FC, Fragment, useContext } from 'react';
import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { LocalizationContext } from 'contexts';
import { EmissionFactors } from 'clients/emissionFactors/emissionFactorsClient.types';


interface IReccomendationTabContentProps {
  value: EmissionFactors;
  index: number;
  imgSrc: string;
  imgAlt: string;
  recommendationCategory: EmissionFactors,
}

const ReccomendationTabContent: FC<IReccomendationTabContentProps> = ({
  value,
  index,
  imgSrc,
  imgAlt,
  recommendationCategory,
}) => {
  const {
    dictionary: {
      recommendations: { [recommendationCategory]: dictionaryRecommendation },
    },
  } = useContext(LocalizationContext);
  if (value !== recommendationCategory) {
    return null;
  }

  const pairTitleDescriptionCount = (Object.keys(dictionaryRecommendation.content).length) / 2;

  return (
    <Box p={3}>
      {[...Array(pairTitleDescriptionCount)].map((item, index) => (
        <Fragment key={dictionaryRecommendation.content[`title${index + 1}` as keyof typeof dictionaryRecommendation.content]}>
          <Typography
            variant="h6"
            component="div"
            sx={{ mt: index === 0 ? undefined : 5 }}
          >
            {dictionaryRecommendation.content[`title${index + 1}` as keyof typeof dictionaryRecommendation.content]}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            {dictionaryRecommendation.content[`description${index + 1}` as keyof typeof dictionaryRecommendation.content]}
          </Typography>
        </Fragment>
      ))}
      <Divider flexItem style={{ width: '100%', margin: '40px 0px' }}/>
      <Box
        component="img"
        alt={imgAlt}
        src={imgSrc}
        sx={{ width: '100%', padding: '24px 40px', backgroundColor: '#FAFAFA' }}
      />
    </Box>
  );
};

export default ReccomendationTabContent;
