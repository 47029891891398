import { differenceInDays, differenceInWeeks, differenceInYears, format, parse } from 'date-fns';
import { MaybeNull } from 'types';
import { Dictionary } from 'contexts/LocalizationContext/types';
import { pluralizeString } from './string';


export const DATE_FORMAT = 'yyyy-MM-dd';

export const formatToApi = (date: Date) => format(new Date(date), DATE_FORMAT);

export const getDeltaLabel = (startDate: Date, endDate: Date) => {
  const diffYears = differenceInYears(endDate, startDate);
  if(diffYears > 1) {
    return `${Math.round(diffYears)} years`;
  }
  const diffMonths = monthsBetween(endDate, startDate);
  if(diffMonths > 2) {
    return `${Math.round(diffMonths)} months`;
  }
  const diffWeeks = differenceInWeeks(endDate, startDate);
  if(diffWeeks > 2) {
    return `${Math.round(diffWeeks)} weeks`;
  }
  const diffDays = differenceInDays(endDate, startDate);
  return `${Math.round(diffDays)} days`;
};

export const convertDaysToDateLabel = (numberOfDays: number, dictionary: Dictionary) => {
  if (numberOfDays < 7) {
    return pluralizeString(numberOfDays, dictionary.charts.dateAggregation.day);
  } else if (numberOfDays < 30) {
    const weeks = Math.floor(numberOfDays / 7);
    return pluralizeString(weeks, dictionary.charts.dateAggregation.week);
  } else if (numberOfDays < 365) {
    const months = Math.floor(numberOfDays / 30);
    return pluralizeString(months, dictionary.charts.dateAggregation.month);
  } else {
    const years = Math.floor(numberOfDays / 365);
    return pluralizeString(years, dictionary.charts.dateAggregation.year);
  }
};

export const getCurrentYear = () => {
  const date = new Date();
  return date.getUTCFullYear();
};

export const formatDate = (date: Date | null) => date
  ? format(date, DATE_FORMAT)
  : date;

export const parseDate = (dateString: string | null) => dateString
  ? parse(dateString, DATE_FORMAT, new Date())
  : dateString;

export const isValidDate = (date: MaybeNull<Date>) => {
  const timestamp = date?.getTime();

  if (!date || !timestamp || isNaN(timestamp)) {
    return false;
  }

  return true;
};

export const GRID_DATE_FILTERS = ['is', 'not', 'before', 'after', 'onOrAfter', 'onOrBefore'];

export const monthsBetween = (firstDay: Date, lastDay: Date) => {
  let numMonths = 0;
  let currentMonth = firstDay.getMonth();
  let currentYear = firstDay.getFullYear();

  while (currentYear < lastDay.getFullYear() || (currentYear === lastDay.getFullYear() && currentMonth <= lastDay.getMonth())) {
    numMonths++;
    currentMonth++;
    if (currentMonth > 11) {
      currentMonth = 0;
      currentYear++;
    }
  }

  return numMonths;
};