import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { cacheKeys } from 'config';
import { LayoutContext } from 'contexts';
import { transactionClient } from 'clients/transactions/transactionClient';


export const useClassifyTransactionsCrud = () => {
  const { genericError } = React.useContext(LayoutContext);
  const queryClient = useQueryClient();

  const onSuccessfullClassification = React.useCallback(async () => {
    queryClient.invalidateQueries(cacheKeys.transactions.getTransactionsStatistics);
    queryClient.invalidateQueries(cacheKeys.transactions.getTransactionsWordAnalysis);
    queryClient.invalidateQueries(cacheKeys.scenarioBuilder.getScenarioBuilderData);
    queryClient.invalidateQueries(cacheKeys.charts.getChart);
    await queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
  }, [queryClient]);

  const classifyTransactions = useMutation(transactionClient.bulkClassifyTransactions, {
    mutationKey: cacheKeys.transactions.bulkClassifyTransactions,
    onSuccess: onSuccessfullClassification,
    onError: () => {
      genericError();
    },
  });

  const classifyFilteredTransactions = useMutation(transactionClient.bulkClassifyFilteredTransactions, {
    mutationKey: cacheKeys.transactions.bulkClassifyTransactions,
    onSuccess: onSuccessfullClassification,
    onError: () => {
      genericError();
    },
  });

  const classifyTransactionsConfidence = useMutation(transactionClient.bulkClassifyConfidenceTransactions, {
    mutationKey: cacheKeys.transactions.bulkClassifyConfidenceTransactions,
    onSuccess: onSuccessfullClassification,
    onError: () => {
      genericError();
    },
  });

  const classifyFilteredTransactionsConfidence = useMutation(transactionClient.bulkClassifyConfidenceFilteredTransactions, {
    mutationKey: cacheKeys.transactions.bulkClassifyConfidenceTransactions,
    onSuccess: onSuccessfullClassification,
    onError: () => {
      genericError();
    },
  });

  return {
    updateClassificationPartial: classifyTransactions.mutateAsync,
    updateClassificationFull: classifyFilteredTransactions.mutateAsync,
    updateConfidencePartial: classifyTransactionsConfidence.mutateAsync,
    updateConfidenceFull: classifyFilteredTransactionsConfidence.mutateAsync,
  };
};
