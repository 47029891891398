import React, {
  Fragment,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';

import { ClockIcon } from 'shared/icons/ClockIcon';
import { CongratsIcon } from 'shared/icons/CongratsIcon';
import { getYear, subYears } from 'date-fns';
import { LocalizationContext } from 'contexts';
import { ChartGroupBy, ChartType } from 'clients/charts/chartClient.types';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';
import { useCharts } from 'shared/hooks/useCharts';
import { useActivityBasedCategories } from 'shared/hooks/useActivityBasedSubCategories';
import { useMapCategoryChart } from 'shared/hooks/useMapCategoryChart';
import CategoriesBar from 'shared/components/layout/CategoriesBar/CategoriesBar';

import { MessageFeedback } from 'shared/components/layout/Feedback/MessageFeedback';
import LoadingModal from 'shared/components/layout/Modal/LoadingModal/LoadingModal';
import EmissionsComparingMessageBox from 'views/LoggedIn/Measurements/components/EmissionsComparingMessageBox';
import { getCategoriesString } from 'views/LoggedIn/Measurements/helpers';
import ExploreRecommendationsBtn from './ExploreRecommendationsBtn';
import CategoriesTable from './CategoriesTable';


export const EmissionsByCategory = ({
  hasClassifiedTransactions,
  hasEmissions,
  emissionsContainerBarRef,
  emissionsContainerCategoryBreakdownRef,
  filters,
  isComparingMonths,
}: any) => {
  const {
    scope,
    gteDate,
    lteDate,
    emissionFactorCategory,
    setEmissionFactorCategory,
    isParent,
    tags,
    intensity,
  } = filters;
  const { dictionary } = useContext(LocalizationContext);
  const [highlightedCategoryBarTable, setHighlightedCategoryBarTable] = useState<string>();
  const reloadChartsRef = useRef(emissionFactorCategory ? true : false);

  const mappedFilters = useMemo(() => ({
    dateGte: gteDate,
    dateLte: lteDate,
    scope: scope !== Scope.All ? scope : [Scope.One, Scope.Two, Scope.Three],
    tags,
    relativeTo: intensity,
  }), [scope, gteDate, lteDate, tags, intensity]);
  const { isLoading: isLoadingActivityBased } = useActivityBasedCategories({ mandatory: false });

  const { data: categoriesChartData, isLoadingCharts: isLoadingCategoriesChartData } = useCharts({
    groupBy: emissionFactorCategory ? ChartGroupBy.category : ChartGroupBy.parentCategory,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...(isParent
      ? { categoryId: emissionFactorCategory?.id }
      : { subCategoryId: emissionFactorCategory?.id }),
    ...mappedFilters,
  });

  const { data: prevCategoriesChartData, isLoadingCharts: isLoadingPrevCategoriesChartData } = useCharts({
    groupBy: emissionFactorCategory ? ChartGroupBy.category : ChartGroupBy.parentCategory,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...(isParent
      ? { categoryId: emissionFactorCategory?.id }
      : { subCategoryId: emissionFactorCategory?.id }),
    ...mappedFilters,
    dateGte: subYears(gteDate, 1),
    dateLte: subYears(lteDate, 1),
  }, { enabled: isComparingMonths });

  const { data: parentCategoriesChartData, isLoadingCharts: isLoadingParentCategoriesChartData, isFetching: isFetchingParentCategoriesChartData } = useCharts({
    groupBy: ChartGroupBy.parentCategory,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...mappedFilters,
    categoryId: undefined,
    subCategoryId: undefined,
  });

  const { data: prevParentCategoriesChartData, isLoadingCharts: isLoadingPrevParentCategoriesChartData, isFetching: isFetchingPrevParentCategoriesChartData } = useCharts({
    groupBy: ChartGroupBy.parentCategory,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...mappedFilters,
    categoryId: undefined,
    subCategoryId: undefined,
    dateGte: subYears(gteDate, 1),
    dateLte: subYears(lteDate, 1),
  }, { enabled: isComparingMonths });

  const { data: childCategoriesChartData, isLoadingCharts: isLoadingChildCategoriesChartData, isFetching: isFetchingChildCategoriesChartData } = useCharts({
    groupBy: ChartGroupBy.category,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...mappedFilters,
    subCategoryId: undefined,
    categoryId: emissionFactorCategory?.parent?.id,
  });

  const { data: prevChildCategoriesChartData, isLoadingCharts: isLoadingPrevChildCategoriesChartData, isFetching: isFetchingPrevChildCategoriesChartData } = useCharts({
    groupBy: ChartGroupBy.category,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...mappedFilters,
    subCategoryId: undefined,
    categoryId: emissionFactorCategory?.parent?.id,
    dateGte: subYears(gteDate, 1),
    dateLte: subYears(lteDate, 1),
  }, { enabled: isComparingMonths });

  const totalCo2 = useMemo(() => parentCategoriesChartData?.meta?.total_co2_kg, [parentCategoriesChartData?.meta?.total_co2_kg]);
  const prevTotalCo2 = useMemo(() => prevParentCategoriesChartData?.meta?.total_co2_kg, [prevParentCategoriesChartData?.meta?.total_co2_kg]);

  const { mapCategoryChartData } = useMapCategoryChart();
  const mappedCategoryChartData = useMemo(() => mapCategoryChartData(categoriesChartData, { allowNegative: true, isChild: true }, totalCo2, prevCategoriesChartData, prevTotalCo2), [mapCategoryChartData, categoriesChartData, prevCategoriesChartData, totalCo2, prevTotalCo2]);
  const mappedChildCategoryChartData = useMemo(() => mapCategoryChartData(childCategoriesChartData, { allowNegative: true }, totalCo2, prevChildCategoriesChartData, prevTotalCo2), [mapCategoryChartData, childCategoriesChartData, prevChildCategoriesChartData, totalCo2, prevTotalCo2]);
  const mappedParentCategoryChartData = useMemo(() => mapCategoryChartData(parentCategoriesChartData, { allowNegative: true }, totalCo2, prevParentCategoriesChartData, prevTotalCo2), [mapCategoryChartData, parentCategoriesChartData, prevParentCategoriesChartData, totalCo2, prevTotalCo2]);
  const isAnyChartsLoading = isLoadingActivityBased || isLoadingChildCategoriesChartData || isLoadingParentCategoriesChartData || isLoadingCategoriesChartData || isLoadingPrevCategoriesChartData || isLoadingPrevParentCategoriesChartData || isLoadingPrevChildCategoriesChartData;
  const isAnyChartsFetching = isFetchingChildCategoriesChartData || isFetchingParentCategoriesChartData || isFetchingPrevParentCategoriesChartData || isFetchingPrevChildCategoriesChartData;

  return (
    <React.Fragment>
      <LoadingModal open={isAnyChartsFetching && !isAnyChartsLoading}/>
      <Box width="100%" pt={2}>
        {hasClassifiedTransactions && hasEmissions && (
          <Box ref={emissionsContainerBarRef}>
            <CategoriesBar
              setEmissionFactorCategory={setEmissionFactorCategory}
              isLoading={isAnyChartsLoading}
              data={mappedParentCategoryChartData}
              reloadChartsRef={reloadChartsRef}
              emissionFactorCategory={emissionFactorCategory}
            />
          </Box>
        )}
        {isAnyChartsLoading && <Skeleton width="100%" height="150px" variant="rectangular"/>}
        {!isAnyChartsLoading && hasClassifiedTransactions && hasEmissions && (
          <Box my={3}>
            <EmissionsComparingMessageBox
              text={dictionary.measurements.comparingMessageBox.overviewCategories(getCategoriesString(mappedParentCategoryChartData))}
              rightComponent={
                //TODO: Implement button for marketplace once it is ready
                // <RightArrowBtn label={dictionary.measurements.comparingMessageBox.overviewRightButtonLabel}/>
                <ExploreRecommendationsBtn/>
              }
            />
          </Box>
        )}
        <Box width="100%">
          {isAnyChartsLoading ? (
            <Skeleton variant="rounded" width="100%" height={400}/>
          ) : (
            categoriesChartData && (
              <Fragment>
                <Box
                  ref={emissionsContainerCategoryBreakdownRef}
                  style={{ position: 'relative', width: '100%' }}
                >
                  <Grid
                    container
                    style={{
                      backgroundColor: '#F0F3F6',
                      maxHeight: '36px',
                      padding: '8px',
                      borderRadius: 4,
                    }}
                    mb={1}
                  >
                    <Grid item xs={isComparingMonths ? 3 : 4}>
                      <Typography variant="tableTitle">
                        {dictionary.measurements.categoryTable.headers.category}
                      </Typography>
                    </Grid>
                    <Grid item xs={isComparingMonths ? 5 : 4}>
                      <Grid container spacing={1} width="100%">
                        {isComparingMonths && (
                          <Grid item xs={4} textAlign="right">
                            <Typography variant="tableTitle">
                              {getYear(subYears(gteDate, 1))}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={isComparingMonths ? 4 : 12} textAlign="right">
                          <Typography variant="tableTitle">
                            {isComparingMonths ? getYear(gteDate) : dictionary.measurements.categoryTable.headers.impact}
                          </Typography>
                        </Grid>
                        {isComparingMonths && (
                          <Grid item xs={4} textAlign="right">
                            <Typography variant="tableTitle">
                              {dictionary.measurements.categoryTable.headers.change}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={isComparingMonths ? 3 : 4} textAlign="end">
                      <Typography variant="tableTitle">
                        RECOMMENDATIONS
                      </Typography>
                    </Grid>
                  </Grid>
                  {!isAnyChartsLoading && (!hasClassifiedTransactions || !hasEmissions) ? (
                    <Box className="_d-flex jc-center" width="100%" py={8}>
                      <MessageFeedback
                        icon={hasClassifiedTransactions ? <CongratsIcon/> : <ClockIcon/>}
                        message={hasClassifiedTransactions ? dictionary.home.dashboard.noEmissions : dictionary.home.dashboard.noAnalysis}
                      />
                    </Box>
                  ) : (
                    <CategoriesTable
                      mappedParentCategoryChartData={mappedParentCategoryChartData}
                      setEmissionFactorCategory={setEmissionFactorCategory}
                      emissionFactorCategory={emissionFactorCategory}
                      mappedFilters={mappedFilters}
                      mappedChildCategoryChartData={mappedChildCategoryChartData}
                      isParent={isParent}
                      reloadChartsRef={reloadChartsRef}
                      mappedCategoryChartData={mappedCategoryChartData}
                      highlightedCategoryBarTable={highlightedCategoryBarTable}
                      setHighlightedCategoryBarTable={setHighlightedCategoryBarTable}
                      isComparingMonths={isComparingMonths}
                    />
                  )}
                </Box>
              </Fragment>
            )
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};
