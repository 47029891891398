import React from 'react';
import { Skeleton, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

import { InformationToolTipContent } from 'shared/components/layout/Tooltip/InformationToolTipContent';

import { IEmissionBoxProps } from './EmissionBoxSection.types';
import EmissionBoxBarGraph from './EmissionBoxBarGraph';


const sxSkeleton = { mt: '5px' };

const EmissionBox: React.FunctionComponent<IEmissionBoxProps> = ({
  tooltip,
  emissionWeight,
  containerProps,
  title,
  emissionBarHeights,
  emissionBarVariant,
  isLoading = false,
  isAllowNegative = false,
}) => {
  return (
    <Box pr={5} {...containerProps}>
      <Stack mb={2} alignItems="center" gap={1} direction="row">
        <Typography color="#6B7280" fontWeight={700} fontSize={15}>
          {title}
        </Typography>
        {tooltip && <InformationToolTipContent tooltipTitle={tooltip}/>}
      </Stack>
      {isLoading ? (
        <Skeleton
          variant="rounded"
          sx={sxSkeleton}
          height={52}
          width={250}
        />
      ) : (
        <EmissionBoxBarGraph
          emissionBarHeights={emissionBarHeights}
          emissionBarVariant={emissionBarVariant}
          emissionWeight={isAllowNegative ? emissionWeight : Math.abs(emissionWeight)}
        />
      )}
    </Box>
  );
};

export default EmissionBox;
