import React from 'react';
import { Box, Typography } from '@mui/material';
import { ExclamationCircleIcon } from 'shared/icons/ExclamationCircleIcon';
import type { StatusIconProps } from 'shared/components/Status/StatusIcon';


export const IntegrationAccountStatus: React.FC<IntegrationAccountStatusProps> = (props) => {
  const { color = 'warning.main', label } = props;

  return (
    <Box className="_d-flex">
      <ExclamationCircleIcon sx={{ color: color, height: 16, width: 16 }}/>
      <Typography
        color={color}
        variant="body2"
      >
        {label}
      </Typography>
    </Box>
  );
};

interface IntegrationAccountStatusProps {
    label: string;
    color?: string;
    type?: StatusIconProps['type'];
}
