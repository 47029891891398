import { MutableRefObject } from 'react';


export type ContextProps = {
    openDrawer: (drawer: Drawers) => void;
    closeDrawer: () => void;
    auxiliaryRef: MutableRefObject<undefined | any>,
  };

export enum Drawers {
  recommendation = 'Recommendation',
}