import { SurveyQuestion } from 'clients/surveys/survey.types';


export enum EmployeeCommutingSurveyYearsEnum {
  twoThousandTwentyOne = '2021',
  twoThousandTwentyTwo = '2022',
  twoThousandTwentyThree = '2023',
}

export enum EmployeeNumSurveyYearsEnum {
  twoThousandTwenty = '2020',
  twoThousandTwentyOne = '2021',
  twoThousandTwentyTwo = '2022',
  twoThousandTwentyThree = '2023',
}

interface Condition {
  type: string;
  subsequentMethods: {
    [method: string]: any[];
  };
}

export interface Conditions {
  [condition: string]: Condition;
}

export type YupSchemaShape = Record<string, any>;

export interface ReplaceData {
    keyStrToBeReplaced: keyof SurveyQuestion;
    [key: string]: any;
  }

export enum EmployeeEmissionsTypeEnum {
  commutingEmissions = 'commutingEmissions',
  workFromHomeEmissions = 'workFromHomeEmissions',
  allEmissions = 'allEmissions',
}

export type GetFillColorVariants = Exclude<EmployeeEmissionsTypeEnum, EmployeeEmissionsTypeEnum.allEmissions>;
export interface IGetFillColor {
  variant: GetFillColorVariants,
  variantValue: EmployeeEmissionsTypeEnum
}

export interface ICreatePieChartArrayData {
  variantValue: EmployeeEmissionsTypeEnum,
  emissions: {
    commutingEmissions: number,
    workFromHomeEmissions: number
  }
}

export type ChartSectionDataType = {
  name: string;
  value: number;
  fill: string;
}[]
