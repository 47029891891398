import React, { FC, useContext } from 'react';
import { Box, Typography } from '@mui/material';

import { defaultIconPink, primaryLight } from 'shared/styles/muiTheme';
import { isUndefined } from 'lodash';
import { LocalizationContext } from 'contexts';


interface Props {
    targetAchieved?: boolean;
};

export const TargetIndicator: FC<Props> = ({ targetAchieved }) => {
  const { dictionary } = useContext(LocalizationContext);
  return isUndefined(targetAchieved) ? (
    <Typography variant="body1">-</Typography>
  ) : (
    <Box sx={(theme) => ({
      border: '1px solid',
      borderRadius: '4px',
      py: 1,
      px: 2,
      borderColor: targetAchieved ? theme.palette.primary.main : theme.palette.error.main,
      bgcolor: targetAchieved ? primaryLight : defaultIconPink,
    })}>
      <Typography variant="body1" color={targetAchieved ? 'primary' : 'error'}>{targetAchieved ? dictionary.confirmation.yes : dictionary.confirmation.no}</Typography>
    </Box>
  );
};
