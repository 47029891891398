import React from 'react';
import { Box } from '@mui/material';
import { primary, primaryLight } from 'shared/styles/muiTheme';


export const TextTag = (props: {text: number | string}) => {
  const { text } = props;
  if (!text && text !== 0) {
    return null;
  }

  return (
    <Box
      sx={{
        padding: '2px 6px',
        borderRadius: '6px',
        fontSize: 12,
        lineHeight: 1,
        background: primary,
        color: primaryLight,
      }}
    >
      {text}
    </Box>
  );
};