import React, { ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';


export const KababMenu = (props: KababMenuProps) => {
  const { items, containerProps = {}, menuProps = {}, closeOnClick = false } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        {...containerProps}
      >
        <MoreVertIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={closeOnClick ? handleClose : undefined}
        {...menuProps}
      >
        {items}
      </Menu>
    </React.Fragment>
  );
};

interface KababMenuProps {
    items: ReactElement[];
    containerProps?: React.ComponentProps<typeof IconButton>;
    menuProps?: React.ComponentProps<typeof Menu>;
    closeOnClick?: boolean;
}