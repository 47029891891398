import React from 'react';
import { IconContainer } from './IconContainer';


export const CongratsIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 24 24">
    <path
      d="M14.0489 5.10963L13.5918 5.56678L14.0789 6.0539L14.566 6.54102L15.0419 6.06139L15.5215 5.58551L15.0532 5.11713C14.7946 4.85858 14.5698 4.64874 14.5473 4.64874C14.5248 4.64874 14.3037 4.85483 14.0489 5.10963Z"
      fill="currentColor"
    />
    <path
      d="M9.21953 5.28571C8.92726 5.35316 8.64623 5.60796 8.53757 5.89649C8.48136 6.04637 8.48511 6.42857 8.54881 6.57471C8.61626 6.73583 8.80361 6.94941 8.97597 7.05433C9.10337 7.13302 9.16707 7.14052 9.8453 7.15925C10.6622 7.18173 10.7334 7.20422 10.9207 7.44778L11.0256 7.58267L11.0369 9.29133L11.0481 11L11.1418 10.8501C11.3104 10.5803 11.6851 9.81218 11.8312 9.43747C12.1572 8.60562 12.2921 7.96487 12.2959 7.27166C12.2996 6.83326 12.2884 6.73208 12.2134 6.51101C11.9774 5.83279 11.464 5.40562 10.7371 5.28571C10.4673 5.2445 9.39939 5.24075 9.21953 5.28571Z"
      fill="currentColor"
    />
    <path
      d="M17.7514 5.3082C17.358 5.41312 17.032 5.70913 16.8708 6.11007C16.8146 6.24871 16.7997 6.42108 16.7847 7.05433L16.7697 7.82623L16.2751 7.84122C15.8329 7.85246 15.7655 7.8637 15.5968 7.95363C15.0835 8.22342 14.8662 8.64684 14.8662 9.37752V9.74473H14.69C14.2367 9.74473 13.5809 10.0145 13.2737 10.3255C13.1725 10.4304 12.809 10.8164 12.468 11.1874L11.8535 11.8543L12.2994 12.2778C12.5467 12.5101 12.7678 12.7012 12.7865 12.7012C12.809 12.6974 13.135 12.3639 13.5097 11.9518C13.9519 11.4721 14.2516 11.1761 14.3491 11.1274C14.4465 11.0787 14.6263 11.0375 14.8587 11.015C15.2858 10.9738 15.5294 10.8763 15.758 10.644C15.8479 10.5541 15.9678 10.393 16.0203 10.2881C16.1102 10.1194 16.1214 10.052 16.1327 9.60234L16.1477 9.10773H16.5111C17.1369 9.10773 17.5566 8.93536 17.8226 8.5644C18.0362 8.26464 18.0587 8.15223 18.0811 7.29789L18.0961 6.52225H19.0479H19.9997V5.88525V5.24824L18.9804 5.25199C18.2086 5.25199 17.905 5.26698 17.7514 5.3082Z"
      fill="currentColor"
    />
    <path
      d="M5.76135 6.39154L5.29297 6.85993L5.77259 7.33955L6.24847 7.81543L6.72435 7.33955L7.20398 6.85993L6.73559 6.39154C6.47704 6.13299 6.25971 5.92316 6.24847 5.92316C6.23723 5.92316 6.0199 6.13299 5.76135 6.39154Z"
      fill="currentColor"
    />
    <path
      d="M4.47588 10.2239L4 10.6998L4.47963 11.1794L4.9555 11.6553L5.44262 11.1682L5.92974 10.681L5.46136 10.2127C5.20281 9.9541 4.98548 9.74427 4.97424 9.74427C4.963 9.74427 4.73817 9.95785 4.47588 10.2239Z"
      fill="currentColor"
    />
    <path
      d="M18.5387 10.2127L18.0703 10.681L18.5574 11.1682L19.0446 11.6553L19.5204 11.1794L20.0001 10.6998L19.5242 10.2239C19.2619 9.95785 19.0371 9.74427 19.0258 9.74427C19.0146 9.74427 18.7972 9.9541 18.5387 10.2127Z"
      fill="currentColor"
    />
    <path
      d="M7.52308 10.4606C7.14088 10.6817 7.09591 11.1388 7.41816 11.4911C7.51558 11.596 7.5643 11.6784 7.54931 11.7196C7.54181 11.7571 6.88608 13.3983 6.09919 15.3655C4.82519 18.5431 4.66406 18.9665 4.66406 19.1501C4.66406 19.6035 5.01254 19.952 5.46219 19.9482C5.6458 19.9482 6.07671 19.7833 9.24673 18.5131C11.2139 17.7262 12.8552 17.0705 12.8926 17.063C12.9339 17.048 13.0163 17.0967 13.1175 17.1904C13.3123 17.3702 13.5072 17.4452 13.6983 17.4077C13.8931 17.374 14.0954 17.2316 14.1666 17.078C14.2453 16.9168 14.2491 16.6321 14.1779 16.4972C14.1104 16.3735 8.21254 10.4831 8.09638 10.4232C7.96523 10.3557 7.66921 10.3744 7.52308 10.4606Z"
      fill="currentColor"
    />
    <path
      d="M16.9275 12.3149C16.3954 12.3823 15.7059 12.566 15.1626 12.7795C14.7767 12.9369 14.0198 13.3079 13.7612 13.469L13.6113 13.5627L15.2675 13.5664C16.3467 13.5664 16.9612 13.5814 17.0287 13.6076C17.0849 13.6301 17.1748 13.6826 17.2198 13.7275C17.4109 13.9037 17.4296 13.9898 17.4521 14.7655C17.4746 15.5898 17.4895 15.6423 17.8006 15.9083C18.1528 16.2119 18.7261 16.1856 19.0558 15.8521C19.3069 15.6011 19.3406 15.4887 19.3556 14.7992C19.3631 14.4507 19.3518 14.0573 19.3294 13.9112C19.2132 13.1617 18.816 12.6671 18.1415 12.4161C17.9354 12.3411 17.8006 12.3186 17.4708 12.3112C17.246 12.3037 16.9987 12.3074 16.9275 12.3149Z"
      fill="currentColor"
    />
    <path
      d="M15.9899 17.2578L15.5215 17.7261L16.0011 18.2058L16.477 18.6816L16.9529 18.2058L17.4325 17.7261L16.9641 17.2578C16.7056 16.9992 16.4882 16.7894 16.477 16.7894C16.4657 16.7894 16.2484 16.9992 15.9899 17.2578Z"
      fill="currentColor"
    />
  </IconContainer>
);
