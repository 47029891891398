import React, { FC, useContext, useMemo, useRef, useState, Fragment } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Popover,
  PopoverProps,
} from '@mui/material';
import { format } from 'date-fns';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { LocalizationContext } from 'contexts';
import { newDateWithNoTime } from 'shared/hooks/useDateFilters';
import { StaticDateRangePicker } from 'shared/components/interactive/DateRangePicker/StaticDateRangePicker';


export type DateOption = {
  gteDate: Date;
  lteDate: Date;
};

type DateRangeSelectProps = {
  readonly gteDate: Date;
  readonly lteDate: Date;
  readonly onChange: (option: DateOption, year?: string) => void;
  readonly minimumDate?: Date;
  readonly maximumDate?: Date;
  readonly year?: string;
};

export const DateRangeSelect: FC<DateRangeSelectProps> = ({
  gteDate,
  lteDate,
  onChange,
  minimumDate,
  maximumDate,
  year,
}) => {
  const { dictionary } = useContext(LocalizationContext);

  const [openOptions, setOpenOptions] = useState<boolean>(false);
  const [isCustomDateRangeShowing, setIsCustomDateRangeShowing] = useState<boolean>(false);
  const containerRef = useRef(null);

  const handleClose = () => {
    setOpenOptions(false);
    setIsCustomDateRangeShowing(false);
  };

  const handleDateChange = (option: DateOption) => {
    if (option.gteDate && option.lteDate) {
      onChange(option);
    }

    handleClose();
  };

  const handleDateChangeWithYear = (yearOption: string) => {
    if (yearOption) {
      onChange(
        {
          gteDate: newDateWithNoTime(`${yearOption}-01-01`),
          lteDate: newDateWithNoTime(`${yearOption}-12-31`),
        },
        yearOption,
      );
    }

    handleClose();
  };

  const handleOpenOption = () => setOpenOptions(true);

  const handleOpenDateRange = () => {
    setOpenOptions(false);
    setIsCustomDateRangeShowing(true);
  };

  const selectOptions: string[] = useMemo(() => {
    let currentYear = new Date().getFullYear();
    const minimumYear = minimumDate!.getFullYear();
    const options = [];
    while (currentYear >= minimumYear) {
      options.push(`${currentYear}`);
      currentYear--;
    }

    return options;
  }, [minimumDate]);

  const popoverProps = {
    anchorEl: containerRef.current,
    sx: { mt: 1 },
    onClose: handleClose,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
  } as Omit<PopoverProps, 'open'>;

  return (
    <Fragment>
      <Button
        ref={containerRef}
        onClick={handleOpenOption}
        color="secondary"
      >
        <CalendarIcon/>
        {year ? year : `${format(gteDate, 'MMM dd, yyyy')} - ${format(lteDate, 'MMM dd, yyyy')}`}
      </Button>

      <Popover
        open={openOptions}
        {...popoverProps}
      >
        <Box p={1}>
          {selectOptions.map((yearOption) => (
            <MenuItem
              onClick={() => handleDateChangeWithYear(yearOption)}
              key={yearOption}
              selected={yearOption === year}
            >
              {yearOption}
            </MenuItem>
          ))}
          <MenuItem
            onClick={handleOpenDateRange}
          >
            {dictionary.customDateRange}
          </MenuItem>
        </Box>
      </Popover>

      <Popover
        open={isCustomDateRangeShowing}
        {...popoverProps}
      >
        <StaticDateRangePicker
          onSetCustomDateRange={handleDateChange}
          minDate={minimumDate}
          maxDate={maximumDate}
        />
      </Popover>
    </Fragment>
  );
};