import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useMount } from 'react-use';

import { Maybe, MaybeNull } from 'types';
import { addQuery, parseQuery } from 'helpers/query';


export const useQueryValue = <T = string>(name: string): Maybe<T> => {
  const location = useLocation();
  return useMemo(() => parseQuery(location.search)[name] as unknown as T, [location.search, name]);
};

export const useQuerySetState = <T = string>(name: string): (value?: MaybeNull<T>) => void => {
  const navigate = useNavigate();

  return useCallback((value?: MaybeNull<T>) => {
    navigate({
      search: addQuery(window.location.search, {
        [name]: value,
      }),
    });
  }, [navigate, name]);
};

export const useQueryState = <T = string>(name: string, defaultValue?: T): [Maybe<T>, (value?: MaybeNull<T>) => void] => {
  const navigate = useNavigate();

  const queryValue = useQueryValue<T>(name);
  const setValue = useQuerySetState<T>(name);

  useMount(() => {
    if (!queryValue && defaultValue) {
      navigate({
        search: addQuery(window.location.search, {
          [name]: defaultValue,
        }),
      }, { replace: true });
    }
  });

  const value = queryValue || defaultValue;

  return [
    value,
    setValue,
  ];
};
