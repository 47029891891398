import { snakeCase } from 'lodash';
import { camelizeObject, snakizeObject } from 'helpers/object';
import { formatToApi } from 'helpers/date';

import {
  ActivityBasedSubCategories,
  ActivityBasedSubCategoriesApi,
  ActivityBasedSubCategory,
  ActivityBasedSubCategoryApi, BulkClassifyTransaction, BulkClassifyTransactionApi,
  GetTransactionsParams,
  GetTransactionsParamsApi,
  ManualInputPayload,
  ManualInputPayloadApi, RevertManualInputTransactionsParams, RevertManualInputTransactionsParamsApi,
  Transaction,
  TransactionApi,
  TransactionEmissionClassification,
  TransactionEmissionClassificationApi,
  TransactionManualInput,
  TransactionManualInputAnswer,
  TransactionManualInputAnswerApi,
  TransactionManualInputApi,
  TransactionStatistic,
  TransactionStatisticApi,
  TransactionSubCategory,
  TransactionSubCategoryApi,
  TransactionWordAnalysis,
  TransactionWordAnalysisApi, UpdateManualInputTransactionsParams, UpdateManualInputTransactionsParamsApi,
} from './transactionClient.types';


export const mapTransactionData = (data: TransactionApi): Transaction => ({
  id: data.id,
  type: data.type,
  vatRate: data.vat_rate,
  netAmount: data.net_amount,
  grossAmount: data.gross_amount,
  currency: data.currency,
  vendor: data.vendor,
  vendorName: data.vendor_name,
  date: new Date(data.date),
  endDate: data.end_date ? new Date(data.end_date) : null,
  ledger: data.ledger,
  assignee: data.assignee,
  category: data.category,
  status: data.status,
  description: data.description,
  transactionData: data.transaction_data,
  externalId: data.external_id,
  integration: data.integration,
  emissionClassification: data?.emission_classification ? mapTransactionEmissionClassification(data?.emission_classification) : null,
  manualInputs: data.manual_inputs?.map(mapManualInputs),
  tags: data.tags,
});

export const mapManualInputs = (data: TransactionManualInputApi): TransactionManualInput => ({
  id: data.id,
  label: data.label,
  order: data.order,
  type: data.type,
  unit: data.unit,
  answer: data.answer ? mapManualInputAnswer(data.answer) : null,
});

export const mapManualInputAnswer = (data: TransactionManualInputAnswerApi): TransactionManualInputAnswer => ({
  value: data.value,
  emissionFactorTitle: data.emission_factor_title,
  manualInputId: data.manual_input_id,
});

export const mapTransactionEmissionClassification = (data: TransactionEmissionClassificationApi): TransactionEmissionClassification | null => {
  try {
    return ({
      id: data.id,
      transaction: data.transaction,
      emissionFactor: data?.emission_factor ? camelizeObject(data.emission_factor) : null,
      confidence: data.confidence,
      source: data.source,
      autoClassificationVersion: data.auto_classification_version,
      co2Kg: data.co2_kg,
    });
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const mapTransactionSubCategory = (data: TransactionSubCategoryApi): TransactionSubCategory => ({
  id: data.id,
  slug: data.slug,
  title: data.title,
  description: data.description,
  color: data.color,
  icon: data.icon,
  parent: data.parent,
});

export const mapActivityBasedSubCategory = (data: ActivityBasedSubCategoryApi): ActivityBasedSubCategory => ({
  count: data.count,
  subCategories: data.sub_categories?.map(mapTransactionSubCategory),
});

export const mapActivityBasedSubCategories = (data: ActivityBasedSubCategoriesApi): ActivityBasedSubCategories => ({
  open: mapActivityBasedSubCategory(data.open),
  completed: mapActivityBasedSubCategory(data.completed),
  skipped: mapActivityBasedSubCategory(data.skipped),
});

export const mapManualInputPayload = (data: ManualInputPayload): ManualInputPayloadApi => ({
  transaction_id: data.transactionId,
  manual_input_id: data.manualInputId,
  emission_factor_id: data.emissionFactorId,
  value: data.value,
});

export const mapManualInputTransaction = (data: any): any => {
  return {
    ...snakizeObject(data),
    date: data?.date && formatToApi(data.date),
  };
};

export const mapTransactionStatistics = (data: TransactionStatisticApi): TransactionStatistic => {
  const statistic: TransactionStatistic = camelizeObject(data);
  return {
    ...statistic,
    vendorName: statistic.vendorName || undefined,
    id: statistic.ledger || statistic.vendorName || 'NULL',
  };
};

export const mapTransactionWordAnalysis = (data: TransactionWordAnalysisApi): TransactionWordAnalysis => {
  const statistic: TransactionWordAnalysis = camelizeObject(data);
  return {
    ...statistic,
    id: statistic.word,
  };
};

export const mapTransactionParams = (params?: GetTransactionsParams): GetTransactionsParamsApi => {
  return {
    status: params?.status,
    tags: params?.tags,
    scope: params?.scope,
    ordering: params?.ordering || '-date',
    date_lte: params?.dateLte && formatToApi(params.dateLte),
    date_gte: params?.dateGte && formatToApi(params.dateGte),
    search: params?.search,
    offset: params?.offset,
    page: params?.page,
    limit: params?.limit,
    manual_input: params?.manualInput,
    sub_category_id: params?.subCategoryId,
    category_id: params?.categoryId,
    confidence: snakeCase(params?.confidence),
  };
};

export const mapSkipManualInputTransactionParams = (params: UpdateManualInputTransactionsParams): UpdateManualInputTransactionsParamsApi => {
  return {
    sub_category_id: params?.subCategoryId,
    date_gte: params?.dateGte && formatToApi(params.dateGte),
  };
};

export const mapRevertManualInputTransactionParams = (params: RevertManualInputTransactionsParams): RevertManualInputTransactionsParamsApi => {
  return {
    skipped: params.skipped,
    ...mapSkipManualInputTransactionParams(params),
  };
};

export const mapBulkClassify = (data: BulkClassifyTransaction[]): BulkClassifyTransactionApi[] => {
  return data.map((entry) => snakizeObject(entry));
};

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
};
