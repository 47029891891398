import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Alert,
  InputLabel,
  Grid,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/system';
import { yupResolver } from '@hookform/resolvers/yup';

import { InformationToolTipContent } from 'shared/components/layout/Tooltip/InformationToolTipContent';

import CustomTextField from 'shared/components/styledMui/CustomTextField';
import { createYupSchema } from 'views/LoggedIn/Employees/EmployeesHelpers';
import RadioButton from './RadioButton';
import MultipleSlider from './MultipleSlider';
import { FormComponentTypes, IFormProps } from './form.types';


export const Form: FC<IFormProps> = ({
  serverValidation,
  inputList,
  formOptions,
  submitButton,
  onSubmit,
  buttonLabel,
  className,
  validationSchema,
}) => {
  const { watch, control, handleSubmit, formState } = useForm({
    ...formOptions,
    //@ts-ignore
    resolver: validationSchema ? yupResolver(validationSchema) : null,
  });

  const formValues = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {serverValidation?.successText && !formState.isSubmitting && (
        <Alert severity="success">{serverValidation.successText}</Alert>
      )}

      {serverValidation?.errorText && !formState.isSubmitting && (
        <Alert severity="error">{serverValidation.errorText}</Alert>
      )}

      <Grid container className={className} columnSpacing="17px">
        {inputList
          .sort((a, b) => (a.order || 0) - (b.order || 0))
          .map(
            ({
              placeholder,
              name,
              label,
              tooltip,
              sliderCircleColor,
              dependentOn,
              gridProps,
              type,
              AfterTextField,
              component = FormComponentTypes.textfield,
              legendLabel,
              legendColors,
              highlightedLegendColors,
              direction,
              step,
              min,
              max,
              options,
              inputProps,
              autoComplete,
            }) => {
              if (dependentOn) {
                const dependentSchema = createYupSchema(
                  dependentOn.frontendConditions,
                );
                if (!dependentSchema.isValidSync(formValues)) return null;
              }
              return (
                <Grid key={name} item xs={12} mt={1} className={`survey-input-${component}`} {...gridProps}>
                  <Controller
                    name={name}
                    control={control}
                    render={({ fieldState, field }) => (
                      <Fragment>
                        <Stack alignItems="center" gap={1} direction="row">
                          <InputLabel className={className}>
                            {label}
                          </InputLabel>
                          {tooltip && (
                            <InformationToolTipContent
                              tooltipTitle={tooltip}
                              tooltipProps={{
                                className: className,
                                sx: { mb: 1 },
                              }}
                            />
                          )}
                        </Stack>
                        {component === FormComponentTypes.textfield && (
                          <CustomTextField
                            {...field}
                            type={type}
                            customvariant="upperLabel"
                            fullWidth
                            error={!!fieldState.error?.message}
                            placeholder={placeholder}
                            inputProps={inputProps}
                            autoComplete={autoComplete}
                          />
                        )}
                        {component === FormComponentTypes.slider && (
                          <MultipleSlider
                            options={options!}
                            sliderCircleColor={sliderCircleColor!}
                            legendColors={legendColors!}
                            highlightedLegendColors={highlightedLegendColors!}
                            legendLabel={legendLabel!}
                            minStr={min}
                            stepStr={step}
                            maxStr={max}
                            {...field}
                          />
                        )}
                        {component === FormComponentTypes.radio && (
                          <RadioButton
                            {...field}
                            direction={direction!}
                            options={options!}
                          />
                        )}
                        {fieldState.error?.message && <FormHelperText sx={{ ml: 2, mt: 1, fontSize: '14px' }} error>{fieldState.error?.message}</FormHelperText>}
                        {AfterTextField}

                      </Fragment>
                    )}
                  />
                </Grid>
              );
            },
          )}
        <Grid
          item
          xs={12}
          pt={2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          {submitButton ? (
            submitButton
          ) : (
            <LoadingButton
              fullWidth
              type="submit"
              sx={{
                height: 48,
                borderRadius: 2,
                fontSize: 15,
                marginTop: 3,
                fontWeight: 600,
              }}
              variant="contained"
              color="secondary"
              loading={formState.isSubmitting}
            >
              {buttonLabel}
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
