import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const ChatBubbleBottomCenterTextIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00078 1.59998C6.21204 1.59998 4.45632 1.74322 2.74459 2.01897C1.59549 2.20408 0.800781 3.21058 0.800781 4.34068V8.45927C0.800781 9.58937 1.59549 10.5959 2.74459 10.781C3.6789 10.9315 4.62633 11.0425 5.5851 11.1123C5.80886 11.1286 6.00174 11.2562 6.09876 11.4428L7.46845 14.0768C7.57172 14.2754 7.77694 14.4 8.00078 14.4C8.22462 14.4 8.42984 14.2754 8.53311 14.0768L9.9028 11.4428C9.99982 11.2562 10.1927 11.1286 10.4165 11.1123C11.3752 11.0425 12.3227 10.9315 13.257 10.781C14.4061 10.5959 15.2008 9.58937 15.2008 8.45927V4.34068C15.2008 3.21058 14.4061 2.20408 13.257 2.01897C11.5452 1.74322 9.78952 1.59998 8.00078 1.59998ZM5.40078 4.79998C5.06941 4.79998 4.80078 5.0686 4.80078 5.39998C4.80078 5.73135 5.06941 5.99998 5.40078 5.99998H10.6008C10.9322 5.99998 11.2008 5.73135 11.2008 5.39998C11.2008 5.0686 10.9322 4.79998 10.6008 4.79998H5.40078ZM5.40078 6.79998C5.06941 6.79998 4.80078 7.0686 4.80078 7.39998C4.80078 7.73135 5.06941 7.99998 5.40078 7.99998H8.20078C8.53215 7.99998 8.80078 7.73135 8.80078 7.39998C8.80078 7.0686 8.53215 6.79998 8.20078 6.79998H5.40078Z"
      fill="currentColor"
    />
  </IconContainer>
);
