import React from 'react';
import { useLocalStorage } from 'react-use';
import { Box, IconButton } from '@mui/material';
import { ChevronDoubleLeftIcon } from 'shared/icons/NavIcons/ChevronDoubleLeftIcon';
import { Authenticated } from 'shared/components/Authenticated/Authenticated';
import { WorkspaceMenu } from 'shared/components/layout/WorkspaceMenu/WorkspaceMenu';
import { UserNavItems } from './UserNavItems';


export const UserNav = () => {

  const [isCollapsed, setIsCollapsed] = useLocalStorage<boolean>('user-nav-collapsed', false);

  const handleCollapse = React.useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, setIsCollapsed]);

  return (
    <Authenticated>
      <Box
        component="nav"
        className={`user-nav ${isCollapsed ? 'is-collapsed' : ''}`}
      >
        <div className="header">
          <WorkspaceMenu/>
          <IconButton onClick={handleCollapse} color="secondary">
            <ChevronDoubleLeftIcon/>
          </IconButton>
        </div>

        <UserNavItems isCollapsed={!!isCollapsed}/>
      </Box>
    </Authenticated>
  );
};
