import React from 'react';
import { Button } from '@mui/material';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowsUpDown from 'shared/components/layout/Icons/ArrowsUpDown';

import { IPriceSortButtonProps } from './CarbonRemovalTypes';


const PriceSortButton: React.FunctionComponent<IPriceSortButtonProps> = ({
  onSortPrice,
  label,
  priceSorted,
}) => {
  const iconVariants = {
    asc: <ArrowUpwardIcon/>,
    desc: <ArrowDownwardIcon/>,
  };

  return (
    <Button
      color="secondary"
      onClick={onSortPrice}
    >
      {label}
      {priceSorted ? (
        iconVariants[priceSorted]
      ) : (
        <ArrowsUpDown/>
      )}
    </Button>
  );
};

export default PriceSortButton;
