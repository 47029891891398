export const dataKnowledgePoints = [
  {
    id: 1,
    title: 'Activity data',
    category: 'Carbon accounting',
    desc: 'The number of units of a certain product or material that a corporation has acquired is specified by activity data. It could be liters of gasoline, pounds of material, and so on. Activity data allows for more precise emissions estimates in carbon accounting than spend-based data.',
  },
  {
    id: 2,
    title: 'Biomass',
    category: 'Energy',
    desc: 'Biomass refers to all organic matter that can be transformed into energy. By organic matter, we mean both materials of plant origin (food residues, wood, leaves) and those of animal origin (animal corpses, living beings in the soil).',
  },
  {
    id: 3,
    title: 'Blue economy',
    category: 'Economy',
    desc: 'Economic activities that generate long-term riches from the oceans and coastlines of the world.',
  },
  {
    id: 4,
    title: 'Carbon accounting',
    category: 'Carbon accounting',
    desc: 'Carbon accounting is the process of calculating the amount of carbon dioxide equivalents (CO2e) emitted by a firm, state, or other entity.',
  },
  {
    id: 5,
    title: 'Carbon negative - Climate positive',
    category: 'Carbon reduction',
    desc: 'If the net consequence of a company\'s actions is a reduction in the quantity of carbon in the atmosphere, the company is carbon negative (or climate positive). This is a step up from net zero.',
  },
  {
    id: 6,
    title: 'Carbon removal/sequestration',
    category: 'Carbon reduction',
    desc: 'Carbon removal is the process of removing carbon from the atmosphere and storing it in a safe location where it will not contribute to climate change.',
  },
  {
    id: 7,
    title: 'Carbon tax',
    category: 'Regulations',
    desc: 'A carbon tax is a charge that is imposed on the carbon emissions that are produced in the production of products and services. A carbon tax lowers overall emissions by reducing demand for high-emission goods and services while also rewarding efforts to make them less so.',
  },
  {
    id: 8,
    title: 'Climate action',
    category: 'Other',
    desc: 'Activities aimed at combating climate change and its consequences, mainly through the reduction of greenhouse gas emissions.',
  },
  {
    id: 9,
    title: 'Compostable',
    category: 'Products',
    desc: 'A material that, under the correct conditions, degrades fully into non-toxic components that can sustain plant growth.',
  },
  {
    id: 10,
    title: 'Doughnut economics',
    category: 'Economy',
    desc: 'A doughnut-shaped figure represents an economic theory for functioning within the bounds of social and environmental sustainability.',
  },
  {
    id: 11,
    title: 'Downstream emissions',
    category: 'Carbon accounting',
    desc: 'Downstream emissions are those that arise after a company\'s goods and services have been sold. They make up a company\'s scope 3 emissions, along with upstream emissions (or supply chain emissions).',
  },
  {
    id: 12,
    title: 'Ecological or nature restoration',
    category: 'Nature',
    desc: 'Assisting an ecosystem in regaining its former, more biodiverse state.',
  },
  {
    id: 13,
    title: 'E-waste',
    category: 'Products',
    desc: 'Electronic appliances such as cell phones, laptops, and televisions that have been discarded.',
  },
  {
    id: 14,
    title: 'ESG reporting',
    category: 'Corporate',
    desc: 'An ESG report is a document that a corporation publishes regarding its influence on the environment, social issues, and governance.',
  },
  {
    id: 15,
    title: 'GHG Protocol',
    category: 'Carbon accounting',
    desc: 'The most extensively utilized greenhouse gas accounting rules are provided by the GHG Protocol. Their corporate accounting and reporting standard lays forth standards and guidelines for businesses, and it\'s the foundation for almost every corporate reporting program in the world.',
  },
  {
    id: 16,
    title: 'Global warming',
    category: 'Climate',
    desc: 'An increase in the world\'s average temperature caused by human actions that release greenhouse gases into the atmosphere, such as burning fossil fuels.',
  },
  {
    id: 17,
    title: 'Indirect emissions',
    category: 'Carbon accounting',
    desc: 'The indirect emissions of a corporation come from their purchased energy (scope 2 emissions) as well as their value chain (scope 3 emissions).',
  },
  {
    id: 18,
    title: 'Life cycle assessment (LCA)',
    category: 'Carbon accounting',
    desc: 'A life cycle assessment (LCA) is a process for assessing a commercial product\'s or service\'s environmental impact at all phases of its life cycle, from cradle to end-of-life.',
  },
  {
    id: 19,
    title: 'Modern slavery',
    category: 'Other',
    desc: 'Human trafficking, slavery, and slavery-like activities such as servitude, forced labor, forced marriage, the sale and exploitation of minors, and debt bondage are all covered under this umbrella phrase.',
  },
  {
    id: 20,
    title: 'Net zero',
    category: 'Carbon reduction',
    desc: 'A state in which the amount of greenhouse gases emitted into the atmosphere is balanced by removing an equal amount of greenhouse gases is known as net zero. Net zero can be a goal for individuals, businesses, regions, or the entire planet.',
  },
  {
    id: 21,
    title: 'Product stewardship',
    category: 'Products',
    desc: 'A notion in which companies are held accountable for the environmental impact of the items they create, market, or buy. This includes end-of-life management and all stages of the product\'s life cycle.',
  },
  {
    id: 22,
    title: 'Recyclable',
    category: 'Products',
    desc: 'A product or material that can be collected, processed, and turned into something new.',
  },
  {
    id: 23,
    title: 'Shared value',
    category: 'Corporate',
    desc: 'A corporate management principle that looks for market possibilities to alleviate social concerns. \'Creating Shared Value\' was initially published in the Harvard Business Review in 2011, and is based on the idea that a company\'s competitiveness and the health of its surrounding community are inextricably linked.',
  },
  {
    id: 24,
    title: 'Social enterprise',
    category: 'Economy',
    desc: 'Businesses that work to solve social issues, develop communities, or protect the environment. They put their revenues back into the company or into the community.',
  },
  {
    id: 25,
    title: 'Sustainable design',
    category: 'Products',
    desc: 'Designing products, services, and the built environment in accordance with sustainable principles.',
  },
  {
    id: 26,
    title: 'SBTi',
    category: 'Resources',
    desc: 'Companies are encouraged to set science-based targets in accordance with the Paris Agreement through the Science-Based Targets initiative (SBTi). They offer both general and industry-specific advice on how to attain these goals.',
  },
  {
    id: 27,
    title: 'Scope 3',
    category: 'Carbon accounting',
    desc: 'Scope 3 emissions (also known as value chain emissions) are any indirect emissions that occur in a company\'s value chain that aren\'t covered by scope 2. These emissions are a result of the company\'s operations, but they come from sources it does not own or control.',
  },
  {
    id: 28,
    title: 'Tree planting',
    category: 'Nature',
    desc: 'Planting trees is perhaps the most well-known method of carbon reduction. There are two sorts of tree-planting programs: reforestation projects and afforestation projects (where there were previously no forests). Some tree-planting methods are less effective than stated, and organizations that adopt them risk being accused of greenwashing.',
  },
  {
    id: 29,
    title: 'Value chain emissions',
    category: 'Carbon accounting',
    desc: 'The emissions that occur either upstream (in the supply chain) or downstream (during product use and disposal) of the company are referred to as value chain emissions (also known as scope 3 emissions). Because value chain emissions account for 90 percent of a company\'s overall emissions, it\'s critical that these emissions be considered when setting reduction goals.',
  },
  {
    id: 30,
    title: 'Biodegradable',
    category: 'Products',
    desc: 'Given the correct conditions and the presence of microbes, fungi, or bacteria, it can break down and merge back in with the earth. No poisons are left behind in the ideal situation, although this is not always the case.',
  },
  {
    id: 31,
    title: 'Biomimicry',
    category: 'Nature',
    desc: 'A type of design that aims to imitate nature in order to find long-term answers. The goal is to develop products and services that are well-suited to long-term life on Earth.',
  },
  {
    id: 32,
    title: 'Business transformation',
    category: 'Corporate',
    desc: 'Rather of making gradual modifications to the existing system, making large and fundamental changes to the way business is conducted.',
  },
  {
    id: 33,
    title: 'Carbon footprint',
    category: 'Carbon accounting',
    desc: 'All direct and indirect greenhouse gas emissions linked with a certain product or activity.',
  },
  {
    id: 34,
    title: 'Carbon reduction',
    category: 'Carbon reduction',
    desc: 'The process of reducing a company\'s GHG emissions is known as carbon reduction. This can be accomplished, for example, by moving to more environmentally friendly suppliers or renewable energy providers. Carbon reduction is an important step on the road to net zero emissions.',
  },
  {
    id: 35,
    title: 'Carbon target',
    category: 'Carbon reduction',
    desc: 'A carbon target is a promise by a firm to reduce its greenhouse gas emissions by a certain amount by a certain year.',
  },
  {
    id: 36,
    title: 'Circular economy',
    category: 'Economy',
    desc: 'Waste and pollution are eliminated, products and materials are reused, and natural systems are regenerated in this economy.',
  },
  {
    id: 37,
    title: 'Collective Impact',
    category: 'Other',
    desc: 'Large-scale transformation requires cross-sector collaboration.',
  },
  {
    id: 38,
    title: 'Conscious capitalism',
    category: 'Economy',
    desc: 'A type of capitalism aimed at benefiting both people and the environment.',
  },
  {
    id: 39,
    title: 'CSRD',
    category: 'Regulations',
    desc: 'By adding more detailed reporting criteria and raising the number of corporations required to comply, the Corporate Sustainability Reporting Directive (CSRD) will set the benchmark by which roughly 50,000 EU companies will have to report their climate and environmental impact. Companies will be required to begin reporting under the new sustainability reporting criteria in 2024, utilizing data from the fiscal year 2023.',
  },
  {
    id: 40,
    title: 'Double-counting',
    category: 'Carbon accounting',
    desc: 'If the same climate investment is sold many times, a carbon removal project engages in double-counting. As a result, avoiding double-counting is a key element of high-quality climate investment.',
  },
  {
    id: 41,
    title: 'Emission factor',
    category: 'Carbon accounting',
    desc: 'An emission factor (EF) measures the emissions associated with one unit of a specified activity. \n\nEFs can be spend-based (per amount spent on a given activity) or activity-based (based on the activity metrics: m³ of water, km of air travel...).',
  },
  {
    id: 42,
    title: 'Ecological or nature regeneration',
    category: 'Nature',
    desc: 'Enabling, supporting, and improving natural processes to improve ecological health and biodiversity.',
  },
  {
    id: 43,
    title: 'Ethical investment',
    category: 'Other',
    desc: 'Investment in activities that are regarded unethical and unsustainable is avoided in favor of those that are less destructive, benign, or socially and environmentally beneficial.',
  },
  {
    id: 44,
    title: 'Fair trade',
    category: 'Economy',
    desc: 'An alternative to traditional commerce that is built on a partnership between producers and consumers to ensure that farmers and workers share in the advantages of trade fairly.',
  },
  {
    id: 45,
    title: 'Greenwashing',
    category: 'Corporate',
    desc: 'Greenwashing is the process of conveying a false impression or providing misleading information about how a company\'s products are more environmentally sound. Greenwashing is considered an unsubstantiated claim to deceive consumers into believing that a company\'s products are environmentally friendly.',
  },
  {
    id: 46,
    title: 'Hybrid vehicle',
    category: 'Energy',
    desc: 'A hybrid car uses at least one electric motor in addition to a gasoline engine to move the vehicle, and sometimes its braking system uses regenerative braking to recover energy.',
  },
  {
    id: 47,
    title: 'IPCC',
    category: 'Resources',
    desc: 'For more than 30 years, the IPCC (Intergovernmental Panel on Climate Change) has been assessing the state of knowledge on climate change, its causes and impacts. It also identifies the possibilities to limit the extent of warming and the severity of its impacts and to adapt to the expected changes. The IPCC reports provide a regular review of the most advanced knowledge. ',
  },
  {
    id: 48,
    title: 'Microplastics',
    category: 'Nature',
    desc: 'Plastic pollution that has resulted in little fragments of plastic, less than 5mm in length, being detected on land and water.',
  },
  {
    id: 49,
    title: 'Natural capital',
    category: 'Nature',
    desc: 'Natural \'assets\' around the earth, include geology, soil, air, water, and all living things.',
  },
  {
    id: 50,
    title: 'Paris Agreement',
    category: 'Regulations',
    desc: 'The Paris Agreement is an international climate change deal that was signed in 2015 and has been ratified by practically every government on the planet. The Agreement commits its members to keeping global warming well below 2°C above pre-industrial levels, with a preference for 1.5°C.',
  },
  {
    id: 51,
    title: 'Recycling',
    category: 'Products',
    desc: 'Taking resources that would otherwise be discarded and converting them into reusable materials. Materials from a product are recycled to make the same, or a similar, product without considerable degradation or waste in closed loop recycling. This can be done again and over again. In an open loop recycling system, a product\'s materials are reused to create a new product.',
  },
  {
    id: 52,
    title: 'Renewable energy',
    category: 'Energy',
    desc: 'Natural sources of energy, such as wind, water, and sunlight, that are constantly renewed.',
  },
  {
    id: 53,
    title: 'Social capital',
    category: 'Other',
    desc: 'The worth of all social networks as a whole; the connections and common values in society that allow people and groups to collaborate.',
  },
  {
    id: 54,
    title: 'Sustainable business',
    category: 'Economy',
    desc: 'A financially viable, socially ethical, and environmentally conscientious company.',
  },
  {
    id: 55,
    title: 'Systems thinking',
    category: 'Other',
    desc: 'A problem-solving strategy that considers \'issues\' as part of a larger, dynamic system. It\'s the process of figuring out how various elements interact as a whole.',
  },
  {
    id: 56,
    title: 'Scope 2',
    category: 'Carbon accounting',
    desc: 'The indirect emissions produced by the production of purchased energy are referred to as Scope 2 emissions.',
  },
  {
    id: 57,
    title: 'SFDR',
    category: 'Regulations',
    desc: 'The Sustainable Finance Disclosure Regulation (SFDR) is a piece of EU legislation that governs how financial advisors and financial market players must disclose sustainability information.',
  },
  {
    id: 58,
    title: 'Supply chain emissions',
    category: 'Carbon accounting',
    desc: 'Upstream emissions in a company\'s supply chain are known as supply chain emissions. Scope 3 emissions include supply chain emissions. Upstream emissions and supply chain emissions are the same definition.',
  },
  {
    id: 59,
    title: 'Upstream emissions',
    category: 'Carbon accounting',
    desc: 'Upstream emissions are those that occur earlier in the supply chain of a corporation. Upstream emissions are classified as scope 3 emissions. Upstream emissions and supply chain emissions are the same definition.',
  },
  {
    id: 60,
    title: 'Zero waste',
    category: 'Other',
    desc: 'The conservation of all resources by means of responsible production, consumption, reuse, and recovery of products, packaging, and materials without burning and with no discharges to land, water, or air that threaten the environment or human health.',
  },
  {
    id: 61,
    title: 'Agroecology',
    category: 'Nature',
    desc: 'Agroecology is a way of designing production systems that rely on the functionalities offered by ecosystems. It amplifies them while aiming to reduce pressure on the environment (e.g., reducing greenhouse gas emissions, limiting the use of plant protection products) and to preserve natural resources. It is a question of using nature as much as possible as a production factor by maintaining its capacity for renewal.',
  },
  {
    id: 62,
    title: 'Biogas',
    category: 'Energy',
    desc: 'Biogas is the gas produced by the fermentation of organic animal or vegetable matter in the absence of oxygen.',
  },
  {
    id: 63,
    title: 'Business resilience',
    category: 'Economy',
    desc: 'An organization\'s ability to adapt in a changing environment in order to fulfill its goals and succeed.',
  },
  {
    id: 64,
    title: 'Carbon dioxide equivalent (CO₂e)',
    category: 'Carbon accounting',
    desc: 'The carbon dioxide equivalent (CO₂e) of any greenhouse gas is the quantity of CO₂ that would warm the globe as much as that gas. CO₂e is a scale that can be used to compare the climate effects of all greenhouse gases.',
  },
  {
    id: 65,
    title: 'Carbon neutral',
    category: 'Carbon reduction',
    desc: 'When a company\'s primary business activities produce no additional GHG emissions, it is said to be carbon neutral. This suggests that a corporation can achieve carbon neutrality without addressing its scope 3 emissions, despite the fact that this is where most companies\' emissions originate. Companies must move beyond carbon neutrality to achieve net zero emissions in order to meet the Paris Agreement\'s goals.',
  },
  {
    id: 66,
    title: 'Carbon sink',
    category: 'Carbon reduction',
    desc: 'Carbon must be stored once it has been extracted from the atmosphere (see carbon removal). This type of storage is referred to as a carbon sink.',
  },
  {
    id: 67,
    title: 'CDP',
    category: 'Resources',
    desc: 'Companies, communities, and states can use the CDP framework to report their environmental effect.',
  },
  {
    id: 68,
    title: 'Climate change',
    category: 'Climate',
    desc: 'A change in global weather patterns or average temperatures over a long period of time. According to scientific study, the rate of temperature rise during the Industrial Revolution is extraordinarily high when compared to climate change trends throughout Earth\'s history. Droughts, sea level rises, and retreating glaciers can all be caused by rising temperatures.',
  },
  {
    id: 69,
    title: 'Conscious consumerism',
    category: 'Other',
    desc: 'Consumers vote with their wallets by choosing ethically produced goods and services.',
  },
  {
    id: 70,
    title: 'Carbon credit',
    category: 'Carbon accounting',
    desc: 'A generic word for any tradable certificate or permit that allows a corporation to emit one tonne of CO₂ equivalent under an emissions trading scheme. This includes CO₂ as well as any other greenhouse gas. ',
  },
  {
    id: 71,
    title: 'Direct emissions',
    category: 'Carbon accounting',
    desc: 'Direct emissions are those that a corporation produces as a result of its operations. This includes, for example, electricity generation, material manufacturing and processing, waste processing, and transportation utilizing the company\'s own vehicle fleet. Scope 1 emissions are also known as direct emissions.',
  },
  {
    id: 72,
    title: 'Degrowth',
    category: 'Economy',
    desc: 'The term degrowth refers to an economic situation during which the economic wealth produced does not increase or even decrease. This concept is to be distinguished from the recession. It is based on the principle of awareness of a finite world, with limited resources, and on the idea that only a reduction in global production and consumption can ensure the future of humanity and the preservation of the planet.',
  },
  {
    id: 73,
    title: 'Ecosystem',
    category: 'Nature',
    desc: 'In ecology, an ecosystem is a group of living beings interacting with their environment. The components of the ecosystem develop a dense network of dependencies, exchanges of energy, information and matter allowing the maintenance and development of life. ',
  },
  {
    id: 74,
    title: 'Environmental management systems',
    category: 'Regulations',
    desc: 'A collection of procedures and policies that allow a company to lessen its environmental effect. The framework designed by the International Organization for Standardisation (ISO) for the ISO 14001 standard is the most widely utilized.',
  },
  {
    id: 75,
    title: 'EU Taxonomy',
    category: 'Regulations',
    desc: 'The EU taxonomy is a framework that gives an uniform language for identifying whether economic activities are ecologically sustainable to enterprises, investors, and regulators.',
  },
  {
    id: 76,
    title: 'Greenhouse gas (GHG)',
    category: 'Climate',
    desc: 'Greenhouse gases, or GHGs, are compound gases that trap heat or longwave radiation in the atmosphere. Their presence in the atmosphere makes the Earth\'s surface warmer. Sunlight or shortwave radiation easily passes through these gases and the atmosphere.',
  },
  {
    id: 77,
    title: 'The Greenhouse Effect',
    category: 'Climate',
    desc: 'The greenhouse effect is a natural phenomenon that is essential to life on Earth. Only a part of the sun\'s rays cross the atmosphere and reach the Earth. Under the effect of the Sun\'s rays, the ground heats up and emits infrared rays. These rays are captured by the greenhouse gases which heat the planet.',
  },
  {
    id: 78,
    title: 'Integrated reporting',
    category: 'Corporate',
    desc: 'A method of corporate reporting that combines financial and non-financial (such as sustainability) data into a single document to illustrate how a firm is doing.',
  },
  {
    id: 79,
    title: 'Microfinance',
    category: 'Economy',
    desc: 'Individuals and small enterprises without access to regular banking services might use this as a source of financial services. By encouraging entrepreneurs to develop enterprises, support their families, and alter their communities, it can be a long-term solution to poverty.',
  },
  {
    id: 80,
    title: 'Nature-based solutions',
    category: 'Nature',
    desc: 'Natural-inspired and supported solutions that may have environmental, economic, and societal benefits while enhancing resilience.',
  },
  {
    id: 81,
    title: 'Offsetting',
    category: 'Carbon reduction',
    desc: 'Carbon offsetting is the technique of removing an amount of carbon from the atmosphere to balance a company\'s carbon emissions, this is the ultimate step in a company\'s net zero path. However, companies must be careful of the effectiveness of their offsets, or risk being accused of greenwashing.',
  },
  {
    id: 82,
    title: 'Permanence',
    category: 'Carbon reduction',
    desc: 'Permanence is a principle for evaluating carbon removal projects. A carbon removal project is permanent if it will result in a quantifiable piece of carbon being kept out of the air forever.',
  },
  {
    id: 83,
    title: 'Remanufacturing',
    category: 'Products',
    desc: 'Using a combination of reused, repaired, and new parts, a product is rebuilt to its original specs.',
  },
  {
    id: 84,
    title: 'Sharing economy',
    category: 'Economy',
    desc: 'Instead of having private ownership of things or services, users share access to them. ',
  },
  {
    id: 85,
    title: 'Sustainability',
    category: 'Other',
    desc: 'Sustainability, is a long-term resilience balance of society, economy, and environment.',
  },
  {
    id: 86,
    title: 'Sustainable Development Goals (SDGs)',
    category: 'Resources',
    desc: 'A set of 17 interconnected global goals aimed at ending poverty, protecting the environment, and ensuring peace and prosperity for all people by 2030. In 2015, the United Nations accepted them.',
  },
  {
    id: 87,
    title: 'Scope 1',
    category: 'Carbon accounting',
    desc: 'Scope 1 emissions are the direct GHG emissions that a corporation produces as a result of its operations. This includes electricity generation, material manufacturing and processing, trash management, and transportation using the company\'s own vehicle fleet.',
  },
  {
    id: 88,
    title: 'SECR',
    category: 'Regulations',
    desc: 'Streamlined Energy and Carbon Reporting (SECR) is a UK law. It mandates all publicly traded enterprises, including huge corporations, to report on their energy consumption and corresponding greenhouse gas emissions.',
  },
  {
    id: 89,
    title: 'Spend-based data',
    category: 'Carbon accounting',
    desc: 'The spend-based approach of calculating GHG emissions multiplies the financial worth of a purchased commodity or service by an emission factor. For example, if you buy a chair, a spend-based approach will consider the fact that you purchased a piece of furniture, but use general industry data and ignore whether the chair is made of iron or wood. ',
  },
  {
    id: 90,
    title: 'UN Global Compact',
    category: 'Resources',
    desc: 'The UN Global Compact is a program that encourages businesses to adopt ecologically and socially sustainable practices and to report on their progress.',
  },
  {
    id: 91,
    title: 'Waste stream',
    category: 'Products',
    desc: 'The entire movement of a particular sort of garbage from residential or industrial regions through recovery, recycling, or disposal.',
  },
  {
    id: 92,
    title: 'Baseline year',
    category: 'Carbon reduction',
    desc: 'A baseline year serves as a benchmark against which future emission reductions are evaluated. Additionally, the chosen baseline year has a significant impact on emission targets.',
  },
];

export const menuItems = [...new Set(dataKnowledgePoints.map((visibleItem) => visibleItem.category))];
