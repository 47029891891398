// ** MUI Theme Provider
import { PaletteMode, ThemeOptions } from '@mui/material';


// ** Theme Override Imports
import palette from './palette';


const themeOptions = (mode: PaletteMode): ThemeOptions => {

  const themeConfig = {
    palette: palette(mode),
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Inter"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),

      fontSize: 12,
      fontWeightLight: 400,
      fontWeightRegular: 400,
      fontWeightBold: 600,
    },

    shape: {
      borderRadius: 4,
    },
  };

  return themeConfig;
};

export default themeOptions;
