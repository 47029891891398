import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, IconButton, Typography, Link } from '@mui/material';
import { object as yupObj, string as yupStr } from 'yup';

import { featureFlags, routes } from 'config';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { emailRegex } from 'helpers/validation';
import { UserInvite } from 'clients/users/userClient.types';
import { useSignin } from 'shared/hooks/useSignin';
import { ReactComponent as GoogleLogo } from 'shared/img/google.svg';
import { ReactComponent as MicrosoftLogo } from 'shared/img/microsoft.svg';
import { LoggedOutActionsLayout } from 'shared/components/layout/LoggedOutActionsLayout/LoggedOutActionsLayout';
import {
  Form,
} from 'shared/components/form/Form/Form';
import { IServerValidation } from 'shared/components/form/Form/form.types';


import { LoggedOutHeader } from './partials/LoggedOutHeader';
import { FormDivider } from './partials/FormDivider';
import { SwitchLoggedOutPage } from './partials/SwitchLoggedOutPage';


type LoginProps = {
  invite?: UserInvite;
  token?: string;
}

export const Login: FC<LoginProps> = (props) => {
  const { invite, token } = props;

  const [serverValidation, setServerValidation] = useState<IServerValidation>({
    successText: undefined,
    errorText: undefined,
  });

  const { dictionary } = useContext(LocalizationContext);

  const { signIn, signInWithSSO } = useSignin();

  const onSSOLogin = useCallback(
    async (provider: string) => {
      try {
        await signInWithSSO(provider, token);
      } catch (e: any) {
        setServerValidation({ errorText: e.message });
      }
    },
    [signInWithSSO, token],
  );

  const submitHandler = useCallback(
    async ({ email, password }: any) => {
      try {
        await signIn(email, password, token);
      } catch (e: any) {
        if (
          [
            'auth/invalid-email',
            'auth/user-not-found',
            'auth/wrong-password',
          ].includes(e.error)
        ) {
          setServerValidation({
            errorText: dictionary.auth.validations.emailOrPasswordWrong,
          });
        } else {
          setServerValidation({ errorText: dictionary.somethingWentWrong });
        }
      }
    },
    [dictionary, signIn, token],
  );
  const inputList = useMemo(() => [
    {
      name: 'email',
      rules: {
        required: dictionary.forms.validations.required,
        pattern: {
          value: emailRegex,
          message: dictionary.forms.validations.invalidEmail,
        },
      },
      label: dictionary.email,
      autoComplete: 'email',
    },
    {
      name: 'password',
      rules: {
        required: dictionary.forms.validations.required,
      },
      label: dictionary.forms.fieldPassword,
      type: 'password',
      AfterTextField: (
        <Typography align="right">
          <Link
            component={RouterLink}
            to={routes.resetPassword}
            color="primary"
            variant="body2"
          >
            {dictionary.resetPassword}
          </Link>
        </Typography>
      ),
      autoComplete: !!invite? 'current-password' : 'new-password',
    },
  ],
  [dictionary, invite],
  );

  const validationSchema = yupObj().shape({
    email: yupStr()
      .required(dictionary.forms.validations.required)
      .matches(emailRegex, dictionary.forms.validations.invalidEmail),
    password: yupStr().required(dictionary.forms.validations.required),
  });

  return (
    <LoggedOutActionsLayout>
      <LoggedOutHeader
        title={invite ? dictionary.invite.title : dictionary.forms.signin.title}
        subtitle={invite ? dictionary.invite.subtitle(invite?.company?.name) : dictionary.forms.signin.desc}
      />
      <Form
        formOptions={{
          defaultValues: {
            password: '',
            email: invite?.email || '',
          },
        }}
        buttonLabel={dictionary.forms.signin.btnLabel}
        validationSchema={validationSchema}
        inputList={inputList}
        onSubmit={submitHandler}
        serverValidation={serverValidation}
      />
      <FormDivider/>
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          '& svg': {
            width: 24,
            height: 24,
          },
        }}
      >
        {featureFlags.googleSSO && (
          <IconButton
            sx={{
              border: '1px solid #E8E8E8',
              width: '100%',
              borderRadius: '8px',
              gap: 1.4,
              height: 48,
            }}
            onClick={() => onSSOLogin('google')}
          >
            <GoogleLogo/>
            <Typography variant="buttonText">
              {dictionary.signInWithGoogle}
            </Typography>
          </IconButton>
        )}
        {featureFlags.microsoftSSO && (
          <IconButton
            sx={{
              border: '1px solid #E8E8E8',
              width: '100%',
              borderRadius: '8px',
              gap: 1.4,
              height: 48,
            }}
            onClick={() => onSSOLogin('microsoft')}
          >
            <MicrosoftLogo/>
            <Typography variant="buttonText">
              {dictionary.signInWithGoogle}
            </Typography>
          </IconButton>
        )}
      </Box>

      {!invite && <SwitchLoggedOutPage
        text={dictionary.auth.dontHaveAccount}
        linkText={dictionary.auth.buttonSignUp}
        linkTo={routes.signup}
      />}
    </LoggedOutActionsLayout>
  );
};