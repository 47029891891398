interface IRotate {
  degreeStart: number;
  degreeFinal: number;
}

export const rotateAnimation = (rotate: IRotate) => {
  const animation = {
    from: {
      transform: `rotate(${rotate.degreeStart}deg)`,
    },
    to: {
      transform: `rotate(${rotate.degreeFinal}deg)`,
    },
  };
  return animation;
};
