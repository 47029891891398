import { request } from 'clients/baseClient';
import { GetChartParams } from './chartClient.types';
import { mapChartParams } from './chartClient.formatter';


const chartsApiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getChart = (params: GetChartParams) => {
  return request({
    options: {
      url: `${chartsApiBaseUrl}/charts/`,
      method: 'GET',
      params: mapChartParams(params),
    },
  });
};

export const chartsClient = {
  getChart,
};
