import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { transactionClient } from 'clients/transactions/transactionClient';
import { GetTransactionsParams, Transaction } from 'clients/transactions/transactionClient.types';
import { useLocalizedCurrency } from './useLocalizedCurrency';


type Options = {
  enabled: boolean
};

interface LocalizedTransaction extends Transaction {
  localizedAmount?: number | null | string;
}

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useTransactions = (params: GetTransactionsParams = {}, options: Partial<Options> = defaultOptions) => {

  const [selected, setSelected] = useState<number[]>([]);
  const [isWholeDatasetSelected, setIsWholeDatasetSelected] = useState<boolean>(false);

  const localizeCurrency = useLocalizedCurrency();

  const updateIsWholeDatasetSelected = useCallback((isSelected: boolean) => {
    setIsWholeDatasetSelected(isSelected);
    setSelected([...selected].reverse());
  }, [selected]);

  const mappedParams = useMemo(() => ({
    ...params,
    ...params?.pageSize && { limit: params.pageSize },
    ...params?.pageSize && params?.page && { offset: params.pageSize * params.page },
  }), [params]);

  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.transactions.getTransactions, mappedParams],
    () => transactionClient.getTransactions(mappedParams),
    {
      enabled: options.enabled,
    },
  );

  const isAllSelected = useMemo<boolean>(() => {
    const resultsCount = (data?.results || []).length;
    const hasResults = resultsCount > 0;
    const isAllSelected = selected.length === resultsCount;

    return hasResults && isAllSelected;
  }, [data, selected]);

  useEffect(() => {
    if (!isAllSelected) {
      setIsWholeDatasetSelected(false);
    }
  }, [isAllSelected]);

  const showSelectAll = useMemo<boolean>(() => {
    const isMultiPageResult = (data?.count || 0) > (params?.pageSize || 0);
    return isAllSelected && isMultiPageResult;
  }, [data, isAllSelected, params]);

  const localizedData: LocalizedTransaction[] | [] = data?.results ? data.results.map(result => ({
    ...result,
    localizedAmount: localizeCurrency(result.netAmount, result.currency),
  })) : [];

  return {
    status,
    error,
    count: data?.count || 0,
    transactions: localizedData,
    selected,
    setSelected,
    isAllSelected,
    showSelectAll,
    isWholeDatasetSelected,
    setIsWholeDatasetSelected: updateIsWholeDatasetSelected,
  };
};
