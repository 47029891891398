import { primaryLight, secondary, tertiary } from './theme/palette';


export const chartPalette = [
  primaryLight,
  secondary,
  tertiary,
];

export const pastelPalette = [
  '#ff8585',
  '#56c261',
  '#9f85ff',
  '#ffa351',
  '#ff85f5',
  '#85b0ff',
  '#9b755a',
];
