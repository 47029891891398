

export enum ReportLanguages {
  english = 'english',
  nederlands = 'nederlands',
}

export enum ReportTypes {
  emission = 'emission',
}

export interface ReportPrompts {
  reportType: string;
  startDate: string;
  endDate: string;
}