import { FunctionComponent, useEffect } from 'react';


const CarbonRemovalBackPage: FunctionComponent = () => {
  useEffect(() => {
    window.parent.postMessage('redirect-back', '*');
  }, []);

  return null;
};

export default CarbonRemovalBackPage;
