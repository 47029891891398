// ** React Imports
import React from 'react';

// ** MUI Imports
import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import { deepmerge } from '@mui/utils';

// ** Theme Override Imports
import overrides from './overrides';
import breakpoints from './breakpoints';
import typography from './typography';

// ** Theme
import themeOptions from './ThemeOptions';

// ** Global Styles
import GlobalStyling from './globalStyles';


type Props = React.PropsWithChildren & {
  mode: PaletteMode;
  locale: string;
}

const ThemeComponent: React.FC<Props> = ({ mode, locale, children }) => {

  // ** Merged ThemeOptions of Core and User
  const coreThemeConfig = themeOptions(mode);

  // ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
  let theme = createTheme(coreThemeConfig);

  // ** Continue theme creation and pass merged component overrides to CreateTheme function
  theme = createTheme(deepmerge(theme, {
    components: { ...overrides(theme) },
    breakpoints: { ...breakpoints(theme) },
    typography: { ...typography(theme) },
  }),
  // @ts-ignore
  locales[locale]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <GlobalStyles styles={() => GlobalStyling(theme) as any}/>
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;
