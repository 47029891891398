import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const AcademicCapIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.73121 1.05498C7.90032 0.970281 8.09946 0.970281 8.26857 1.05498C10.6321 2.23869 12.8325 3.69941 14.8273 5.39447C14.9931 5.53532 15.0696 5.75485 15.0274 5.96823C14.9851 6.18161 14.8307 6.35541 14.6238 6.42247C12.3724 7.15208 10.2596 8.19011 8.33519 9.48694C8.13252 9.62352 7.86726 9.62352 7.66459 9.48694C7.12418 9.12276 6.56891 8.779 5.99988 8.45674V7.51512C5.99988 7.32029 6.09248 7.14494 6.24122 7.04185C7.09032 6.45333 7.973 5.90994 8.88581 5.41515C9.17713 5.25724 9.28528 4.89306 9.12737 4.60173C8.96946 4.31041 8.60528 4.20226 8.31395 4.36017C7.36249 4.87592 6.44251 5.44227 5.55763 6.05559C5.07414 6.3907 4.79988 6.9407 4.79988 7.51512V7.81933C3.70158 7.2735 2.55788 6.80549 1.37598 6.42247C1.16905 6.35541 1.01463 6.18161 0.972379 5.96823C0.93013 5.75485 1.00668 5.53532 1.17244 5.39447C3.16725 3.69941 5.36768 2.23869 7.73121 1.05498ZM4.79988 9.16777C4.16156 8.83031 3.50628 8.52084 2.83565 8.24098C2.70079 9.06148 2.59638 9.89223 2.52361 10.732C2.50216 10.9796 2.63552 11.2147 2.85898 11.3234C3.28133 11.5288 3.69585 11.7478 4.10198 11.9799C3.93501 12.239 3.73807 12.4844 3.51116 12.7113C3.27685 12.9456 3.27685 13.3255 3.51116 13.5598C3.74548 13.7942 4.12537 13.7942 4.35969 13.5598C4.65541 13.2641 4.91116 12.9435 5.12692 12.6046C4.79168 12.387 4.44992 12.1786 4.10199 11.9798C4.56729 11.2577 4.79988 10.4294 4.79988 9.60007V9.16777ZM7.60341 14.483C6.82625 13.7987 5.99864 13.1705 5.12691 12.6047C5.66357 11.7619 5.95283 10.8068 5.99459 9.84388C6.33326 10.0486 6.66648 10.2614 6.99399 10.4821C7.60201 10.8918 8.39779 10.8918 9.00581 10.4821C10.3074 9.60495 11.6992 8.85232 13.1642 8.24097C13.299 9.06147 13.4034 9.89223 13.4762 10.732C13.4977 10.9796 13.3643 11.2147 13.1408 11.3234C11.4158 12.1623 9.82131 13.2284 8.39641 14.483C8.16975 14.6826 7.83008 14.6826 7.60341 14.483Z"
      fill="currentColor"
    />
  </IconContainer>
);
