import { cloneDeep } from 'lodash';

import { camelizeObject } from 'helpers/object';

import { Answer, MappedAnswers, MappedSurveyAnswersApi, Survey, SurveyAnswer, SurveyApi, SurveyQuestions } from './survey.types';


export const addNameInQuestions = <T extends SurveyQuestions> (data: T): T => {
  // @ts-ignore
  const transformedData: T = [];
  Object.entries(data).forEach(([key, value]) => {
    transformedData.push({
      // @ts-ignore
      ...value,
      name: key,
    });
  });
  return transformedData;
};

export const mapAnswersSurvey = ({ answers, year }: SurveyAnswer): MappedSurveyAnswersApi => {
  const mappedAnswers = [];
  for(const answerKey in answers) {
    mappedAnswers.push({ question: answerKey, answer: answers[answerKey] });
  }
  return { year, answers: mappedAnswers };
};

export const formatAnswers = (answers: MappedAnswers) => {
  const formattedAnswers: Answer = {};
  answers.forEach(answerData => {
    formattedAnswers[answerData.question] = answerData.answer;
  });
  return formattedAnswers;
};

export const formatSurveyAnswers = (surveyAnswers: SurveyAnswer[]): SurveyAnswer[] => {
  return surveyAnswers.map((surveyAnswer) => {
    return {
      id: surveyAnswer.id,
      year: surveyAnswer.year,
      answers: formatAnswers(surveyAnswer.answers),
    };
  });
};

export const mapQuestionSurvey = (survey: SurveyApi) : Survey => {
  const mappedSurvey = cloneDeep(survey);
  mappedSurvey.survey_answers = formatSurveyAnswers(survey.survey_answers);
  const camelizedSurvey: Survey = camelizeObject(mappedSurvey);
  camelizedSurvey.questions.questions = addNameInQuestions(camelizedSurvey.questions.questions);
  return camelizedSurvey;
};