import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocalStorage } from 'react-use';
import { Expenses as ExpensesTypes } from 'modules/Expenses/expenses';
import { integrationsClient } from 'modules/Expenses/client/integrationsClient';
import { isEqual } from 'lodash';
import { chiftIntegrationsClient } from 'modules/Expenses/client/chiftIntegrationClient';
import {
  CleaningSteps,
  CsvAnalysisType,
  IntegrationSteps,
  UploadIntegrationCsvSteps,
} from 'modules/Expenses/helpers/integrationOptions';
import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext, WaitingAsyncProcessContext } from 'contexts';
import {
  AsyncProcessCsvUpload,
  AsyncProcessImportingTransactions,
  AsyncProcessType,
} from 'contexts/WaitingAsyncProcessContext/types';
import { transactionClient } from 'clients/transactions/transactionClient';
import { UseUploadCsvWizardProps, WizardStepAction, useUploadCsvWizard } from 'shared/hooks/useUploadCsvWizard';
import { useLinkedAccounts } from './useLinkedAccounts';
import { useChiftIntegrations } from './useChiftIntegrations';
import { useIntegrationsCrud } from './useIntegrationsCrud';


const UploadProcessType = AsyncProcessType.CsvUpload;
const ImportingProcessType = AsyncProcessType.ImportingTransactions;

export const useUploadIntegrationWizard = ({ columnOptions, uploadFileStepProps }: UseUploadCsvWizardProps) => {
  const { deleteIntegration } = useIntegrationsCrud();
  const queryClient = useQueryClient();

  const { dictionary } = useContext(LocalizationContext);
  const { genericError, validationError } = useContext(LayoutContext);
  const { asyncProcesses, addAsyncProcess, removeAsyncProcess, setIsOpenInDialog } = useContext(WaitingAsyncProcessContext);

  const {
    isUploadCsvDialogOpen,
    setIsUploadCsvDialogOpen,
    fileUploaded,
    setFileUploaded,
    fileText,
    setFileText,
    fileColumnsPreview,
    setFileColumnsPreview,
    rowsNumPreview,
    setRowsNumPreview,
    isFirstRowHeaders,
    setIsFirstRowHeaders,
    headerIndexes,
    setHeaderIndexes,
    loaderText,
    setLoaderText,
    isLoading,
    setIsLoading,
    isReviewConfirmOpen,
    setIsReviewConfirmOpen,
    errors,
    setErrors,
    isUploadDisabled,
    setIsUploadDisabled,
    handleUploadDiffFile,
    onHandleSendFile,
    handleIsFirstRowHeadersChange,
    isCanUpload,
  } = useUploadCsvWizard({ columnOptions, uploadFileStepProps });

  const [wizardSteps, setWizardSteps] = useState<{ [key: string]: string }>(UploadIntegrationCsvSteps);
  const [wizardStep, setWizardStep] = useState<string>(wizardSteps.UPLOAD_FILE);
  const [ledgerAndJournalCleaning, setLedgerAndJournalCleaning] = useState<ExpensesTypes.Integrations.JournalAndLedgerCleaningData[]>([]);
  const [integrationId, setIntegrationId] = useState<number | null>(null);
  const [integrationName, setIntegrationName] = useState<string | null>(null);
  const [isNoJournals, setIsNoJournals] = useState<boolean>(false);
  const [isUpdateCleaning, setIsUpdateCleaning] = useState<boolean>(false);
  const [isAwaitingIntegration, setIsAwaitingIntegration] = useState<boolean>(true);
  const [errorIntegrationId, setErrorIntegrationId] = useState<number | null>(null);

  const { chiftIntegrationOptions, isLoadingOptions } = useChiftIntegrations();
  const [awaitingIntegration, setAwaitingIntegration, removeAwaitingIntegration] = useLocalStorage<string | undefined>('awaiting-integration');

  const analysisType = useMemo<ExpensesTypes.AnalysesType | undefined>(() => CsvAnalysisType[wizardStep], [wizardStep]);

  const {
    analysisList,
    isLoading: isLoadingAnalysisList,
    refetch,
  } = useLinkedAccounts(integrationId, { limit: 1000, type: analysisType });

  const uploadProcess = useMemo<AsyncProcessCsvUpload | undefined>(() =>
    asyncProcesses.find((process) => process.processType === UploadProcessType) as AsyncProcessCsvUpload | undefined,
  [asyncProcesses],
  );
  const importingProcess = useMemo<AsyncProcessImportingTransactions | undefined>(() =>
    asyncProcesses.find((process) => process.processType === ImportingProcessType) as AsyncProcessImportingTransactions | undefined,
  [asyncProcesses],
  );

  const setToUpload = useCallback((isSkipIntro?: boolean) => {
    const isDiffSteps = !isEqual(UploadIntegrationCsvSteps, wizardSteps);
    if (isDiffSteps) {
      setWizardSteps(UploadIntegrationCsvSteps);
      setWizardStep(isSkipIntro ? UploadIntegrationCsvSteps.UPLOAD_FILE : UploadIntegrationCsvSteps.INTRO);
    }
  },
  [wizardSteps],
  );

  const setToIntegration = useCallback(() => {
    const isDiffSteps = !isEqual(IntegrationSteps, wizardSteps);
    if (isDiffSteps) {
      setWizardSteps(IntegrationSteps);
      setWizardStep(IntegrationSteps.INTRO);
    }
  }, [wizardSteps]);

  const nextStep = useCallback(() => {
    const stepIdx = Object.keys(wizardSteps).findIndex((key) => wizardSteps[key] === wizardStep);
    setWizardStep(wizardSteps[Object.keys(wizardSteps)[stepIdx + 1]]);
  }, [wizardStep, wizardSteps]);

  const previousStep = useCallback(() => {
    const stepIdx = Object.keys(wizardSteps).findIndex((key) => wizardSteps[key] === wizardStep);
    setWizardStep(wizardSteps[Object.keys(wizardSteps)[Math.max(stepIdx - 1, 0)]]);
  }, [wizardStep, wizardSteps]);

  const selectIntegrationType = useCallback((key: string) => {
    setIntegrationName(key);
    nextStep();
  },
  [nextStep],
  );

  const handleOnClose = useCallback((stepToSet?: string) => {
    setIsUploadCsvDialogOpen(false);
    setIsNoJournals(false);
    setIsUpdateCleaning(false);
    setIntegrationName(null);
    setErrorIntegrationId(null);
    removeAwaitingIntegration();

    uploadProcess && uploadProcess.isActive
      ? setIsOpenInDialog(UploadProcessType, false)
      : removeAsyncProcess(UploadProcessType);
    importingProcess && importingProcess.isActive
      ? setIsOpenInDialog(ImportingProcessType, false)
      : removeAsyncProcess(ImportingProcessType);

    setTimeout(() => {
      if (stepToSet !== undefined) {
        setWizardStep(stepToSet);
      } else if (
        [
          UploadIntegrationCsvSteps.UPLOAD_DONE,
          UploadIntegrationCsvSteps.ASYNC_LOADER,
          IntegrationSteps.ASYNC_LOADER,
          IntegrationSteps.SYNC_DONE,
          IntegrationSteps.PERMISSIONS,
          CleaningSteps.JOURNAL,
          CleaningSteps.LEDGER,
          CleaningSteps.REVIEW_DONE,
        ].includes(wizardStep)
      ) {
        setWizardStep(wizardSteps.UPLOAD_FILE);
      }
    }, 500);
  },
  [
    setIsUploadCsvDialogOpen,
    setErrorIntegrationId,
    removeAwaitingIntegration,
    uploadProcess,
    setIsOpenInDialog,
    removeAsyncProcess,
    importingProcess,
    wizardStep,
    wizardSteps,
  ],
  );

  const setChiftIntegrationProcess = useCallback(async (integrationName: string) => {
    try {
      const {
        data: { id },
      } = await chiftIntegrationsClient.getChiftIntegration(integrationName);

      addAsyncProcess({
        processType: AsyncProcessType.ImportingTransactions,
        name: integrationName,
        isOpenInDialog: true,
        data: {
          integrationId: id,
          integrationName: integrationName,
          isInactive: true,
        },
      } as Omit<AsyncProcessImportingTransactions, 'isActive' | 'snackbarType'>);
      setIntegrationName(integrationName);
      setToIntegration();
      setWizardStep(IntegrationSteps.ASYNC_LOADER);
      setIsUploadCsvDialogOpen(true);
      removeAwaitingIntegration();
    } catch (e) {}
  },
  [addAsyncProcess, setToIntegration, setIsUploadCsvDialogOpen, removeAwaitingIntegration],
  );

  const handleGoToChift = useCallback(async () => {
    const option = chiftIntegrationOptions.activeIntegrations.find((opt) => opt.name === integrationName);
    if (!option) {
      genericError();
      return;
    }

    try {
      setLoaderText(dictionary.expenses.uploadIntegration.permissions.loading);
      setIsLoading(true);
      const {
        data: { url },
      } = await chiftIntegrationsClient.getChiftIntegrationUrl({
        integrationId: option.integrationid,
        connectionName: option.name,
      });
      setIsAwaitingIntegration(false);
      setAwaitingIntegration(option.name);
      window.location.replace(url);
    } catch (e: any) {
      validationError(
        e?.response?.data?.message?.includes('already a connection')
          ? dictionary.expenses.uploadIntegration.permissions.existingError
          : dictionary.somethingWentWrong,
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    chiftIntegrationOptions,
    integrationName,
    genericError,
    setLoaderText,
    dictionary,
    setIsLoading,
    setAwaitingIntegration,
    validationError,
  ]);

  const onComplete = useCallback(async (rows: any[]): Promise<[number | null, CsvUploadErrors | undefined]> => {
    const res = await transactionClient.uploadTransactionsFromCsv({ data: rows, fileName: fileUploaded?.name });
    return [res.data.integration_id, undefined];
  },
  [fileUploaded],
  );

  const handleSendFile = useCallback(async () => {
    const sendFile = async (rows: any[]) => {
      if (errorIntegrationId) {
        await deleteIntegration(errorIntegrationId);
        setErrorIntegrationId(null);
      }

      const [integrationId] = await onComplete(rows);
      queryClient.invalidateQueries(cacheKeys.integrations.getIntegrations);

      if (!integrationId) {
        throw new Error('Integration id is not defined');
      }

      setIntegrationId(integrationId);
      addAsyncProcess({
        processType: UploadProcessType,
        name: fileUploaded?.name || 'file',
        isOpenInDialog: true,
        data: {
          integrationId: integrationId!,
          fileText,
          headerIndexes,
        },
      } as Omit<AsyncProcessCsvUpload, 'isActive' | 'snackbarType'>);
    };

    onHandleSendFile(nextStep, sendFile, previousStep);
  }, [
    onHandleSendFile,
    nextStep,
    previousStep,
    errorIntegrationId,
    onComplete,
    queryClient,
    addAsyncProcess,
    fileUploaded?.name,
    fileText,
    headerIndexes,
    deleteIntegration,
  ]);

  const handleReviewStepDone = useCallback(async () => {
    if (!integrationId) {
      return;
    }

    const cleaning = [...ledgerAndJournalCleaning];
    analysisList.forEach((a) => {
      if (cleaning.every((c) => c.id !== a.id)) {
        cleaning.push({
          id: a.id,
          isIncludedInAnalysis: a.isIncludedInAnalysis === null ? true : a.isIncludedInAnalysis,
        });
      }
    });
    setLedgerAndJournalCleaning(cleaning);

    try {
      setIsReviewConfirmOpen(false);
      setLoaderText(dictionary.expenses.uploadIntegration.ledgerAnalysis.loadingUpload(analysisType));
      setIsLoading(true);
      await integrationsClient.setIntegrationLedgerAndJournalsCleaning(integrationId, cleaning);

      queryClient.invalidateQueries(cacheKeys.integrations.getIntegrations);
      queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
      queryClient.invalidateQueries(cacheKeys.charts.getChart);
      nextStep();
    } catch (e) {
      genericError();
    } finally {
      setIsLoading(false);
    }
  }, [
    integrationId,
    ledgerAndJournalCleaning,
    analysisList,
    setIsReviewConfirmOpen,
    setLoaderText,
    dictionary,
    analysisType,
    setIsLoading,
    queryClient,
    nextStep,
    genericError,
  ]);

  const handleUploadProcessDone = useCallback(() => {
    const isError = uploadProcess?.snackbarType === 'error';
    setToUpload();
    setWizardStep(isError ? UploadIntegrationCsvSteps.SELECT_COLS : UploadIntegrationCsvSteps.UPLOAD_DONE);
    setIntegrationId(uploadProcess!.data.integrationId);
    setErrors(uploadProcess!.data.errors || null);
    setFileText(isError ? uploadProcess.data.fileText : null);
    setHeaderIndexes(isError ? uploadProcess.data.headerIndexes : {});
    setIsUploadCsvDialogOpen(true);
    setIsUploadDisabled(isError);

    if (!isError) {
      setFileUploaded(null);
      setFileColumnsPreview([]);
    } else {
      setErrorIntegrationId(uploadProcess.data.integrationId);
    }

    if (!uploadProcess!.isActive) {
      removeAsyncProcess(UploadProcessType);
    }

    queryClient.invalidateQueries(cacheKeys.integrations.getIntegrations);
    queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
  }, [
    queryClient,
    removeAsyncProcess,
    setErrors,
    setFileColumnsPreview,
    setFileText,
    setFileUploaded,
    setHeaderIndexes,
    setIsUploadCsvDialogOpen,
    setIsUploadDisabled, setToUpload,
    uploadProcess,
  ]);

  const handleImportingProcessDone = useCallback(() => {
    const isError = importingProcess?.snackbarType === 'error';
    if (isError) {
      genericError();
      handleOnClose();
      removeAsyncProcess(ImportingProcessType);
      return;
    }

    setToIntegration();
    setWizardStep(IntegrationSteps.SYNC_DONE);
    setIntegrationId(importingProcess!.data.integrationId);
    setIntegrationName(importingProcess!.data.integrationName);
    setIsUploadCsvDialogOpen(true);
    if (!importingProcess!.isActive) {
      removeAsyncProcess(ImportingProcessType);
    }

    queryClient.invalidateQueries(cacheKeys.integrations.getIntegrations);
    queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
  }, [genericError, handleOnClose, importingProcess, queryClient, removeAsyncProcess, setIsUploadCsvDialogOpen, setToIntegration]);

  useEffect(() => {
    if (wizardStep !== UploadIntegrationCsvSteps.SELECT_COLS) { setIsUploadDisabled(false); }
  }, [setIsUploadDisabled, wizardStep]);

  useEffect(() => {
    if (isNoJournals && wizardStep === wizardSteps.JOURNAL) { setWizardStep(wizardSteps.LEDGER); }
  }, [isNoJournals, wizardStep, wizardSteps]);

  useEffect(() => {
    if (analysisType === 'ledger') { refetch(); }
  }, [refetch, analysisType]);

  useEffect(() => {
    if (analysisType === 'journal') {
      setIsNoJournals(!isLoadingAnalysisList && !analysisList.some((x) => x.name));
    }
  }, [analysisList, analysisType, isLoadingAnalysisList, setIsNoJournals]);

  useEffect(() => setLedgerAndJournalCleaning([]), [integrationId, setLedgerAndJournalCleaning]);

  useEffect(() => {
    if (awaitingIntegration && isAwaitingIntegration) {
      setChiftIntegrationProcess(awaitingIntegration);
    }
  }, [awaitingIntegration, isAwaitingIntegration, isUploadCsvDialogOpen, setChiftIntegrationProcess]);

  useEffect(() => {
    if (uploadProcess && uploadProcess.isOpenInDialog && !uploadProcess.isActive) {
      handleUploadProcessDone();
    }
  }, [uploadProcess, isUploadCsvDialogOpen, handleUploadProcessDone]);

  useEffect(() => {
    if (importingProcess && importingProcess.isOpenInDialog && !importingProcess.isActive) {
      handleImportingProcessDone();
    }
  }, [importingProcess, isUploadCsvDialogOpen, handleImportingProcessDone]);

  const wizardStepActions = useMemo<{ [step: string]: WizardStepAction }>(
    () => ({
      // UploadIntegrationCsvSteps
      [UploadIntegrationCsvSteps.INTRO]: {
        back: {
          onClick: () => handleOnClose(),
          title: dictionary.expenses.uploadIntegration.intro.backButton,
        },
        forward: { title: dictionary.expenses.uploadIntegration.intro.forwardButton },
      },
      [UploadIntegrationCsvSteps.UPLOAD_FILE]: {
        forward: { title: dictionary.expenses.uploadIntegration.forwardButton, isDisabled: !fileColumnsPreview.length },
      },
      [UploadIntegrationCsvSteps.PREVIEW]: {
        back: { title: dictionary.expenses.uploadIntegration.backButton },
        forward: { title: dictionary.expenses.uploadIntegration.forwardButton },
      },
      [UploadIntegrationCsvSteps.SELECT_COLS]: {
        back: {
          title: errors
            ? dictionary.expenses.uploadIntegration.selectColumns.backButton
            : dictionary.expenses.uploadIntegration.backButton,
          onClick: errors ? () => {
            handleUploadDiffFile();
            setWizardStep(UploadIntegrationCsvSteps.UPLOAD_FILE);
          } : null,
        },
        forward: !!fileColumnsPreview.length
          ? {
            title: dictionary.expenses.uploadIntegration.selectColumns.forwardButton,
            onClick: handleSendFile,
            isDisabled: !isCanUpload,
          }
          : undefined,
      },
      [UploadIntegrationCsvSteps.ASYNC_LOADER]: {},
      [UploadIntegrationCsvSteps.UPLOAD_DONE]: {
        forward: { title: dictionary.expenses.uploadIntegration.uploadDone.review },
      },
      // IntegrationSteps
      [IntegrationSteps.INTRO]: {
        back: {
          onClick: () => handleOnClose(),
          title: dictionary.expenses.uploadIntegration.intro.backButton,
        },
        forward: { title: dictionary.expenses.uploadIntegration.intro.forwardButton },
      },
      [IntegrationSteps.SELECT_ACC]: {},
      [IntegrationSteps.PERMISSIONS]: {
        back: { title: dictionary.expenses.uploadIntegration.backButton },
        forward: {
          title: dictionary.expenses.uploadIntegration.forwardButton,
          onClick: handleGoToChift,
        },
      },
      [IntegrationSteps.ASYNC_LOADER]: {},
      [IntegrationSteps.SYNC_DONE]: {
        forward: { title: dictionary.expenses.uploadIntegration.uploadDone.review },
      },
      // CleaningSteps
      [CleaningSteps.JOURNAL]: {
        forward: {
          title: dictionary.expenses.uploadIntegration.forwardButton,
          onClick: handleReviewStepDone,
        },
      },
      [CleaningSteps.LEDGER]: {
        back: !isNoJournals ? { title: dictionary.expenses.uploadIntegration.backButton } : undefined,
        forward: {
          title: dictionary.expenses.uploadIntegration.ledgerAnalysis.run,
          onClick: () => setIsReviewConfirmOpen(true),
        },
      },
      [CleaningSteps.REVIEW_DONE]: {},
    }),
    [
      dictionary,
      fileColumnsPreview,
      errors,
      handleUploadDiffFile,
      handleSendFile,
      isCanUpload,
      handleGoToChift,
      handleReviewStepDone,
      isNoJournals,
      handleOnClose,
      setIsReviewConfirmOpen,
    ],
  );

  return {
    wizardStep,
    setWizardStep,
    isUploadCsvDialogOpen,
    setIsUploadCsvDialogOpen,
    fileUploaded,
    setFileUploaded,
    fileText,
    setFileText,
    fileColumnsPreview,
    setFileColumnsPreview,
    rowsNumPreview,
    setRowsNumPreview,
    isFirstRowHeaders,
    setIsFirstRowHeaders,
    headerIndexes,
    setHeaderIndexes,
    loaderText,
    setLoaderText,
    isLoading,
    setIsLoading,
    isReviewConfirmOpen,
    setIsReviewConfirmOpen,
    errorIntegrationId,
    setErrorIntegrationId,
    errors,
    setErrors,
    isUploadDisabled,
    setIsUploadDisabled,
    ledgerAndJournalCleaning,
    setLedgerAndJournalCleaning,
    integrationId,
    setIntegrationId,
    isNoJournals,
    setIsNoJournals,
    isUpdateCleaning,
    setIsUpdateCleaning,
    integrationName,
    setIntegrationName,
    onComplete,
    isCanUpload,
    isLoadingAnalysisList,
    analysisList,
    handleIsFirstRowHeadersChange,
    handleUploadDiffFile,
    handleOnClose,
    handleReviewStepDone,
    wizardStepActions,
    nextStep,
    previousStep,
    setToUpload,
    setToIntegration,
    selectIntegrationType,
    chiftIntegrationOptions,
    isLoadingOptions,
    isCheckingConnection: !!importingProcess?.data?.isInactive,
    columnOptions,
    uploadFileStepProps,
  };
};

export type CsvUploadErrors = {
  [key: string]: {
    error: string;
    rows: number[];
  };
};
