import { formatToApi } from 'helpers/date';
import { snakizeObject } from 'helpers/object';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';
import { GetChartParams } from './chartClient.types';


export const mapChartParams = (params: GetChartParams) => {
  return {
    ...snakizeObject<GetChartParams>(params),
    date_lte: params?.dateLte ? formatToApi(params.dateLte) : undefined,
    date_gte: params?.dateGte ? formatToApi(params.dateGte) : undefined,
    scope: params?.scope ?? [Scope.One, Scope.Two, Scope.Three],
  };
};