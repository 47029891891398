import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { usersClient } from 'clients/users/usersClient';


type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useMe = (options: Partial<Options> = defaultOptions) => {
  const { data: { data: user } = {}, status, isLoading, error, isFetching } = useQuery(
    cacheKeys.users.getMe,
    usersClient.getMe,
    {
      enabled: options.enabled,
    },
  );

  return {
    isLoading,
    status,
    error,
    user,
    isFetching,
  };
};
