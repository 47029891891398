import { useQuery } from 'react-query';
import { Emissions } from 'modules/Emissions/emissions';
import { cacheKeys } from 'config';
import { capitalize } from 'helpers/string';
import { emissionFactorsClient } from 'clients/emissionFactors/emissionFactorsClient';
import { EmissionFactor } from 'clients/emissionFactors/emissionFactorsClient.types';


type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

function capitalizeEmissionFactors(results: Array<EmissionFactor>): Array<EmissionFactor> {
  if (results) {
    results.forEach((emissionFactor) => {
      if (emissionFactor.category) {
        if (emissionFactor.category.parent) {
          emissionFactor.category.parent.title = capitalize(emissionFactor.category.parent.title);
          emissionFactor.category.parent.description = capitalize(emissionFactor.category.parent.description);
        }
      }
      if (emissionFactor.title) {
        emissionFactor.title = capitalize(emissionFactor.title);
      }
      if (emissionFactor.description) {
        emissionFactor.description = capitalize(emissionFactor.description);
      }
    });
    return results;
  }
  return [];
}

export const useEmissionFactors = (params: Emissions.Categories.GetEmissionFactorListParams = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error, isLoading } = useQuery(
    [cacheKeys.emissionFactors.getEmissionFactors, params],
    () => emissionFactorsClient.getEmissionFactors(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    isLoading,
    count: data?.count || 0,
    emissionFactors: data?.results ? capitalizeEmissionFactors(data.results) : [],
  };
};
