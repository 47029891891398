import { CamelToSnakeCase, MaybeNull } from 'types';
import { GetListParams } from 'types/Query';


export enum Scope {
  One = '1',
  Two = '2',
  Three = '3',
  Offset = '9',
  All = 'all',
}

export type EmissionFactor = {
  id: number;
  slug: MaybeNull<string>;
  title: string;
  description: string;
  scope: Scope;
  kgCo2EMultiplier: number;
  kgCo2EMultiplierUnit: KgCo2eMultiplierUnit;
  company: number;
  region: string | null,
  isActivityBased?: boolean;
  parent: MaybeNull<EmissionFactor>;
  category?: any;
  year: string;
}

export type KgCo2eMultiplierUnit = {
  id: number;
  name: string;
  displayName: string;
}
export type KgCo2eMultiplierUnitApi = CamelToSnakeCase<KgCo2eMultiplierUnit>

export type EmissionFactorsAutocomplete = {
  id: number;
  slug: string;
  title: string;
  description: string;
  scope: number;
  kgCo2eMultiplier: number;
  region: string;
  isActivityBased: boolean;
  kgCo2eMultiplierUnit: KgCo2eMultiplierUnit;
  company: number;
  parent: number;
  category: number;
}

export type EmissionFactorsAutocompleteParams = {
  manualInputId?: number;
  search?: string;
  limit?: number;
};

export type EmissionFactorApi = CamelToSnakeCase<Omit<EmissionFactor, 'kgCo2eMultiplierUnit'>> & {
  kg_co2e_multiplier: KgCo2eMultiplierUnitApi;
};

export type EmissionFactorsAutocompleteApi = CamelToSnakeCase<Omit<EmissionFactorsAutocomplete, 'kgCo2eMultiplierUnit'>> & {
  kg_co2e_multiplier: KgCo2eMultiplierUnitApi;
};

export type GetEmissionFactorCategoriesParams = GetListParams & {
  mandatory?: boolean;
}

export enum EmissionFactors {
  Travel = 'Travel',
  TransportAndLogistics = 'Transport',
  FoodsAndBeverages = 'Restaurants and accommodation',
  Energy = 'Energy',
  Digital = 'Information and communication',
  Waste = 'Waste',
  Buildings = 'Buildings and infrastructure',
  Products = 'Consumer goods and services',
  Logistics = 'Equipment',
  Design = 'Materials and manufacturing',
}

export type EmissionFactorCategory = {
  id: number,
  color: string,
  description: string,
  icon?: string,
  parent?: EmissionFactorCategory,
  slug: string,
  title: string,
}
