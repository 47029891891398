import { Fragment, FC, MutableRefObject, useMemo } from 'react';
import { Box, Skeleton } from '@mui/material';

import { EmissionFactor } from 'clients/emissionFactors/emissionFactorsClient.types';
import { MappedPieChartData } from 'views/LoggedIn/Measurements/types';

import { CategoryBar } from './CategoryBar';



interface BarProps {
  data: MappedPieChartData;
  isLoading: boolean;
  setEmissionFactorCategory: (category: string | undefined) => void;
  reloadChartsRef: MutableRefObject<boolean>,
  emissionFactorCategory: EmissionFactor,
}

const CategoriesBar: FC<BarProps> = ({ data = [], isLoading, setEmissionFactorCategory, reloadChartsRef, emissionFactorCategory }) => {

  const sortedData = useMemo(() => {
    const clonedData = [...data];
    return clonedData?.sort((prev: any, next: any) => {
      return prev?.co2ProportionWithNegativeValues > next?.co2ProportionWithNegativeValues ? -1 : 1;
    });
  }, [data]);

  return (
    <Box className="_d-flex-jc-sb-xxs-gap" position="relative">
      {isLoading ? (
        <Skeleton
          sx={{ mb: 2 }}
          variant="rounded"
          height={50}
          width="100%"
        />
      ) : (
        <Fragment>
          {sortedData?.map((bar, i) => {
            return (
              <CategoryBar
                key={i}
                bar={bar}
                reloadChartsRef={reloadChartsRef}
                setEmissionFactorCategory={setEmissionFactorCategory}
                emissionFactorCategory={emissionFactorCategory}
              />
            );
          })}
        </Fragment>
      )}
    </Box>
  );
};

export default CategoriesBar;
