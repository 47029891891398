import { useContext, useMemo } from 'react';
import { Button, Grid } from '@mui/material';

import { Add } from '@mui/icons-material';
import { DialogContext, LocalizationContext, PermissionContext } from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';
import { FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { TeamList } from 'views/LoggedIn/Team/TeamList';


export const Team = () => {
  const { dictionary } = useContext(LocalizationContext);
  const { openDialog } = useContext(DialogContext);
  const { getPermission } = useContext(PermissionContext);

  const hasEditPermission = useMemo(() => getPermission('invitation.crud.create'), [getPermission]);

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <FiltersSection
          pageActions={[
            ...hasEditPermission ? [(
              <Button
                key="invite-team-member-button"
                onClick={() => openDialog(Dialog.InviteTeamMember)}
              >
                <Add/>
                {dictionary.team.buttonInvite}
              </Button>
            )] : [],
          ]}
          title={dictionary.team.team}
        >
          {() =>
            <Grid py={8}>
              <TeamList/>
            </Grid>
          }
        </FiltersSection>
      </Grid>
    </Grid>
  );
};
