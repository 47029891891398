import React from 'react';
import { useLocalStorage } from 'react-use';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { line } from 'shared/styles/muiTheme';
import { useUploadIntegrationWizard } from 'modules/Expenses/hooks/useUploadIntegrationWizard';
import { CleaningSteps, CsvColumnOptions, UploadIntegrationCsvSteps } from 'modules/Expenses/helpers/integrationOptions';
import { integrationsClient } from 'modules/Expenses/client/integrationsClient';
import { chiftIntegrationsClient } from 'modules/Expenses/client/chiftIntegrationClient';
import { LayoutContext, LocalizationContext, WaitingAsyncProcessContext } from 'contexts';
import { AsyncProcessType } from 'contexts/WaitingAsyncProcessContext/types';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { UploadIntegrationWizard } from './UploadIntegrationWizard';
import { IntegrationsTable } from './IntegrationTable/IntegrationsTable';


export const LinkedAccounting = () => {
  const { currencyLocale: { symbol }, dictionary } = React.useContext(LocalizationContext);
  const breadcrumbs = useBreadcrumbs('linkedAccounting');
  const { genericError } = React.useContext(LayoutContext);
  const { asyncProcesses } = React.useContext(WaitingAsyncProcessContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ awaitingIntegration, setAwaitingIntegration] = useLocalStorage<string | undefined>('awaiting-integration');

  const uploadCsvWizardStore = useUploadIntegrationWizard({
    columnOptions: CsvColumnOptions(symbol),
    uploadFileStepProps: { templateUrl: '/files/Coolset - expenses - template.csv' },
  });
  const {
    integrationId,
    setIntegrationId,
    setWizardStep,
    setIntegrationName,
    setIsUpdateCleaning,
    setIsUploadCsvDialogOpen,
    setToUpload,
    setToIntegration,
    setErrorIntegrationId,
    setIsLoading,
    setErrors,
  } = uploadCsvWizardStore;

  const handleOpenCleaningDialog = React.useCallback((id: number, fileName: string | null, isUpdate: boolean) => {
    if (id !== integrationId) {
      setIntegrationId(id);
    }
    setWizardStep(CleaningSteps.JOURNAL);
    setIntegrationName(fileName);
    setIsUpdateCleaning(isUpdate);
    setIsUploadCsvDialogOpen(true);
  }, [integrationId, setIntegrationId, setIntegrationName, setIsUpdateCleaning, setIsUploadCsvDialogOpen, setWizardStep]);

  const handleOpenErrorDialog = React.useCallback(async (id: number, fileName: string | null) => {
    setErrorIntegrationId(id);
    setIntegrationName(fileName);
    setToUpload();
    setWizardStep(UploadIntegrationCsvSteps.SELECT_COLS);
    setIsUploadCsvDialogOpen(true);

    setIsLoading(true);
    try {
      setErrors((await integrationsClient.getIntegration(id)).logs?.error || null);
    }
    catch (e) {
      genericError();
    }
    finally {
      setIsLoading(false);
    }
  }, [setErrorIntegrationId, setIntegrationName, setToUpload, setWizardStep, setIsUploadCsvDialogOpen, setIsLoading, setErrors, genericError]);

  const handleActivateIntegration = React.useCallback(async (integrationId: number, integrationName: string) => {
    const { data: { url } } = await chiftIntegrationsClient.updateChiftIntegrationUrl(integrationId);
    setAwaitingIntegration(integrationName);
    window.location.replace(url);
  }, [setAwaitingIntegration]);

  const handleOpenCsvUploadDialog = () => {
    setToUpload(true);
    setIsUploadCsvDialogOpen(true);
  };

  const handleOpenIntegrationDialog = () => {
    setToIntegration();
    setIsUploadCsvDialogOpen(true);
  };

  const isUploadDisabled = React.useMemo(() =>
    asyncProcesses.some((process) => process.processType === AsyncProcessType.CsvUpload),
  [asyncProcesses],
  );

  return (
    <div>
      <Breadcrumbs list={breadcrumbs} show/>
      <Box
        className="_d-flex-ali-center-jc-sb"
        borderBottom={`solid 1px ${line}`}
        py={3}
        mb={3}
      >
        <Typography variant="h2">{dictionary.expenses.linkedAccounting.title}</Typography>
        <Box className="_d-flex-md-gap">
          <Button
            color="secondary"
            onClick={handleOpenCsvUploadDialog}
            disabled={isUploadDisabled}
          >
            {dictionary.upload}
          </Button>
          <Button
            onClick={handleOpenIntegrationDialog}
            disabled={isUploadDisabled}
          >
            {dictionary.connect}
          </Button>
        </Box>
      </Box>

      <IntegrationsTable
        openCleaning={handleOpenCleaningDialog}
        openError={handleOpenErrorDialog}
        handleActivateIntegration={handleActivateIntegration}
      />

      <UploadIntegrationWizard uploadCsvWizardStore={uploadCsvWizardStore}/>
    </div>
  );
};