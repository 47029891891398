import { useCallback, useContext } from 'react';
import { useIsMutating, useMutation } from 'react-query';

import { cacheKeys } from 'config';
import { LayoutContext } from 'contexts';
import { generateDownload } from 'helpers/download';
import { transactionClient } from 'clients/transactions/transactionClient';
import { GetTransactionsParams } from 'clients/transactions/transactionClient.types';


export const useExportTransactions = (allFilters: GetTransactionsParams) => {
  const { genericError } = useContext(LayoutContext);
  const isLoadingExportingTransaction = useIsMutating(
    cacheKeys.transactions.exportTransactions,
  );

  const { mutateAsync: exportTransactions } = useMutation(
    transactionClient.getTransactionsExport,
    {
      mutationKey: cacheKeys.transactions.exportTransactions,
      onSuccess: (result) => {
        generateDownload({
          blobFile: result.data,
          downloadName: 'Coolset_Transactions_' + new Date().toISOString().split('T')[0],
        });
      },
      onError: () => {
        genericError();
      },
    },
  );

  const exportButtonClickHandler = useCallback(() => {
    if (isLoadingExportingTransaction) {
      return;
    }

    exportTransactions(allFilters);

  },
  [allFilters, exportTransactions, isLoadingExportingTransaction],
  );

  return {
    exportTransactions,
    isLoadingExportingTransaction,
    exportButtonClickHandler,
  };
};
