import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { cacheKeys, featureFlags } from 'config';
import { GetChartParams } from 'clients/charts/chartClient.types';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';
import { DateAggregation } from 'clients/transactions/transactionClient.types';
import { chartsClient } from 'clients/charts/chartsClient';
import { useEmissionFactorCategories } from './useEmissionFactorCategories';


type Options = {
  enabled: boolean
  staleTime: number,
  keepPreviousData: boolean,
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useCharts = (params: GetChartParams = {}, options: Partial<Options> = defaultOptions) => {

  const queryClient = useQueryClient();
  const { emissionFactorCategories = [] } = useEmissionFactorCategories({ limit: 2000 }, { enabled: featureFlags.prefetchCharts });

  const { data: { data } = {}, status, error, isFetched, isLoading: isLoadingCharts, isFetching } = useQuery(
    [cacheKeys.charts.getChart, params],
    () => chartsClient.getChart(params),
    {
      keepPreviousData: true,
      staleTime: Infinity,
      ...options,
    },
  );

  useEffect(() => {
    if (!isFetched) {
      return;
    }

    if (featureFlags.prefetchCharts) {
      [Scope.One, Scope.Two, Scope.Three, Scope.Offset, undefined].forEach((scope) => {
        const mappedParams = { ...params, scope };
        if (mappedParams.scope !== params.scope) {
          queryClient.prefetchQuery(
            [cacheKeys.charts.getChart, mappedParams],
            () => chartsClient.getChart(mappedParams),
          );
        }
      });
    }
  }, [params, queryClient, isFetched]);

  useEffect(() => {
    if (!isFetched) {
      return;
    }

    if (featureFlags.prefetchCharts) {
      [DateAggregation.Month, DateAggregation.Year].forEach((dateAggregation) => {
        const mappedParams = { ...params, dateAggregation };
        if(mappedParams.dateAggregation !== params.dateAggregation) {
          queryClient.prefetchQuery(
            [cacheKeys.charts.getChart, mappedParams],
            () => chartsClient.getChart(mappedParams),
          );
        }
      });
    }
  }, [params, queryClient, isFetched]);

  useEffect(() => {
    if (!isFetched) {
      return;
    }

    if (featureFlags.prefetchCharts) {
      emissionFactorCategories.forEach(({ parent, id }) => {
        if (!parent) {
          const mappedParams = { ...params, categoryId: id };
          queryClient.prefetchQuery(
            [cacheKeys.charts.getChart, mappedParams],
            () => chartsClient.getChart(mappedParams),
          );
        }
      });
    }
  }, [emissionFactorCategories, params, queryClient, isFetched]);

  return {
    status,
    error,
    data,
    isFetched,
    isLoadingCharts,
    isFetching,
  };
};
