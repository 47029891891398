import { Typography } from '@mui/material';
import coolsetIcon from 'shared/img/icon.svg';


/**
 * @component WorkspaceLogo
 *
 * @description Renders the provided logo and optional name with fallback to the Coolset logo
*/

export const WorkspaceLogo = (props: WorkspaceLogoProps) => {
  const { iconOnly = false, image, name } = props;

  return (
    <>
      <img
        alt={name || 'WorkspaceLogo'}
        className="logo-container"
        src={image || coolsetIcon}
      />
      {!iconOnly && (
        <Typography
          className="one-line-text"
          mx={0.5}
          variant="h5"
        >
          {name || 'Coolset'}
        </Typography>
      )}
    </>
  );
};

interface WorkspaceLogoProps {
  iconOnly?: boolean
  image?: string;
  name?: string;
}