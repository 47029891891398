// ** Theme Type Import
import { Theme } from '@mui/material/styles';


const Breakpoints = (theme: Theme) => {
  return {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      breakPt1100: 1100,
      breakPt1350: 1350,
      breakPt800: 800,
    },
  };
};

export default Breakpoints;
