import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { usersClient } from 'clients/users/usersClient';


export type Params = {
    id: string;
};

export const useWorkspaceInvitation = (params: Params) => {
  const { status, error, isLoading } = useQuery(
    [cacheKeys.users.getInvitation, params],
    () => usersClient.inviteToWorkspace(params),
  );

  return {
    status,
    error,
    isLoading,
  };
};
