import queryString from 'query-string';
import axiosRequest, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { firebaseClient } from './firebaseClient';


export type AbstractRequest = (props: { options: AxiosRequestConfig; authenticate?: boolean; maxRetries?: number; mockRequest?: boolean }) => Promise<AxiosResponse>;

const serializeParams = (params: any) => queryString.stringify(params);

const attachOptions = async (options: AxiosRequestConfig, authenticate = true) => {
  const token = await firebaseClient.getAuth().currentUser?.getIdToken();
  const selectedLanguage = typeof localStorage !== 'undefined' ? localStorage.getItem('gc-language') || 'en' : 'en';
  return {
    ...options,
    paramsSerializer: (params: any) => serializeParams(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': selectedLanguage,
      'Cache-Control': 'max-age=0',
      ...(authenticate && {
        'Authorization': 'Bearer ' + token,
      }),
      ...options.headers,
    },
  };
};

export const request: AbstractRequest = async ({ options, authenticate = true, maxRetries = 3, mockRequest = false }): Promise<AxiosResponse> => {
  if (mockRequest) {
    options.url = options.url?.replace(process.env.REACT_APP_GREENCAST_API_URL!, process.env.REACT_APP_MOCK_API_URL!);
  }
  const optionsWithHeader = await attachOptions(options, authenticate);
  return axiosRequest(optionsWithHeader).catch((e) => {
    if(e.response && e.response.status && (e.response.status === 403 || e.response.status === 401) && maxRetries > 0) {
      if([401].includes(e.response.status)) {
        firebaseClient.auth.signOut();
      }
    }
    throw(e);
  });
};
