// ** MUI Imports
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';


const Checkbox = (theme: Theme) => {
  return {
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: grey[900],
        },
      },
    },
  };
};

export default Checkbox;
