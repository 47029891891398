import { AxiosResponse } from 'axios';

import { Marketplace } from 'modules/Marketplace/marketplace';
import { PaginatedResults } from 'types/Query';
import { camelizeObject, snakizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';


const apiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getMarketplaceVendors = (
  params?: Partial<Marketplace.Vendors.GetVendorsParams>,
): Promise<AxiosResponse<PaginatedResults<Marketplace.Vendors.Vendor> & {isEmptyState?: boolean}>> => {
  const mappedParams = {
    ...params,
    ...params?.pageSize && { limit: params.pageSize },
    ...params?.pageSize && params?.page && { offset: params.pageSize * params.page },
  };

  return request({
    options: {
      url: `${apiBaseUrl}/vendors/marketplace`,
      method: 'GET',
      params: snakizeObject(mappedParams),
    },
  }).then((data: AxiosResponse<PaginatedResults<Marketplace.Vendors.VendorApi>>) => {
    return {
      ...data,
      data: {
        ...data.data,
        results: data.data.results.map(camelizeObject<Marketplace.Vendors.Vendor>),
      },
    };
  });
};

const getMarketplaceFilterOptions = (vendorType: string): Promise<AxiosResponse<Marketplace.FilterOptions.Options>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/vendors/marketplace/filter-options`,
      method: 'GET',
      params: { vendor_type: vendorType },
    },
  }).then((data: AxiosResponse<Marketplace.FilterOptions.OptionsApi>) => {
    return {
      ...data,
      data: camelizeObject<Marketplace.FilterOptions.Options>(data.data),
    };
  });
};

export const marketplaceClient = {
  getMarketplaceVendors,
  getMarketplaceFilterOptions,
};
