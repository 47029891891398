import React from 'react';
import { IconContainer } from './IconContainer';


export const BeakerIcon = ({ ...props }) => (
  <IconContainer {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.49996 3.52795V8.17157C8.49996 8.90092 8.21023 9.60039 7.6945 10.1161L6.47809 11.3325C7.68601 11.2911 8.89512 11.5003 10.0275 11.9532L10.5295 12.1541C11.8561 12.6847 13.3097 12.8115 14.708 12.5187L12.3054 10.1161C11.7897 9.60039 11.5 8.90092 11.5 8.17157V3.52795C11.0023 3.50937 10.5022 3.5 9.99996 3.5C9.49768 3.5 8.99763 3.50937 8.49996 3.52795ZM13 3.61218C13.0634 3.61695 13.1268 3.62186 13.1902 3.62693C13.6031 3.65992 13.9646 3.35194 13.9976 2.93905C14.0306 2.52615 13.7226 2.16468 13.3097 2.13169C12.9713 2.10466 12.6318 2.08173 12.2912 2.06296C11.5327 2.02117 10.7688 2 9.99996 2C9.23115 2 8.46721 2.02117 7.7087 2.06296C7.36809 2.08173 7.02859 2.10466 6.69022 2.13169C6.27733 2.16468 5.96935 2.52615 6.00234 2.93905C6.03533 3.35194 6.3968 3.65992 6.8097 3.62693C6.87307 3.62186 6.9365 3.61695 6.99996 3.61218V8.17157C6.99996 8.50309 6.86826 8.82104 6.63384 9.05546L2.60031 13.089C1.10382 14.5855 1.78331 17.2391 4.00333 17.5645C5.96107 17.8515 7.9634 18 9.99996 18C12.0365 18 14.0388 17.8515 15.9966 17.5645C18.2166 17.2391 18.8961 14.5855 17.3996 13.089L13.3661 9.05546C13.1317 8.82104 13 8.50309 13 8.17157V3.61218Z"
      fill="currentColor"
    />
  </IconContainer>
);
