import { MAX_FILE_SIZE } from './Dropzone';
import { IFileUpload } from './dropInterfaces';


export const getDragVariant = ({
  isDragActive,
  fileUploaded,
}: {
  fileUploaded: IFileUpload | null;
  isDragActive: boolean;
}) => {
  let dragVariant;

  if (isDragActive) {
    dragVariant = 'dragAccepted' as 'dragAccepted';
  } else if (fileUploaded?.isAccepted === false) {
    if (fileUploaded?.size > MAX_FILE_SIZE) {
      dragVariant = 'dragSizeRejected' as 'dragSizeRejected';
    } else {
      dragVariant = 'dragExtensionRejected' as 'dragExtensionRejected';
    }
  } else if (fileUploaded?.isAccepted) {
    dragVariant = 'dragDropped' as 'dragDropped';
  } else {
    dragVariant = 'drag' as 'drag';
  }

  return dragVariant ;
};

