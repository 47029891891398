import { MutableRefObject } from 'react';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';


interface IuseSelectAllProps {
  apiRef: MutableRefObject<GridApiPro>;
}

export const useSelectAllProps = ({
  apiRef,
}: IuseSelectAllProps) => {
  const { setState, selectRows, getAllRowIds, getSelectedRows } = apiRef.current;
  const selectedRowsSize = getSelectedRows().size;
  const allRowsSize = getAllRowIds().length;

  const selectAllRowsHandler = () => {
    selectRows(getAllRowIds());
  };

  const unselectAllHandler = () =>
    setState((prev: any) => ({
      ...prev,
      selection: [],
    }));

  if (selectedRowsSize > 0 && selectedRowsSize < allRowsSize)
    return {
      checkBoxState: 'fewCheckBoxesSelected',
      clickHandler: unselectAllHandler,
    };

  if (selectedRowsSize === 0)
    return {
      checkBoxState: 'anyCheckBoxSelected',
      clickHandler: selectAllRowsHandler,
    };

  if (selectedRowsSize === allRowsSize)
    return {
      checkBoxState: 'allCheckBoxesSelected',
      clickHandler: unselectAllHandler,
    };

  return {
    checkBoxState: 'anyCheckBoxSelected',
    clickHandler: unselectAllHandler,
  };
};
