import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { GetTransactionsStatisticsParams, TransactionStatistic } from 'clients/transactions/transactionClient.types';
import { transactionClient } from 'clients/transactions/transactionClient';
import { useLocalizedCurrency } from './useLocalizedCurrency';


type Options = {
  enabled: boolean
};

interface LocalizedTransactionStatistic extends TransactionStatistic {
  localizedAmount?: number | null | string;
}

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useTransactionsStatistics = (params: GetTransactionsStatisticsParams = {}, options: Partial<Options> = defaultOptions) => {

  const localizeCurrency = useLocalizedCurrency();

  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.transactions.getTransactionsStatistics, params],
    () => transactionClient.getTransactionsStatistics(params),
    {
      enabled: options.enabled,
    },
  );

  const localizedData: LocalizedTransactionStatistic[] | [] = data?.results ? data.results.map(result => ({
    ...result,
    id: `${result.id}-${result.currency}`,
    localizedAmount: localizeCurrency(result.totalAmount, result.currency),
  })) : [];

  return {
    status,
    error,
    count: data?.count || 0,
    statistics: localizedData,
  };
};