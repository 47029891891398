import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { routes } from 'config';
import { LayoutContext } from 'contexts';
import { useWorkspaceInvitation } from 'shared/hooks/useWorkspaceInvitation';
import { CoolsetPageLoader } from 'shared/components/layout/CoolsetLoader/CoolsetLoader';


export const WorkspaceInvite = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { genericError, genericSuccess } = useContext(LayoutContext);
  const { status } = useWorkspaceInvitation({ id: token || '' });

  useEffect(() => {
    if (status === 'error') {
      genericError();
      navigate(routes.home);
    }
    if (status === 'success') {
      queryClient.resetQueries();
      genericSuccess('You have joined a new workspace!');
      navigate(routes.home);
    }
  }, [status, genericError, navigate, queryClient, genericSuccess]);

  return <CoolsetPageLoader/>;
};
