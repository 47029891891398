import React, { FC, Fragment, ReactElement, Ref, useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLocalStorage } from 'react-use';
import { Divider, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash';
import { useElasticEmissionFactorCategories } from 'modules/Emissions/hooks/useElasticEmissionFactorCategories';
import { DocumentChartBarIcon } from 'shared/icons/DocumentIcon';
import { format } from 'date-fns';
import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { addQuery } from 'helpers/query';
import { generateDownload } from 'helpers/download';
import { CompanyTag } from 'clients/companyTags/companyTagClient.types';
import { DateAggregation, TransactionStatus } from 'clients/transactions/transactionClient.types';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';
import { reportClient } from 'clients/report/reportClient';
import { ReportTypes } from 'clients/report/reportClient.types';
import { useCompanyTags } from 'shared/hooks/useCompanyTags';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useEmissionFactorCategories } from 'shared/hooks/useEmissionFactorCategories';
import { useDateFilters } from 'shared/hooks/useDateFilters';
import { DateRangeSelect } from 'shared/components/interactive/DateRangeSelect/DateRangeSelect';
import { IntensitySelect } from 'shared/components/interactive/IntensitySelect/IntensitySelect';
import { CompanyTagAutocomplete } from 'shared/components/layout/CompanyTagAutocomplete/CompanyTagAutocomplete';
import { SelectWithSearch, SelectWithSearchOption } from 'shared/components/form/SelectWithSearch/SelectWithSearch';


export type Filters = Partial<{
    search?: string;
    status?: TransactionStatus;
    gteDate?: Date;
    lteDate?: Date;
    dateAggregation?: DateAggregation;
    ordering?: string;
    confidence?: string;
    intensity?: string;
    setGteDate?: any;
    setLteDate?: any;
    setSearch?: any;
    setStatus?: any;
    setSortBy?: any;
    setOrdering?: any;
    setConfidence?: any;
    scope?: Scope;
    tags?: string[];
    setTags?: string[];
    setScope?: any;
    emissionFactorCategory?: any;
    setEmissionFactorCategory?: any;
    setIntensity?: any;
    isParent?: boolean;
    year?: string;
    resetFilters?: (params?: ResetFilters) => void;
}>

export const FilterModules = {
  YEAR: 'year',
  INTENSITY: 'intensity',
  CATEGORY: 'category',
  REPORT: 'report',
  SCOPE: 'scope',
  TAGS: 'tags',
};

type Props = {
    emissionsContainerBarChartRef?: Ref<ReactElement>,
    emissionsBarChartRef?: Ref<ReactElement>,
    emissionsContainerBarRef?: Ref<ReactElement>,
    emissionsContainerCategoryBreakdownRef?: Ref<ReactElement>,
    title: string;
    children(props: {
        filters: Filters,
    }): ReactElement;
    filterModules?: string[];
    isNotSticky?: boolean;
    pageActions?: ReactElement[];
}

type ResetFilters = {
  preseveKeys?: (keyof Filters)[];
};

export const FiltersSection: FC<Props> = ({
  children,
  title,
  filterModules = [],
  emissionsContainerBarChartRef,
  emissionsBarChartRef,
  emissionsContainerBarRef,
  emissionsContainerCategoryBreakdownRef,
  isNotSticky,
  pageActions,
}) => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const { genericError } = React.useContext(LayoutContext);
  const { user } = useContext(UserContext);

  const {
    emissionFactorCategories,
    status: emissionFactorCategoriesStatus,
  } = useEmissionFactorCategories({ limit: 2000 });
  const { companyTags } = useCompanyTags();

  const {
    gteDate,
    lteDate,
    maxTransactionDate,
    handleDateChange,
    minimumDateGte,
    lastYearDateGte,
    lastYearDateLte,
    year,
  } = useDateFilters();

  const [defaultScope, setDefaultScope] = useLocalStorage<Scope>('scope-filter', Scope.All);
  const [defaultTagsIds, setDefaultTagsIds] = useLocalStorage<string[]>('tags-filter', []);
  const [defaultEFCategory, setDefaultEFCategory, removeDefaultEFCategory] = useLocalStorage<string | undefined>('category-filter');
  const [defaultIntensity, setDefaultIntensity, removeDefaultIntensity] = useLocalStorage<string | undefined>('intensity-filter');
  const { categories: parentCategories } = useElasticEmissionFactorCategories({ isParent: true });
  const [isGeneratingReport, setIsGeneratingReport] = React.useState<boolean>(false);

  const defaultFilters: Filters = useMemo(() => ({
    gteDate: lastYearDateGte,
    lteDate: lastYearDateLte,
    scope: Scope.All,
    dateAggregation: DateAggregation.Month,
    tags: [],
  }), [lastYearDateLte, lastYearDateGte]);

  const [status, setStatus] = useQueryState<TransactionStatus>('status');
  const [scope, setScope] = useQueryState<Scope>('scope', defaultScope);
  const [ordering, setOrdering] = useQueryState('ordering');
  const [confidence, setConfidence] = useQueryState('confidence');
  const [tags, setTags] = useQueryState<string[]>('tags', defaultTagsIds);
  const [emissionFactorCategory, setEmissionFactorCategory] = useQueryState('category', defaultEFCategory);
  const [emissionIntensity, setEmissionIntensity] = useQueryState('intensity', defaultIntensity);

  useEffect(() => {
    const availableTagsIds = [...companyTags.map((option) => String(option.id)), 'null'];
    if (companyTags.length > 0 && tags) {
      const currentTags = tags.filter((id) => availableTagsIds.includes(String(id)));
      if (currentTags.length !== tags.length) {
        setTags(currentTags);
        setDefaultTagsIds(currentTags);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyTags]);

  const shouldShowFilter = (module: string) => filterModules.includes(module);
  const showTagsFilter = shouldShowFilter(FilterModules.TAGS) && !isEmpty(companyTags);

  const emissionFactorCategoryValue = useMemo(() => emissionFactorCategories.find(({ slug }) => (
    slug === emissionFactorCategory
  )), [emissionFactorCategories, emissionFactorCategory]);

  const emissionFactorCategoryOptions = useMemo<SelectWithSearchOption[]>(() => {
    const options = parentCategories.map((c) => ({ name: c.title, id: c.slug })) as SelectWithSearchOption[];

    emissionFactorCategories.forEach((category) => {
      if (category.parent) {
        const parent = options.find((c) => c.id === category.parent.slug);

        if (parent) {
          parent.subOptions = [
            ...parent.subOptions || [],
            { name: category.title, id: category.slug, parentId: category.parent.slug },
          ];
        }
      }
    });

    return options;

  }, [emissionFactorCategories, parentCategories]);

  const emissionFactorCategoryOptionsValue = React.useMemo(() => {
    let value = emissionFactorCategoryOptions.find((ef) => ef.id === emissionFactorCategory);

    if (!value) {
      value = emissionFactorCategoryOptions
        .map((ef) => ef.subOptions || [])
        .flat()
        .find((ef) => ef.id === emissionFactorCategory);
    }

    return value || null;
  }, [emissionFactorCategory, emissionFactorCategoryOptions]);

  const isParent = useMemo(() => !emissionFactorCategoryValue?.parent, [emissionFactorCategoryValue]);

  const scopeOptions = React.useMemo(() => ([
    { id: Scope.One, name: dictionary.measurements.toggleButtonLabels.scope[1] },
    { id: Scope.Two, name: dictionary.measurements.toggleButtonLabels.scope[2] },
    { id: Scope.Three, name: dictionary.measurements.toggleButtonLabels.scope[3] },
  ]), [dictionary]);

  const scopeValue = React.useMemo(() =>
    scope !== Scope.All ? scopeOptions.find((s) => s.id === scope) || null : null,
  [scope, scopeOptions]);

  const selectedTags = React.useMemo(() => (
    [
      { id: 'null', name: dictionary.filterSection.companyTagsLabels.emptyOptionLabel },
      ...companyTags,
    ].filter((ct) => tags?.includes(String(ct.id)))
  ), [companyTags, tags, dictionary]);

  const filters = useMemo<Filters>(() => ({
    status: status,
    ordering: ordering,
    confidence: confidence,
    gteDate: gteDate,
    lteDate: lteDate,
    dateAggregation: DateAggregation.Month,
    emissionFactorCategory: emissionFactorCategoryValue,
    scope: scope === Scope.All ? undefined : scope,
    tags,
    intensity: emissionIntensity,
  }), [
    status,
    ordering,
    confidence,
    scope,
    tags,
    emissionFactorCategoryValue,
    gteDate,
    lteDate,
    emissionIntensity,
  ]);

  const handleTagsChange = useCallback((tags: CompanyTag[]) => {
    const newTagsIds = tags.map((tag) => tag.id as string);
    setDefaultTagsIds(newTagsIds);
    setTags(newTagsIds);
  }, [setTags, setDefaultTagsIds]);

  const handleScopeChange = useCallback((scopeValue: string = Scope.All) => {
    setScope(scopeValue as Scope);
    setDefaultScope(scopeValue as Scope);
  }, [setScope, setDefaultScope]);

  const handleEFCategoryChange = useCallback((categoryValue?: string) => {
    setEmissionFactorCategory(categoryValue);
    categoryValue ? setDefaultEFCategory(categoryValue) : removeDefaultEFCategory();
  }, [setDefaultEFCategory, removeDefaultEFCategory, setEmissionFactorCategory]);

  const handleEmissionIntensityChange = useCallback((value?: string) => {
    setEmissionIntensity(value);
    value ? setDefaultIntensity(value) : removeDefaultIntensity();
  }, [setDefaultIntensity, removeDefaultIntensity, setEmissionIntensity]);

  const handleReportClick = useCallback(async () => {
    try {
      setIsGeneratingReport(true);
      const report = await reportClient.getReport({
        startDate: format(gteDate, 'yyyy-MM-dd'),
        endDate: format(lteDate, 'yyyy-MM-dd'),
        reportType: ReportTypes.emission,
      });

      const datesString = `${format(gteDate, 'dd-MM-yyyy')} - ${format(lteDate, 'dd-MM-yyyy')}`;
      generateDownload({
        blobFile: report.data,
        downloadName: (
          `${user?.company?.name || 'Company'}'s sustainability report ${datesString}`
        ),
      });
    }
    catch (e) {
      genericError();
    }
    finally {
      setIsGeneratingReport(false);
    }
  }, [gteDate, lteDate, user, genericError]);

  const resetFilters = useCallback(({ preseveKeys }: ResetFilters = { }) => {
    const mappedFilters = { ...defaultFilters };
    preseveKeys?.forEach((key) => {
      mappedFilters[key] = filters[key];
    });
    if (mappedFilters.tags) {
      handleTagsChange(
        mappedFilters.tags.map((t) => ({ id: t, name: companyTags.find((ct) => ct.id === t)?.name || 'NA' })),
      );
    }
    navigate({
      search: addQuery('', mappedFilters),
    });
  }, [defaultFilters, navigate, filters, handleTagsChange, companyTags]);

  if (emissionFactorCategoriesStatus !== 'success') {
    return null;
  }

  return (
    <Fragment>
      <div className={`title-and-filters-container ${isNotSticky ? 'should-not-stick' : ''}`}>
        <div className="_d-flex-jc-sb-md-gap">
          <div className="_d-flex-ali-center-md-gap">
            <Typography variant="pageTitle">{title}</Typography>
            {shouldShowFilter(FilterModules.YEAR) && (
              <DateRangeSelect
                gteDate={filters.gteDate!}
                lteDate={filters.lteDate!}
                onChange={handleDateChange}
                minimumDate={minimumDateGte}
                maximumDate={maxTransactionDate}
                year={year}
              />
            )}
            {shouldShowFilter(FilterModules.INTENSITY) && (
              <IntensitySelect
                value={filters.intensity}
                onChange={handleEmissionIntensityChange}
                year={year}
                gteDate={filters.gteDate!}
                lteDate={filters.lteDate!}
              />
            )}
          </div>

          <div className="_d-flex-ali-center-md-gap">
            {shouldShowFilter(FilterModules.SCOPE) && (
              <SelectWithSearch
                options={scopeOptions}
                value={scopeValue}
                onChange={(options) => handleScopeChange(options[0]?.id as string | undefined)}
                label={dictionary.filterSection.scope.autocompleteLabel}
                hideSearch
              />
            )}

            {shouldShowFilter(FilterModules.CATEGORY) && (
              <SelectWithSearch
                options={emissionFactorCategoryOptions}
                value={emissionFactorCategoryOptionsValue}
                onChange={(options) => handleEFCategoryChange(options[0]?.id as string | undefined)}
                label={dictionary.filterSection.EFCategories.autocompleteLabel}
                placeholder={dictionary.filterSection.searchFor(dictionary.filterSection.EFCategories.autocompleteSearchLabel)}
              />
            )}

            {showTagsFilter && (
              <CompanyTagAutocomplete
                value={selectedTags}
                onChange={handleTagsChange}
              />
            )}

            {shouldShowFilter(FilterModules.REPORT) && (
              <LoadingButton
                onClick={handleReportClick}
                loading={isGeneratingReport}
                variant="contained"
                color="primary"
              >
                <DocumentChartBarIcon/>
                {dictionary.filterSection.report}
              </LoadingButton>
            )}
            {pageActions && pageActions}
          </div>
        </div>
        <Divider sx={{ mt: 2 }}/>
      </div>
      {children({
        filters: {
          ...filters,
          year,
          setStatus,
          setScope: handleScopeChange,
          setEmissionFactorCategory: handleEFCategoryChange,
          setOrdering,
          setConfidence,
          isParent,
          resetFilters,
        },
      })}
    </Fragment>
  );
};
