import {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Divider,
  Grid,
  Skeleton,
  Stack,
} from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { ResponsiveContainer } from 'recharts';

import { Intensity } from 'modules/Emissions/enums';
import { scopeColors } from 'shared/styles/muiTheme';
import { getYear, subYears } from 'date-fns';
import { ClockIcon } from 'shared/icons/ClockIcon';
import { CongratsIcon } from 'shared/icons/CongratsIcon';
import { MultiAreaChart } from 'modules/Charts/components/MultiAreaChart/MultiAreaChart';
import MultiChartLegend from 'modules/Charts/components/ChartLegend/MultiChartLegend';
import { LayoutContext, LocalizationContext } from 'contexts';
import { ChartGroupBy, ChartType } from 'clients/charts/chartClient.types';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';
import { useEmissionFactorCategories } from 'shared/hooks/useEmissionFactorCategories';
import { useCharts } from 'shared/hooks/useCharts';
import { useActivityBasedCategories } from 'shared/hooks/useActivityBasedSubCategories';
import { useDateFilters } from 'shared/hooks/useDateFilters';
import { CompanyPlanAlert } from 'shared/components/alerts/CompanyPlanAlert';
import { OpenTransactionsAlert } from 'shared/components/alerts/OpenTransactionsAlert';
import { FilterModules, FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';

import { ChartSection } from 'shared/components/layout/ChartSection';
import { MessageFeedback } from 'shared/components/layout/Feedback/MessageFeedback';
import { NoTransactionsFeedback } from 'shared/components/layout/Feedback/NoTransactionsFeedback';
import CheckBox from 'shared/components/interactive/Checkbox/Checkbox';
import { Transactions } from 'views/LoggedIn/Transactions/Transactions';
import EmissionsComparingMessageBox from './components/EmissionsComparingMessageBox';
import { getBarChartDataByScope, getLegendItemColors, getLegendItems, isNegativeChartData } from './helpers';
import { EmissionsByType } from './components/EmissionsByType/EmissionsByType';


const checkboxSquareSxProps = { height: '15px', width: '15px' };
const checkedSquareSxProps = { bgcolor: 'black', borderColor: 'black', ...checkboxSquareSxProps };
const checkIconSxProps = { color: 'black' };
const muiSxCheckBox = { '& .MuiSvgIcon-root': { fontSize: 13 } };


export const EmissionFactorsDashboard = ({
  emissionsContainerBarChartRef,
  setEmissionsBarChartRef,
  emissionsContainerBarRef,
  emissionsContainerCategoryBreakdownRef,
  filters,
}: any) => {
  const {
    scope,
    dateAggregation,
    gteDate,
    lteDate,
    emissionFactorCategory,
    setEmissionFactorCategory,
    isParent,
    tags,
    intensity,
  } = filters;
  const { dictionary } = useContext(LocalizationContext);
  const { emissionFactorCategoriesColors } = useContext(LayoutContext);
  const [highlightedCategoryBar, setHighlightedCategoryBar] = useState<string>();
  const [isComparingMonths, setIsComparingMonths] = useState<boolean>(false);
  const handleCompareMonthsChange = useCallback(() => setIsComparingMonths(prev => !prev), []);
  const reloadChartsRef = useRef(emissionFactorCategory ? true : false);
  const { minimumDateGte } = useDateFilters();

  const comparibilityEnabled = useMemo(() => {
    const lowestYear = getYear(gteDate);
    return lowestYear > getYear(minimumDateGte);
  }, [gteDate, minimumDateGte]);

  const mappedFilters = useMemo(() => ({
    dateGte: gteDate,
    dateLte: lteDate,
    scope: scope !== Scope.All ? scope : [Scope.One, Scope.Two, Scope.Three],
    tags,
    relativeTo: intensity,
  }), [scope, gteDate, lteDate, tags, intensity]);
  const { emissionFactorCategories } = useEmissionFactorCategories({ limit: 2000 });
  const { isLoading: isLoadingActivityBased } = useActivityBasedCategories({ mandatory: false });

  const {
    data: barChartData,
    isLoadingCharts: isLoadingBarChartData,
    isFetching: isFetchingBarCharData,
  } = useCharts({
    dateAggregation: dateAggregation,
    groupBy: emissionFactorCategory && reloadChartsRef.current ? ChartGroupBy.category : ChartGroupBy.scope,
    metricLabel: emissionFactorCategory && reloadChartsRef.current ? 'Category' : 'Scope',
    ...(reloadChartsRef.current ? (isParent ?
      { categoryId: emissionFactorCategory?.id } :
      { subCategoryId: emissionFactorCategory?.id }) : null),
    type: ChartType.bar,
    ...mappedFilters,
    scope: scope ? scope : [Scope.One, Scope.Two, Scope.Three, Scope.Offset],
  });

  const {
    data: barChartDataPrev,
    isFetching: isFetchingBarCharDataPrev,
  } = useCharts(
    {
      dateAggregation: dateAggregation,
      groupBy: emissionFactorCategory && reloadChartsRef.current ? ChartGroupBy.category : ChartGroupBy.scope,
      metricLabel: emissionFactorCategory && reloadChartsRef.current ? 'Category' : 'Scope',
      type: ChartType.bar,
      ...((isParent && reloadChartsRef.current) ?
        { categoryId: emissionFactorCategory?.id } :
        { subCategoryId: emissionFactorCategory?.id }),
      ...mappedFilters,
      dateGte: subYears(gteDate, 1),
      dateLte: subYears(lteDate, 1),
      scope: scope ? scope : [Scope.One, Scope.Two, Scope.Three, Scope.Offset],
    },
    { enabled: isComparingMonths },
  );

  const { mappedBarChartDataNegative, mappedBarChartDataPositive } = useMemo(() => {
    const chartDataByScope = getBarChartDataByScope(barChartData?.values);
    const mappedBarChartDataPositive =
      {
        ...barChartData,
        values: chartDataByScope?.otherScopesBarChartData,
        dimensions: (barChartData?.dimensions || []).map((dimension: any) => ({
          ...dimension,
          color: emissionFactorCategoriesColors?.[dimension.label] || '#000',
        })),
      };
    const mappedBarChartDataNegative = {
      ...barChartData,
      values: chartDataByScope?.scope9BarChartData,
      dimensions: (barChartData?.dimensions || []).map((dimension: any) => ({
        ...dimension,
        color: emissionFactorCategoriesColors?.[dimension.label] || '#000',
      })),
    };
    return ({
      mappedBarChartDataNegative,
      mappedBarChartDataPositive,
    });},
  [barChartData, emissionFactorCategoriesColors],
  );

  const { mappedPrevYearBarChartDataPositive, mappedBarPrevYearChartDataNegative } = useMemo(() => {
    let mappedBarPrevYearChartDataNegative;
    let mappedPrevYearBarChartDataPositive;

    if (isComparingMonths && barChartDataPrev && comparibilityEnabled) {
      const chartDataByScope = getBarChartDataByScope(barChartDataPrev?.values);
      mappedPrevYearBarChartDataPositive =
      {
        ...barChartDataPrev,
        values: chartDataByScope?.otherScopesBarChartData,
        dimensions: (barChartDataPrev?.dimensions || []).map((dimension: any) => ({
          ...dimension,
          color: emissionFactorCategoriesColors?.[dimension.label] || '#EBC371',
        })),
      };
      mappedBarPrevYearChartDataNegative = {
        ...barChartDataPrev,
        values: chartDataByScope?.scope9BarChartData,
        dimensions: (barChartDataPrev?.dimensions || []).map((dimension: any) => ({
          ...dimension,
          color: emissionFactorCategoriesColors?.[dimension.label] || '#000',
        })),
      };
    }
    return {
      mappedPrevYearBarChartDataPositive,
      mappedBarPrevYearChartDataNegative,
    };
  },
  [barChartDataPrev, emissionFactorCategoriesColors, isComparingMonths, comparibilityEnabled],
  );

  const isAnyChartsLoading = isLoadingActivityBased || isLoadingBarChartData;
  const isAnyChartsFetching = isFetchingBarCharData || (isFetchingBarCharDataPrev && isComparingMonths);
  const legendItemColors = useMemo(() => getLegendItemColors({ isComparingMonths, barChartDataPrev, mappedFilters, scopeColors }), [barChartDataPrev, isComparingMonths, mappedFilters]);
  const legendItems = useMemo(() => getLegendItems({ reloadChartsRef, isComparingMonths, barChartDataPrev, mappedFilters }), [barChartDataPrev, isComparingMonths, mappedFilters]);

  const hasTransactions = barChartData?.meta?.count > 0 ?? false;
  const hasClassifiedTransactions = (barChartData?.meta?.count > 0 && barChartData?.meta?.total_co2_kg !== 0) ?? false;
  const hasEmissions = barChartData?.meta?.total_co2_kg !== 0 ?? false;

  if (!isAnyChartsLoading && !hasTransactions) {
    return (
      <Box p={8}><NoTransactionsFeedback/></Box>
    );
  }

  return (
    <Grid container pb={8}>
      <Grid pt={2} pb={6} item xs={12}>
        {isAnyChartsLoading ? (
          <Skeleton width="100%" height="100px" variant="rounded"/>
        ) : (
          <OpenTransactionsAlert/>
        )}
      </Grid>
      <Grid ref={emissionsContainerBarChartRef} item xs={12}>
        <ChartSection
          title={dictionary.measurements.emissionsBy(dateAggregation)}
          subtitle={(
            <Fragment>
              {intensity === Intensity.absolute && dictionary.measurements.co2e}
              {intensity === Intensity.perEmployee && dictionary.measurements.co2ePerEmployee}
              {intensity === Intensity.perRevenueUnit && dictionary.measurements.co2eUnitOfRevenue}
            </Fragment>
          )}
          actions={hasClassifiedTransactions && hasEmissions && (
            <Stack mr={1} direction="row">
              <FormControlLabel
                sx={{ borderRadius: 4, mr: legendItems.length > 0 ? 6 : 0 }}
                control={
                  <CheckBox
                    muiSxCheckBox={muiSxCheckBox}
                    checkedSquareSxProps={checkedSquareSxProps}
                    checkIconSxProps={checkIconSxProps}
                    squareSxProps={checkboxSquareSxProps}
                    onChange={handleCompareMonthsChange}
                    checked={comparibilityEnabled && isComparingMonths}
                    disabled={!comparibilityEnabled}
                  />
                }
                label={dictionary.filterSection.compareDataCheckboxLabel}
              />
              <MultiChartLegend
                legendItemColors={legendItemColors}
                legendItems={legendItems}
                legendLabel={(item: string) =>
                  item in Object.values(Scope) ? `Scope ${item}` : item
                }
              />
            </Stack>
          )}
          info={dictionary.tooltips.totalEmissions}
        >
          {!isAnyChartsLoading && (!hasClassifiedTransactions || !hasEmissions) && (
            <Box className="_d-flex jc-center" position="absolute" width="100%" top={0} bottom={40}>
              <MessageFeedback
                icon={hasClassifiedTransactions ? <CongratsIcon/> : <ClockIcon/>}
                message={hasClassifiedTransactions ? dictionary.home.dashboard.noEmissions : dictionary.home.dashboard.noAnalysis}
              />
            </Box>
          )}
          <Box width="100%">
            <Box height={550} width="100%" py={3}>
              <ResponsiveContainer width="100%" height="100%">
                <MultiAreaChart
                  newTooltip
                  prevYearNegativeChart={mappedBarPrevYearChartDataNegative}
                  allowDataOverflow={!isNegativeChartData(mappedBarChartDataNegative, mappedBarPrevYearChartDataNegative, isComparingMonths)}
                  isLoadingCharts={isAnyChartsLoading}
                  isFetchingCharts={isAnyChartsFetching}
                  previousData={mappedPrevYearBarChartDataPositive}
                  mapNetOverviewData
                  setEmissionsBarChartRef={setEmissionsBarChartRef}
                  enableClick={!emissionFactorCategory}
                  negativeValues={mappedBarChartDataNegative}
                  data={mappedBarChartDataPositive}
                  setDimension={(categoryTitle: any) => {
                    if (!emissionFactorCategory) {
                      const category = emissionFactorCategories.find(
                        ({ title }) => title === categoryTitle,
                      );
                      if (category) {
                        setEmissionFactorCategory(category.slug);
                      }
                    }
                  }}
                  highlightedDimension={highlightedCategoryBar}
                  setHighlightedDimension={(category: any) => {
                    setHighlightedCategoryBar(category);
                  }}
                  showAxisX
                  showAxisY={hasClassifiedTransactions && hasEmissions}
                  showChart={hasClassifiedTransactions && hasEmissions}
                  showGrid={hasClassifiedTransactions && hasEmissions}
                  showLegend={false}
                  dateAggregation={dateAggregation}
                />
              </ResponsiveContainer>
            </Box>
            {hasClassifiedTransactions && hasEmissions && (barChartData?.values?.length > 0 || isAnyChartsLoading) && (
              <Box mb={3}>
                <EmissionsComparingMessageBox
                  text={dictionary.measurements.comparingMessageBox.benefits}
                />
              </Box>
            )}
          </Box>
        </ChartSection>
      </Grid>
      <Divider flexItem style={{ width: '100%', marginBottom: '24px' }}/>
      <Grid item xs={12}>
        <EmissionsByType
          emissionsContainerBarRef={emissionsContainerBarRef}
          emissionsContainerCategoryBreakdownRef={emissionsContainerCategoryBreakdownRef}
          hasClassifiedTransactions={hasClassifiedTransactions}
          hasEmissions={hasEmissions}
          filters={filters}
          isComparingMonths={isComparingMonths && comparibilityEnabled}
        />
      </Grid>
      <Divider/>
      <Grid width="100%" item xs={12}>
        <Divider sx={{ my: 5 }} flexItem/>
        <Transactions filters={filters} title={dictionary.measurements.transactionsTitle}/>
      </Grid>
    </Grid>
  );
};

export const Measurements = () => {
  const { dictionary } = useContext(LocalizationContext);
  const emissionsContainerBarChartRef = useRef(null);
  const emissionsContainerBarRef = useRef(null);
  const emissionsContainerCategoryBreakdownRef = useRef(null);
  const [emissionsBarChartRef, setEmissionsBarChartRef] = useState(null);

  return (
    <Grid container direction="column">
      <Grid item>
        <CompanyPlanAlert/>
      </Grid>
      <Grid item>
        <FiltersSection
          filterModules={[
            FilterModules.YEAR,
            FilterModules.INTENSITY,
            FilterModules.REPORT,
            FilterModules.CATEGORY,
            FilterModules.SCOPE,
            FilterModules.TAGS,
          ]}
          emissionsContainerBarChartRef={emissionsContainerBarChartRef}
          emissionsBarChartRef={emissionsBarChartRef}
          emissionsContainerBarRef={emissionsContainerBarRef}
          emissionsContainerCategoryBreakdownRef={emissionsContainerCategoryBreakdownRef}
          title={dictionary.pageTitles.measurements}
        >
          {({ filters }) => (
            <EmissionFactorsDashboard
              emissionsContainerBarChartRef={emissionsContainerBarChartRef}
              setEmissionsBarChartRef={setEmissionsBarChartRef}
              emissionsContainerBarRef={emissionsContainerBarRef}
              emissionsContainerCategoryBreakdownRef={emissionsContainerCategoryBreakdownRef}
              filters={filters}
            />
          )}
        </FiltersSection>
      </Grid>
    </Grid>
  );
};
