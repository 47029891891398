// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Alert = (theme: Theme) => {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
        },
        action: {
          paddingBottom: 4,
          paddingTop: 10,
          marginRight: 0,
        },
        icon: {
          '& .MuiSvgIcon-root': {
            width: '1em',
            height: '1em',
          },
        },
        standard: {
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'transparent',
        },
        standardInfo: {
          backgroundColor: theme.palette.info.light,
          borderColor: theme.palette.info.main,
          color: theme.palette.text.primary,
          '& .MuiAlert-icon': {
            color: theme.palette.info.dark,
          },
        },
        standardWarning: {
          backgroundColor: theme.palette.warning.light,
          borderColor: theme.palette.warning.main,
          color: theme.palette.text.primary,
          '& .MuiAlert-icon': {
            color: theme.palette.warning.dark,
          },
        },
      },
    },
  };
};

export default Alert;
