// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Paper = (theme: Theme) => {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        square: true,
      },
    },
  };
};

export default Paper;
