import React, { FC, Fragment, useMemo } from 'react';
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { North, South } from '@mui/icons-material';

import { ChartGroupBy, ChartType } from 'clients/charts/chartClient.types';
import { useCharts } from 'shared/hooks/useCharts';
import { useEmissionFactorCategories } from 'shared/hooks/useEmissionFactorCategories';
import { Emissions } from 'shared/components/layout/Emissions/Emissions';
import { ChildCategoryProps } from 'views/LoggedIn/Measurements/types';
import { getPercentageChangeColorCategoryTable, getTextWeightCategoryTable } from 'views/LoggedIn/Measurements/helpers';


const ChildCategory: FC<ChildCategoryProps> = ({
  emissionFactorCategory,
  slug: parentSlug,
  mappedChildCategoryChartData,
  isParent,
  mappedCategoryChartData,
  highlightedCategoryBarTable,
  setHighlightedCategory,
  reloadChartsRef,
  setEmissionFactorCategory,
  mappedFilters,
  isComparingMonths,
}) => {
  const { emissionFactorCategories } = useEmissionFactorCategories({
    limit: 2000,
  });

  const emissionFactorCategoryValue = useMemo(
    () => emissionFactorCategories.find(({ slug }) => slug === parentSlug),
    [emissionFactorCategories, parentSlug],
  );

  const { isLoadingCharts } = useCharts({
    groupBy: ChartGroupBy.category,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...mappedFilters,
    categoryId: emissionFactorCategoryValue?.id,
  });

  if (isLoadingCharts) {
    return <Skeleton variant="rounded" sx={{ height: 24, my: 2 }}/>;
  }

  const chartData = (isParent ? mappedCategoryChartData : mappedChildCategoryChartData) || [];

  return (
    <Fragment>
      {(chartData).map(({ name, value, fill, slug, co2Percentage, previousValues, percentageChange }: any) => (
        <Grid
          className="fadein-at-render"
          key={slug}
          container
          onMouseEnter={() => setHighlightedCategory(slug)}
          onMouseLeave={() => setHighlightedCategory(undefined)}
          alignItems="center"
          spacing={1}
          sx={{
            mt: 1,
            mb: 1,
            '--opacity': (!emissionFactorCategory?.parent || emissionFactorCategory?.slug === slug || highlightedCategoryBarTable === slug || (!emissionFactorCategory?.slug && !highlightedCategoryBarTable) )? 1 : 0.5,
          }}
        >
          <Grid pl={2} item xs={isComparingMonths ? 3 : 4}>
            <Stack gap={1} direction="row">
              <Box
                sx={{
                  background: fill,
                  padding: '2px',
                  borderRadius: 1,
                  height: 16,
                  width: 16,
                }}
              />
              <Typography
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if (emissionFactorCategory?.slug === slug) {
                    reloadChartsRef.current = true;
                    setEmissionFactorCategory(
                      emissionFactorCategory?.parent?.slug,
                    );
                  } else {
                    setEmissionFactorCategory(slug);
                    reloadChartsRef.current = true;
                  }
                }}
                variant="subtitle2"
                fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })}
              >
                {name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={isComparingMonths ? 5 : 4}>
            <Grid container spacing={1} width="100%">
              {isComparingMonths && (
                <Grid item textAlign="right" pr={1} xs={4}>
                  <Typography variant="categoryTable" fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })}>
                    <Emissions showBoldValue={false} kg={previousValues?.value} showCo2={false}/>
                  </Typography>
                  <Typography fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })} variant="body2">{previousValues?.co2Percentage}%</Typography>
                </Grid>
              )}
              <Grid item textAlign="right" pr={1} xs={isComparingMonths ? 4 : 12}>
                <Typography variant="categoryTable" fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })}>
                  <Emissions showBoldValue={false} kg={value} showCo2={false}/>
                </Typography>
                <Typography fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })} variant="body2">{co2Percentage}%</Typography>
              </Grid>
              {isComparingMonths && (
                <Grid item textAlign="right" xs={4}>
                  <Typography
                    fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })}
                    color={getPercentageChangeColorCategoryTable(percentageChange)}
                    variant="body2"
                  >
                    <Box component="span" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                      {percentageChange > 0 && <North fontSize="small"/>}
                      {percentageChange < 0 && <South fontSize="small"/>}
                      {Math.abs(percentageChange).toFixed()}%
                    </Box>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};

export default ChildCategory;
