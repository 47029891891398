import { AxiosResponse } from 'axios';

import { Surveys } from 'modules/Surveys/survey';
import { camelizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';


const apiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getSurvey = (params: any): Promise<AxiosResponse<Surveys.Survey>> => {
  const { name, ...restParams } = params;
  return request({
    options: {
      url: `${apiBaseUrl}/survey/${name}/`,
      method: 'GET',
      params: restParams,
    },
  }).then((data: AxiosResponse<Surveys.SurveyApi>) => {
    return ({
      ...data,
      data: camelizeObject(data.data),
    });});
};

const createSurveyAnswers = (data: {
  name: string;
  answers: Surveys.AnswerCreate[];
}): Promise<AxiosResponse<Surveys.Answer[]>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/survey/${data.name}/answer/`,
      method: 'POST',
      data: data.answers,
    },
  });
};

const editSurveyAnswers = (data: {
  name: string;
  answers: Surveys.AnswerEdit[];
}): Promise<AxiosResponse<Surveys.Answer[]>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/survey/${data.name}/answer/partial-update-batch/`,
      method: 'PATCH',
      data: data.answers.map(({ id, ...answer }) => ({
        ...answer,
        answer: id,
      })),
    },
  });
};

const deleteAnswer = (data: {
  name: string;
  answer: number;
}): Promise<AxiosResponse<void>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/survey/${data.name}/answer/${data.answer}/`,
      method: 'DELETE',
    },
  });
};



export const surveysClient = {
  getSurvey,
  createSurveyAnswers,
  editSurveyAnswers,
  deleteAnswer,
};
