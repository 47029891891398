import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { cacheKeys } from 'config';
import { LayoutContext } from 'contexts';
import { scenarioBuilderClient } from 'clients/scenarioBuilder/scenarioBuilderClient';


export const useScenarioBuilderFormCrud = () => {
  const { genericError } = useContext(LayoutContext);
  const queryClient = useQueryClient();

  const submitForm = useMutation(scenarioBuilderClient.updateBuilder, {
    mutationKey: cacheKeys.scenarioBuilder.editForm,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.scenarioBuilder.getScenarioBuilderData);
    },
    onError: () => {
      genericError();
    },
  });

  return {
    submitForm,
  };
};
