import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { GetCompanyTagsParams } from 'clients/companyTags/companyTagClient.types';
import { companyTagClient } from 'clients/companyTags/companyTagClient';


type Options = {
    enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useCompanyTags = (params: GetCompanyTagsParams = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.companyTags.getCompanyTags, params],
    () => companyTagClient.getCompanyTags(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    companyTags: data?.results || [],
  };
};
