import React from 'react';
import { useLocalStorage } from 'react-use';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { useIntegrations } from 'modules/Expenses/hooks/useIntegrations';
import { Expenses } from 'modules/Expenses/expenses';
import { format } from 'date-fns';
import { FilterChip } from 'modules/Marketplace/components/FilterChips';
import { useIntegrationsCrud } from 'modules/Expenses/hooks/useIntegrationsCrud';
import { IntegrationStatus } from 'modules/Expenses/helpers/integrationOptions';
import { useChiftIntegrations } from 'modules/Expenses/hooks/useChiftIntegrations';
import { LocalizationContext, WaitingAsyncProcessContext } from 'contexts';
import { AsyncProcessType } from 'contexts/WaitingAsyncProcessContext/types';
import { Dictionary } from 'contexts/LocalizationContext/types';
import { ReactComponent as NoIntegration } from 'shared/img/noIntegrations.svg';
import { AsyncConfirmationDialog } from 'shared/components/dialog/AsyncConfirmationDialog/AsyncConfirmationDialog';
import { DataGridWithEmptyState } from 'shared/components/layout/DataGrid/DataGridWithEmptyState';
import { IntegrationSourceCell } from './IntegrationSourceCell';
import { LinkedByCell } from './LinkedByCell';
import { ActionsCell } from './ActionsCell';
import { IntegrationAccountStatus } from './IntegrationAccountStatus';


const useGetColumns = (
  openCleaning: (id: number, fileName: string | null, isUpdate: boolean) => void,
  openError: (id: number, fileName: string | null) => void,
  onDelete: (id: number) => void,
  onDisconnect: (id: number) => void,
  handleActivateIntegration: (integrationId: number, integrationName: string) => void,
  dictionary: Dictionary,
): GridColDef[] => {

  const columns = React.useMemo<GridColDef[]>(() => {
    const cols = [
      {
        field: 'source',
        headerName: 'Source',
        renderCell: ({ row }: {row: Expenses.Integrations.Integration}) => <IntegrationSourceCell integration={row}/>,
        flex: 1.5,
      },
      {
        field: 'lastUpdated',
        headerName: 'Last updated',
        valueGetter: ({ row }: {row: Expenses.Integrations.Integration}) => row.lastSyncedAt,
        valueFormatter: ({ value }: any) => value && format(new Date(value as string), 'dd MMM \'\'yy h:mm aaa'),
        width: 140,
      },
      {
        field: 'linkedBy',
        headerName: 'Linked by',
        renderCell: ({ row }: {row: Expenses.Integrations.Integration}) => <LinkedByCell integration={row}/>,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 310,
        renderCell: ({ row }: {row: Expenses.Integrations.Integration}) => (
          <Box className="_d-flex-ali-center-sm-gap">
            <FilterChip label={row.status} color={getStatusColor(row.status)}/>
            {row.connectionStatus === IntegrationStatus.INACTIVE && row.status !== IntegrationStatus.INACTIVE && (
              <IntegrationAccountStatus label={dictionary.expenses.linkedAccounting.accountInactive}/>
            )}
          </Box>
        ),
      },
      {
        field: 'actions',
        headerName: '',
        width: 200,
        renderCell: ({ row }: {row: Expenses.Integrations.Integration}) => (
          <ActionsCell
            integration={row}
            openCleaning={openCleaning}
            openError={openError}
            onDelete={onDelete}
            onDisconnect={onDisconnect}
            handleActivateIntegration={handleActivateIntegration}
          />
        ),
      },
    ];

    const filteredColumns: Array<GridColDef> = cols.filter((column) => column !== undefined) as Array<GridColDef>;
    filteredColumns.forEach((c) => c.pinnable = false);
    return filteredColumns;
  }, [openCleaning, onDelete, onDisconnect, handleActivateIntegration, openError, dictionary]);

  return columns;
};


export const IntegrationsTable = (props: IntegrationsTableProps) => {
  const {
    openCleaning,
    openError,
    handleActivateIntegration,
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);
  const { asyncProcesses, removeAsyncProcess } = React.useContext(WaitingAsyncProcessContext);

  const [page = 0, setPage] = useLocalStorage<number>('integrationTable-page');
  const [pageSize = 10, setPageSize] = useLocalStorage<number>('integrationTable-pageSize');
  const [integrationToDelete, setIntegrationToDelete] = React.useState<number | null>(null);
  const [integrationToDisconnect, setIntegrationToDisconnect] = React.useState<number | null>(null);

  const { integrations, integrationCount, isLoading } = useIntegrations({ page, pageSize });
  const { deleteIntegration, isLoading: isUpdatingIntegration } = useIntegrationsCrud();
  const { disconnectChiftIntegration, isDisconnectingChiftIntegration } = useChiftIntegrations();

  const handleDeleteIntegration = async () => {
    try {
      await deleteIntegration(integrationToDelete!);
      const processIntegrationId = asyncProcesses.find((p) => p.processType === AsyncProcessType.CsvUpload)?.data?.integrationId;
      if (processIntegrationId === integrationToDelete) {
        removeAsyncProcess(AsyncProcessType.CsvUpload);
      }
    }
    finally {
      setIntegrationToDelete(null);
    }
  };

  const handleDisconnectIntegration = async () => {
    try {
      await disconnectChiftIntegration(integrationToDisconnect!);
    }
    finally {
      setIntegrationToDisconnect(null);
    }
  };

  return (
    <React.Fragment>
      <AsyncConfirmationDialog
        title={dictionary.expenses.linkedAccounting.deleteDialogTitle()}
        content={dictionary.expenses.linkedAccounting.deleteDialogText}
        open={!!integrationToDelete}
        onConfirm={handleDeleteIntegration}
        onClose={() => setIntegrationToDelete(null)}
        confirmLabel={dictionary.expenses.linkedAccounting.deleteDialogConfirm}
        cancelLabel={dictionary.goBack}
        isLoading={isUpdatingIntegration}
        isDelete
      />
      <AsyncConfirmationDialog
        title={dictionary.expenses.linkedAccounting.disconnectDialogTitle}
        content={dictionary.expenses.linkedAccounting.disconnectDialogText}
        open={!!integrationToDisconnect}
        onConfirm={handleDisconnectIntegration}
        onClose={() => setIntegrationToDisconnect(null)}
        confirmLabel={dictionary.expenses.linkedAccounting.disconnectBtn}
        cancelLabel={dictionary.goBack}
        isLoading={isDisconnectingChiftIntegration}
        isDelete
      />

      <DataGridWithEmptyState
        columns={useGetColumns(
          openCleaning,
          openError,
          setIntegrationToDelete,
          setIntegrationToDisconnect,
          handleActivateIntegration,
          dictionary,
        )}
        rows={integrations}
        rowCount={integrationCount}
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        rowsPerPageOptions={[10, 20, 50, 100, 1000]}
        headerHeight={36}
        paginationMode="server"
        disableColumnSelector
        disableColumnFilter
        disableSelectionOnClick
        disableColumnResize
        autoHeight
        pagination
        loading={isLoading}

        isEmpty={!integrations.length && !isLoading}
        emptyStateImage={<NoIntegration/>}
        emptyStateTitle={dictionary.expenses.linkedAccounting.integrationEmptyTitle}
        emptyStateText={dictionary.expenses.linkedAccounting.integrationEmptyText}
      />
    </React.Fragment>
  );
};

interface IntegrationsTableProps {
  openCleaning: (id: number, fileName: string | null, isUpdate: boolean) => void;
  openError: (id: number, fileName: string | null) => void;
  handleActivateIntegration: (integrationId: number, integrationName: string) => void,
}

const getStatusColor = (status: string): 'default' | 'primary' | 'success' | 'error' => {
  switch (status) {
  case IntegrationStatus.NEED_REV:
    return 'primary';
  case IntegrationStatus.ANAL_RUN:
    return 'success';
  case IntegrationStatus.ERROR:
    return 'error';
  case IntegrationStatus.ANAL_DONE:
  case IntegrationStatus.INACTIVE:
  default:
    return 'default';
  }
};