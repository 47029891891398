import { Fragment, forwardRef } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { IRadioButtonProps } from './form.types';


const RadioButton = forwardRef<HTMLSpanElement, IRadioButtonProps>(
  (props, ref) => {
    const { options, direction, errorMessage, ...restProps } = props;

    return (
      <Fragment>
        <RadioGroup
          {...restProps}
          ref={ref}
          row={direction === 'horizontal' ? true : false}
        >
          {options.map((option) => {
            const { value, ...restOptions } = option;
            const [key, label]= Object.entries(restOptions)[0];
            return (
              <FormControlLabel
                key={key}
                value={value}
                control={<Radio/>}
                label={label}
              />
            );
          })}
        </RadioGroup>
      </Fragment>
    );
  },
);

export default RadioButton;
