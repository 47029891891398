import React, { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { useAuthState } from 'react-firebase-hooks/auth';

import { trackUserData } from 'tracking/googleTagManager';
import { PermissionContext } from 'contexts/PermissionContext/PermissionContext';
import { PermissionKey } from 'contexts/PermissionContext/types';
import { firebaseClient } from 'clients/firebaseClient';
import { UserAuthGroupName } from 'clients/users/userClient.types';
import { useMe } from 'shared/hooks/useMe';
import { ContextProps } from './types';


export const defaultContext: ContextProps = {
  firebaseUser: undefined,
  user: undefined,
  isAdmin: false,
  isEditor: false,
  isNoCompany: false,
  isOwner: false,
  isViewer: false,
  login: () => {
  },
  impersonate: () => {
  },
  ssoLogin: () => {
  },
  logout: () => {
  },
  isLoggedIn: false,
  loading: false,
  error: undefined,
};


export const UserContext = createContext(defaultContext);
export const UserContextProvider: FC<React.PropsWithChildren> = ({ children }) => {

  const { setPermissions } = useContext(PermissionContext);
  const [firebaseUser, loading, error] = useAuthState(firebaseClient.getAuth());

  const queryClient = useQueryClient();
  const { user, isLoading } = useMe({
    enabled: !!firebaseUser && !firebaseUser.isAnonymous,
  });

  useEffect(() => {
    if (user) {
      trackUserData(user);
    }
  }, [user]);

  const isLoggedIn = useMemo(() => !!firebaseUser, [firebaseUser]);

  const isPWA = useMemo(() => {
    return window.hasOwnProperty('matchMedia') && window?.matchMedia('(display-mode: standalone)').matches;
  }, []);

  const isOwner = useMemo(() => user?.authGroup?.name === UserAuthGroupName.Owner, [user]);
  const isAdmin = useMemo(() => user?.authGroup?.name === UserAuthGroupName.Admin, [user]);
  const isEditor = useMemo(() => user?.authGroup?.name === UserAuthGroupName.Editor, [user]);
  const isViewer = useMemo(() => user?.authGroup?.name === UserAuthGroupName.Viewer, [user]);
  const isNoCompany = useMemo(() => user?.authGroup?.name === UserAuthGroupName.NoCompany, [user]);

  const noCompanyPermissions: PermissionKey[] = useMemo(() => ([
    'user.crud.edit',
  ]), []);

  const viewerPermissions: PermissionKey[] = useMemo(() => ([
    'authGroup.crud.view',
  ]), []);

  const editorPermissions: PermissionKey[] = useMemo(() => ([
    ...viewerPermissions,
    'invitation.crud.create',
    'transaction.crud.edit',
    'emissionFactor.crud.view',
    'manualInput.crud.view',
    'user.crud.view',
    'company.crud.view',
    'scenarioBuilder.yearlyPrediction.create',
    'scenarioBuilder.yearlyPrediction.edit',
    'scenarioBuilder.yearlyPrediction.view',
    'scenarioBuilder.crud.create',
    'scenarioBuilder.crud.edit',
    'scenarioBuilder.crud.view',
  ]), [viewerPermissions]);

  const adminPermissions: PermissionKey[] = useMemo(() => ([
    ...editorPermissions,
    'transaction.totalAmount.view',
    'transaction.crud.create',
    'transaction.export',
    'transaction.classification.view',
    'transaction.co2.view',
    'company.crud.edit',
    'user.crud.delete',
    'user.crud.edit',
  ]), [editorPermissions]);

  const ownerPermissions: PermissionKey[] = useMemo(() => ([
    ...adminPermissions,
  ]), [adminPermissions]);

  useEffect(() => {
    if (isOwner) {
      setPermissions(ownerPermissions, true);
    } else if (isAdmin) {
      setPermissions(adminPermissions, true);
    } else if (isEditor) {
      setPermissions(editorPermissions, true);
    } else if (isViewer) {
      setPermissions(viewerPermissions, true);
    } else if (isNoCompany) {
      setPermissions(noCompanyPermissions, true);
    }
  }, [isAdmin, isEditor, isNoCompany, isViewer, isOwner, setPermissions, adminPermissions, editorPermissions, viewerPermissions, ownerPermissions, noCompanyPermissions]);

  const ssoLogin = async (provider: string) => {
    try {
      let ssoProvider: any = new firebaseClient.auth.GoogleAuthProvider();
      if (provider === 'twitter') {
        ssoProvider = new firebaseClient.auth.TwitterAuthProvider();
      }
      if (provider === 'microsoft') {
        ssoProvider = new firebaseClient.auth.OAuthProvider('microsoft.com');
      }
      if (provider === 'facebook') {
        ssoProvider = new firebaseClient.auth.FacebookAuthProvider();
      }
      if (provider === 'google') {
        ssoProvider.addScope('email');
        ssoProvider.addScope('profile');
        ssoProvider.setCustomParameters({ prompt: 'select_account' });
      }
      try {
        if (isPWA) {
          firebaseClient.auth.signInWithRedirect(ssoProvider);
          return Promise.resolve({
            success: true,
          });
        } else {
          const response = await firebaseClient.auth.signInWithPopup(ssoProvider);

          if (response.user) {
            return Promise.resolve({
              success: true,
            });
          } else {
            return Promise.reject({
              success: false,
              message: 'Wrong Email',
            });
          }
        }
      } catch (e: any) {
        return Promise.reject({
          success: false,
          message: e.message,
        });
      }
    } catch (error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const response = await firebaseClient.auth.signInWithEmailAndPassword(email, password);
      return Promise.resolve({
        success: !!response,
      });
    } catch (error: any) {
      return Promise.reject({
        error: error.code,
        message: error.message,
      });
    }
  };

  const impersonate = useCallback(async (token: string) => {
    try {
      const response = await firebaseClient.auth.signInWithCustomToken(token);
      await queryClient.invalidateQueries();
      return Promise.resolve({
        success: !!response,
      });
    } catch (error: any) {
      return Promise.reject({
        error: error.code,
        message: error.message,
      });
    }
  }, [queryClient]);

  const logout = useCallback(async () => {
    try {
      await firebaseClient.auth.signOut();
      queryClient.clear();
    } catch (error) {
      throw error;
    }
  }, [queryClient]);

  return (
    <UserContext.Provider
      value={{
        user,
        firebaseUser,
        isAdmin,
        isEditor,
        isNoCompany,
        isOwner,
        isViewer,
        logout,
        login,
        ssoLogin,
        impersonate,
        isLoggedIn,
        loading: loading || isLoading,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
