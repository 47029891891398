// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Toolbar = (theme: Theme) => {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'rgba(0,0,0,0)',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('sm')]: {
            minHeight: 70,
          },
        },
      },
    },
  };
};

export default Toolbar;
