import React, { ReactElement } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Expenses } from 'modules/Expenses/expenses';
import { CsvErrors } from 'modules/Expenses/components/CsvErrors';
import { CsvUploadErrors } from 'modules/Expenses/hooks/useUploadIntegrationWizard';
import { LocalizationContext } from 'contexts';
import { CsvColumnOption } from 'shared/hooks/useUploadCsvWizard';
import { ReactComponent as UndoICon } from 'shared/img/undo.svg';
import { PreviewTable } from 'shared/components/layout/PreviewTable/PreviewTable';
import { InformationToolTipContent } from 'shared/components/layout/Tooltip/InformationToolTipContent';


export const SelectColumnsStep = (props: SelectColumnsStepProps) => {
  const {
    previewColumns,
    headerIndexes,
    setHeaderIndexes,
    errors,
    isFirstRowHeaders,
    columnOptions,
  } = props;

  const [draggedColIndex, setDraggedColIndex] = React.useState<number | null>(null);

  const handleUndoSelectColumn = (index: number) => {
    const temp = { ...headerIndexes };
    const keyToUndo = Object.keys(temp).find((key) => temp[key] === index);
    delete temp[keyToUndo || ''];

    setHeaderIndexes(temp);
  };

  const handleDroppedIn = (key: string) => {
    const temp = { ...headerIndexes };
    if (draggedColIndex !== null) {
      const keyToUndo = Object.keys(temp).find((key) => temp[key] === draggedColIndex);
      delete temp[keyToUndo || ''];
      temp[key] = draggedColIndex;
    }

    setHeaderIndexes(temp);
    setDraggedColIndex(null);
  };

  return (
    <React.Fragment>
      {!!previewColumns.length && (
        <Box
          className="_d-flex-md-gap"
          sx={{ overflow: 'auto', pb: 3 }}
        >
          {previewColumns.map((col, i) => (
            <DragFromContainer
              key={col.title}
              onUndo={() => handleUndoSelectColumn(i)}
              onDragStart={() => setDraggedColIndex(i)}
            >
              {!Object.values(headerIndexes).includes(i) ? (
                <PreviewTable
                  columnHeaders={[col.title]}
                  previewRows={col.column.map((cell) => [cell])}
                  overflow="hidden"
                  isHeadersHaveTooltips
                />
              ) : undefined}
            </DragFromContainer>
          ))}
        </Box>
      )}

      {!!previewColumns.length && (
        <Box
          className="_d-flex-md-gap-f-wrap"
          sx={{ pt: 2 }}
        >
          {columnOptions.map((option) => (
            <DragInContainer
              key={option.key}
              title={option.title}
              isOptional={option.isOptional}
              onDrop={() => handleDroppedIn(option.key)}
              onDragStart={() => setDraggedColIndex(headerIndexes[option.key])}
              error={errors && errors[option.key]?.error}
              helpText={option.helpText}
            >
              {headerIndexes[option.key] !== undefined ? (
                <PreviewTable
                  columnHeaders={[previewColumns[headerIndexes[option.key]].title]}
                  previewRows={previewColumns[headerIndexes[option.key]].column.map((cell) => [cell])}
                  overflow="hidden"
                  isHeadersHaveTooltips
                />
              ) : undefined}
            </DragInContainer>
          ))}
        </Box>
      )}

      <CsvErrors errors={errors} isFirstRowHeaders={isFirstRowHeaders} columnOptions={columnOptions}/>

    </React.Fragment>
  );
};

const DragFromContainer = (props: DragFromContainerProps) => {
  const {
    children,
    onUndo,
    onDragStart,
  } = props;

  return (
    <div className="drag-container">
      {children ? (
        <DragContainerChild onDragStart={onDragStart} element={children}/>
      ) : (
        <IconButton
          onClick={onUndo}
          sx={{ position: 'absolute', top: 1, right: 1 }}
        >
          <UndoICon/>
        </IconButton>
      )}
    </div>
  );
};

const DragInContainer = (props: DragInContainerProps) => {
  const {
    children,
    error,
    title,
    isOptional,
    onDrop,
    onDragStart,
    helpText,
  } = props;

  const { dictionary: { dragAndDrop } } = React.useContext(LocalizationContext);

  return (
    <Box>
      <div className="_d-flex-ali-baseline-sm-gap">
        <Typography variant="body1" mb={.5}>{title}{!isOptional && '*'}</Typography>
        {helpText && <InformationToolTipContent tooltipTitle={helpText}/>}
      </div>
      <div
        className={`drag-in-container ${error ? 'is-error' : ''}`}
        onDrop={onDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {children ? (
          <DragContainerChild onDragStart={onDragStart} element={children}/>
        ) : (
          <Typography variant="body1" color="text.secondary">
            {dragAndDrop}
          </Typography>
        )}
      </div>
    </Box>
  );
};

const DragContainerChild = (
  { onDragStart, element }: {onDragStart: () => void, element: ReactElement},
) => (
  <Box
    draggable="true"
    onDragStart={onDragStart}
    sx={{ '& .one-line-text': { overflow: 'unset' } }}
  >
    {element}
  </Box>
);

interface SelectColumnsStepProps {
    previewColumns: Expenses.CsvColumn[];
    headerIndexes: {[header: string]: number};
    setHeaderIndexes: any;
    errors: CsvUploadErrors | null;
    isFirstRowHeaders: boolean;
    columnOptions: CsvColumnOption[];
}

interface DragFromContainerProps {
  children?: ReactElement;
  onUndo: () => void;
  onDragStart: () => void;
}

interface DragInContainerProps {
  children?: ReactElement;
  error?: string | null;
  title: string;
  isOptional?: boolean;
  onDrop: () => void;
  onDragStart: () => void;
  helpText?: string;
}