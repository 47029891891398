import React, { useContext, useMemo, Fragment } from 'react';
import { Link } from 'react-router-dom';

import {
  Alert,
  Button,
} from '@mui/material';
import { routes } from 'config';
import { UserContext } from 'contexts';


export const CompanyPlanAlert = () => {
  const { user, isLoggedIn } = useContext(UserContext);

  const resultsLimitPerMonth = useMemo(() => (
    user?.company?.resultsLimitPerMonth ? user?.company?.resultsLimitPerMonth : null
  ), [user],
  );

  return (
    <Fragment>
      {(isLoggedIn && user?.company?.hasReachedResultsLimit && resultsLimitPerMonth && resultsLimitPerMonth > 0) &&
        <Alert
          severity="warning"
          sx={{ padding: 2, alignItems: 'left', justifyContent: 'left', fontSize: 14 }}
          action={
            <Link to={routes.myAccount}>
              <Button>
                Upgrade plan
              </Button>
            </Link>
          }
        >
          Your account has reached the {resultsLimitPerMonth} monthly transactions limit on your current plan. Upgrade to continue viewing more transactions
        </Alert>
      }
    </Fragment>
  );
};
