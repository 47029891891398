import { FunctionComponent } from 'react';
import { Box, Button, ButtonProps } from '@mui/material';

import { InformationToolTipContent } from './InformationToolTipContent';


interface ISkipTransactionsButtonProps {
  toolTipTitle: string;
  btnLabel: string;
  onClick: () => void;
  btnProps?: ButtonProps;
  tooltipContentBgDarker?: boolean;
}

const SkipTransactionsButton: FunctionComponent<ISkipTransactionsButtonProps> = ({ toolTipTitle, tooltipContentBgDarker, btnLabel, onClick, btnProps }) => {
  return (
    <Button
      variant="text"
      color="inherit"
      onClick={onClick}
      {...btnProps}
    >
      {btnLabel}
      <Box ml={1}>
        <InformationToolTipContent
          isBgDarker={tooltipContentBgDarker}
          tooltipTitle={toolTipTitle}
          tooltipProps={{
            sx: {
              width: 200,
              textAlign: 'center',
            },
          }}
        />
      </Box>
    </Button>
  );
};

export default SkipTransactionsButton;
