import { useCallback, useContext, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useLocation, useNavigate } from 'react-router';
import { endOfYear, startOfYear, max, differenceInMonths, differenceInDays, format } from 'date-fns';

import { LayoutContext, UserContext } from 'contexts';

import { addQuery } from 'helpers/query';
import { DateOption } from 'shared/components/interactive/DateRangeSelect/DateRangeSelect';
import { useQueryState } from './useQueryState';


const queryFormat = 'yyyy-MM-dd';
export const newDateWithNoTime = (
  dateString: string | null = '',
  defaultValue: string = '2021-01-01',
): Date => {
  const date = dateString?.split('T')[0] + 'T00:00:00.000';
  return isNaN(Date.parse(date)) ? new Date(defaultValue + 'T00:00:00.000') : new Date(date);
};

export const useDateFilters = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const minimumDateFilter = useMemo(() => newDateWithNoTime(user?.company?.minimumDateFilter), [user]);
  const minTransactionDate = useMemo(() => newDateWithNoTime(user?.company?.minTransactionDate), [user]);
  const maxTransactionDate = useMemo(() => newDateWithNoTime(user?.company?.maxTransactionDate, '2021-12-31'), [user]);
  const minimumDateGte = useMemo(
    () => max([startOfYear(minimumDateFilter), minTransactionDate]),
    [minimumDateFilter, minTransactionDate],
  );

  const lastYearDateLte = useMemo(() => {
    const endOfLastYear = newDateWithNoTime(`${maxTransactionDate.getFullYear() - 1}-12-31`);
    if (differenceInMonths(endOfLastYear, minTransactionDate) >= 6) {
      return endOfLastYear;
    }

    return endOfYear(maxTransactionDate);
  }, [maxTransactionDate, minTransactionDate]);

  const lastYearDateGte = useMemo(() => startOfYear(lastYearDateLte), [lastYearDateLte]);

  const [defaultDateGte, setDefaultDateGte] = useLocalStorage(
    'date-gte-filter',
    format(lastYearDateGte, queryFormat),
  );
  const [defaultDateLte, setDefaultDateLte] = useLocalStorage(
    'date-lte-filter',
    format(lastYearDateLte, queryFormat),
  );
  const { genericError } = useContext(LayoutContext);

  const [queryStateGteDate, queryStateSetGteDate] = useQueryState('gteDate', defaultDateGte);
  const [queryStateLteDate, queryStateSetLteDate] = useQueryState('lteDate', defaultDateLte);

  const gteDate = queryStateGteDate ? newDateWithNoTime(queryStateGteDate) : lastYearDateGte;
  const lteDate = queryStateLteDate ? newDateWithNoTime(queryStateLteDate, '2021-12-31') : lastYearDateLte;

  const [year, setYear] = useState<string | undefined>(() => {
    if (
      !differenceInDays(gteDate, startOfYear(gteDate)) &&
      !differenceInDays(lteDate, endOfYear(lteDate)) &&
      gteDate.getFullYear() === lteDate.getFullYear()
    ) {
      return `${gteDate.getFullYear()}`;
    }
  });

  const handleDateChange = useCallback((option: DateOption, year?: string) => {
    setYear(year);
    const optionGteDate = option.gteDate;
    const optionLteDate = option.lteDate;
    try {
      navigate({
        search: addQuery(location.search, {
          gteDate: format(optionGteDate, queryFormat),
          lteDate: format(optionLteDate, queryFormat),
        }),
      });
      setDefaultDateGte(format(optionGteDate, queryFormat));
      setDefaultDateLte(format(optionLteDate, queryFormat));
    } catch (e) {
      console.error(e);
      genericError();
    }
  }, [genericError, location.search, navigate, setDefaultDateGte, setDefaultDateLte]);

  return {
    gteDate,
    lteDate,
    setDefaultDateGte,
    setDefaultDateLte,
    queryStateSetGteDate,
    queryStateSetLteDate,
    minimumDateFilter,
    minimumDateGte,
    lastYearDateGte,
    lastYearDateLte,
    minTransactionDate,
    maxTransactionDate,
    handleDateChange,
    year,
  };
};
