import {
  EmailStatusDefault,
  getResolvedEmailsStatusValues,
  IsetEnqueuedSnackbar,
} from './InviteTeamMemberDialog.types';


export const getResolvedEmailsStatus = ({
  succeeded,
  failed,
}: EmailStatusDefault) => {

  if (failed.length > 0) {
    if (succeeded.length === 0) {
      return getResolvedEmailsStatusValues.allEmailsFailed;
    } else {
      return getResolvedEmailsStatusValues.succeededAndFailed;
    }
  }

  return getResolvedEmailsStatusValues.allEmailsSucceeded;
};

export const setEnqueuedSnackbar = ({
  enqueueSnackbar,
  emailsStatusList,
  resolvedEmailsStatus,
  alert,
}: IsetEnqueuedSnackbar) => {
  const enqueuedSnackBarVariants = {
    allEmailsFailed: () =>
      enqueueSnackbar({
        variant: 'alert',
        severity: 'error',
        message: alert.messages.allEmailsFailed,
      }),

    succeededAndFailed: () => {
      enqueueSnackbar({
        variant: 'alert',
        severity: 'success',
        message: alert.messages.someEmailsSent,
      });
      enqueueSnackbar({
        variant: 'alert',
        severity: 'error',
        messagelist: emailsStatusList.failed,
        alertTitle: alert.titles.succeededAndFailedError,
      });
    },

    allEmailsSucceeded: () =>
      enqueueSnackbar({
        variant: 'alert',
        severity: 'success',
        message: alert.messages.allEmailSent,
      }),
  };

  return enqueuedSnackBarVariants[resolvedEmailsStatus]();
};
