import React from 'react';
import { IconContainer } from './IconContainer';


export const ClockIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 24 24">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0004 21.5999C17.3023 21.5999 21.6004 17.3018 21.6004 11.9999C21.6004 6.69797 17.3023 2.3999 12.0004 2.3999C6.69846 2.3999 2.40039 6.69797 2.40039 11.9999C2.40039 17.3018 6.69846 21.5999 12.0004 21.5999ZM12.7504 5.9999C12.7504 5.58569 12.4146 5.2499 12.0004 5.2499C11.5862 5.2499 11.2504 5.58569 11.2504 5.9999V11.9999C11.2504 12.4141 11.5862 12.7499 12.0004 12.7499H16.8004C17.2146 12.7499 17.5504 12.4141 17.5504 11.9999C17.5504 11.5857 17.2146 11.2499 16.8004 11.2499H12.7504V5.9999Z"
      fill="currentColor"
    />
  </IconContainer>
);
