import React from 'react';
import { Box } from '@mui/system';
import { outlineLight } from 'shared/styles/muiTheme';


export const VendorImage = (props: VendorImageProps) => {
  const { src, alt } = props;

  return (
    <Box
      height={64}
      width={64}
      border={`solid 1px ${outlineLight}`}
      borderRadius="4px"
      overflow="hidden"
      p={.5}
    >
      <img
        src={src}
        alt={alt}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
    </Box>
  );
};

interface VendorImageProps {
    src: string;
    alt: string;
}