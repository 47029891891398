import { getFormDataFromObject } from 'helpers/form';
import {
  CompanyCrud,
  CompanyCrudApi,
} from './companyClient.types';


export const mapCompanyCrudData = (data: CompanyCrud): FormData => {
  const apiData: CompanyCrudApi = {
    id: data.id,
    name: data.name,
    logo: typeof data.logo === 'string' ? undefined : data.logo,
    nr_of_employees: data.nrOfEmployees,
    nr_of_locations: data.nrOfLocations,
  };

  const formData = getFormDataFromObject(apiData);

  return formData;
};
