import React, { FC } from 'react';
import { BarLoader } from 'react-spinners';
import { Grid, useTheme } from '@mui/material';


export const BodyLoading: FC<any> = ({ height = 'calc(100vh - 150px)' }) => {

  const theme = useTheme();

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height }}>
      <BarLoader color={theme.palette.primary.main}/>
    </Grid>
  );
};
