import React from 'react';
import { Box, Typography } from '@mui/material';
import { ChevronIcon } from 'shared/icons/ChevronArrIcon';
import { round } from 'lodash';
import { CategoryIcon } from 'shared/components/layout/CategoryIcon/CategoryIcon';


export const CategoryListParent = (props: CategoryListParentProps) => {
  const {
    icon,
    name,
    color,
    totalCo2,
    co2Percentage,
    onClick,
    selectedCategoryName,
    isChildSelected,
    isHaveChildren,
  } = props;

  const isSelected = React.useMemo(() =>
    selectedCategoryName === name || isChildSelected,
  [selectedCategoryName, name, isChildSelected]);

  const hasNoData = React.useMemo(() =>
    totalCo2 === undefined && co2Percentage === undefined
  , [co2Percentage, totalCo2]);

  return (
    <Box
      className="_d-flex-sm-gap"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        opacity: selectedCategoryName && selectedCategoryName !== name ? 0.5 : 1,
        alignItems: hasNoData ? 'center' : 'start',
        '&:hover': {
          opacity: 1,
        },
      }}
    >
      <CategoryIcon icon={icon} name={name} color={color} size={24}/>

      <div>
        <Box className="_d-flex-sm-gap">
          <Typography
            variant="body1"
            fontWeight={selectedCategoryName === name ? 600 : ''}
            lineHeight={1}
          >
            {name}
          </Typography>
          {isHaveChildren && <ChevronIcon direction={isSelected ? 'up' : 'down'}/>}
        </Box>
        {!hasNoData &&
            <Typography variant="body2" color="text.secondary">
              {round(totalCo2! / 1000, 2)}t ({co2Percentage}%)
            </Typography>
        }
      </div>

    </Box>
  );
};

interface CategoryListParentProps {
    icon?: string;
    name: string;
    color?: string;
    co2Percentage?: string;
    totalCo2?: number;
    onClick: () => void;
    selectedCategoryName?: string;
    isChildSelected?: boolean;
    isHaveChildren?: boolean;
}