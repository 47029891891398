import React from 'react';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { XMarkIcon } from 'shared/icons/XMarkIcon';
import { StatusIcon } from 'shared/components/Status/StatusIcon';


export const ProcessSnackbar = (props: ProcessSnackbarProps) => {
  const {
    type = 'info',
    message,
    onClose,
    actionName,
    actionOnClick,
    actionProps,
    status,
    progress,
    integrationName,
    isActive,
  } = props;

  return (
    <div className={`layout-snackbar is-${type}`}>
      {<StatusIcon type={type} integrationName={integrationName}/> || <div/>}
      <Box>
        <Typography>{message}</Typography>
        {status && <Typography color="text.secondary">{status}</Typography>}
      </Box>
      <Box className="_d-flex-dir-col-ali-end-sm-gap" sx={{ alignSelf: 'start' }}>
        {!isActive && <XMarkIcon onClick={onClose} sx={{ cursor: 'pointer' }}/>}
        {actionName && actionOnClick && (
          <Button
            onClick={actionOnClick}
            size="small"
            color="secondary"
            {...actionProps}
          >
            {actionName}
          </Button>
        )}
      </Box>

      {!!progress && (
        <Box className="_d-flex-ali-center-sm-gap" sx={{ gridColumn: '1 / -1' }}>
          <LinearProgress variant={progress === 100 ? 'indeterminate' : 'determinate'} value={progress} sx={{ flex: 1 }}/>
          {progress !== 100 && <Typography variant="body2" color="text.secondary">{progress}%</Typography>}
        </Box>
      )}
    </div>
  );
};

export interface ProcessSnackbarProps {
    message: string;
    onClose: () => void;
    type?: ProcessSnackbarType;
    actionName?: string;
    actionOnClick?: () => void;
    actionProps?: React.ComponentProps<typeof Button>
    status?: string;
    progress?: number;
    integrationName?: string;
    isActive?: boolean;
}

export type ProcessSnackbarType = 'info' | 'success' | 'error' | 'uploading' | 'importing';