import React from 'react';
import { Box, Typography } from '@mui/material';
import { LocalizationContext } from 'contexts';


export const IntroductionStep = ({ weNeedList }: {weNeedList?: string[]}) => {
  const { dictionary } = React.useContext(LocalizationContext);

  return (
    <Box className="hide-dialog-file-name" mt={3} sx={{ textWrap: 'balance' }}>
      <Typography variant="body1">
        {dictionary.expenses.uploadIntegration.intro.weWillNeed}
      </Typography>
      <ul>
        {(weNeedList || dictionary.expenses.uploadIntegration.intro.weNeedList).map((li, i) => (
          <li key={i}>{li}</li>
        ))}
      </ul>
    </Box>
  );
};