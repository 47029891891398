import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const UserGroupIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      d="M8.00078 7.20002C9.32626 7.20002 10.4008 6.12551 10.4008 4.80002C10.4008 3.47454 9.32626 2.40002 8.00078 2.40002C6.6753 2.40002 5.60078 3.47454 5.60078 4.80002C5.60078 6.12551 6.6753 7.20002 8.00078 7.20002Z"
      fill="currentColor"
    />
    <path
      d="M4.80078 6.40002C4.80078 7.28368 4.08444 8.00002 3.20078 8.00002C2.31713 8.00002 1.60078 7.28368 1.60078 6.40002C1.60078 5.51637 2.31713 4.80002 3.20078 4.80002C4.08444 4.80002 4.80078 5.51637 4.80078 6.40002Z"
      fill="currentColor"
    />
    <path
      d="M1.1933 12.2606C1.05763 12.1817 0.952952 12.0575 0.906756 11.9075C0.837866 11.6839 0.800781 11.4463 0.800781 11.2C0.800781 9.87454 1.8753 8.80002 3.20078 8.80002C3.61838 8.80002 4.01107 8.90668 4.3531 9.09424C3.51541 9.91911 2.95609 11.026 2.82861 12.2614C2.81022 12.4395 2.8179 12.6154 2.84875 12.7847C2.24927 12.7325 1.68767 12.548 1.1933 12.2606Z"
      fill="currentColor"
    />
    <path
      d="M13.1532 12.7847C13.7525 12.7324 14.314 12.5479 14.8083 12.2606C14.9439 12.1817 15.0486 12.0575 15.0948 11.9075C15.1637 11.6839 15.2008 11.4463 15.2008 11.2C15.2008 9.87454 14.1263 8.80002 12.8008 8.80002C12.3833 8.80002 11.9907 8.90664 11.6487 9.09412C12.4864 9.919 13.0458 11.026 13.1733 12.2614C13.1917 12.4395 13.184 12.6153 13.1532 12.7847Z"
      fill="currentColor"
    />
    <path
      d="M14.4008 6.40002C14.4008 7.28368 13.6844 8.00002 12.8008 8.00002C11.9171 8.00002 11.2008 7.28368 11.2008 6.40002C11.2008 5.51637 11.9171 4.80002 12.8008 4.80002C13.6844 4.80002 14.4008 5.51637 14.4008 6.40002Z"
      fill="currentColor"
    />
    <path
      d="M4.24385 12.9527C4.08456 12.8085 4.00016 12.5983 4.02221 12.3846C4.23004 10.3707 5.93205 8.80002 8.0009 8.80002C10.0697 8.80002 11.7718 10.3707 11.9796 12.3846C12.0016 12.5983 11.9172 12.8086 11.7579 12.9527C10.7644 13.8522 9.44663 14.4 8.0009 14.4C6.55516 14.4 5.23739 13.8522 4.24385 12.9527Z"
      fill="currentColor"
    />
  </IconContainer>
);
