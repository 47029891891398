import React, { FC, ReactNode, createContext, useState, useCallback } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import { ContextProps, PermissionKey, Permissions } from './types';


const defaultPermissions: Permissions = {
  user: {
    crud: { view: false, create: false, edit: false, delete: false },
  },
  authGroup: {
    crud: { view: false },
  },
  invitation: {
    crud: { view: false, create: false, edit: false, delete: false },
  },
  company: {
    crud: { view: false, create: false, edit: false, delete: false },
  },
  manualInput: {
    crud: { view: false, create: false, edit: false, delete: false },
  },
  transaction: {
    crud: { view: false, create: false, edit: false, delete: false },
    totalAmount: { view: false },
    co2: { view: false },
    classification: { view: false },
    export: false,
  },
  emissionFactor: {
    crud: { view: false, create: false, edit: false, delete: false },
  },
  scenarioBuilder: {
    crud: { view: false, create: false, edit: false, delete: false },
    yearlyPrediction: { view: false, edit: false },
  },
};

Object.freeze(defaultPermissions);

export const defaultContext: ContextProps = {
  permissions: cloneDeep(defaultPermissions),
  getPermission: () => false,
  setPermissions: () => {},
  resetPermissions: () => {},
};

export const PermissionContext = createContext(defaultContext);

export const PermissionContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {

  const [permissions, setStatePermissions] = useState<Permissions>(cloneDeep(defaultPermissions));

  const getPermission = useCallback((permissionKey: PermissionKey) => {
    return get(permissions, permissionKey) || false;
  }, [permissions]);

  const setPermissions = useCallback((permissionKeys: PermissionKey[], value: boolean) => {
    const updatedPermissions = {};
    permissionKeys.forEach(key => set(updatedPermissions, key, value));
    setStatePermissions(merge(cloneDeep(defaultPermissions), updatedPermissions));
  }, []);

  const resetPermissions = useCallback(() => {
    setStatePermissions(cloneDeep(defaultPermissions));
  }, []);

  return (
    <PermissionContext.Provider
      value={{
        permissions,
        getPermission,
        setPermissions,
        resetPermissions,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};
