import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { format } from 'date-fns';

import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { surveysClient } from 'clients/surveys/surveysClient';
import { chartsClient } from 'clients/charts/chartsClient';
import { useCharts } from 'shared/hooks/useCharts';
import { useEmissionFactorCategories } from 'shared/hooks/useEmissionFactorCategories';
import { EmployeeCommutingSurveyYearsEnum } from 'views/LoggedIn/Employees/Employees.types';
import { getMappedFilters } from 'views/LoggedIn/Employees/EmployeesHelpers';


export const useEmployeeCommutingData = (params: any, year: EmployeeCommutingSurveyYearsEnum) => {
  const { dictionary } = useContext(LocalizationContext);
  const { genericInfo, genericError } = useContext(LayoutContext);
  const [isSubmitSurveyLoading, setIsSubmitSurveyLoading] = useState(false);
  const queryClient = useQueryClient();
  const { emissionFactorCategories } = useEmissionFactorCategories({
    limit: 2000,
  });

  const emissionFactorCategoryValue = useMemo(
    () => emissionFactorCategories.find(({ slug }) => slug === 'employees'),
    [emissionFactorCategories],
  );

  const mappedFilters = useMemo(() => getMappedFilters(year, emissionFactorCategoryValue?.id ), [emissionFactorCategoryValue?.id, year]);
  const {
    status: statusPieChartDataCategories,
    data: pieChartDataCategories,
    isLoadingCharts: isPieChartDataLoading,
    isFetching: isFecthingPieCharts,
  } = useCharts(mappedFilters);

  useEffect(() => {
    if (!isFecthingPieCharts) {
      setIsSubmitSurveyLoading(false);
    }
  }, [isFecthingPieCharts, setIsSubmitSurveyLoading]);

  useEffect(() => {
    for (const year of Object.values(EmployeeCommutingSurveyYearsEnum)) {
      const mappedParams = {
        ...params,
        year: format(new Date(year), 'yyyy-MM-dd'),
      };
      const mappedFilters = getMappedFilters(year, emissionFactorCategoryValue?.id );
      queryClient.prefetchQuery(
        [cacheKeys.charts.getChart, mappedFilters],
        () => chartsClient.getChart(mappedFilters),
      );
      queryClient.prefetchQuery(
        [cacheKeys.employeeCommuting.getSurvey, mappedParams],
        () => surveysClient.getSurvey(mappedParams),
      );
    }
  }, [emissionFactorCategoryValue?.id, params, queryClient]);

  const surveyQueryKey = useMemo(
    () => [cacheKeys.employeeCommuting.getSurvey, params],
    [params],
  );
  const { data: { data: survey } = {}, status: surveyQueryStatus, isLoading: isLoadingSurvey } = useQuery(
    surveyQueryKey,
    () => surveysClient.getSurvey(params),
  );


  const {
    mutateAsync: mutatePostSurveyForm,
    isLoading: isLoadingPostSurveyForm,
  } = useMutation(surveysClient.postSurveyAnswer, {
    onMutate: () => setIsSubmitSurveyLoading(true),
    mutationKey: [
      cacheKeys.employeeCommuting.createSurveyAnswer, mappedFilters,
    ],
    onSuccess: () => {
      genericInfo(dictionary.employees.surveyAnswerSubmitted);
      queryClient.invalidateQueries(surveyQueryKey);
      queryClient.invalidateQueries([
        cacheKeys.charts.getChart, mappedFilters,
      ]);
    },
    onError: () => {
      setIsSubmitSurveyLoading(false);
      genericError();
    },
  });

  const {
    mutateAsync: mutateUpdateSurveyForm,
    isLoading: isLoadingUpdateSurveyForm,
  } = useMutation(surveysClient.updateSurveyAnswer, {
    onMutate: () => setIsSubmitSurveyLoading(true),
    mutationKey: [
      cacheKeys.employeeCommuting.updateSurveyAnswer, mappedFilters,
    ],
    onSuccess: () => {
      genericInfo(dictionary.employees.surveyAnswerSubmitted);
      queryClient.invalidateQueries(surveyQueryKey);
      queryClient.invalidateQueries([
        cacheKeys.charts.getChart, mappedFilters,
      ]);
    },
    onError: () => {
      setIsSubmitSurveyLoading(false);
      genericError();
    },
  });

  const employeeEmissionsMappedData = useMemo<any[]>(() => {
    return pieChartDataCategories?.values.map((data: any) => ({
      name: data.dimensions.group,
      value: data.metrics.sum_co2_kg,
      co2Percentage: pieChartDataCategories?.meta?.total_co2_kg ? (data.metrics.sum_co2_kg / pieChartDataCategories?.meta?.total_co2_kg) * 100 : 0,
      transactionCount: data.metrics.transaction_count,
    }));
  }, [pieChartDataCategories]);

  const allEmissions = useMemo(() => pieChartDataCategories?.meta?.total_co2_kg || 0, [pieChartDataCategories]);

  return {
    pieChartDataCategories,
    isPieChartDataLoading,
    statusPieChartDataCategories,
    surveyQueryStatus,
    survey,
    surveyQueryKey,
    mutatePostSurveyForm,
    mutateUpdateSurveyForm,
    employeeEmissionsMappedData,
    isLoadingUpdateSurveyForm,
    isLoadingPostSurveyForm,
    isLoadingSurvey,
    allEmissions,
    isFecthingEmployeeEmissionsData: isSubmitSurveyLoading || isPieChartDataLoading,
  };
};
