import React, { FC, Fragment, useContext } from 'react';
import { Snackbar, Alert } from '@mui/material';

import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';


export const SharedLayoutComponent: FC = () => {

  const {
    snackbar,
    closeSnackbar,
  } = useContext(LayoutContext);

  return (
    <Fragment>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={snackbar.autoHideDuration}
        onClose={closeSnackbar}
        sx={{ '.MuiPaper-root': { alignItems: 'center' } }}
      >
        <Alert onClose={closeSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
