import React, { ReactNode } from 'react';
import { Box, IconButton, Theme, Typography, useTheme } from '@mui/material';
import { ExclamationCircleIcon } from 'shared/icons/ExclamationCircleIcon';
import { ChevronIcon } from 'shared/icons/ChevronArrIcon';
import { InfoCircleIcon } from 'shared/icons/InfoCircleIcon';


export const NotificationCardTypes: {[key: string]: NotificationCardType} = {
  ERROR: 'error',
  INFO: 'info',
};

export const ErrorCard = (props: Omit<NotificationCardProps, 'cardType'>) => (
  <NotificationCard {...props} cardType={NotificationCardTypes.ERROR}/>
);
export const InfoCard = (props: Omit<NotificationCardProps, 'cardType'>) => (
  <NotificationCard {...props} cardType={NotificationCardTypes.INFO}/>
);

export const NotificationCard = (props: NotificationCardProps) => {
  const {
    text,
    expandedText,
    cardType,
    containerProps,
  } = props;

  const theme = useTheme();

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const { bg, color, icon } = getIconAndColors(cardType, theme);

  return (
    <Box
      {...containerProps}
      className={['_d-grid-xs-gap', containerProps?.className].join(' ')}
      sx={{
        background: bg,
        color: color,
        borderRadius: 1,
        p: 1,
        ...containerProps?.sx,
      }}
    >
      <Box className="_d-flex-ali-center-xs-gap">
        {icon}
        <Typography variant="body1" flexGrow={1}>{text}</Typography>
        {expandedText && (
          <IconButton onClick={() => setIsExpanded(!isExpanded)} sx={{ color: 'inherit', p: 0.5 }}>
            <ChevronIcon direction={isExpanded ? 'up' : 'down'}/>
          </IconButton>
        )}
      </Box>

      {isExpanded && (
        <Typography variant="body1" ml="20px">{expandedText}</Typography>
      )}
    </Box>
  );
};

const getIconAndColors = (cardType: NotificationCardType, theme: Theme) => {
  switch (cardType) {
  case NotificationCardTypes.ERROR:
    return {
      bg: theme.palette.error.light,
      color: theme.palette.error.main,
      icon: <ExclamationCircleIcon/>,
    };
  case NotificationCardTypes.INFO:
    return {
      bg: theme.palette.primary.light,
      color: theme.palette.text.primary,
      icon: <InfoCircleIcon sx={{ color: theme.palette.primary.main }}/>,
    };
  default:
    return {
      bg: theme.palette.secondary.light,
      color: theme.palette.text.primary,
      icon: <InfoCircleIcon/>,
    };
  }
};

export interface NotificationCardProps {
    text: ReactNode;
    expandedText?: ReactNode;
    containerProps?: React.ComponentProps<typeof Box>;
    cardType: NotificationCardType;
}

export type NotificationCardType = 'error' | 'info';