import { FunctionComponent, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DialogProps } from '@mui/material/Dialog';
import { Box, Stack, Typography } from '@mui/material';

import { LocalizationContext } from 'contexts';
import ExclamationTriangleIcon from 'shared/components/layout/Icons/ExclamationTriangle';


interface IScenarioBuilderDialogProps extends DialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const ScenarioBuilderDialog: FunctionComponent<IScenarioBuilderDialogProps> = ({
  open,
  onConfirm,
  onClose,
}) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack
        minWidth="md"
        direction="row"
        spacing={2}
        padding="24px 24px 12px 24px"
      >
        <Box
          bgcolor="#FEE2E2"
          height={40}
          minWidth={40}
          color="#F87171"
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ExclamationTriangleIcon
            stroke="#F87171"
            strokeWidth={2}
            sx={{ color: '#FEE2E2', height: '23px', width: '23px' }}
          />
        </Box>
        <Stack maxWidth="408px">
          <Typography
            fontSize={16}
            fontWeight={500}
            mb="5px"
            lineHeight="28px"
            color="#111827"
          >
            {dictionary.scenarioBuilder.autofillTitle}
          </Typography>
          <Typography color="#6B7280" fontSize={14}>
            {dictionary.scenarioBuilder.autofillConfimation}
          </Typography>
          <Typography mt={1} fontStyle="italic" color="#F87171">
            {dictionary.confirmation.defaultContent}
          </Typography>
          <Stack mt="28px" direction="row" justifyContent="flex-end">
            <Button
              onClick={onClose}
              color="secondary"
            >
              {dictionary.scenarioBuilder.autofillBtnCancel}
            </Button>
            <Button
              onClick={onConfirm}
              sx={{ ml: 2 }}
              autoFocus
            >
              {dictionary.scenarioBuilder.autofillBtnConfirm}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};
