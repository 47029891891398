import { CamelToSnakeCase } from 'types';
import { DateAggregation, TransactionStatus } from 'clients/transactions/transactionClient.types';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';


export enum ChartGroupBy {
    scope = 'scope',
    category = 'category',
    parentCategory = 'parent_category',
    status = 'status',
    vendor = 'vendor_name',
    ledger = 'ledger',
}

export enum ChartType {
    pie = 'pie',
    bar = 'bar',
    line = 'line',
    area = 'area',
    dashedLine = 'dashed-line',
    dottedLine = 'dotted-line',
    treeMap = 'tree-map',
}


export type GetChartParams = {
    metricLabel?: string;
    type?: ChartType;
    groupBy?: ChartGroupBy;
    limit?: number;
    dateAggregation?: DateAggregation;
    dateLte?: Date;
    dateGte?: Date;
    status?: TransactionStatus;
    scope?: Scope[] | Scope;
    tags?: string[];
    subCategoryId?: number;
    categoryId?: number;
}
export type Dimension = {
    key: string;
    label: string;
    type?: string;
    axis?: 'x' | 'y';
    aggregation?: {
      chart: ChartType;
      key: string;
    };
  }

export type Metric = {
    chart: ChartType;
    key: string;
    label: string;
    stackId: number;
  }

export type Meta = {
    count: number;
    highConfidenceCount: number | null;
    countPreviousPeriod: number | null;
    percentileDiffPeriodsCount: number;
    totalCo2Kg: number;
    totalCo2KgPreviousPeriod: number;
    percentileDiffPeriodsTotalCo2Kg: number;
    avgPerLocation: number | null;
    avgPerEmployee: number | null;
    avgPerTransaction: number | null;
    deltaDays: number;
  }

export type ChartData = {
    type: ChartType;
    dimensions: Dimension[];
    metrics: Metric[];
    values: any[];
    meta: Meta;
  }

export type ChartDataApi = CamelToSnakeCase<ChartData>