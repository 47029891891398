import { ProcessSnackbarType } from 'shared/components/Snackbar/ProcessSnackbar';


export interface ContextProps {
  addAsyncProcess: (process: Omit<AsyncProcess, 'isActive' | 'snackbarType' | 'timeChecked'>) => void;
  removeAsyncProcess: (processType: AsyncProcessType) => void;
  setIsOpenInDialog: (processType: AsyncProcessType, isOpen: boolean) => void;
  asyncProcesses: AsyncProcess[];
};

export type AsyncProcess = AsyncProcessCsvUpload | DefaultAsyncProcess | AsyncProcessImportingTransactions;

export interface DefaultAsyncProcess {
  processType: AsyncProcessType;
  name: string;
  isActive: boolean;
  snackbarType: ProcessSnackbarType;
  snackbarAction?: {
    actionName: string;
    actionOnClick: () => void;
  }
  progress?: number;
  isOpenInDialog?: boolean;
  data?: any;
  timesChecked: number
}

export interface AsyncProcessCsvUpload extends DefaultAsyncProcess {
  processType: AsyncProcessType.CsvUpload;
  data: {
    integrationId: number;
    fileText: string;
    headerIndexes: {[header: string]: number};
    errors?: {
      [column: string]: {
        error: string,
        rows: number[]
      }
    }
  }
}

export interface AsyncProcessImportingTransactions extends DefaultAsyncProcess {
  processType: AsyncProcessType.ImportingTransactions;
  data: {
    integrationId: number;
    integrationName: string;
    isInactive: boolean;
  }
}

export enum AsyncProcessType {
  CsvUpload = 'CsvUpload',
  ImportingTransactions = 'ImportingTransactions',
}
