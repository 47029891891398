// ** MUI Imports
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import { backgroundSemiLight, primaryLight, textSecondary } from 'shared/styles/muiTheme';


const Table = (theme: Theme) => {
  return {
    MuiTableContainer: {
      styleOverrides: {
      },
    },
    MuiTableHead: {
      styleOverrides: {
      },
    },
    MuiTableBody: {
      styleOverrides: {
      },
    },
    MuiTableRow: {
      styleOverrides: {
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: backgroundSemiLight,
          color: textSecondary,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          fontSize: 12,
          fontWeight: 400,
          textTransform: 'uppercase',
          '&:first-of-type': {
            borderRadius: '4px 0 0 4px',
          },
          '&:last-of-type': {
            borderRadius: '0px 4px 4px 0px',
          },
        },
        body: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
        root: {
          '&:first-of-type': {
            paddingLeft: theme.spacing(4),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(4),
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            '& svg': {
              color: '#DADADA',
            },
          },
          border: 'none',
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 4,
            backgroundColor: '#F0F3F6',
            color: '#586478',
          },
        },
        columnHeaders: {
          backgroundColor: '#F2F2F2',
          borderRadius: '0',
          borderBottom: 'none',
          color: '#757575',
          textTransform: 'uppercase',
          fontSize: 11,
        },
        row: {
          '&.Mui-selected': {
            backgroundColor: primaryLight,
          },
          '&:hover': {
            backgroundColor: '#F0F3F6',
          },
          color: '#26262B',
        },
        panel: {
          boxShadow: '0 0 0 1px rgba(136,152,170,.1),0 15px 35px 0 rgba(49,49,93,.1),0 5px 15px 0 rgba(0,0,0,.08)',
          marginTop: '-36px',
        },
        cell: {
          borderBottomColor: grey['100'],
          '&:focus-within': {
            outline: 'none',
          },
        },
        menu: {
          boxShadow: '0 0 0 1px rgba(136,152,170,.1),0 15px 35px 0 rgba(49,49,93,.1),0 5px 15px 0 rgba(0,0,0,.08)',
        },
      },
    },
  };
};

export default Table;
