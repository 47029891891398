import { useQuery } from 'react-query';
import { cacheKeys } from 'modules/Surveys/config';
import { surveysClient } from 'modules/Surveys/client/surveysClient';


export type Params = any;

export const useSurvey = (params: Params, enabled: boolean = true) => {
  const { data: { data: survey } = {}, status, error, isLoading } = useQuery(
    [cacheKeys.getSurvey, params],
    () => surveysClient.getSurvey(params),
    { enabled },
  );

  return {
    isLoading,
    status,
    error,
    survey,
  };
};
