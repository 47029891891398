import { FC } from 'react';
import { Box, Popover, PopoverProps } from '@mui/material';


export const DropdownMenu: FC<DropdownMenuProps> = (props) => {
  const {
    anchorEl,
    anchorOrigin = { horizontal: 'left', vertical: 'bottom' },
    children,
    handleClose,
    id,
    topOffset = '8px',
    transformOrigin = { horizontal: 'left', vertical: 'top' },
    sx = {},
  } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      disableScrollLock
      id={!!anchorEl ? id : undefined}
      onClose={handleClose}
      onClick={handleClose}
      open={!!anchorEl}
      sx={{ marginTop: topOffset }}
      transformOrigin={transformOrigin}
    >
      <Box className="dropdown-menu" sx={sx}>
        {children}
      </Box>
    </Popover>
  );
};

  interface DropdownMenuProps extends Omit<PopoverProps, 'open'> {
    handleClose: () => void;
    id: string;
    /**  The offset in pixels to shift the menu from its top origin */
    topOffset?: string;
  }