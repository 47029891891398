import React from 'react';
import { Box, Button, Link, Skeleton, Typography } from '@mui/material';
import { IntegrationOption, IntegrationOptions } from 'modules/Expenses/helpers/integrationOptions';
import { outlineLight } from 'shared/styles/muiTheme';
import { Expenses } from 'modules/Expenses/expenses';
import { LocalizationContext } from 'contexts';


export const SelectIntegrationStep = (props: SelectIntegrationStepProps) => {
  const {
    switchToUploadCsv,
    selectIntegrationType,
    chiftIntegrationOptions,
    isLoading,
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);

  const [options, comingSoon] = React.useMemo(() => {
    const options: IntegrationOption[] = [];
    const comingSoon: IntegrationOption[] = [];

    IntegrationOptions.forEach((opt) => {
      if(chiftIntegrationOptions.activeIntegrations.some((cOpt) => cOpt.name === opt.key)) {
        options.push(opt);
      }
      else {
        comingSoon.push(opt);
      }
    });

    return [options, comingSoon];
  }, [chiftIntegrationOptions]);

  return (
    <div className="hide-dialog-file-name">
      <Typography variant="h3" mb={2}>
        {dictionary.expenses.uploadIntegration.selectAcc.available}
      </Typography>

      {isLoading ? (
        <Box className="_col-container:2">
          <Skeleton variant="rounded" sx={{ height: 56 }}/>
          <Skeleton variant="rounded" sx={{ height: 56 }}/>
          <Skeleton variant="rounded" sx={{ height: 56 }}/>
          <Skeleton variant="rounded" sx={{ height: 56 }}/>
        </Box>
      ) : (
        <React.Fragment>
          <Box className="_col-container:2" mb={2}>
            {options.map((opt) => (
              <IntegrationButton
                key={opt.key}
                title={opt.title}
                img={opt.img}
                onClick={() => selectIntegrationType(opt.key)}
              />
            ))}
          </Box>
          <Typography variant="body1" color="text.secondary" mb={5}>
            {dictionary.expenses.uploadIntegration.selectAcc.notUsing}{' '}
            <Link
              onClick={switchToUploadCsv}
              color="text.primary"
              sx={{ fontWeight: 600 }}
            >
              {dictionary.expenses.uploadIntegration.selectAcc.upload}
            </Link>
          </Typography>

          <Typography variant="h3" mb={2}>
            {dictionary.comingSoon}
          </Typography>
          <Box className="_col-container:2">
            {comingSoon.map((opt) => (
              <IntegrationButton
                key={opt.key}
                title={opt.title}
                img={opt.img}
              />
            ))}
          </Box>
        </React.Fragment>
      )}
    </div>
  );
};

const IntegrationButton = ({ title, img, onClick }: {
  title: string;
  img: string;
  onClick?: () => void;
}) => {

  const content = (
    <React.Fragment>
      <Box
        component="img"
        src={img}
        alt={title}
        sx={{
          height: 40,
          aspectRatio: '1/1',
          borderRadius: '50%',
          objectFit: 'contain',
          border: `solid 1px ${outlineLight}`,
        }}
      />
      {title}
    </React.Fragment>
  );

  return onClick ? (
    <Button
      onClick={onClick}
      variant="text"
      color="secondary"
      sx={{ justifyContent: 'start', gap: 3, padding: '8px 16px' }}
    >
      {content}
    </Button>
  ) : (
    <Box
      className="_d-flex-ali-center-lg-gap"
      p="8px 16px"
    >
      {content}
    </Box>
  );
};

interface SelectIntegrationStepProps {
  switchToUploadCsv: () => void;
  selectIntegrationType: (key: string) => void;
  chiftIntegrationOptions: Expenses.Integrations.Chift.Options;
  isLoading: boolean;
}