import React from 'react';
import { IconContainer } from './IconContainer';


export const CheckCircleIcon = ({ ...props }) => (
  <IconContainer viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 21.5984C17.3018 21.5984 21.5999 17.3004 21.5999 11.9984C21.5999 6.6965 17.3018 2.39844 11.9999 2.39844C6.69797 2.39844 2.3999 6.6965 2.3999 11.9984C2.3999 17.3004 6.69797 21.5984 11.9999 21.5984ZM16.6278 9.82779C16.9201 9.4258 16.8312 8.86293 16.4293 8.57058C16.0273 8.27822 15.4644 8.3671 15.172 8.76908L10.9915 14.5173L8.7363 12.262C8.38483 11.9106 7.81498 11.9106 7.46351 12.262C7.11203 12.6135 7.11203 13.1834 7.46351 13.5348L10.4635 16.5348C10.6496 16.721 10.9079 16.8163 11.1703 16.7957C11.4327 16.7751 11.673 16.6407 11.8278 16.4278L16.6278 9.82779Z"
      fill="currentColor"
    />
  </IconContainer>
);
