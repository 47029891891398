import { FC } from 'react';
import { Box } from '@mui/material';


export const ReadOnlyValue: FC<{value: string | number}> = ({ value }) => {
  return (
    <Box
      sx={{
        border: '1px solid #E8E8E8',
        borderRadius: '4px',
        background: '#F2F2F2',
        padding: '8px 16px',
      }}
    >
      {value}
    </Box>
  );
};
