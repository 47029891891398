import { AxiosResponse } from 'axios';

import { camelizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';

import {
  LuneBundles,
  LuneBundlesApi,
  OffsetApiKeys,
  OffsetApiKeysApi,
} from './offsettingClientTypes';


const apiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getLuneBundles = (): Promise<AxiosResponse<LuneBundles>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/offset/lune/bundles`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<LuneBundlesApi>) => {
    return {
      ...data,
      data: data.data.map((e) => camelizeObject(e)),
    };
  });
};

const getOffsetLuneApiKeys = (): Promise<AxiosResponse<OffsetApiKeys>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/integration/offset/?&offset_seller=lune`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<OffsetApiKeysApi>) => {
    return {
      ...data,
      data: camelizeObject(data.data),
    };
  });
};

const getCertificate = ({ transactionId } : {transactionId: number}): Promise<void | File> => {
  return request({
    options: {
      url: `${apiBaseUrl}/offset/lune/certificate/?&transaction_id=${transactionId}`,
      method: 'GET',
      responseType: 'blob',
    },
  }).then((blob) => {
    const href = window.URL.createObjectURL(blob.data);
    const link = document.createElement('a');
    link.href = href;
    link.download = 'certificate';
    link.setAttribute('download', 'certificate');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

export const offsettingClient = {
  getLuneBundles,
  getOffsetLuneApiKeys,
  getCertificate,
};
