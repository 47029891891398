import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp, CalendarMonth, Clear, EmojiObjects, Language, Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { Emissions } from 'modules/Emissions/emissions';
import { useEmissionFactors } from 'modules/Emissions/hooks/useEmissionFactors';
import { TransparentTextField } from 'modules/Emissions/components/TransactionClassification/TransparentTextField';
import { useClassifyTransactionsCrud } from 'modules/Transactions/hooks/useClassifyTransactionsCrud';
import { isHighConfidenceTransaction } from 'modules/Transactions/helpers/classification';
import { VendorInsightsList } from 'modules/Transactions/components/VendorInsightsList';
import { LayoutContext, LocalizationContext } from 'contexts';
import { GetTransactionsParams, Transaction, EmissionClassificationConfidenceStatus } from 'clients/transactions/transactionClient.types';
import { ButtonDropdown } from 'shared/components/interactive/ButtonDropdown/ButtonDropdown';

import { TransactionClassificationCategoryList } from './TransactionClassificationCategoryList';


const RegionPriority = ['NL', 'DE', 'FR', 'GB', 'GLOBAL', 'US'];

export type Options = {
  isFullSelection: boolean;
  transactionCount: number;
  transactions: Transaction[];
  filters: GetTransactionsParams;
  onClassifyStart?: () => void;
  onClassifyCompleted: () => void;
};

interface Props {
  open: boolean;
  onClose: () => void;
  options: Options;
};

export const TransactionClassificationDialog: React.FC<Props> = ({ open, onClose, options }) => {

  const { dictionary } = React.useContext(LocalizationContext);
  const { navWidth } = React.useContext(LayoutContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [mode, setMode] = React.useState<'manual' | 'certainty'>('manual');
  const [focused, setFocused] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [categoryPath, setCategoryPath] = React.useState<number[]>([]);
  const [classificationCategory, setClassificationCategory] = React.useState<Emissions.Categories.ElasticEmissionFactorCategory>();
  const [region, setRegion] = React.useState<string>();
  const [year, setYear] = React.useState<string>();
  const [confidence, setConfidence] = React.useState<EmissionClassificationConfidenceStatus>(EmissionClassificationConfidenceStatus.NEEDS_ATTENTION);

  const classifyTransactions = useClassifyTransactionsCrud();

  const { status, emissionFactors } = useEmissionFactors({
    classificationCategory: classificationCategory?.id,
  }, {
    enabled: !!classificationCategory,
  });

  const getDefaultRegion = (codes: string[], priority: string[]): number => {
    const index = priority
      .map(p => codes.indexOf(p))
      .find(index => index !== -1);
    return (!!index && index !== -1) ? index : 0;
  };

  const regions = React.useMemo(() => {
    return emissionFactors.reduce((prev, curr) => {
      if (curr.region && !prev.includes(curr.region)) {
        return [...prev, curr.region];
      }
      return prev;
    }, [] as string[]);
  }, [emissionFactors]);

  const years = React.useMemo(() => {
    return emissionFactors
      .filter(c => c.region === region)
      .reduce((prev, curr) => {
        if (curr.region && !prev.includes(curr.year)) {
          return [...prev, curr.year];
        }
        return prev;
      }, [] as string[])
      .sort((a, b) => parseInt(b) - parseInt(a));
  }, [emissionFactors, region]);

  const emissionFactorSelected = React.useMemo(() => {
    return emissionFactors.find(c => c.region === region && c.year === year);
  }, [emissionFactors, region, year]);

  React.useEffect(() => {
    setRegion(regions[getDefaultRegion(regions, RegionPriority)]);
  }, [regions]);

  React.useEffect(() => {
    setYear(years[0]);
  }, [years]);

  React.useEffect(() => {
    if (options.transactions.every(t => isHighConfidenceTransaction(t))) {
      setConfidence(EmissionClassificationConfidenceStatus.NEEDS_ATTENTION);
    } else {
      setConfidence(EmissionClassificationConfidenceStatus.CONFIDENT);
    }
  }, [options.transactions]);

  const onClickAway = React.useCallback(() => {
    setFocused(false);
    onClose();
  }, [onClose]);

  const onClear = React.useCallback(() => {
    setMode('manual');
    setValue('');
    setSearch('');
    if (classificationCategory && classificationCategory?.parent?.parent?.id) {
      setCategoryPath([classificationCategory?.parent?.parent.id, classificationCategory?.parent.id]);
    } else {
      setCategoryPath([]);
    }
    setClassificationCategory(undefined);
    setConfidence(EmissionClassificationConfidenceStatus.NEEDS_ATTENTION);
  }, [classificationCategory]);

  const onValueChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(e => {
    setValue(e.target.value);
  }, []);

  const selectCategory = React.useCallback((category: Emissions.Categories.ElasticEmissionFactorCategory) => {
    setCategoryPath([category.id]);
  }, []);

  const selectEmissionFactor = React.useCallback((category: Emissions.Categories.ElasticEmissionFactorCategory) => {
    if (category.parent?.parent?.id && category.parent?.id) {
      setCategoryPath([category.parent?.parent?.id, category.parent.id]);
    }
    setClassificationCategory(category);
    setValue(category.title);
    setSearch('');
    setFocused(false);
  }, []);

  React.useEffect(() => {
    if (!classificationCategory && value !== '') {
      setSearch(value);
      setCategoryPath([-1]);
    }
    if (classificationCategory && value === '') {
      onClear();
    }
    if (search !== '' && value === '') {
      onClear();
    }
    if (value !== '' && classificationCategory && classificationCategory.title !== value) {
      setClassificationCategory(undefined);
      setSearch(value);
      setCategoryPath([-1]);
    }
  }, [search, value, classificationCategory, onClear]);

  const onSave = React.useCallback(async () => {
    setIsSubmitting(true);
    if (mode === 'manual' && emissionFactorSelected) {
      options.onClassifyStart && options.onClassifyStart();
      if (options.isFullSelection) {
        await classifyTransactions.updateClassificationFull({
          ...options.filters,
          emissionFactorId: emissionFactorSelected.id,
        });
      } else {
        const transactions = options.transactions.map((transaction) => ({
          transactionId: transaction.id,
          emissionFactorId: emissionFactorSelected.id,
        }));
        await classifyTransactions.updateClassificationPartial(transactions);
      }
    }
    if (mode === 'certainty') {
      if (options.isFullSelection) {
        await classifyTransactions.updateConfidenceFull({
          ...options.filters,
          source: confidence === EmissionClassificationConfidenceStatus.CONFIDENT ? 'manual' : 'auto',
        });
      } else {
        await classifyTransactions.updateConfidencePartial({
          transactionIds: options.transactions.map(t => t.id),
          source: confidence === EmissionClassificationConfidenceStatus.CONFIDENT ? 'manual' : 'auto',
        });
      }
    }

    onClose();
    onClear();
    setIsSubmitting(false);
    options.onClassifyCompleted();
  }, [mode, emissionFactorSelected, confidence, onClose, onClear, classifyTransactions, options]);

  if (!open) {
    return null;
  }

  return (
    <Box position="fixed" bottom={32} left={navWidth} right={0} zIndex={1000}>
      <ClickAwayListener onClickAway={onClickAway}>
        <Box width={{ md: '95%', xl: '80%' }} m="auto">
          <Box
            sx={{
              height: '30vh',
              backgroundColor: '#F9FAFE',
              borderRadius: 6,
              margin: 'auto',
              marginBottom: 0.5,
              boxShadow: '0 15px 35px 0 rgba(49,49,93,.1),0 5px 15px 0 rgba(0,0,0,.08)',
              display: focused ? 'block' : 'none',
            }}
          >
            <Grid container height="100%">
              <Grid item xs={12} md={3} height="100%">
                <Box px={2} overflow="auto" height="100%">
                  <TransactionClassificationCategoryList
                    subheader={
                      <ListSubheader component="div" color="inherit" sx={{ background: '#F9FAFE', textTransform: 'uppercase', color: 'grey.600' }}>
                        {dictionary.transaction.classify.category}
                      </ListSubheader>
                    }
                    filters={{
                      ordering: 'title',
                      isParent: true,
                      limit: 1000,
                    }}
                    category={categoryPath[0]}
                    onSelect={selectCategory}
                    items={!!search ? [{ title: 'Search results', onClick: () => setCategoryPath([-1]), selected: categoryPath[0] === -1 }] : []}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} height="100%">
                {categoryPath[0] === -1 ? (
                  <Stack direction="row" height="100%" width="100%">
                    <Divider orientation="vertical" variant="fullWidth"/>
                    <Box flex={1} px={2} overflow="auto" width="100%">
                      <TransactionClassificationCategoryList
                        showParentCategory
                        autoSelect={false}
                        subheader={
                          <ListSubheader component="div" color="inherit" sx={{ background: '#F9FAFE', textTransform: 'uppercase', color: 'grey.600' }}>
                            {dictionary.transaction.classify.emissionFactors}
                          </ListSubheader>
                        }
                        filters={{
                          search,
                          isParent: false,
                          isClassificationCategory: true,
                          limit: 1000,
                        }}
                        category={classificationCategory?.id}
                        onSelect={selectEmissionFactor}
                      />
                    </Box>
                  </Stack>
                ) : (
                  <Stack direction="row" height="100%" width="100%">
                    {categoryPath[0] && (
                      <Box flex={1} flexShrink={0} width="50%">
                        <Stack direction="row" height="100%" width="100%">
                          <Divider orientation="vertical" variant="fullWidth"/>
                          <Box px={2} overflow="auto" width="100%" height="100%">
                            <TransactionClassificationCategoryList
                              subheader={
                                <ListSubheader component="div" color="inherit" sx={{ background: '#F9FAFE', textTransform: 'uppercase', color: 'grey.600' }}>
                                  {dictionary.transaction.classify.subcategory}
                                </ListSubheader>
                              }
                              filters={{
                                ordering: 'title',
                                parent: categoryPath[0],
                                isParent: false,
                                limit: 1000,
                              }}
                              category={categoryPath[1]}
                              onSelect={c => c.isClassificationCategory ? selectEmissionFactor(c) : setCategoryPath([categoryPath[0], c.id])}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    )}
                    {categoryPath[1] && (
                      <Box flex={1} flexShrink={0} width="50%">
                        <Stack direction="row" height="100%" width="100%">
                          <Divider orientation="vertical" variant="fullWidth"/>
                          <Box px={2} overflow="auto" width="100%" height="100%">
                            <TransactionClassificationCategoryList
                              autoSelect={false}
                              subheader={
                                <ListSubheader component="div" color="inherit" sx={{ background: '#F9FAFE', textTransform: 'uppercase', color: 'grey.600' }}>
                                  {dictionary.transaction.classify.emissionFactors}
                                </ListSubheader>
                              }
                              filters={{
                                ordering: 'title',
                                parent: categoryPath[1],
                                isParent: false,
                                isClassificationCategory: true,
                                limit: 1000,
                              }}
                              category={classificationCategory?.id}
                              onSelect={selectEmissionFactor}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12} md={3} height="100%">
                {focused && (
                  <Stack direction="row" height="100%" width="100%">
                    <Divider orientation="vertical" variant="fullWidth"/>
                    <Box px={2} overflow="auto" height="100%">
                      <VendorInsightsList
                        subheader={
                          <ListSubheader component="div" color="inherit" sx={{ background: '#F9FAFE', textTransform: 'uppercase', color: 'grey.600' }}>
                            <EmojiObjects color="inherit" sx={{ verticalAlign: 'middle', ml: -0.4, mr: 0.4 }}/>
                            {dictionary.transaction.classify.insights}
                          </ListSubheader>
                        }
                        filters={{
                          ...options.filters,
                          transactionIds: options.isFullSelection ? undefined : options.transactions.slice(0, 19).map(it => it.id),
                        }}
                      />
                    </Box>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height={65}
            sx={{
              px: 2,
              py: 1,
              backgroundColor: focused ? 'common.white' : 'grey.900',
              color: focused ? 'text.default' : 'common.white',
              borderRadius: 6,
              margin: 'auto',
              boxShadow: '0 15px 35px 0 rgba(49,49,93,.1),0 5px 15px 0 rgba(0,0,0,.08)',
              transition: '300ms background-color ease-in',
            }}
          >
            <Stack direction="row" spacing={3} alignItems="center" width="100%">
              <Box textAlign="left">
                <ButtonDropdown
                  label={dictionary.transaction.classify.mode(mode)}
                  buttonProps={{
                    endIcon: open => open ? <ArrowDropUp color="inherit"/> : <ArrowDropDown color="inherit"/>,
                    size: 'small',
                    variant: 'text',
                    color: 'inherit',
                    sx: {
                      flexShrink: 0,
                      whiteSpace: 'nowrap',
                      px: 0.2,
                      py: 0,
                      '&:hover': {
                        backgroundColor: focused ? 'grey.100' : 'grey.900',
                      },
                    },
                  }}
                  placement="top"
                  dropdownSelectOptions={{
                    onSelect: (value: any) => [setMode(value), setFocused(false)],
                    selectOptions: [
                      { value: 'manual', option: dictionary.transaction.classify.mode('manual') },
                      { value: 'certainty', option: dictionary.transaction.classify.mode('certainty') },
                    ],
                  }}
                />
                <Typography variant="body2" whiteSpace="nowrap" color="primary.main">
                  {dictionary.transaction.classify.rowSelected(options.transactionCount)}
                </Typography>
              </Box>
              <Divider orientation="vertical" variant="fullWidth" sx={{ height: 32, borderColor: focused ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.18)' }}/>
              {mode === 'manual' && (
                <React.Fragment>
                  <Box width="100%">
                    <TransparentTextField
                      variant="outlined"
                      value={value}
                      placeholder={dictionary.transaction.classify.fieldSearchPlaceholder}
                      onFocus={() => setFocused(true)}
                      onChange={onValueChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start" sx={{ color: 'inherit' }}><Search color="inherit"/></InputAdornment>,
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ color: 'inherit' }}
                          >
                            {status === 'loading' ? (
                              <CircularProgress size={24}/>
                            ) : value !== '' && (
                              <IconButton onClick={onClear} color="inherit">
                                <Clear color="inherit"/>
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Divider orientation="vertical" variant="fullWidth" sx={{ height: 32, borderColor: focused ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.18)' }}/>
                  <Stack direction="row" spacing={2}>
                    <Tooltip
                      disableHoverListener={regions.length !== 0}
                      title={dictionary.transaction.classify.countryAndYearDisabledTooltip}
                    >
                      <span>
                        <ButtonDropdown
                          label={region || dictionary.transaction.classify.buttonLocationLabel}
                          buttonProps={{
                            startIcon: <Language/>,
                            disabled: regions.length === 0,
                            variant: 'text',
                            color: 'inherit',
                            sx: {
                              whiteSpace: 'nowrap',
                              flexShrink: 0,
                              'svg': {
                                color: 'inherit',
                              },
                              '&:hover': {
                                background: focused ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.14)',
                              },
                              '&.Mui-disabled': {
                                color: focused ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.40)',
                              },
                            },
                          }}
                          placement="top"
                          dropdownSelectOptions={{
                            onSelect: value => setRegion(value),
                            selectOptions: regions.map(r => ({ value: r, option: r })),
                            ListProps: {
                              sx: {
                                maxHeight: 250,
                                overflow: 'auto',
                              },
                            },
                          }}
                        />
                      </span>
                    </Tooltip>
                    <Divider orientation="vertical" variant="fullWidth" sx={{ height: 32, borderColor: focused ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.18)' }}/>
                    <Tooltip
                      disableHoverListener={regions.length !== 0}
                      title={dictionary.transaction.classify.countryAndYearDisabledTooltip}
                    >
                      <span>
                        <ButtonDropdown
                          label={year || dictionary.transaction.classify.buttonYearLabel}
                          buttonProps={{
                            startIcon: <CalendarMonth color="inherit"/>,
                            disabled: years.length === 0 || !region,
                            variant: 'text',
                            color: 'inherit',
                            sx: {
                              whiteSpace: 'nowrap',
                              flexShrink: 0,
                              'svg': {
                                color: 'inherit',
                              },
                              '&:hover': {
                                background: focused ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.14)',
                              },
                              '&.Mui-disabled': {
                                color: focused ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.40)',
                              },
                            },
                          }}
                          placement="top"
                          dropdownSelectOptions={{
                            onSelect: value => setYear(value),
                            selectOptions: years.map(y => ({ value: y, option: y })),
                            ListProps: {
                              sx: {
                                maxHeight: 250,
                                overflow: 'auto',
                              },
                            },
                          }}
                        />
                      </span>
                    </Tooltip>
                  </Stack>
                </React.Fragment>
              )}
              {mode === 'certainty' && (
                <Stack width="100%" spacing={1} direction="row">
                  <Button
                    sx={{
                      color: confidence === EmissionClassificationConfidenceStatus.NEEDS_ATTENTION ? 'common.white' : 'grey.500',
                      backgroundColor: confidence === EmissionClassificationConfidenceStatus.NEEDS_ATTENTION ? 'grey.800' : 'transparent',
                    }}
                    onClick={() => setConfidence(EmissionClassificationConfidenceStatus.NEEDS_ATTENTION)}
                  >
                    {dictionary.transaction.lowConfidenceLabel}
                  </Button>
                  <Button
                    sx={{
                      color: confidence === EmissionClassificationConfidenceStatus.CONFIDENT ? 'common.white' : 'grey.500',
                      backgroundColor: confidence === EmissionClassificationConfidenceStatus.CONFIDENT ? 'grey.800' : 'transparent',
                    }}
                    onClick={() => setConfidence(EmissionClassificationConfidenceStatus.CONFIDENT)}
                  >
                    {dictionary.transaction.highConfidenceLabel}
                  </Button>
                </Stack >
              )}
              <LoadingButton
                loading={isSubmitting}
                disabled={mode === 'manual' ? !emissionFactorSelected : false}
                variant="contained"
                onClick={onSave}
                sx={{
                  flexShrink: 0,
                  backgroundColor: 'grey.900',
                  '&.Mui-disabled:not(.MuiLoadingButton-loading)': {
                    backgroundColor: 'grey.900',
                    color: focused ? 'grey.600' : 'rgba(255, 255, 255, 0.40)',
                  },
                }}
              >
                {dictionary.transaction.classify.applyButton}
              </LoadingButton>
            </Stack>
          </Box>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};