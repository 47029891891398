// ** MUI Imports
import { Theme } from '@mui/material/styles';


const DateTimePicker = (theme: Theme) => {
  return {
    MuiCalendarPicker: {
      styleOverrides: {
      },
    },
  };
};

export default DateTimePicker;
