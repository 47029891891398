import { MaybeNull } from 'types';
import { GetListParams } from 'types/Query';
import { Company, CompanyApi } from 'clients/companies/companyClient.types';


export type UserSignupWithEmailAndPasswordApi = {
    first_name: string;
    last_name: string;
    email: string;
    company?: string;
    job_title?: string;
    password: string;
    repeated_password: string;
    user_invitation_id?: string;
    auth_group_id?: number;
};

export type UserSignupWithEmailAndPassword = {
    firstName: string;
    lastName: string;
    company?: MaybeNull<string>;
    jobTitle?: MaybeNull<string>;
    email: string;
    password: string;
    repeatPassword: string;
    userInvitationId?: MaybeNull<string>;
    authGroupId: number;
};

export type UserCrudApi = {
    first_name: string;
    last_name: string;
    job_title: MaybeNull<string>;
    email: string;
    company_id?: number;
    type?: UserType;
    profile_picture?: File;
    auth_group?: number;
};

export type UserCreateApi = UserCrudApi;

export type UserEditApi = UserCrudApi & {
    id: number;
}

export type UserCrud = {
    firstName: string;
    lastName: string;
    jobTitle: MaybeNull<string>;
    email: string;
    company?: MaybeNull<Company>;
    type?: UserType;
    profilePicture?: File | string;
    authGroupId: number;
};

export type UserEdit = UserCrud & {
    id: number;
}

export type UserEditRole = {
    userWorkspaceId: number;
    authGroupId: number;
}

export type SimpleUserApi = {
    id: number;
    first_name: string;
    last_name: string;
    job_title: MaybeNull<string>;
    email: string;
    auth_group: {
        id: number;
        name: UserAuthGroupName;
    };
    type: UserType;
    profile_picture: MaybeNull<string>;
    active_user_company_id?: number;
}

export type UserApi = SimpleUserApi & {
    firebase_uid: string;
    company: MaybeNull<CompanyApi>;
}

export type SimpleUser = {
    id: number;
    displayName: string;
    firstName: string;
    lastName: string;
    authGroup: any;
    jobTitle: MaybeNull<string>;
    email: string;
    type: UserType;
    profilePicture?: string;
    activeUserCompanyId?: number;
}

export type User = SimpleUser & {
    firebaseUid: string;
    company: MaybeNull<Company>;
}

export enum UserType {
    Customer = 'customer',
    Admin = 'admin',
}

export enum UserAuthGroupName {
    Editor = 'Editor',
    Viewer = 'Viewer',
    Admin = 'Admin',
    Owner = 'Owner',
    NoCompany = 'NoCompany',
}

export type GetUsersParams = GetListParams<{
    type: string;
    search: string;
    company: string | number;
}>;

export type UserInviteApi = {
    id: string;
    email: string;
    company?: CompanyApi;
    auth_group_id: number;
    is_existing_user: boolean;
}

export type UserInvite = {
    id?: string;
    email?: string;
    company?: Company;
    authGroupId?: number;
    isExistingUser: boolean;
}

export type AuthGroup = {
    id: number;
    name: string;
    permissions?: any;
}

export type PostUserInvite = {
    email?: string,
    auth_group?: number,
}

export type UserInvitePartial = {
    email?: string,
    authGroup: number,
}

export type Workspace = {
    id: number;
    logo?: string;
    name: string;
    membersCount: number;
}

export type WorkspaceTeam = Omit<SimpleUser, 'type'> & {
    id: number;
    authGroup: number;
    authGroupName: UserAuthGroupName;
}

export type WorkspaceApi = {
    workspace_id: number;
    workspace_logo?: string;
    workspace_name: string;
    member_count: number;
}

export type WorkspaceTeamApi = {
    id: number;
    user: UserEditApi;
    auth_group: number;
    auth_group_name: UserAuthGroupName;
}

export type ChangeWorkspaceParams = {
    workspaceId: number;
    /** When present, excludes current session from websocket events */
    socketId?: string;
}