import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListProps,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import { Transactions } from 'modules/Transactions/transactions';
import { useVendorInsights } from 'modules/Transactions/hooks/useVendorInsights';
import { LocalizationContext } from 'contexts';
import { Transaction } from 'clients/transactions/transactionClient.types';
import { ReactComponent as NotFound } from 'shared/img/notfound.svg';

import { VendorInsightsListItem } from './VendorInsightsListItem';


export type Options = {
  transactions: Transaction[];
};

interface Props extends Omit<ListProps, 'onSelect'> {
  filters: Transactions.Vendors.Insights.GetListParams;
};

export const VendorInsightsList: React.FC<Props> = ({ filters, ...props }) => {

  const { dictionary } = React.useContext(LocalizationContext);
  const { status, insights } = useVendorInsights(filters);

  if (status === 'loading') {
    return (
      <List {...props}>
        <ListItem>
          <Stack spacing={2} direction="column">
            <Skeleton variant="rounded" width={130}/>
            <Skeleton variant="rounded" width={170}/>
            <Skeleton variant="rounded" width={120}/>
            <Skeleton variant="rounded" width={140}/>
          </Stack>
        </ListItem>
      </List>
    );
  }

  if (status === 'success' && insights.length === 0) {
    return (
      <Stack width="100%" height="100%" justifyContent="center" alignItems="center" textAlign="center" spacing={1}>
        <Box mb={1}><NotFound/></Box>
        <Typography variant="h4">{dictionary.transaction.classify.noInsightsFound}</Typography>
        <Typography variant="body1" color="grey.600">{dictionary.transaction.classify.noInsightsFoundMessage}</Typography>
      </Stack>
    );
  }

  return (
    <List {...props}>
      {insights.slice(0, 5).map(insight => <VendorInsightsListItem key={insight.id} {...insight}/>)}
    </List>
  );
};