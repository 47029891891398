import React from 'react';
import { List, ListItemButton, ListProps } from '@mui/material';


export interface IDropDownContentSelectProps {
  selectOptions: { option: string; value: string }[];
  onSelect: (value: string) => void;
  onCloseDropdown: () => void;
  ListProps?: ListProps;
}

const DropDownContentSelect = React.forwardRef<any, IDropDownContentSelectProps>(({ selectOptions, onSelect, onCloseDropdown, ListProps }, ref) => {
  const handleSelectOption = (value: string) => {
    onCloseDropdown();
    onSelect(value);
  };

  return (
    <List {...ListProps} ref={ref}>
      {selectOptions.map(({ option, value }) => (
        <ListItemButton
          onClick={() => handleSelectOption(value)}
          key={value}
        >
          {option}
        </ListItemButton>
      ))}
    </List>
  );
});

export default DropDownContentSelect;
