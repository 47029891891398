import { cleanObject, snakizeObject } from 'helpers/object';


export const getFormDataFromObject = (data: Record<string, any>): FormData => {
  const formData = new FormData();
  const cleanData = cleanObject(data);

  Object.keys(cleanData).forEach(key => {
    formData.append(key, data[key]);
  });

  return formData;
};

export const getSnakizedFormDataFromObj = (data: Record<string, any>): FormData => {
  const snakizedObject : Record<string, any> = snakizeObject(data);
  return getFormDataFromObject(snakizedObject);
};
