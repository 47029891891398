import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { transactionClient } from 'clients/transactions/transactionClient';
import { GetActivityBasedSubcategoriesParams } from 'clients/transactions/transactionClient.types';


type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useActivityBasedCategories = (params: GetActivityBasedSubcategoriesParams, options: Partial<Options> = defaultOptions) => {
  const { data: { data: activityBasedSubCategories } = {}, status, error, isLoading } = useQuery(
    [cacheKeys.activityBasedSubCategories.getActivityBasedSubCategories, params],
    () => transactionClient.getActivityBasedSubCateogries(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    isLoading,
    error,
    activityBasedSubCategories,
  };
};
