import { Fragment, useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { trackPageView } from 'tracking/googleTagManager';
import Marketplace from 'modules/Marketplace/components/Marketplace';
import { Expenses } from 'modules/Expenses/components/Expenses';
import { LinkedAccounting } from 'modules/Expenses/components/LinkedAccounting';
import { EsrsPage } from 'modules/CSRD/components/EsrsPage';
import { hideUserNavRoutes, routes } from 'config';
import { DialogContext, WebsocketContext } from 'contexts';
import { UserContext } from 'contexts/UserContext/UserContext';
import { WorkspaceSwitchData } from 'contexts/WebsocketContext/types';
import { Dialog } from 'contexts/DialogContext/types';
import { websocketEventTypes } from 'contexts/WebsocketContext/constants';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { AuthenitcatedWithoutWorkspace, Authenticated, NotAuthenticated } from 'shared/components/Authenticated/Authenticated';
import { UserNav } from 'shared/components/layout/UserNav/UserNav';
import { SharedLayoutComponent } from 'shared/components/layout/SharedLayoutComponent/SharedLayoutComponent';
import { CoolsetPageLoader } from 'shared/components/layout/CoolsetLoader/CoolsetLoader';

import { Permissioned } from 'shared/components/Permissioned/Permissioned';
import { Login } from 'views/LoggedOut/Login';
import { SignUp } from 'views/LoggedOut/SignUp';
import { ResetPassword } from 'views/LoggedOut/ResetPassword';
import { Invite } from 'views/LoggedOut/Invite';
import { Impersonate } from 'views/LoggedOut/Impersonate';

import { Home } from 'views/LoggedIn/Home/Home';
import { Profile } from './LoggedIn/User/Profile';
import { CompanySettings } from './LoggedIn/Company/CompanySettings';
import { Team } from './LoggedIn/Team/Team';
import { Measurements } from './LoggedIn/Measurements/Measurements';
import { KnowledgeBase } from './LoggedIn/KnowledgeBase/KnowledgeBase';
import { ActivityBasedTransactions } from './LoggedIn/ActivityBasedTransactions/ActivityBasedTransactions';
import { EmissionFactors } from './LoggedIn/EmissionFactors/EmissionFactors';
import { ScenarioBuilder } from './LoggedIn/ScenarioBuilder/ScenarioBuilder';
import CarbonRemoval from './LoggedIn/CarbonRemoval/CarbonRemoval';
import CarbonRemovalBackPage from './LoggedIn/CarbonRemoval/CarbonRemovalBackPage';
import Employees from './LoggedIn/Employees/Employees';
import { LeftWorkspace } from './LoggedIn/LeftWorkspace';
import { WorkspaceInvite } from './LoggedIn/WorkspaceInvite';
import { NoWorkspace } from './LoggedIn/NoWorkspace';


export const Root = () => {
  useScrollTop();
  const { loading, isLoggedIn } = useContext(UserContext);
  const location = useLocation();
  const showUserNav = isLoggedIn && (!hideUserNavRoutes.includes(location.pathname) && location.pathname.split('/')[1] !== 'invitation');
  const { openDialog } = useContext(DialogContext);
  const { bindEvent, unbindEvent } = useContext(WebsocketContext);

  useEffect(() => {
    const { switchWorkspace } = websocketEventTypes;
    bindEvent(
      switchWorkspace,
      (data: WorkspaceSwitchData) => {
        openDialog(
          Dialog.WorkspaceChange,
          {
            workspaceId: data.company_id,
            prevWorkspaceId: data.previous_company_id,
          });
      });

    return () => {
      unbindEvent(switchWorkspace);
    };
  }, [bindEvent, unbindEvent, openDialog]);

  useEffect(() => {
    trackPageView();
  }, [location]);

  if (loading) {
    return <CoolsetPageLoader/>;
  }

  return (
    <Fragment>
      <Box
        className="_d-grid-no-gap"
        sx={{
          gridTemplateColumns: `${showUserNav ? 'auto' : ''} 1fr`,
          height: '100vh',
        }}
      >
        {showUserNav && <UserNav/>}
        <Box overflow="auto" id="mainScrollContainer">
          <Box
            sx={showUserNav ? {
              px: 4,
              py: 0,
              pb: 2,
              maxWidth: '1440px',
              mx: 'auto',
              '&:has(.full-page-layout)': {
                pb: 0,
              },
            } : {}}
          >
            <NotAuthenticated>
              <Routes>
                <Route path={routes.leftWorkspace} element={<LeftWorkspace/>}/>
                <Route path={routes.noWorkspace} element={<NoWorkspace/>}/>
                <Route path={routes.impersonateUser} element={<Impersonate/>}/>
                <Route path={routes.login} element={<Login/>}/>
                <Route path={routes.resetPassword} element={<ResetPassword/>}/>
                <Route path={routes.signup} element={<SignUp/>}/>
                <Route path={routes.invite()} element={<Invite/>}/>
                <Route path={routes.leftWorkspace} element={<LeftWorkspace/>}/>
                <Route path="*" element={<Navigate to={routes.login}/>}/>
              </Routes>
            </NotAuthenticated>
            <Authenticated>
              <Routes>
                <Route path={routes.invite()} element={<WorkspaceInvite/>}/>
                <Route path={routes.impersonateUser} element={<Impersonate/>}/>
                <Route
                  path={routes.activityBasedTransactions}
                  element={
                    <Permissioned permission="manualInput.crud.view">
                      <ActivityBasedTransactions/>
                    </Permissioned>
                  }
                />
                <Route
                  path={routes.emissionFactors}
                  element={
                    <Permissioned permission="manualInput.crud.view">
                      <EmissionFactors/>
                    </Permissioned>
                  }
                />
                <Route path={routes.home} element={<Home/>}/>
                <Route path={routes.myAccount} element={<Profile/>}/>
                <Route path={routes.settings} element={<CompanySettings/>}/>
                <Route path={routes.team} element={<Team/>}/>
                <Route path={routes.expenses} element={<Expenses/>}/>
                <Route path={routes.linkedAccounting} element={<LinkedAccounting/>}/>
                <Route path={routes.measurements} element={<Measurements/>}/>
                <Route path={routes.marketplace} element={<Marketplace/>}/>
                <Route path={routes.carbonRemoval} element={<CarbonRemoval/>}/>
                <Route path={routes.carbonRemovalRedirect} element={<CarbonRemovalBackPage/>}/>
                <Route path={routes.knowledgeBase} element={<KnowledgeBase/>}/>
                <Route path={routes.employees} element={<Employees/>}/>
                <Route path={routes.esrs} element={<EsrsPage/>}/>
                <Route
                  path={routes.scenarioBuilder}
                  element={
                    <Permissioned permission="scenarioBuilder.yearlyPrediction.view">
                      <ScenarioBuilder/>
                    </Permissioned>
                  }
                />
                <Route path="*" element={<Navigate to={routes.home}/>}/>
              </Routes>
            </Authenticated>
            <AuthenitcatedWithoutWorkspace>
              <Routes>
                <Route path={routes.noWorkspace} element={<NoWorkspace/>}/>
                <Route path={routes.leftWorkspace} element={<LeftWorkspace/>}/>
                <Route path="*" element={<Navigate to={routes.noWorkspace}/>}/>
              </Routes>
            </AuthenitcatedWithoutWorkspace>
          </Box>
        </Box>
      </Box>
      <SharedLayoutComponent/>
    </Fragment>
  );
};
