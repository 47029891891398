import React, { ReactElement } from 'react';
import { useLocalStorage } from 'react-use';
import { Box, Skeleton, Typography } from '@mui/material';
import { MarketplaceFilterTypes } from 'modules/Marketplace/components/Marketplace';
import { useMarketplaceFilterOptions } from 'modules/Marketplace/hooks/useMarketplaceFilterOptions';
import { uniq } from 'lodash';
import { VendorTypes } from 'modules/Marketplace/helpers/general';
import { LocalizationContext } from 'contexts';
import { ChartGroupBy, ChartType } from 'clients/charts/chartClient.types';
import { useCharts } from 'shared/hooks/useCharts';
import { useMapCategoryChart } from 'shared/hooks/useMapCategoryChart';
import { useEmissionFactorCategories } from 'shared/hooks/useEmissionFactorCategories';
import { Filters } from 'shared/components/interactive/FiltersSection/FiltersSection';
import CategoriesBar from 'shared/components/layout/CategoriesBar/CategoriesBar';
import { CategoriesList } from 'shared/components/layout/CategoriesList/CategoriesList';
import { CategoryListParent } from 'shared/components/layout/CategoriesList/CategoryListParent';
import { FilterChips } from './FilterChips';


const MaxShownLocations = 10;

export const MarketplaceFilters = (props: MarketplaceFiltersProps) => {
  const {
    filters: {
      gteDate,
      lteDate,
    },
    sustainabilityType,
    focusArea,
    location,
    vendorType,
    handleFilterChange,
  } = props;

  const {
    dictionary: { marketplace: { filterOptions: filtersDictionary } },
  } = React.useContext(LocalizationContext);
  const reloadChartsRef = React.useRef(focusArea ? true : false);

  const {
    filterOptions,
    isLoading,
  } = useMarketplaceFilterOptions(vendorType);

  const { emissionFactorCategories = [] } = useEmissionFactorCategories({ limit: 2000 });
  const [lastLocations = [], setLastLocations] = useLocalStorage<string[]>('marketplaceVendors-lastLocations', []);

  const focusAreaOptionsEF = React.useMemo(() =>
    filterOptions.focusAreas.map((fa) => emissionFactorCategories.find((ef) => ef.id === fa.parentId)),
  [emissionFactorCategories, filterOptions.focusAreas]);

  const mappedFilters = React.useMemo(() => ({
    dateGte: gteDate,
    dateLte: lteDate,
  }), [gteDate, lteDate]);

  const {
    data: parentCategoriesChartData,
    isLoadingCharts: isLoadingParentCategoriesChartData,
  } = useCharts({
    groupBy: ChartGroupBy.parentCategory,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...mappedFilters,
  });

  const {
    data: childCategoriesChartData,
    isLoadingCharts: isLoadingChildCategoriesChartData,
  } = useCharts({
    groupBy: ChartGroupBy.category,
    metricLabel: 'Category',
    type: ChartType.pie,
    ...mappedFilters,
  });

  const isAllLoading = isLoadingChildCategoriesChartData || isLoadingParentCategoriesChartData || isLoading;

  const { mapCategoryChartData } = useMapCategoryChart();
  const totalCo2 = React.useMemo(() =>
    parentCategoriesChartData?.meta?.total_co2_kg,
  [parentCategoriesChartData?.meta?.total_co2_kg]);

  const mappedParentCategoryChartData = React.useMemo(
    () => mapCategoryChartData(
      parentCategoriesChartData,
      { allowNegative: true },
      totalCo2,
    ) || [],
    [mapCategoryChartData, parentCategoriesChartData, totalCo2],
  );
  const mappedChildCategoryChartData = React.useMemo(
    () => mapCategoryChartData(childCategoriesChartData, { allowNegative: true }, totalCo2)
      ?.map((child) => ({
        ...child,
        parent: emissionFactorCategories.find((ef) => ef.slug === child.slug).parent,
      })) || [],
    [mapCategoryChartData, childCategoriesChartData, totalCo2, emissionFactorCategories],
  );

  const otherEmissionFactors = React.useMemo(() => {
    const usedEmissionFactorNames = mappedParentCategoryChartData?.map((c) => c.name) || [];
    return focusAreaOptionsEF
      .filter((ef) => !usedEmissionFactorNames.includes(ef.title));
  }, [focusAreaOptionsEF, mappedParentCategoryChartData]);

  const selectedEF = React.useMemo(() =>
    emissionFactorCategories.find((ef) => ef.id === focusArea),
  [emissionFactorCategories, focusArea]);

  const handleLocationChange = (value?: string) => {
    handleFilterChange(MarketplaceFilterTypes.LOCATION, value);
    if (value) {
      setLastLocations(
        uniq([value, ...(lastLocations || [])]).slice(0, MaxShownLocations),
      );
    }
  };

  const sortedLocation = React.useMemo(() =>
    [...filterOptions.countries].sort((a) => lastLocations.includes(a) ? -1 : 1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [filterOptions.countries]);

  const filterSections = (
    <Box className="_d-grid-xl-gap">
      <FilterSection
        title={filtersDictionary.emissions}
        shouldHide={!mappedParentCategoryChartData.length}
      >
        <Box className="_d-grid">
          <CategoriesBar
            setEmissionFactorCategory={(value) => handleFilterChange(
              MarketplaceFilterTypes.EF,
              emissionFactorCategories.find((ef) => ef.slug === value)?.id,
            )}
            isLoading={isLoadingParentCategoriesChartData}
            data={mappedParentCategoryChartData}
            reloadChartsRef={reloadChartsRef}
            emissionFactorCategory={selectedEF}
          />
          <CategoriesList
            mappedParentCategoryChartData={mappedParentCategoryChartData}
            mappedChildCategoryChartData={mappedChildCategoryChartData}
            onCategoryChange={(name) => handleFilterChange(
              MarketplaceFilterTypes.EF,
              emissionFactorCategories.find((ef) => ef.title === name)?.id,
            )}
            emissionFactorCategory={selectedEF}
          />
        </Box>
      </FilterSection>

      <FilterSection
        title={filtersDictionary.otherCategories}
        shouldHide={!otherEmissionFactors.length}
      >
        <Box className="_d-grid">
          {otherEmissionFactors.map((fa) => (
            <CategoryListParent
              key={fa.id}
              name={fa.title}
              color={fa.color}
              icon={fa.icon}
              onClick={() => handleFilterChange(
                MarketplaceFilterTypes.EF,
                fa.id === selectedEF?.id ? undefined : fa.id,
              )}
              selectedCategoryName={selectedEF?.title}
            />
          ))}
        </Box>
      </FilterSection>

      {vendorType === VendorTypes.SUS && (
        <FilterSection title={filtersDictionary.type}>
          <FilterChips
            options={filterOptions.sustainabilityTypes}
            value={sustainabilityType}
            onChange={(value) => handleFilterChange(MarketplaceFilterTypes.SUS, value)}
          />
        </FilterSection>
      )}

      <FilterSection title={filtersDictionary.location}>
        <FilterChips
          options={sortedLocation}
          value={location}
          onChange={handleLocationChange}
          limit={MaxShownLocations}
        />
      </FilterSection>
    </Box>
  );

  return (
    <Box
      className="_d-grid-sm-gap"
      sx={{
        overflow: 'auto',
        alignContent: 'start',
        py: 2,
        px: 3,
      }}
    >
      <Typography variant="h2" mb={5}>{filtersDictionary.title}</Typography>

      {isAllLoading ? (
        <Box className="_d-grid">
          <Skeleton variant="rounded" sx={{ borderRadius: '100vmax', height: 24, width: '100%' }}/>
          <Skeleton variant="rounded" sx={{ borderRadius: '100vmax', height: 24, width: '50%' }}/>
          <Skeleton variant="rounded" sx={{ borderRadius: '100vmax', height: 24, width: '60%' }}/>
          <Skeleton variant="rounded" sx={{ borderRadius: '100vmax', height: 24, width: '40%' }}/>
          <Skeleton variant="rounded" sx={{ borderRadius: '100vmax', height: 24, width: '30%' }}/>
        </Box>
      ) : filterSections}
    </Box>
  );
};

const FilterSection = ({ title, children, shouldHide = false }: FilterSectionProps) => {

  if (shouldHide) {
    return null;
  }

  return (
    <div>
      <Typography variant="h3" mb={3}>{title}</Typography>
      {children}
    </div>
  );
};

interface FilterSectionProps {
  title: string,
  children: ReactElement,
  shouldHide?: boolean,
}

interface MarketplaceFiltersProps {
  filters: Filters;
  sustainabilityType?: string;
  focusArea?: string;
  location?: string;
  vendorType?: string;
  handleFilterChange: (filterType: string, value?: string) => void;
}