import React from 'react';
import { IconContainer } from './IconContainer';


export const CloudIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      d="M0.800781 9.9999C0.800781 11.9881 2.41256 13.5999 4.40078 13.5999H12.0008C13.7681 13.5999 15.2008 12.1672 15.2008 10.3999C15.2008 9.17184 14.509 8.10535 13.4939 7.56886C13.5635 7.32453 13.6008 7.06657 13.6008 6.7999C13.6008 5.25351 12.3472 3.9999 10.8008 3.9999C10.4821 3.9999 10.1759 4.05314 9.89048 4.1512C9.26139 3.10207 8.11309 2.3999 6.80078 2.3999C4.81256 2.3999 3.20078 4.01168 3.20078 5.9999C3.20078 6.20025 3.21715 6.39678 3.24862 6.58822C1.82545 7.06867 0.800781 8.41462 0.800781 9.9999Z"
      fill="currentColor"
    />
  </IconContainer>
);
