
export const pluralizeString = (count: number, noun: string, suffix = 's') => {
  return `${count !== 1 ? count : ''} ${noun}${count !== 1 ? suffix : ''}`;
};

export function capitalize(string: string): string {
  if (string.length === 0) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}