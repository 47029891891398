import React, { FC, createContext, useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

import { pastelPalette } from 'shared/styles/charts';
import { UserContext } from 'contexts/UserContext/UserContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { infiniteArrayIterator } from 'helpers/array';
import { useEmissionFactorCategories } from 'shared/hooks/useEmissionFactorCategories';

import { ContextProps, Mode } from './types';


const defaultContext: ContextProps = {
  mode: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  setMode: () => null,
  toggleMode: () => null,
  adminView: false,
  isMobile: false,

  mobileMenuOpen: false,
  setMobileMenuOpen: () => null,
  navExpanded: true,
  setNavExpanded: () => null,

  snackbar: {
    open: false,
    message: '',
    severity: 'info',
    autoHideDuration: 5000,
  },
  setSnackbar: () => null,
  closeSnackbar: () => null,
  genericSuccess: () => null,
  changesSaved: () => null,
  genericError: () => null,
  genericInfo: () => null,
  validationError: () => null,
  navWidth: 256,
  emissionFactorCategoriesColors: {},
  emissionFactorCategoriesIcons: {},
  emissionFactorCategoriesByTitle: {},
  isResizing: false,
  setIsResizing: () => null,
};


export const LayoutContext = createContext(defaultContext);

export const LayoutContextProvider: FC<React.PropsWithChildren> = ({ children }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isAdmin } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const [mode, setMode] = useState<Mode>('light');
  const [snackbar, setSnackbar] = useState(defaultContext.snackbar);
  const [navExpanded, setNavExpanded] = useState(defaultContext.navExpanded);
  const [isResizing, setIsResizing] = React.useState<boolean>(false);

  const navWidth = useMemo(() => navExpanded ? defaultContext.navWidth : 90, [navExpanded]);

  const { emissionFactorCategories = [] } = useEmissionFactorCategories({ limit: 2000 });

  const emissionFactorCategoriesColors = useMemo(() => {
    const iterator = infiniteArrayIterator(pastelPalette);
    return emissionFactorCategories.reduce((prev, next) => {
      prev[next.title] = next?.color || iterator.next().value;
      return prev;
    }, {});
  }, [emissionFactorCategories]);

  const emissionFactorCategoriesIcons = useMemo(() => {
    return emissionFactorCategories.reduce((prev, next) => {
      prev[next.title] = next?.icon;
      return prev;
    }, {});
  }, [emissionFactorCategories]);

  const emissionFactorCategoriesByTitle = useMemo(() => {
    return emissionFactorCategories.reduce((prev, next) => {
      prev[next.title] = next;
      return prev;
    }, {});
  }, [emissionFactorCategories]);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const closeSnackbar = useCallback(() => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  }, [snackbar]);

  const genericSuccess = useCallback((msg: string = dictionary.successGeneric, hideDuration: number = 2000) => {
    setSnackbar({
      open: true,
      message: msg,
      severity: 'success',
      autoHideDuration: hideDuration,
    });
  }, [dictionary]);

  const changesSaved = useCallback(() => genericSuccess(dictionary.successChangeSaved), [dictionary, genericSuccess]);

  const validationError = useCallback((message: string = dictionary.oneOrMoreFieldsAreIncorrect) => {
    setSnackbar({
      open: true,
      message: message,
      severity: 'error',
      autoHideDuration: 3000,
    });
  }, [dictionary]);

  const genericError = useCallback(() => {
    setSnackbar({
      open: true,
      message: dictionary.somethingWentWrong,
      severity: 'error',
      autoHideDuration: 2000,
    });
  }, [dictionary]);

  const genericInfo = useCallback((message: string = '') => {
    setSnackbar({
      open: true,
      message: message,
      severity: 'success',
      autoHideDuration: 6000,
    });
  }, []);

  const toggleMode = useCallback(() => {
    setMode(mode === 'dark' ? 'light' : 'dark');
  }, [mode]);

  useEffect(() => {
    localStorage.setItem('mode', mode);
  }, [mode]);

  return (
    <LayoutContext.Provider
      value={{
        mode,
        setMode,
        toggleMode,

        mobileMenuOpen,
        setMobileMenuOpen,
        navExpanded,
        setNavExpanded,
        navWidth,

        adminView: isAdmin,
        isMobile,

        snackbar,
        setSnackbar,
        closeSnackbar,
        genericSuccess,
        changesSaved,
        genericError,
        genericInfo,
        validationError,
        emissionFactorCategoriesColors,
        emissionFactorCategoriesIcons,
        emissionFactorCategoriesByTitle,
        isResizing,
        setIsResizing,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
