import { useContext, FunctionComponent, useState } from 'react';
import {
  Typography,
  Collapse,
  Box,
} from '@mui/material';

import { ChevronIcon } from 'shared/icons/ChevronArrIcon';
import { LocalizationContext } from 'contexts';

import { IPurchaseProps } from './CarbonRemovalTypes';
import PurchaseBundleItem from './PurchaseBundleItem';


const Purchases: FunctionComponent<IPurchaseProps> = ({
  offsetTransactions,
}) => {
  const { dictionary } = useContext(LocalizationContext);
  const [isExpand, setIsExpand] = useState<boolean>(true);

  return (
    <Box>
      <Box
        className="_d-flex-ali-center-jc-sb"
        onClick={() => setIsExpand(!isExpand)}
        sx={{ cursor: 'pointer', py: 3 }}
      >
        <Typography variant="h2">
          {dictionary.carbonRemoval.purchases.title}
        </Typography>
        <ChevronIcon direction={isExpand ? 'up' : 'down'}/>
      </Box>
      <Collapse in={isExpand}>
        {offsetTransactions!.results.map(({ description, id, status }) => (
          <PurchaseBundleItem description={description} id={id} status={status} key={id}/>))}
      </Collapse>
    </Box>
  );
};

export default Purchases;
