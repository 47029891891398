import React from 'react';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { LocalizationContext } from 'contexts';
import { WaitingAsyncProcessContext } from 'contexts';
import { AsyncProcessType } from 'contexts/WaitingAsyncProcessContext/types';


export const AsyncLoaderStep = (props: AsyncLoaderStepProps) => {
  const { dictionary } = React.useContext(LocalizationContext);
  const {
    onClose,
    title = dictionary.expenses.uploadIntegration.asyncLoading.title,
    text = dictionary.expenses.uploadIntegration.asyncLoading.text,
    processType = AsyncProcessType.CsvUpload,
  } = props;
  const { asyncProcesses } = React.useContext(WaitingAsyncProcessContext);

  const uploadCsvProcess = React.useMemo(() =>
    asyncProcesses.find((p) => p.processType === processType),
  [asyncProcesses, processType],
  );

  const { progress } = uploadCsvProcess || {};

  return (
    <Box
      className="_d-grid"
      sx={{ gridTemplateRows: '1fr auto 1fr' }}
    >
      <div/>
      <div className="_d-flex-dir-col-ali-center-sm-gap">
        <Typography variant="h5" mb={1} className="show-first">{title}</Typography>
        <LinearProgress
          variant={!!progress && progress < 100 ? 'determinate' : 'indeterminate'}
          value={progress}
          sx={{ minWidth: 280 }}
        />
        <Typography color="text.secondary" className="show-first" sx={{ minHeight: 20 }}>
          {!!progress && progress < 100 && `${progress}%`}
        </Typography>
      </div>
      <div className="_d-flex-dir-col-ali-center-md-gap">
        <Typography color="text.secondary" my={10} textAlign="center" className="show-second">
          {text}
        </Typography>
        <Button
          onClick={onClose}
          className="show-third"
        >
          {dictionary.minimize}
        </Button>
      </div>
    </Box>
  );
};

interface AsyncLoaderStepProps {
  onClose: () => void;
  title?: string;
  text?: string;
  processType?: AsyncProcessType;
}