import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState, Fragment, MutableRefObject } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import VisibilitySensor from 'react-visibility-sensor';
import { useLocalStorage } from 'react-use';
import { Box, Button, Card, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { DataGridPro as DataGrid, GridColDef, useGridApiRef, GRID_CHECKBOX_SELECTION_COL_DEF, GridCellCheckboxRenderer, selectedIdsLookupSelector } from '@mui/x-data-grid-pro';
import { GridAlignment } from '@mui/x-data-grid';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';
import { cloneDeep, isEmpty, set } from 'lodash';

import { greyTextColor } from 'shared/styles/muiTheme';
import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext, PermissionContext, UserContext } from 'contexts';
import { Dictionary } from 'contexts/LocalizationContext/types';
import { transactionClient } from 'clients/transactions/transactionClient';
import { ActivityBasedTransactionStatus, TransactionManualInputType, TransactionManualInput, Transaction } from 'clients/transactions/transactionClient.types';
import { useTransactions } from 'shared/hooks/useTransactions';
import { useEmissionFactors } from 'shared/hooks/useEmissionFactors';
import SkipTransactionsButton from 'shared/components/layout/Tooltip/SkipTransactionsButton';
import { EmissionFactorsAutocomplete } from 'shared/components/form/EmissionFactorsAutocomplete/EmissionFactorsAutocomplete';
import { SelectAll } from 'shared/components/interactive/SelectAll/SelectAll';
import TableCheckBox from 'shared/components/interactive/Checkbox/Checkbox';
import SelectAllTableCheckBox from 'shared/components/interactive/Checkbox/SelectAllTableCheckBox';
import { TagsCell } from 'views/LoggedIn/Measurements/components/TagsCell';


type OnManualInputChange = (transactionId: number, manualInputId: number, value?: string | number, emissionFactorId?: number) => void;
interface ManualInputProps {
  row: Transaction;
  manualInput: TransactionManualInput;
  onChange: OnManualInputChange;
  apiRef: { current: object }
}

export const ManualSelectInput: FC<ManualInputProps> = ({ row, manualInput, onChange, apiRef }) => {
  const { emissionFactors, status } = useEmissionFactors({ manualInputId: manualInput?.id, search: manualInput?.answer?.emissionFactorTitle, limit: 2000 });

  if (status === 'loading') {
    return null;
  }

  const preselectedFactor = emissionFactors?.find((factor: any) => factor.title === manualInput?.answer?.emissionFactorTitle);

  return (
    <EmissionFactorsAutocomplete
      key={`${row.id}_${manualInput?.id}`}
      manualInputId={manualInput?.id}
      defaultValue={preselectedFactor}
      sx={{ marginTop: '2px' }}
      TextFieldProps={{
        placeholder: 'Select factor',
        size: 'small',
        sx: {
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            py: '3.5px',
          },
          minWidth: '8em',
        },
      }}
      handleOnChange={(value) => onChange(row.id, manualInput?.id, undefined, value?.id)}
      onKeyDown={(e) => e.stopPropagation()}
      fullWidth
    />
  );
};

interface ManualInputProps {
  row: Transaction;
  manualInput: TransactionManualInput;
}

export const ManualInput: FC<ManualInputProps> = ({ row, manualInput, onChange }) => {
  const sharedId = `${row.id}_${manualInput?.id}`;

  return (
    <Fragment>
      <TextField
        size="small"
        id={sharedId}
        sx={{
          '.MuiOutlinedInput-input': { padding: '6px' },
          minWidth: '3em',
        }}
        onBlur={({ target: { value } }) => onChange(row.id, manualInput?.id, value) }
        defaultValue={manualInput?.answer?.value}
      />
      <Typography
        component="label"
        htmlFor={sharedId}
        sx={{
          fontStyle: 'italic',
          opacity: 0.7,
          marginLeft: '8px',
        }}
      >
        {manualInput?.unit}
      </Typography>
    </Fragment>
  );
};
interface GetColumnsParams {
  canViewTotal: boolean;
  canChangeTransaction: boolean;
  canViewCo2: boolean;
  onManualInputChange: OnManualInputChange;
  apiRef: MutableRefObject<GridApiPro>
  dictionary: Dictionary;
  hasManualInputs?: boolean;
}

const getColumns = ({ canViewTotal, canChangeTransaction, canViewCo2, onManualInputChange, apiRef, dictionary, hasManualInputs }: GetColumnsParams): GridColDef[] => {

  const manualInputOption = [{
    field: 'manualInputDropdown',
    headerName: '',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    align: 'left' as GridAlignment,
    pinnable: false,
    renderCell: (props: any) => props.row.manualInputs[0]?.type === TransactionManualInputType.Dropdown
      ? <ManualSelectInput {...props} manualInput={props.row.manualInputs[0]} onChange={onManualInputChange}/>
      : <ManualInput {...props} manualInput={props.row.manualInputs[0]} onChange={onManualInputChange}/>,
  },
  {
    field: 'manualInputField',
    headerName: '',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    align: 'left' as GridAlignment,
    pinnable: false,
    renderCell: (props: any) => props.row.manualInputs[1]?.type === TransactionManualInputType.Dropdown
      ? <ManualSelectInput {...props} manualInput={props.row.manualInputs[1]} onChange={onManualInputChange}/>
      : <ManualInput {...props} manualInput={props.row.manualInputs[1]} onChange={onManualInputChange}/>,
  }];

  const columns: Array<GridColDef | undefined> = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      valueGetter: (params) => {
        const selectionLookup = selectedIdsLookupSelector(
          apiRef,
        );
        return selectionLookup[params.id] !== undefined;
      },
      renderHeader: (params) => (
        <Fragment>
          <SelectAllTableCheckBox apiRef={apiRef} {...params}/>
        </Fragment>
      ),
      renderCell: (params) => <GridCellCheckboxRenderer {...params}/>,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
      editable: false,
      sortable: true,
      type: 'dateTime',
      pinnable: false,
      valueFormatter: ({ value }: any) => value && format(new Date(value as string), 'dd MMM \'\'yy'),
    },
    {
      field: 'ledger',
      headerName: 'Ledger',
      flex: 1,
      sortable: false,
      pinnable: false,
    },
    {
      field: 'vendorName',
      sortable: true,
      headerName: 'Vendor',
      flex: 1,
      hide: false,
      resizable: true,
      pinnable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      editable: false,
      sortable: true,
      resizable: true,
      hide: false,
      flex: 1,
      valueGetter: ({ row }: any) => row?.description,
      pinnable: false,
      renderCell: ({ row, value }: any) => (
        value && (
          <Tooltip title={row?.description}>
            <span>{value}</span>
          </Tooltip>
        )
      ),
    },
    ...(canViewTotal ? [{
      field: 'netAmount',
      headerName: 'Net cost',
      sortable: false,
      width: 80,
      align: 'right' as GridAlignment,
      disableColumnMenu: true,
      pinnable: false,
      valueGetter: ({ row }: any) => row.localizedAmount,
    }] : []),
    ...(canViewCo2 ? [{
      field: 'co2Kg',
      headerName: 'kg CO₂e',
      sortable: false,
      width: 80,
      align: 'right' as GridAlignment,
      disableColumnMenu: true,
      pinnable: false,
      valueGetter: ({ row }: any) => row?.emissionClassification?.co2Kg,
      renderCell: ({ value }: any) => value && (value as number).toFixed(1),
    }] : []),
    {
      field: 'emissionFactor',
      headerName: 'CO₂e emission factor',
      editable: false,
      sortable: false,
      flex: 1,
      pinnable: false,
      valueGetter: ({ row }: any) => row?.emissionClassification?.emissionFactor?.title,
      renderCell: ({ row, value }: any) => (
        value && <Tooltip title={row?.emissionClassification?.emissionFactor?.description}>
          <span>{value}</span>
        </Tooltip>
      ),
    },
    ...(canChangeTransaction && hasManualInputs ? manualInputOption : []),
    {
      field: 'tags',
      headerName: dictionary.measurements.datagrid.headerNames.tags,
      editable: false,
      sortable: true,
      filterable: false,
      resizable: true,
      width: 150,
      renderCell: (props) => <TagsCell {...props} getTagTooltip={dictionary.filterSection.companyTagsLabels.tagsTooltip}/>,
      pinnable: false,
    },
  ];
  const filteredColumns: Array<GridColDef> = columns.filter((column) => column !== undefined) as Array<GridColDef>;
  return filteredColumns;
};

interface Props {
  transactionStatus?: ActivityBasedTransactionStatus;
  subCategoryId?: number;
  tags?: string[];
  renderCheckBoxSelection?: boolean;
}

type ManualInputPayload = {
  transactionId: number;
  manualInputId: number;
  value?: string | number;
  emissionFactorId?: number;
}
interface ManualInputData {
  [key: string | number]: {
    [key: string | number]: ManualInputPayload;
  }
}

export const TransactionsList: FC<Props> = ({ subCategoryId, transactionStatus, tags, renderCheckBoxSelection = true }) => {

  const apiRef = useGridApiRef();
  const queryClient = useQueryClient();
  const { genericInfo, genericError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const manualInputData = useRef<ManualInputData | null>(null);
  const [isInViewport, setIsInViewport] = useState(false);
  const { user } = useContext(UserContext);
  const { getPermission } = useContext(PermissionContext);
  const minimumDateFilter = useMemo(() => (user?.company?.minimumDateFilter ? new Date(user?.company?.minimumDateFilter) : null
  ), [user]);
  const [page, setPage, removePage] = useLocalStorage(`${subCategoryId}-page`, 0);
  const [pageSize, setPageSize, removePageSize] = useLocalStorage(`${subCategoryId}-pageSize`, 20);
  const {
    transactions,
    status,
    count,
    selected,
    setSelected,
    showSelectAll,
    isWholeDatasetSelected,
    setIsWholeDatasetSelected,
  } = useTransactions({
    page,
    pageSize,
    subCategoryId,
    manualInput: transactionStatus,
    tags,
    ...(minimumDateFilter && { dateGte: minimumDateFilter }),
  }, {
    enabled: isInViewport,
  });

  const hasManualInputs = useMemo(() => !!transactions[0]?.manualInputs?.length, [transactions]);

  useEffect(() => {
    return () => {
      removePage();
      removePageSize();
    };
  }, [removePageSize, removePage]);

  const skipManualInputTransactions = useMutation(transactionClient.skipManualInputTransactions, {
    mutationKey: cacheKeys.transactions.skipManualInputTransactions,
    onSuccess: () => {
      genericInfo(dictionary.transaction.edit.transactionsSkipped);
      queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
      queryClient.invalidateQueries(cacheKeys.activityBasedSubCategories.getActivityBasedSubCategories);
    },
    onError: () => {
      genericError();
    },
  });

  const revertManualInputTransactions = useMutation(transactionClient.revertManualInputTransactions, {
    mutationKey: cacheKeys.transactions.revertManualInputTransactions,
    onSuccess: () => {
      genericInfo(dictionary.transaction.edit.transactionsReverted);
      queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
      queryClient.invalidateQueries(cacheKeys.activityBasedSubCategories.getActivityBasedSubCategories);
    },
    onError: () => {
      genericError();
    },
  });

  const saveManualInputAnswers = useMutation(transactionClient.saveManualInputAnswers, {
    mutationKey: cacheKeys.transactions.saveManualInputAnswers,
    onSuccess: () => {
      genericInfo(dictionary.transaction.edit.transactionsSaved);
      queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
      queryClient.invalidateQueries(cacheKeys.activityBasedSubCategories.getActivityBasedSubCategories);
    },
    onError: () => {
      genericError();
    },
  });

  const onSkip = useCallback(async () => {
    if (isWholeDatasetSelected) {
      return skipManualInputTransactions.mutateAsync({
        subCategoryId,
        ...(minimumDateFilter && { dateGte: minimumDateFilter }),
      });
    }

    const data = selected.map((transactionId: number) => ({
      transactionId: transactionId,
    }));

    return skipManualInputTransactions.mutateAsync({ transactionIds: data });
  }, [isWholeDatasetSelected, minimumDateFilter, selected, subCategoryId, skipManualInputTransactions]);

  const onRevert = useCallback((skipped: boolean = true) => {
    if (isWholeDatasetSelected) {
      return revertManualInputTransactions.mutateAsync(
        {
          subCategoryId,
          skipped,
          ...(minimumDateFilter && { dateGte: minimumDateFilter }),
        },
      );
    }
    const data = selected.map((transactionId: number) => ({
      transactionId: transactionId,
    }));

    return revertManualInputTransactions.mutateAsync({ transactionIds: data, skipped: skipped });
  }, [selected, revertManualInputTransactions, isWholeDatasetSelected, subCategoryId, minimumDateFilter]);

  const onManualInputChange = useCallback((transactionId: number, manualInputId: number, value?: string | number, emissionFactorId?: number) => {
    const nextManualInputData = cloneDeep(manualInputData.current || {});
    set(nextManualInputData,`[${transactionId}]`, nextManualInputData?.[transactionId] || {});
    set(nextManualInputData,`[${transactionId}][${manualInputId}]`, {
      transactionId,
      manualInputId,
      value,
      emissionFactorId,
    });
    manualInputData.current = nextManualInputData;
  }, []);

  const onSave = useCallback(async () => {
    const payload = manualInputData.current || {};
    const data: ManualInputPayload[][] = Object.keys(payload)?.map((transactionId: string | number) => {
      return Object.keys(payload[transactionId]).map((manualInputId: string | number) => ({
        ...(payload[transactionId][manualInputId]),
      }));
    });

    const filteredData = data.flat().filter((item) => !isEmpty(item));

    if (isEmpty(filteredData)) {
      return;
    }

    return saveManualInputAnswers.mutateAsync(filteredData);
  }, [saveManualInputAnswers]);

  const gridColumns = useMemo(() => {
    const columnsDef = getColumns({
      canViewTotal: getPermission('transaction.totalAmount.view'),
      canChangeTransaction: getPermission('transaction.crud.edit'),
      canViewCo2: getPermission('transaction.co2.view'),
      onManualInputChange,
      apiRef: apiRef,
      dictionary,
      hasManualInputs,
    });
    if (!transactions?.length) {
      return columnsDef;
    }

    const columnsWithAdditionalHeaders = cloneDeep(columnsDef);
    const manualInputDropdownColumn = columnsWithAdditionalHeaders.find(({ field }) => field === 'manualInputDropdown');
    const manualInputFieldColumn = columnsWithAdditionalHeaders.find(({ field }) => field === 'manualInputField');

    if (manualInputDropdownColumn) {
      manualInputDropdownColumn.headerName = transactions[0]?.manualInputs?.[0]?.label || '';
    }

    if (manualInputFieldColumn) {
      manualInputFieldColumn.headerName = transactions[0]?.manualInputs?.[1]?.label || '';
    }

    return columnsWithAdditionalHeaders;
  }, [onManualInputChange, getPermission, transactions, apiRef, dictionary, hasManualInputs]);

  return (
    <VisibilitySensor
      partialVisibility
      onChange={(visibility: any) => setIsInViewport(visibility)}
    >
      <Grid container spacing={3} sx={{ mt: -4 }}>
        <Grid item xs={12}>
          <Card>
            <SelectAll
              pageCount={selected.length}
              show={showSelectAll}
              totalDatasetCount={count}
              isWholeDatasetSelected={isWholeDatasetSelected}
              onSelectAll={setIsWholeDatasetSelected}
            />
            <DataGrid
              loading={status === 'loading'}
              autoHeight
              checkboxSelection={getPermission('transaction.crud.edit') && renderCheckBoxSelection}
              disableSelectionOnClick
              disableColumnFilter
              rows={transactions}
              columns={gridColumns}
              density="compact"
              rowsPerPageOptions={[10, 20, 50, 100, 1000]}
              page={page}
              pagination
              components={{
                BaseCheckbox: TableCheckBox,
              }}
              rowHeight={59}
              headerHeight={52}
              apiRef={apiRef}
              paginationMode="server"
              pageSize={pageSize}
              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
              rowCount={count}
              onPageChange={newPage => setPage(newPage)}
              selectionModel={selected}
              onSelectionModelChange={selected => setSelected(selected as number[])}
              localeText={{
                footerRowSelected: (defaultCount) => dictionary.dataGrid.footer.rowSelected(isWholeDatasetSelected ? count : defaultCount),
              }}
            />
          </Card>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {hasManualInputs && (
              <LoadingButton
                variant="contained"
                loading={saveManualInputAnswers.isLoading}
                onClick={onSave}
              >
                {dictionary.transaction.transactionList.saveAndContinue}
              </LoadingButton>
            )}
            {(transactionStatus === ActivityBasedTransactionStatus.Open && renderCheckBoxSelection) && (
              <SkipTransactionsButton
                onClick={onSkip}
                toolTipTitle={dictionary.transaction.transactionList.toolTip.content}
                btnLabel={dictionary.transaction.transactionList.toolTip.skipTransactionsBtnLabel}
                btnProps={{ disabled: !selected.length }}
              />
            )}
            {(transactionStatus === ActivityBasedTransactionStatus.Skipped && renderCheckBoxSelection) && (
              <Button
                variant="text"
                sx={{ ml: 2, color: greyTextColor }}
                disabled={!selected.length}
                onClick={() => onRevert()}
              >
                {dictionary.transaction.transactionList.revertSkippedTransactions}
              </Button>
            )}
            {(transactionStatus === ActivityBasedTransactionStatus.Completed && renderCheckBoxSelection) && (
              <Button
                variant="text"
                sx={{ ml: 2, color: greyTextColor }}
                disabled={!selected.length}
                onClick={() => onRevert(false)}
              >
                {dictionary.transaction.transactionList.revertCompletedTransactions}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </VisibilitySensor>
  );
};
