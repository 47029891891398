import React, { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { Button, Typography } from '@mui/material';
import { Box, BoxProps, Stack } from '@mui/system';

import { greyTextColor } from 'shared/styles/muiTheme';
import { ArrowPath } from 'shared/icons/ArrowPath';
import { LocalizationContext } from 'contexts';
import ExclamationTriangleIcon from 'shared/components/layout/Icons/ExclamationTriangleIcon';


interface IErrorPageProps extends BoxProps {
  queryKey: any;
}

const ErrorPage: React.FunctionComponent<IErrorPageProps> = ({ queryKey, ...restProps }) => {
  const { dictionary } = useContext(LocalizationContext);
  const queryClient = useQueryClient();

  const handleRetry = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return (
    <Box
      display="flex"
      gap={3}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt={20}
      mr={32}
      {...restProps}
    >
      <Stack alignItems="center" gap={1} direction="row">
        <ExclamationTriangleIcon
          sx={{ color: 'grey', fontSize: 30 }}
          stroke="white"
        />
        <Typography
          sx={{ verticalAlign: 'center' }}
          fontSize={16}
          fontWeight={600}
        >
          {dictionary.somethingWentWrong}
        </Typography>
      </Stack>

      <Typography sx={{ verticalAlign: 'center' }} color={greyTextColor}>
        {dictionary.employees.errorPage.loadFormError}
      </Typography>
      <Button
        onClick={handleRetry}
        color="info"
      >
        <ArrowPath/>
        {dictionary.employees.errorPage.reloadForm}
      </Button>
    </Box>
  );
};

export default ErrorPage;
