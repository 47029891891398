import { FC, useMemo, useState, Fragment, useCallback } from 'react';
import { TableRow, TableCell, Typography } from '@mui/material';
import { isEmpty, isArray } from 'lodash';

import { getCurrentYear } from 'helpers/date';
import { numberToPercentage } from 'helpers/number';

import { capitalize } from 'helpers/string';
import { CategoryCell } from './CategoryCell';
import { ValueCell } from './ValueCell';


export const ProjectionTableRow: FC<any> = ({ row, categoryName, projectedReduction = 0, baselineYear, years }) => {
  const hasSubRows = useMemo(() => {
    return !isEmpty(row.subcategories);
  }, [row]);
  const [isExpanded, setIsExpanded] = useState(false);
  const values = useMemo(() => {
    return isArray(row.values) ? row.values : row;
  }, [row]);

  const shouldShowValue = useCallback((year: any) => {
    if (
      year < getCurrentYear() ||
      year === baselineYear
    ) {
      return false;
    }
    return true;
  }, [baselineYear]);

  return (
    <Fragment>
      <TableRow>
        <TableCell component="td" scope="row" sx={{ py: 1.5, verticalAlign: 'top' }}>
          <CategoryCell
            categoryName={capitalize(categoryName)}
            hasSubCategories={hasSubRows}
            isExpanded={isExpanded}
            onClick={() => hasSubRows && setIsExpanded(!isExpanded)}
            color={row.color}
          />
        </TableCell>
        {values.map(({ id, value, percentage, year, editable }: any, index: number) => {
          if (!years.includes(year)) {
            return null;
          }
          return (
            <TableCell
              component="td"
              scope="row"
              key={index}
              sx={{
                p: 0,
                textAlign: 'center',
                bgcolor: year >= getCurrentYear() ? '#FAFAFA' : undefined,
                verticalAlign: shouldShowValue(year) ? undefined : 'baseline',
              }}
            >
              <ValueCell
                id={id}
                value={value}
                percentage={percentage}
                showValue={shouldShowValue(year)}
                editable={editable}
              />
            </TableCell>
          );
        })}
        <TableCell component="td" scope="row" sx={{ textAlign: 'right', py: 1.5, verticalAlign: 'top' }}>
          <Typography
            variant="body1"
            fontSize={13}
            color={projectedReduction.toFixed(4) <= row.projected_reduction.toFixed(4) ? '#249683' : 'error'}
          >
            {numberToPercentage(row.projected_reduction || 0)}
          </Typography>
        </TableCell>
      </TableRow>
      {hasSubRows && isExpanded && Object.keys(row.subcategories || {})?.map((subcategoryName: any) => (
        <ProjectionTableRow
          key={subcategoryName}
          row={row.subcategories[subcategoryName]}
          categoryName={subcategoryName}
          projectedReduction={projectedReduction}
          baselineYear={baselineYear}
          years={years}
        />
      ))}
    </Fragment>
  );
};
