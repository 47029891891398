import React from 'react';
import { Grid } from '@mui/material';

import { Logo } from 'shared/components/layout/Logo/Logo';


export const LoggedOutActionsLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: '100vh', position: 'relative' }}
      gap={4}
    >
      <Grid item>
        <Logo withBackground style={{ width: 120 }}/>
      </Grid>
      <Grid
        item
        mt={1}
        sx={(theme) => ({
          width: 415,
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0 16px',
          },
        })}
      >
        {children}
      </Grid>
    </Grid>
  );
};
