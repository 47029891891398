import React, { FC, Fragment } from 'react';
import { Typography } from '@mui/material';
import { greyTextColor } from 'shared/styles/muiTheme';


export const LoggedOutHeader: FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle,
}) => {
  return (
    <Fragment>
      <Typography variant="h4" align="center" fontSize={27} mb={1}>
        {title}
      </Typography>
      <Typography
        color={greyTextColor}
        variant="subtitle1"
        fontSize={15}
        mb={4}
        align="center"
      >
        {subtitle}
      </Typography>
    </Fragment>
  );
};
