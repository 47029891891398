import { TextField, BaseTextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { greyTextColor } from 'shared/styles/muiTheme';


export interface ICustomTextFieldProps extends BaseTextFieldProps {
  customvariant: 'upperLabel';
}

const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'success',
})<ICustomTextFieldProps>(({ customvariant }) => {
  const variants = {
    upperLabel: {
      '& .MuiOutlinedInput-root': {
        height: 48,
        '& input': {
          '&::placeholder': { color: greyTextColor },
        },
      },
      overflow: 'clip',
      borderWidth: 1,
      '&.MuiOutlinedInput-root.Mui-focused, .MuiOutlinedInput-notchedOutline:is(#_, *)': {
        borderWidth: '1px',
      },
      borderRadius: '4px',
    },
  };

  return variants[customvariant as keyof typeof variants];
});

export default CustomTextField;
