import {
  FC,
  createContext,
  useCallback,
  PropsWithChildren,
  useRef,
  useState,
} from 'react';

import { EmissionFactors } from 'clients/emissionFactors/emissionFactorsClient.types';
import { Authenticated } from 'shared/components/Authenticated/Authenticated';

import { RecommendationsPanel } from 'shared/components/layout/RecommendationsPanel/RecommendationsPanel';
import { ContextProps, Drawers } from './types';


export const defaultContext: ContextProps = {
  openDrawer: () => {},
  closeDrawer: () => {},
  auxiliaryRef: { current: undefined },
};

export const DrawerContext = createContext(defaultContext);
export const DrawerContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openedDrawer, setOpenedDrawer] = useState<Drawers | null>(null);
  const [value, setValue] = useState(EmissionFactors.Travel);
  const auxiliaryRef = useRef(undefined);

  const openDrawer = useCallback((drawer: Drawers) => {
    setOpenedDrawer(drawer);
  }, [setOpenedDrawer]);

  const closeDrawer = useCallback(() => {
    setOpenedDrawer(null);
  }, [setOpenedDrawer]);

  return (
    <DrawerContext.Provider
      value={{
        openDrawer,
        closeDrawer,
        auxiliaryRef,
      }}
    >
      {children}
      <Authenticated>
        <RecommendationsPanel
          value={value}
          setValue={setValue}
          closeDrawer={closeDrawer}
          isDrawerOpen={openedDrawer === Drawers.recommendation}
          category={auxiliaryRef.current}
        />
      </Authenticated>
    </DrawerContext.Provider>
  );
};
