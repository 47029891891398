import { Fragment, useCallback, useContext } from 'react';

import { LocalizationContext } from 'contexts';
import { DrawerContext } from 'contexts/DrawerContext/DrawerContext';
import { Drawers } from 'contexts/DrawerContext/types';
import RightArrowBtn from 'shared/components/layout/Buttons/RightArrowBtn';



const ExploreRecommendationsBtn = () => {
  const { dictionary } = useContext(LocalizationContext);
  const { openDrawer, auxiliaryRef } = useContext(DrawerContext);

  const exploreRecommendationsHandler = useCallback(() => {
    auxiliaryRef.current = undefined;
    openDrawer(Drawers.recommendation);
  },[auxiliaryRef, openDrawer]);

  return (
    <Fragment>
      <RightArrowBtn
        label={dictionary.recommendations.exploreButton}
        onClick={exploreRecommendationsHandler}
      />
    </Fragment>
  );
};

export default ExploreRecommendationsBtn;
