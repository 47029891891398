import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { usersClient } from 'clients/users/usersClient';
import { WorkspaceTeam } from 'clients/users/userClient.types';


export const useWorkspace = (): Workspace => {
  const { data: { data: workspace = [] } = {}, status, error } = useQuery(
    [cacheKeys.workspaces.getWorkspace],
    () => usersClient.getWorkspace(),
  );

  return {
    status,
    error,
    workspace,
  };
};

export interface Workspace {
  error: unknown;
  status: 'idle' | 'loading' | 'error' | 'success';
  workspace: WorkspaceTeam[];
}