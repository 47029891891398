import { useCallback, useContext, useMemo, useEffect, FC, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { QueryStatus } from 'react-query';
import { Percent } from '@mui/icons-material';
import { Typography, Grid, TextField, MenuItem, InputAdornment, Button, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';

import { greyTextColor } from 'shared/styles/muiTheme';
import { CompanyContext, DialogContext, LocalizationContext, PermissionContext } from 'contexts';
import { getCurrentYear } from 'helpers/date';
import { useScenarioBuilderFormCrud } from 'shared/hooks/ScenarioBuilder/useScenarioBuilderFormCrud';

import { ReadOnlyValue } from './ReadOnlyValue';
import { TargetIndicator } from './TargetIndicator';
import { ScenarioBuilderDialog } from './ScenarioBuilderDialog';


export type Form = {
    start_year: number;
    baseline_year: number;
    reduction_target: number | undefined;
}

interface Props {
    table: any;
    status: QueryStatus;
}

export const ScenarioBuilderForm: FC<Props> = ({ table, status }) => {
  const { getPermission } = useContext(PermissionContext);
  const { asyncConfirmation } = useContext(DialogContext);
  const { dictionary } = useContext(LocalizationContext);
  const { company } = useContext(CompanyContext);
  const [willTargetBeHit, setWillTargetBeHit] = useState(table.will_target_be_hit);

  const { handleSubmit, getValues, control, reset, watch, setValue, formState: { isSubmitting, errors } } = useForm<Form>({
    defaultValues: {
      start_year: table.start_year,
      baseline_year: table.baseline_year,
      reduction_target: 0,
    },
  });

  const baselineYearWatch = watch('baseline_year');
  const currentYear = useMemo(() => (getCurrentYear()), []);
  const defaultBaselineYear = useMemo(() => (
    company ? new Date(company.minimumDateFilter).getFullYear() : getCurrentYear() - 1
  ), [company]);

  const baselineYears = useMemo(() => {
    return Array.from(
      { length: currentYear - defaultBaselineYear + 1 },
      (_, i) => (
        defaultBaselineYear + i
      ),
    );
  }, [currentYear, defaultBaselineYear]);

  const startYears = useMemo(() => {
    const targetYear = table.target_year;
    return Array.from(
      { length: targetYear - baselineYearWatch },
      (_, i) => (
        baselineYearWatch + 1 + i
      ),
    );
  }, [baselineYearWatch, table.target_year]);

  const tableIsEmpty = useMemo(() => {
    return isEmpty(table);
  }, [table]);

  const shouldDisableForm = useMemo(() => {
    return status === 'loading' || isSubmitting || !getPermission('scenarioBuilder.crud.edit');
  }, [isSubmitting, status, getPermission]);

  const updateTargetProjection = useCallback(async () => {
    const formData = getValues();
    const willTargetBeHit = (formData.reduction_target || 0) <= parseFloat(((table?.categories?.total?.projected_reduction || 0) * 100).toFixed(4));
    setWillTargetBeHit(willTargetBeHit);
  }, [getValues, table]);

  useEffect(() => {
    if (baselineYearWatch) {
      setValue('start_year', baselineYearWatch + 1);
    }
  }, [baselineYearWatch, setValue]);

  useEffect(() => {
    const formData = getValues();
    reset({
      start_year: table.start_year === (defaultBaselineYear + 1) ? (defaultBaselineYear + 1) : table.start_year,
      baseline_year: table.baseline_year === defaultBaselineYear ? defaultBaselineYear : table.baseline_year,
      reduction_target: formData.reduction_target || (table.projected_reduction ? parseFloat((table.projected_reduction * 100).toFixed(2)) : undefined),
    });
    updateTargetProjection();
  }, [getValues, reset, table, updateTargetProjection, defaultBaselineYear]);
  const { submitForm } = useScenarioBuilderFormCrud();

  const onSubmit = useCallback(async (data: Form) => {
    const userConfirmed = await asyncConfirmation({ CustomDialog: ScenarioBuilderDialog });

    if (!userConfirmed) {
      return false;
    }

    return submitForm.mutateAsync({ ...data, reduction_target: parseFloat(((data.reduction_target || 0) / 100).toFixed(4)) });
  }, [asyncConfirmation, submitForm]);


  return (
    <form onSubmit={handleSubmit(onSubmit)} onBlur={updateTargetProjection}>
      <Typography variant="h6" mt={3} mb={2}>{dictionary.scenarioBuilder.targetSetting}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color={greyTextColor}>{dictionary.scenarioBuilder.baselineYear}</Typography>
          <Controller
            name="baseline_year"
            control={control}
            render={({ field }) => (
              <Tooltip title={dictionary.scenarioBuilder.baselineYearTooltip} placement="right" arrow>
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={shouldDisableForm}
                  select
                >
                  {baselineYears.map((baselineYear, index) => (
                    <MenuItem key={index} value={baselineYear}>{baselineYear}</MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color={greyTextColor}>{dictionary.scenarioBuilder.startYear}</Typography>
          <Controller
            name="start_year"
            control={control}
            render={({ field }) => (
              <Tooltip title={dictionary.scenarioBuilder.startYearTooltip} placement="right" arrow>
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={shouldDisableForm}
                  select
                >
                  {startYears.map((startYear) => (
                    <MenuItem value={startYear}>{startYear}</MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color={greyTextColor}>{dictionary.scenarioBuilder.reductionTarget}</Typography>
          <Controller
            name="reduction_target"
            control={control}
            rules={{ required: dictionary.forms.validations.required }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                variant="outlined"
                size="small"
                error={!!errors.reduction_target}
                helperText={errors.reduction_target && errors.reduction_target.message}
                disabled={shouldDisableForm}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><Percent/></InputAdornment>,
                }}
              />
            )}
          />

        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color={greyTextColor}>{dictionary.scenarioBuilder.baseline}</Typography>
          <ReadOnlyValue
            value={tableIsEmpty ? '-' : `${(table.baseline * 100).toFixed(2)}%`}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color={greyTextColor}>{dictionary.scenarioBuilder.willTargetBeHit}</Typography>
          <TargetIndicator targetAchieved={willTargetBeHit}/>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color={greyTextColor}>{dictionary.scenarioBuilder.targetYear}</Typography>
          <ReadOnlyValue value={tableIsEmpty ? '-' : table.target_year}/>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            disabled={shouldDisableForm}
            sx={{
              marginTop: '18px',
              py: '10px',
            }}
          >
            {dictionary.scenarioBuilder.autofillTable}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color={greyTextColor}>{dictionary.scenarioBuilder.annualTarget}</Typography>
          <ReadOnlyValue
            value={tableIsEmpty ? '-' : `${(table.annual_target * 100).toFixed(2)}%`}
          />
        </Grid>
      </Grid>
    </form>
  );
};
