import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';


export const TransparentTextField = withStyles({
  root: {
    color: 'inherit',
    '& input, & svg': {
      color: 'inherit',
    },
    '& .MuiAutocomplete-hasClearIcon': {
      color: 'inherit',
    },
    '& label.Mui-focused': {
      color: 'inherit',
    },
    '& .MuiInputBase-root': {
      color: 'inherit',
    },
    '& .MuiInputLabel-root': {
      color: 'inherit',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      color: 'inherit',
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  },
})(TextField);
