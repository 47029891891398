// ** MUI Imports
import { Theme } from '@mui/material/styles';
import { textColor } from 'shared/styles/theme/palette';


const input = (theme: Theme) => {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: textColor,
          fontSize: 13,
          marginBottom: 4,
          '&.Survey-Forms': {
            fontSize: 15,
            color: '#374151',
            fontWeight: 600,
            marginBottom: 8,
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: true,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#C5C7CD',
        },
      },
    },
  };
};

export default input;
