import { BoxProps } from '@mui/system';
import { PaginatedResults } from 'types/Query';
import { LuneBundles } from 'clients/offsetting/offsettingClientTypes';
import { Transaction } from 'clients/transactions/transactionClient.types';


export interface IBundlesProps {
    imgsrc: string;
    desc: string;
    price: string;
    luneClientCompanyKey?: string;
    title: string;
    offsetType?: string;
    carbonPermanence?: string | null;
    id?: string;
    onSelectProject?: () => void;
    iframeUrl?: string;
  }

export interface IPurchaseProps {
  offsetTransactions: PaginatedResults<Transaction> | undefined
}

export enum sortBundlePrice {
  asc ='asc',
  desc = 'desc'
}
export interface IPriceSortButtonProps {
  onSortPrice: () => void;
  label: string;
  priceSorted: sortBundlePrice.asc | sortBundlePrice.desc | false;
}
export interface IEmissionBoxSectionProps {
  emissionWeight: number;
  totalOffset: number;
}

export interface IEmissionBoxBarVariants {
  netEmissions: BoxProps;
  totalEmissions: BoxProps;
  offset: BoxProps;
}

export interface IemissionBoxBarSizes {
  small: {
    height: number | undefined;
    width: number;
  };
  big: {
    height: number;
    width: number;
  };
}

export type EmissionBoxBarVariant = keyof IEmissionBoxBarVariants;

export interface IEmissionBoxBarProps {
  variant: EmissionBoxBarVariant;
  size: keyof IemissionBoxBarSizes;
  smallBarHeight?: number,
}

export interface IEmissionBoxProps {
  containerProps?: BoxProps;
  emissionBarHeights: number[];
  title: string;
  emissionBarVariant: EmissionBoxBarVariant;
  emissionWeight: number;
  tooltip?: boolean;
}

export interface IBundleSectionProps {
  luneOffsetKey?: string,
  luneBundles: LuneBundles,
}

export enum BundleIcons {
  cloud = 'cloud',
  lock = 'lock'
}

export interface IBundleCategoryIconProps {
  icon: BundleIcons.cloud | BundleIcons.lock;
  title: string;
}

export enum OrderStatus {
  complete = 'complete',
  retiring = 'retiring',
}

export enum OffsetTypeVariantsEnum {
  carbonRemoval = 'carbon_removal',
  emissionsReduction = 'emissions_reduction',
  natureBased = 'nature_based',
}

export enum CarbonPermanceVariantsEnum {
  shortTerm = 'short_term',
  longTerm = 'long_term' ,
  noStorage = 'no_storage',
}