import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { SparklesIcon } from 'shared/icons/SparklesIcon';
import { BeakerIcon } from 'shared/icons/BeakerIcon';
import { LocalizationContext } from 'contexts';
import { ReactComponent as Status } from 'shared/img/Status.svg';
import { Steps } from 'shared/components/form/Steps/Steps';


export const WizardDoneStep = (props: WizardDoneStepProps)=> {
  const {
    title,
    subTitle,
    actions,
    stepsCompleted,
  } = props;
  const { dictionary } = React.useContext(LocalizationContext);

  return (
    <Box className="_d-grid" sx={{ gridTemplateRows: '1fr auto', height: '100%' }} p={5}>
      <Box className="_d-flex-dir-col-ali-center-jc-center-md-gap" height="100%">
        <Status/>
        <Typography variant="h2">{title}</Typography>
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ textWrap: 'balance', mb: 3 }}
        >
          {subTitle}
        </Typography>

        <Steps
          stepItems={[
            {
              title: dictionary.expenses.uploadIntegration.stepsDone[0],
              isCompleted: true,
            },
            {
              title: dictionary.expenses.uploadIntegration.stepsDone[1],
              image: <SparklesIcon/>,
              isCompleted: 1 < stepsCompleted,
            },
            {
              title: dictionary.expenses.uploadIntegration.stepsDone[2],
              image: <BeakerIcon/>,
              isCompleted: 2 < stepsCompleted,
            },
          ]}
        />
      </Box>

      <Box className="_d-flex-jc-center-md-gap">
        {actions}
      </Box>
    </Box>
  );
};

interface WizardDoneStepProps {
  title: string;
  subTitle: string;
  actions: ReactElement;
  stepsCompleted: number;
}