import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const LeafIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      d="M4.66797 14C4.66797 14 5.0013 11 7.33464 8.33331L4.66797 14Z"
      fill="currentColor"
    />
    <path
      d="M4.66797 14C4.66797 14 5.0013 11 7.33464 8.33331"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7538 2.82799L13.1498 6.94465C13.3992 9.53532 11.4565 11.842 8.86651 12.0913C6.32451 12.336 4.02184 10.478 3.77717 7.93599C3.6596 6.71528 4.03176 5.49787 4.81179 4.55157C5.59181 3.60526 6.71581 3.00757 7.93651 2.88999L12.3172 2.46865C12.3695 2.4636 12.4223 2.4689 12.4725 2.48426C12.5228 2.49961 12.5696 2.52473 12.6101 2.55816C12.6507 2.59159 12.6842 2.63269 12.7089 2.6791C12.7336 2.72552 12.7488 2.77567 12.7538 2.82799Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconContainer>
);
