// ** Theme Type Import
import { Theme } from '@mui/material/styles';

import { textSecondary } from 'shared/styles/theme/palette';


export const textColor = '#333333';

declare module '@mui/material' {
  interface TypographyPropsVariantOverrides {
    buttonText: true;
    pageTitle: true;
    tableTitle: true;
    categoryTable: true;
  }
}

const Typography = (theme: Theme) => {
  return {
    fontSize: 12,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightBold: 600,
    buttonText: {
      fontSize: 15,
      fontWeight: 600,
      color: textColor,
    },
    pageTitle: {
      fontWeight: 600,
      color: textColor,
      fontSize: 26,
    },
    tableTitle: {
      color: textSecondary,
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 11,
      backgroundColor: '#F0F3F6',
      borderRadius: 1,
      '&:first-child': {
        borderRadius: '4px 0 0 4px',
      },
      '&:last-child': {
        borderRadius: '0 4px 4px 0',
      },
    },
    categoryTable: {
      fontSize: 13,
      color: '#374151',
    },
    h1: {
      fontWeight: 600,
      fontSize: 25,
    },
    h2: {
      fontWeight: 600,
      fontSize: 21,
    },
    h3: {
      fontWeight: 600,
      fontSize: 17,
    },
    h4: {
      fontWeight: 600,
      fontSize: 15,
    },
    h5: {
      fontWeight: 400,
      fontSize: 15,
    },
    h6: {
      fontWeight: 600,
      fontSize: 13,
    },
    body1: {
      fontWeight: 400,
      fontSize: 13,
    },
    body2: {
      fontWeight: 400,
      fontSize: 12,
    },
    ...['subtitle1', 'subtitle2', 'default', 'button', 'overline'].reduce((prev, next) => {
      prev[next] = { fontWeight: 400, fontSize: 13 };
      return prev;
    }, {} as any),
  };
};

export default Typography;
