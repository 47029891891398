import { EmissionFactors } from 'clients/emissionFactors/emissionFactorsClient.types';


export const recomendationTabContentList = [
  {
    imgSrc:
      'https://res.cloudinary.com/greencast/image/upload/v1658129935/Recommendations/Chart_travel_mnnlsu.svg',
    recommendationCategory: EmissionFactors.Travel,
  },
  {
    imgSrc:
      'https://res.cloudinary.com/greencast/image/upload/v1658129933/Recommendations/Chart_transport_jjzbkr.svg',
    recommendationCategory: EmissionFactors.TransportAndLogistics,
  },
  {
    imgSrc:
      'https://res.cloudinary.com/greencast/image/upload/v1658130652/Recommendations/Chart_logistics_svgldf.svg',
    recommendationCategory: EmissionFactors.Logistics,
  },
  {
    imgSrc:
      'https://res.cloudinary.com/greencast/image/upload/v1658129936/Recommendations/Chart_food_hkgugr.svg',
    recommendationCategory: EmissionFactors.FoodsAndBeverages,
  },
  {
    imgSrc:
      'https://res.cloudinary.com/greencast/image/upload/v1658131049/Recommendations/Chart_energy_i1snwj.svg',
    recommendationCategory: EmissionFactors.Energy,
  },
  {
    imgSrc:
      'https://res.cloudinary.com/greencast/image/upload/v1658129934/Recommendations/Chart_digital_uwfehx.svg',
    recommendationCategory: EmissionFactors.Digital,
  },
  {
    imgSrc:
      'https://res.cloudinary.com/greencast/image/upload/v1658129936/Recommendations/Chart_product_n3atur.svg',
    recommendationCategory: EmissionFactors.Design,
  },
  {
    imgSrc:
      'https://res.cloudinary.com/greencast/image/upload/v1658129933/Recommendations/Chart_waste_waczkk.svg',
    recommendationCategory: EmissionFactors.Waste,
  },
  {
    imgSrc:
      'https://res.cloudinary.com/greencast/image/upload/v1658129936/Recommendations/Chart_building_kr1vvd.svg',
    recommendationCategory: EmissionFactors.Buildings,
  },
  {
    imgSrc:
      'https://res.cloudinary.com/greencast/image/upload/v1658130652/Recommendations/Chart_consumption_tzwume.svg',
    recommendationCategory: EmissionFactors.Products,
  },
];
