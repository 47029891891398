import { FC, useContext, useEffect } from 'react';
import { Box, Divider, Tab, Tabs, Typography, Drawer } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { LocalizationContext } from 'contexts';
import { EmissionFactors } from 'clients/emissionFactors/emissionFactorsClient.types';
import { useSxBreakPointsStyles } from 'shared/hooks/useSxBreakPointsStyles';

import RecomendationTabContent from './Tabs/RecommendationTabContent';
import { recomendationTabContentList } from './recomendationTabContentList';


const sxBrkPtDrawerPaperList = [
  { maxBreakPointValue: 770, breakPointStyle: { width: '90%' } },
];

interface IRecommendationsPanel {
  category?: EmissionFactors,
  isDrawerOpen: boolean,
  closeDrawer: () => void;
  value: EmissionFactors,
  setValue: (category: EmissionFactors) => void;
}

export const RecommendationsPanel: FC<IRecommendationsPanel> = ({ category, isDrawerOpen, closeDrawer, value, setValue }) => {
  const { dictionary } = useContext(LocalizationContext);

  function a11yProps(index: number | string) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const sxBrkPtDrawerPaper = useSxBreakPointsStyles(sxBrkPtDrawerPaperList);

  useEffect(() => {
    if (isDrawerOpen) {
      setValue(category || EmissionFactors.Travel);
    }
  }, [category, isDrawerOpen, setValue]);


  const handleChange = (event: React.SyntheticEvent, category: EmissionFactors) => {
    setValue(category);
  };

  return (
    <Drawer
      open={isDrawerOpen}
      anchor="right"
      transitionDuration={500}
      title={dictionary.recommendations.drawerTitle}
      PaperProps={{
        sx: {
          width: '720px',
          ...sxBrkPtDrawerPaper,
          boxShadow: '0 8px 8px rgb(0 0 0 / 50%)',
        },
      }}
      ModalProps={{
        disableScrollLock: true,
      }}
      BackdropProps={{
        sx: { background: 'rgba(0, 0, 0, 0.3)' },
      }}
      onClose={closeDrawer}
    >
      <Box
        sx={{
          minHeight: 64,
          display: 'flex',
          alignItems: 'center',
          color: 'rgb(37, 39, 51)',
          opacity: 0.7,
        }}
        borderBottom="1px solid rgba(0,0,0,.1)"
      >
        <ArrowBackIosIcon
          onClick={closeDrawer}
          sx={{ ml: 5, mr: 4, fontSize: 25, cursor: 'pointer' }}
        />
        <Typography fontSize={18} fontWeight={500}>
          {dictionary.recommendations.drawerTitle}
        </Typography>
      </Box>
      <Box
        sx={{
          '[id^="simple-tabpanel"] > .MuiBox-root': {
            padding: '24px 0px',
          },
          overflow: 'auto',
          padding: '16px 24px',
        }}
      >
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="standard"
            sx={{
              '.MuiTabs-flexContainer': {
                flexWrap: 'wrap',
              },
            }}
          >
            {recomendationTabContentList.map(({ imgSrc, recommendationCategory }, i) => (
              <Tab key={recommendationCategory} value={recommendationCategory} label={dictionary.recommendations[recommendationCategory].label} {...a11yProps(i)}/>
            ))}
          </Tabs>
          <Divider flexItem style={{ width: '100%', marginTop: '8px' }}/>
        </Box>
        {recomendationTabContentList.map(
          ({ imgSrc, recommendationCategory }, i) => (
            <RecomendationTabContent
              key={recommendationCategory}
              imgSrc={imgSrc}
              imgAlt={recommendationCategory}
              value={value}
              index={i}
              recommendationCategory={recommendationCategory}
            />
          ),
        )}
      </Box>
    </Drawer>
  );
};
