import { FC } from 'react';
import { SxProps } from '@mui/system';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

import { ArrowCircleIcon } from 'shared/icons/ArrowIcon';
import { IconContainer } from 'shared/icons/IconContainer';



interface IRightArrowBtn extends LoadingButtonProps {
  onClick?: () => void;
  label: string;
  sxProps?: SxProps;
  arrowRightCircleProps?: React.ComponentProps<typeof IconContainer>;
}

const RightArrowBtn: FC<IRightArrowBtn> = ({
  onClick,
  label,
  sxProps,
  loading,
  arrowRightCircleProps,
  variant='contained',
  ...restBtnProps
}) => {
  return (
    <LoadingButton
      loading={loading}
      variant={variant}
      onClick={onClick}
      {...restBtnProps}
      sx={sxProps}
    >
      {label}
      <ArrowCircleIcon
        {...arrowRightCircleProps}
        direction="right"
      />
    </LoadingButton>
  );
};

export default RightArrowBtn;
