import { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';


export const ICON_DIR = {
  down: '0',
  left: '90deg',
  up: '180deg',
  right: '270deg',
} as { [key: string]: string };

type IconDirection = 'up' | 'right' | 'down' | 'left';

export const IconContainer: FC<IconContainerProps> = ({
  children,
  direction = 'down',
  ...props
}) => (
  <SvgIcon
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    sx={{
      minWidth: 16,
      color: 'inherit',
      width: 16,
      height: 16,
      fill: 'none',
      ...props.sx,
      rotate: ICON_DIR[direction],
    }}
  >
    {children}
  </SvgIcon>
);

interface IconContainerProps extends ComponentProps<typeof SvgIcon> {
  /**
   * Direction of the icon
   * @default down
   */
  direction?: IconDirection;
}