import { useEffect, useState } from 'react';
import { GridColumns, GridValidRowModel } from '@mui/x-data-grid-pro';
import { snakeCase } from 'lodash';

import { SortModel, SortColumnField } from 'types/SortModel';


export const useSortModel = <T extends GridValidRowModel>(setSorting: (sorting: string) => void, columns: GridColumns<T> = []): [SortModel, (model: SortModel) => void] => {
  const [sortModel, setSortModel] = useState<SortModel>(
    columns
      .filter((column) => column.sortable)
      .map((column) => ({
        field: column.field as SortColumnField,
        sort: undefined,
      })),
  );

  useEffect(() => {
    const params = sortModel
      .map((model) => {
        const snakedField = snakeCase(model.field);
        return model.sort === 'asc' ? snakedField : `-${snakedField}`;
      });
    setSorting(params.join(','));
  }, [sortModel, setSorting]);

  return [sortModel, setSortModel];
};
