import { FC, useContext } from 'react';
import { Typography } from '@mui/material';

import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';

import { IDropMessageProps } from './dropInterfaces';


const DropMessage: FC<IDropMessageProps> = ({
  variant,
  fileName,
  readableSize,
  dropZoneMsgs,
}) => {
  const { dictionary } = useContext(LocalizationContext);

  const { dropZoneMsg, dropZoneAcceptedMsg, dropZoneExtensionRejectedMsg, dropZoneSizeRejectedMsg } = dropZoneMsgs;

  const typographyVariants = {
    drag: dropZoneMsg,
    dragAccepted: dropZoneAcceptedMsg,
    dragExtensionRejected: dropZoneExtensionRejectedMsg,
    dragSizeRejected: dropZoneSizeRejectedMsg,
    dragDropped: dictionary.transaction.import.dropSuccessful({ fileName , readableSize }),
  };

  return (
    <Typography fontSize={14}>
      {typographyVariants[variant as keyof typeof typographyVariants]}
    </Typography>
  );
};

export default DropMessage;
