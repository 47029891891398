import { FC, Fragment, useContext } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, North, South } from '@mui/icons-material';

import { LocalizationContext } from 'contexts';
import { Emissions } from 'shared/components/layout/Emissions/Emissions';
import { CategoryIcon } from 'shared/components/layout/CategoryIcon/CategoryIcon';
import { CategoriesTableProps } from 'views/LoggedIn/Measurements/types';
import { getPercentageChangeColorCategoryTable, getTextWeightCategoryTable } from 'views/LoggedIn/Measurements/helpers';

import ChildCategory from 'views/LoggedIn/Measurements/components/ChildCategory';
import RecommendationsCell from 'views/LoggedIn/Measurements/components/EmissionsByType/RecommendationsCell';


const CategoriesTable: FC<CategoriesTableProps> = ({
  mappedParentCategoryChartData,
  setEmissionFactorCategory,
  emissionFactorCategory,
  mappedFilters,
  mappedChildCategoryChartData,
  isParent,
  setHighlightedCategoryBarTable,
  reloadChartsRef,
  mappedCategoryChartData,
  highlightedCategoryBarTable,
  isComparingMonths,
}) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Fragment>
      {(mappedParentCategoryChartData || []).map(
        ({ name, slug, value, icon, fill, co2Percentage, transactionCount, previousValues, percentageChange }) => {
          const isOpen = emissionFactorCategory?.parent?.slug === slug || emissionFactorCategory?.slug === slug;

          return (
            <Grid
              key={slug}
              container
              spacing={1}
              py={1}
              alignItems="center"
              onMouseEnter={() => emissionFactorCategory?.slug ? setHighlightedCategoryBarTable(slug) : null}
              onMouseLeave={() => setHighlightedCategoryBarTable(undefined)}
              sx={{
                opacity: ((emissionFactorCategory?.slug === slug) || emissionFactorCategory?.parent?.slug === slug || highlightedCategoryBarTable === slug || (!emissionFactorCategory?.slug && !highlightedCategoryBarTable)) ? 1 : 0.5,
              }}
            >
              <Grid item xs={isComparingMonths ? 3 : 4}>
                <Stack
                  spacing={1}
                  direction="row"
                >
                  <CategoryIcon icon={icon} name={name} color={fill}/>
                  <Stack direction="column">
                    <Stack direction="row">
                      <Typography
                        fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })}
                        onClick={() => {
                          if (emissionFactorCategory?.slug === slug) {
                            setEmissionFactorCategory(undefined);
                            reloadChartsRef.current = false;
                          } else {
                            setEmissionFactorCategory(slug);
                            reloadChartsRef.current = true;
                          }
                        }}
                        sx={{ cursor: 'pointer' }}
                        variant="body1"
                      >
                        {name}
                      </Typography>
                      {emissionFactorCategory?.slug === slug ? (
                        <ExpandLess
                          onClick={() => {
                            setEmissionFactorCategory(undefined);
                            reloadChartsRef.current = false;
                          }}
                          sx={{ ml: 1, cursor: 'pointer' }}
                        />
                      ) : (
                        <ExpandMore
                          onClick={() => {
                            setEmissionFactorCategory(slug);
                            reloadChartsRef.current = false;
                          }}
                          sx={{ ml: 1, cursor: 'pointer' }}
                        />
                      )}
                    </Stack>
                    <Typography variant="body2" component="span" color="text.secondary">{dictionary.measurements.transactionCount(transactionCount)}</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={isComparingMonths ? 5 : 4}>
                <Grid container spacing={1} width="100%">
                  {isComparingMonths && (
                    <Grid item textAlign="right" xs={4}>
                      <Typography
                        fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })}
                        variant="categoryTable"
                      >
                        <Emissions showBoldValue={false} kg={previousValues?.value} showCo2={false}/>
                      </Typography>
                      <Typography
                        fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })}
                        variant="body2"
                        color="text.secondary"
                      >
                        {previousValues?.co2Percentage}%
                      </Typography>
                    </Grid>
                  )}
                  <Grid item textAlign="right" xs={isComparingMonths ? 4 : 12}>
                    <Typography
                      fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })}
                      variant="categoryTable"
                    >
                      <Emissions showBoldValue={false} kg={value} showCo2={false}/>
                    </Typography>
                    <Typography
                      fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })}
                      variant="body2"
                      color="text.secondary"
                    >
                      {co2Percentage}%
                    </Typography>
                  </Grid>
                  {isComparingMonths && (
                    <Grid item textAlign="right" xs={4}>
                      <Typography
                        fontWeight={getTextWeightCategoryTable({ emissionFactorCategory, slug })}
                        color={getPercentageChangeColorCategoryTable(percentageChange)}
                        variant="body2"
                      >
                        <Box component="span" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                          {percentageChange > 0 && <North fontSize="inherit"/>}
                          {percentageChange < 0 && <South fontSize="inherit"/>}
                          {Math.abs(percentageChange).toFixed()}%
                        </Box>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <RecommendationsCell name={name}/>
              </Grid>
              <Grid sx={{ mt: -1, ml: -1 }} item xs={12}>
                <Box pl={4.5}>
                  {isOpen && (
                    <ChildCategory
                      mappedFilters={mappedFilters}
                      slug={slug}
                      mappedChildCategoryChartData={mappedChildCategoryChartData}
                      isParent={isParent}
                      mappedCategoryChartData={mappedCategoryChartData}
                      setEmissionFactorCategory={setEmissionFactorCategory}
                      highlightedCategoryBarTable={highlightedCategoryBarTable}
                      reloadChartsRef={reloadChartsRef}
                      emissionFactorCategory={emissionFactorCategory}
                      setHighlightedCategory={setHighlightedCategoryBarTable}
                      isComparingMonths={isComparingMonths}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          );},
      )}
    </Fragment>
  );
};

export default CategoriesTable;
