import React, { FC } from 'react';
import { SnackbarProvider } from 'notistack';

import NotistackAlert from './NotistackAlert';


const NotistackProvider: FC<React.PropsWithChildren> = (props) => {
  return(
    <SnackbarProvider
      maxSnack={2}
      Components={{
        alert: NotistackAlert,
      }}
    >
      {props.children}
    </SnackbarProvider>);
};

export default NotistackProvider;
