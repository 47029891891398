import { FC, createContext, useContext, useState, useCallback, useMemo, PropsWithChildren } from 'react';

import { CompanyOverviewDialog } from 'modules/Companies/dialogs/CompanyOverviewDialog';

import { LocalizationContext } from 'contexts';

import { useQueryState } from 'shared/hooks/useQueryState';
import { Authenticated } from 'shared/components/Authenticated/Authenticated';
import { AsyncConfirmationDialog } from 'shared/components/dialog/AsyncConfirmationDialog/AsyncConfirmationDialog';
import { InviteTeamMemberDialog } from 'shared/components/dialog/InviteTeamMember/InviteTeamMemberDialog';
import { WorkspaceChangeDialog } from 'shared/components/dialog/WorkspaceChangeDialog/WorkspaceChangeDialog';
import { ContextProps, Dialog, AsyncDialogProps, AsyncConfirmation } from './types';


export const defaultContext: ContextProps = {
  openDialog: () => {},
  closeDialog: () => {},
  asyncConfirmation: () => Promise.resolve(false),
};

const defaultAsyncConfirmationDialog = {
  title: null,
  content: null,
  confirmLabel: null,
  cancelLabel: null,
  show: false,
};

export const DialogContext = createContext(defaultContext);
export const DialogContextProvider: FC<PropsWithChildren> = ({ children }) => {

  const { dictionary } = useContext(LocalizationContext);

  const [queryDialogs = [], setDialogs] = useQueryState<Array<Dialog>>('dialog');
  const [dialogOptions, setDialogOptions] = useState<Partial<Record<Dialog, any>>>({});


  const dialogs: Array<Dialog> = useMemo(() => {
    return typeof queryDialogs === 'string' ? [queryDialogs] : queryDialogs;
  }, [queryDialogs]);

  const openDialogs: Record<string, boolean> = useMemo(() => {
    return Object.values(Dialog)
      .filter(k => typeof k === 'string')
      .reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: dialogs.includes(curr as Dialog),
        };
      }, {});
  }, [dialogs]);

  const openDialog = useCallback((dialog: Dialog, options?: any) => {
    setDialogs([...dialogs, dialog]);
    setDialogOptions(prevOptions => ({
      ...prevOptions,
      [dialog]: options,
    }));
  }, [dialogs, setDialogs, setDialogOptions]);

  const closeDialog = useCallback((dialog: Dialog) => {
    setDialogs(dialogs.filter(d => d !== dialog));

    const options = { ...dialogOptions };

    delete options[dialog];

    setDialogOptions(options);
  }, [dialogs, setDialogs, dialogOptions, setDialogOptions]);

  const [popup, setPopup] = useState<AsyncDialogProps>(defaultAsyncConfirmationDialog);
  const [onConfirmPopup, setOnConfirmPopup] = useState<any>(() => null);
  const [onClosePopup, setOnClosePopup] = useState<any>(() => null);

  const asyncConfirmation: AsyncConfirmation = useCallback(({ title, content, confirmLabel, cancelLabel, CustomDialog, isDelete }) => {
    setPopup({
      show: true,
      title: title || dictionary.confirmation.defaultTitle,
      content: content || dictionary.confirmation.defaultContent,
      confirmLabel: confirmLabel || dictionary.confirmation.yes,
      cancelLabel: cancelLabel || dictionary.confirmation.no,
      CustomDialog: CustomDialog,
      isDelete: isDelete,
    });
    return new Promise((resolve) => {
      setOnConfirmPopup(() => () => [setPopup(defaultAsyncConfirmationDialog), resolve(true)]);
      setOnClosePopup(() => () => [setPopup(defaultAsyncConfirmationDialog), resolve(false)]);
    });
  }, [dictionary]);

  return (
    <DialogContext.Provider
      value={{
        openDialog,
        closeDialog,
        asyncConfirmation,
      }}
    >
      {children}

      <Authenticated>
        <InviteTeamMemberDialog open={openDialogs[Dialog.InviteTeamMember]} onClose={() => closeDialog(Dialog.InviteTeamMember)}/>
        {!!openDialogs[Dialog.CompanyOverview] && (
          <CompanyOverviewDialog open={openDialogs[Dialog.CompanyOverview]} data={dialogOptions.companyOverview} onClose={() => closeDialog(Dialog.CompanyOverview)}/>
        )}
        <WorkspaceChangeDialog open={openDialogs[Dialog.WorkspaceChange]} onClose={() => closeDialog(Dialog.WorkspaceChange)} data={dialogOptions.workspaceChange}/>
      </Authenticated>

      {popup.CustomDialog ? <popup.CustomDialog open={popup.show || false} onConfirm={onConfirmPopup} onClose={onClosePopup}/> : <AsyncConfirmationDialog
        open={popup.show}
        onClose={onClosePopup}
        onConfirm={onConfirmPopup}
        title={popup.title}
        content={popup.content}
        confirmLabel={popup.confirmLabel}
        cancelLabel={popup.cancelLabel}
        isDelete={popup.isDelete}
      />
      }
    </DialogContext.Provider>
  );
};
