// ** MUI Imports
import { Theme } from '@mui/material/styles';
import { line } from 'shared/styles/muiTheme';


const TextField = (theme: Theme) => {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline': {
            '&:before, &:hover:not(.Mui-disabled):before': {
              borderBottom: `solid 1px ${line}`,
            },
            '&:after': {
              borderWidth: '1px',
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#E8E8E8',
            },
          },
        },
      },
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 5px 15px -3px rgba(0, 0, 0, 0.1), 0px 2px 6px -4px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  };
};

export default TextField;
