import React, { useContext } from 'react';
import { Button, Typography } from '@mui/material';
import { LocalizationContext } from 'contexts';
import { FilterModules, FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';


export const EsrsPage = () => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <FiltersSection
      filterModules={[FilterModules.YEAR]}
      title={dictionary.csrd.esrs.title}
      pageActions={[
        <Typography key="savedText">saved placeholder</Typography>,
        <Button key="generateReportButton" disabled>
          Generate Report
        </Button>,
      ]}
    >
      {({ filters }) => <div>hello world</div>}
    </FiltersSection>
  );
};
