import { FunctionComponent } from 'react';
import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material';


const CheckIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="white"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </SvgIcon>
  );
};


export default CheckIcon;
