import { IconContainer } from './IconContainer';


export const UnlinkIcon = ({ ...props }) => (
  <IconContainer {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7678 4.23223C14.7915 3.25592 13.2085 3.25592 12.2322 4.23223L9.23223 7.23223C9.06955 7.39492 8.93397 7.57445 8.8255 7.76521L11.416 10.3557C11.3066 9.94413 11.0906 9.55502 10.7678 9.23222C10.7217 9.18618 10.6744 9.14239 10.626 9.10084C10.3118 8.83101 10.2758 8.3575 10.5456 8.04324C10.8154 7.72897 11.2889 7.69295 11.6032 7.96278C11.6804 8.02904 11.7555 8.09864 11.8284 8.17156C12.8267 9.16988 13.187 10.5646 12.9093 11.8491L16.2584 15.1981C16.5513 15.491 16.5513 15.9658 16.2584 16.2587C15.9655 16.5516 15.4906 16.5516 15.1977 16.2587L12.2591 13.3202C12.1319 13.4984 11.9884 13.6685 11.8284 13.8284L8.82843 16.8284C7.26633 18.3905 4.73367 18.3905 3.17157 16.8284C1.60948 15.2663 1.60948 12.7337 3.17157 11.1716L4.39591 9.94723C4.6888 9.65433 5.16367 9.65433 5.45657 9.94723C5.74946 10.2401 5.74946 10.715 5.45657 11.0079L4.23223 12.2322C3.25592 13.2085 3.25592 14.7914 4.23223 15.7678C5.20854 16.7441 6.79146 16.7441 7.76777 15.7678L10.7678 12.7678C10.9303 12.6052 11.0658 12.4258 11.1742 12.2353L8.5843 9.64533C8.69373 10.0565 8.90971 10.4452 9.23223 10.7678C9.27828 10.8138 9.32556 10.8576 9.37395 10.8991C9.68822 11.169 9.72424 11.6425 9.45441 11.9567C9.18457 12.271 8.71107 12.307 8.3968 12.0372C8.31963 11.9709 8.24449 11.9013 8.17157 11.8284C7.17342 10.8303 6.81307 9.43584 7.09052 8.15156L3.53044 4.59147C3.23754 4.29858 3.23754 3.8237 3.53044 3.53081C3.82333 3.23792 4.2982 3.23792 4.5911 3.53081L7.74056 6.68028C7.86783 6.50191 8.0115 6.33165 8.17157 6.17157L11.1716 3.17157C12.7337 1.60948 15.2663 1.60948 16.8284 3.17157C18.3905 4.73367 18.3905 7.26633 16.8284 8.82843L15.6041 10.0528C15.3112 10.3457 14.8363 10.3457 14.5434 10.0528C14.2505 9.75987 14.2505 9.28499 14.5434 8.9921L15.7678 7.76777C16.7441 6.79146 16.7441 5.20854 15.7678 4.23223Z"
      fill="currentColor"
    />
  </IconContainer>
);
