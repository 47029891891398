import { AxiosResponse } from 'axios';
import { PaginatedResults } from 'types/Query';
import { request } from 'clients/baseClient';
import {
  UserSignupWithEmailAndPassword,
  UserEdit,
  User,
  UserApi,
  GetUsersParams,
  UserInvite,
  UserInviteApi,
  WorkspaceApi,
  UserEditRole,
  WorkspaceTeamApi,
  ChangeWorkspaceParams,
} from './userClient.types';
import {
  mapInviteData,
  mapPostInviteData,
  mapSignUpData,
  mapUserCrudData,
  mapUserData,
  mapWorkspaceData,
  mapWorkspaceTeamData,
} from './userClient.formatter';


const usersApiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const signupWithEmailAndPassword = (data: UserSignupWithEmailAndPassword) => {
  return request({
    authenticate: false,
    options: {
      url: `${usersApiBaseUrl}/signup-multi-company/`,
      method: 'POST',
      data: mapSignUpData(data),
    },
  });
};

const signupWithSSO = (invitationToken: string) => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/signup-with-sso-multi-company/`,
      method: 'POST',
      data: {
        user_invitation_id: invitationToken,
      },
    },
  });
};

const signinWithSSO = (invitationToken?: string) => {
  const data = invitationToken ? { user_invitation_id: invitationToken } : undefined;
  return request({
    options: {
      url: `${usersApiBaseUrl}/signin-with-sso-multi-company/`,
      method: 'POST',
      data: data,
    },
  });
};

const getGroups = () => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/available-group/`,
      method: 'GET',
    },
  });
};

const getMe = (): Promise<AxiosResponse<User>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/me`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<UserApi>) => ({
    ...data,
    data: mapUserData(data.data),
  }));
};

const getUser = (params: { id: number | string }): Promise<AxiosResponse<User>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/${params.id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<UserApi>) => ({
    ...data,
    data: mapUserData(data.data),
  }));
};

const getUsers = (params: GetUsersParams): Promise<AxiosResponse<PaginatedResults<User>>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/`,
      method: 'GET',
      params: {
        type: params.type,
        company: params.company,
        search: params.search,
      },
    },
  }).then((data: AxiosResponse<PaginatedResults<UserApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapUserData),
    },
  }));
};

const editUser = (data: UserEdit): Promise<AxiosResponse<User>> => {
  return request({
    options: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${usersApiBaseUrl}/users/${data.id}/`,
      method: 'PUT',
      data: mapUserCrudData(data),
    },
  }).then((data: AxiosResponse<UserApi>) => ({
    ...data,
    data: mapUserData(data.data),
  }));
};

const editUserRole = (data: UserEditRole): Promise<AxiosResponse<User>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/workspace/team/${data.userWorkspaceId}/`,
      method: 'PATCH',
      data: {
        auth_group: data.authGroupId,
      },
    },
  });
};

const deleteUser = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/${params.id}/`,
      method: 'DELETE',
    },
  });
};

const inviteUser = (data: { email: string, authGroup: number; }): Promise<AxiosResponse<any>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/user-invitations-multi-company/`,
      method: 'POST',
      data: mapPostInviteData(data),
    },
  }).then((data: AxiosResponse<{ invitation_id: string }>) => ({
    ...data,
    data: {
      invitationId: data.data.invitation_id,
    },
  }));
};

const getInvite = (params: { id: string }): Promise<AxiosResponse<UserInvite>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/user-invitations-multi-company/${params.id}/`,
      method: 'GET',
    },
    authenticate: false,
  }).then((data: AxiosResponse<UserInviteApi>) => ({
    ...data,
    data: mapInviteData(data.data),
  }));
};

const resetPassword = (email: string) => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/reset-password/`,
      method: 'POST',
      data: {
        email: email,
      },
    },
  });
};

const inviteToWorkspace = (params: { id: string }) => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/signin-with-user-credentials-multi-company/`,
      method: 'POST',
      data: {
        user_invitation_id: params.id,
      },
    },
  });
};

const getWorkspace = (): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/workspace/team`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<{ results: WorkspaceTeamApi[] }>) => ({
    ...data,
    data: data.data.results.map((workspace) => mapWorkspaceTeamData(workspace)),
  }));
};


const getWorkspaces = (): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/workspaces`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<{ workspaces: WorkspaceApi[] }>) => ({
    ...data,
    data: data.data?.workspaces?.map((workspace) => mapWorkspaceData(workspace)),
  }));
};

const changeWorkspace = ({ workspaceId, socketId }: ChangeWorkspaceParams) => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/workspaces/switch/`,
      method: 'POST',
      data: {
        company_id: workspaceId,
        socket_id: socketId,
      },
    },
  });
};

const leaveWorkspace = (userWorkspaceId: number): Promise<any> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/workspace/team/${userWorkspaceId}/`,
      method: 'DELETE',
    },
  });
};

export const usersClient = {
  signupWithEmailAndPassword,
  signinWithSSO,
  signupWithSSO,
  getMe,
  getUser,
  getUsers,
  editUser,
  editUserRole,
  deleteUser,
  inviteUser,
  getInvite,
  resetPassword,
  getGroups,
  getWorkspace,
  getWorkspaces,
  changeWorkspace,
  leaveWorkspace,
  inviteToWorkspace,
};
