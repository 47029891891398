import React, { Fragment, FunctionComponent } from 'react';
import { Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { times } from 'lodash';


const BundleSectionSkeleton: FunctionComponent = () => {
  return (
    <Fragment>
      {times(4, (index) => (
        <Stack key={index} width="100%" spacing={3} mt={4} direction="row">
          <Skeleton
            sx={{ borderRadius: 4 }}
            variant="rounded"
            height="164px"
            width="280px"
          />
          <Stack spacing={2} flexGrow="1">
            <Skeleton variant="rounded" height={25} width="280px"/>
            <Stack spacing={2} direction="row">
              <Skeleton variant="rounded" height={15} width="120px"/>
              <Skeleton variant="rounded" height={15} width="120px"/>
            </Stack>
            <Skeleton variant="text" height={30} width="100%"/>
            <Skeleton variant="rounded" height={40} width="180px"/>
          </Stack>
          <Skeleton variant="rounded" height={30} sx={{ minWidth: 120 }}/>
        </Stack>
      ))}
    </Fragment>
  );
};

export default BundleSectionSkeleton;
