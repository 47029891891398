import { useQuery } from 'react-query';

import { integrationsClient } from 'modules/Expenses/client/integrationsClient';
import { Expenses } from 'modules/Expenses/expenses';
import { cacheKeys } from 'config';


type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useIntegrations = (params?: Expenses.Integrations.GetIntegrationsParams ,options: Partial<Options> = defaultOptions) => {

  const {
    data: { data: integrationsData } = {},
    isLoading,
  } = useQuery(
    [cacheKeys.integrations.getIntegrations, params],
    () => integrationsClient.getIntegrations(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    integrations: integrationsData?.results || [],
    integrationCount: integrationsData?.count || 0,
    isLoading,
  };
};
