import React from 'react';
import { Button, Chip, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { LocalizationContext } from 'contexts';
import { Drawers } from 'contexts/DrawerContext/types';
import { DrawerContext } from 'contexts/DrawerContext/DrawerContext';
import { EmissionFactors } from 'clients/emissionFactors/emissionFactorsClient.types';


interface RecommendationsCellProps {
  name: string;
}

const RecommendationsCell: React.FC<RecommendationsCellProps> = ({ name }) => {
  const { openDrawer, auxiliaryRef } = React.useContext(DrawerContext);

  const { dictionary } = React.useContext(LocalizationContext);
  const recommendation = React.useMemo(() => {
    return dictionary.recommendations[name as EmissionFactors]?.recommendation;
  }, [dictionary.recommendations, name]);

  const panelChangeHandler = React.useCallback(() => {
    if (recommendation) {
      auxiliaryRef.current = name;
      openDrawer(Drawers.recommendation);
    }
  }, [auxiliaryRef, name, openDrawer, recommendation]);

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      justifyContent="flex-end"
    >
      {recommendation ? (
        <React.Fragment>
          <Typography variant="categoryTable">{recommendation}</Typography>
          <Button
            size="small"
            color="info"
            onClick={panelChangeHandler}
          >
            {dictionary.measurements.categoryTable.cells.open}
          </Button>
        </React.Fragment>
      ) : (
        <Chip label={dictionary.measurements.categoryTable.cells.commingSoon} color="warning" size="small"/>
      )}
    </Stack>
  );
};

export default RecommendationsCell;
