import React, { useContext } from 'react';
import { useLocalStorage } from 'react-use';
import { Box } from '@mui/material';
import { Marketplace as MarketplaceTypes } from 'modules/Marketplace/marketplace';
import { useMarketplaceData } from 'modules/Marketplace/hooks/useMarketplaceData';
import { VendorTypes } from 'modules/Marketplace/helpers/general';
import { LocalizationContext } from 'contexts';

import { useEmissionFactorCategories } from 'shared/hooks/useEmissionFactorCategories';
import { FilterModules, FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { VendorList } from './VendorList';
import { MarketplaceFilters } from './MarketplaceFilters';


export const MarketplaceFilterTypes = {
  SUS: 'sustainabilityType',
  EF: 'focusArea',
  LOCATION: 'location',
};

const Marketplace: React.FunctionComponent = () => {
  const { dictionary } = useContext(LocalizationContext);

  const { emissionFactorCategories = [] } = useEmissionFactorCategories({ limit: 2000 });

  const [page = 0, setPage] = useLocalStorage<number>('marketplaceVendors-page');
  const [pageSize = 10, setPageSize] = useLocalStorage<number>('marketplaceVendors-pageSize');
  const [sustainabilityType, setSustainabilityType, removeSustainabilityType] = useLocalStorage<string>('marketplaceVendors-sustainabilityType');
  const [focusArea, setFocusArea, removeFocusArea] = useLocalStorage<string>('marketplaceVendors-focusAreas');
  const [location, setLocation, removeLocation] = useLocalStorage<string>('marketplaceVendors-locations');
  const [vendorType = VendorTypes.SUS, setVendorType] = useLocalStorage<string>('marketplaceVendors-vendorType', VendorTypes.SUS);

  const FilterFunctions = React.useMemo(() => ({
    [MarketplaceFilterTypes.SUS]: {
      set: setSustainabilityType,
      remove: removeSustainabilityType,
    },
    [MarketplaceFilterTypes.EF]: {
      set: setFocusArea,
      remove: removeFocusArea,
    },
    [MarketplaceFilterTypes.LOCATION]: {
      set: setLocation,
      remove: removeLocation,
    },
  }), [removeFocusArea, removeLocation, removeSustainabilityType, setFocusArea, setLocation, setSustainabilityType]);

  const allFilters = React.useMemo<MarketplaceTypes.Vendors.GetVendorsParams>(() => ({
    page,
    pageSize,
    sustainabilityType: vendorType === VendorTypes.SUS ? sustainabilityType : undefined,
    focusAreas: focusArea,
    locations: location,
    vendorType,
  }), [focusArea, location, page, pageSize, sustainabilityType, vendorType]);

  const {
    marketplaceVendors,
    vendorCount,
    isLoading,
    isEmptyState,
  } = useMarketplaceData(allFilters);

  const handleFilterChange = React.useCallback((filterType: string, value?: string) => {
    value ? FilterFunctions[filterType].set(value) : FilterFunctions[filterType].remove();
    setPage(0);
  }, [FilterFunctions, setPage]);

  const filteredChips = React.useMemo<{label: string, onDelete: () => void}[]>(() => (
    [
      {
        label: emissionFactorCategories.find((ef) => ef.id === focusArea)?.title,
        onDelete: FilterFunctions[MarketplaceFilterTypes.EF].remove,
      },
      {
        label: vendorType === VendorTypes.SUS && sustainabilityType ? sustainabilityType : '',
        onDelete: FilterFunctions[MarketplaceFilterTypes.SUS].remove,
      },
      { label: location || '', onDelete: FilterFunctions[MarketplaceFilterTypes.LOCATION].remove },
    ].filter((chip) => !!chip.label)
  ), [FilterFunctions, emissionFactorCategories, focusArea, location, sustainabilityType, vendorType]);

  return (
    <div className="full-page-layout">
      <FiltersSection
        filterModules={[
          FilterModules.YEAR,
        ]}
        title={dictionary.marketplace.title}
      >
        {({ filters }) => (
          <Box
            className="_d-grid-no-gap"
            sx={{
              gridTemplateColumns: '2fr minmax(300px, 1fr)',
              gridTemplateRows: 'minmax(0, 1fr)',
            }}
          >
            <VendorList
              vendors={marketplaceVendors}
              vendorCount={vendorCount}
              isLoading={isLoading}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              filteredChips={filteredChips}
              isEmptyState={isEmptyState}
              vendorType={vendorType}
              setVendorType={setVendorType}
            />
            <MarketplaceFilters
              filters={filters}
              sustainabilityType={sustainabilityType}
              vendorType={vendorType}
              focusArea={focusArea}
              location={location}
              handleFilterChange={handleFilterChange}
            />
          </Box>
        )}
      </FiltersSection>
    </div>
  );
};

export default Marketplace;
