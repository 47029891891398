import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { surveysClient } from 'modules/Surveys/client/surveysClient';
import { cacheKeys } from 'modules/Surveys/config';
import { LayoutContext } from 'contexts';


export const useSurveyCrud = () => {
  const { genericError } = React.useContext(LayoutContext);
  const queryClient = useQueryClient();

  const createAnswers = useMutation(surveysClient.createSurveyAnswers, {
    mutationKey: cacheKeys.createSurveyAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.getSurvey);
    },
    onError: () => {
      genericError();
    },
  });

  const editAnswers = useMutation(surveysClient.editSurveyAnswers, {
    mutationKey: cacheKeys.updateSurveyAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.getSurvey);
    },
    onError: () => {
      genericError();
    },
  });

  const deleteAnswer = useMutation(surveysClient.deleteAnswer, {
    mutationKey: cacheKeys.deleteAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.getSurvey);
    },
    onError: () => {
      genericError();
    },
  });

  return {
    createAnswers: createAnswers.mutateAsync,
    editAnswers: editAnswers.mutateAsync,
    deleteAnswer: deleteAnswer.mutateAsync,
  };
};
