import { Options } from './form.types';


export const getPercentageValue = (value: number, max: number) => (value/max)*100;

export const createGradientString = (
  options: Options,
  informationColors: any,
  rawValue: any,
  max: number,
): string => {
  let gradientString = 'repeating-linear-gradient(to right, ';
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    const { value, ...restOptions } = option;
    const [key] = Object.entries(restOptions)[0];
    if (i === 0) {
      gradientString += `${informationColors[key]} 0 ${getPercentageValue(rawValue[i], max)}%`;
    } else if (i === options.length - 1) {
      gradientString += `${informationColors[key]} ${getPercentageValue(rawValue[i - 1], max)}% 100%`;
    } else {
      gradientString += `${informationColors[key]} ${getPercentageValue(rawValue[i - 1], max)}% ${
        rawValue[i]
      }%`;
    }
    if (i < options.length - 1) {
      gradientString += ' , ';
    }
  }
  gradientString += ')';
  return gradientString;
};

export const createObject = (options: Options, values: number[]) => {
  if (options.length === 1) {
    return values[0];
  }
  const obj: { [key: string]: number } = {};
  let sum = 0;
  for (let i = 0; i < options.length; i++) {
    const { value, ...restOptions } = options[i];
    const [key] = Object.entries(restOptions)[0];
    obj[key] = (values[i] || values[i] === 0) ? values[i] - sum : 100 - sum;
    sum += obj[key];
  }
  return obj;
};

export const getOutput = (input: { [key: string]: number } | number, options: Options): number[] => {
  const output: number[] = [];
  if (typeof input === 'number') {
    output.push(input);
    return output;
  }
  let total = 0;
  options.forEach((item) => {
    const { value, ...restOptions } = item;
    const [key] = Object.entries(restOptions)[0];
    total += input[key];
    output.push(total);
  });

  output.pop();
  return output;
};