import React, { ReactElement, ReactNode } from 'react';
import { Box, Button, MenuItem } from '@mui/material';
import { Expenses } from 'modules/Expenses/expenses';
import { EyeIcon } from 'shared/icons/EyeIcon';
import { PencilIcon } from 'shared/icons/PencilIcon';
import { TrashIcon } from 'shared/icons/TrashIcon';
import { ActivateIcon } from 'shared/icons/ActivateIcon';
import { IntegrationStatus } from 'modules/Expenses/helpers/integrationOptions';
import { UnlinkIcon } from 'shared/icons/UnlinkIcon';
import { LocalizationContext } from 'contexts';
import { KababMenu } from 'shared/components/form/KababMenu/KababMenu';


export const ActionsCell = (props: ActionsCellProps) => {
  const {
    integration: {
      connectionStatus,
      id,
      name,
      status,
      type,
      transactionsCount,
    },
    openCleaning,
    openError,
    handleActivateIntegration,
    onDelete,
    onDisconnect,
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);

  const actions: ReactElement[] = [];

  if (type === 'chift' && connectionStatus === IntegrationStatus.ACTIVE && status !== IntegrationStatus.IMPORTING_TRANSACTIONS) {
    actions.push(...[
      <MenuItem key="disconnect" onClick={() => onDisconnect(id)}>
        <UnlinkIcon sx={{ mr: 1 }}/>
        {dictionary.expenses.linkedAccounting.disconnectBtn}
      </MenuItem>,
    ]);
  }

  if (![IntegrationStatus.UPLOAD, IntegrationStatus.IMPORTING_TRANSACTIONS].includes(status)) {
    actions.push(
      <MenuItem key="delete" onClick={() => onDelete(id)}>
        <TrashIcon sx={{ mr: 1 }}/>
        {status === IntegrationStatus.INACTIVE && !transactionsCount ? (
          dictionary.remove
        ) : (
          dictionary.expenses.linkedAccounting.deleteBtn
        )}
      </MenuItem>,
    );
  }

  let mainAction: ReactNode = null;
  switch (status) {
  case IntegrationStatus.NEED_REV:
    mainAction = (
      <Button variant="text" onClick={() => openCleaning(id, name, false)}>
        <EyeIcon/>
        {dictionary.review}
      </Button>
    );
    break;
  case IntegrationStatus.INACTIVE:
    mainAction = (
      <Button variant="text" onClick={() => handleActivateIntegration(id, name || '')}>
        <ActivateIcon/>
        {dictionary.activate}
      </Button>
    );
    break;
  case IntegrationStatus.ERROR:
    mainAction = (
      <Button variant="text" onClick={() => openError(id, name)}>
        <EyeIcon/>
        {dictionary.review}
      </Button>
    );
    break;
  case IntegrationStatus.UPLOAD:
  case IntegrationStatus.IMPORTING_TRANSACTIONS:
    break;
  default:
    mainAction = (
      <Button variant="text" onClick={() => openCleaning(id, name, true)}>
        <PencilIcon/>
        {dictionary.update}
      </Button>
    );
    break;
  }

  return (
    <Box className="_d-flex-jc-end-sm-gap" width="100%">
      {mainAction}
      <KababMenu closeOnClick items={actions}/>
    </Box>
  );
};

interface ActionsCellProps {
  integration: Expenses.Integrations.Integration;
  openCleaning: (id: number, fileName: string | null, isUpdate: boolean) => void;
  openError: (id: number, fileName: string | null) => void;
  onDelete: (id: number) => void;
  handleActivateIntegration: (integrationId: number, integrationName: string) => void;
  onDisconnect: (id: number) => void;
}