import React, { Fragment, FC, useCallback, ReactElement, ForwardedRef } from 'react';
import { styled } from '@mui/system';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';


export type FileUploaderProps = {
  name: string;
  value?: File[];
  onChange: (value: File[]) => void;
  label?: string;
  disabled?: boolean;
  inputProps?: {
    accept?: string;
    multiple?: boolean;
    ref: ForwardedRef<HTMLInputElement>;
  },
  buttonRender?: (props: LoadingButtonProps) => ReactElement;
  buttonProps?: Omit<LoadingButtonProps, 'disabled'>;
}

const HiddenInput = styled('input')({
  display: 'none',
});

export const FileUploader: FC<FileUploaderProps> = ({ name, label = 'Upload file', disabled, onChange, inputProps, buttonRender, buttonProps }) => {

  const handleFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    !disabled && onChange(e.target.files);
  }, [disabled, onChange]);

  return (
    <Fragment>
      <HiddenInput
        id={name}
        type="file"
        onChange={handleFileInput}
        value=""
        disabled={disabled}
        {...inputProps}
      />
      <label htmlFor={name}>
        {!!buttonRender ? buttonRender({
          ...buttonProps,
          disabled,
        }) : (
          <LoadingButton disabled={disabled} {...buttonProps} component="span">
            {label}
          </LoadingButton>
        )}
      </label>
    </Fragment>
  );
};
