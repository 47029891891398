// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Grid = (theme: Theme) => {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          minHeight: 'calc(100vh - 70px)',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.Survey-Forms': {
            gap: 24,
          },
        },
      },
    },
  };
};

export default Grid;
