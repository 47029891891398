import React from 'react';
import { Box, Chip, Link } from '@mui/material';
import { XMarkIcon } from 'shared/icons/XMarkIcon';
import { LocalizationContext } from 'contexts';


export const FilterChips = (props: FilterChipsProps) => {
  const {
    options = [],
    onChange,
    value,
    limit,
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);
  const [isExpanded, setIsExpanded] = React.useState(false);

  const optionsToShow = React.useMemo(() =>
    limit && !isExpanded ? options.slice(0, limit) : options
  , [isExpanded, limit, options]);

  return (
    <Box className="_d-flex-f-wrap-sm-gap">
      {optionsToShow.map((option) => (
        <FilterChip
          key={option}
          label={option}
          onClick={() => onChange(option === value ? undefined : option)}
          isSelected={option === value}
        />
      ))}
      {limit && (
        <Link
          sx={{ ml: 1, alignSelf: 'end' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ?
            dictionary.marketplace.filterOptions.showLess :
            dictionary.marketplace.filterOptions.showMore}
        </Link>
      )}
    </Box>
  );
};

export const FilterChip = (props: FilterChipProps) => {
  const {
    isSelected,
    ...rest
  } = props;

  if (!rest.label) return null;

  return (
    <Chip
      sx={{
        px: .5,
        py: '2px',
        height: 'unset',
        'span:first-letter': { textTransform: 'capitalize' },
      }}
      color={isSelected ? 'selected' : undefined}
      {...rest}
    />
  );
};

export const FilteredChip = (props: React.ComponentProps<typeof Chip>) => (
  <Chip
    sx={{
      borderRadius: '4px',
      'span:first-letter': { textTransform: 'capitalize' },
    }}
    color="primary"
    deleteIcon={<XMarkIcon/>}
    {...props}
  />
);

interface FilterChipsProps {
  options?: string[];
  onChange: (value?: string) => void;
  value?: string;
  limit?: number;
}

interface FilterChipProps extends React.ComponentProps<typeof Chip> {
    isSelected?: boolean;
}