// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Popover = (theme: Theme) => {
  return {
    MuiPopper: {
      defaultProps: {
        sx: {
          boxShadow: '0px 5px 15px -3px rgba(0, 0, 0, 0.1), 0px 2px 6px -4px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: '1px solid #E8E8E8',
          boxShadow: '0px 5px 15px -3px rgba(0, 0, 0, 0.1), 0px 2px 6px -4px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  };
};

export default Popover;
