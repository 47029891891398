import React, { useContext, Fragment } from 'react';
import { useMutation } from 'react-query';
import { Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { cacheKeys } from 'config';
import { LocalizationContext } from 'contexts';
import { offsettingClient } from 'clients/offsetting/offsettingClient';
import RightArrowBtn from 'shared/components/layout/Buttons/RightArrowBtn';


interface IPurchaseBundleItemProps {
    id: number,
    status: string,
    description: string

}

const PurchaseBundleItem: React.FunctionComponent<IPurchaseBundleItemProps> = ({ id, description, status }) => {

  const { dictionary } = useContext(LocalizationContext);

  const { mutateAsync: certificateMutate, isLoading } = useMutation(
    offsettingClient.getCertificate,
    {
      mutationKey: [cacheKeys.offset.getCertificate, id],
    },
  );

  return <Fragment key={id}>
    <Stack py={2} alignItems="center" gap={1.5} direction="row">
      <Typography fontSize={13} fontWeight={500}>
        {description}
      </Typography>
      {status === 'classified' ? (
        <RightArrowBtn
          color="secondary"
          loading={isLoading}
          onClick={async () => certificateMutate({ transactionId: id })}
          label={dictionary.carbonRemoval.purchases.certificate}
        />
      ) : (
        <Tooltip
          title={dictionary.carbonRemoval.purchases.certificateTooltip}
        >
          <Box>
            <RightArrowBtn
              color="secondary"
              disabled
              label={dictionary.carbonRemoval.purchases.certificate}
            />
          </Box>
        </Tooltip>
      )}
    </Stack>
  </Fragment> ;
};

export default PurchaseBundleItem;
