import React, { FunctionComponent, useContext, useMemo, useState, useRef } from 'react';
import { Box, Stack } from '@mui/system';
import {
  Typography,
  Tab,
  Tabs,
  Slide,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import format from 'date-fns/format';

import { line } from 'shared/styles/muiTheme';
import { round } from 'lodash';
import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { SurveyNames } from 'clients/surveys/survey.types';
import { useEmployeeCommutingData } from 'shared/hooks/EmployeeCommuting/useEmployeeCommutingData';
import { useDateFilters } from 'shared/hooks/useDateFilters';
import { Form } from 'shared/components/form/Form/Form';
import RightArrowBtn from 'shared/components/layout/Buttons/RightArrowBtn';

import { FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { Emissions } from 'shared/components/layout/Emissions/Emissions';
import { CategoryIcon } from 'shared/components/layout/CategoryIcon/CategoryIcon';
import { InformationToolTipContent } from 'shared/components/layout/Tooltip/InformationToolTipContent';
import {
  EmployeeCommutingSurveyYearsEnum,
} from './Employees.types';
import {
  a11yProps,
  createYupSchema,
  replaceValueOnStrings,
} from './EmployeesHelpers';
import ErrorPage from './components/ErrorPage';
import SurveySkeleton from './components/SurveySkeleton';


const Employees: FunctionComponent = () => {
  const { gteDate } = useDateFilters();
  const [ year, setYear ] = useState(format(new Date(gteDate), 'yyyy') as EmployeeCommutingSurveyYearsEnum);
  const { user } = useContext(UserContext);
  const containerRef = useRef(null);
  const { dictionary } = useContext(LocalizationContext);

  const params = useMemo(() => {
    return { year: format(new Date(year), 'yyyy-MM-dd'), surveyName: SurveyNames.employeeEmissions };
  }, [year]);

  const {
    isPieChartDataLoading,
    isFecthingEmployeeEmissionsData,
    statusPieChartDataCategories,
    survey,
    surveyQueryStatus,
    mutatePostSurveyForm,
    mutateUpdateSurveyForm,
    employeeEmissionsMappedData,
    allEmissions,
    surveyQueryKey,
  } = useEmployeeCommutingData(params, year);

  const {
    emissionFactorCategoriesColors,
    emissionFactorCategoriesIcons,
  } = useContext(LayoutContext);

  const replacedQuestions = useMemo(() =>
    survey?.questions?.questions &&
      user &&
      replaceValueOnStrings(survey?.questions.questions, [
        {
          // @ts-ignore
          keyStrToBeReplaced: 'label',
          company_name: user?.company?.name,
          year,
        },
      ]),
  [survey, user, year],
  );

  if (
    surveyQueryStatus === 'error' &&
    statusPieChartDataCategories === 'error'
  ) {
    return <ErrorPage queryKey={surveyQueryKey}/>;
  }

  const handleChange = (
    event: React.SyntheticEvent,
    year: EmployeeCommutingSurveyYearsEnum,
  ) => {
    setYear(year);
  };

  const onSubmit = async (answers: any) => {
    const submitPayload = { answers, year: params.year, surveyName: SurveyNames.employeeEmissions };
    if (survey!.surveyAnswers.length > 0) {
      return await mutateUpdateSurveyForm({ ...submitPayload, surveyAnswerId: survey?.surveyAnswers[0].id });
    }
    return await mutatePostSurveyForm(submitPayload);
  };

  const submitButton = (
    <RightArrowBtn
      sxProps={{ ml: 0, mt: 5 }}
      label={survey?.questions.submitLabel || dictionary.employees.submitBackupButton}
      type="submit"
      loading={isFecthingEmployeeEmissionsData && !isPieChartDataLoading}
    />
  );

  return (
    <div className="full-page-layout">
      <FiltersSection title={dictionary.employees.pageTitle}>
        {({ filters }) => (
          <React.Fragment>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'minmax(400px, 2fr) minmax(400px, 1fr)',
                gridTemplateRows: 'minmax(0, 1fr)',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  borderRight: `1px solid ${line}`,
                  overflow: 'auto',
                  pr: 5,
                  pt: 5,
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Typography color="#374151" fontSize="18px" fontWeight={600}>
                    {dictionary.employees.selectYear}
                  </Typography>
                  <Box
                    sx={{
                      display: 'inline-block',
                      minWidth: 0,
                    }}
                  >
                    <Tabs
                      value={year}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="2021" {...a11yProps(0)}/>
                      <Tab label="2022" {...a11yProps(1)}/>
                      <Tab label="2023" {...a11yProps(2)}/>
                    </Tabs>
                  </Box>
                </Stack>
                <TabContext value={year}>
                  <Box minWidth={595}>
                    {surveyQueryStatus === 'error' && <ErrorPage mt={15} mr={0} queryKey={surveyQueryKey}/>}
                    {(surveyQueryStatus === 'loading' || (surveyQueryStatus === 'success' && !replacedQuestions)) && (
                      <SurveySkeleton/>
                    )}
                    {replacedQuestions && (
                      <Box sx={{ overflow: 'hidden' }} ref={containerRef}>
                        {Object.values(EmployeeCommutingSurveyYearsEnum).map(
                          (enumYear) => (
                            <Slide
                              timeout={500}
                              easing="ease-out"
                              container={containerRef.current}
                              direction="left"
                              in={enumYear === year}
                              key={enumYear}
                            >
                              <TabPanel sx={{ p: 0, pt: 4 }} key={enumYear} value={enumYear}>
                                <Box className="employee-form-container">
                                  <Form
                                    className="Survey-Forms"
                                    containerProps={{ width: 645 }}
                                    onSubmit={onSubmit}
                                    inputList={replacedQuestions!}
                                    submitButton={submitButton}
                                    validationSchema={createYupSchema(
                                      survey!.questions.validationSchemaFrontend,
                                    )}
                                    formOptions={{
                                      defaultValues:
                                        survey?.surveyAnswers[0]?.answers ||
                                        survey?.questions.defaultValues,
                                    }}
                                  />
                                </Box>
                              </TabPanel>
                            </Slide>
                          ),
                        )}
                      </Box>
                    )
                    }
                  </Box>
                </TabContext>
              </Box>

              <Box pt={5} pl={5} overflow="auto">
                <Box className="_d-flex-sm-gap-ali-center" mb={5}>
                  <Typography variant="h4" color="text.secondary">
                    {dictionary.employees.emissionBoxes.totalEmployeeEmission}
                  </Typography>
                  <InformationToolTipContent
                    tooltipTitle={dictionary.employees.emissionBoxes.totalEmployeeEmissionTooltip}
                  />
                </Box>
                {!employeeEmissionsMappedData?.length ? (
                  <Typography variant="h1" color="text.tertiary">{dictionary.employees.emissionBoxes.emptyState}</Typography>
                ) : (
                  <>
                    <Typography variant="h2" fontSize={52} lineHeight={1} mb={5}>
                      <Emissions kg={allEmissions}/>
                    </Typography>

                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        rowGap: 3,
                      }}
                    >
                      <Typography variant="tableTitle" p={1} >
                        {dictionary.measurements.categoryTable.headers.category}
                      </Typography>
                      <Typography variant="tableTitle" p={1}>
                        {dictionary.measurements.categoryTable.headers.impact}
                      </Typography>

                      {employeeEmissionsMappedData.map(
                        ({ name, value, co2Percentage, transactionCount }) => (
                          <React.Fragment key={name}>
                            <Box className="_d-flex-sm-gap" px={1}>
                              <CategoryIcon
                                icon={emissionFactorCategoriesIcons[name]}
                                name={name}
                                color={emissionFactorCategoriesColors[name]}
                              />
                              <div>
                                <Typography variant="h6">{name}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                  {dictionary.measurements.transactionCount(transactionCount)}
                                </Typography>
                              </div>
                            </Box>
                            <Box px={1}>
                              <Typography variant="h6">
                                <Emissions showBoldValue={false} kg={value} showCo2={false}/>
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {round(co2Percentage, 2)}%
                              </Typography>
                            </Box>
                          </React.Fragment>
                        ))}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </React.Fragment>
        )}
      </FiltersSection>
    </div>
  );
};

export default Employees;
