import React from 'react';
import { Stack, Typography } from '@mui/material';

import CloudIcon from 'shared/components/layout/Icons/CloudIcon';
import LockClosedIcon from 'shared/components/layout/Icons/LockClosedIcon';

import { IBundleCategoryIconProps } from './CarbonRemovalTypes';



const iconProps = { sx: { color: '#E5E7EB', fontSize: '16px' }, stroke: '#374151', strokeWidth: 2 };

const BundleCategoryIcon: React.FunctionComponent<IBundleCategoryIconProps> = ({
  icon,
  title,
}) => {
  return (
    <Stack gap="5.5px" sx={{ background: '#E5E7EB' }} borderRadius="4px" py={0.2} px={0.6} alignItems="center" direction="row">
      {icon === 'cloud' ? (
        <CloudIcon {...iconProps}/>
      ) : (
        <LockClosedIcon {...iconProps}/>
      )}
      <Typography fontSize={11} fontWeight={500}>{title}</Typography>
    </Stack>
  );
};

export default BundleCategoryIcon;
