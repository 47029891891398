import React from 'react';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';


export const CategoryIcon = (props: CategoryIconProps) => {
  const { icon = grey[300], name, color, size = 20 } = props;

  const iconSize = size - 4;

  return (
    <Box
      className="_d-flex-ali-center-jc-center"
      sx={{
        background: color,
        borderRadius: '4px',
        width: size,
        height: size,
      }}
    >
      {icon && (
        <Box
          component="img"
          height={iconSize}
          width={iconSize}
          src={icon}
          alt={name}
        />
      )}
    </Box>
  );
};

interface CategoryIconProps {
    icon?: string;
    name: string;
    color?: string;
    size?: number;
}