import React from 'react';
import { Box, Typography } from '@mui/material';
import { BuildingLibraryIcon } from 'shared/icons/BuildingLibraryIcon';
import { CircleStackIcon } from 'shared/icons/CircleStackIcon';
import { ShieldCheckIcon } from 'shared/icons/ShieldCheckIcon';
import { primary } from 'shared/styles/muiTheme';
import { LocalizationContext } from 'contexts';


export const PermissionsStep = () => {
  const { dictionary } = React.useContext(LocalizationContext);

  return (
    <React.Fragment>
      <Box className="_d-flex-md-gap" mb={3}>
        <BuildingLibraryIcon/>
        <Box flex={1}>
          <Typography variant="h4" mb={0.5}>{dictionary.expenses.uploadIntegration.permissions.details}</Typography>
          <Typography variant="body1">{dictionary.expenses.uploadIntegration.permissions.detailsText}</Typography>
        </Box>
      </Box>
      <Box className="_d-flex-md-gap" mb={7}>
        <CircleStackIcon/>
        <Box flex={1}>
          <Typography variant="h4" mb={0.5}>{dictionary.expenses.uploadIntegration.permissions.transactions}</Typography>
          <Typography variant="body1">{dictionary.expenses.uploadIntegration.permissions.transactionsText}</Typography>
        </Box>
      </Box>

      <Typography variant="h4" mb={3}>{dictionary.expenses.uploadIntegration.permissions.howStoreTitle}</Typography>
      {dictionary.expenses.uploadIntegration.permissions.howStoreText.map((text, i) => (
        <Box className="_d-flex-md-gap" mb={2} key={i}>
          <ShieldCheckIcon sx={{ color: primary }}/>
          <Typography variant="body1" sx={{ textWrap: 'balance', flex: 1 }}>{text}</Typography>
        </Box>
      ))}
    </React.Fragment>
  );
};