// ** MUI Imports
import { Theme } from '@mui/material/styles';

// Extend color prop on components
declare module '@mui/material/Chip' {
  export interface ChipPropsColorOverrides {
    selected: true
  }
}

const Chip = (theme: Theme) => {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          '& svg.MuiChip-deleteIcon, & svg.MuiChip-deleteIcon:hover': {
            color: 'inherit',
          },
        },
        sizeMedium: {
          height: 24,
        },
        sizeSmall: {
          height: 18,
        },
        colorSuccess: {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.success.main,
        },
        colorWarning: {
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.warning.main,
        },
        colorPrimary: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
        },
        colorError: {
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.main,
        },
        colorSelected: {
          backgroundColor: theme.palette.primary.main,
          color: '#fff',
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: '#fff',
          },
        },
      },
    },
  };
};

export default Chip;
