import React from 'react';
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListProps,
  Skeleton,
  Stack,
  Typography,
  darken,
  lighten,
} from '@mui/material';

import { Emissions } from 'modules/Emissions/emissions';
import { useElasticEmissionFactorCategories } from 'modules/Emissions/hooks/useElasticEmissionFactorCategories';
import { LayoutContext, LocalizationContext } from 'contexts';
import { Transaction } from 'clients/transactions/transactionClient.types';
import { ReactComponent as NotFound } from 'shared/img/notfound.svg';
import { OverflowTooltip } from 'shared/components/OverflowTooltip';


export type Options = {
  transactions: Transaction[];
};

interface Props extends Omit<ListProps, 'onSelect'> {
  filters: Emissions.Categories.GetElasticEmissionFactorCategoryListParams;
  category?: number;
  autoSelect?: boolean;
  onSelect: (category: Emissions.Categories.ElasticEmissionFactorCategory) => void;
  showParentCategory?: boolean;
  items?: {
    title: string;
    selected: boolean;
    onClick?: () => void;
  }[];
};

export const TransactionClassificationCategoryList: React.FC<Props> = ({ filters, category, autoSelect = true, showParentCategory = false, onSelect, items, ...props }) => {

  const { dictionary } = React.useContext(LocalizationContext);
  const { emissionFactorCategoriesColors } = React.useContext(LayoutContext);
  const { status, categories } = useElasticEmissionFactorCategories(filters);

  React.useEffect(() => {
    if (autoSelect && !category && categories.length > 0) {
      onSelect(categories[0]);
    }
  }, [autoSelect, categories, category, onSelect]);

  if (status === 'loading') {
    return (
      <List {...props}>
        <ListItem><Skeleton variant="rounded" width={160}/><Box ml={1}/><Skeleton variant="rounded" width={30}/></ListItem>
        <ListItem><Skeleton variant="rounded" width={140}/><Box ml={1}/><Skeleton variant="rounded" width={40}/></ListItem>
        <ListItem><Skeleton variant="rounded" width={200}/><Box ml={1}/><Skeleton variant="rounded" width={50}/></ListItem>
        <ListItem><Skeleton variant="rounded" width={120}/><Box ml={1}/><Skeleton variant="rounded" width={30}/></ListItem>
      </List>
    );
  }

  if (status === 'success' && categories.length === 0) {
    return (
      <Stack width="100%" height="100%" justifyContent="center" alignItems="center" textAlign="center" spacing={1}>
        <Box mb={1}><NotFound/></Box>
        <Typography variant="h4">{dictionary.transaction.classify.noEmissionFactorsFound}</Typography>
        <Typography variant="body1" color="grey.600">{dictionary.transaction.classify.noEmissionFactorsFoundMessage}</Typography>
      </Stack>
    );
  }

  return (
    <List {...props}>
      {items?.map(item => (
        <ListItemButton
          key={item.title}
          onClick={item.onClick}
          selected={item.selected}
        >
          <OverflowTooltip placement="right">
            {item.title}
          </OverflowTooltip>
        </ListItemButton>
      ))}
      {categories.map(c => (
        <ListItemButton
          key={c.id}
          onClick={() => onSelect(c)}
          selected={c.id === category}
        >
          <Stack direction="row" spacing={1} width="100%" alignItems="center">
            <OverflowTooltip placement="right">
              {c.title}
            </OverflowTooltip>
            {showParentCategory && !!c.parent && (
              <Chip
                size="small"
                component="span"
                label={(
                  <OverflowTooltip placement="right">
                    {c.parent.parent?.title || c.parent.title}
                  </OverflowTooltip>
                )}
                sx={{
                  display: 'inline-grid',
                  backgroundColor: lighten(emissionFactorCategoriesColors[c.parent.parent?.title || c.parent.title], 0.7),
                  color: darken(emissionFactorCategoriesColors[c.parent.parent?.title || c.parent.title], 0.3),
                }}
              />
            )}
          </Stack>
        </ListItemButton>
      ))}
    </List>
  );
};