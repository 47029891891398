import React, { FC } from 'react';
import { IconContainer } from './IconContainer';


export const ArrowPath: FC<React.ComponentProps<typeof IconContainer>> = ({
  ...props
}) => (
  <IconContainer {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3124 11.4243C14.5262 14.3584 11.5104 16.0996 8.5763 15.3134C7.60728 15.0537 6.77024 14.5521 6.11052 13.8912L5.79898 13.5796L8.23221 13.5792C8.64642 13.5791 8.98215 13.2433 8.98208 12.829C8.982 12.4148 8.64616 12.0791 8.23194 12.0792L3.98851 12.0799C3.78959 12.08 3.59884 12.159 3.45821 12.2997C3.31758 12.4404 3.2386 12.6312 3.23864 12.8301L3.23954 17.0719C3.23962 17.4861 3.57548 17.8219 3.98969 17.8218C4.40391 17.8217 4.73962 17.4858 4.73954 17.0716L4.73903 14.641L5.04888 14.9508C5.88991 15.7934 6.95759 16.4326 8.18808 16.7623C11.9223 17.7629 15.7607 15.5468 16.7613 11.8125C16.8685 11.4124 16.6311 11.0012 16.231 10.894C15.8309 10.7868 15.4196 11.0242 15.3124 11.4243ZM16.5413 7.70192C16.682 7.56124 16.761 7.37045 16.7609 7.17151L16.7603 2.92956C16.7602 2.51535 16.4243 2.17962 16.0101 2.17969C15.5959 2.17976 15.2602 2.5156 15.2603 2.92981L15.2606 5.36068L14.9508 5.05086C14.1098 4.20845 13.0419 3.569 11.8115 3.23932C8.07728 2.23873 4.23892 4.45481 3.23833 8.18907C3.13112 8.58917 3.36856 9.00042 3.76866 9.10763C4.16876 9.21483 4.58001 8.9774 4.68722 8.5773C5.4734 5.64323 8.48925 3.90203 11.4233 4.68821C12.3924 4.94787 13.2294 5.44954 13.8892 6.11052L14.2003 6.42164L11.7683 6.42164C11.3541 6.42164 11.0183 6.75742 11.0183 7.17164C11.0183 7.58585 11.3541 7.92164 11.7683 7.92164H16.0109C16.2099 7.92164 16.4007 7.8426 16.5413 7.70192Z"
      fill="currentColor"
    />
  </IconContainer>
);
