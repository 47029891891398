import { AxiosResponse } from 'axios';
import { snakizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';

import { ReportPrompts } from './reportClient.types';


const apiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getReport = (data: ReportPrompts): Promise<AxiosResponse<Blob>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/reporting/docx-reporting/`,
      method: 'POST',
      data: snakizeObject(data),
      responseType: 'blob',
    },
  });
};

export const reportClient = {
  getReport,
};
