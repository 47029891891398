import React, { ReactElement } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import {
  CsvColumnOptions,
  UploadIntegrationCsvSteps,
  IntegrationSteps,
} from 'modules/Expenses/helpers/integrationOptions';
import { isEmpty } from 'lodash';
import { FileOrIntegrationName } from 'modules/Expenses/components/FileOrIntegrationName';
import { LocalizationContext } from 'contexts';
import { UploadCsvSteps, useUploadCsvWizard } from 'shared/hooks/useUploadCsvWizard';
import { CoolsetPageLoader } from 'shared/components/layout/CoolsetLoader/CoolsetLoader';
import { UploadFileStep } from './Steps/UploadFileStep';
import { PreviewDataStep } from './Steps/PreviewDataStep';
import { SelectColumnsStep } from './Steps/SelectColumnsStep';
import { UploadDoneStep } from './Steps/UploadDoneStep';


export const CsvColOptionOrder = CsvColumnOptions().map((opt) => opt.key);

export const UploadCsvWizard = (props: UploadIntegrationWizardProps) => {
  const { dictionary } = React.useContext(LocalizationContext);

  const {
    uploadCsvWizardStore: {
      isUploadCsvDialogOpen: isOpen,
      nextStep,
      previousStep,
      wizardStep,
      fileUploaded,
      setFileUploaded,
      setFileText,
      fileColumnsPreview,
      setFileColumnsPreview,
      rowsNumPreview,
      setRowsNumPreview,
      isFirstRowHeaders,
      headerIndexes,
      setHeaderIndexes,
      loaderText,
      isLoading,
      handleOnClose,
      handleIsFirstRowHeadersChange,
      errors,
      wizardStepActions,
      columnOptions,
      uploadFileStepProps,
    },
    stepTitle = UploadCsvSteps,
    stepSubTitle = UploadCsvSteps,
  } = props;

  const isShowJustStepComponent = [UploadCsvSteps.UPLOAD_DONE].includes(wizardStep);

  const isReviewErrors = wizardStep === UploadCsvSteps.SELECT_COLS && !fileColumnsPreview.length;

  const wizardStepComponent = React.useMemo<ReactElement>(() => {
    switch (wizardStep) {
    case UploadCsvSteps.UPLOAD_FILE:
      return (
        <UploadFileStep
          fileUploaded={fileUploaded}
          isFirstRowHeaders={isFirstRowHeaders}
          setFileText={setFileText}
          setFileColumnsPreview={setFileColumnsPreview}
          setRowsNumPreview={setRowsNumPreview}
          setFileUploaded={setFileUploaded}
          templateUrl={uploadFileStepProps?.templateUrl || ''}
        />
      );
    case UploadCsvSteps.PREVIEW:
      return (
        <PreviewDataStep
          previewColumns={fileColumnsPreview}
          isFirstRowHeaders={isFirstRowHeaders}
          setIsFirstRowHeaders={handleIsFirstRowHeadersChange}
          rowsNum={rowsNumPreview}
        />
      );
    case UploadCsvSteps.SELECT_COLS:
      return (
        <SelectColumnsStep
          previewColumns={fileColumnsPreview}
          headerIndexes={headerIndexes}
          setHeaderIndexes={setHeaderIndexes}
          errors={errors}
          isFirstRowHeaders={isFirstRowHeaders}
          columnOptions={columnOptions}
        />
      );
    case UploadCsvSteps.UPLOAD_DONE:
      return <UploadDoneStep onClose={() => handleOnClose()} onNextStep={nextStep}/>;
    default:
      return <>something went wrong</>;
    }
  }, [
    wizardStep,
    fileUploaded,
    isFirstRowHeaders,
    setFileText,
    setFileColumnsPreview,
    setRowsNumPreview,
    setFileUploaded,
    uploadFileStepProps,
    fileColumnsPreview,
    handleIsFirstRowHeadersChange,
    rowsNumPreview,
    headerIndexes,
    setHeaderIndexes,
    errors,
    columnOptions,
    nextStep,
    handleOnClose,
  ]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleOnClose()}
      PaperProps={{
        sx: {
          minWidth: 'min(800px, 80vw)',
          minHeight: [UploadIntegrationCsvSteps.INTRO, IntegrationSteps.INTRO].includes(wizardStep)
            ? 'unset'
            : 'min(680px, 80vh)',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          position: 'relative',
          borderRadius: 1,
          py: 5,
          '&:has(.hide-dialog-file-name) .dialog-file-name': {
            display: 'none',
          },
        },
      }}
    >
      <CoolsetPageLoader isOpen={isLoading} text={loaderText} animationSetting={{ fadeInOut: true }}/>

      {!isShowJustStepComponent ? (
        <Box px={5}>
          <FileOrIntegrationName fileName={fileUploaded?.name}/>

          <Typography variant="h1" mb={1}>
            {isReviewErrors ? dictionary.expenses.uploadIntegration.stepTitle.error : stepTitle[wizardStep]}
          </Typography>
          {dictionary.expenses.uploadIntegration.stepSubTitles[wizardStep] && (
            <Typography variant="body1" mb={5}>
              {isReviewErrors ? dictionary.expenses.uploadIntegration.stepSubTitles.error : stepSubTitle[wizardStep]}
            </Typography>
          )}
        </Box>
      ) : (
        <div/>
      )}

      <Box overflow="auto" px={5}>
        {wizardStepComponent}
      </Box>

      {!isShowJustStepComponent && !isEmpty(wizardStepActions[wizardStep]) ? (
        <Box className="_d-flex-ali-center-jc-sb-md-gap" px={5} pt={5}>
          <Typography variant="body1">{/* TODO - get help link with video */}</Typography>
          <Box className="_d-flex-ali-center-md-gap">
            {wizardStepActions[wizardStep]?.back && (
              <Button onClick={wizardStepActions[wizardStep].back?.onClick || previousStep} color="info">
                {wizardStepActions[wizardStep].back?.title}
              </Button>
            )}
            {wizardStepActions[wizardStep].forward && (
              <Button
                onClick={wizardStepActions[wizardStep].forward?.onClick || nextStep}
                disabled={wizardStepActions[wizardStep].forward?.isDisabled}
              >
                {wizardStepActions[wizardStep].forward?.title}
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <div/>
      )}
    </Dialog>
  );
};

interface UploadIntegrationWizardProps {
  uploadCsvWizardStore: ReturnType<typeof useUploadCsvWizard>;
  stepTitle?: {[key: string]: string};
  stepSubTitle?: {[key: string]: string};
}
