import React from 'react';
import TextTruncate from 'react-text-truncate';
import {
  Link,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import { Transactions } from 'modules/Transactions/transactions';
import { LocalizationContext } from 'contexts';
import { Transaction } from 'clients/transactions/transactionClient.types';


export type Options = {
  transactions: Transaction[];
};

interface Props extends Transactions.Vendors.Insights.Insight {};

export const VendorInsightsListItem: React.FC<Props> = insight => {

  const { dictionary } = React.useContext(LocalizationContext);

  const [showFullText, setShowFullText] = React.useState(false);

  const handleReadMore = () => {
    setShowFullText(true);
  };

  const handleReadLess = () => {
    setShowFullText(false);
  };

  return (
    <ListItem>
      <ListItemText
        disableTypography
        primary={<Typography variant="h6">{insight.name}</Typography>}
        secondary={showFullText ? (
          <React.Fragment>
            <Typography variant="body1">{insight.detailedDescription || insight.description}</Typography>
            <Link onClick={handleReadLess} underline="hover">{dictionary.transaction.classify.buttonInsightLess}</Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TextTruncate
              line={3}
              element="div"
              truncateText="..."
              text={insight.detailedDescription || insight.description}
              textTruncateChild={<Link onClick={handleReadMore} underline="hover">{dictionary.transaction.classify.buttonInsightMore}</Link>}
            />
          </React.Fragment>
        )}
      />
    </ListItem>
  );
};