import { AxiosResponse } from 'axios';

import { Transactions } from 'modules/Transactions/transactions';
import { camelizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';


const emissionsApiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getVendorInsights = async (params: Transactions.Vendors.Insights.GetListParams): Promise<AxiosResponse<Transactions.Vendors.Insights.Insight[]>> => {
  let response: AxiosResponse<Transactions.Vendors.Insights.InsightApi[]>;
  if (params.transactionIds) {
    response = await request({
      options: {
        url: `${emissionsApiBaseUrl}/transactions/vendor-insights/`,
        method: 'GET',
        params: {
          transaction_ids: params.transactionIds,
        },
      },
    });
  } else {
    response = await request({
      options: {
        url: `${emissionsApiBaseUrl}/transactions/vendor-insights-filtered/`,
        method: 'POST',
        data: {
          filters: params?.filters || [],
        },
      },
    });
  }

  return {
    ...response,
    data: response.data.map(camelizeObject<Transactions.Vendors.Insights.Insight>),
  };
};

export const transactionsClient = {
  getVendorInsights,
};
