import React from 'react';
import { useQueryClient } from 'react-query';
import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  PopoverProps,
} from '@mui/material';
import { format, getYear } from 'date-fns';

import { ChartBarIcon } from 'shared/icons/ChartBar';
import { Intensity } from 'modules/Emissions/enums';
import { cacheKeys } from 'modules/Surveys/config';
import { useSurvey } from 'modules/Surveys/hooks/useSurvey';
import { CogIcon } from 'shared/icons/CogIcon';
import { DialogContext, LocalizationContext } from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';


type Option = {
  value: string;
  title: string;
  onClick?: (option: Option) => void;
  disabled?: boolean;
}

type IntensitySelectProps = {
  readonly value?: string;
  readonly onChange: (option: string) => void;
  readonly year?: string;
  readonly gteDate?: Date;
  readonly lteDate?: Date;
};

export const IntensitySelect: React.FC<IntensitySelectProps> = ({
  value = 'absolute',
  onChange,
  year,
  gteDate,
  lteDate,
}) => {
  const { dictionary } = React.useContext(LocalizationContext);
  const { openDialog } = React.useContext(DialogContext);
  const queryClient = useQueryClient();

  const [openOptions, setOpenOptions] = React.useState<boolean>(false);
  const containerRef = React.useRef(null);

  const yearValue = React.useMemo(() => {
    let yearValue = year;
    if (gteDate && lteDate) {
      const gteYear = getYear(gteDate);
      const lteYear = getYear(lteDate);

      if (gteYear === lteYear) {
        yearValue = gteYear.toString();
      }
    }
    return yearValue;
  }, [year, lteDate, gteDate]);

  const params = React.useMemo(() => {
    if (!yearValue) {
      return {};
    }
    return { year: format(new Date(yearValue), 'yyyy-MM-dd'), name: 'emission_intensity' };
  }, [yearValue]);

  const { status, survey } = useSurvey(params, !!yearValue);

  const hasAnswer = React.useCallback((questionName: string) => {
    const questionId = survey?.surveyQuestions.find(question => question.name === questionName)?.id;
    if (questionId) {
      const answerId = survey.surveyAnswers.find(answer => answer.question === questionId)?.id;
      return !!answerId;
    }
    return false;
  }, [survey]);

  const hasEmployeeCountData = React.useMemo(() => {
    return hasAnswer('employee_count');
  }, [hasAnswer]);

  const hasTotalRevenueData = React.useMemo(() => {
    return hasAnswer('total_revenue');
  }, [hasAnswer]);

  const handleClose = React.useCallback(() => {
    setOpenOptions(false);
  }, []);

  const handleChange = React.useCallback((option: string) => {
    onChange(option);
    handleClose();
  }, [onChange, handleClose]);

  const handleDialogChange = React.useCallback(async (option: string) => {
    await queryClient.invalidateQueries([cacheKeys.getSurvey, params]);
    handleChange(option);
  }, [queryClient, params, handleChange]);

  const handleOpenOption = () => setOpenOptions(true);

  const selectOptions: Option[] = React.useMemo(() => {
    return [{
      value: Intensity.absolute,
      title: dictionary.filterSection.intensity.absolute,
    }, {
      value: Intensity.perEmployee,
      title: dictionary.filterSection.intensity.perEmployee,
      onClick: hasEmployeeCountData ? undefined : () => openDialog(Dialog.CompanyOverview, { onSubmitCompleted: () => handleDialogChange(Intensity.perEmployee) }),
      disabled: !yearValue,
    }, {
      value: Intensity.perRevenueUnit,
      onClick: hasTotalRevenueData ? undefined : () => openDialog(Dialog.CompanyOverview, { onSubmitCompleted: () => handleDialogChange(Intensity.perRevenueUnit) }),
      title: dictionary.filterSection.intensity.perRevenueUnit,
    }];
  }, [dictionary, hasEmployeeCountData, hasTotalRevenueData, openDialog, yearValue, handleDialogChange]);

  const popoverProps = {
    anchorEl: containerRef.current,
    sx: { mt: 1 },
    onClose: handleClose,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
  } as Omit<PopoverProps, 'open'>;

  React.useEffect(() => {
    if (status === 'success') {
      if (!hasEmployeeCountData && value === Intensity.perEmployee) {
        onChange(Intensity.absolute);
      }
      if (!hasTotalRevenueData && value === Intensity.perRevenueUnit) {
        onChange(Intensity.absolute);
      }
    }
  }, [hasEmployeeCountData, hasTotalRevenueData, onChange, value, status]);

  return (
    <React.Fragment>
      <Button
        ref={containerRef}
        onClick={handleOpenOption}
        color="secondary"
      >
        <ChartBarIcon/>
        {selectOptions.find(option => option.value === value)?.title}
      </Button>

      <Popover
        open={openOptions}
        {...popoverProps}
      >
        <Box p={1}>
          {selectOptions.map((option) => (
            <MenuItem
              onClick={() => option.onClick ? option.onClick(option) : handleChange(option.value)}
              key={option.value}
              selected={option.value === value}
              disabled={option.disabled}
            >
              <ListItemText>{option.title}</ListItemText>
            </MenuItem>
          ))}
          <Divider/>
          <MenuItem
            onClick={() => [setOpenOptions(false), openDialog(Dialog.CompanyOverview)]}
          >
            <ListItemIcon sx={{ color: 'primary.main' }}>
              <CogIcon/>
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: 'primary', fontWeight: 500 }}>{dictionary.filterSection.intensity.settings}</ListItemText>
          </MenuItem>
        </Box>
      </Popover>
    </React.Fragment>
  );
};