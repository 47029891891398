import { FC, useContext, useMemo } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { isEmpty } from 'lodash';

import { ClockIcon } from 'shared/icons/ClockIcon';
import { CongratsIcon } from 'shared/icons/CongratsIcon';
import { LayoutContext, LocalizationContext } from 'contexts';
import { MessageFeedback } from 'shared/components/layout/Feedback/MessageFeedback';
import { ProjectionTableRow } from './ProjectionTableRow';


interface Props {
  table: any;
  years: number[];
}

export const ProjectionTable: FC<Props> = ({ table, years }) => {
  const { emissionFactorCategoriesColors } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);

  const isTableEmpty = useMemo(() => {
    if (table.categories) {
      const keys = Object.keys(table?.categories);
      const values = table.categories.total.values;
      return !!(keys.length <= 1 || values.every((val: any) => !val.value));
    }
    return false;
  }, [table]);

  const mappedTableData = useMemo(() => {
    if (isEmpty(table)) {
      return table;
    }

    return {
      ...table,
      categories: Object.keys(table?.categories).reduce((accumulator, category) => ({
        ...accumulator,
        [category]: {
          ...table?.categories[category],
          color: !isEmpty(table?.categories[category]?.subcategories) ? emissionFactorCategoriesColors?.[category] || '#000' : undefined,
        },
      }), {}),
    };
  }, [table, emissionFactorCategoriesColors]);

  const hasEmissions = useMemo(() => !!table?.categories, [table]);

  return (
    <Box position="relative" minHeight={300}>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>{dictionary.scenarioBuilder.category}</TableCell>
              {years.map((year: number) => (
                <TableCell key={year} sx={{ border: 'none', color: 'inherit', fontSize: 'inherit', minWidth: 82 }} align="center">
                  {year}
                </TableCell>
              ))}
              <TableCell align="center">{dictionary.scenarioBuilder.projectedReduction}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isTableEmpty && hasEmissions && Object.keys(mappedTableData?.categories || {})?.map((categoryName: any) => (
              <ProjectionTableRow
                key={categoryName}
                row={mappedTableData.categories[categoryName]}
                categoryName={categoryName}
                baselineYear={mappedTableData.baseline_year}
                projectedReduction={mappedTableData.projected_reduction}
                years={years}
              />
            ))}
          </TableBody>
        </Table>
        {(isTableEmpty || !hasEmissions) && (
          <Box className="_d-flex jc-center" position="absolute" width="100%" top={0} bottom={40}>
            <MessageFeedback
              icon={isTableEmpty ? <ClockIcon/> : <CongratsIcon/> }
              message={isTableEmpty ? dictionary.home.dashboard.noAnalysis : dictionary.home.dashboard.noEmissions}
            />
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};
