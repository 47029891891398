import { FunctionComponent, forwardRef, ReactNode } from 'react';
import { Box, SxProps, BoxProps } from '@mui/system';
import { Tooltip, Typography } from '@mui/material';

import { greyTextColor } from 'shared/styles/muiTheme';


interface InformationToolTipContentProps {
  containerProps?: BoxProps;
  infoStyleIconProps?: SxProps;
  customComponentBefore?: ReactNode;
  customComponentAfter?: ReactNode;
  isBgDarker?: boolean;
  tooltipTitle?: ReactNode;
  tooltipProps?: Omit<React.ComponentProps<typeof Tooltip>, 'children' | 'title'>;
}

export const InformationToolTipContent: FunctionComponent<InformationToolTipContentProps> =
  forwardRef(function InformationToolTipContent(props, ref) {
    const {
      containerProps,
      customComponentBefore,
      customComponentAfter,
      infoStyleIconProps,
      isBgDarker,
      tooltipTitle,
      tooltipProps,
    } = props;

    return (
      <Tooltip
        title={tooltipTitle}
        {...tooltipProps}
      >
        <Box
          display="flex"
          sx={{ ':hover': { cursor: 'default' }, color: greyTextColor }}
          ref={ref}
          {...containerProps}
        >
          {customComponentBefore}
          <Box
            sx={{
              width: '14px',
              height: '15px',
              background: isBgDarker ? '#D1D1D1' : '#F2F2F2',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...infoStyleIconProps,
            }}
          >
            <Typography sx={{ textTransform: 'lowercase' }} fontSize={11}>i</Typography>
          </Box>
          {customComponentAfter}
        </Box>
      </Tooltip>
    );
  });
