import React, { ReactElement, ReactNode } from 'react';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';
import { Box, Skeleton, Typography } from '@mui/material';


export const DataGridWithEmptyState = (props: DataGridWithEmptyStateProps) => {
  const {
    emptyStateTitle,
    emptyStateText,
    emptyStateImage,
    emptyStateComponent,
    isEmpty,
    loading,
    className,
    ...dataGridProps
  } = props;

  const emptyStateComponentCalc = React.useMemo(() => (
    emptyStateComponent ? emptyStateComponent : (
      <Box
        className="_d-flex-dir-col-ali-center-jc-center"
        sx={{ minHeight: 'min(50vh, 500px)' }}
      >
        {emptyStateImage && emptyStateImage}
        {emptyStateTitle && <Typography variant="h2" mt={5} mb={1}>{emptyStateTitle}</Typography>}
        {emptyStateText && <Typography variant="body1" color="text.secondary">{emptyStateText}</Typography>}
      </Box>
    )
  ), [emptyStateComponent, emptyStateImage, emptyStateText, emptyStateTitle]);

  return (
    <React.Fragment>
      {isEmpty && emptyStateComponentCalc}

      {loading && (
        <Box
          className="_d-grid-lg-gap"
          sx={{
            gridTemplateColumns: 'repeat(5, 1fr)',
            gridAutoRows: '24px',
            '.MuiSkeleton-root': {
              height: 'unset',
              borderRadius: '24px',
            },
          }}
        >
          <Skeleton variant="rounded" sx={{ gridColumn: '1 / -1' }}/>
          {Array(5 * 3).fill(null).map((x, i) => (
            <Skeleton variant="rounded" key={i}/>
          ))}
        </Box>
      )}

      <DataGrid
        {...dataGridProps}
        loading={loading}
        className={`${className || ''} ${isEmpty || loading ? 'is-hide-data-grid' : ''}`}
      />
    </React.Fragment>
  );
};

interface DataGridWithEmptyStateProps extends React.ComponentProps<typeof DataGrid> {
    emptyStateTitle?: ReactNode;
    emptyStateText?: ReactNode;
    emptyStateImage?: ReactNode;
    emptyStateComponent?: ReactElement;
    isEmpty: boolean;
}

