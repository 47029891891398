import { CSSProperties, FC, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';
import { Box, Button, Typography } from '@mui/material';
import { ArrowSmallIcon } from 'shared/icons/ArrowIcon';
import { outlineLight, textPrimary } from 'shared/styles/muiTheme';
import { cacheKeys, persistKeys, routes } from 'config';
import { LocalizationContext } from 'contexts';
import { useWebsocket } from 'contexts/WebsocketContext/WebsocketContext';
import { Workspace } from 'clients/users/userClient.types';
import { usersClient } from 'clients/users/usersClient';
import { useBulkQueryKeys } from 'shared/hooks/useBulkQueryKeys';
import { WorkspaceLogo } from 'shared/components/Logo/WorkspaceLogo';


export const WorkspaceItem: FC<Workspace> = (props) => {
  const {
    id,
    logo,
    membersCount,
    name,
  } = props;


  const { dictionary } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { queryKeys } = useBulkQueryKeys(persistKeys.workspaceChange);
  const { socketId } = useWebsocket();

  const handleClick = async () => {
    await usersClient.changeWorkspace({ workspaceId: id, socketId });
    queryKeys.forEach(queryKey => queryClient.resetQueries([queryKey]));
    queryClient.invalidateQueries(cacheKeys.users.getMe);
    queryClient.invalidateQueries(cacheKeys.emissionFactorCategories.getEmissionFactorCategories);
    navigate(routes.home);
  };

  return (
    <Button
      className="_d-flex-ali-center-md-gap-jc-sb"
      color="secondary"
      component="li"
      onClick={handleClick}
      sx={{
        border: `solid 1px ${outlineLight}`,
        borderRadius: 2,
        p: 2,
        minHeight: 64,
        minWidth: '264px',
      }}
    >
      <Box className="_d-flex-md-gap-ali-center">
        <WorkspaceLogo
          iconOnly
          image={logo}
          name={name}
        />
        <Box sx={{ maxWidth: '135px' }}>
          <Typography className="one-line-text" variant="h6">{name}</Typography>
          <Typography variant="body2">{membersCount} {dictionary.workspace.members}</Typography>
        </Box>
      </Box>
      <ArrowSmallIcon direction="right" style={{ '--icon-color': textPrimary } as CSSProperties}/>
    </Button>
  );
};