import React, { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';

import { Dropdown, DropdownProps } from './Dropdown';


export type ButtonDropdownProps = Omit<DropdownProps, 'children'> & {
  label: React.ReactElement | string;
  buttonProps?: Omit<ButtonProps, 'endIcon'> & {
    endIcon?: (open: boolean) => React.ReactNode;
  };
}

export const ButtonDropdown: FC<ButtonDropdownProps> = ({ label, buttonProps, ...props }) => (
  <Dropdown popperProps={{ sx: { zIndex: 1050 } }} {...props}>
    {({ ref, handleToggleDropdownMenu, openDropdownMenu }) => (
      <Button
        {...buttonProps}
        ref={ref}
        aria-label="options menu"
        aria-haspopup="menu"
        onClick={handleToggleDropdownMenu}
        endIcon={buttonProps?.endIcon && buttonProps.endIcon(openDropdownMenu)}
      >
        {label}
      </Button>
    )}
  </Dropdown>
);
