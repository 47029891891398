import { AxiosResponse } from 'axios';
import { snakizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';
import { MappedSurveyAnswersApi, Survey, SurveyAnswer, SurveyApi } from './survey.types';
import { mapAnswersSurvey, mapQuestionSurvey } from './surveyClient.formatter';



const apiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getSurvey = (params: any): Promise<AxiosResponse<Survey>> => {
  const { surveyName, ...restParams } = params;
  return request({
    options: {
      url: `${apiBaseUrl}/survey/${surveyName}/`,
      method: 'GET',
      params: restParams,
    },
  }).then((data: AxiosResponse<SurveyApi>) => {
    return ({
      ...data,
      data: mapQuestionSurvey(data.data),
    });});
};

const postSurveyAnswer = ({ answers, year, surveyName }: SurveyAnswer): Promise<AxiosResponse<MappedSurveyAnswersApi>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/survey/${surveyName}/answer/`,
      method: 'POST',
      data: mapAnswersSurvey({ answers: snakizeObject(answers), year }),
    },
  });
};

const updateSurveyAnswer = ({ answers, year, surveyName, surveyAnswerId }: SurveyAnswer): Promise<AxiosResponse<MappedSurveyAnswersApi>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/survey/${surveyName}/answer/${surveyAnswerId}/`,
      method: 'PUT',
      data: mapAnswersSurvey({ answers: snakizeObject(answers), year, id: surveyAnswerId }),
    },
  });
};




export const surveysClient = {
  getSurvey,
  postSurveyAnswer,
  updateSurveyAnswer,
};
