import { AxiosResponse } from 'axios';
import { camelizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';
import { Company, CompanyApi, CompanyEdit, GetCompaniesParams } from './companyClient.types';
import { mapCompanyCrudData } from './companyClient.formatter';


const companiesApiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getCompanies = (params: GetCompaniesParams): Promise<AxiosResponse<Company[]>> => {
  return request({
    options: {
      url: `${companiesApiBaseUrl}/companies/`,
      method: 'GET',
      params: {
        search: params.search,
      },
    },
  }).then((data: AxiosResponse<CompanyApi[]>) => ({
    ...data,
    data: data.data.map(camelizeObject<Company>),
  }));
};

const editCompany = (data: CompanyEdit): Promise<AxiosResponse<Company>> => {
  return request({
    options: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${companiesApiBaseUrl}/companies/${data.id}/`,
      method: 'PUT',
      data: mapCompanyCrudData(data),
    },
  });
};

const deleteCompany = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${companiesApiBaseUrl}/companies/${params.id}/`,
      method: 'DELETE',
    },
  });
};

export const companyClient = {
  getCompanies,
  editCompany,
  deleteCompany,
};
