import { useQuery } from 'react-query';
import { cacheKeys } from 'config';

import { scenarioBuilderClient } from 'clients/scenarioBuilder/scenarioBuilderClient';


type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useScenarioBuilderData = (options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.scenarioBuilder.getScenarioBuilderData],
    () => scenarioBuilderClient.get(),
    {
      enabled: options.enabled,
      retry: (failureCount, { response: { status } }) => {
        if (status === 404) {
          return false;
        }
        return true;
      },
    },
  );

  return {
    status,
    error,
    chart: data?.chart || {},
    table: data?.table || {},
  };
};
