// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Pagination = (theme: Theme) => {
  return {
    MuiPagination: {
      styleOverrides: {
      },
    },
  };
};

export default Pagination;
