import { useQuery } from 'react-query';

import { Transactions } from 'modules/Transactions/transactions';
import { transactionsClient } from 'modules/Transactions/client/transactionsClient';
import { cacheKeys } from 'config';


type Options = {
  enabled: boolean,
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};


export const useVendorInsights = (params: Transactions.Vendors.Insights.GetListParams, options: Partial<Options> = defaultOptions) => {

  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.transactions.getVendorInsights, params],
    () => transactionsClient.getVendorInsights(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    insights: data || [],
  };
};
