// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Typography = (theme: Theme) => {
  return {
    MuiTypography: {
      styleOverrides: {

      },
    },
  };
};

export default Typography;
