import React, { FunctionComponent } from 'react';
import { Skeleton, Stack } from '@mui/material';


const sx = { mb: 2 };

const SurveySkeleton: FunctionComponent = (props) => {
  return (
    <Stack mt={4} width="100%" gap={1}>
      <Skeleton variant="rounded" width="60%" height={20}/>
      <Skeleton sx={sx} variant="rounded" height={50}/>
      <Skeleton variant="rounded" width="80%" height={20}/>
      <Skeleton sx={sx} variant="rounded" height={50}/>
    </Stack>
  );
};

export default SurveySkeleton;
