// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Accordion = (theme: Theme) => {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: '0 0 24px 0',
          border: '1px solid #E8E8E8',
          padding: '12px 24px',
          color: '#757575',
          width: '720px',
          '&::before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-content': {
            '& .MuiTypography-root': {
              fontSize: '18px',
              fontWeight: '600',
              color: '#222222',
            },
          },
          '&.Mui-expanded': {
            margin: '0 0 24px 0',
          },
        },
      },
    },
  };
};

export default Accordion;
