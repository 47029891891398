import React, { FC, useCallback } from 'react';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';

import { formatDate, isValidDate } from 'helpers/date';


type DatePickerProps = {
  readonly value: string | null;
  readonly onChange: (value: string | null) => void;
  readonly yearLimit?: Date | null;
};

export const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  yearLimit,
}) => {

  const handleChange = useCallback((value: Date | null) => {
    if (!value || !isValidDate(value) || !yearLimit || !(value.getFullYear() >= yearLimit.getFullYear())){
      return;
    }
    onChange(formatDate(value));
  }, [onChange, yearLimit]);

  return (
    <DesktopDatePicker
      value={value}
      inputFormat="dd.MM.yyyy"
      toolbarFormat="dd.MM.yyyy"
      mask="__.__.____"
      onChange={handleChange}
      renderInput={(props: any) => (
        <TextField
          {...props}
          size="small"
          autoComplete="off"
        />
      )}
    />
  );
};
