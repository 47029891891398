//@ts-nocheck
import _ from 'lodash';
import {
  BundleIcons,
  OffsetTypeVariantsEnum,
  CarbonPermanceVariantsEnum,
} from './CarbonRemovalTypes';



export const getBundleCategoryList = (
  offsetType?: string,
  carbonPermanence?: string | null,
) => {
  if (carbonPermanence === null) {
    carbonPermanence = 'no_storage';
  }

  const offsetTypeVariants = {
    [OffsetTypeVariantsEnum.carbonRemoval]: 'Carbon removal' as const,
    [OffsetTypeVariantsEnum.emissionsReduction]: 'Emission reduction' as const,
    [OffsetTypeVariantsEnum.natureBased]: 'Nature-based emission removal' as const,
  };

  const carbonPermanenceVariants = {
    [CarbonPermanceVariantsEnum.shortTerm]: 'Short-lived carbon storage' as const,
    [CarbonPermanceVariantsEnum.longTerm]: 'Long-lived carbon storage' as const,
    [CarbonPermanceVariantsEnum.noStorage]: 'No carbon storage' as const,
  };

  return [
    {
      title: offsetTypeVariants[offsetType as keyof typeof offsetTypeVariants],
      icon: BundleIcons.cloud as const,
    },
    {
      title:
        carbonPermanenceVariants[
          carbonPermanence as keyof typeof carbonPermanenceVariants
        ],
      icon: BundleIcons.lock as const,
    },
  ];
};

export const extractCountryNames = (data) => {
  let uniqueCountryNames = [];
  data.forEach(bundleData => {
    bundleData.projects.forEach(project => {
      if (!uniqueCountryNames.includes(project.countryName)) {
        uniqueCountryNames.push(project.countryName);
      }
    });
  });
  return _.sortBy(uniqueCountryNames);
};

export const filterBundles = (bundles, filters) => bundles.filter(bundle => filters.every(filter => filter(bundle)));