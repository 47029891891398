import { UseFormProps } from 'react-hook-form';
import { CamelToSnakeCase } from 'types';
import { FormInputObject } from 'shared/components/form/Form/form.types';


export type Survey = {
  questions: {
    questions: SurveyQuestions;
    submitLabel: string;
    defaultValues: Pick<UseFormProps, 'defaultValues'>;
    validationSchemaFrontend: ValidationSchemaFrontend;
  };
  surveyAnswers: SurveyAnswer[];
  survey_answers: SurveyAnswer[];
};
export type ValidationSchemaFrontend = {
  [key: string]: {
    type: string;
    subsequentMethods: { [key: string]: any[] };
  };
};
export type SurveyQuestion = FormInputObject;
export type ReplaceableKeys = Pick<FormInputObject, 'label'>
export type SurveyQuestions = SurveyQuestion[];
export type SurveyApi = CamelToSnakeCase<Survey> & {
  survey_answers: SurveyAnswer[];
};
export interface SurveyAnswer extends Answer {
  surveyName?: SurveyNames,
  surveyAnswerId?: number,
  year: string;
}
export type Answer = { [key: string]: any };

export type MappedAnswers = Array<{
  answer: any;
  question: string;
}>;

export enum SurveyNames {
  employeeEmissions = 'employee_emissions',
}

export type MappedSurveyAnswersApi = {
  id?: number;
  year: string;
  answers: MappedAnswers;
};
