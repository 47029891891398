import { FunctionComponent, ReactElement } from 'react';
import { Box, SxProps } from '@mui/material';

import { primary } from 'shared/styles/muiTheme';


interface ISquareBaseProps {
  children?: ReactElement;
  selected?: boolean;
  onClick?: () => void;
  squareSxProps?: SxProps
}

export const SquareBase: FunctionComponent<ISquareBaseProps> = (props) => (
  <Box
    sx={{
      borderRadius: '4px',
      height: '20px',
      width: '20px',
      border: `1px solid ${props.selected ? primary : '#D1D1D1'}`,
      background: props.selected ? primary : 'white',
      ...props.squareSxProps,
    }}
    display="flex"
    onClick={props.onClick}
    justifyContent="center"
    alignContent="center"
  >
    {props.children}
  </Box>
);
