import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { integrationsClient } from 'modules/Expenses/client/integrationsClient';
import { cacheKeys } from 'config';
import { LayoutContext } from 'contexts';


export const useIntegrationsCrud = () => {
  const { genericError } = React.useContext(LayoutContext);
  const queryClient = useQueryClient();

  const onSuccessfulIntegrationMutation = React.useCallback(async () => {
    queryClient.invalidateQueries(cacheKeys.integrations.getIntegrations);
    queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
  }, [queryClient]);

  const deleteIntegration = useMutation(integrationsClient.deleteIntegration, {
    mutationKey: cacheKeys.integrations.deleteIntegration,
    onSuccess: onSuccessfulIntegrationMutation,
    onError: genericError,
  });

  return {
    deleteIntegration: deleteIntegration.mutateAsync,
    isLoading: deleteIntegration.isLoading,
  };
};
