
export const getShortDescription = (description: string) => {
  return description.slice(0, 200) + '...';
};

export const SustainabilityTypes = {
  NEUTRAL: 'carbon neutral',
  OPT_NEUTRAL: 'optionally carbon neutral',
};

export const VendorTypes = {
  SUS: 'sustainable',
  RED: 'reduction tool',
};