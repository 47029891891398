import React from 'react';
import { Box, Chip } from '@mui/material';
import { XMarkIcon } from 'shared/icons/XMarkIcon';
import { SelectWithSearchOption } from './SelectWithSearch';


export const ChipsSection = (props: ChipsSectionProps) => {
  const { options, onClear } = props;

  return (
    <Box
      className="_d-flex-f-wrap-xs-gap"
      p={options.length ? 1 : 0}
      pt={0}
    >
      {options.map((option) => (
        <Chip
          key={option.id}
          label={option.name}
          onDelete={onClear ? () => onClear(option) : undefined}
          deleteIcon={<XMarkIcon/>}
          sx={{ borderRadius: '24px', height: 24 }}
        />
      ))}
    </Box>
  );
};

interface ChipsSectionProps {
    options: SelectWithSearchOption[]
    onClear?: (option: SelectWithSearchOption) => void;
}