import { Dictionary } from 'contexts/LocalizationContext/types';
import {
  EmissionClassificationConfidenceStatus,
  EmissionClassificationConfidenceStatusPercentage,
  Source,
  Transaction,
  TransactionStatus,
} from 'clients/transactions/transactionClient.types';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';


const TRANSACTION_STATUS_LABEL_MAP = (dictionary: Dictionary) => ({
  [TransactionStatus.New]: dictionary.transaction.statusNewLabel,
  [TransactionStatus.Classified]: dictionary.transaction.statusClassifiedLabel,
});

const TRANSACTION_STATUS_SCOPE_MAP = (dictionary: Dictionary) => ({
  [Scope.One]: dictionary.transaction.scopeOneLabel,
  [Scope.Two]: dictionary.transaction.scopeTwoLabel,
  [Scope.Three]: dictionary.transaction.scopeThreeLabel,
  [Scope.Offset]: dictionary.transaction.scopeOffsetLabel,
  [Scope.All]: dictionary.transaction.scopeAllLabel,
});

const TRANSACTION_CLASSIFICATION_CONFIDENCE_MAP = (dictionary: Dictionary) => ({
  [EmissionClassificationConfidenceStatus.CONFIDENT]: dictionary.transaction.highConfidenceLabel,
  [EmissionClassificationConfidenceStatus.NEEDS_ATTENTION]: dictionary.transaction.lowConfidenceLabel,
});

const TRANSACTION_CLASSIFICATION_CONFIDENCE_CHIP_COLOR_MAP = (dictionary: Dictionary) => ({
  [dictionary.transaction.highConfidenceLabel]: 'success',
  [dictionary.transaction.lowConfidenceLabel]: 'warning',
});

export const getTransactionStatusLabel = (dictionary: Dictionary, status: TransactionStatus) => TRANSACTION_STATUS_LABEL_MAP(dictionary)[status];

export const getTransactionScopeLabel = (dictionary: Dictionary, scope: Scope) => TRANSACTION_STATUS_SCOPE_MAP(dictionary)[scope];

export const isHighConfidenceTransaction = (transaction: Transaction) => {
  if (!transaction.emissionClassification) {
    return false;
  }
  if (transaction.emissionClassification?.source === Source.Manual) {
    return true;
  }

  return transaction.emissionClassification.confidence >= EmissionClassificationConfidenceStatusPercentage.CONFIDENT;
};

export const getTransactionConfidenceStatusLabel = (dictionary: Dictionary, transaction: Transaction ) => {
  const isHighConfidenceChip = isHighConfidenceTransaction(transaction);
  if (isHighConfidenceChip) {
    return TRANSACTION_CLASSIFICATION_CONFIDENCE_MAP(dictionary)[EmissionClassificationConfidenceStatus.CONFIDENT];
  }

  return TRANSACTION_CLASSIFICATION_CONFIDENCE_MAP(dictionary)[EmissionClassificationConfidenceStatus.NEEDS_ATTENTION];
};

export const getConfidenceChipColor = (dictionary: Dictionary, confidenceValue: string) => TRANSACTION_CLASSIFICATION_CONFIDENCE_CHIP_COLOR_MAP(dictionary)[confidenceValue];

