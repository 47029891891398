import React, { FC, useContext } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LocalizationContext } from 'contexts';


interface Props {
    show: boolean;
    pageCount: number;
    totalDatasetCount: number;
    isWholeDatasetSelected: boolean;
    onSelectAll: (isSelected: boolean) => void;
};

export const SelectAll: FC<Props> = ({ show, isWholeDatasetSelected, onSelectAll, pageCount, totalDatasetCount }) => {
  const { dictionary } = useContext(LocalizationContext);

  if (!show) {
    return null;
  }

  return (
    <Box pb={1}>
      {isWholeDatasetSelected ? (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Typography>{dictionary.transaction.transactionList.selectAll.totalIsSelected(totalDatasetCount)}</Typography>
          <Button
            variant="text"
            color="primary"
            onClick={() => onSelectAll(false)}
          >
            {dictionary.transaction.transactionList.selectAll.clearSelection}
          </Button>
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Typography>{dictionary.transaction.transactionList.selectAll.pageIsSelected(pageCount)}</Typography>
          <Button
            variant="text"
            color="primary"
            onClick={() => onSelectAll(true)}
          >
            {dictionary.transaction.transactionList.selectAll.selectAllDataset(totalDatasetCount)}
          </Button>
        </Stack>
      )}
    </Box>
  );
};
