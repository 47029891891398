import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const ChevronDoubleLeftIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 20 21">
    <path
      clipRule="evenodd"
      d="M15.7906 15.7679C15.5035 16.0665 15.0287 16.0758 14.7302 15.7887L10.2302 11.5387C10.0831 11.3973 10 11.2021 10 10.998C10 10.794 10.0831 10.5988 10.2302 10.4574L14.7302 6.20742C15.0287 5.92033 15.5035 5.92964 15.7906 6.22821C16.0777 6.52679 16.0684 7.00158 15.7698 7.28867L11.8321 10.998L15.7698 14.7074C16.0684 14.9945 16.0777 15.4693 15.7906 15.7679ZM9.79062 15.7679C9.50353 16.0665 9.02875 16.0758 8.73017 15.7887L4.23017 11.5387C4.08311 11.3973 4 11.2021 4 10.998C4 10.794 4.08311 10.5988 4.23017 10.4574L8.73017 6.20742C9.02875 5.92033 9.50353 5.92964 9.79062 6.22821C10.0777 6.52679 10.0684 7.00158 9.76983 7.28867L5.83208 10.998L9.76983 14.7074C10.0684 14.9945 10.0777 15.4693 9.79062 15.7679Z" fill="#9098A7"
      fillRule="evenodd"
    />
  </IconContainer>
);
