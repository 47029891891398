import React from 'react';
import { Box } from '@mui/material';
import { CsvColOptionOrder } from 'modules/Expenses/components/UploadIntegrationWizard';
import { CsvUploadErrors } from 'modules/Expenses/hooks/useUploadIntegrationWizard';
import { LocalizationContext } from 'contexts';
import { CsvColumnOption } from 'shared/hooks/useUploadCsvWizard';
import { ErrorCard, NotificationCardProps } from 'shared/components/alerts/NotificationCard';


export const CsvErrors = (props: CsvErrorsProps) => {
  const { errors, isFirstRowHeaders, columnOptions } = props;

  const { dictionary } = React.useContext(LocalizationContext);

  const errorCardsProps = React.useMemo<Omit<NotificationCardProps, 'cardType'>[]>(() => (
    Object.keys(errors || {})
      .sort((a, b) =>
        CsvColOptionOrder.findIndex((c) => c === a) > CsvColOptionOrder.findIndex((c) => c === b) ? 1 : -1,
      )
      .map((key) => ({
        text: `
        ${columnOptions.find((opt) => opt.key === key)?.title}:${' '}
        ${dictionary.expenses.uploadIntegration.selectColumns.errorNumRows(errors![key].rows?.length)}${' '}
        ${errors![key].error}
      `,
        expandedText: `
        ${dictionary.expenses.uploadIntegration.selectColumns.rows(errors![key].rows?.length)}:${' '}
        ${errors![key].rows?.map((n) => n + (isFirstRowHeaders ? 2 : 1)).join(', ')}
      `,
      }))
  ), [columnOptions, errors, dictionary, isFirstRowHeaders]);

  if (!errors) {
    return null;
  }

  return (
    <Box mt={3} className="_d-grid-sm-gap">
      {errorCardsProps.map((cardProps, i) => (
        <ErrorCard key={i} {...cardProps}/>
      ))}
    </Box>
  );
};

interface CsvErrorsProps {
  errors: CsvUploadErrors | null;
  isFirstRowHeaders: boolean;
  columnOptions: CsvColumnOption[];
}