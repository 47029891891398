import React from 'react';
import { IconContainer } from './IconContainer';


export const ChartBarIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3996 1.59961C11.7369 1.59961 11.1996 2.13687 11.1996 2.79961V13.1996C11.1996 13.8624 11.7369 14.3996 12.3996 14.3996H13.1996C13.8624 14.3996 14.3996 13.8624 14.3996 13.1996V2.79961C14.3996 2.13687 13.8624 1.59961 13.1996 1.59961H12.3996Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.59961 4.79961C6.93687 4.79961 6.39961 5.33687 6.39961 5.99961V13.1996C6.39961 13.8624 6.93687 14.3996 7.59961 14.3996H8.39961C9.06235 14.3996 9.59961 13.8623 9.59961 13.1996V5.99961C9.59961 5.33687 9.06235 4.79961 8.39961 4.79961H7.59961Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.79961 7.99961C2.13687 7.99961 1.59961 8.53687 1.59961 9.19961V13.1996C1.59961 13.8624 2.13687 14.3996 2.79961 14.3996H3.59961C4.26235 14.3996 4.79961 13.8623 4.79961 13.1996V9.19961C4.79961 8.53687 4.26235 7.99961 3.59961 7.99961H2.79961Z"
      fill="currentColor"
    />
  </IconContainer>
);

