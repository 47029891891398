import { FC } from 'react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Stack, Tooltip, Typography, capitalize } from '@mui/material';
import { Box } from '@mui/system';
import { noop } from 'lodash';



interface CategoryCellProps {
    hasSubCategories?: boolean;
    color?: string;
    onClick?: () => void;
    isExpanded?: boolean;
    categoryName: string;
};

export const CategoryCell: FC<CategoryCellProps> = ({ hasSubCategories, color, onClick = noop, isExpanded, categoryName }) => {

  return (
    <Stack direction="row" sx={{ cursor: hasSubCategories ? 'pointer' : undefined }} onClick={onClick}>
      {hasSubCategories && (
        <Box
          sx={{
            bgcolor: color,
            width: 4,
            height: 18,
          }}
        />
      )}
      <Tooltip title={<Typography fontSize={13}>{capitalize(categoryName)}</Typography>}>
        <Typography
          variant="body1"
          fontSize={13}
          ml={1}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          width={100}
          pl={!hasSubCategories && categoryName !== 'total' ? 2: undefined}
          sx={{ overflow: 'hidden' }}
        >
          {capitalize(categoryName)}
        </Typography>
      </Tooltip>

      <Box ml="auto"/>

      {hasSubCategories && (isExpanded ? <KeyboardArrowDown/> : <KeyboardArrowRight/>)}
    </Stack>
  );
};
