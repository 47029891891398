import React, { useContext } from 'react';
import { Box, Divider } from '@mui/material';

import { LocalizationContext } from 'contexts';


export function FormDivider() {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Box width="100%" my={2}>
      <Divider orientation="horizontal" flexItem>
        {dictionary.auth.divider}
      </Divider>
    </Box>
  );
}
