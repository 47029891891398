import React, { FC } from 'react';
import { Chip, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { isHighConfidenceTransaction } from 'modules/Transactions/helpers/classification';
import { Transaction } from 'clients/transactions/transactionClient.types';


export const CertaintyCell: FC<
  GridRenderCellParams<any, Transaction, any> & {getChipValue: (row: Transaction) => string}
> = ({ row, getChipValue }) => {

  const color = isHighConfidenceTransaction(row) ? 'success' : 'warning';

  return (
    <Stack direction="row">
      <Chip
        size="small"
        color={color}
        label={getChipValue(row)}
      />
    </Stack>
  );
};