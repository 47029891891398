import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs as Crumbs, Link } from '@mui/material';


export type Crumb = {
  text: string;
  link: string;
};

type Props = {
  list: Crumb[];
  show?: boolean;
}

export const Breadcrumbs: FC<Props> = ({ list, show = false }) => {
  return (
    <Crumbs aria-label="breadcrumb" sx={{ ml: 0, mb: 0, mt: 2, display: !show ? 'none' : '' }}>
      {list.map(({ text, link }) => (
        <Link key={link} color="secondary" variant="h6" component={RouterLink} to={link} fontWeight={300}>
          {text}
        </Link>
      ))}
    </Crumbs>
  );
};
