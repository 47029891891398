import { FC, Fragment } from 'react';
import { Divider } from '@mui/material';
import { Stack } from '@mui/system';

import EmissionBox from './EmissionBox';
import { IEmissionBoxSectionProps } from './EmissionBoxSection.types';


const emissionCarbonBarHeights = [20, 14, 8, 21, 24, 12];
const emissionOffsetBarHeights = [8, 14, 16, 10, 24, 19];
const emissionBoxContainerProps = { pl: 5 };

const EmissionBoxSection: FC<IEmissionBoxSectionProps> = ({
  totalEmission,
  totalOffset,
  netEmission,
  totalEmissionTooltip,
  isLoading,
  netEmissionTooltip,
  offsetTooltip,
  titleOffset,
  titleNetEmission,
  titleTotalEmission,
}) => {
  return (
    <Stack height={187} alignItems="center" direction="row">
      {(totalEmission || totalEmission === 0) && (
        <Fragment>
          <EmissionBox
            title={titleTotalEmission!}
            emissionWeight={totalEmission}
            emissionBarVariant="totalEmissions"
            emissionBarHeights={emissionCarbonBarHeights}
            tooltip={totalEmissionTooltip}
            isLoading={isLoading}
          />
          <Divider orientation="vertical"/>
        </Fragment>
      )}
      {(totalOffset || totalOffset === 0) && (
        <EmissionBox
          title={titleOffset!}
          emissionWeight={totalOffset}
          emissionBarVariant="offset"
          emissionBarHeights={emissionOffsetBarHeights}
          containerProps={emissionBoxContainerProps}
          tooltip={offsetTooltip}
          isLoading={isLoading}
        />
      )}
      {(netEmission || netEmission === 0) && (
        <Fragment>
          <Divider orientation="vertical"/>
          <EmissionBox
            title={titleNetEmission!}
            emissionWeight={netEmission}
            emissionBarVariant="netEmissions"
            emissionBarHeights={emissionOffsetBarHeights}
            containerProps={emissionBoxContainerProps}
            tooltip={netEmissionTooltip}
            isLoading={isLoading}
          />
        </Fragment>
      )}
    </Stack>
  );
};

export default EmissionBoxSection;
