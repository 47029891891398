import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';


interface Props {
  children: ReactNode;
  title: string;
  headerText?: string;
  defaultIsExpanded?: boolean;
  hideDivider?: boolean;
  handleForceExpanded?: () => void;
}

export const TransactionSection: FC<Props> = ({
  children,
  title,
  headerText,
  defaultIsExpanded = false,
  hideDivider = false ,
  handleForceExpanded,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultIsExpanded);

  useEffect(() => {
    if (handleForceExpanded) {
      setIsExpanded(true);
      handleForceExpanded();
    }
  }, [handleForceExpanded]);

  return (
    <Box mt={3}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body1">{headerText}</Typography>
        <IconButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Box mt={4}>{children}</Box>
      </Collapse>

      {!hideDivider && <Divider sx={{ mt: 5 }}/>}
    </Box>
  );
};
