import React from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { orderBy } from 'lodash';

import { LocalizationContext, UserContext } from 'contexts';
import { ILuneBundle } from 'clients/offsetting/offsettingClientTypes';
import { project1, project2 } from 'shared/img/offset';

import ExclamationTriangleIcon from 'shared/components/layout/Icons/ExclamationTriangleIcon';
import Bundle from './Bundle';
import PriceSortButton from './PriceSortButton';
import { IBundleSectionProps, sortBundlePrice } from './CarbonRemovalTypes';
import {
  extractCountryNames,
  filterBundles,
} from './carbonRemovalHelpers';


const BundleSection: React.FunctionComponent<IBundleSectionProps> = ({
  luneOffsetKey,
  luneBundles,
}) => {
  const { dictionary } = React.useContext(LocalizationContext);
  const { user } = React.useContext(UserContext);
  const [typeOption, setTypeOption] = React.useState('type');
  const [locationOption, setLocationOption] = React.useState('location');
  const [priceSorted, setPriceSorted] = React.useState<
    sortBundlePrice.asc | sortBundlePrice.desc | false
  >(false);

  const iframeParams = React.useMemo(() => {
    if (!user) {
      return '';
    }
    return `&prefill_First+name=${user.firstName}&prefill_Last+name=${user.lastName}&prefill_Email=${user.email}&prefill_Company=${user.company?.name || ''}`;
  }, [user]);

  const allBundles = React.useMemo(
    () => [
      ...luneBundles,
      {
        primaryImage: project1,
        description:
          dictionary.carbonRemoval.bundleSection.regreenerBundle.project1
            .description,
        grossUnitPrice: '15.50',
        name: dictionary.carbonRemoval.bundleSection.regreenerBundle.project1
          .name,
        offsetType: 'emissions_reduction',
        carbonPermanence: 'no_storage',
        projects: [
          {
            countryName: 'United States of America',
          },
        ],
        iframeUrl: `https://airtable.com/embed/shrvT7mqmjyP3jGYI?prefill_Project=recWc6QzQfJdedv9i${iframeParams}`,
        id: undefined,
      },
      {
        primaryImage: project2,
        description:
          dictionary.carbonRemoval.bundleSection.regreenerBundle.project2
            .description,
        grossUnitPrice: '12.50',
        name: dictionary.carbonRemoval.bundleSection.regreenerBundle.project2
          .name,
        offsetType: 'emissions_reduction',
        carbonPermanence: 'long_term',
        projects: [
          {
            countryName: 'Indonesia',
          },
        ],
        iframeUrl: `https://airtable.com/embed/shrvT7mqmjyP3jGYI?prefill_Project=recpzXSjAgsuDIFXL${iframeParams}`,
        id: undefined,
      },
    ],
    [luneBundles, dictionary, iframeParams],
  );

  const handleChange = (e: any) => {
    setTypeOption(e.target.value);
  };

  const handleLocationChange = (e: any) => {
    setLocationOption(e.target.value);
  };

  const sortedBundles = orderBy(
    allBundles,
    (bundle) => parseInt(bundle.grossUnitPrice, 10),
    priceSorted,
  );
  const preFilteredBundles = priceSorted ? sortedBundles : allBundles;

  const filteredBundles = filterBundles(preFilteredBundles, [
    (bundle: ILuneBundle) => {
      if (typeOption === 'type') return bundle;
      return (bundle.offsetType === typeOption);
    },
    (bundle: ILuneBundle) => {
      if (locationOption === 'location') return bundle;
      return bundle.projects.some(project => project.countryName === locationOption);
    },
  ]);

  const sortPriceHandler = () =>
    setPriceSorted((prev) => {
      if (prev === sortBundlePrice.asc) return sortBundlePrice.desc;
      if (prev === sortBundlePrice.desc) return false;
      return sortBundlePrice.asc;
    });

  return (
    <Box>
      <Box className="_d-flex-ali-center-jc-sb" pt={3} pb={1}>
        <Typography color="#334155" fontSize={21} fontWeight={500}>
          {dictionary.carbonRemoval.bundleSection.title}
        </Typography>
        <Box className="_d-flex-ali-start-md-gap">
          <PriceSortButton
            priceSorted={priceSorted}
            onSortPrice={sortPriceHandler}
            label={dictionary.carbonRemoval.bundleSection.sortPrice}
          />
          <Select
            value={typeOption}
            onChange={handleChange}
            sx={{ width: 175, height: 32 }}
          >
            <MenuItem value="type">
              {dictionary.carbonRemoval.bundleSection.typeDropdownOptions.type}
            </MenuItem>
            <MenuItem value="carbon_removal">
              {
                dictionary.carbonRemoval.bundleSection.typeDropdownOptions
                  .carbonRemoval
              }
            </MenuItem>
            <MenuItem value="emissions_reduction">
              {
                dictionary.carbonRemoval.bundleSection.typeDropdownOptions
                  .emissionReduction
              }
            </MenuItem>
          </Select>
          <Select
            value={locationOption}
            onChange={handleLocationChange}
            sx={{ width: 175, height: 32 }}
          >
            <MenuItem value="location">Location</MenuItem>
            {extractCountryNames(allBundles).map((countryName) => (
              <MenuItem value={countryName} key={countryName}>
                {countryName}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      {filteredBundles.length > 0 ? (
        filteredBundles.map((bundle: ILuneBundle) => (
          <Bundle
            key={bundle.name}
            imgsrc={bundle.primaryImage}
            desc={bundle.description}
            price={`€${bundle.grossUnitPrice} / tCO2`}
            title={bundle.name}
            luneClientCompanyKey={luneOffsetKey}
            {...bundle}
          />
        ))
      ) : (
        <Stack mt={2} alignItems="center" gap={1} direction="row">
          <ExclamationTriangleIcon
            stroke="black"
            sx={{ color: 'white', fontSize: 22 }}
          />
          <Typography fontStyle="italic" fontSize={15}>{dictionary.carbonRemoval.bundleSection.noMatchingProjects}</Typography>
        </Stack>
      )}
    </Box>
  );
};

export default BundleSection;
