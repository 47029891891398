import React from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@mui/system';
import { Button, Link, Skeleton, Typography } from '@mui/material';
import { line, primary } from 'shared/styles/muiTheme';
import { CogIcon } from 'shared/icons/CogIcon';
import { useIntegrations } from 'modules/Expenses/hooks/useIntegrations';
import { ShieldCheckIcon } from 'shared/icons/ShieldCheckIcon';
import { IntegrationStatus } from 'modules/Expenses/helpers/integrationOptions';
import { routes } from 'config';
import { LocalizationContext } from 'contexts';
import { useMe } from 'shared/hooks/useMe';
import { FilterModules, FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { InfoCard } from 'shared/components/alerts/NotificationCard';
import { Transactions } from 'views/LoggedIn/Transactions/Transactions';


export const Expenses = () => {

  const { dictionary } = React.useContext(LocalizationContext);
  const navigate = useNavigate();

  const { integrations, isLoading: isLoadingIntegrations } = useIntegrations({ page: 0, pageSize: 2000 });
  const { user, isLoading: isLoadingUser } = useMe();

  const transactionCount = React.useMemo<number>(() => {
    return user?.company?.nrOfTransactions ||
      integrations.reduce((pre, value) => (value.transactionsCount || 0) + pre,0);
  }, [integrations, user]);

  const isNeedValidation = React.useMemo<boolean>(() => {
    return integrations.some((int) => int.status === IntegrationStatus.NEED_REV);
  }, [integrations]);

  const transactionStatus = transactionCount ? (
    <Typography variant="body1" color="text.secondary">
      {dictionary.expenses.linkedAccounting.manualCount(transactionCount)}{' '}
      <ShieldCheckIcon sx={{ height: 12, width: 12, color: primary }}/>
    </Typography>
  ) : (
    <Typography variant="body1" color="text.secondary">
      {dictionary.expenses.linkedAccounting.noTransactionStatus}
    </Typography>
  );

  return (
    <Box mb={8}>
      <FiltersSection
        filterModules={[
          FilterModules.YEAR,
          FilterModules.SCOPE,
          FilterModules.CATEGORY,
          FilterModules.TAGS,
        ]}
        title={dictionary.expenses.title}
      >
        {({ filters }) => (
          <React.Fragment>
            <Box
              borderBottom={`solid 1px ${line}`}
              py={3}
              mb={3}
            >
              <Box className="_d-flex-ali-center-jc-sb">
                <Box>
                  <Typography variant="h2" mb={1}>{dictionary.expenses.linkedAccounting.title}</Typography>
                  {(isLoadingIntegrations || isLoadingUser) ? (
                    <Skeleton variant="rounded" sx={{ height: 20, width: 200 }}/>
                  ) : ( transactionStatus )}
                </Box>

                <Button color="info" onClick={() => navigate(routes.linkedAccounting)}>
                  <CogIcon/>
                  {dictionary.expenses.manage}
                </Button>
              </Box>

              {isNeedValidation && (
                <InfoCard
                  text={
                    <React.Fragment>
                      {dictionary.expenses.linkedAccounting.needsReview}{' '}
                      <Link onClick={() => navigate(routes.linkedAccounting)}>
                        {dictionary.expenses.linkedAccounting.reviewLink}
                      </Link>
                    </React.Fragment>
                  }
                  containerProps={{ mt: 1 }}
                />
              )}
            </Box>

            <Transactions filters={filters}/>
          </React.Fragment>
        )}
      </FiltersSection>
    </Box>
  );
};