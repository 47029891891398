import React, { FC } from 'react';
import { DataGridPro as DataGrid, DataGridProProps, GridCellModes, GridCellModesModel, GridCellParams } from '@mui/x-data-grid-pro';


export const DataGridWithSingleClickCellEdit: FC<DataGridProProps> = (props) => {
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const handleCellClick = React.useCallback((params: GridCellParams) => {
    if (params.isEditable) {
      setCellModesModel(prevModel => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {},
              ),
            }),
            {},
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {},
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    }
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel: any) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <DataGrid
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      experimentalFeatures={{ newEditingApi: true }}
      {...props}
    />
  );
};
