import React, { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Emissions } from 'shared/components/layout/Emissions/Emissions';
import EmissionBoxBar from './EmissionBoxBar';
import { EmissionBoxBarVariant } from './EmissionBoxSection.types';


interface IEmissionBoxBarGraphProps {
  emissionBarHeights: number[];
  emissionBarVariant?: EmissionBoxBarVariant;
  emissionWeight: number;
}

const EmissionBoxBarGraph: FunctionComponent<
  IEmissionBoxBarGraphProps
> = ({ emissionBarVariant, emissionWeight, emissionBarHeights }) => {
  return (
    <Stack gap={1.5} alignItems="flex-end" direction="row">
      {emissionBarVariant && <EmissionBoxBar variant={emissionBarVariant} size="big"/>}
      <Typography variant="h2" fontSize={52} lineHeight={1}>
        <Emissions kg={emissionWeight}/>
      </Typography>
      <Stack gap={0.2} alignItems="flex-end" height={52} direction="row">
        {emissionBarHeights.map((height, index) => (
          <EmissionBoxBar
            key={index}
            variant="netEmissions"
            smallBarHeight={height}
            size="small"
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default EmissionBoxBarGraph;
