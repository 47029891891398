import { useContext } from 'react';
import { LocalizationContext } from 'contexts';


export const useLocalizedCurrency = () => {
  const { currencyLocale: { locale } } = useContext(LocalizationContext);

  return (value: number, currency: string): number | null | string | undefined => {
    // If no value or currency is provided, return the value as is
    if (
      value === null
      || value === undefined
      || !currency
    ) return value;

    try {
      const localisedCurrency = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
      return localisedCurrency;
    } catch (error) {
      console.error(`Unable to localize currency: ${error}`);

      // Attempt some formatting if value is a number, otherwise retun the value as is
      return typeof value === 'number'
        ? value.toFixed(2)
        : value;
    }
  };
};