import {
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { ArrowDownTrayIcon } from 'shared/icons/ArrowDownTrayIcon';
import { PermissionContext } from 'contexts';

import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import {
  EmissionClassificationStatus,
} from 'clients/transactions/transactionClient.types';
import { useDataGridPagination } from 'shared/hooks/DataGrid/useDataGridPagination';
import ClassificationToggleButtonGroup from 'shared/components/layout/ToggleButton/ClassificationToggleButtonGroup';
import { SelectAll } from 'shared/components/interactive/SelectAll/SelectAll';
import CustomLoadingButton from 'shared/components/layout/Buttons/CustomLoadingButton';
import TransactionStatisticsList from './TransactionStatisticsList';


export const CustomToolbars = ({
  setFilterButtonEl,
  advancedMenuOpen,
  allFilters,
  advancedPanelRef,
  setCustomFilters,
  classificationStatus,
  selected,
  showSelectAll,
  count,
  isWholeDatasetSelected,
  setIsWholeDatasetSelected,
  onClassificationStatusChanged,
  filters,
  isLoadingExportingTransaction,
  exportButtonClickHandler,
  setAdvancedMenuOpen,
  newSearch,
  currSearch,
  setSearch,
}: any) => {
  const { getPermission } = useContext(PermissionContext);

  const hasExportPermission = useMemo(
    () => getPermission('transaction.export'),
    [getPermission],
  );
  const { dictionary } = useContext(LocalizationContext);

  const { pageSize, handlePageSizeChange } = useDataGridPagination({ defaultPage: 0 });

  const TransactionsAdvancedPanel = useCallback(() => {
    return (
      <Box mt={3}>
        {advancedMenuOpen && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TransactionStatisticsList
                allFilters={allFilters}
                pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}
                advancedPanelRef={advancedPanelRef}
                setCustomFilters={setCustomFilters}
                statisticsColumn="ledger"
                showRowPerPageOption
                statisticsColumnName={
                  dictionary.menu.transactions.ledgerAnalysis.ledger
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TransactionStatisticsList
                allFilters={allFilters}
                advancedPanelRef={advancedPanelRef}
                pageSize={pageSize}
                handlePageSizeChange={handlePageSizeChange}
                setCustomFilters={setCustomFilters}
                statisticsColumn="vendorName"
                statisticsColumnName={
                  dictionary.menu.transactions.vendorAnalysis.vendor
                }
              />
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }, [
    advancedMenuOpen,
    advancedPanelRef,
    allFilters,
    dictionary.menu.transactions.ledgerAnalysis.ledger,
    dictionary.menu.transactions.vendorAnalysis.vendor,
    handlePageSizeChange,
    pageSize,
    setCustomFilters,
  ]);

  return (
    <Stack>
      <GridToolbarContainer
        sx={{
          marginTop: '16px',
          marginBottom: '4px',
          padding: '0px',
        }}
      >
        <Box className="_d-flex-ali-center-jc-sb" sx={{ width: '100%' }}>
          <Box className="_d-flex-ali-center-sm-gap">
            <GridToolbarFilterButton
              ref={setFilterButtonEl}
              componentsProps={{
                button: {
                  size: 'medium',
                  color: 'info',
                  sx: { gap: 0 },
                },
              }}
            />
            {hasExportPermission && (
              <CustomLoadingButton
                isLoading={isLoadingExportingTransaction}
                loadingBtnLabel={dictionary.processing}
                onClick={exportButtonClickHandler}
                color="info"
              >
                <ArrowDownTrayIcon/>
                {dictionary.transaction.transactionList.export}
              </CustomLoadingButton>
            )}
            <Button
              color={advancedMenuOpen ? 'success' : 'secondary'}
              //@ts-ignore
              onClick={() => setAdvancedMenuOpen((prev) => !prev)}
            >
              {dictionary.transaction.transactionList.advanced}
            </Button>
            <ClassificationToggleButtonGroup
              exclusive
              size="small"
              defaultValue={EmissionClassificationStatus.ALL}
              value={
                filters.confidence ||
                classificationStatus ||
                EmissionClassificationStatus.ALL
              }
              onChange={(event, value) => onClassificationStatusChanged(value)}
            />
          </Box>

          <TextField
            placeholder="Search"
            size="small"
            style={{ boxShadow: '0px 1px 6px 0px #30313d08', width: 230 }}
            variant="outlined"
            defaultValue={currSearch}
            value={newSearch || ''}
            onChange={({ target: { value } }) => setSearch(value.trim() === '' ? undefined : value)}
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
            }}
          />
        </Box>
        <Grid item xs={12}>
          <TransactionsAdvancedPanel/>
        </Grid>
      </GridToolbarContainer>
      <SelectAll
        pageCount={selected.length}
        show={showSelectAll}
        totalDatasetCount={count}
        isWholeDatasetSelected={isWholeDatasetSelected}
        onSelectAll={setIsWholeDatasetSelected}
      />
    </Stack>
  );
};
