import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Divider, List, Tooltip, Typography } from '@mui/material';


export const DropdownMenuItems: FunctionComponent<DropdownMenuItemsProps> = (props) => {
  const { enabled = true, subItems = [], title, withDivider } = props;

  const enabledSubItems = React.useMemo(() => (
    subItems.filter(({ enabled }) => enabled !== false)
  ), [subItems]);

  const enabledSection = React.useMemo(() => (
    enabled && !!enabledSubItems.length
  ), [enabled, enabledSubItems]);

  if (!enabledSection) {
    return null;
  }


  return (
    <List className="_d-grid-xs-gap" sx={{ p: 0 }}>
      {withDivider && <Divider sx={{ paddingTop: 0.5 }}/>}
      {title && (
        <Typography variant="h6" color="text.tertiary" px={1}>
          {title}
        </Typography>
      )}
      {enabledSubItems?.map((item, index) => {
        const functionalProps = !item.onClick ? item.isRedirect ? {
          component: 'a',
          href: item.link,
          target: '_blank',
        } : {
          component: NavLink,
          to: item.link,
        } : {};

        const MenuAction = () => (
          <Button
            {...functionalProps}
            onClick={item.onClick}
            className="nav-btn"
            color="secondary"
            variant="text"
          >
            {item.icon}
            <span className="btn-text">
              {item.title}
            </span>
          </Button>
        );

        return (
          <li key={`dropdown-menu-item-${index}`}>
            {item.tooltip
              ? (
                <Tooltip key={index} title={item.tooltip} placement="left">
                  <span><MenuAction/></span>
                </Tooltip>
              )
              : <MenuAction/>
            }
          </li>
        );
      })}
    </List>
  );
};

export interface DropdownMenuItemsProps {
    enabled?: boolean;
    subItems?: NavButtonProps[];
    title?: string;
    withDivider?: boolean;
}

interface NavButtonProps {
  title: ReactNode;
  enabled?: boolean;
  link?: string;
  id?: number;
  icon?: ReactElement;
  isRedirect?: boolean;
  isWorkspace?: boolean;
  onClick?: () => void;
  tooltip?: ReactNode;
}
