// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Card = (theme: Theme) => {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          transitionDuration: '400ms',
          transitionProperty: 'box-shadow',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: 20,
          fontWeight: 600,
          color: '#333333',
        },
      },
    },
  };
};

export default Card;
