import { useState, forwardRef, Fragment } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { Grid, Slider } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { Box } from '@mui/system';
import { replaceValueOnString } from 'views/LoggedIn/Employees/EmployeesHelpers';
import {
  createGradientString,
  createObject,
  getOutput,
} from './multiSliderHelpers';
import { Options, SliderLabelColors } from './form.types';


interface ISliderProps extends ControllerRenderProps {
  highlightedLegendColors: SliderLabelColors;
  legendColors: SliderLabelColors;
  options: Options;
  legendLabel: string;
  sliderCircleColor: string;
  errorMessage?: string;
  minStr?: string,
  stepStr?: string,
  maxStr?: string,
  disableSwap?: boolean,
}

const MultipleSlider = forwardRef<HTMLDivElement, ISliderProps>(
  (props, ref) => {
    const {
      onChange: reactHookFormOnchange,
      value: reactHookFormValue,
      highlightedLegendColors,
      legendColors,
      options,
      legendLabel,
      sliderCircleColor = 'black',
      minStr = '0',
      maxStr = '100',
      stepStr = '1',
      errorMessage,
      disableSwap=true,
      ...restProps
    } = props;

    const [rawValue, setRawValue] = useState<number[] | number>(
      getOutput(reactHookFormValue, options),
    );

    const min = parseFloat(minStr);
    const max = parseFloat(maxStr);
    const step = parseFloat(stepStr);
    const [informationColors, setInformationColors] = useState(legendColors);

    const onChangeSliderHandler = (e: Event, value: number[] | number) => {
      reactHookFormOnchange(createObject(options, value as number[]));
      setRawValue(value);
    };

    return (
      <Fragment>
        <Grid ref={ref} item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap"
          >
            {options.map((option) => {
              const { value, ...restOption } = option;
              const [key, label] = Object.entries(restOption)[0];
              return (
                <Grid item key={value}>
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      cursor: 'pointer',
                    }}
                    onMouseEnter={() =>
                      setInformationColors((prev) => ({
                        ...prev,
                        [key]: highlightedLegendColors[key],
                      }))
                    }
                    onMouseLeave={() =>
                      setInformationColors((prev) => ({
                        ...prev,
                        [key]: legendColors[key],
                      }))
                    }
                  >
                    <Grid item>
                      <Circle
                        sx={{
                          color: informationColors[key],
                          width: 12,
                          height: 12,
                        }}
                      />
                    </Grid>
                    <Grid item sx={{ fontSize: 12, fontWeight: 400 }}>
                      {replaceValueOnString(legendLabel, {
                        value: reactHookFormValue[key] || reactHookFormValue[key] === 0
                          ? reactHookFormValue[key]
                          : reactHookFormValue,
                        option: label,
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Box px={2} mt={2}>
          <Slider
            disableSwap={disableSwap}
            sx={{
              '& .MuiSlider-rail': {
                background: createGradientString(
                  options,
                  informationColors,
                  rawValue,
                  max,
                ),
                opacity: '1',
              },
              '& .MuiSlider-thumb': {
                background: sliderCircleColor,
                '& .Mui-focusVisible': {
                  boxShadow: '0px 0px 0px 8px rgb(30 120 255/ 16%)',
                },
                '&:hover': {
                  boxShadow: '0px 0px 0px 8px rgb(30 120 255/ 16%)',
                },
              } }}
            value={rawValue}
            track={false}
            min={min}
            step={step}
            max={max}
            onChange={onChangeSliderHandler}
            {...restProps}
          />
        </Box>
      </Fragment>
    );
  },
);

export default MultipleSlider;
