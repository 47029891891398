import React from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { csvParser } from 'modules/Expenses/helpers/csvParse';
import { filesize } from 'filesize';
import { PREVIEW_ROW_LIMIT } from 'modules/Expenses/helpers/integrationOptions';
import { LocalizationContext } from 'contexts';
import { ReactComponent as CsvImage } from 'shared/img/csv.svg';
import { Dropzone, MAX_FILE_SIZE_MB } from 'shared/components/layout/Dropzone/Dropzone';
import { IFileUpload } from 'shared/components/layout/Dropzone/dropInterfaces';
import { InsightCard } from 'shared/components/layout/InsightCard/InsightCard';


const fileReader = new FileReader();

const acceptedFileExtensions = {
  'text/csv': ['.csv'],
};

export const UploadFileStep = (props: UploadFileStepProps) => {
  const {
    fileUploaded,
    isFirstRowHeaders,
    setFileText,
    setFileColumnsPreview,
    setRowsNumPreview,
    setFileUploaded,
    templateUrl,
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);

  const dropZoneMsgs = React.useMemo(() => ({
    dropZoneMsg: dictionary.expenses.uploadIntegration.uploadFile.chooseOrDrag,
    dropZoneExtensionRejectedMsg: dictionary.expenses.uploadIntegration.uploadFile.error(),
    dropZoneSizeRejectedMsg: dictionary.expenses.uploadIntegration.uploadFile.error(),
    dropZoneAcceptedMsg: dictionary.expenses.uploadIntegration.uploadFile.chooseOrDrag,
  }),
  [dictionary],
  );

  const dropHandler = React.useCallback(({ file, isAccepted }: { file: File; isAccepted: boolean }) => {
    if (!file) return;

    fileReader.readAsText(file);
    fileReader.onload = (e) => {
      const csv = e.target?.result;
      if (typeof csv === 'string') {
        setFileText(csv);
        const [previewCols, rowsNum] = csvParser.csvToColumns(csv, isFirstRowHeaders, PREVIEW_ROW_LIMIT);

        setFileColumnsPreview(previewCols);
        setRowsNumPreview(rowsNum);
      }
    };

    setFileUploaded({
      name: file.name,
      readableSize: filesize(file.size) as number,
      size: file.size,
      file: file,
      type: file.type,
      isAccepted,
    });
  },
  [
    isFirstRowHeaders,
    setFileColumnsPreview,
    setFileText,
    setFileUploaded,
    setRowsNumPreview,
  ],
  );

  return (
    <Box className="hide-dialog-file-name">
      <Dropzone
        onDrop={dropHandler}
        onFileReject={dropHandler}
        fileUploaded={fileUploaded}
        acceptedFileExtensions={acceptedFileExtensions}
        dropZoneMsgs={dropZoneMsgs}
      />

      <Box className="_d-flex-jc-sb" mt={1}>
        <Typography variant="body1" color="text.secondary">
          {dictionary.expenses.uploadIntegration.uploadFile.supported()}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {dictionary.expenses.uploadIntegration.uploadFile.maxSize(MAX_FILE_SIZE_MB)}
        </Typography>
      </Box>

      <InsightCard
        title={dictionary.expenses.uploadIntegration.uploadFile.template.title}
        text={dictionary.expenses.uploadIntegration.uploadFile.template.desc}
        image={<CsvImage style={{ alignSelf: 'start' }}/>}
        containerProps={{ mt: 6 }}
        actions={
          <Button
            color="secondary"
            href={templateUrl}
            download
          >
            {dictionary.download}
          </Button>
        }
      />
    </Box>
  );
};

interface UploadFileStepProps {
    fileUploaded: IFileUpload | null;
    isFirstRowHeaders: boolean;
    setFileText: any;
    setFileColumnsPreview: any;
    setRowsNumPreview: any;
    setFileUploaded: any;
    templateUrl: string;
}