import { FC, useContext, useCallback, Fragment, useMemo } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { Percent } from '@mui/icons-material';
import { Stack, Typography, TextField, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { isNumber } from 'lodash';

import { cacheKeys } from 'config';
import { LayoutContext, PermissionContext } from 'contexts';
import { numberToLocale, numberToPercentage } from 'helpers/number';
import { scenarioBuilderClient } from 'clients/scenarioBuilder/scenarioBuilderClient';


interface Props {
  id: number;
  value: number;
  percentage: number;
  editable: boolean;
  showValue: boolean;
}

export const ValueCell: FC<Props> = ({ id, value, percentage, editable, showValue }) => {
  const { genericError } = useContext(LayoutContext);
  const { getPermission } = useContext(PermissionContext);
  const queryClient = useQueryClient();

  const hasEditPermission = useMemo(() => getPermission('scenarioBuilder.yearlyPrediction.edit'), [getPermission]);

  const updateCell = useMutation(scenarioBuilderClient.updateCell, {
    mutationKey: cacheKeys.scenarioBuilder.editCell,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.scenarioBuilder.getScenarioBuilderData);
    },
    onError: () => {
      genericError();
    },
  });

  const onChange = useCallback((updatedValue: any) => {
    if (updatedValue === '' || `${(percentage * 100).toFixed(2)}` === updatedValue) {
      return;
    }

    updateCell.mutateAsync({
      id,
      reduction_value: (updatedValue / 100).toFixed(4),
    });
  }, [id, percentage, updateCell]);

  const onKeyPress = useCallback((event: any) => {
    if (event.key === 'Enter') {
      onChange(event.target.value);
    }
  }, [onChange]);

  return (
    <Stack>
      <Box p={1.5}>
        {isNumber(value) && <Typography variant="body1" fontSize={13} color="#858585">{numberToLocale(value/1000)}</Typography>}
      </Box>
      {showValue && (
        <Fragment>
          {(!editable || !hasEditPermission) && (
            <Box
              p={1.5}
            >
              {isNumber(percentage) && <Typography variant="body1" fontSize={13}>{numberToPercentage(percentage)}</Typography>}
            </Box>
          )}
          {editable && hasEditPermission && (
            <Box
              p={1.5}
              px={1}
              bgcolor="#FFFFFF"
            >
              <TextField
                key={`${id}-${percentage}`}
                variant="outlined"
                defaultValue={(percentage * 100).toFixed(2)}
                size="small"
                sx={{
                  width: 64,
                  '.MuiOutlinedInput-root': {
                    paddingRight: 0.5,
                    fontSize: 13,
                  },
                  '.MuiOutlinedInput-input': {
                    paddingLeft: 0.6,
                  },
                }}
                onBlur={(event) => onChange(event.target.value)}
                onKeyPress={onKeyPress}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><Percent fontSize="inherit"/></InputAdornment>,
                }}
              />
            </Box>
          )}
        </Fragment>
      )}
    </Stack>
  );
};
