import React from 'react';
import { Box } from '@mui/system';

import { primary } from 'shared/styles/muiTheme';
import { IEmissionBoxBarProps, IemissionBoxBarSizes, IEmissionBoxBarVariants } from './EmissionBoxSection.types';


const totalEmissions = {
  bgcolor: primary,
};

const offset = {
  bgcolor: '#A1DDDB',
};

const netEmissions = {
  bgcolor: '#E5E7EB',
};

const big = {
  width: 20,
  height: 54,
};

const small = {
  width: 8,
};

const EmissionBoxBar: React.FunctionComponent<IEmissionBoxBarProps> = ({
  variant,
  size,
  smallBarHeight,
}) => {
  const emissionBoxBarVariants: IEmissionBoxBarVariants = {
    offset,
    netEmissions,
    totalEmissions,
  };

  const emissionBoxBarSizes: IemissionBoxBarSizes = {
    big,
    small: {
      ...small,
      height: smallBarHeight,
    },
  };

  return <Box sx={{ borderRadius: '4px', ...emissionBoxBarVariants[variant], ...emissionBoxBarSizes[size] }}/>;
};

export default EmissionBoxBar;
