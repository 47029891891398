import { SvgIcon, SvgIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { rotateAnimation } from 'shared/components/muiAnimations/muiAnimation';


export interface CustomSvgIconProps extends SvgIconProps {
  variant?: 'dragAccepted' | 'dragDropped';
}

const CustomSvgIcon = styled(SvgIcon, {
  shouldForwardProp: (prop) => prop !== 'success',
})<CustomSvgIconProps>(({ variant }) => {

  const variants = {
    dragAccepted: {
      '@keyframes rotate40': rotateAnimation({ degreeStart: 0, degreeFinal: 40 }),
      animation: 'rotate40 0.2s linear forwards',
    },
    dragDropped: {
      '@keyframes rotate40back': rotateAnimation({ degreeStart: 40, degreeFinal: 0 }),
      animation: 'rotate40back 0.2s linear forwards',
    },
  };

  return variants[variant as keyof typeof variants];
});

export default CustomSvgIcon;
