import { FC, useContext } from 'react';
import { useMutation } from 'react-query';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { useWebsocket } from 'contexts/WebsocketContext/WebsocketContext';
import { WorkspaceMappedSwitchData } from 'contexts/WebsocketContext/types';
import { usersClient } from 'clients/users/usersClient';
import { useWorkspaces } from 'shared/hooks/useWorkspaces';


export const WorkspaceChangeDialog: FC<{data: WorkspaceMappedSwitchData, onClose: () => void, open: boolean}> = (props) => {
  const { data, onClose, open } = props;
  const { genericError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { socketId } = useWebsocket();
  const { clearWorkspaceCache } = useWorkspaces();

  const changeWorkspace = useMutation(usersClient.changeWorkspace, {
    mutationKey: cacheKeys.workspaces.changeWorkspace,
    onSuccess: () => {
      onClose();
    },
    onError: () => {
      onClose();
      genericError();
    },
  });

  // By default, accept the workspace change and close the dialog
  const handleClose = () => {
    clearWorkspaceCache();
    onClose();
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      PaperProps={{ className: 'dialog-root' }}
    >
      <DialogContent className="dialog-content">
        {dictionary.workspace.changedWorkspace}
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button color="secondary" onClick={() => changeWorkspace.mutate({ workspaceId: parseInt(data.prevWorkspaceId), socketId: socketId })}>
          {dictionary.workspace.cancelWorkspaceChange}
        </Button>
        <Button color="secondary" onClick={handleClose}>
          {dictionary.workspace.confirmWorkspaceChange}
        </Button>
      </DialogActions>
    </Dialog>
  );};