import { FC, Fragment, useContext } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { useQueryState } from 'shared/hooks/useQueryState';
import { CompanyPlanAlert } from 'shared/components/alerts/CompanyPlanAlert';

import { FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { OtherABTransactions } from './OtherABTransactions';
import { RequiredABTransactions } from './RequiredABTransactions';


enum TabValue {
  GasAndElectricity = 'gasAndElectricity',
  Other = 'other'
}

export const ActivityBasedTransactions: FC = () => {
  const { dictionary } = useContext(LocalizationContext);
  const [tabValue, setTabValue] = useQueryState('tab', TabValue.GasAndElectricity);

  const handleTabChange = (event: any, newValue: TabValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <CompanyPlanAlert/>
      </Grid>
      <Grid item>
        <FiltersSection
          title={dictionary.pageTitles.activityInputs}
        >
          {({ filters }) => (
            <Fragment>
              <Grid item>
                <Box sx={{ mt: 5, display: 'inline-block' }}>
                  <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Gas and electricity" value={TabValue.GasAndElectricity}/>
                    <Tab label="Other optional" value={TabValue.Other}/>
                  </Tabs>
                </Box>
              </Grid>
              <Grid item>
                {tabValue === TabValue.GasAndElectricity && <RequiredABTransactions filters={filters}/>}
                {tabValue === TabValue.Other && <OtherABTransactions filters={filters}/>}
              </Grid>
            </Fragment>
          )}
        </FiltersSection>
      </Grid>
    </Grid>
  );
};
