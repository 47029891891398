import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { primary, outlineLight, error } from 'shared/styles/muiTheme';

import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext, WaitingAsyncProcessContext } from 'contexts';
import { AsyncProcessType } from 'contexts/WaitingAsyncProcessContext/types';
import { Dictionary } from 'contexts/LocalizationContext/types';
import { formatToApi } from 'helpers/date';
import { CompanyTag } from 'clients/companyTags/companyTagClient.types';
import { transactionClient } from 'clients/transactions/transactionClient';
import { CsvColumnOption, UploadCsvSteps, useUploadCsvWizard } from 'shared/hooks/useUploadCsvWizard';
import { useCompanyTags } from 'shared/hooks/useCompanyTags';
import { CompanyTagAutocomplete } from 'shared/components/layout/CompanyTagAutocomplete/CompanyTagAutocomplete';
import { DataGridWithSingleClickCellEdit as DataGrid } from 'shared/components/interactive/DataGrid/DataGridWithSingleCellEdit';
import { InformationToolTipContent } from 'shared/components/layout/Tooltip/InformationToolTipContent';
import { UploadCsvWizard } from 'shared/components/dialog/UploadCsvWizard/UploadCsvWizard';
import { CompletedSectionsIds } from './RequiredABTransactions';


interface GetColumnsParams {
  subCategoryName: string;
  subCategoryUnit?: string;
  endDateTooltip: string;
}

const getColumns = ({ subCategoryName, subCategoryUnit, endDateTooltip }: GetColumnsParams): GridColDef[] => {
  const columns: Array<GridColDef | undefined> = [
    {
      field: 'date',
      headerName: 'Start date *',
      width: 120,
      editable: true,
      sortable: false,
      type: 'date',
      disableColumnMenu: true,
      pinnable: false,
      valueFormatter: ({ value }: any) => value && format(new Date(value as string), 'dd/MM/yyyy'),
    },
    {
      field: 'endDate',
      width: 120,
      editable: true,
      sortable: false,
      type: 'date',
      disableColumnMenu: true,
      pinnable: false,
      valueFormatter: ({ value }: any) => value && format(new Date(value as string), 'dd/MM/yyyy'),
      renderHeader: () => (
        <div className="_d-flex-ali-center-sm-gap">
          End date
          <InformationToolTipContent isBgDarker tooltipTitle={endDateTooltip}/>
        </div>
      ),
    },
    {
      field: 'vendorName',
      sortable: false,
      headerName: 'Vendor',
      flex: 1,
      hide: false,
      resizable: true,
      pinnable: false,
      editable: true,
      disableColumnMenu: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      editable: true,
      sortable: false,
      resizable: true,
      hide: false,
      disableColumnMenu: true,
      flex: 1,
      pinnable: false,
    },
    {
      field: 'consumption',
      headerName: (subCategoryUnit ? subCategoryName.concat(' ', subCategoryUnit) : subCategoryName) + ' *',
      sortable: false,
      editable: true,
      width: 220,
      disableColumnMenu: true,
      pinnable: false,
    },
    {
      field: 'netAmount',
      headerName: 'Net cost',
      sortable: false,
      editable: true,
      width: 120,
      disableColumnMenu: true,
      pinnable: false,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      sortable: false,
      editable: true,
      width: 120,
      disableColumnMenu: true,
      pinnable: false,
    },
  ];
  const filteredColumns: Array<GridColDef> = columns.filter((column) => column !== undefined) as Array<GridColDef>;
  return filteredColumns;
};

interface Props {
  subCategoryId: number;
  subCategoryName: string;
  subCategoryUnit: string;
  setForceExpandCompleted: any;
}

const defaultRowData = {
  date: null,
  endDate: null,
  vendorName: '',
  description: '',
  netAmount: '',
  consumption: '',
  currency: '',
};

export const ManualEntryTransactionsList: FC<Props> = ({ subCategoryId, subCategoryName, subCategoryUnit, setForceExpandCompleted }) => {
  const apiRef = useGridApiRef();
  const queryClient = useQueryClient();
  const { genericInfo, genericError, genericSuccess } = useContext(LayoutContext);
  const { currencyLocale: { symbol: currencySymbol }, dictionary } = useContext(LocalizationContext);
  const { asyncProcesses } = React.useContext(WaitingAsyncProcessContext);
  const { companyTags } = useCompanyTags();
  const [ showError, setShowError ] = useState(false);
  const isGas = useMemo(() => subCategoryName === 'gas', [subCategoryName]);

  const isUploadDisabled = React.useMemo(() =>
    asyncProcesses.some((process) => process.processType === AsyncProcessType.CsvUpload),
  [asyncProcesses],
  );

  const tableData = useMemo(() => {
    const numberOfRows = 12;
    const rows = Array.from({ length: numberOfRows }).map((_, index) => ({
      rowId: index + 1,
      ...defaultRowData,
    }));

    return rows;
  }, []);

  const [tags, setTags] = useState<CompanyTag[]>([]);

  const isRowValid = useCallback((row: any) => {
    const { rowId, ...rowData } = row;
    const hasSomeData = Object.values(rowData).some((value: any) => !!value);

    const isRequiredDataMissing = [
      row.date,
      row.consumption,
    ].some((value: any) => !value);

    return hasSomeData ? !isRequiredDataMissing : true;
  }, []);

  const handleOpenAndScrollToTransactions = useCallback(() => {
    setForceExpandCompleted(true);
    setTimeout(() => {
      const element = document.getElementById(isGas ? CompletedSectionsIds.GAS : CompletedSectionsIds.ELECTRIC);
      const scrollContainer = document.querySelector('#mainScrollContainer');
      const rect = element?.getBoundingClientRect();
      scrollContainer?.scroll({
        top: (rect?.top || 0) + scrollContainer.scrollTop,
        behavior: 'smooth',
      });
    }, 1000);
  }, [isGas, setForceExpandCompleted]);

  const saveTransaction = useMutation(transactionClient.saveManualInputTransaction, {
    mutationKey: cacheKeys.transactions.saveManualInputTransaction,
    onSuccess: () => {
      genericInfo(dictionary.transaction.edit.transactionsSaved);
      setTags([]);
      queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
      queryClient.invalidateQueries(cacheKeys.activityBasedSubCategories.getActivityBasedSubCategories);
    },
    onError: () => {
      genericError();
    },
  });

  const uploadTransactions = useCallback(async (transactionsData: any, isCsv?: boolean) => {
    const payload = {
      timeInterval: 'monthly',
      subCategoryId,
      transactions: transactionsData,
    };

    if (isCsv) {
      await transactionClient.saveManualInputTransaction(payload);
      queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
      queryClient.invalidateQueries(cacheKeys.activityBasedSubCategories.getActivityBasedSubCategories);
      return;
    }

    await saveTransaction.mutateAsync(payload);
  }, [subCategoryId, saveTransaction, queryClient]);

  const onSave = useCallback(async () => {
    const dataMap = Array.from(apiRef.current.getRowModels());
    setShowError(true);

    if (!dataMap.every(([key, row]) => isRowValid(row))) {
      return;
    }

    const transactionsData = dataMap.map(([key, value]) => ({
      ...value,
      tags: (tags || []).map((tag) => tag.name).join(','),
      date: formatToApi(value.date),
      endDate: value.endDate ? formatToApi(value.endDate) : null,
      netAmount: value.netAmount ? value.netAmount : null,
    })).filter((row: any) => row.date && row.consumption);

    if (isEmpty(transactionsData)) {
      return;
    }

    uploadTransactions(transactionsData);
  }, [apiRef, uploadTransactions, isRowValid, tags]);

  const onSendFile = useCallback(async (rows: any[]) => {
    if (isEmpty(rows)) {
      return;
    }

    await uploadTransactions(rows, true);

    genericSuccess(dictionary.transaction.transactionList.uploadSuccess, 10000);
    handleOpenAndScrollToTransactions();
  }, [dictionary, uploadTransactions, genericSuccess, handleOpenAndScrollToTransactions]);

  const csvGasColumnOptions = (dictionary: Dictionary, currencySymbol?: string) => [
    { key: 'date', title: 'Start date' },
    { key: 'endDate', title: 'End date', isOptional: true, helpText: dictionary.activityInputs.endDateHelp },
    { key: 'consumption', title: 'Gas (m³)' },
    { key: 'description', title: 'Description', isOptional: true },
    { key: 'vendorName', title: 'Vendor', isOptional: true },
    { key: 'netAmount', title: 'Net cost', isOptional: true },
    { key: 'currency', title: 'Currency', isOptional: true, helpText: `If left empty, all transactions will default to the company's currency${currencySymbol ? ` (${currencySymbol}).` : '.'}` },
    { key: 'tags', title: 'Tags', isOptional: true },
  ];

  const CsvGasColumnOptions: CsvColumnOption[] = useMemo(() => csvGasColumnOptions(dictionary, currencySymbol), [currencySymbol, dictionary]);

  const CsvElectricityColumnOptions = useMemo(() => CsvGasColumnOptions
    .map((c) => c.key === 'consumption' ? { ...c, title: 'Electricity (KWh)' } : c),
  [CsvGasColumnOptions],
  );

  const uploadCsvWizardStore = useUploadCsvWizard({
    columnOptions: isGas ? CsvGasColumnOptions : CsvElectricityColumnOptions,
    uploadFileStepProps: {
      handleSendFile: onSendFile,
      closeOnSuccess: true,
      templateUrl: `/files/Coolset - activity inputs (${isGas ? 'gas' : 'electricity'}) - template.csv`,
    },
  });
  const {
    setIsUploadCsvDialogOpen,
    setWizardStep,
  } = uploadCsvWizardStore;

  const stepTitle = isGas ? dictionary.activityInputs.uploadCsv.stepGasTitle : dictionary.activityInputs.uploadCsv.stepElectricityTitle;
  const stepSubTitle = isGas ? dictionary.activityInputs.uploadCsv.stepGasSubTitle : dictionary.activityInputs.uploadCsv.stepElectricitySubTitle;

  const handleOpenCsvUploadDialog = () => {
    setWizardStep(UploadCsvSteps.UPLOAD_FILE);
    setIsUploadCsvDialogOpen(true);
  };

  const gridColumns = useMemo(() => {
    const columnsDef = getColumns({
      subCategoryName,
      subCategoryUnit,
      endDateTooltip: dictionary.transaction.transactionList.endDateTooltip,
    });
    return columnsDef;
  }, [subCategoryName, subCategoryUnit, dictionary]);

  return (
    <div>
      <Box
        className="_d-flex-ali-end"
        sx={{ justifyContent: isEmpty(companyTags) ? 'end' : 'space-between' }}
        mb={3}
      >
        {!isEmpty(companyTags) && (
          <div>
            <Typography variant="body1" mb={1}>
              {dictionary.transaction.transactionList.tagsLabel}
            </Typography>
            <CompanyTagAutocomplete
              value={tags}
              onChange={setTags}
            />
          </div>
        )}
        <Button
          color="secondary"
          onClick={handleOpenCsvUploadDialog}
          disabled={isUploadDisabled}
        >
          {dictionary.upload}
        </Button>
      </Box>

      <DataGrid
        autoHeight
        disableSelectionOnClick
        disableColumnFilter
        rows={tableData}
        columns={gridColumns}
        density="compact"
        rowHeight={59}
        headerHeight={52}
        apiRef={apiRef}
        hideFooterRowCount
        getRowClassName={(params) => showError ? (!isRowValid(params.row) ? 'hasError' : '') : ''}
        onCellClick={() => {
          setShowError(false);
        }}
        getRowId={(row) => row.rowId}
        sx={{
          '.MuiDataGrid-cell': {
            borderBottom: `1px solid ${outlineLight}`,
            '&:focus-within': {
              border: `1px solid ${primary}`,
            },
          },
          '.hasError .MuiDataGrid-cell': {
            borderTop: `1px solid ${error}`,
            borderColor: error,
            borderRight: `1px solid ${outlineLight}`,
          },
          '.hasError + .hasError .MuiDataGrid-cell': {
            borderTop: 'none',
          },
          '.hasError .MuiDataGrid-cell:first-child': {
            borderLeft: `1px solid ${error}`,
          },
          '.hasError .MuiDataGrid-cell:last-child': {
            borderRight: `1px solid ${error}`,
          },
        }}
      />

      <LoadingButton
        variant="contained"
        onClick={onSave}
        loading={saveTransaction.isLoading}
      >
        {dictionary.transaction.transactionList.uploadAndSave}
      </LoadingButton>

      <UploadCsvWizard
        uploadCsvWizardStore={uploadCsvWizardStore}
        stepTitle={stepTitle}
        stepSubTitle={stepSubTitle}
      />
    </div>
  );
};
