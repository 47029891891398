// ** MUI Imports
import { Theme } from '@mui/material/styles';
import { dividerColor } from 'shared/styles/theme/palette';


const Divider = (theme: Theme) => {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: dividerColor,
        },
      },
    },
  };
};

export default Divider;
