import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { usersClient } from 'clients/users/usersClient';


type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export type Params = {
  id: string;
};

export const useInvitation = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: invite } = {}, status, error, isLoading } = useQuery(
    [cacheKeys.users.getInvitation, params],
    () => usersClient.getInvite(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    invite,
    isLoading,
  };
};
