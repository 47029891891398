import { FC } from 'react';
import { Language } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { defaultIconPink, greyTextColor } from 'shared/styles/muiTheme';
import { MaybeNull } from 'types';
import { ActivityBasedTransactionStatus, TransactionSubCategory } from 'clients/transactions/transactionClient.types';

import { TransactionsList } from './TransactionsList';


type Props = {
    title: string;
    description: MaybeNull<string>;
    status: ActivityBasedTransactionStatus;
    category: TransactionSubCategory;
    tags?: string[];
    renderCheckBoxSelection?: boolean;
    id?: string;
};

export const TransactionGroup: FC<Props> = ({ title, description, category, status, tags, renderCheckBoxSelection = true, id }) => {
  return (
    <Box id={id}>
      <Box mb={3} display="flex" alignItems="center">
        <Box
          sx={{
            backgroundColor: category.color || defaultIconPink,
            borderRadius: '4px',
            width: '30px',
            height: '30px',
            padding: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {category.icon ? (
            <img src={category.icon} alt={title} style={{ width: 24, height: 24 }}/>
          ) : (
            <Language/>
          )}
        </Box>
        <Typography variant="h6" fontSize={16} color={greyTextColor} ml={2}>{title}</Typography>
      </Box>
      <TransactionsList
        transactionStatus={status}
        subCategoryId={category.id}
        tags={tags}
        renderCheckBoxSelection={renderCheckBoxSelection}
      />
    </Box>
  );
};
