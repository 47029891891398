import React from 'react';
import { ProcessSnackbar, ProcessSnackbarProps } from './ProcessSnackbar';


export const ProcessSnackbars = (props: ProcessSnackbarsProps) => {
  const { snackbars } = props;

  return (
    <div className="layout-snackbars">
      {snackbars.map((snackbar, i) => (
        <ProcessSnackbar key={i} {...snackbar}/>
      ))}
    </div>
  );
};

interface ProcessSnackbarsProps {
    snackbars: ProcessSnackbarProps[];
}