export const cacheKeys = {
  employeeCommuting: {
    getSurvey: 'getSurvey',
    createSurveyAnswer: 'createSurveyAnswer',
    updateSurveyAnswer: 'updateSurveyAnswer',
  },
  reports: {
    getReportAllParagraphs: 'getReportAllParagraphs',
    getReportScopeParagraph: 'getReportScopeParagraph',
    getReportInitiativesParagraph: 'getReportInitiativesParagraph',
    getReportCategoriesParagraph: 'getReportCategoriesParagraph',
    getReportBenefitsParagraph: 'getReportBenefitsParagraph',
    getExportedReport: 'getExportedReport',
  },
  marketplace: {
    getMarketplaceVendors: 'getMarketplaceVendors',
    getMarketplaceFilterOptions: 'getMarketplaceFilterOptions',
  },
  offset: {
    getLuneBundles: 'getLuneBundles',
    getOffsetApiKeys: 'getOffsetApiKeys',
    getOffsetOrders: 'getOffsetOrders',
    getCertificate: 'getCertificate',
  },
  companies: {
    getCompanies: 'getCompanies',
    editCompany: 'editCompany',
    deleteCompany: 'deleteCompany',
  },
  companyTags: {
    getCompanyTags: 'getCompanyTags',
  },
  users: {
    getMe: 'getMe',
    getUser: 'getUser',
    getUsers: 'getUsers',
    editUser: 'editUser',
    editUserRole: 'editUserRole',
    deleteUser: 'deleteUser',
    inviteUser: 'inviteUser',
    getInvitation: 'getInvitation',
    getGroups: 'getGroups',
  },
  transactions: {
    getTransactions: 'getTransactions',
    exportTransactions: 'exportTransactions',
    getTransactionsStatistics: 'getTransactionsStatistics',
    getTransactionsWordAnalysis: 'getTransactionsWordAnalysis',
    getTransaction: 'getTransaction',
    skipManualInputTransactions: 'skipTransactions',
    skipAllOpenTransactions: 'skipAllOpenTransactions',
    saveManualInputAnswers: 'saveManualInputAnswers',
    revertManualInputTransactions: 'revertManualInputTransactions',
    saveManualInputTransaction: 'saveManualInputTransaction',
    bulkClassifyTransactions: 'bulkClassifyTransactions',
    bulkClassifyFilteredTransactions: 'bulkClassifyFilteredTransactions',
    bulkClassifyConfidenceTransactions: 'bulkClassifyConfidenceTransactions',
    bulkClassifyConfidenceFilteredTransactions: 'bulkClassifyConfidenceFilteredTransactions',
    getVendorInsights: 'getVendorInsights',
  },
  activityBasedSubCategories: {
    getActivityBasedSubCategories: 'getActivityBasedSubCategories',
  },
  emissionFactors: {
    getEmissionFactorsAutocomplete: 'getEmissionFactorsAutocomplete',
    getEmissionFactor: 'getEmissionFactor',
    getEmissionFactors: 'getEmissionFactors',
  },
  emissionFactorCategories: {
    getEmissionFactorCategory: 'getEmissionFactorCategory',
    getEmissionFactorCategories: 'getEmissionFactorCategories',
    getElasticEmissionFactorCategories: 'getElasticEmissionFactorCategories',
  },
  charts: {
    getChart: 'getChart',
  },
  integrations: {
    getIntegrations: 'getIntegrations',
    getIntegration: 'getIntegration',
    deleteIntegration: 'deleteIntegration',
    getIntegrationLedgerAndJournals: 'getIntegrationLedgerAndJournals',
    getChiftIntegrationOptions: 'getChiftIntegrationOptions',
    getChiftIntegrationUrl: 'getChiftIntegrationUrl',
    getChiftIntegration: 'getChiftIntegration',
    disconnectChiftIntegration: 'disconnectChiftIntegration',
    manageChiftIntegration: 'manageChiftIntegration',
  },
  scenarioBuilder: {
    getScenarioBuilderData: 'getScenarioBuilderData',
    editCell: 'editCell',
    editForm: 'editForm',
    getTargetProjection: 'getTargetProjection',
    resetData: 'resetData',
  },
  workspaces: {
    getWorkspace: 'getWorkspace',
    getWorkspaces: 'getWorkspaces',
    changeWorkspace: 'changeWorkspace',
  },
};

export const persistKeys = {
  workspaceChange: [
    cacheKeys.users.getMe,
    cacheKeys.workspaces.getWorkspaces,
    cacheKeys.emissionFactorCategories.getEmissionFactorCategories,
  ],
};

export const routes = {
  activityLog: '/activity',
  carbonRemoval: '/carbon-removal',
  marketplace: '/marketplace',
  carbonRemovalRedirect: '/redirect/carbon-removal',
  dataManager: '/data-manager',
  knowledgeBase: '/knowledge-base',
  employees: '/employees',
  activityBasedTransactions: '/data-manager/activity-inputs',
  activityBasedTransactionsOther: '/data-manager/activity-inputs?tab=other',
  emissionFactors: '/data-manager/emission-factors',
  measurements: '/measurements',
  scenarioBuilder: '/scenario-builder',
  drivers: '/drivers',
  home: '/',
  invite: (token: string = ':token') => `/invitation/${token}`,
  impersonateUser: '/impersonate',
  login: '/login',
  myAccount: '/my-account',
  queries: '/queries',
  resetPassword: '/reset-password',
  settings: '/settings',
  integrations: '/integrations',
  expenses: '/expenses',
  linkedAccounting: '/expenses/linked-accounting',
  signup: '/coolset-signup',
  team: '/team',
  leftWorkspace: '/left-workspace',
  noWorkspace: '/no-workspace',
  esrs: '/esrs',
};

export const hideUserNavRoutes = [
  routes.leftWorkspace,
  routes.noWorkspace,
];

export type Routes = keyof typeof routes;

export const routeBreadcrumbs: Record<Routes, Routes[]> = {
  measurements: ['home', 'measurements'],
  employees: ['home', 'employees'],
  marketplace: ['home', 'marketplace'],
  carbonRemoval: ['home', 'carbonRemoval'],
  carbonRemovalRedirect: ['home', 'carbonRemovalRedirect'],
  scenarioBuilder: ['home', 'scenarioBuilder'],
  integrations: ['home', 'integrations'],
  expenses: ['home', 'expenses'],
  linkedAccounting: ['home', 'expenses', 'linkedAccounting'],
  dataManager: ['home', 'dataManager'],
  activityBasedTransactions: ['home', 'dataManager', 'activityBasedTransactions'],
  activityBasedTransactionsOther: ['home', 'dataManager', 'activityBasedTransactions'],
  emissionFactors: ['home', 'dataManager', 'emissionFactors'],
  activityLog: ['home', 'activityLog'],
  drivers: ['home', 'drivers'],
  home: ['home'],
  invite: ['invite'],
  impersonateUser: ['impersonateUser'],
  login: ['login'],
  myAccount: ['home', 'myAccount'],
  queries: ['home', 'queries'],
  resetPassword: ['resetPassword'],
  settings: ['home', 'settings'],
  signup: ['signup'],
  team: ['home', 'team'],
  knowledgeBase: ['home', 'knowledgeBase'],
  leftWorkspace: ['leftWorkspace'],
  noWorkspace: ['noWorkspace'],
  esrs: ['esrs'],
};

export const featureFlags = {
  pages: {
    measurements: true,
    expenses: true,
    emissionFactors: true,
    knowledgeBase: true,
    academy: true,
    feedback: true,
    employees: true,
    marketplace: true,
    carbonRemoval: true,
    scenarioBuilder: true,
    esrs: false,
  },
  drivers: false,
  queries: false,
  team: true,
  activityLog: true,
  transactions: true,
  dataManager: true,
  activityBasedTransactions: true,
  emissionFactors: true,
  settings: false,
  googleSSO: true,
  microsoftSSO: false,
  translations: false,
  darkMode: false,
  teamSort: false,
  teamFilter: false,
  expandableNav: true,
  emissionDateRangeComparison: false,
  showTopSuppliersChart: false,
  showTopVendorsChart: false,
  prefetchCharts: false,
};