import React from 'react';
import { Button } from '@mui/material';
import { WizardDoneStep } from 'modules/Expenses/components/WizardDoneStep';
import { LocalizationContext } from 'contexts';


export const SyncDoneStep = (props:
  {
    integrationName: string;
    onClose: () => void;
    onNextStep: () => void
  },
) => {
  const { integrationName, onClose, onNextStep } = props;
  const { dictionary } = React.useContext(LocalizationContext);

  return (
    <WizardDoneStep
      title={dictionary.expenses.uploadIntegration.syncDone.title(integrationName)}
      subTitle={dictionary.expenses.uploadIntegration.syncDone.subTitle}
      actions={(
        <React.Fragment>
          <Button
            color="secondary"
            onClick={onClose}
          >
            {dictionary.close}
          </Button>
          <Button onClick={onNextStep}>
            {dictionary.expenses.uploadIntegration.uploadDone.review}
          </Button>
        </React.Fragment>
      )}
      stepsCompleted={1}
    />
  );
};