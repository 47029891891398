import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { Grid, Typography, LinearProgress } from '@mui/material';

import { CompanyOverviewSurvey } from 'modules/Surveys/partials/CompanyOverviewSurvey';
import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext, PermissionContext } from 'contexts';
import { companyClient } from 'clients/companies/companyClient';
import { CompanyCrud } from 'clients/companies/companyClient.types';
import { useMe } from 'shared/hooks/useMe';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';

import { FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { CompanyForm } from './partials/CompanyForm';


export const CompanySettings: FC = () => {

  const { genericError, changesSaved } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { getPermission } = useContext(PermissionContext);
  const { user, status, isFetching } = useMe();

  const queryClient = useQueryClient();

  const editCompany = useMutation(companyClient.editCompany, {
    mutationKey: cacheKeys.companies.editCompany,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.companies.getCompanies);
      queryClient.invalidateQueries(cacheKeys.users.getMe);
      changesSaved();
    },
    onError: () => {
      genericError();
    },
  });

  const hasViewPermission = useMemo(() => (
    getPermission('company.crud.view')
  ), [getPermission]);

  const onCompanySubmit = useCallback((data: CompanyCrud) => {
    if (!user?.company) {
      return false;
    }
    return editCompany.mutate({
      ...data,
      id: user?.company?.id,
    });
  }, [user, editCompany]);

  return status !== 'success' || !user ? (
    <BodyLoading height="100vh"/>
  ) : (
    <Grid container direction="column">
      <Grid item xs={12}>
        <FiltersSection title={dictionary.menu.settings}>
          {() => (
            <Grid item xs={12} lg={8} pt={5}>
              <Typography variant="h2" mb={3}>{dictionary.company.companyInfo}</Typography>
              <CompanyForm
                defaultValues={{
                  name: user?.company?.name,
                  nrOfLocations: user?.company?.nrOfLocations,
                  nrOfEmployees: user?.company?.nrOfEmployees,
                }}
                isSubmitting={editCompany.isLoading}
                onSubmitRequest={onCompanySubmit}
                onSubmitButtonText={dictionary.forms.save}
                isFetching={isFetching}
              />
              <LinearProgress sx={{ mt: 2, opacity: editCompany.isLoading ? 1 : 0 }}/>
              {hasViewPermission && (
                <>
                  <Typography variant="h2" mb={3} pt={5}>{dictionary.profile.companyOverviewTitle}</Typography>
                  <CompanyOverviewSurvey
                    name="emission_intensity_with_locations"
                    onSubmitButtonText={dictionary.forms.save}
                  />
                </>
              )}
            </Grid>
          )}
        </FiltersSection>
      </Grid>
    </Grid>
  );
};
