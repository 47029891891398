import React from 'react';
import { Box, Typography } from '@mui/material';
import { round } from 'lodash';
import { CategoryIcon } from 'shared/components/layout/CategoryIcon/CategoryIcon';


export const CategoryListChild = (props: CategoryListChildProps) => {
  const {
    icon,
    name,
    color,
    totalCo2,
    co2Percentage,
    onClick,
    selectedCategoryName,
  } = props;

  const hasNoData = React.useMemo(() =>
    totalCo2 === undefined && co2Percentage === undefined
  , [co2Percentage, totalCo2]);

  return (
    <Box
      className="_d-flex-sm-gap"
      onClick={onClick}
      sx={{
        ml: 3,
        cursor: 'pointer',
        opacity: selectedCategoryName && selectedCategoryName !== name ? 0.5 : 1,
        '&:hover': {
          opacity: 1,
        },
      }}
    >
      <CategoryIcon icon={icon} name={name} color={color} size={16}/>

      <Box>
        <Typography
          variant="body1"
          fontWeight={selectedCategoryName === name ? 600 : ''}
          lineHeight={1}
        >
          {name}
        </Typography>
        {!hasNoData &&
            <Typography variant="body2" color="text.secondary">
              {round(totalCo2! / 1000, 2)}t ({co2Percentage}%)
            </Typography>
        }
      </Box>
    </Box>
  );
};

interface CategoryListChildProps {
    icon?: string;
    name: string;
    color?: string;
    co2Percentage?: string;
    totalCo2?: number;
    onClick: () => void;
    selectedCategoryName?: string;
}