import { useContext } from 'react';
import { Typography } from '@mui/material';

import { LocalizationContext } from 'contexts';


export const DiferencePercentageParagraph = ({
  currentYearValue,
  lastYearValue,
}: {
    currentYearValue: number;
    lastYearValue: number;
  }) => {
  const difference = currentYearValue - lastYearValue;
  const { dictionary } = useContext(LocalizationContext);

  if (lastYearValue === 0) {
    return (
      <Typography fontSize="11px" color="red">
        {dictionary.differencePercentageParagraph.noLastYearData}
      </Typography>
    );
  }

  const percentageDifference = (difference / lastYearValue) * 100;

  if (currentYearValue > lastYearValue) {
    return (
      <Typography fontSize="11px" color="red">
          +{dictionary.differencePercentageParagraph.emissionPercentageComparisson(percentageDifference.toFixed(2))}
      </Typography>
    );
  } else if (currentYearValue < lastYearValue) {
    return (
      <Typography fontSize="11px" color="#249683">
        {dictionary.differencePercentageParagraph.emissionPercentageComparisson(percentageDifference.toFixed(2))}
      </Typography>
    );
  } else {
    return null;
  }
};