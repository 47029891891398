import { useQueryClient } from 'react-query';

/**
 * Returns an array of all active query keys in the cache.
 *
 * @param keysToExclude - An array of query keys to exclude from the returned array.
 *
 */
export const useBulkQueryKeys = (keysToExclude?: string[]) => {
  const queryClient = useQueryClient();
  const queryKeys = [
    ...[...new Set(
      queryClient.getQueryCache().getAll()
        .map(cache => typeof cache.queryKey === 'string' ? cache.queryKey : cache.queryKey[0])
        .filter(queryKey => (!!keysToExclude && keysToExclude?.length) ? !keysToExclude.includes(queryKey as string) : queryKey),
    )],
  ];

  return {
    queryKeys,
  };
};