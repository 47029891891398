import React, { FC, Fragment, useState, useRef, useCallback } from 'react';
import { Popper, Paper, PopperPlacementType, Fade, ClickAwayListener } from '@mui/material';
import { Box } from '@mui/system';

import DropDownContentSelect, {
  IDropDownContentSelectProps,
} from './DropdownContentSelect';


type ContentProps = {
  closeDropdown: () => void;
};

export type DropdownProps = {
  content?: React.ReactElement | ((props: ContentProps) => React.ReactElement);
  placement?: PopperPlacementType;
  dropdownSelectOptions?: Pick<
    IDropDownContentSelectProps,
    'selectOptions' | 'onSelect' | 'ListProps'
  >;
  popperProps?: Omit<React.ComponentProps<typeof Popper>, 'children' | 'open' | 'anchorEl'>;
  children: (props: {
    ref: React.RefObject<HTMLButtonElement>;
    handleToggleDropdownMenu: () => void;
    openDropdownMenu: boolean;
  }) => React.ReactElement;
};

export const Dropdown: FC<DropdownProps> = ({
  children,
  content = <Box/>,
  placement,
  dropdownSelectOptions,
  popperProps,
}) => {
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggleDropdownMenu = useCallback(() => {
    setOpenDropdownMenu((prevOpen) => !prevOpen);
  }, []);

  const handleCloseDropdown = useCallback(() => {
    setOpenDropdownMenu(false);
  }, []);

  return (
    <Fragment>
      {children({
        ref: anchorRef,
        handleToggleDropdownMenu,
        openDropdownMenu,
      })}
      <Popper
        open={openDropdownMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={placement}
        transition
        {...popperProps}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseDropdown}>
                {dropdownSelectOptions ? (
                  <DropDownContentSelect
                    onCloseDropdown={handleCloseDropdown}
                    {...dropdownSelectOptions}
                  />
                ) : (
                  <Fragment>
                    {typeof content === 'function'
                      ? content({ closeDropdown: handleCloseDropdown })
                      : content}
                  </Fragment>
                )}
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Fragment>
  );
};
