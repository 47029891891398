import { Container, ContainerProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { lightTealColor, tealColor, redColor, lightRedColor } from 'shared/styles/muiTheme';


interface CustomContainerProps extends ContainerProps {
  variant: string;
}

const CustomContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'success',
})<CustomContainerProps>(({ variant }) => {
  const variants = {
    drag: {
      border: '1px dashed #9CA3AF',
      borderRadius: '.4rem',
      minHeight: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      'flex-direction': 'column',
      gap: '.8rem',
      height: '180px',
      cursor: 'pointer',
      postion: 'relative',
      color: '#6B7280',
    },
    get dragAccepted() {
      return { ...this.drag, borderColor: tealColor, background: lightTealColor, color: tealColor };
    },
    get dragDropped() {
      return this.dragAccepted;
    },
    get dragSizeRejected() {
      return { ...this.drag, borderColor: redColor, background: lightRedColor, color: redColor, cursor: 'pointer' };
    },
    get dragExtensionRejected() {
      return { ...this.drag, borderColor: redColor, background: lightRedColor, color: redColor, cursor: 'pointer' };
    },
  };

  return variants[variant as keyof typeof variants];
});

export default CustomContainer;
