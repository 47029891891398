import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Alert,
  InputLabel,
  Grid,
  FormHelperText,
  TextField,
  AlertTitle,
  AlertColor,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/system';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Surveys } from 'modules/Surveys/survey';
import { ComponentTypes } from 'modules/Surveys/enums';
import { LightbulbIcon } from 'shared/icons/LightbulbIcon';
import { LocalizationContext } from 'contexts';

import { InformationToolTipContent } from 'shared/components/layout/Tooltip/InformationToolTipContent';

import CustomTextField from 'shared/components/styledMui/CustomTextField';


export type FormAnswer = {
  question: number;
  text: string;
}

export interface Props {
  questions: Surveys.Question[];
  answers: Surveys.Answer[];
  onSubmit: ((data: FormAnswer[]) => void);
  buttonLabel?: string;
  validationSchema?: Yup.AnySchema;
  renderSubmit?: (props: { submitButton: JSX.Element }) => React.ReactNode;
}

export const Form: React.FC<Props> = ({
  questions = [],
  answers,
  renderSubmit,
  onSubmit,
  buttonLabel,
  validationSchema,
}) => {
  const { control, handleSubmit, formState } = useForm<any>({
    defaultValues: questions.reduce((prev, curr) => {
      const answer = answers.find(a => a.question === curr.id);
      return {
        ...prev,
        [curr.name]: answer ? answer.text : null,
      };
    }, {}),
    //@ts-ignore
    resolver: validationSchema ? yupResolver(validationSchema) : null,
    mode: 'all',
  });

  const { currencyLocale } = useContext(LocalizationContext);

  const _onSubmit = React.useCallback((values: any) => {
    const answers: FormAnswer[] = questions.filter(question => [ComponentTypes.input, ComponentTypes.textarea].includes(question.component)).map(question => ({
      question: question.id,
      text: values[question.name],
    }));

    return onSubmit(answers);
  }, [questions, onSubmit]);

  const SubmitButton = (
    <LoadingButton
      type="submit"
      variant="contained"
      loading={formState.isSubmitting}
      disabled={!formState.isDirty}
    >
      {buttonLabel}
    </LoadingButton>
  );

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>

      <Grid container spacing={2}>
        {questions.map(
          ({
            placeholderText,
            name,
            text,
            type,
            tooltip,
            component = ComponentTypes.input,
          }) => {
            return (
              <Grid key={name} item xs={12} mt={1}>
                <Controller
                  name={name}
                  control={control}
                  render={({ fieldState, field }) => (
                    <React.Fragment>
                      {[ComponentTypes.input, ComponentTypes.textarea].includes(component) && (
                        <Stack alignItems="center" gap={1} direction="row">
                          <InputLabel>
                            {text}
                          </InputLabel>
                          {tooltip && <InformationToolTipContent tooltipTitle={tooltip}/>}
                        </Stack>
                      )}
                      {component === ComponentTypes.alert && (
                        <Alert
                          severity={(type || 'info') as AlertColor}
                          icon={type === 'info' && <LightbulbIcon/>}
                        >
                          <AlertTitle>{text}</AlertTitle>
                          {placeholderText}
                        </Alert>
                      )}
                      {component === ComponentTypes.input && (
                        <CustomTextField
                          {...field}
                          value={field.value || null}
                          onChange={value => field.onChange(value.target.value === '' ? null : value)}
                          type={type === 'currency' ? 'number' : type}
                          customvariant="upperLabel"
                          fullWidth
                          error={!!fieldState.error?.message}
                          placeholder={placeholderText}
                          InputProps={{
                            endAdornment: type === 'currency' ? (
                              <InputAdornment position="start">
                                {currencyLocale.symbol}
                              </InputAdornment>
                            ) : undefined,
                            inputProps: {
                              // https://github.com/mui/material-ui/issues/7960#issuecomment-1076959490
                              onWheel: e => e.target instanceof HTMLElement && e.target.blur(),
                            },
                          }}
                        />
                      )}
                      {component === ComponentTypes.textarea && (
                        <TextField
                          {...field}
                          multiline
                          type={type}
                          fullWidth
                          error={!!fieldState.error?.message}
                          placeholder={placeholderText}
                        />
                      )}
                      {fieldState.error?.message && <FormHelperText sx={{ ml: 2, mt: 1, fontSize: '14px' }} error>{fieldState.error?.message}</FormHelperText>}
                    </React.Fragment>
                  )}
                />
              </Grid>
            );
          },
        )}
        <Grid
          item
          xs={12}
          pt={2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          {renderSubmit ? (
            renderSubmit({ submitButton: SubmitButton })
          ) : SubmitButton}
        </Grid>
      </Grid>
    </form>
  );
};
