import React, { ReactElement } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';


export const PreviewTable = (props: PreviewTableProps) => {
  const {
    previewRows,
    columnHeaders,
    overflow,
    isHeadersHaveTooltips,
  } = props;

  const colCount = previewRows[0].length;

  return (
    <Box
      className="preview-table"
      sx={{ '--col-count': colCount, '--table-overflow': overflow || '' }}
    >
      {columnHeaders.map((header, i) => (
        <Tooltip key={i} title={isHeadersHaveTooltips ? header : ''}>
          <Typography
            variant="body2"
            color="text.secondary"
            className="table-header"
          >
            {header}
          </Typography>
        </Tooltip>
      ))}

      {previewRows.map((row) => (
        row.map((cell, i) => (
          <Typography
            key={i}
            variant="body2"
            color="text.secondary"
            className="one-line-text"
          >
            {cell}
          </Typography>
        ))
      ))}

    </Box>
  );
};

interface PreviewTableProps {
    previewRows: Cell[][];
    columnHeaders: Cell[];
    overflow?: string;
    isHeadersHaveTooltips?: boolean;
}

type Cell = string | ReactElement;