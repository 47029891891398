import { useContext } from 'react';
import { useQuery } from 'react-query';

import { Emissions } from 'modules/Emissions/emissions';
import { emissionFactorsClient } from 'modules/Emissions/client/emissionsClient';
import { cacheKeys } from 'config';
import { UserContext } from 'contexts';


type Options = {
  enabled: boolean,
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};


export const useElasticEmissionFactorCategories = (params: Emissions.Categories.GetElasticEmissionFactorCategoryListParams, options: Partial<Options> = defaultOptions) => {

  const { isLoggedIn } = useContext(UserContext);

  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.emissionFactorCategories.getElasticEmissionFactorCategories, params],
    () => emissionFactorsClient.getElasticEmissionFactorCategories(params),
    {
      enabled: isLoggedIn && options.enabled,
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    categories: data?.results || [],
  };
};
