import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { sortBy } from 'lodash';
import { cacheKeys } from 'config';
import { usersClient } from 'clients/users/usersClient';


type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useGroups = (options: Partial<Options> = defaultOptions): Groups => {

  const { data: { data: { results = [] } = {} } = {}, status, error } = useQuery(
    cacheKeys.users.getGroups,
    usersClient.getGroups,
    {
      enabled: options.enabled,
    },
  );

  const groups = useMemo(() =>
    sortBy(results, 'name')
      .filter(group => group.name !== 'NoCompany'),
  [results]);

  return {
    status,
    error,
    groups,
  };
};

export type Group = {
  id: number;
  name: 'Admin' | 'Editor' | 'Viewer' | 'Owner';
  permissions: Record<'codename', string>[];
}

export interface Groups {
  error: unknown;
  groups: Group[];
  status: 'idle' | 'loading' | 'error' | 'success';
}