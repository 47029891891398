import React, { Fragment, MutableRefObject, useContext, useMemo } from 'react';
import {
  GridAlignment,
  GridColDef,
  getGridNumericOperators,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  selectedIdsLookupSelector,
  GridColumnHeaderParams,
  GridColumns,
  GridCellCheckboxRenderer,
} from '@mui/x-data-grid-pro';
import { Tooltip, Typography } from '@mui/material';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { format } from 'date-fns';

import { getTransactionConfidenceStatusLabel } from 'modules/Transactions/helpers/classification';
import { LocalizationContext, PermissionContext } from 'contexts';
import { GRID_DATE_FILTERS } from 'helpers/date';
import { Transaction } from 'clients/transactions/transactionClient.types';
import { DateFilter } from 'shared/components/layout/DataGridCustomFilters/DateFilter/DateFilter';
import SelectAllTableCheckBox from 'shared/components/interactive/Checkbox/SelectAllTableCheckBox';
import { InformationToolTipContent } from 'shared/components/layout/Tooltip/InformationToolTipContent';
import { TagsCell } from 'views/LoggedIn/Measurements/components/TagsCell';
import { CertaintyCell } from 'views/LoggedIn/Measurements/components/CertaintyCell';
import { useDateFilters } from './useDateFilters';


export const useMeasurementTransactionsColumns = ( apiRef: MutableRefObject<GridApiPro>): Array<GridColDef> => {
  const { getPermission } = useContext(PermissionContext);
  const { currencyLocale, dictionary } = useContext(LocalizationContext);

  const { minimumDateFilter } = useDateFilters();

  const columns = useMemo<GridColumns<Transaction>>(() => {
    const canViewTotal = getPermission('transaction.totalAmount.view');
    const canViewEmissionFactor = getPermission('emissionFactor.crud.view');
    const canViewCo2 = getPermission('transaction.co2.view');

    const columns: Array<GridColDef | undefined> = [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        valueGetter: (params) => {
          const selectionLookup = selectedIdsLookupSelector(
            apiRef,
          );
          return selectionLookup[params.id] !== undefined;
        },
        renderHeader: (params) => (
          <React.Fragment>
            <SelectAllTableCheckBox apiRef={apiRef} {...params}/>
          </React.Fragment>
        ),
        renderCell: (params) => <GridCellCheckboxRenderer {...params}/>,
      },
      {
        field: 'date',
        headerName: dictionary.measurements.datagrid.headerNames.date,
        minWidth: 70,
        editable: false,
        sortable: true,
        type: 'date',
        valueGetter: ({ row }: any) => row?.date,
        valueFormatter: ({ value }: any) => value && format(new Date(value as string), 'dd MMM \'\'yy'),
        pinnable: false,
        filterable: true,
        resizable: true,
        filterOperators: GRID_DATE_FILTERS.map((operator) => (
          {
            value: operator,
            label: (dictionary.dataGrid.toolbar.filters.operators as any)[operator],
            getApplyFilterFn: () => null,
            InputComponent: DateFilter,
            InputComponentProps: {
              initialValue: null,
              yearLimit: minimumDateFilter,
            },
          }
        )),
      },
      {
        field: 'ledger',
        headerName: dictionary.measurements.datagrid.headerNames.ledger,
        minWidth: 70,
        sortable: true,
        resizable: true,
        pinnable: false,
        flex: 1,
        renderCell: ({ row, value }: any) => (
          value && (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          )
        ),
      },
      {
        field: 'vendorName',
        sortable: true,
        headerName: dictionary.measurements.datagrid.headerNames.vendor,
        valueGetter: ({ row }: any) => row?.vendorName,
        minWidth: 70,
        hide: false,
        resizable: true,
        pinnable: false,
        flex: 1,
        renderCell: ({ row, value }: any) => (
          value && (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          )
        ),
      },
      {
        field: 'description',
        headerName: dictionary.measurements.datagrid.headerNames.description,
        editable: false,
        sortable: true,
        resizable: true,
        hide: false,
        minWidth: 70,
        flex: 1,
        valueGetter: ({ row }: any) => row?.description,
        pinnable: false,
        renderCell: ({ row, value }: any) => (
          value && (
            <Tooltip title={row?.description}>
              <span>{value}</span>
            </Tooltip>
          )
        ),
      },
      {
        field: 'tags',
        headerName: dictionary.measurements.datagrid.headerNames.tags,
        editable: false,
        sortable: false,
        filterable: false,
        resizable: true,
        minWidth: 110,
        renderCell: (props) => <TagsCell {...props} getTagTooltip={dictionary.filterSection.companyTagsLabels.tagsTooltip}/>,
        pinnable: false,
      },
      ...(canViewTotal ? [{
        field: 'netAmount',
        headerName: dictionary.measurements.datagrid.headerNames.netAmount,
        sortable: true,
        resizable: true,
        minWidth: 20,
        align: 'right' as GridAlignment,
        pinnable: false,
        valueGetter: ({ row }: any) => row.localizedAmount,
        filterOperators: getGridNumericOperators().filter(
          (operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty',
        ),
      }] : []),
      ...(canViewTotal ? [{
        field: 'kgCo2EMultiplier',
        type: 'number',
        headerName: dictionary.measurements.datagrid.headerNames.kgCo2EMultiplier(currencyLocale.symbol),
        sortable: true,
        resizable: true,
        minWidth: 20,
        align: 'right' as GridAlignment,
        valueGetter: ({ row }: any) => row?.emissionClassification?.emissionFactor && row?.emissionClassification?.emissionFactor.kgCo2EMultiplier,
        renderCell: ({ value }: any) => value && (value as number).toFixed(1),
        pinnable: false,
        filterable: false,
      }] : []),
      ...(canViewCo2 ? [{
        field: 'co2Kg',
        type: 'number',
        headerName: dictionary.measurements.datagrid.headerNames.co2Kg,
        editable: false,
        sortable: true,
        resizable: true,
        minWidth: 20,
        align: 'right' as GridAlignment,
        pinnable: false,
        valueGetter: ({ row }: any) => row?.emissionClassification && row.emissionClassification?.co2Kg,
        renderCell: ({ value }: any) => value !== undefined && value !== null &&
            <b>{(value as number).toFixed(2)}</b>,
        cellClassName: 'gc--custom-cell',
      }] : []),
      {
        field: 'scope',
        headerName: dictionary.measurements.datagrid.headerNames.scope,
        editable: false,
        sortable: true,
        resizable: true,
        minWidth: 20,
        align: 'center' as GridAlignment,
        cellClassName: 'gc--custom-cell',
        valueGetter: ({ row }: any) => row?.emissionClassification?.emissionFactor && row.emissionClassification.emissionFactor.scope,
        filterOperators: getGridNumericOperators().filter(
          (operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty',
        ),
        pinnable: false,
      },
      ...(canViewEmissionFactor ? [{
        field: 'emissionFactor',
        headerName: dictionary.measurements.datagrid.headerNames.emissionFactor,
        editable: false,
        sortable: false,
        resizable: true,
        minWidth: 150,
        cellClassName: 'gc--custom-cell',
        valueGetter: ({ row }: any) => row?.emissionClassification?.emissionFactor && row?.emissionClassification?.emissionFactor?.title,
        renderCell: ({ row, value }: any) => (
          value && (
            <Tooltip title={row?.emissionClassification?.emissionFactor?.title && (
              <React.Fragment>
                <Typography variant="body2" fontWeight={500}>
                  {row?.emissionClassification?.emissionFactor?.title}
                </Typography>
                {row?.emissionClassification?.emissionFactor?.description && (row?.emissionClassification?.emissionFactor?.title !== row?.emissionClassification?.emissionFactor?.description) && (
                  <Typography variant="body2" mt={2}>
                    {row?.emissionClassification?.emissionFactor?.description}
                  </Typography>
                )}
                {row?.emissionClassification?.emissionFactor?.source && (
                  <Typography variant="body2" mt={2}>
                    {dictionary.emissionFactors.labelSource}: {row?.emissionClassification?.emissionFactor?.source}
                    {row?.emissionClassification?.emissionFactor?.year && `, ${row?.emissionClassification?.emissionFactor?.year}`}
                  </Typography>
                )}
              </React.Fragment>
            )}>
              <span>{value}</span>
            </Tooltip>
          )
        ),
        pinnable: false,
      }] : []),
      {
        field: 'confidence',
        headerName: dictionary.measurements.datagrid.headerNames.certainty,
        editable: false,
        sortable: true,
        filterable: false,
        resizable: true,
        minWidth: 160,
        cellClassName: 'gc--custom-cell',
        valueGetter: ({ row }: any) => row?.emissionClassification && row?.emissionClassification?.confidence,
        renderCell: (props) => <CertaintyCell {...props} getChipValue={(row) => getTransactionConfidenceStatusLabel(dictionary, row)}/>,
        renderHeader: (params: GridColumnHeaderParams) => (
          <Fragment>
            {dictionary.measurements.datagrid.headerNames.certainty}
            <InformationToolTipContent
              isBgDarker
              containerProps={{ ml: '6px' }}
              tooltipTitle={
                <span style={{ whiteSpace: 'pre-line' }}>
                  {dictionary.measurements.datagrid.headerDescriptions.certainty}
                </span>
              }
              tooltipProps={{
                placement: 'bottom-end',
                arrow: true,
                sx: {
                  width: '260px',
                },
              }}
            />
          </Fragment>
        ),
        pinnable: false,
      },
      {
        field: 'integrationName',
        headerName: dictionary.measurements.datagrid.headerNames.integrationName,
        valueGetter: ({ row }: any) => row?.integration?.name,
      },
    ];
    const filteredColumns: Array<GridColDef> = columns.filter((column) => column !== undefined) as Array<GridColDef>;
    return filteredColumns;
  }, [dictionary, minimumDateFilter, apiRef, getPermission, currencyLocale]);

  return columns;
};
