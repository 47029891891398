import React from 'react';
import { Box, Chip, Paper, Typography } from '@mui/material';


export const AnalysisCard = ({
  id,
  name,
  transactionText,
  journalName,
  isNull,
}: AnalysisCardProps) => (
  <Paper
    sx={{
      borderRadius: 1,
      px: 2,
      py: 1.5,
    }}
  >
    <Box className="_d-flex-ali-center-jc-sb-xxs-gap" data-item-id={id}>
      <Typography
        variant="body2"
        color={isNull ? 'text.tertiary' : undefined}
        fontStyle={isNull ? 'italic' : ''}
      >
        {name}
      </Typography>
      <Typography variant="body2" color="text.secondary" whiteSpace="nowrap">{transactionText}</Typography>
    </Box>
    {journalName && (
      <Chip
        label={`in ${journalName}`}
        color="primary"
        size="small"
        sx={{ mt: 1, fontSize: '11px' }}
      />
    )}
  </Paper>
);

interface AnalysisCardProps {
    name: string;
    id: number;
    transactionText: string;
    journalName?: string | null;
    isNull: boolean;
}
