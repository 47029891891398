import React from 'react';
import { Button, CircularProgress, ButtonProps } from '@mui/material';


interface ICustomLoadingButtonProps extends ButtonProps {
    isLoading?: boolean | number;
    circularIconSize?: number;
    loadingBtnLabel: string,
}

const CustomLoadingButton: React.FunctionComponent<
  ICustomLoadingButtonProps
> = ({ isLoading,circularIconSize=20, loadingBtnLabel, children, ...restProps }) => {
  return (
    <Button
      {...restProps}
      aria-label="options menu"
      aria-haspopup="menu"
      onClick={!isLoading ? restProps.onClick : () => {}}
      startIcon={
        isLoading ? (
          <CircularProgress
            size={circularIconSize}
            sx={{ color: 'grey.400' }}
          />
        ) : (
          restProps.startIcon
        )
      }
    >
      {isLoading ? <span>{loadingBtnLabel}</span> : children}
    </Button>
  );
};

export default CustomLoadingButton;
