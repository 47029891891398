import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';


type Props = {
  icon?: React.ReactNode;
  message?: string;
}
export const MessageFeedback: React.FC<Props> = ({ icon, message }) => {

  return (
    <Box className="_d-flex jc-center ali-center dir-col md-gap" maxWidth={320} m="auto" textAlign="center">
      {icon && (
        <Avatar variant="rounded" color="primary">
          {icon}
        </Avatar>
      )}
      {message && (
        <Typography variant="body1" fontWeight={500} component="span">{message}</Typography>
      )}
    </Box>
  );
};
