import React, { useContext } from 'react';
import { Grid } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { CompanyPlanAlert } from 'shared/components/alerts/CompanyPlanAlert';

import { FilterModules, FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { Dashboard } from './Dashboard';


export const Home = () => {

  const breadcrumbs = useBreadcrumbs('home');
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <CompanyPlanAlert/>
        <Breadcrumbs list={breadcrumbs}/>
      </Grid>
      <Grid item xs={12}>
        <FiltersSection
          filterModules={[
            FilterModules.YEAR,
            FilterModules.INTENSITY,
            FilterModules.SCOPE,
            FilterModules.TAGS,
          ]}
          title={dictionary.pageTitles.carbonFootprint}
        >
          {({ filters }) => (
            <Dashboard filters={filters}/>
          )}
        </FiltersSection>
      </Grid>
    </Grid>
  );
};
