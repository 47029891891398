import * as Yup from 'yup';
import { cloneDeep } from 'lodash';

import { endOfYear, startOfYear } from 'date-fns';
import { ChartGroupBy, ChartType } from 'clients/charts/chartClient.types';
import { ReplaceableKeys, SurveyQuestions, ValidationSchemaFrontend } from 'clients/surveys/survey.types';

import { ChartSectionDataType, Conditions, EmployeeEmissionsTypeEnum, ICreatePieChartArrayData, IGetFillColor, ReplaceData, YupSchemaShape, GetFillColorVariants } from './Employees.types';


export const createYupSchema = (conditions: ValidationSchemaFrontend): Yup.AnySchema => {
  let shape: YupSchemaShape = {};
  for (const condition in conditions) {
    //@ts-ignore
    shape[condition as keyof YupSchemaShape] = Yup[conditions[condition].type]();
    for (const method in conditions[condition].subsequentMethods) {
      shape[condition] = shape[condition as keyof Conditions][method](
        ...conditions[condition].subsequentMethods[method],
      );
    }
  }
  const inputSchema = Yup.object().shape(shape);
  return inputSchema;
};

export const a11yProps = (index: number) => {
  return {
    value: `202${index + 1}`,
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const replaceValueOnString = (keyStrToBeReplaced: any, replaceData: Record<string, object>) => {
  Object.entries(replaceData).forEach((entries) => {
    const [key, value] = entries;
    const replaceValue = `{${key}}`;
    keyStrToBeReplaced = keyStrToBeReplaced.replace(
      replaceValue,
      value,
    );
  });
  return keyStrToBeReplaced;
};

export const replaceValueOnStrings = (
  datasToBeReplaced: SurveyQuestions,
  valuesToReplaceList: ReplaceData[],
) => {
  const clonedDatasToBeReplaced = cloneDeep(datasToBeReplaced);
  clonedDatasToBeReplaced.forEach((dataToBeReplaced) => {
    valuesToReplaceList.forEach((replaceData) => {
      const { keyStrToBeReplaced, ...restReplaceData } = replaceData;
      dataToBeReplaced[keyStrToBeReplaced as keyof ReplaceableKeys] = replaceValueOnString(
        dataToBeReplaced[keyStrToBeReplaced],
        restReplaceData,
      );
    });
  });
  return clonedDatasToBeReplaced;
};

const getFillColor = ({ variantValue, variant }: IGetFillColor): string => {
  const fillVariants: {[key in GetFillColorVariants]: string} = {
    [EmployeeEmissionsTypeEnum.commutingEmissions]: '#A5CBF9',
    [EmployeeEmissionsTypeEnum.workFromHomeEmissions]: '#71DDEF',
  };
  if (variant !== variantValue) return 'none';

  return fillVariants[variantValue];
};

export const createPieChartArrayData = ({ variantValue, emissions }: ICreatePieChartArrayData): ChartSectionDataType => {
  return [
    {
      name: EmployeeEmissionsTypeEnum.commutingEmissions,
      value: emissions.commutingEmissions,
      fill: getFillColor({ variantValue, variant: EmployeeEmissionsTypeEnum.commutingEmissions }),
    },
    {
      name: EmployeeEmissionsTypeEnum.workFromHomeEmissions,
      value: emissions.workFromHomeEmissions,
      fill: getFillColor({ variantValue, variant: EmployeeEmissionsTypeEnum.workFromHomeEmissions }),
    },
  ];
};



export const getCo2SumFromSubCategories = ({ subCategoriesDataList, groupLabel }: any) => {
  if (Array.isArray(subCategoriesDataList)) {
    return subCategoriesDataList.reduce((accumulator : any, subCategoryData: any) => {
      if (subCategoryData.dimensions.group.includes(groupLabel)) {
        return accumulator + subCategoryData.metrics.sum_co2_kg;
      }
      return accumulator;
    }, 0);
  }
  return 0;
};

export const getMappedFilters = (year: string, emissionFactorCategoryValueId: number) => ({
  dateGte: startOfYear(new Date(parseInt(year), 0, 1)),
  dateLte: endOfYear(new Date(parseInt(year), 11, 31)),
  categoryId: emissionFactorCategoryValueId,
  groupBy: ChartGroupBy.category,
  metricLabel: 'Category',
  type: ChartType.pie,
});
