import React, { FC, ReactNode, Fragment, useContext, useMemo } from 'react';

import { PermissionContext, LocalizationContext } from 'contexts';
import { PermissionKey } from 'contexts/PermissionContext/types';
import { MessageFeedbackView } from 'shared/components/layout/Feedback/MessageFeedbackView';


type Props = {
    permission: PermissionKey;
    children?: ReactNode;
};

export const Permissioned: FC<Props> = ({ permission, children }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { getPermission } = useContext(PermissionContext);

  const hasPermission = useMemo(() => getPermission(permission), [getPermission, permission]);

  if (hasPermission) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  return <MessageFeedbackView height="100vh" message={dictionary.noPermission}/>;
};
