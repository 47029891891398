import { useCallback, useState } from 'react';


export type UseDataGridPaginationProps = {
  defaultPage: number;
  defaultPageSize?: number;
}


export const useDataGridPagination = ({ defaultPage, defaultPageSize = 10 }: UseDataGridPaginationProps) => {
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  return {
    page,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
  };
};