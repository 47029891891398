import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { North, South } from '@mui/icons-material';
import { getYear, subYears } from 'date-fns';

import { LocalizationContext } from 'contexts';
import { Emissions } from 'shared/components/layout/Emissions/Emissions';
import { getPercentageChangeColorCategoryTable } from 'views/LoggedIn/Measurements/helpers';


export interface Props {
  data: any;
  isComparingMonths: boolean;
  date: Date;
}

const VendorsTable: React.FC<Props> = ({
  data,
  isComparingMonths,
  date,
}) => {
  const { dictionary } = React.useContext(LocalizationContext);

  const [pageSize, setPageSize] = React.useState(10);

  const columns = React.useMemo(() => {
    const name = {
      field: 'name',
      headerName: dictionary.measurements.vendorTable.headers.vendor,
      minWidth: 70,
      editable: false,
      sortable: true,
      pinnable: false,
      filterable: false,
      resizable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 3,
      renderCell: ({ row, value }: any) => (
        <Stack
          direction="column"
        >
          <Typography variant="body1">{value || dictionary.measurements.vendorTable.cells.unknown}</Typography>
          <Typography variant="body2" color="text.secondary">{dictionary.measurements.transactionCount(row.transactionCount)}</Typography>
        </Stack>
      ),
    };

    const prevValue = {
      field: 'previousValues',
      headerName: getYear(subYears(date, 1)).toString(),
      minWidth: 70,
      editable: false,
      sortable: false,
      pinnable: false,
      filterable: false,
      resizable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ value }: any) => (
        <Box flexDirection="row">
          <Typography
            variant="categoryTable"
          >
            <Emissions showBoldValue={false} kg={value.value} showCo2={false}/>
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
          >
            {value.co2Percentage}%
          </Typography>
        </Box>
      ),
    };

    const change = {
      field: 'percentageChange',
      headerName: dictionary.measurements.categoryTable.headers.change,
      minWidth: 70,
      editable: false,
      sortable: true,
      pinnable: false,
      filterable: false,
      resizable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ value }: any) => (
        <Typography
          color={getPercentageChangeColorCategoryTable(value)}
          variant="body2"
        >
          <Box component="span" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
            {value > 0 && <North fontSize="inherit"/>}
            {value < 0 && <South fontSize="inherit"/>}
            {Math.abs(value).toFixed()}%
          </Box>
        </Typography>
      ),
    };

    const currentValue = {
      field: 'value',
      headerName: isComparingMonths ? getYear(date).toString() : dictionary.measurements.categoryTable.headers.impact,
      minWidth: 70,
      editable: false,
      sortable: true,
      pinnable: false,
      filterable: false,
      resizable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row, value }: any) => (
        <Box flexDirection="row">
          <Typography
            variant="categoryTable"
          >
            <Emissions showBoldValue={false} kg={value} showCo2={false}/>
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
          >
            {row.co2Percentage}%
          </Typography>
        </Box>
      ),
    };

    const columns = [name];

    if (isComparingMonths) {
      columns.push(prevValue);
    }

    columns.push(currentValue);

    if (isComparingMonths) {
      columns.push(change);
    }
    return columns;
  }, [date, dictionary.measurements, isComparingMonths]);

  return (
    <React.Fragment>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent',
          },
          '&, [class^=MuiDataGrid]': { border: 'none' } }
        }
        rows={data}
        getRowId={row => row.name === null ? 'Null' : row.name}
        columns={columns}
        density="compact"
        rowSpacingType="margin"
        pageSize={pageSize}
        onPageSizeChange={pageSize => setPageSize(pageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        pagination
        getRowHeight={() => 59}
        headerHeight={52}
      />
    </React.Fragment>
  );
};

export default VendorsTable;
