import { AxiosResponse } from 'axios';

import { Emissions } from 'modules/Emissions/emissions';
import { GetListParams, PaginatedResults } from 'types/Query';
import { camelizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';

import { EmissionFactor, EmissionFactorApi, EmissionFactorsAutocomplete, EmissionFactorsAutocompleteApi, EmissionFactorsAutocompleteParams } from './emissionFactorsClient.types';
import { mapEmissionFactorsAutocompleteParams } from './emissionFactorsClient.formatter';


const emissionFactorsApiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getEmissionFactors = ({ classificationCategory, categoryParentId, isActivityBased, ...params }: Emissions.Categories.GetEmissionFactorListParams): Promise<AxiosResponse<PaginatedResults<EmissionFactor>>> => {
  return request({
    options: {
      url: `${emissionFactorsApiBaseUrl}/emission-factors/`,
      method: 'GET',
      params: {
        ...params,
        classification_category: classificationCategory,
        category__parent__id: categoryParentId,
        is_activity_based: isActivityBased,
      },
    },
  }).then((data: AxiosResponse<PaginatedResults<EmissionFactorApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(camelizeObject<EmissionFactor>),
    },
  }));
};

const getEmissionFactor = (params: { id: number | string }): Promise<AxiosResponse<EmissionFactor>> => {
  return request({
    options: {
      url: `${emissionFactorsApiBaseUrl}/emission-factors/${params.id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<EmissionFactorApi>) => ({
    ...data,
    data: camelizeObject(data.data),
  }));
};

const getEmissionFactorCategories = (params: GetListParams): Promise<AxiosResponse<PaginatedResults<any>>> => {
  return request({
    options: {
      url: `${emissionFactorsApiBaseUrl}/emission-factor-categories/`,
      method: 'GET',
      params,
    },
  });
};

const getEmissionFactorsAutocomplete = (params: GetListParams<EmissionFactorsAutocompleteParams>): Promise<AxiosResponse<PaginatedResults<EmissionFactorsAutocomplete>>> => {
  return request({
    options: {
      url: `${emissionFactorsApiBaseUrl}/activity-based-emission-factor/autocomplete/`,
      method: 'GET',
      params: mapEmissionFactorsAutocompleteParams(params),
    },
  }).then((data: AxiosResponse<PaginatedResults<EmissionFactorsAutocompleteApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(camelizeObject<EmissionFactorsAutocomplete>),
    },
  }));
};

export const emissionFactorsClient = {
  getEmissionFactor,
  getEmissionFactors,
  getEmissionFactorCategories,
  getEmissionFactorsAutocomplete,
};
