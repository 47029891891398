import React, { FC, Fragment, useContext } from 'react';

import { UserContext } from 'contexts';


export const Authenticated: FC<React.PropsWithChildren> = ({ children }) => {

  const { isLoggedIn, user } = useContext(UserContext);

  if (isLoggedIn && !!user?.company) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  return null;
};

export const AuthenitcatedWithoutWorkspace: FC<React.PropsWithChildren> = ({ children }) => {

  const { isLoggedIn, user } = useContext(UserContext);

  if (isLoggedIn && !user?.company) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  return null;
};

export const NotAuthenticated: FC<React.PropsWithChildren> = ({ children }) => {

  const { isLoggedIn } = useContext(UserContext);

  if (!isLoggedIn) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  return null;
};
