import { request } from 'clients/baseClient';


const chartsApiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const get = () => {
  return request({
    options: {
      url: `${chartsApiBaseUrl}/scenario-builder/company_data/`,
      method: 'GET',
    },
  });
};

const updateCell = (data: any) => {
  return request({
    options: {
      url: `${chartsApiBaseUrl}/scenario-builder/update_cell/`,
      method: 'PATCH',
      data,
    },
  });
};

const updateBuilder = (data: any) => {
  return request({
    options: {
      url: `${chartsApiBaseUrl}/scenario-builder/update_builder/`,
      method: 'PATCH',
      data,
    },
  });
};

const resetData = () => {
  return request({
    options: {
      url: `${chartsApiBaseUrl}/scenario-builder/reset_to_default/`,
      method: 'POST',
    },
  });
};

export const scenarioBuilderClient = {
  get,
  updateCell,
  updateBuilder,
  resetData,
};
