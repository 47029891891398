import { FC } from 'react';
import ReactDropzone from 'react-dropzone';

import CustomContainer from 'shared/components/styledMui/CustomContainer';

import { getDragVariant } from './dropFuntions';
import DropMessage from './DropMessage';
import DropZoneIcon from './DropzoneIcon';
import { IDropzone } from './dropInterfaces';


export const MAX_FILE_SIZE_MB = 25;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1000 * 1000;

export const Dropzone: FC<IDropzone> = ({
  dropZoneMsgs,
  onDrop,
  onFileReject,
  fileUploaded,
  acceptedFileExtensions,
  maxFiles = 1,
  maxSize= MAX_FILE_SIZE,
}) => {

  return (
    <ReactDropzone
      onDrop={([acceptedFile]) => onDrop({ file: acceptedFile, isAccepted: true })}
      accept={acceptedFileExtensions}
      maxFiles={maxFiles}
      onDropRejected={([{ file }]) => onFileReject({ file: file, isAccepted: false })}
      maxSize={maxSize}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
      }) => {

        const dragVariant = getDragVariant({
          isDragActive,
          fileUploaded,
        });

        return (
          <CustomContainer {...getRootProps()} variant={dragVariant}>
            <DropZoneIcon variant={dragVariant}/>
            <input {...getInputProps()}/>
            <DropMessage
              variant={dragVariant}
              dropZoneMsgs={dropZoneMsgs}
              fileName={fileUploaded?.name}
              readableSize={fileUploaded?.readableSize}
            />
          </CustomContainer>
        );
      }}
    </ReactDropzone>
  );
};
