import React, { CSSProperties } from 'react';
import { Button, InputAdornment, MenuItem, Popover, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { isArray } from 'lodash';
import { ArrowIcon } from 'shared/icons/ArrowIcon';
import { line, textPrimary } from 'shared/styles/muiTheme';
import { MagnifyingGlassIcon } from 'shared/icons/MagnifyingGlassIcon';
import { XMarkIcon } from 'shared/icons/XMarkIcon';
import { ChevronIcon } from 'shared/icons/ChevronArrIcon';
import { LocalizationContext } from 'contexts';
import { ReactComponent as NotFound } from 'shared/img/notfound.svg';
import { TextTag } from 'shared/components/layout/Tags/TextTag';
import { ChipsSection } from './ChipsSection';
import { LoadingSkeleton } from './SelectInputsLoadingSkeleton';


export const SelectWithSearch = (props: SelectWithSearchProps) => {
  const {
    options,
    value,
    label,
    onChange,
    multiple,
    placeholder,
    onInputChange,
    isLoading,
    hideSearch,
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [isSelectOpen, setIsSelectOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>('');
  const [subOptions, setSubOptions] = React.useState<SelectWithSearchSubOption[]>([]);
  const buttonRef = React.useRef(null);
  const multiValue = React.useMemo(() => (isArray(value) ? value : value ? [value] : []), [value]);
  const singleValue = React.useMemo(() => isArray(value) ? value[0] : value, [value]);

  React.useEffect(() => {
    setSubOptions(
      singleValue?.subOptions ||
      (singleValue?.parentId && options.find((opt) => opt.id === singleValue?.parentId)?.subOptions) ||
      [],
    );
  }, [options, singleValue]);

  const filteredOptions = onInputChange ? options : options.filter((opt) => opt.name.toLowerCase().includes(search.toLowerCase()));

  const handleSelectOpen = () => setIsSelectOpen(true);

  const handleSearchInputChange = React.useCallback((value: string) => {
    setSearch(value);
    if (onInputChange) {
      onInputChange(value);
    }
  }, [onInputChange]);

  const handleSelectClose = React.useCallback(() => {
    handleSearchInputChange('');
    setIsSelectOpen(false);
  }, [handleSearchInputChange]);

  const handleSelect = React.useCallback((option: SelectWithSearchOption) => {
    if (multiple) {
      const newOptions = [...multiValue];
      const optId = newOptions.findIndex((opt) => opt.id === option.id);
      if (optId >= 0) {
        newOptions.splice(optId, 1);
      }
      else {
        newOptions.push(option);
      }
      onChange(newOptions);
    }
    else {
      onChange([option]);
      if (!option.subOptions?.length) {
        handleSelectClose();
      }
    }

    if (search) {
      handleSearchInputChange('');
      searchInputRef?.current?.focus();
    }
  }, [handleSearchInputChange, handleSelectClose, multiValue, multiple, onChange, search]);

  const handleRemoveSelectionFromButton = (e: Event) => {
    e.stopPropagation();
    onChange([]);
    handleSelectClose();
  };

  const selectOptions = filteredOptions.length ? (
    <React.Fragment>
      {filteredOptions.map((option) => (
        <MenuItem
          key={option.id}
          onClick={() => handleSelect(option)}
          selected={multiValue.some(
            (opt) => opt.id === option.id || opt.parentId === option.id,
          )}
          sx={{
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <span className="one-line-text">{option.name}</span>
          {!!option.subOptions?.length &&
            <ChevronIcon direction="right"/>
          }
        </MenuItem>
      ))}
    </React.Fragment>
  ) : (
    <Box className="_d-flex-dir-col-ali-center-jc-center-sm-gap" height="100%">
      <NotFound/>
      <Typography variant="body1">
        {dictionary.filterSection.noResults}
      </Typography>
    </Box>
  );

  return (
    <React.Fragment>
      <Button
        color={singleValue ? 'success' : 'secondary'}
        ref={buttonRef}
        onClick={handleSelectOpen}
        sx={{ maxWidth: '200px' }}
      >
        {multiple ? (
          <React.Fragment>
            <span className="one-line-text">{label}</span>
            <TextTag text={multiValue.length ? multiValue.length : ''}/>
          </React.Fragment>
        ) : (
          <span className="one-line-text">{singleValue?.name || label}</span>
        )}
        {singleValue ? (
          <XMarkIcon onClick={handleRemoveSelectionFromButton}/>
        ) : (
          <ArrowIcon style={{ '--icon-color': textPrimary } as CSSProperties}/>
        )}
      </Button>

      <Popover
        open={isSelectOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            mt: 1,
            mr: 1,
            minWidth: subOptions.length ? 560 : null,
            maxWidth: subOptions.length ? 680 : 340,
            height: hideSearch ? null : 294,
          },
        }}
        onClose={handleSelectClose}
      >
        <Box
          className={subOptions.length ? '_d-grid-no-gap' : ''}
          gridTemplateColumns="1fr 1fr"
          height="100%"
        >
          <Box
            className="_d-grid-no-gap"
            sx={{
              gridTemplateRows: 'auto auto 1fr',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            {!hideSearch ? (
              <TextField
                value={search}
                onChange={(e) => handleSearchInputChange(e.currentTarget.value)}
                variant="standard"
                placeholder={placeholder || dictionary.search}
                sx={{ p: 1, pt: .5 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagnifyingGlassIcon/>
                    </InputAdornment>
                  ),
                  inputRef: searchInputRef,
                }}
              />
            ) : <div/>}

            {multiple ? (
              <ChipsSection options={multiValue} onClear={handleSelect}/>
            ) : <div/>}

            <Box overflow="auto">
              {isLoading ? <LoadingSkeleton/> : selectOptions}
            </Box>
          </Box>

          {!!subOptions.length && (
            <Box
              overflow="auto"
              borderLeft={`solid 1px ${line}`}
            >
              {subOptions.map((option) => (
                <MenuItem
                  key={option.id}
                  onClick={() => handleSelect(option)}
                  selected={multiValue.some((opt) => opt.id === option.id)}
                >
                  <span className="one-line-text">{option.name}</span>
                </MenuItem>
              ))}
            </Box>
          )}
        </Box>
      </Popover>

    </React.Fragment>
  );
};

interface SelectWithSearchProps {
    options: SelectWithSearchOption[];
    value: SelectWithSearchOption | SelectWithSearchOption[] | null;
    label: string;
    onChange: (option: SelectWithSearchOption[]) => void;
    multiple?: boolean;
    placeholder?: string;
    onInputChange?: (value: string) => void;
    isLoading?: boolean;
    hideSearch?: boolean;
}

export interface SelectWithSearchOption {
    id: string | number;
    name: string;
    subOptions?: SelectWithSearchSubOption[];
    parentId?: string | number;
}

export type SelectWithSearchSubOption = Omit<SelectWithSearchOption, 'subOptions'> ;