import { Expenses } from 'modules/Expenses/expenses';
import * as integrationImgs from 'shared/img/integrations';


export const PREVIEW_ROW_LIMIT = 5;

export const CleaningSteps: {[key: string]: string} = {
  JOURNAL: 'journal',
  LEDGER: 'ledger',
  REVIEW_DONE: 'reviewDone',
};

export const UploadIntegrationCsvSteps: {[key: string]: string} = {
  INTRO: 'intro',
  UPLOAD_FILE: 'uploadFile',
  PREVIEW: 'preview',
  SELECT_COLS: 'selectColumns',
  ASYNC_LOADER: 'asyncLoader',
  UPLOAD_DONE: 'uploadDone',
  ...CleaningSteps,
};

export const IntegrationSteps: {[key: string]: string} = {
  INTRO: 'introSync',
  SELECT_ACC: 'selectAccounting',
  PERMISSIONS: 'permissions',
  ASYNC_LOADER: 'asyncLoaderSync',
  SYNC_DONE: 'syncDone',
  ...CleaningSteps,
};

export const CsvColumnOptions = (currencySymbol?: string) => [
  { key: 'date', title: 'Date' },
  { key: 'journal', title: 'Journal', isOptional: true },
  { key: 'ledger', title: 'Ledger' },
  { key: 'description', title: 'Description' },
  { key: 'vendorName', title: 'Vendor', isOptional: true },
  { key: 'netAmount', title: 'Net cost' },
  { key: 'currency', title: 'Currency', isOptional: true, helpText: `If left empty, all transactions will default to the company's currency${currencySymbol ? ` (${currencySymbol}).` : '.'}` },
  { key: 'tags', title: 'Tags', isOptional: true },
];

export const CsvAnalysisType = {
  [CleaningSteps.JOURNAL]: 'journal',
  [CleaningSteps.LEDGER]: 'ledger',
} as {[step: string]: Expenses.AnalysesType};

export enum IntegrationStatus {
  UPLOAD = 'uploading',
  ERROR = 'upload failed',
  NEED_REV = 'needs review',
  ANAL_RUN = 'analysis running',
  ANAL_DONE = 'analysis completed',
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  IMPORTING_TRANSACTIONS = 'importing transactions',
}

export const IntegrationOptions: IntegrationOption[] = [
  {
    key: 'Exact Online',
    title: 'Exact Online',
    img: integrationImgs.exact,
  },
  {
    key: 'visma',
    title: 'Visma',
    img: integrationImgs.visma,
  },
  {
    key: 'twinfield',
    title: 'Twinfield',
    img: integrationImgs.twinfield,
  },
  {
    key: 'afas',
    title: 'AFAS software',
    img: integrationImgs.afas,
  },
  {
    key: 'odoo',
    title: 'Odoo',
    img: integrationImgs.odoo,
  },
  {
    key: 'winBooks',
    title: 'WinBooks',
    img: integrationImgs.winBooks,
  },
  {
    key: 'xero',
    title: 'Xero',
    img: integrationImgs.xero,
  },
  {
    key: 'quickBooks',
    title: 'QuickBooks',
    img: integrationImgs.quickBooks,
  },
  {
    key: 'datev',
    title: 'DATEV',
    img: integrationImgs.datev,
  },
];

export interface IntegrationOption {
  key: string;
  title: string;
  img: string;
}