import React, { FC, useCallback, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { Grid, LinearProgress, Typography } from '@mui/material';

import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';
import { UserCrud } from 'clients/users/userClient.types';
import { useMe } from 'shared/hooks/useMe';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';

import { FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { UserForm } from './partials/UserForm';


export const Profile: FC = () => {

  const { genericError, changesSaved } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { user, status } = useMe();

  const queryClient = useQueryClient();
  const editUser = useMutation(usersClient.editUser, {
    mutationKey: cacheKeys.users.editUser,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.users.getUsers);
      queryClient.invalidateQueries(cacheKeys.users.getMe);
      if (user) {
        queryClient.invalidateQueries([cacheKeys.users.getUser, {
          id: user.id,
        }]);
      }
      changesSaved();
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback((data: UserCrud) => {
    if (!user) {
      return false;
    }
    return editUser.mutate({
      ...data,
      id: user.id,
    });
  }, [user, editUser]);

  return status !== 'success' || !user ? (
    <BodyLoading height="100vh"/>
  ) : (
    <Grid container direction="column">
      <Grid item xs={12}>
        <FiltersSection title={dictionary.myAccount}>
          {() => (
            <Grid pt={5}>
              <Typography variant="h2" mb={3}>{dictionary.profile.title}</Typography>
              <UserForm
                defaultValues={{
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  jobTitle: user.jobTitle,
                  company: user.company,
                  type: user.type,
                  profilePicture: user.profilePicture,
                }}
                disabledFields={['type']}
                onSubmitRequest={onSubmit}
                onSubmitButtonText={dictionary.forms.save}
                isSubmitting={editUser.isLoading}
              />
              <LinearProgress sx={{ mt: 2, opacity: editUser.isLoading ? 1 : 0 }}/>
            </Grid>
          )}
        </FiltersSection>
      </Grid>
    </Grid>
  );
};
