import React, { Fragment, MutableRefObject, useRef } from 'react';
import { Box } from '@mui/material';

import { floor } from 'lodash';
import { EmissionFactor } from 'clients/emissionFactors/emissionFactorsClient.types';
import { Emissions } from 'shared/components/layout/Emissions/Emissions';
import { MappedPieChartItem } from 'views/LoggedIn/Measurements/types';

import { CategoriesTooltip } from './CategoriesTooltip';


interface CategoryBarProps {
  bar: MappedPieChartItem;
  setEmissionFactorCategory: (category: string | undefined) => void;
  reloadChartsRef: MutableRefObject<boolean>,
  emissionFactorCategory: EmissionFactor,
}

export const CategoryBar: React.FC<CategoryBarProps> = ({
  bar,
  setEmissionFactorCategory,
  reloadChartsRef,
  emissionFactorCategory,
}) => {
  const gridBarRef = useRef<HTMLDivElement>(null);

  const isFullOpacity = emissionFactorCategory?.slug === bar.slug ||
    emissionFactorCategory?.parent?.slug === bar.slug ||
    !emissionFactorCategory;

  return (
    <Box
      key={bar.slug}
      sx={{
        cursor: 'pointer',
        opacity: isFullOpacity ? 1 : 0.5,
        '&:hover': {
          opacity: 1,
        },
        flexGrow: floor(bar.co2ProportionWithNegativeValues, 3) * 11.98,
      }}
      ref={gridBarRef}
      onClick={() => {
        if (emissionFactorCategory?.slug === bar.slug) {
          setEmissionFactorCategory(undefined);
          reloadChartsRef.current = false;
        } else {
          setEmissionFactorCategory(bar.slug);
          reloadChartsRef.current = true;
        }
      }}
    >
      <CategoriesTooltip title={<Fragment><span>{bar.name}</span>
        <div><Emissions kg={bar.value}/>
          <span style={{ marginLeft: 4 }}>
      ({bar.co2Percentage}%)
          </span>
        </div>
      </Fragment>
      }>
        <Box
          width="100%"
          height="100%"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: bar.fill,
            height: 40,
            borderRadius: 1,
          }}
        >
          {gridBarRef?.current &&
        gridBarRef?.current?.offsetWidth > 40 && (
            <Box
              component="img"
              src={bar.icon}
              alt={bar.name}
              sx={{ width: 20, height: 20 }}
            />
          )}
        </Box>
      </CategoriesTooltip>
    </Box>
  );
};
