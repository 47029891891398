import { Expenses } from 'modules/Expenses/expenses';


const SeparatorOptions = [
  { string: ';', regex: /;/g },
  { string: ',', regex: /,/g },
  { string: ':', regex: /:/g },
  { string: '\t', regex: /\t/g },
];
const PlaceholderChar = '；';
const PlaceholderCharRegex = new RegExp(PlaceholderChar, 'g');

const findSeparator = (text: string): RegExp => {
  const breakNum = text.match(/n/g)?.length || 0;
  for (const option of SeparatorOptions) {
    if ((text.match(option.regex) || []).length > breakNum * 2) {
      return option.regex;
    }
  }

  return SeparatorOptions[0].regex;
};

const cleanStrings = (text: string, separator: RegExp): [string, RegExp] => {
  const cleanedText = text.replace(/"([^"]*)"/g, (_match, p1) => {
    const withoutBreaks = p1.replace(/\n/g, '');
    return withoutBreaks.replace(separator, PlaceholderChar);
  }).replace(/\r/g, '');

  if (separator !== SeparatorOptions[0].regex) {
    const separatorString = SeparatorOptions.find((option) => option.regex === separator)!.string;

    return [
      cleanedText
        .replace(SeparatorOptions[0].regex, PlaceholderChar)
        .replace(separator, SeparatorOptions[0].string)
        .replace(PlaceholderCharRegex, separatorString),
      SeparatorOptions[0].regex,
    ];
  }

  return [cleanedText, separator];
};

const convertIndexToLetter = (index: number) => {
  // currently does A-Z
  return (index + 10).toString(36).toUpperCase();
};

const csvToColumns = (text: string, isFirstRowHeaders: boolean = true, rowLimit?: number, customSeparator?: RegExp): [
  Expenses.CsvColumn[],
  number
] => {
  const colsArr = [] as Expenses.CsvColumn[];
  const [cleanedText, separator] = cleanStrings(text, customSeparator || findSeparator(text));
  const rows = cleanedText.split(/\n/);

  const rowsNum = rowLimit ? Math.min(rowLimit, rows.length) : rows.length;

  for (let rowIdx = 0; rowIdx < rowsNum; rowIdx++) {
    rows[rowIdx].split(separator!).forEach((value, colIdx) => {
      if (rowIdx === 0) {
        if (isFirstRowHeaders) {
          colsArr.push({
            title: value,
            column: [],
          });
        }
        else {
          colsArr.push({
            title: convertIndexToLetter(colIdx),
            column: [value],
          });
        }
      }
      else {
        colsArr[colIdx]?.column.push(value);
      }
    });
  }

  return [colsArr, rowsNum - (isFirstRowHeaders ? 1 : 0)];
};

const getCsvRowsWithHeaders = (text: string, headerIndexes: Expenses.HeadersIndexes, isFirstRowHeaders: boolean = true) => {
  const reorderedRows = [];
  const [fileColumns, rowsCount] = csvToColumns(text, isFirstRowHeaders);

  for (let i = 0; i < rowsCount; i++) {
    const row = {} as {[header: string]: string};
    Object.keys(headerIndexes).forEach((key) => {
      row[key] = fileColumns[headerIndexes[key]].column[i];
    });

    reorderedRows.push(row);
  }

  return reorderedRows;
};

export const csvParser = {
  csvToColumns,
  getCsvRowsWithHeaders,
};