import React, { useContext, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Tooltip,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { DataGridPro as DataGrid, GridColDef } from '@mui/x-data-grid-pro';
import { GridAlignment } from '@mui/x-data-grid';

import { useEmissionFactors } from 'modules/Emissions/hooks/useEmissionFactors';
import { LocalizationContext, PermissionContext } from 'contexts';
import { CurrencyLocale, Dictionary } from 'contexts/LocalizationContext/types';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import { CompanyPlanAlert } from 'shared/components/alerts/CompanyPlanAlert';
import { FilterModules, FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';


interface GetColumnsParams {
  canChangeTransaction: boolean;
}

const getColumns = (permissions: GetColumnsParams, dictionary: Dictionary, currencyLocale: CurrencyLocale): GridColDef[] => {
  const columns: Array<GridColDef | undefined> = [{
    field: 'id',
    headerName: 'ID',
    width: 50,
    hide: true,
    pinnable: false,
  },
  {
    field: 'title',
    headerName: 'Title',
    flex: 2,
    pinnable: false,
    renderCell: ({ row, value }: any) => (
      value && (
        <Tooltip title={(
          <React.Fragment>
            <Typography variant="body2" fontWeight={500}>
              {row?.title}
            </Typography>
            {row?.description && (row?.title !== row?.description) && (
              <Typography variant="body2" mt={2}>
                {row?.description}
              </Typography>
            )}
            {row?.source && (
              <Typography variant="body2" mt={2}>
                {dictionary.emissionFactors.labelSource}: {row?.source}
                {row?.year && `, ${row?.year}`}
              </Typography>
            )}
          </React.Fragment>
        )}>
          <span>{value}</span>
        </Tooltip>
      )
    ),
  },
  {
    field: 'category',
    headerName: 'Category',
    valueGetter: ({ row }: any) => row?.category?.parent?.title || row?.parent?.category?.parent?.title,
    flex: 1,
    pinnable: false,
  },
  {
    field: 'subCategory',
    headerName: 'Sub Category',
    valueGetter: ({ row }: any) => row?.category?.title || row?.parent?.category?.title,
    flex: 1,
    pinnable: false,
  },
  {
    field: 'scope',
    headerName: 'Scope',
    type: 'number',
    pinnable: false,
  },
  ...(permissions.canChangeTransaction ? [{
    field: 'kgCo2EMultiplier',
    headerName: `kg CO₂e / ${currencyLocale.symbol}`,
    width: 100,
    align: 'center' as GridAlignment,
    pinnable: false,
    valueGetter: ({ row }: any) => row?.parent?.kgCo2EMultiplier || row?.kgCo2EMultiplier,
  }] : []),
  ];
  const filteredColumns: Array<GridColDef> = columns.filter((column) => column !== undefined) as Array<GridColDef>;
  return filteredColumns;
};


export const EmissionFactorsList = ({
  filters: {
    scope,
    emissionFactorCategory,
  },
}: any) => {

  const [newSearch, currSearch, setSearch] = useDebouncedQueryState('search');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const { getPermission } = useContext(PermissionContext);
  const { currencyLocale, dictionary } = useContext(LocalizationContext);

  const { emissionFactors, count, isLoading } = useEmissionFactors({
    search: currSearch,
    offset: pageSize * page,
    limit: pageSize,
    scope,
    categoryParentId: emissionFactorCategory?.id,
  });

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 8 }}>
        <Card sx={{ py: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6">
                {dictionary.emissionFactors.emissionFactorsByScope(scope)}
              </Typography>
            </Grid>
            <Box ml="auto"/>
            <Grid item>
              <TextField
                sx={{ width: 300 }}
                placeholder="Search"
                size="small"
                variant="outlined"
                defaultValue={currSearch}
                value={newSearch || ''}
                onChange={({ target: { value } }) => {
                  setPage(0);
                  setSearch(value?.trim() === '' ? undefined : value);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
                }}
                InputLabelProps={{
                  shrink: undefined,
                }}
              />
            </Grid>
          </Grid>
          <Box mt={2}/>
          <DataGrid
            rowCount={count}
            disableColumnSelector
            disableColumnFilter
            disableSelectionOnClick
            autoHeight
            rows={emissionFactors}
            columns={getColumns({ canChangeTransaction: getPermission('transaction.crud.edit') }, dictionary, currencyLocale)}
            rowsPerPageOptions={[10, 20, 50, 100, 1000]}
            page={page}
            headerHeight={36}
            pagination
            onPageChange={p => setPage(p)}
            paginationMode="server"
            pageSize={pageSize}
            onPageSizeChange={p => setPageSize(p)}
            loading={isLoading}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export const EmissionFactors = () => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Grid container direction="column">
      <Grid item>
        <CompanyPlanAlert/>
      </Grid>
      <Grid item>
        <FiltersSection
          filterModules={[
            FilterModules.SCOPE,
          ]}
          title={dictionary.pageTitles.emissionFactors}
        >
          {({ filters }) => (
            <EmissionFactorsList filters={filters}/>
          )}
        </FiltersSection>
      </Grid>
    </Grid>
  );
};
