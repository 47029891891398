import React, { ReactElement } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { ChevronIcon } from 'shared/icons/ChevronIcon';
import { LightbulbIcon } from 'shared/icons/LightbulbIcon';
import { textTertiary } from 'shared/styles/muiTheme';
import { Expenses } from 'modules/Expenses/expenses';
import { CsvAnalysisType } from 'modules/Expenses/helpers/integrationOptions';
import { AnalysisCard } from 'modules/Expenses/components/AnalysisCard';
import { LocalizationContext } from 'contexts';
import { InsightCard } from 'shared/components/layout/InsightCard/InsightCard';
import { DragAndDropColumn } from 'shared/components/interactive/DragAndDropColumn/DragAndDropColumn';
import { AsyncConfirmationDialog } from 'shared/components/dialog/AsyncConfirmationDialog/AsyncConfirmationDialog';


export const JournalAndLedgerAnalysisStep = (props: JournalAndLedgerAnalysisStepProps) => {
  const {
    analysisType,
    ledgerAndJournalCleaning,
    setLedgerAndJournalCleaning,
    autoNum,
    isReviewConfirmOpen,
    setIsReviewConfirmOpen,
    analysisList,
    handleReviewStepDone,
    isNoJournals,
    isUpdateCleaning,
    isLoading,
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);

  const [dragIndexes, setDragIndexes] = React.useState<number[] | null>(null);

  const steps = Object.values(CsvAnalysisType).map((type) => ({ title: type, isSelected: type === analysisType }));

  const [includeList, ignoreList] = React.useMemo(() => {
    const includeList: Expenses.Integrations.LedgerOrJournal[] = [];
    const ignoreList: Expenses.Integrations.LedgerOrJournal[] = [];

    analysisList.forEach((analysis) => {
      const cleaning = ledgerAndJournalCleaning.find((c) => c.id === analysis.id);
      const isIncludedInAnalysisCalc = cleaning ? cleaning.isIncludedInAnalysis : analysis.isIncludedInAnalysis;

      if (isIncludedInAnalysisCalc === false) {
        ignoreList.push(analysis);
      }
      else {
        isIncludedInAnalysisCalc === null ? includeList.unshift(analysis) : includeList.push(analysis);
      }
    });

    return [includeList, ignoreList];
  },
  [analysisList, ledgerAndJournalCleaning]);

  const getItems = React.useCallback((list: Expenses.Integrations.LedgerOrJournal[]) => {
    return list.map((item) => ({
      key: item.name || String(item.id),
      element: (
        <AnalysisCard
          name={item.name || dictionary.expenses.uploadIntegration.ledgerAnalysis.unnamed}
          isNull={!item.name}
          id={item.id}
          transactionText={`${item.transactionCount} ${dictionary.expenses.transactions}`}
          journalName={item.journalName}
        />
      ),
    }));
  }, [dictionary]);

  const handleDragStart = (items: ReactElement[]) => {
    const idsStrArr = items.map((item) => item.props.id)
      .filter((id) => id !== null && id !== undefined) as string[];
    setDragIndexes(idsStrArr.length ? idsStrArr.map((id) => parseInt(id)) : null);
  };

  const handleDrop = (isIncludedInAnalysis: boolean) => {
    if (dragIndexes !== null) {
      const temp = [...ledgerAndJournalCleaning];
      for (const dragIndex of dragIndexes) {
        const idx = temp.findIndex((c) => c.id === dragIndex);
        if (idx >= 0) {
          temp.splice(idx, 1);
        }
        temp.push({ id: dragIndex, isIncludedInAnalysis });
      }
      setLedgerAndJournalCleaning(temp);
    }
    setDragIndexes(null);
  };

  return (
    <Box>
      <AsyncConfirmationDialog
        open={isReviewConfirmOpen}
        title={isUpdateCleaning ? dictionary.expenses.uploadIntegration.ledgerAnalysis.confirmTitleUpdate :
          dictionary.expenses.uploadIntegration.ledgerAnalysis.confirmTitle}
        content={isUpdateCleaning ? dictionary.expenses.uploadIntegration.ledgerAnalysis.confirmTextUpdate :
          dictionary.expenses.uploadIntegration.ledgerAnalysis.confirmText}
        confirmLabel={dictionary.expenses.uploadIntegration.ledgerAnalysis.confirmRun}
        cancelLabel={dictionary.expenses.uploadIntegration.backButton}
        onConfirm={handleReviewStepDone}
        onClose={() => setIsReviewConfirmOpen(false)}
      />

      {!isNoJournals && <StepsList steps={steps} isLoading={isLoading}/>}

      {autoNum && (
        <InsightCard
          text={(
            <React.Fragment>
              <span className="bold-font">{autoNum} {dictionary.singleToPlural(analysisType)}</span>{' '}
              {dictionary.expenses.uploadIntegration.ledgerAnalysis.autoMove}
            </React.Fragment>
          )}
          image={<LightbulbIcon sx={{ color: textTertiary }}/>}
          containerProps={{ mb: 3 }}
          canClose
        />
      )}

      <Box className="col-container:2-lg-gap">
        <DragAndDropColumn
          items={getItems(includeList)}
          onDragStart={handleDragStart}
          onDrop={() => handleDrop(true)}
          title={dictionary.expenses.uploadIntegration.ledgerAnalysis.include}
          itemName={dictionary.singleToPlural(analysisType)}
          isLoading={isLoading}
        />
        <DragAndDropColumn
          items={getItems(ignoreList)}
          onDragStart={handleDragStart}
          onDrop={() => handleDrop(false)}
          title={dictionary.expenses.uploadIntegration.ledgerAnalysis.ignore}
          itemName={dictionary.singleToPlural(analysisType)}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

const StepsList = (props: {
  steps: {title: string, isSelected: boolean}[];
  isLoading: boolean;
}) => (
  props.isLoading ? (
    <Skeleton variant="rounded" sx={{ height: 20, mb: 2, width: 200 }}/>
  ) : (
    <Box className="_d-flex-ali-center-sm-gap" mb={2}>
      {props.steps.map((step, i) => (
        <React.Fragment key={i}>
          <Typography
            variant="body1"
            color={step.isSelected ? 'primary.main' : ''}
            sx={{ textTransform: 'capitalize' }}
          >
            {i + 1}. {step.title}
          </Typography>
          {i < props.steps.length - 1 && <ChevronIcon direction="right"/>}
        </React.Fragment>
      )) }
    </Box>
  )
);

interface JournalAndLedgerAnalysisStepProps {
    analysisType: Expenses.AnalysesType;
    analysisList: Expenses.Integrations.LedgerOrJournal[];
    ledgerAndJournalCleaning: Expenses.Integrations.JournalAndLedgerCleaningData[];
    setLedgerAndJournalCleaning: any;
    isReviewConfirmOpen: boolean;
    setIsReviewConfirmOpen: any;
    handleReviewStepDone: (e: any, integrationId: number | null) => void;
    isLoading: boolean;
    isNoJournals: boolean;
    isUpdateCleaning: boolean;
    autoNum?: number;
}

