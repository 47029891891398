import { useQuery } from 'react-query';

import { integrationsClient } from 'modules/Expenses/client/integrationsClient';
import { Expenses } from 'modules/Expenses/expenses';
import { cacheKeys } from 'config';


type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useLinkedAccounts = (
  integrationId: number | null,
  params: Expenses.GetLedgerAndJournalsParams = {},
  options: Partial<Options> = defaultOptions,
) => {

  const {
    data: { data: analysisData } = {},
    isLoading,
    refetch,
  } = useQuery(
    [cacheKeys.integrations.getIntegrationLedgerAndJournals, integrationId, params],
    () => {
      if (!integrationId || !params.type) {
        return;
      }

      return integrationsClient.getIntegrationLedgerAndJournals(integrationId, params);
    },
    { enabled: options.enabled },
  );

  return {
    analysisList: analysisData?.results || [],
    isLoading,
    refetch,
  };
};
