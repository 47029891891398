import React, { FC, Fragment, useMemo } from 'react';


export const Emissions: FC<{
  kg?: number,
  multiline?: boolean,
  showLabel?: boolean,
  showValue?: boolean,
  string?: boolean,
  showTonnes?: boolean,
  showCo2?: boolean
  showBoldValue?: boolean,
}> = ({
  kg,
  multiline = false,
  showLabel = true,
  showValue = true,
  string = false,
  showTonnes = false,
  showCo2 = true,
  showBoldValue = true,
}) => {


  const [value, label] = useMemo(() => {
    const co2Label = showCo2 ? ' CO₂e' : '';
    if(!kg && kg !== 0) {
      return [0, ''];
    }
    if (showTonnes || kg >= 100 || kg <= -100) {

      let decimalNumber = (kg / 1000).toFixed(2);
      if (decimalNumber[decimalNumber.length - 1] === '0' ){
        decimalNumber = (kg / 1000).toFixed(1);
      }

      return [decimalNumber, 't' + co2Label];
    }
    return [kg.toFixed(1), 'kg' + co2Label];
  }, [kg, showTonnes, showCo2]);

  if(kg === undefined) {
    return null;
  }

  if(string) {
    return <Fragment>{`${showValue ? value : ''} ${showLabel ? label : ''}`}</Fragment>;
  }

  if(multiline) {
    return (
      <Fragment>
        {showValue && <Fragment><span>{value}</span><br/></Fragment>}
        {showLabel && <span style={{ fontSize: 14 }}>{label}</span>}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {showValue && showBoldValue && <b>{value}</b>} {showValue && !showBoldValue && value}  {showLabel && <span style={{ fontSize: 12 }}>{label}</span>}
    </Fragment>
  );
};
