import React, { FunctionComponent, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { ArrowTopRightOnSquare } from 'shared/icons/ArrowTopRightOnSquare';


export const UserNavItemSection: FunctionComponent<UserNavItemSectionProps> = ({
  title,
  subItems = [],
  enabled,
  isCollapsed,
}) => {

  const enabledSubItems = React.useMemo(() => (
    subItems.filter(({ enabled }) => enabled)
  ), [subItems]);

  const enabledSection = React.useMemo(() => (
    enabled && !!enabledSubItems.length
  ), [enabled, enabledSubItems]);

  if (!enabledSection) {
    return null;
  }

  return (
    <Box className="_d-grid-xs-gap" mb={2}>
      {title && (
        <Typography variant="h6" color="text.tertiary" p={1}>
          {title}
        </Typography>
      )}
      {enabledSubItems?.map((item, i) => {
        const isRoot = item.link === '/';
        const isActive = item.link && (
          (!isRoot && window.location.pathname.includes(item.link)) ||
          (isRoot && window.location.pathname === '/')
        );

        const functionalProps = item.isRedirect ? {
          component: 'a',
          href: item.link,
          target: '_blank',
        } : {
          component: NavLink,
          to: item.link,
        };

        return (
          <Tooltip key={i} title={isCollapsed ? item.title : ''} placement="right" arrow>
            <Button
              {...functionalProps}
              className={isActive ? 'nav-btn' : 'nav-btn inactive'}
              variant={isActive ? 'contained' : 'text'}
              color={isActive ? 'success' : 'secondary'}
            >
              {item.icon}
              <span className="btn-text">
                {item.title}
                {item.isRedirect && <ArrowTopRightOnSquare/>}
              </span>
            </Button>
          </Tooltip>
        );
      })}

    </Box>
  );
};

interface UserNavItemSectionProps {
  title?: string;
  subItems?: NavButtonProps[];
  enabled: boolean;
  isCollapsed: boolean;
}

interface NavButtonProps {
  title: string;
  enabled: boolean;
  link: string;
  icon?: ReactElement;
  isRedirect?: boolean;
}
