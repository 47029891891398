import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { Expenses } from 'modules/Expenses/expenses';
import { LocalizationContext } from 'contexts';
import { PreviewTable } from 'shared/components/layout/PreviewTable/PreviewTable';


export const PreviewDataStep = (props: PreviewDataStepProps) => {
  const {
    previewColumns,
    isFirstRowHeaders,
    setIsFirstRowHeaders,
    rowsNum,
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);

  const previewRows = React.useMemo<string[][]>(() => {
    const rows = [];

    for (let i = 0; i < rowsNum; i++) {
      rows.push(previewColumns.map((col) => col.column[i]));
    }

    return rows;
  }, [previewColumns, rowsNum]);

  return (
    <React.Fragment>
      <Box className="_d-flex-jc-end" mb={1}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isFirstRowHeaders}
              onChange={() => setIsFirstRowHeaders(!isFirstRowHeaders)}
            />
          }
          label={dictionary.expenses.uploadIntegration.previewData.hasHeaders}
        />
      </Box>
      <PreviewTable
        columnHeaders={previewColumns.map((col) => col.title)}
        previewRows={previewRows}
      />
    </React.Fragment>
  );
};

interface PreviewDataStepProps {
    previewColumns: Expenses.CsvColumn[];
    isFirstRowHeaders: boolean;
    setIsFirstRowHeaders: (isIt: boolean) => void;
    rowsNum: number;
}