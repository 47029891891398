import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const ArrowTrendingDownIcon = ({ ...props }) => (
  <IconContainer {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.71136 5.19671C1.99318 4.93443 2.4501 4.93443 2.73191 5.19671L7.2731 9.42319L10.8991 6.0485C11.0436 5.91396 11.2427 5.8428 11.4469 5.85269C11.651 5.86258 11.8411 5.95259 11.9698 6.10037C13.5977 7.96854 14.8628 10.157 15.6382 12.5961L17.1533 10.1538C17.3526 9.83255 17.7939 9.72249 18.1391 9.90795C18.4842 10.0934 18.6025 10.5042 18.4032 10.8254L16.0219 14.6641C15.8226 14.9853 15.3813 15.0954 15.0361 14.9099L10.9116 12.6937C10.5665 12.5082 10.4482 12.0974 10.6475 11.7762C10.8468 11.455 11.2881 11.3449 11.6333 11.5304L14.2394 12.9307C13.5986 10.9351 12.6054 9.1229 11.341 7.53682L7.78337 10.8479C7.64804 10.9739 7.46449 11.0446 7.2731 11.0446C7.08171 11.0446 6.89816 10.9739 6.76282 10.8479L1.71136 6.14654C1.42955 5.88425 1.42955 5.459 1.71136 5.19671Z"
      fill="currentColor"
    />
  </IconContainer>
);
