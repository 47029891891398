import React, { Fragment, useContext } from 'react';
import { Divider, Grid } from '@mui/material';
import { LocalizationContext } from 'contexts';

import { useCarbonRemovalData } from 'shared/hooks/CarbonRemoval/useCarbonRemovalData';

import EmissionBoxSection from 'shared/components/layout/EmissionBoxSection/EmissionBoxSection';

import { FilterModules, FiltersSection } from 'shared/components/interactive/FiltersSection/FiltersSection';
import BundleSection from './BundleSection';
import Purchases from './Purchases';
import BundleSectionSkeleton from './BundleSectionSkeleton';


const CarbonRemoval: React.FunctionComponent = () => {
  const { dictionary } = useContext(LocalizationContext);

  const {
    emissionWeight,
    luneBundles,
    luneOffsetKey,
    isAnyDataLoading,
    totalOffset,
    offsetTransactions,
  } = useCarbonRemovalData();

  const netEmission = totalOffset + emissionWeight;

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <FiltersSection
          filterModules={[
            FilterModules.YEAR,
          ]}
          title={dictionary.carbonRemoval.title}
        >
          {({ filters }) => (
            <Fragment>
              <EmissionBoxSection
                isLoading={isAnyDataLoading}
                totalOffset={totalOffset}
                totalEmission={emissionWeight}
                netEmission={netEmission}
                titleOffset={dictionary.carbonRemoval.emissionBox.titleOffset}
                titleTotalEmission={dictionary.carbonRemoval.emissionBox.titleCarbon}
                titleNetEmission={dictionary.carbonRemoval.emissionBox.netEmissions}
                totalEmissionTooltip={dictionary.tooltips.totalEmissions}
              />
              <Divider/>
              {!isAnyDataLoading && offsetTransactions && offsetTransactions.results.length > 0 && (
                <Purchases offsetTransactions={offsetTransactions}/>
              )}
              <Divider/>
              {isAnyDataLoading ? (
                <BundleSectionSkeleton/>
              ) : (
                <BundleSection
                  luneBundles={luneBundles!}
                  luneOffsetKey={luneOffsetKey}
                />
              )}
            </Fragment>
          )}
        </FiltersSection>
      </Grid>
    </Grid>
  );
};

export default CarbonRemoval;
