import React from 'react';
import { IconContainer } from './IconContainer';


export const BoltIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      d="M9.5863 1.52555C9.64331 1.25949 9.51415 0.988374 9.27162 0.865025C9.02908 0.741675 8.73391 0.796975 8.55248 0.999754L1.75248 8.59975C1.59469 8.7761 1.55529 9.02871 1.65188 9.24473C1.74846 9.46075 1.96299 9.59983 2.19962 9.59983H7.45743L6.41294 14.4741C6.35593 14.7402 6.48509 15.0113 6.72762 15.1346C6.97016 15.258 7.26533 15.2027 7.44676 14.9999L14.2468 7.39991C14.4045 7.22356 14.4439 6.97095 14.3474 6.75493C14.2508 6.53891 14.0363 6.39983 13.7996 6.39983H8.54181L9.5863 1.52555Z"
      fill="currentColor"
    />
  </IconContainer>
);
