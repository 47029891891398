// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Avatar = (theme: Theme) => {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          '& svg': {
            width: 16,
            height: 16,
          },
        },
        colorDefault: {
          backgroundColor: theme.palette.primary.light,
          '& svg': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  };
};

export default Avatar;
