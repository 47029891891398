import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { cacheKeys, persistKeys } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';
import { Workspace } from 'clients/users/userClient.types';
import { useBulkQueryKeys } from './useBulkQueryKeys';


export const useWorkspaces = (): Workspaces => {
  const { queryKeys } = useBulkQueryKeys(persistKeys.workspaceChange);
  const queryClient = useQueryClient();
  const { genericError, genericSuccess } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);

  const { data: { data: workspaces = [] } = {}, status, error } = useQuery(
    [cacheKeys.workspaces.getWorkspaces],
    () => usersClient.getWorkspaces(),
  );

  const clearWorkspaceCache = () => {
    Promise.all([
      queryKeys.forEach(queryKey => queryClient.resetQueries([queryKey])),
      queryClient.invalidateQueries(cacheKeys.users.getMe),
      queryClient.invalidateQueries(cacheKeys.emissionFactorCategories.getEmissionFactorCategories),
      queryClient.resetQueries(cacheKeys.workspaces.getWorkspaces),
    ])
      .then(() => {
        genericSuccess(dictionary.workspace.changeSuccess);
      })
      .catch(() => genericError());
  };

  return {
    status,
    error,
    workspaces,
    clearWorkspaceCache,
  };
};

export interface Workspaces {
  error: unknown;
  status: 'idle' | 'loading' | 'error' | 'success';
  workspaces: Workspace[];
  clearWorkspaceCache: () => void;
}