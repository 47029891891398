import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

import { useComponentVisibility } from 'shared/hooks/useComponentVisibility';


export const OverflowTooltip: React.FC<React.PropsWithChildren<Omit<TooltipProps, 'title' | 'disableHoverListener' | 'children'>>> = ({ children, ...props }) => {
  const [isOverflowed, setIsOverflow] = React.useState(false);
  const textElementRef = React.useRef<HTMLDivElement>(null);
  const isVisible = useComponentVisibility(textElementRef);

  const onVisibilityChange = React.useCallback(() => {
    if (textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  }, []);

  React.useLayoutEffect(() => {
    if (isVisible) {
      onVisibilityChange();
    }
  }, [isVisible, onVisibilityChange]);

  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed} {...props}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
