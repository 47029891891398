import React, {
  useContext,
  useMemo,
} from 'react';
import {
  Box,
} from '@mui/material';

import { HomeIcon } from 'shared/icons/NavIcons/HomeIcon';
import { ChartPieIcon } from 'shared/icons/NavIcons/ChartPieIcon';
import { BuilderIcon } from 'shared/icons/NavIcons/BuilderIcon';

import { LeafIcon } from 'shared/icons/NavIcons/LeafIcon';
import { ArrowTrendingDownIcon } from 'shared/icons/NavIcons/ArrowTrendingDownIcon';
import { CoinsIcon } from 'shared/icons/NavIcons/CoinsIcon';
import { CloudIcon } from 'shared/icons/NavIcons/CloudIcon';
import { AcademicCapIcon } from 'shared/icons/NavIcons/AcademicCapIcon';
import { ChatBubbleBottomCenterTextIcon } from 'shared/icons/NavIcons/ChatBubbleBottomCenterTextIcon';
import { UserGroupIcon } from 'shared/icons/NavIcons/UserGroupIcon';
import { BoltIcon } from 'shared/icons/NavIcons/BoltIcon';
import { LightBulbMiniIcon } from 'shared/icons/LightbulbIcon';
import { ShieldCheckIcon } from 'shared/icons/ShieldCheckIcon';
import { featureFlags, routes } from 'config';
import { LocalizationContext, UserContext } from 'contexts';
import { UserNavItemSection } from './UserNavItemSection';


export const UserNavItems = ({ isCollapsed }: {isCollapsed: boolean}) => {

  const { dictionary } = useContext(LocalizationContext);
  const { isViewer } = useContext(UserContext);

  const mainMenuTopItems = useMemo(
    () =>
      [
        {
          enabled: true,
          subItems: [
            {
              title: dictionary.menu.dashboard,
              link: '/',
              icon: <HomeIcon/>,
              enabled: true,
            },
            {
              title: dictionary.menu.measurements,
              link: routes.measurements,
              icon: <ChartPieIcon/>,
              enabled: featureFlags.pages.measurements,
            },
          ],
        },
        {
          title: dictionary.menu.act.title,
          enabled: !isViewer,
          subItems: [
            {
              title: dictionary.menu.act.scenarioBuilder,
              enabled: featureFlags.pages.scenarioBuilder,
              link: routes.scenarioBuilder,
              icon: <BuilderIcon/>,
            },
            {
              title: dictionary.menu.act.marketplace,
              enabled: featureFlags.pages.marketplace,
              link: routes.marketplace,
              icon: <LeafIcon/>,
            },
            {
              title: dictionary.menu.act.carbonRemoval,
              enabled: featureFlags.pages.carbonRemoval,
              link: routes.carbonRemoval,
              icon: <ArrowTrendingDownIcon/>,
            },
          ],
        },
        {
          title: dictionary.menu.data.title,
          enabled: featureFlags.dataManager && !isViewer,
          subItems: [
            {
              title: dictionary.menu.data.expenses,
              link: routes.expenses,
              icon: <CoinsIcon/>,
              enabled: featureFlags.pages.expenses,
            },
            {
              title: dictionary.menu.data.activityBasedTransactions,
              link: routes.activityBasedTransactions,
              icon: <BoltIcon/>,
              enabled: featureFlags.activityBasedTransactions,
            },
            {
              title: dictionary.menu.data.employees,
              link: routes.employees,
              icon: <UserGroupIcon/>,
              enabled: featureFlags.pages.employees,
            },
            {
              title: dictionary.menu.data.emissionFactors,
              link: routes.emissionFactors,
              icon: <CloudIcon/>,
              enabled: featureFlags.pages.emissionFactors,
            },
            {
              title: dictionary.menu.data.esrs,
              link: routes.esrs,
              icon: <ShieldCheckIcon/>,
              enabled: featureFlags.pages.esrs,
            },
          ],
        },
      ],
    [dictionary, isViewer],
  );

  const mainMenuBottomItems = useMemo(
    () =>
      [
        {
          enabled: true,
          subItems: [
            {
              title: dictionary.menu.knowledgeBase,
              link: routes.knowledgeBase,
              icon: <LightBulbMiniIcon/>,
              enabled: featureFlags.pages.knowledgeBase,
            },
            {
              title: dictionary.menu.academy,
              link: 'https://www.coolset.com/academy',
              icon: <AcademicCapIcon/>,
              enabled: featureFlags.pages.academy,
              isRedirect: true,
            },
            {
              title: dictionary.menu.feedback,
              link: 'https://tally.so#tally-open=npbEPB&tally-align-left=1&tally-hide-title=1&tally-auto-close=0',
              icon: <ChatBubbleBottomCenterTextIcon/>,
              enabled: featureFlags.pages.feedback,
            },
          ],
        },
      ],
    [dictionary],
  );

  return (
    <Box className="_d-flex-dir-col-jc-sb">
      <div>
        {mainMenuTopItems.map((props, i) => (
          <UserNavItemSection key={i} isCollapsed={isCollapsed} {...props}/>
        ))}
      </div>


      {mainMenuBottomItems.map((props, i) => (
        <UserNavItemSection key={i} isCollapsed={isCollapsed} {...props}/>
      ))}
    </Box>
  );
};
