import { FC, ReactElement } from 'react';

import { InsightCard } from 'shared/components/layout/InsightCard/InsightCard';
import EmissionBoxBar from 'views/LoggedIn/CarbonRemoval/EmissionBoxBar';


interface EmissionsComparingMessageBoxProps {
  text: ReactElement | string;
  rightComponent?: ReactElement;
}

const emissionBarHeights = [20, 14, 8, 21, 24, 12];

const EmissionsComparingMessageBox: FC<EmissionsComparingMessageBoxProps> = ({
  text,
  rightComponent,
}) => {
  return (
    <InsightCard
      text={text}
      image={
        <div className="_d-flex-ali-end-xxs-gap">
          {emissionBarHeights.map((height, index) => (
            <EmissionBoxBar
              key={index}
              variant="netEmissions"
              smallBarHeight={height}
              size="small"
            />
          ))}
        </div>
      }
      actions={rightComponent}
    />
  );
};

export default EmissionsComparingMessageBox;
