import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const BuilderIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.800781 2.19998C0.800781 1.8686 1.06941 1.59998 1.40078 1.59998H14.6008C14.9322 1.59998 15.2008 1.8686 15.2008 2.19998C15.2008 2.53135 14.9322 2.79998 14.6008 2.79998H14.4008V9.79998C14.4008 11.015 13.4158 12 12.2008 12H11.3428L11.9815 14.4485C12.0651 14.7691 11.873 15.0969 11.5524 15.1805C11.2317 15.2641 10.904 15.072 10.8204 14.7514L10.7287 14.3999H5.27318L5.1815 14.7514C5.09785 15.072 4.77011 15.2641 4.44947 15.1805C4.12883 15.0969 3.93671 14.7691 4.02036 14.4485L4.6591 12H3.80078C2.58576 12 1.60078 11.015 1.60078 9.79998V2.79998H1.40078C1.06941 2.79998 0.800781 2.53135 0.800781 2.19998ZM5.89925 12L5.58622 13.1999H10.4156L10.1026 12H5.89925ZM5.60078 3.64998C6.01499 3.64998 6.35078 3.98576 6.35078 4.39998V8.79998C6.35078 9.21419 6.01499 9.54998 5.60078 9.54998C5.18657 9.54998 4.85078 9.21419 4.85078 8.79998V4.39998C4.85078 3.98576 5.18657 3.64998 5.60078 3.64998ZM10.4008 7.24998C10.815 7.24998 11.1508 7.58576 11.1508 7.99998V8.79998C11.1508 9.21419 10.815 9.54998 10.4008 9.54998C9.98657 9.54998 9.65078 9.21419 9.65078 8.79998V7.99998C9.65078 7.58576 9.98657 7.24998 10.4008 7.24998ZM8.75078 6.19998C8.75078 5.78576 8.41499 5.44998 8.00078 5.44998C7.58657 5.44998 7.25078 5.78576 7.25078 6.19998V8.99998C7.25078 9.41419 7.58657 9.74998 8.00078 9.74998C8.41499 9.74998 8.75078 9.41419 8.75078 8.99998V6.19998Z"
      fill="currentColor"
    />
  </IconContainer>
);
