export function* infiniteArrayIterator(array: any[]) {
  let n = 0;
  while (true) {
    if(n < array.length) {
      n++;
      yield array[n - 1];
    } else {
      n = 0;
    }
  }
}
