import { ComponentProps, FC } from 'react';
import { IconContainer } from './IconContainer';


export const ArrowIcon: FC<ComponentProps<typeof IconContainer>> = ({
  ...props
}) => (
  <IconContainer viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66112 6.27251C4.87012 6.03812 4.9375 6 5.375 6.00003L8 6L10.625 6.00005C11.0625 6.00003 11.1299 6.03812 11.3389 6.27251C11.5479 6.50689 11.5544 6.89451 11.3534 7.13827L8.37844 10.8121C8.27946 10.9321 8.14281 11 8 11C7.85719 11 7.72055 10.9321 7.62156 10.8121L4.64656 7.13827C4.4456 6.89451 4.45211 6.50689 4.66112 6.27251Z"
      fill="currentColor"
    />
  </IconContainer>
);

export const ArrowCircleIcon: FC<
  ComponentProps<typeof IconContainer>
> = ({ ...props }) => (
  <IconContainer {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75V11.3401L7.29959 9.23966C7.01774 8.93613 6.54319 8.91855 6.23966 9.20041C5.93613 9.48226 5.91855 9.95681 6.20041 10.2603L9.45041 13.7603C9.59231 13.9132 9.79145 14 10 14C10.2086 14 10.4077 13.9132 10.5496 13.7603L13.7996 10.2603C14.0814 9.95681 14.0639 9.48226 13.7603 9.20041C13.4568 8.91855 12.9823 8.93613 12.7004 9.23966L10.75 11.3401V6.75Z"
      fill="currentColor"
    />
  </IconContainer>
);

export const ArrowSmallIcon: FC<
  ComponentProps<typeof IconContainer>
> = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 24 24">
    <path
      d="M12 4.5V19.5M12 19.5L18.75 12.75M12 19.5L5.25 12.75"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </IconContainer>
);