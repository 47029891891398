import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { GetListParams } from 'types/Query';
import { emissionFactorsClient } from 'clients/emissionFactors/emissionFactorsClient';


type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useEmissionFactors = (params: GetListParams<{ manualInputId: number, search?: string }> = {}, options: Partial<Options> = defaultOptions) => {

  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.emissionFactors.getEmissionFactorsAutocomplete, params],
    () => emissionFactorsClient.getEmissionFactorsAutocomplete(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    emissionFactors: data?.results || [],
  };
};
