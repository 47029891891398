import { useQuery } from 'react-query';
import { marketplaceClient } from 'modules/Marketplace/client/marketplaceClient';
import { Marketplace } from 'modules/Marketplace/marketplace';
import { cacheKeys } from 'config';


export const useMarketplaceData = (params?: Marketplace.Vendors.GetVendorsParams) => {

  const {
    data: { data: marketplaceData } = {},
    isLoading,
  } = useQuery(
    [cacheKeys.marketplace.getMarketplaceVendors, params],
    async () => {
      const res = await marketplaceClient.getMarketplaceVendors(params);
      if (!res.data.results.length) {
        const resWithoutFilters = await marketplaceClient.getMarketplaceVendors({ vendorType: params?.vendorType });
        resWithoutFilters.data.isEmptyState = true;

        return resWithoutFilters;
      }

      return res;
    },
  );

  return {
    marketplaceVendors: marketplaceData?.results || [],
    vendorCount: marketplaceData?.count || 0,
    isLoading,
    isEmptyState: !!marketplaceData?.isEmptyState,
  };
};
