import { MaybeNull } from 'types';


export enum Dialog {
  InviteTeamMember = 'inviteTeamMember',
  CompanyOverview = 'companyOverview',
  WorkspaceChange = 'workspaceChange',
};

export type ContextProps = {
  openDialog: (dialog: Dialog, options?: any) => any;
  closeDialog: (dialog: Dialog) => any;
  asyncConfirmation: AsyncConfirmation;
};

export type AsyncDialogProps = {
  show?: boolean;
  title?: MaybeNull<React.ReactNode>;
  content?: MaybeNull<React.ReactNode>;
  confirmLabel?: MaybeNull<React.ReactNode>;
  cancelLabel?: MaybeNull<React.ReactNode>;
  CustomDialog?: React.FC<{onClose: () => void, onConfirm: () => void, open: boolean }>;
  isDelete?: boolean;
}

export type AsyncConfirmation = (props: AsyncDialogProps) => Promise<boolean>;
