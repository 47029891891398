import React from 'react';
import { Box, Card } from '@mui/material';
import { Emissions } from 'shared/components/layout/Emissions/Emissions';


export const Tooltip = (props: any) => {
  const {
    active,
    payload,
    showTooltipOnHoverBetweenItems,
    highlightedDimension,
    dimensionsMap,
    metricsMap,
    xAxisLabelFormatter = (v: any) => v,
    hideUnits = false,
  } = props;
  const dateDimensionKey = Object.keys(dimensionsMap).find(
    (key: any) => dimensionsMap?.[key]?.type === 'date',
  );
  const [firstPayload] = payload || [{}];
  const isHovering = !!highlightedDimension;

  if ((!isHovering && !showTooltipOnHoverBetweenItems) || !active || !payload) return null;

  return (
    <Card
      sx={{
        boxShadow: '0px 5px 15px -3px rgba(0, 0, 0, 0.1), 0px 2px 6px -4px rgba(0, 0, 0, 0.1)',
        borderRadius: 1,
      }}
    >
      <Box p={1}>
        <Box sx={{ fontWeight: 600 }}>
          {xAxisLabelFormatter(firstPayload?.payload?.[dateDimensionKey || ''])}
        </Box>
        {payload
          .sort((prev: any, next: any) => (prev?.name > next?.name ? 1 : -1))
          .map((props: any, i: number) => {
            const { dataKey, value } = props;
            const dimension = dimensionsMap[dataKey];
            const metric = metricsMap[dataKey];

            if (dimension) {
              return (
                <div key={i}>
                  {dimension.label}:{' '}
                  {dataKey === 'sum_co2_kg' ? (
                    <Emissions kg={value}/>
                  ) : (
                    <span style={{ fontWeight: 600 }}>{value}</span>
                  )}
                </div>
              );
            }
            if (metric) {
              if (highlightedDimension && highlightedDimension !== dataKey) {
                return null;
              }

              return (
                <div key={i}>
                  {metric.label}:{' '}
                  {dataKey === 'sum_co2_kg' ? (
                    <Emissions kg={value}/>
                  ) : (
                    <span style={{ fontWeight: 600 }}>
                      {value >= 1000
                        ? `${(value / 1000).toFixed(0)} ${hideUnits ? '' : 't'}`
                        : `${value.toFixed(0)} ${hideUnits ? '' : 'kg'}`}
                    </span>
                  )}
                </div>
              );
            }

            return null;
          })}
      </Box>
    </Card>
  );
};
