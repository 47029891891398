import React, { FC, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IconButton, Skeleton, Tooltip as MuiTooltip, useTheme } from '@mui/material';
import { LayersClear, ZoomOutMap } from '@mui/icons-material';
import { Box } from '@mui/system';
import {
  Area,
  Bar,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  Tooltip as ReTooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
} from 'recharts';
import { addYears, format, set } from 'date-fns';

import { pastelPalette } from 'shared/styles/charts';
import { scopeColors } from 'shared/styles/muiTheme';
import { uniqBy } from 'lodash';

import { Tooltip } from 'modules/Charts/components/ChartTooltip/ChartTooltip';
import { CustomLegend } from 'modules/Charts/components/ChartLegend/ChartLegend';
import NewChartTooltip from 'modules/Charts/components/ChartTooltip/NewChartTooltip';

import { textTertiary } from 'shared/styles/theme/palette';
import { LayoutContext } from 'contexts';
import { MaybeNull } from 'types';
import { infiniteArrayIterator } from 'helpers/array';


import LoadingModal from 'shared/components/layout/Modal/LoadingModal/LoadingModal';

import { Chart } from './MultiAreaChart.types';
import { mapComparissonData, getBarOpacity, getDistinctYearsCount, mapData } from './MultiAreaChartHelpers';


const defaultZoomState = {
  left: 'dataMin',
  right: 'dataMax',
  refAreaLeft: '',
  refAreaRight: '',
  top: 'dataMax+1',
  bottom: 'dataMin-1',
  animation: true,
};

type Props = {
  data: Chart;
  previousData?: Chart;
  showLegend?: boolean;
  showAxisX?: boolean;
  showAxisY?: boolean;
  showChart?: boolean;
  enableZoom?: boolean;
  showGrid?: boolean;
  dateAggregation?: string;
  onZoomChange?: (props: { from?: MaybeNull<string>, to?: MaybeNull<string> }) => void;
  yAxisLabelFormatter?: (value: any) => string;
  xAxisLabelFormatter?: (value: any) => string;
  highlightedDimension?: any;
  setHighlightedDimension?: any;
  dimension?: any;
  isLoadingCharts?: boolean,
  xMargin?: boolean,
  isFetchingCharts?: boolean,
  setDimension?: any;
  prevYearNegativeChart?: any,
  allowDataOverflow?: boolean;
  enableClick?: boolean;
  setEmissionsBarChartRef?: any,
  newTooltip?: boolean,
  showTooltipOnHoverBetweenItems?: boolean,
  negativeValues?: any,
  mapNetOverviewData?: boolean,
  hideTooltipUnits?: boolean,
}


export const MultiAreaChart: FC<Props> = (props) => {

  const theme = useTheme();
  const { emissionFactorCategoriesColors, mode, isResizing } = useContext(LayoutContext);
  const {
    isLoadingCharts,
    isFetchingCharts,
    data: { values = [], metrics = [], dimensions = [] } = {},
    showLegend = true,
    showAxisX,
    showAxisY,
    showChart = true,
    negativeValues,
    enableZoom,
    mapNetOverviewData = false,
    showTooltipOnHoverBetweenItems = false,
    newTooltip,
    previousData,
    allowDataOverflow = true,
    xMargin,
    prevYearNegativeChart,
    highlightedDimension = undefined,
    setHighlightedDimension = (value: any) => value,
    onZoomChange = () => null,
    yAxisLabelFormatter = (value: any) => {
      if (value >= 100 || value <= -100 || value === 0) {
        return `${(value / 1000).toFixed((value >= 1000 || value <= -1000) ? 0 : 1)} t`;
      }
      else {
        return `${value.toFixed(0)} kg`;
      }
    },
    xAxisLabelFormatter = (value: any) => {
      try {
        const [year, month = 1, day = 1] = value.split('-');
        if (!isNaN(year)) {
          const date = set(new Date(), { year: parseInt(year), month: parseInt(month), date: parseInt(day) - 1 });
          const previousYearDate = previousData ? addYears(date, -1) : null;
          if (props?.dateAggregation === 'month' && yearsCount > 1) {
            if (previousData) {
              const currentYear = format(date, '\'\'yy');
              const previousYear = format(previousYearDate!, 'MMM \'\'yy');
              return `${previousYear} / ${currentYear}`;
            }
            return format(date, 'MMM \'\'yy');
          }
          if(props?.dateAggregation === 'day') {
            return format(date, ' dd MMM ');
          }
          if(props?.dateAggregation === 'month') {
            return format(date, ' MMM ');
          }
          if(props?.dateAggregation === 'year') {
            const currentYear = format(date, ' yyyy ');
            if (previousData){
              const previousYear = format(previousYearDate!, ' yyyy ');
              return `${previousYear} - ${currentYear}`;
            }
            return currentYear;
          }
        }
      } catch(e) {
        console.error(e);
      }
      return value;
    },
    showGrid = true,
    hideTooltipUnits = false,
  } = props || {};

  const [selectedDimension, setSelectedDimension] = useState<any>(null);
  const [zoomState, setZoomState] = useState<any>(defaultZoomState);
  const [zoomStart, setZoomStart] = useState<number|null>(null);
  const [zoomEnd, setZoomEnd] = useState<number|null>(null);
  const composedChartRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    setSelectedDimension(null);
  }, [values]);

  const {
    left,
    right,
    refAreaLeft,
    refAreaRight,
  } = zoomState;

  const zoomOut = useCallback(() => {
    setZoomStart(null);
    setZoomEnd(null);
    setZoomState(defaultZoomState);
    onZoomChange({ from: null, to: null });
  }, [onZoomChange]);

  const zoom = useCallback(() => {
    const { start, end, refAreaLeft, refAreaRight } = zoomState;
    if(start && end) {
      if(onZoomChange) {
        onZoomChange({ from: refAreaLeft , to: refAreaRight });
        setZoomStart(null);
        setZoomEnd(null);
        setZoomState(defaultZoomState);
      } else {
        setZoomStart(start < end ? start : end);
        setZoomEnd((start < end ? end : start) + 1);
        setZoomState(((prevState: any) => ({
          ...prevState,
          refAreaLeft: '',
          refAreaRight: '',
          left: refAreaLeft,
          right: refAreaRight,
        })));
      }
    }
  }, [zoomState, onZoomChange]);

  const colors = useMemo(() => infiniteArrayIterator(pastelPalette), []);

  const onLegendClick = useCallback((e: any) => {
    const { dataKey } = e;
    setSelectedDimension((prevState: any) => prevState === dataKey ? null : dataKey);
  }, []);

  const opacity = useCallback((key: any) => {
    return selectedDimension === key || selectedDimension === null ? 1 : 1;
  }, [selectedDimension]);

  const aggregateBy = useMemo(() => {
    return dimensions.find((dimension: any) => !!dimension?.aggregation?.key);
  }, [dimensions]);

  const mappedMetrics = useMemo(() => {
    let _metrics = metrics;
    const allValues = values.concat(negativeValues?.values || []).concat(prevYearNegativeChart?.values || []);
    if(aggregateBy) {
      _metrics = [
        // @ts-ignore
        ...[...new Set(allValues.map(({ dimensions }: any) => dimensions[aggregateBy.aggregation.key]))].map((key: any) => ({
          key: key,
          label: `${metrics[0].label} ${key}`,
          // @ts-ignore
          chart: aggregateBy.aggregation.chart,
          opacity: highlightedDimension ? (highlightedDimension === key ? 1 : 0.3) : 1,
          ...(metrics[0].stack_id && { stack_id: metrics[0].stack_id }),
        })),
      ];
    }

    const mappedMetrics = _metrics.map((metric) => {
      const mappedMetric = {
        color: scopeColors?.[metric?.key as keyof typeof scopeColors] || emissionFactorCategoriesColors?.[metric?.key],
        ...metric,
      };
      if(!mappedMetric?.color) {
        mappedMetric.color = _metrics?.length > 1 ? colors.next().value : theme.palette.primary.main;
      }
      return mappedMetric;
    }).sort((a, b) => parseInt(a.key) > parseInt(b.key) ? -1 : 1 );

    return mappedMetrics;
  }, [metrics, values, negativeValues?.values, prevYearNegativeChart?.values, aggregateBy, highlightedDimension, emissionFactorCategoriesColors, colors, theme.palette.primary.main]);

  const dimensionsMap = useMemo(() => {
    return dimensions.reduce((map: any, dimension: any) => { map[dimension.key] = dimension; return map; }, {} as any);
  }, [dimensions]);

  const metricsMap = useMemo(() => {
    return mappedMetrics.reduce((map: any, metric: any) => { map[metric.key] = metric; return map; }, {} as any);
  }, [mappedMetrics]);

  const { xAxis, yAxis } = useMemo(() => {
    type axisProps = {
      key: string;
      type: 'category' | 'number'
    }
    let xAxis: axisProps = { key: dimensions?.[0]?.key, type: 'category' };
    let yAxis: undefined | axisProps = undefined;
    Object.keys(dimensionsMap).forEach(key => {
      if(dimensionsMap[key]?.type === 'date') {
        xAxis = { key, type: 'category' };
      }
      if(dimensionsMap[key]?.axis) {
        if(dimensionsMap[key]?.axis === 'x') {
          xAxis = { key, type: 'category' };
        }
        if(dimensionsMap[key]?.axis === 'y') {
          yAxis = { key, type: 'category' };
        }
      }
    });
    Object.keys(metricsMap).forEach(key => {
      if(metricsMap[key]?.axis) {
        if(metricsMap[key]?.axis === 'x') {
          xAxis = { key, type: 'number' };
        }
        if(metricsMap[key]?.axis === 'y') {
          yAxis = { key, type: 'number' };
        }
      }
    });
    return { xAxis, yAxis };
  }, [dimensionsMap, metricsMap, dimensions]);

  const { mappedData, yearsCount } = useMemo(() => {
    const mapCurrentYearData = mapData({ values: values, aggregateBy, metrics, selectedDimension, zoomStart, zoomEnd });
    const mappedNegativeValues = negativeValues?.values && mapData({ values: negativeValues?.values || [], aggregateBy, metrics, selectedDimension, zoomStart, zoomEnd });
    const mappedPrevYearNegativeValues = prevYearNegativeChart?.values && mapData({ values: prevYearNegativeChart?.values || [], aggregateBy, metrics, selectedDimension, zoomStart, zoomEnd });
    const mappedPreviousYearData = previousData?.values ? mapData({ values: previousData?.values || [], aggregateBy, metrics, selectedDimension, zoomStart, zoomEnd }) : null;
    const allCurrentData = uniqBy([...mapCurrentYearData, ...mappedNegativeValues || []], `date_${props?.dateAggregation}` || 'date_month');
    const mappedData = mapNetOverviewData ? mapComparissonData(allCurrentData, mapCurrentYearData, mappedPreviousYearData, aggregateBy, mappedNegativeValues, mappedPrevYearNegativeValues) : mapCurrentYearData;
    const yearsCount = getDistinctYearsCount(mapCurrentYearData);
    return { yearsCount, mappedData };
  }, [aggregateBy, mapNetOverviewData, metrics, negativeValues?.values, prevYearNegativeChart?.values, previousData?.values, props?.dateAggregation, selectedDimension, values, zoomEnd, zoomStart]);

  const getDataKey = useCallback(({ accessor, key }: { accessor: string, key: string }) => {
    return `${accessor}.${key}`;
  }, []);

  const CustomizedAxisTick = ({ x, y, payload }: any) => {
    const isFirst = payload.index === 0;
    const isLast = payload.index === mappedData.length - 1;
    let anchor = 'middle';

    if (!showGrid || !mappedMetrics.some((it: any) => it.chart === 'bar')) {
      if (isFirst) {
        anchor = 'start';
      }
      if (isLast) {
        anchor = 'end';
      }
    }


    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor={anchor} fill={textTertiary} fontSize={12}>{xAxisLabelFormatter(payload.value)}</text>
      </g>
    );
  };

  return (
    <Fragment>
      {(isLoadingCharts || isResizing) ? (
        <Skeleton width="100%" height="100%" variant="rounded"/>
      ) : (
        <Box key={mode} sx={{ position: 'relative', height: '100%', width: '100%' }}>
          <LoadingModal open={isFetchingCharts}/>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              ref={composedChartRef}
              margin={{ top: 0, right: xMargin ? yearsCount > 1 ? 30 : 20 : 0, left: xMargin ? yearsCount > 1 ? 30 : 20 : 0, bottom: 0 }}
              style={{ userSelect: 'none' }}
              data={mappedData}
              stackOffset={(negativeValues?.values.length > 0 || prevYearNegativeChart?.values) ? 'sign' : 'none' }
              onMouseDown={enableZoom ? (e: any) => {
                if(enableZoom && e) {
                  setZoomState((prevState: any) => ({
                    ...prevState,
                    start: e.activeTooltipIndex,
                    refAreaLeft: e.activeLabel,
                  }));
                }
              } : undefined}
              onMouseMove={enableZoom ? (e: any) => {
                if(enableZoom && e && zoomState.refAreaLeft) {
                  setZoomState((prevState: any) => ({
                    ...prevState,
                    end: e.activeTooltipIndex,
                    refAreaRight: e.activeLabel,
                  }));
                }
              } : undefined}
              onMouseUp={enableZoom ? zoom : undefined}
            >
              <defs>
                {((mappedMetrics || []).length > 0 ? mappedMetrics : [{}]).map(({ color }: any, i: number) => {
                  return (
                    <linearGradient key={i} id={`color${i}`} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={color} stopOpacity={0.12}/>
                      <stop offset="96%" stopColor={color} stopOpacity={0}/>
                    </linearGradient>
                  );
                })}
              </defs>
              {showGrid && <CartesianGrid horizontal={true} vertical={false} strokeOpacity={0.35}/>}
              <XAxis
                domain={[left, right]}
                dataKey={xAxis.key}
                type={xAxis.type as 'number' | 'category'}
                allowDataOverflow={true}
                stroke="#E8E8E8"
                tickLine={false}
                interval={mappedData.length > 12 ? 'preserveStartEnd' : 0}
                hide={!showAxisX}
                tick={<CustomizedAxisTick/>}
              />
              <ReTooltip
                cursor={false}
                content={(props: any) => (
                  newTooltip ? (
                    <NewChartTooltip
                      {...props}
                      dimensionsMap={dimensionsMap}
                      metricsMap={metricsMap}
                      highlightedDimension={highlightedDimension}
                      xAxisLabelFormatter={xAxisLabelFormatter}
                      tooltipRef={tooltipRef}
                    />
                  ) : (
                    <Tooltip
                      {...props}
                      showTooltipOnHoverBetweenItems={showTooltipOnHoverBetweenItems}
                      dimensionsMap={dimensionsMap}
                      metricsMap={metricsMap}
                      highlightedDimension={highlightedDimension}
                      xAxisLabelFormatter={xAxisLabelFormatter}
                      hideUnits={hideTooltipUnits}
                    />
                  )
                )}
              />
              {showChart && mappedMetrics.map(({ chart, key, color, stack_id, opacity: _opacity }: any, i: number) => {
                const prevDataKey = getDataKey({ accessor: 'prevYear', key });
                const currentDataKey = getDataKey({ accessor: 'currentYear', key });
                const negativeDataKey = getDataKey({ accessor: 'negativeValues', key });
                const prevNegativeDataKey = getDataKey({ accessor: 'prevYearNegativeValues', key });

                if(chart === 'bar') {
                  return (
                    <Fragment key={i}>
                      <Bar
                        yAxisId="1"
                        dataKey={currentDataKey}
                        ref={(ref: any) => props.setEmissionsBarChartRef && props.setEmissionsBarChartRef(ref)}
                        stroke="#ffffff"
                        strokeWidth={2}
                        fillOpacity={getBarOpacity(highlightedDimension, currentDataKey) || _opacity}
                        fill={!stack_id ? `url(#color${i})` : color}
                        radius={[4, 4, 0, 0]}
                        animationDuration={300}
                        stackId={stack_id}
                        onMouseEnter={() => {
                          setHighlightedDimension(currentDataKey);
                          tooltipRef.current = key;
                        }}
                        onMouseLeave={() => {
                          setHighlightedDimension(undefined);
                          tooltipRef.current = null;
                        }}
                      >
                      </Bar>
                      <Bar
                        yAxisId="1"
                        dataKey={negativeDataKey}
                        ref={(ref: any) => props.setEmissionsBarChartRef && props.setEmissionsBarChartRef(ref)}
                        stroke="#ffffff"
                        strokeWidth={2}
                        fillOpacity={getBarOpacity(highlightedDimension,negativeDataKey) || _opacity}
                        fill="#EBC371"
                        radius={[4, 4, 0, 0]}
                        animationDuration={300}
                        stackId={stack_id}
                        onMouseEnter={() => {
                          setHighlightedDimension(negativeDataKey);
                          tooltipRef.current = key;
                        }}
                        onMouseLeave={() => {
                          setHighlightedDimension(undefined);
                          tooltipRef.current = null;
                        }}
                      >
                      </Bar>
                      {previousData && (
                        <Fragment>
                          <Bar
                            yAxisId="1"
                            dataKey={prevDataKey}
                            stroke="#ffffff"
                            strokeWidth={2}
                            fillOpacity={getBarOpacity(highlightedDimension,prevDataKey) || 0.3}
                            fill={!stack_id ? `url(#color${i})` : color}
                            radius={[4, 4, 0, 0]}
                            animationDuration={300}
                            stackId={0}
                            onMouseEnter={() => {
                              setHighlightedDimension(prevDataKey);
                              tooltipRef.current = key;
                            }}
                            onMouseLeave={() => {
                              setHighlightedDimension(undefined);
                              tooltipRef.current = null;
                            }}
                          />
                          <Bar
                            yAxisId="1"
                            dataKey={prevNegativeDataKey}
                            ref={(ref: any) => props.setEmissionsBarChartRef && props.setEmissionsBarChartRef(ref)}
                            stroke="#ffffff"
                            strokeWidth={2}
                            fillOpacity={getBarOpacity(highlightedDimension,prevNegativeDataKey) || 0.3}
                            fill="#EBC371"
                            radius={[4, 4, 0, 0]}
                            animationDuration={300}
                            stackId={0}
                            onMouseEnter={() => {
                              setHighlightedDimension(prevNegativeDataKey);
                              tooltipRef.current = key;
                            }}
                            onMouseLeave={() => {
                              setHighlightedDimension(undefined);
                              tooltipRef.current = null;
                            }}
                          />
                        </Fragment>
                      )}
                    </Fragment>
                  );
                } else if(chart === 'line' || chart.includes('line-')) {
                  return (
                    <Line
                      yAxisId="1"
                      key={i}
                      type="monotone"
                      dataKey={key}
                      stroke={color}
                      strokeDasharray={chart === 'line-dashed' ? 3 : undefined}
                      animationDuration={300}
                      dot={chart === 'line-dashed' ? false : undefined }
                    />
                  );
                } else if(chart === 'area') {
                  return (
                    <Area
                      yAxisId="1"
                      key={i}
                      type="monotone"
                      dataKey={key}
                      stroke={color || theme.palette.secondary.main}
                      strokeOpacity={opacity(key)}
                      fillOpacity={opacity(key)}
                      fill={`url(#color${i})`}
                      animationDuration={300}
                      stackId={stack_id}
                    />
                  );
                } else {
                  return null;
                }
              })}
              {refAreaLeft && refAreaRight ? (
                <ReferenceArea
                  yAxisId="1"
                  x1={refAreaLeft}
                  x2={refAreaRight}
                  strokeOpacity={0.3}
                />
              ) : null}
              {showLegend && (
                <Legend
                  verticalAlign="top"
                  onClick={onLegendClick}
                  content={(
                    <CustomLegend
                      selectedDimension={selectedDimension}
                      metricsMap={metricsMap}
                    />
                  )}
                />
              )}
              <ReferenceLine yAxisId="1" y={0} opacity={1} stroke="#ccc"/>
              <YAxis
                yAxisId="1"
                orientation="right"
                allowDataOverflow={allowDataOverflow}
                hide={!showAxisY}
                style={{ pointerEvents: 'none' }}
                tick={{ fill: textTertiary, fontSize: 12 }}
                tickFormatter={yAxisLabelFormatter}
                tickLine={false}
                axisLine={false}
                // mirror
                enableBackground="true"
                {...(yAxis ? {
                  //@ts-ignore
                  dataKey: yAxis.key,
                  //@ts-ignore
                  type: yAxis.type as 'number' | 'category',
                } : { })}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <div style={{
            position: 'absolute',
            zIndex: 1000,
            ...(showLegend ? ({
              top: 12,
              right: 12,
            }) : ({
              top: 12,
              right: 12,
            })),
          }}>
            {zoomStart !== null && (
              <MuiTooltip title="Clear Zoom">
                <IconButton color="secondary" onClick={() => zoomOut()} style={{ margin: 10 }}>
                  <ZoomOutMap/>
                </IconButton>
              </MuiTooltip>
            )}
            {selectedDimension !== null && (
              <MuiTooltip title="Clear Filters">
                <IconButton color="primary" onClick={() => setSelectedDimension(null)} style={{ margin: 10 }}>
                  <LayersClear/>
                </IconButton>
              </MuiTooltip>
            )}
          </div>
        </Box>
      )}
    </Fragment>
  );
};
