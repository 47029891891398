import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { CloudIcon } from 'shared/icons/CloudIcon';
import { CircleStackIcon } from 'shared/icons/CircleStackIcon';
import { BoltIcon } from 'shared/icons/BoltIcon';
import { routes } from 'config';
import { LocalizationContext } from 'contexts';


export const NoTransactionsFeedback: React.FC = () => {

  const { dictionary } = React.useContext(LocalizationContext);

  return (
    <Box className="_d-flex jc-center ali-center dir-col md-gap" maxWidth={350} m="auto" textAlign="center">
      <Avatar variant="rounded" color="primary" sx={{ width: 40, height: 40, '& svg': { width: 16, height: 16 } }}>
        <CloudIcon/>
      </Avatar>
      <Typography variant="h4" component="span" fontWeight={400}>
        {dictionary.feedback.noTransactions.title}
      </Typography>
      <Typography variant="body1" component="span" color="text.secondary">
        {dictionary.feedback.noTransactions.text}
      </Typography>
      <Box className="_d-flex jc-center sm-gap" mt={2}>
        <Button color="secondary" component={Link} to={routes.expenses} sx={{ '& svg': { color: 'inherit' } }}>
          <CircleStackIcon/>
          {dictionary.expenses.buttonAddExpenses}
        </Button>
        <Button color="secondary" component={Link} to={routes.activityBasedTransactions} sx={{ '& svg': { color: 'inherit' } }}>
          <BoltIcon/>
          {dictionary.activityInputs.buttonAddActivities}
        </Button>
      </Box>
      <Box width={100} height={2} borderRadius={8} my={2} sx={{ backgroundColor: 'grey.300' }}/>
      <Typography variant="h4" component="span" fontWeight={400}>
        {dictionary.feedback.noTransactions.alreadyAdded}
      </Typography>
      <Typography variant="body1" component="span" color="text.secondary">
        {dictionary.feedback.noTransactions.hint}
      </Typography>
    </Box>
  );
};
