import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocalStorage } from 'react-use';
import {
  Card,
  Grid,
  LinearProgress,
} from '@mui/material';
import {
  DataGridPro as DataGrid,
  GridLinkOperator,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { GridFilterModel } from '@mui/x-data-grid';
import { camelCase, isEmpty, noop, snakeCase } from 'lodash';

import { TransactionClassificationDialog } from 'modules/Emissions/components/TransactionClassification/TransactionClassificationDialog';
import { PermissionContext } from 'contexts';

import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import {
  CustomFilter,
  EmissionClassificationConfidenceStatus,
  EmissionClassificationStatus,
  GetTransactionsParams,
} from 'clients/transactions/transactionClient.types';
import { useSortModel } from 'shared/hooks/DataGrid/useSortModel';
import { useTransactions } from 'shared/hooks/useTransactions';
import { useMeasurementTransactionsColumns } from 'shared/hooks/useMeasurementTransactionsColumns';
import { useExportTransactions } from 'shared/hooks/useExportTransactions';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import TableCheckBox from 'shared/components/interactive/Checkbox/Checkbox';
import { Filters } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { CustomToolbars } from './partials/CustomToolbar';


interface Props {
  filters: Filters;
}

export const TransactionsList: FC<Props> = ({ filters }) => {

  const getFilterModelForClassificationFilter = useCallback((value: EmissionClassificationStatus) => {
    if (value === EmissionClassificationStatus.ALL || !value) {
      return null;
    } else {
      return {
        column: 'emission_factor',
        operator: value === EmissionClassificationStatus.CLASSIFIED ? 'isNotEmpty' : 'isEmpty',
        value: undefined,
      };
    }
  }, []);

  const [customFilters, setCustomFilters] = useState<CustomFilter[]>([]);
  const [sortModel, setSortModel] = useSortModel(filters.setOrdering);
  const [classificationStatus, setClassificationStatus] = useState<string | null>(EmissionClassificationStatus.ALL);
  const [page, setPage, removePage] = useLocalStorage('transactions-page', 0);
  const [pageSize, setPageSize, removePageSize] = useLocalStorage('transactions-pageSize', 20);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const [advancedMenuOpen, setAdvancedMenuOpen] = useState(false);
  const [newSearch, currSearch, setSearch] = useDebouncedQueryState('search');
  const apiRef = useGridApiRef();

  useEffect(() => {
    return () => {
      removePage();
      removePageSize();
    };
  }, [removePageSize, removePage]);

  const allFilters: GetTransactionsParams = useMemo(() => {
    return {
      search: currSearch,
      dateGte: filters.gteDate,
      dateLte: filters.lteDate,
      status: filters.status,
      ordering: filters.ordering,
      confidence: filters.confidence,
      scope: filters.scope,
      tags: filters.tags,
      page,
      pageSize,
      filters: customFilters.filter(customFilter =>
        !!customFilter.column &&
          !!customFilter.operator &&
          (
            customFilter.operator === 'isEmpty' ||
              customFilter.operator === 'isNotEmpty' ||
              !!customFilter.value
          ) &&
          (
            customFilter.operator !== 'isAnyOf' ||
              (!!customFilter.value && customFilter.value.length > 0)
          ),
      ),
      ...(filters?.isParent ? { categoryId: filters.emissionFactorCategory?.id } : { subCategoryId: filters.emissionFactorCategory?.id }),
    };
  }, [filters, customFilters, page, pageSize, currSearch]);

  const { isLoadingExportingTransaction, exportButtonClickHandler } = useExportTransactions(allFilters);
  const {
    transactions,
    status,
    count,
    selected,
    setSelected,
    showSelectAll,
    isWholeDatasetSelected,
    setIsWholeDatasetSelected,
  } = useTransactions(allFilters);

  const [isClassifying, setIsClassifying] = useState(false);
  const { getPermission } = useContext(PermissionContext);
  const columns = useMeasurementTransactionsColumns(apiRef);
  const { dictionary } = useContext(LocalizationContext);

  const hasEditPermission = useMemo(() => getPermission('transaction.crud.edit'), [getPermission]);

  const onClassifyCompleted = useCallback(async () => {
    setSelected([]);
    setIsClassifying(false);
  }, [setSelected]);

  useEffect(() => {
    if (isClassifying && isEmpty(transactions) && filters.emissionFactorCategory) {
      filters.setEmissionFactorCategory(undefined);
    }
  }, [filters, isClassifying, transactions]);

  const filterByColumn = (filter: CustomFilter) => filter.column === 'emission_factor';
  const filterByOperator = (filter: CustomFilter) => filter.operator === 'isNotEmpty' || filter.operator === 'isEmpty';

  const getEmissionFactorFilterForList = useCallback((list: Array<CustomFilter>) => {
    return list.filter(filterByColumn).filter(filterByOperator).pop();
  }, []);

  const getEmissionFactorIndexFilterForList = useCallback((list: Array<CustomFilter>) => {
    return list.findIndex(filter => filterByColumn(filter) && filterByOperator(filter));
  }, []);

  const onCustomFilterChange = useCallback((filterModel: GridFilterModel) => {
    const mapped: CustomFilter[] = filterModel.items
      .filter(filterModel =>
        !!filterModel.columnField &&
            !!filterModel.operatorValue,
      )
      .map(filterModel => {
        const column = filterModel.columnField!;
        const operator = filterModel.operatorValue!;
        const value = filterModel.value;
        return {
          column: snakeCase(column),
          operator: operator,
          value: operator === 'isAnyOf' ? value?.join('|') : value,
        };
      });
    setCustomFilters(mapped);
    const emissionFactorFilter = getEmissionFactorFilterForList(mapped);
    if (emissionFactorFilter) {
      if (emissionFactorFilter.operator === 'isNotEmpty') {
        setClassificationStatus(EmissionClassificationStatus.CLASSIFIED);
      } else if (emissionFactorFilter.operator === 'isEmpty') {
        setClassificationStatus(EmissionClassificationStatus.NON_CLASSIFIED);
      } else {
        setClassificationStatus(EmissionClassificationStatus.ALL);
      }
    } else {
      setClassificationStatus(EmissionClassificationStatus.ALL);
    }
  }, [getEmissionFactorFilterForList]);

  const handleCustomFiltersChange = useCallback((classificationFilterStatus: EmissionClassificationStatus) => {
    const filterForClassification = getFilterModelForClassificationFilter(classificationFilterStatus);
    setCustomFilters(prev => {
      let newList = [...prev];
      let findIndex = getEmissionFactorIndexFilterForList(newList);
      if (filterForClassification) {
        if (findIndex >= 0) {
          let find = newList[findIndex];
          find.column = filterForClassification.column;
          find.operator = filterForClassification.operator;
          find.value = filterForClassification.value;
        } else {
          newList.push(filterForClassification);
        }
      } else {
        if (findIndex >= 0) {
          newList.splice(findIndex, 1);
        }
      }
      return newList;
    });
  }, [getEmissionFactorIndexFilterForList, getFilterModelForClassificationFilter]);

  const onClassificationStatusChanged = useCallback( async (value: string) => {
    setClassificationStatus(value);
    let classificationFilterStatus;
    if ((Object as any).values(EmissionClassificationConfidenceStatus).includes(value)) {
      filters.setConfidence(value);
      classificationFilterStatus = EmissionClassificationStatus.CLASSIFIED;
    } else {
      filters.setConfidence(null);
      classificationFilterStatus = EmissionClassificationStatus[((Object as any).keys(EmissionClassificationStatus)[(Object as any).values(EmissionClassificationStatus).indexOf(value)]) as keyof typeof EmissionClassificationStatus];
    }
    handleCustomFiltersChange(classificationFilterStatus);
  },[filters, handleCustomFiltersChange]);

  useEffect(() => {
    if (filters.confidence) {
      setClassificationStatus(EmissionClassificationStatus.CLASSIFIED);
      handleCustomFiltersChange(EmissionClassificationStatus.CLASSIFIED);
    }
  }, [filters, handleCustomFiltersChange]);

  useEffect(() => {
    setPage(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFilters]);

  const filterModel = useMemo<GridFilterModel>(() => {
    return {
      items: customFilters.map((customFilter, index) => {
        return {
          id: index,
          columnField: camelCase(customFilter.column),
          value: customFilter.operator === 'isAnyOf' ? customFilter.value?.split('|') : customFilter.value,
          operatorValue: customFilter.operator,
        };
      }),
    };
  }, [customFilters]);


  const advancedPanelRef = useRef<any>({
    ledger: {
      isSelected: false,
      page: 0,
    },
    vendorName: {
      isSelected: false,
      page: 0,
    },
    word: {
      isSelected: false,
      page: 0,
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12} mb={0}>
          <Card
            sx={{
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .gc--custom-cell': {
                backgroundColor: '#FAFAFA',
              },
            }}>
            <DataGrid
              loading={status === 'loading'}
              autoHeight
              checkboxSelection={hasEditPermission}
              disableSelectionOnClick={!hasEditPermission}
              rows={transactions}
              columns={columns}
              density="compact"
              apiRef={apiRef}
              rowsPerPageOptions={[10, 20, 50, 100, 1000]}
              page={page}
              pagination
              paginationMode="server"
              pageSize={pageSize}
              onPageSizeChange={pageSize => setPageSize(pageSize)}
              filterMode="server"
              filterModel={filterModel}
              onFilterModelChange={onCustomFilterChange}
              sortModel={sortModel}
              sortingMode="server"
              rowHeight={59}
              headerHeight={52}
              onSortModelChange={setSortModel}
              rowCount={count}
              onPageChange={page => setPage(page)}
              selectionModel={selected}
              localeText={{
                footerRowSelected: (defaultCount) => dictionary.dataGrid.footer.rowSelected(isWholeDatasetSelected ? count : defaultCount),
              }}
              components={{
                Toolbar: CustomToolbars,
                LoadingOverlay: LinearProgress,
                BaseCheckbox: TableCheckBox,
              }}
              componentsProps={{
                panel: {
                  anchorEl: filterButtonEl,
                },
                toolbar: {
                  setFilterButtonEl,
                  advancedMenuOpen,
                  allFilters,
                  advancedPanelRef,
                  setCustomFilters,
                  classificationStatus,
                  selected,
                  showSelectAll,
                  count,
                  isWholeDatasetSelected,
                  setIsWholeDatasetSelected,
                  onClassificationStatusChanged,
                  filters: {
                    ...filters,
                    setSearch,
                  },
                  isLoadingExportingTransaction,
                  exportButtonClickHandler,
                  setAdvancedMenuOpen,
                  newSearch,
                  currSearch,
                  setSearch,
                },
                filterPanel: {
                  // Force to use "And" operator
                  linkOperators: [GridLinkOperator.And],
                  // Display columns by ascending alphabetical order
                  columnsSort: 'asc',
                  filterFormProps: {
                    // Customize inputs by passing props
                    linkOperatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                    },
                    columnInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: { mt: 'auto' },
                    },
                    operatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: { mt: 'auto' },
                    },
                  },
                  sx: {
                    // Customize inputs using css selectors
                    p: 2,
                    '& .MuiDataGrid-filterForm': { px: 0 },
                    '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 1 },
                    '& .MuiDataGrid-filterFormColumnInput': { mr: 1, width: 150 },
                    '& .MuiDataGrid-filterFormOperatorInput': { mr: 1 },
                    '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                  },
                },
              }}
              onSelectionModelChange={(selected) => setSelected(selected as number[])}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    category: false,
                    time: false,
                    status: false,
                    kgCo2EMultiplier: false,
                    integrationName: false,
                  },
                },
              }}
            />
          </Card>
        </Grid>
        <TransactionClassificationDialog
          open={selected.length > 0}
          onClose={noop}
          options={{
            isFullSelection: isWholeDatasetSelected,
            transactionCount: isWholeDatasetSelected ? count : selected.length,
            transactions: transactions.filter(t => selected.includes(t.id)),
            filters: allFilters,
            onClassifyStart: () => setIsClassifying(true),
            onClassifyCompleted,
          }}
        />
      </Grid>
    </Grid>
  );
};
