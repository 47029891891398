import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { CheckIcon } from 'shared/icons/CheckIcon';


export const Steps = (props: StepsProps) => {
  const { stepItems, containerProps = {} } = props;

  return (
    <Box
      {...containerProps}
      className={`steps-container ${containerProps.className}`}
    >
      {stepItems.map(({ title, image, isCompleted }, i) => (
        <React.Fragment key={i}>
          <Box className={`steps-item ${isCompleted ? 'is-completed' : ''}`}>
            {isCompleted ? (
              <CheckIcon/>
            ) : (
              typeof image === 'string' ? (<img src={image} alt={title}/>) : (image)
            )}
            <Typography variant="h6" color="text.primary">{title}</Typography>
          </Box>
          {i < stepItems.length - 1 && <div className="steps-bar"/>}
        </React.Fragment>
      ))}
    </Box>
  );
};

interface StepsProps {
    stepItems: StepItem[];
    containerProps?: React.ComponentProps<typeof Box>;
}

interface StepItem {
    title: string;
    image?: string | ReactElement;
    isCompleted: boolean;
}