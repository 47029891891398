import { FC } from 'react';

import { getIconPath } from 'shared/components/styledMui/styledIcons/getIconPath';
import CustomSvgIcon from 'shared/components/styledMui/styledIcons/CustomSvgIcon';
import DocumentArrowUpIcon from 'shared/components/layout/Icons/DocumentArrowUpIcon';
import ExclamationTriangleIcon from 'shared/components/layout/Icons/ExclamationTriangleIcon';


export interface IDropZoneIconProps {
  variant?: 'dragAccepted' | 'drag' | 'dragExtensionRejected' | 'dragSizeRejected' | 'dragDropped';
}

const sharedSx = {
  color: 'inherit',
  transform: 'rotate(0deg)',
  fontSize: '36px',
};

const svgProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  fill: 'none',
  viewBox: '0 0 24 24',
  strokeWidth: 1.5,
  stroke: 'white',
};

const DropZoneIcon: FC<IDropZoneIconProps> = ({
  variant,
}) => {

  const customSvgVariant = (variant === 'dragAccepted' || variant === 'dragDropped' ) ? variant : undefined;

  const CheckIcon = (
    <CustomSvgIcon variant={customSvgVariant} {...svgProps} sx={sharedSx}>
      {getIconPath('DocumentCheck')}
    </CustomSvgIcon>
  );

  const variants = {
    dragAccepted: CheckIcon,
    dragExtensionRejected: <ExclamationTriangleIcon sx={sharedSx}/>,
    dragSizeRejected: <ExclamationTriangleIcon sx={sharedSx}/>,
    drag: <DocumentArrowUpIcon sx={{ ...sharedSx, color: 'var(--icon-grey)' }}/>,
    dragDropped: CheckIcon,
  };

  return variants[variant as keyof typeof variants];
};

export default DropZoneIcon;
