import React, {
  FC,
  createContext,
  useCallback,
  PropsWithChildren,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';
import { uniq } from 'lodash';
import { cacheKeys, routes } from 'config';
import { ContextProps, AsyncProcess, AsyncProcessType, AsyncProcessCsvUpload, AsyncProcessImportingTransactions } from 'contexts/WaitingAsyncProcessContext/types';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { Authenticated } from 'shared/components/Authenticated/Authenticated';
import { ProcessSnackbarProps, ProcessSnackbarType } from 'shared/components/Snackbar/ProcessSnackbar';
import { ProcessSnackbars } from 'shared/components/Snackbar/ProcessSnackbars';
import { checkImportingTransactionsProcess, checkUploadCsvProcess } from './checkProcessHelper';


const CheckProcessTime = 1000;

export const defaultContext: ContextProps = {
  addAsyncProcess: () => {},
  removeAsyncProcess: () => {},
  setIsOpenInDialog: () => {},
  asyncProcesses: [],
};

const getSnackbarType = (processType: AsyncProcessType): ProcessSnackbarType => {
  switch (processType) {
  case AsyncProcessType.CsvUpload:
    return 'uploading';
  case AsyncProcessType.ImportingTransactions:
    return 'importing';
  default:
    return 'info';
  }
};

export const WaitingAsyncProcessContext = createContext(defaultContext);
export const WaitingAsyncProcessContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { dictionary } = React.useContext(LocalizationContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [asyncProcesses, setAsyncProcesses] = useState<AsyncProcess[]>([]);
  const [blocked, setBlocked] = useState<AsyncProcessType[]>([]);
  const [displayAsSnackbarList, setDisplayAsSnackbarList] = useState<AsyncProcessType[]>([]);

  const addAsyncProcess = useCallback((process: Omit<AsyncProcess, 'isActive' | 'snackbarType'>) => {
    setAsyncProcesses([
      ...asyncProcesses.filter((p) => p.processType !== process.processType),
      {
        ...process,
        isActive: true,
        snackbarType: getSnackbarType(process.processType),
        timesChecked: 0,
      },
    ]);
  }, [asyncProcesses]);

  const removeAsyncProcess = useCallback((processType: AsyncProcessType) => {
    setAsyncProcesses(asyncProcesses.filter((p) => p.processType !== processType));
  }, [asyncProcesses]);

  const setIsOpenInDialog = useCallback((processType: AsyncProcessType, isOpenInDialog: boolean) => {
    setAsyncProcesses(asyncProcesses.map((p) => {
      if (p.processType === processType) {
        return { ...p, isOpenInDialog };
      }

      return p;
    }));

    setDisplayAsSnackbarList(asyncProcesses.filter((p) => p.processType !== processType || !isOpenInDialog).map((p) => p.processType));
  }, [asyncProcesses]);

  const checkProcesses = useCallback(async () => {
    const newAsyncProcesses: AsyncProcess[] = [ ...asyncProcesses ];
    const isBlocked = (type: AsyncProcessType) => blocked.some((b) => b === type);
    const addToBlocked = (type: AsyncProcessType) => setBlocked(uniq([ ...blocked, type ]));
    const deleteFromBlocked = (type: AsyncProcessType) => setBlocked(blocked.filter((b) => b !== type));
    const checkForRemoveOpenInDialog = (type: AsyncProcessType, newProcess: AsyncProcess) => {
      if (displayAsSnackbarList.includes(type)) {
        Object.assign(newProcess, { isOpenInDialog: false });
      }
    };
    Promise.all(asyncProcesses.map(async (process, i) => {
      if (!process.isActive) {
        return;
      }

      if (process.processType === AsyncProcessType.CsvUpload) {
        if (isBlocked(AsyncProcessType.CsvUpload)) {
          return;
        }

        addToBlocked(AsyncProcessType.CsvUpload);
        const newProcess = await checkUploadCsvProcess(
          process as AsyncProcessCsvUpload,
          {
            actionName: dictionary.review,
            actionOnClick: () => {
              navigate(routes.linkedAccounting);
              setIsOpenInDialog(AsyncProcessType.CsvUpload, true);
            },
          },
        );
        deleteFromBlocked(AsyncProcessType.CsvUpload);
        if (!newProcess) {
          newAsyncProcesses.splice(i, 1);
          return;
        }

        checkForRemoveOpenInDialog(AsyncProcessType.CsvUpload, newProcess);
        if (!newProcess.isActive) {
          queryClient.invalidateQueries(cacheKeys.integrations.getIntegrations);
        }
        newAsyncProcesses.splice(i, 1, newProcess);
      }

      else if (process.processType === AsyncProcessType.ImportingTransactions) {
        if (isBlocked(AsyncProcessType.ImportingTransactions)) {
          return;
        }

        addToBlocked(AsyncProcessType.ImportingTransactions);
        const newProcess = await checkImportingTransactionsProcess(
          process as AsyncProcessImportingTransactions,
          {
            actionName: dictionary.review,
            actionOnClick: () => {
              navigate(routes.linkedAccounting);
              setIsOpenInDialog(AsyncProcessType.ImportingTransactions, true);
            },
          },
        );
        deleteFromBlocked(AsyncProcessType.ImportingTransactions);
        if (!newProcess) {
          newAsyncProcesses.splice(i, 1);
          return;
        }

        checkForRemoveOpenInDialog(AsyncProcessType.ImportingTransactions, newProcess);
        if (!newProcess.isActive) {
          queryClient.invalidateQueries(cacheKeys.integrations.getIntegrations);
        }
        newAsyncProcesses.splice(i, 1, newProcess);
      }
    })).then(() => {
      setAsyncProcesses(newAsyncProcesses);
    });
  }, [asyncProcesses, blocked, dictionary, displayAsSnackbarList, navigate, queryClient, setIsOpenInDialog]);

  useEffect(() => {
    if (!asyncProcesses.some((p) => p.isActive )) {
      return;
    }

    const checkProcessesInterval = setInterval(checkProcesses, CheckProcessTime);
    return () => clearInterval(checkProcessesInterval);
  }, [asyncProcesses, checkProcesses]);

  const snackbars = useMemo<ProcessSnackbarProps[]>(() => {
    return asyncProcesses
      .filter((process) => !process.isOpenInDialog || displayAsSnackbarList.includes(process.processType))
      .map((process) => {
        const {
          name,
          progress,
          snackbarType,
          snackbarAction,
          processType,
          isActive,
          data: {
            isInactive,
            integrationName,
          },
        } = process;

        return {
          type: snackbarType,
          message: name,
          status: dictionary.snackbar.statusText(snackbarType, processType, progress, isInactive),
          progress: progress,
          onClose: () => removeAsyncProcess(processType),
          integrationName: integrationName,
          isActive: isActive,
          ...snackbarAction,
        };
      });
  }, [asyncProcesses, displayAsSnackbarList, dictionary, removeAsyncProcess]);

  return (
    <WaitingAsyncProcessContext.Provider
      value={{ addAsyncProcess, removeAsyncProcess, setIsOpenInDialog, asyncProcesses }}
    >
      {children}
      <Authenticated>
        <ProcessSnackbars snackbars={snackbars}/>
      </Authenticated>
    </WaitingAsyncProcessContext.Provider>
  );
};
