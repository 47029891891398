import React from 'react';
import { IntegrationIcon } from 'modules/Expenses/components/FileOrIntegrationName';
import { ExclamationCircleIcon } from 'shared/icons/ExclamationCircleIcon';
import { InfoCircleIcon } from 'shared/icons/InfoCircleIcon';
import { CheckCircleIcon } from 'shared/icons/CheckCircleIcon';


export const StatusIcon = (props: StatusIconProps) => {
  const { type, integrationName } = props;

  switch (type) {
  case 'info':
    return <InfoCircleIcon sx={{ height: 24, width: 24 }}/>;
  case 'success':
    return <CheckCircleIcon sx={{ color: 'primary.main', height: 24, width: 24 }}/>;
  case 'error':
    return <ExclamationCircleIcon sx={{ color: 'error.main', height: 24, width: 24 }}/>;
  case 'warning':
    return <ExclamationCircleIcon sx={{ color: 'warning.main', height: 24, width: 24 }}/>;
  case 'uploading':
  case 'importing':
    return <IntegrationIcon fileName={integrationName}/>;
  default:
    return null;
  }
};

export interface StatusIconProps {
    type: 'info' | 'success' | 'error' | 'uploading' | 'warning' | 'importing';
    integrationName?: string;
}