import React, { forwardRef, useContext } from 'react';
import { AlertTitle, Alert as AlertMui, Typography } from '@mui/material';
import { SnackbarContent } from 'notistack';

import { LocalizationContext } from 'contexts';

import { ExtendedINotistackAlertProps } from './notistack.types';


const NotistackAlert = forwardRef<HTMLDivElement, ExtendedINotistackAlertProps>(({ severity, messagelist, message, alertTitle }, ref) => {

  const { dictionary } = useContext(LocalizationContext);
  const title = alertTitle || dictionary.feedback[severity];

  return (
    <SnackbarContent ref={ref}>
      {messagelist && (
        <AlertMui
          sx={{
            width: 300,
            '& .MuiSvgIcon-root': {
              color: 'inherit',
            },
          }}
          severity={severity}
        >
          <AlertTitle>{title}</AlertTitle>
          {messagelist.map((message) => (
            <Typography key={message}>{message}</Typography>
          ))}
        </AlertMui>
      )}

      {message && (
        <AlertMui
          sx={{
            width: 300,
            '& .MuiSvgIcon-root': {
              color: 'inherit',
            },
          }}
          severity={severity}
        ><Typography>{message}</Typography>
        </AlertMui>
      )}
    </SnackbarContent>
  );
},
);

export default NotistackAlert;
