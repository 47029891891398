import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StyledEngineProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';

import { ReactQueryDevtools } from 'react-query/devtools';
import ThemeComponent from 'shared/styles/theme/ThemeComponent';
import * as Sentry from '@sentry/react';
import { LayoutContextProvider,
  UserContextProvider,
  CompanyContextProvider,
  LocalizationContextProvider,
  LocalizationContext,
  LayoutContext,
  DialogContextProvider,
  PermissionContextProvider,
  WebsocketContextProvider,
} from 'contexts';
import { WaitingAsyncProcessContextProvider } from 'contexts';
import { DrawerContextProvider } from 'contexts/DrawerContext/DrawerContext';
import NotistackProvider from 'shared/components/layout/Feedback/Notistack/NotistackProvider';
import { Root } from 'views/Root';
import './tracking/googleTagManager';


if (!!process.env.REACT_APP_GREENCAST_SENTRY_KEY) {
  Sentry.init({
    dsn: process.env.REACT_APP_GREENCAST_SENTRY_KEY,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/^https:\/\/gc-api-dot-greencast-prod\.ey\.r\.appspot\.com\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const muiProLicense = process.env.REACT_APP_GREENCAST_MUI_PRO_LICENCE_KEY;

if (muiProLicense) {
  LicenseInfo.setLicenseKey(muiProLicense);
}

const AppWithTheme = () => {

  const { dateLocale } = useContext(LocalizationContext);
  const { mode } = useContext(LayoutContext);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeComponent mode={mode} locale={dateLocale === 'de' ? 'deDE' : 'enUS'}>
        <NotistackProvider>
          <DialogContextProvider>
            <DrawerContextProvider>
              <WaitingAsyncProcessContextProvider>
                <WebsocketContextProvider>
                  <Root/>
                </WebsocketContextProvider>
              </WaitingAsyncProcessContextProvider>
            </DrawerContextProvider>
          </DialogContextProvider>
        </NotistackProvider>
      </ThemeComponent>
    </StyledEngineProvider>
  );
};

const App = () => {

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <PermissionContextProvider>
          <UserContextProvider>
            <LocalizationContextProvider>
              <CompanyContextProvider>
                <LayoutContextProvider>
                  <AppWithTheme/>
                  <ReactQueryDevtools/>
                </LayoutContextProvider>
              </CompanyContextProvider>
            </LocalizationContextProvider>
          </UserContextProvider>
        </PermissionContextProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
