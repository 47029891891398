import { useCallback, useContext } from 'react';
import { trackLogIn } from 'tracking/tracking';
import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';


export const useSignin = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { login: userLogin, ssoLogin, logout } = useContext(UserContext);
  const { genericError, validationError } = useContext(LayoutContext);


  const signIn = useCallback(async (email: string, password: string, userInvitationId?: string) => {
    const result = await userLogin(email, password);
    if (result.success !== true) {
      return Promise.reject({ type: 'error', message: result.message });
    } else {
      try {
        trackLogIn();
        if (userInvitationId) {
          await usersClient.inviteToWorkspace({ id: userInvitationId });
        }
        return result;
      } catch {
        genericError();
      }
    }
  }, [userLogin, genericError]);

  const signInWithSSO = useCallback(async (provider: string, userInvitationId?: string) => {
    const result = await ssoLogin(provider);
    if(result.success !== true) {
      return Promise.reject({ type: 'error', message: result.message });
    } else {
      try {
        await usersClient.signinWithSSO(userInvitationId);
        trackLogIn();
      } catch (e: any) {
        if (e.response?.status === 400) {
          validationError(dictionary.userNotFound);
          logout();
        } else {
          genericError();
          logout();
        }
      }
    }
  }, [ssoLogin, logout, dictionary, genericError, validationError]);

  return {
    signIn,
    signInWithSSO,
  };
};
