import { FunctionComponent, MutableRefObject } from 'react';
import Checkbox from '@mui/material/Checkbox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

import { primary } from 'shared/styles/muiTheme';
import { useSelectAllProps } from 'shared/hooks/useSelectAllProps';
import CheckIcon from 'shared/components/layout/Icons/CheckIcon';

import { SquareBase } from './SquareBase';


interface ISelectAllTableCheckBoxProps {
  apiRef: MutableRefObject<GridApiPro>;
}

const SelectAllTableCheckBox: FunctionComponent<
  ISelectAllTableCheckBoxProps
> = (props) => {
  const { checkBoxState, clickHandler } = useSelectAllProps({
    apiRef: props.apiRef,
  });

  return (
    <Checkbox
      icon={<SquareBase/>}
      checkedIcon={
        <SquareBase selected>
          <CheckIcon strokeWidth={2} sx={{ color: primary, fontSize: 17 }}/>
        </SquareBase>
      }
      indeterminateIcon={
        <IndeterminateCheckBoxIcon
          sx={{ color: primary, height: 25, width: 25 }}
          stroke="primary"
        />
      }
      checked={checkBoxState === 'allCheckBoxesSelected'}
      indeterminate={checkBoxState === 'fewCheckBoxesSelected'}
      onClick={clickHandler}
    />
  );
};

export default SelectAllTableCheckBox;
