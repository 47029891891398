import { useContext } from 'react';
import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { UserContext } from 'contexts';
import { GetEmissionFactorCategoriesParams } from 'clients/emissionFactors/emissionFactorsClient.types';
import { emissionFactorsClient } from 'clients/emissionFactors/emissionFactorsClient';


type Options = {
  enabled: boolean,
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useEmissionFactorCategories = (params: GetEmissionFactorCategoriesParams, options: Partial<Options> = defaultOptions) => {

  const { isLoggedIn, isNoCompany, loading } = useContext(UserContext);

  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.emissionFactorCategories.getEmissionFactorCategories, params],
    () => emissionFactorsClient.getEmissionFactorCategories(params),
    {
      enabled: isLoggedIn && options.enabled && !isNoCompany && !loading,
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    emissionFactorCategories: data?.results || [],
  };
};
