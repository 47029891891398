// ** MUI Imports
import { Theme } from '@mui/material/styles';


const GlobalStyles = (theme: Theme) => {
  return {

  };
};

export default GlobalStyles;
