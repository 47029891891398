import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';
import { ChartData, ChartDataEnum, MappedComparissonData } from './MultiAreaChart.types';


export const getDistinctYearsCount = (data: ChartData[]): number => {
  const years = new Set();
  data.forEach((date) => {
    if (!date[ChartDataEnum.dateMonth]) {
      return;
    }
    const [year] = date[ChartDataEnum.dateMonth].split('-');
    years.add(year);
  });
  return years.size;
};

export const mapData = ({
  values,
  aggregateBy,
  metrics,
  selectedDimension = null,
  zoomStart = null,
  zoomEnd = null,
}: any) => {
  let _values = values.map((value: any) => value);
  if (aggregateBy) {
    const aggregateValueKeys: any = [
      ...new Set(values.map((value: any) => value.dimensions[aggregateBy.key])),
    ].reduce((obj: any, key: any) => {
      obj[key] = {};
      return obj;
    }, {});
    values.forEach((value: any) => {
      // @ts-ignore
      aggregateValueKeys[value.dimensions[aggregateBy.key]][
        value.dimensions[aggregateBy.aggregation.key]
      ] = value.metrics[metrics[0].key];
    });
    _values = Object.keys(aggregateValueKeys).map((key: string) => ({
      dimensions: {
        [aggregateBy.key]: key,
      },
      metrics: aggregateValueKeys[key],
    }));
  }
  const data = _values.map(({ metrics, dimensions }: any) => ({
    ...Object.keys(metrics).reduce((filteredMetrics, key) => {
      // TODO: Handle String Integers
      // eslint-disable-next-line
      if (selectedDimension === null || selectedDimension == key) {
        filteredMetrics[key] = metrics[key];
      }
      return filteredMetrics;
    }, {} as any),
    ...dimensions,
  }));
  if (zoomStart !== null && zoomEnd !== null) {
    return data.slice(zoomStart, zoomEnd);
  }
  return data;
};

export const mapComparissonData = (allCurrentData: any, mappedData: any, prevYearData: any, aggregateBy: any, mappedNegativeValues: any, mappedPrevYearNegativeValues: any): MappedComparissonData[] => {
  const output: MappedComparissonData[] = [];
  let currentYearObj: any = {};
  let year: string;
  const aggregateByKey = aggregateBy?.key;


  allCurrentData.forEach((obj: any, index: number) => {
    let negativeValuesObj: any = {};
    let prevYearNegativeValuesObj: any = {};

    if (obj.date_month || true) {
      const currentDate = obj[aggregateByKey];
      year = obj[aggregateByKey].slice(0, 4);
      currentYearObj = {};

      const currentYearValueObj = mappedData?.find(
        (d: any) => d[aggregateByKey] === currentDate,
      );

      if (currentYearValueObj) {
        Object.entries(currentYearValueObj).forEach(([key, value]) => {
          if (key !== ChartDataEnum.dateMonth) {
            currentYearObj[key] = value;
          }
        });
      }

      const currentYearNegativeValueObj = mappedNegativeValues?.find(
        (d: any) => d[aggregateByKey] === currentDate,
      );

      if (mappedNegativeValues && currentYearNegativeValueObj) {
        negativeValuesObj[Scope.Offset] = currentYearNegativeValueObj[Scope.Offset];
      }

      const prevYearNegativeValueObj = mappedPrevYearNegativeValues?.find(
        (d: any) => d[aggregateByKey] === currentDate,
      );

      if (mappedPrevYearNegativeValues && prevYearNegativeValueObj) {
        prevYearNegativeValuesObj[Scope.Offset] = prevYearNegativeValueObj[Scope.Offset];;
      }

      const prevYearObj: any = {};
      let prevYearDate = `${parseInt(year, 10) - 1}${obj[aggregateByKey].slice(
        4,
      )}`;

      const prevYearValueObj = prevYearData?.find(
        (d: any) => d[aggregateByKey] === prevYearDate,
      );

      if (prevYearValueObj) {
        Object.entries(prevYearValueObj).forEach(([key, value]) => {
          if (prevYearData) {
            prevYearObj[key] = value;
          } else {
            prevYearObj[key] = null;
          }
        });
      }
      const objToAdd: MappedComparissonData = {
        currentYear: currentYearObj,
        negativeValues: negativeValuesObj,
        prevYearNegativeValues: prevYearNegativeValuesObj,
        [aggregateByKey]: obj[aggregateByKey],
        prevDataDate: prevYearDate,
        prevYear: prevYearObj,
      };

      output.push(objToAdd);
    }
  });

  return output;
};

export const getBarOpacity = (highlitedDimension: string | number, currentValue: string | number) => highlitedDimension === currentValue ? 1 : null;