import React from 'react';
import { Box, Typography } from '@mui/material';
import { Expenses } from 'modules/Expenses/expenses';
import { backgroundDark, textContrast } from 'shared/styles/muiTheme';


export const LinkedByCell = (props: LinkedByCellProps) => {
  const {
    integration: {
      linkedByFirstName,
      linkedByLastName,
      linkedByEmail,
    },
  } = props;

  const initials = linkedByFirstName && linkedByLastName && ((linkedByFirstName[0] || '') + (linkedByLastName[0] || '')).toUpperCase();

  return (
    <Box className="_d-flex-sm-gap">
      {initials && (
        <Box
          className="_d-flex-ali-center-jc-center"
          sx={{
            height: 24,
            width: 24,
            fontSize: 8,
            fontWeight: 600,
            background: backgroundDark,
            borderRadius: '50%',
            color: textContrast,
          }}
        >
          {initials}
        </Box>
      )}
      <Typography variant="body1">{linkedByEmail}</Typography>
    </Box>
  );
};

interface LinkedByCellProps {
    integration: Expenses.Integrations.Integration;
}