import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { Filters } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { TransactionsList } from 'views/LoggedIn/Transactions/TransactionsList';


interface TransactionProps {
  filters: Filters;
  title?: string;
}

export const Transactions: React.FunctionComponent<TransactionProps> = (props) => {

  const { filters, title } = props;

  const { dictionary } = useContext(LocalizationContext);

  return (
    <Box>
      <Typography variant="h2">
        { title || dictionary.pageTitles.transactions }
      </Typography>

      <TransactionsList
        filters={filters}
      />
    </Box>
  );
};
