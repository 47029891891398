import React, { FC, createContext, useState, useEffect, useContext } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import enLocale from 'date-fns/locale/en-GB';
import deLocale from 'date-fns/locale/de';

import { UserContext } from 'contexts/UserContext/UserContext';
import { dictionary as en } from './languages/en';
import { dictionary as de } from './languages/de';
import { ContextProps, CurrencyLocale } from './types';


const browserLocale = window?.navigator?.language;

const defaultContext: ContextProps = {
  language: browserLocale.slice(0,2),
  currencyLocale: {
    currency: '',
    locale: '',
    symbol: '',
  },
  dictionary: en,
  setCurrencyLocale: () => null,
  setLanguage: () => null,
  dateLocale: enLocale,
};


const getLanguageFromLocalStorage = (): string => {
  const lang = localStorage.getItem('gc-language') || defaultContext.language;
  if(['de', 'en'].includes(lang)) {
    return lang;
  }
  return 'en';
};

const getCurrencySymbol = (locale: string, currency: string): string => (
  new Intl.NumberFormat(locale, { style: 'currency', currency })
    .formatToParts(0)
    .find(part => part.type === 'currency')
    ?.value || ''
);


export const LocalizationContext = createContext(defaultContext);


export const LocalizationContextProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useContext(UserContext);

  const [language, setLanguage] = useState<string>(getLanguageFromLocalStorage());
  const [currencyLocale, setCurrencyLocale] = useState<CurrencyLocale>(defaultContext.currencyLocale);
  const dictionaries = { en, de };
  const dictionary = dictionaries[language as 'en' | 'de'] || dictionaries.en;

  useEffect(() => {
    localStorage.setItem('gc-language',language);
  },[language]);

  useEffect(() => {
    if (user?.company?.defaultCurrency && user?.company?.defaultLocale) {
      setCurrencyLocale({
        currency: user.company.defaultCurrency,
        locale: user.company.defaultCurrency,
        symbol: getCurrencySymbol(user.company.defaultCurrency, user.company.defaultCurrency),
      });
    }
  }, [user]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <LocalizationContext.Provider
        value={{
          language,
          currencyLocale,
          dictionary,
          setCurrencyLocale,
          setLanguage,
          dateLocale: language === 'en' ? enLocale : deLocale,
        }}
      >
        {children}
      </LocalizationContext.Provider>
    </LocalizationProvider>
  );
};

