import React, { FC, useContext, useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import {
  Autocomplete, Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { cacheKeys } from 'config';
import { LocalizationContext, LayoutContext } from 'contexts';
import { isEmailValid } from 'helpers/validation';
import { usersClient } from 'clients/users/usersClient';
import { useGroups } from 'shared/hooks/useGroups';
import { useMe } from 'shared/hooks/useMe';
import { InformationToolTipContent } from 'shared/components/layout/Tooltip/InformationToolTipContent';

import { getResolvedEmailsStatus, setEnqueuedSnackbar } from './InviteTeamDialogHelpers';
import { EmailStatusDefault, getResolvedEmailsStatusValues, InviteTeamMemberDialogForm, InviteTeamMemberDialogProps } from './InviteTeamMemberDialog.types';


export const InviteTeamMemberDialog: FC<InviteTeamMemberDialogProps> = ({ open, onClose }) => {

  const { currencyLocale: { symbol }, dictionary } = useContext(LocalizationContext);
  const { validationError } = useContext(LayoutContext);
  const { handleSubmit, control, formState: { isSubmitting, errors }, reset } = useForm<InviteTeamMemberDialogForm>({
    defaultValues: {
      emails: [],
      authGroup: {},
    },
  });
  const { groups } = useGroups();
  const filteredGroups = groups.filter(group => group.name !== 'Owner');
  const { user } = useMe();
  const emailsStatusList = useRef<EmailStatusDefault>({ succeeded: [], failed: [] });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const inviteMember = useMutation(usersClient.inviteUser, {
    mutationKey: cacheKeys.users.inviteUser,
    onSuccess: (result, request) => {
      emailsStatusList.current.succeeded.push(request.email);
    },
    onError: (result, request) => {
      emailsStatusList.current.failed.push(request.email);
    },
  });

  useEffect(() => {
    reset({
      emails: [],
    });
  }, [open, reset]);

  const handleInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
    if (newInputValue.endsWith(',') || newInputValue.endsWith(' ')) {
      // @ts-ignore
      event.target.blur();
      // @ts-ignore
      event.target.focus();
    }
  }, []);

  const onSubmit = useCallback(async (data: InviteTeamMemberDialogForm) => {
    const privaledgedGroups = ['Admin', 'Owner'];
    if (!privaledgedGroups.includes(user?.authGroup.name)) {
      return validationError('Admin privileges are needed to perform this action.');
    }
    const authGroup = data.authGroup?.id;
    emailsStatusList.current = { succeeded: [], failed: [] };


    await Promise.allSettled(data.emails.map(email => inviteMember.mutateAsync({ email, authGroup })));
    const resolvedEmailsStatus = getResolvedEmailsStatus(emailsStatusList.current);

    const emailssucceededCount = emailsStatusList.current.succeeded.length;
    const emailsFailedCount = emailsStatusList.current.failed.length;

    closeSnackbar();
    setEnqueuedSnackbar({
      enqueueSnackbar,
      emailsStatusList: emailsStatusList.current,
      resolvedEmailsStatus,
      alert: {
        messages: {
          allEmailsFailed: dictionary.invite.dialog.feedback.allEmailsFailed(emailsFailedCount),
          allEmailSent: dictionary.invite.dialog.feedback.allEmailSent(emailssucceededCount),
          someEmailsSent: dictionary.invite.dialog.feedback.someEmailsSent(emailssucceededCount),
        },
        titles: {
          succeededAndFailedError: dictionary.invite.dialog.feedback.succeededAndFailedError(emailsFailedCount),
        },
      },
    });

    if (resolvedEmailsStatus === getResolvedEmailsStatusValues.succeededAndFailed || resolvedEmailsStatus === getResolvedEmailsStatusValues.allEmailsFailed) return;

    onClose();
  }, [inviteMember, onClose, user, validationError, enqueueSnackbar, dictionary, closeSnackbar]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {dictionary.invite.dialog.title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column">
            <Grid item>
              <Controller
                name="emails"
                control={control}
                rules={{
                  required: dictionary.forms.validations.required,
                  validate: {
                    allEmailsValid: (value: string[]) => {
                      let isValid = true;
                      value.forEach(v => {
                        if (!isEmailValid(v)) {
                          isValid = false;
                        }
                      });
                      return isValid || dictionary.forms.validations.memberInvitationAllEmailsValid;
                    },
                  },
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    multiple
                    freeSolo
                    autoSelect
                    onInputChange={handleInputChange}
                    onBlur={() => {
                    }}
                    //@ts-ignore
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((email, index) => (
                        <Chip
                          deleteIcon={<Close/>}
                          label={email}
                          size="small"
                          color={isEmailValid(email) ? 'default' : 'error'}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(props) =>
                      <TextField
                        {...props}
                        autoFocus
                        size="small"
                        placeholder={dictionary.invite.dialog.placeholderEmail}
                        error={!!errors.emails}
                        helperText={errors.emails?.message}
                      />
                    }
                    onChange={(e, value) => field.onChange(value)}
                    options={[]}
                  />
                )}
              />
            </Grid>

            <Grid item md={12}>
              <Box mt={1}/>
              <Controller
                name="authGroup"
                control={control}
                rules={{
                  required: dictionary.forms.validations.required,
                }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    autoSelect
                    renderInput={(props) =>
                      <TextField
                        {...props}
                        size="small"
                        placeholder={dictionary.team.inviteDialogPlaceholder}
                        error={!!errors.authGroup}
                        helperText={errors.authGroup?.message}
                      />
                    }
                    onChange={(e, value) => field.onChange(value)}
                    options={filteredGroups}
                    value={field.value || null}
                    getOptionLabel={(data) => data?.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                )}
              />
            </Grid>
            <InformationToolTipContent
              customComponentBefore={
                <Typography variant="body2">
                  {dictionary.team.roleInfoLabel}
                </Typography>
              }
              infoStyleIconProps={{ ml: '4px' }}
              containerProps={{ marginTop: '16px' }}
              tooltipTitle={dictionary.tooltips.teamRoles(symbol)}
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button type="button" variant="text" onClick={onClose}>{dictionary.dialogs.buttonCancel}</Button>
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            variant="contained">{dictionary.invite.dialog.buttonInvite}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};