import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { useInvitation } from 'shared/hooks/useInvitation';
import { CoolsetPageLoader } from 'shared/components/layout/CoolsetLoader/CoolsetLoader';
import { SignUp } from './SignUp';
import { Login } from './Login';


export const Invite = () => {

  const { token } = useParams();
  const { genericError } = useContext(LayoutContext);

  const { status, invite, isLoading } = useInvitation({ id: token || '' }, {
    enabled: !!token,
  });

  useEffect(() => {
    if (status === 'error') {
      genericError();
    }
  },[status, genericError]);

  const Component = invite?.isExistingUser ? Login : SignUp;

  return (
    <>
      {isLoading
        ? <CoolsetPageLoader/>
        : <Component invite={invite} token={token}/>
      }
    </>
  );
};
