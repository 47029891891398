import { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { Alert, Box, Button, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { ResponsiveContainer } from 'recharts';

import { ClockIcon } from 'shared/icons/ClockIcon';
import { CongratsIcon } from 'shared/icons/CongratsIcon';
import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext, PermissionContext, UserContext } from 'contexts';
import { getCurrentYear } from 'helpers/date';
import { scenarioBuilderClient } from 'clients/scenarioBuilder/scenarioBuilderClient';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { useScenarioBuilderData } from 'shared/hooks/ScenarioBuilder/useScenarioBuilderData';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { MessageFeedback } from 'shared/components/layout/Feedback/MessageFeedback';
import { ChartSection } from 'shared/components/layout/ChartSection';

import { ProjectionTable } from './Components/ProjectionTable/ProjectionTable';
import { ScenarioBuilderForm } from './Components/ScenarioBuilderForm';
import { ReductionStrategyChart } from './Components/ReductionStrategyChart';


export const ScenarioBuilder = () => {
  const breadcrumbs = useBreadcrumbs('scenarioBuilder');
  const { dictionary } = useContext(LocalizationContext);
  const { emissionFactorCategoriesColors } = useContext(LayoutContext);
  const { genericError } = useContext(LayoutContext);
  const { user } = useContext(UserContext);
  const { getPermission } = useContext(PermissionContext);
  const queryClient = useQueryClient();
  const [highlightedCategory, setHighlightedCategory] = useState<string>();

  const { table, chart: barChartData, status } = useScenarioBuilderData({ enabled: !!user?.company?.name });

  const resetData = useMutation(scenarioBuilderClient.resetData, {
    mutationKey: cacheKeys.scenarioBuilder.resetData,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.scenarioBuilder.getScenarioBuilderData);
    },
    onError: () => {
      genericError();
    },
  });

  const onReset = useCallback(async () => {
    await resetData.mutateAsync();
  }, [resetData]);

  const isTableEmpty = useMemo(() => {
    if (table.categories) {
      const keys = Object.keys(table?.categories);
      const values = table.categories.total.values;
      return !!(keys.length <= 1 || values.every((val: any) => !val.value));
    }
    return false;
  }, [table]);

  const mappedBarChartData = useMemo(() => ({
    ...barChartData,
    type: 'bar',
    dimensions: (barChartData?.dimensions || []).map((dimension: any) => ({
      ...dimension,
      color: emissionFactorCategoriesColors?.[dimension.label] || '#000',
    })),
    values: (barChartData?.values || []).map((value: any) => ({
      ...value,
      metrics: {
        ...value.metrics,
        sum_co2_kg: parseFloat((value.metrics.sum_co2_kg || 0).toFixed(2)),
      },
    })),
  }), [barChartData, emissionFactorCategoriesColors]);

  const dataYears = useMemo(() => {
    let years: number[] = [table.baseline_year];
    for(let i = table.start_year; i <= table.target_year; i++) {
      years.push(i);
    }
    return years;
  }, [table]);

  const chartYears = useMemo(() => {
    const { baseline_year: baselineYear } = table;
    const projectionYears: { year: number, isFinished: boolean }[] = [];
    const currentYear = getCurrentYear();

    if (!baselineYear) {
      return [];
    }

    dataYears.forEach((year: number, index: number) => {
      projectionYears.push({
        isFinished: year < currentYear,
        year,
      });
    });

    return projectionYears;
  }, [dataYears, table]);

  const hasEmissions = useMemo(() => !!table?.categories, [table]);

  return (
    <Grid container spacing={0} alignItems="left" maxWidth="m">
      <Grid item xs={12}>
        <Breadcrumbs list={breadcrumbs}/>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="pageTitle">{dictionary.scenarioBuilder.title}</Typography>
          <Tooltip title={dictionary.scenarioBuilder.resetButtonTooltip}>
            <Button
              color="secondary"
              disabled={!getPermission('scenarioBuilder.crud.edit')}
              onClick={onReset}
            >
              {dictionary.reset}
            </Button>
          </Tooltip>
        </Stack>
      </Grid>

      <Divider style={{ marginTop: 24, width: '100%' }} flexItem/>

      {isTableEmpty && (
        <Grid item xs={12} py={2}>
          <Alert
            severity="warning"
          >
            {dictionary.scenarioBuilder.noTransactionsWarning}
          </Alert>
        </Grid>
      )}

      {(status === 'loading') && (
        <BodyLoading height="100vh"/>
      )}

      {status === 'success' && (
        <Fragment>
          <Grid item xs={12} sm={5} pr={4}>
            <ScenarioBuilderForm table={table} status={status}/>
          </Grid>

          <Divider orientation="vertical" flexItem style={{ marginRight: -1, zIndex: 0 }}/>

          <Grid item position="relative" xs={12} sm={7} sx={{ padding: 2, marginBottom: -2 }}>
            <ChartSection
              title={dictionary.scenarioBuilder.reductionStrategy}
              subtitle={dictionary.scenarioBuilder.co2e}
            >
              {(isTableEmpty || !hasEmissions) && (
                <Box className="_d-flex jc-center" position="absolute" width="100%" top={0} bottom={40}>
                  <MessageFeedback
                    icon={isTableEmpty ? <ClockIcon/> : <CongratsIcon/> }
                    message={isTableEmpty ? dictionary.home.dashboard.noAnalysis : dictionary.home.dashboard.noEmissions}
                  />
                </Box>
              )}
              <Box
                sx={{
                  width: '100%',
                  minHeight: 350,
                  height: '100%',
                  minWidth: 200,
                  marginTop: 'auto',
                  ...chartYears.reduce((accumulator, year, index) => {
                    if (!highlightedCategory && !year.isFinished) {
                      return {
                        ...accumulator,
                        [`& .bar-${index}`]: { opacity: 0.5 },
                      };
                    }
                    return accumulator;
                  }, {} as any),
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <ReductionStrategyChart
                    data={mappedBarChartData}
                    highlightedDimension={highlightedCategory}
                    setHighlightedDimension={setHighlightedCategory}
                    showGrid={hasEmissions}
                    showAxisY={hasEmissions}
                    showChart={hasEmissions}
                    showAxisX={hasEmissions}
                  />
                </ResponsiveContainer>
              </Box>
            </ChartSection>
          </Grid>

          <Divider style={{ width: '100%', marginBottom: 22 }} flexItem/>

          <Grid item xs={12}>
            <ProjectionTable table={table} years={dataYears}/>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};
