import { MaybeNull } from 'types';
import { FileImage, FileVideo, FileDocument, FileType } from 'types/File';


export const imageMimes = Object.values(FileImage);
export const videoMimes = Object.values(FileVideo);
export const documentMimes = Object.values(FileDocument);

export const getFileType = (mime: string): MaybeNull<FileType> => {
  if (imageMimes.indexOf(mime as FileImage) > -1) {
    return FileType.Image;
  }
  if (videoMimes.indexOf(mime as FileVideo) > -1) {
    return FileType.Video;
  }
  if (documentMimes.indexOf(mime as FileDocument) > -1) {
    return FileType.File;
  }

  return null;
};

export const bytesToMb = (bytes: number) => bytes / (1024*1024);
