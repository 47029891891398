import React, { useCallback, useContext, useMemo, useState } from 'react';
import { object as yupObj, string as yupStr } from 'yup';

import { routes } from 'config';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { emailRegex } from 'helpers/validation';
import { usersClient } from 'clients/users/usersClient';
import { LoggedOutActionsLayout } from 'shared/components/layout/LoggedOutActionsLayout/LoggedOutActionsLayout';
import {
  Form,
} from 'shared/components/form/Form/Form';
import { IServerValidation } from 'shared/components/form/Form/form.types';

import { LoggedOutHeader } from './partials/LoggedOutHeader';
import { SwitchLoggedOutPage } from './partials/SwitchLoggedOutPage';



const formOptions = {
  defaultValues: {
    email: '',
  },
};

export const ResetPassword = () => {
  const [serverValidation, setServerValidation] = useState<IServerValidation>({
    successText: undefined,
    errorText: undefined,
  });
  const { dictionary } = useContext(LocalizationContext);

  const submitHandler = useCallback(async ({ email }: { email: string }) => {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    try {
      await usersClient.resetPassword(email);
      setServerValidation({
        successText: dictionary.forms.resetPassword.emailSent,
      });
    } catch (e: any) {
      if (e.response.status === 404) {
        setServerValidation({
          errorText: dictionary.forms.resetPassword.emailNotFound,
        });
      } else {
        setServerValidation({ errorText: dictionary.somethingWentWrong });
      }
    }
  },
  [setServerValidation, dictionary],
  );

  const validationSchema = yupObj().shape({
    email: yupStr()
      .required(dictionary.forms.validations.required)
      .matches(emailRegex, dictionary.forms.validations.invalidEmail),
  });

  const inputList = useMemo(() => [
    {
      name: 'email',
      rules: {
        required: dictionary.forms.validations.required,
        pattern: {
          value: emailRegex,
          message: dictionary.forms.validations.invalidEmail,
        },
      },
      label: dictionary.email,
    },
  ],
  [dictionary],
  );

  return (
    <LoggedOutActionsLayout>
      <LoggedOutHeader
        title={dictionary.forms.resetPassword.title}
        subtitle={dictionary.forms.resetPassword.desc}
      />

      <Form
        serverValidation={serverValidation}
        inputList={inputList}
        formOptions={formOptions}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        buttonLabel={dictionary.forms.resetPassword.btnLabel}
      />

      <SwitchLoggedOutPage
        linkText={dictionary.forms.resetPassword.backToLoginCTA}
        linkTo={routes.login}
        text={dictionary.forms.resetPassword.backToLoginDesc}
      />
    </LoggedOutActionsLayout>
  );
};
