import { AxiosResponse } from 'axios';
import { Expenses } from 'modules/Expenses/expenses';
import { PaginatedResults } from 'types/Query';
import { camelizeObject, snakizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';
import { mapLedgerAndJournalsParams } from './integrationsClient.formatter';


const apiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getIntegrations = (
  params?: Expenses.Integrations.GetIntegrationsParams,
): Promise<AxiosResponse<PaginatedResults<Expenses.Integrations.Integration>>> => {
  const mappedParams = {
    ...params,
    ...params?.pageSize && { limit: params.pageSize },
    ...params?.pageSize && params?.page && { offset: params.pageSize * params.page },
  };

  return request({
    options: {
      url: `${apiBaseUrl}/integrations`,
      method: 'GET',
      params: snakizeObject(mappedParams),
    },
  }).then((data: AxiosResponse<PaginatedResults<Expenses.Integrations.IntegrationApi>>) => {
    return {
      ...data,
      data: {
        ...data.data,
        results: data.data.results.map(camelizeObject<Expenses.Integrations.Integration>),
      },
    };
  });
};

const getIntegration = (
  integrationId?: number,
): Promise<Expenses.Integrations.Integration> => {
  return request({
    options: {
      url: `${apiBaseUrl}/integrations/${integrationId}`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Expenses.Integrations.IntegrationApi>) => {
    return camelizeObject(data.data);
  });
};

const getIntegrationLedgerAndJournals = (
  integrationId: number,
  params: Expenses.GetLedgerAndJournalsParams,
): Promise<AxiosResponse<PaginatedResults<Expenses.Integrations.LedgerOrJournal>>> => {
  return request({
    options: {
      url: `${apiBaseUrl}/integration/${integrationId}/accounting/ledger-and-journal`,
      method: 'GET',
      params: mapLedgerAndJournalsParams(params),
    },
  }).then((data: AxiosResponse<PaginatedResults<Expenses.Integrations.LedgerOrJournalApi>>) => {
    return {
      ...data,
      data: {
        ...data.data,
        results: data.data.results.map(camelizeObject<Expenses.Integrations.LedgerOrJournal>),
      },
    };
  });
};

const setIntegrationLedgerAndJournalsCleaning = (
  integrationId: number,
  ledgerAndJournalCleaning: Expenses.Integrations.JournalAndLedgerCleaningData[],
): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${apiBaseUrl}/integration/${integrationId}/accounting/ledger-and-journal/partial-update-batch/`,
      method: 'PATCH',
      data: ledgerAndJournalCleaning.map(snakizeObject<Expenses.Integrations.JournalAndLedgerCleaningData>),
    },
  });
};

const deleteIntegration = (
  integrationId: number,
): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${apiBaseUrl}/integrations/${integrationId}`,
      method: 'DELETE',
    },
  });
};

export const integrationsClient = {
  getIntegrations,
  getIntegration,
  getIntegrationLedgerAndJournals,
  setIntegrationLedgerAndJournalsCleaning,
  deleteIntegration,
};
