import { FC, KeyboardEvent, MouseEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { Button, List, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ChevronDownFillIcon } from 'shared/icons/NavIcons/ChevronDownFillIcon';
import { CheckIcon } from 'shared/icons/CheckIcon';
import { cacheKeys, routes } from 'config';
import { LayoutContext, UserContext } from 'contexts';
import { dictionary } from 'contexts/LocalizationContext/languages/en';
import { useWebsocket } from 'contexts/WebsocketContext/WebsocketContext';
import { usersClient } from 'clients/users/usersClient';
import { useWorkspaces } from 'shared/hooks/useWorkspaces';
import coolsetIcon from 'shared/img/icon.svg';
import { DropdownMenuItems } from 'shared/components/DropdownMenu/DropdownMenuItems';
import { DropdownMenu } from 'shared/components/DropdownMenu/DropdownMenu';
import { WorkspaceLogo } from 'shared/components/Logo/WorkspaceLogo';


export const WorkspaceMenu: FC = () => {
  const { genericError } = useContext(LayoutContext);
  const { logout, user } = useContext(UserContext);
  const { status, workspaces, clearWorkspaceCache } = useWorkspaces();
  const { socketId } = useWebsocket();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeCompanyId, setActiveCompanyId] = useState<number | undefined>(user?.company?.id);
  const [activeWorkspace, setActiveWorkspace] = useState(workspaces.find((workspace) => workspace.id === activeCompanyId));
  const prevActiveCompanyIdRef = useRef<number | undefined>(user?.company?.id);

  useEffect(() => {
    setActiveCompanyId(user?.company?.id);
  }, [user]);

  useEffect(() => {
    if (status === 'loading' || status === 'error') return;
    setActiveWorkspace(workspaces.find((workspace) => workspace.id === activeCompanyId));
  }, [activeCompanyId, status, workspaces]);

  const menuOptions = useMemo(
    () =>
      [
        {
          subItems: [
            {
              title: dictionary.menu.settings,
              link: routes.settings,
            },
            {
              title: dictionary.team.title,
              link: routes.team,
            },
          ],
          title: dictionary.workspaceMenu.currentWorkspace,
          withDivider: true,
        },
        {
          subItems: [
            {
              title: dictionary.routes.myAccount,
              link: routes.myAccount,
            },
            {
              title: dictionary.logOut,
              onClick: () => logout(),
            },
          ],
          withDivider: true,
        },
      ],
    [logout],
  );

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeWorkspace = (workspaceId: number) => {
    setActiveCompanyId(workspaceId);
    changeWorkspace.mutate({ workspaceId, socketId });
  };

  const changeWorkspace = useMutation(usersClient.changeWorkspace, {
    mutationKey: cacheKeys.workspaces.changeWorkspace,
    onSuccess: () => {
      clearWorkspaceCache();
      prevActiveCompanyIdRef.current = activeCompanyId;
    },
    onError: () => {
      genericError();
      setActiveCompanyId(prevActiveCompanyIdRef.current);
    },
  });

  return (
    <>
      <div
        className="logo"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <WorkspaceLogo
          image={activeWorkspace?.logo}
          name={activeWorkspace?.name}
        />
        <ChevronDownFillIcon/>
      </div>
      <DropdownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        id="workspace-menu"
        sx={{ p: 1, width: '280px' }}
      >
        <List
          className="_d-grid-xs-gap"
          id="workspace-select-list"
          sx={{ p: 0 }}
        >
          <Typography variant="h6" color="text.tertiary" px={1}>
            {dictionary.workspaceMenu.workspaces}
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: .5,
            maxHeight: '130px',
            overflowY: 'auto',
          }}>
            {workspaces.map((workspace) => {
              const isActive = workspace.id === activeCompanyId;
              return (
                <li key={`workspace-${workspace.id}`}>
                  <Button
                    className={isActive ? 'nav-btn' : 'nav-btn inactive'}
                    color={isActive ? 'success' : 'secondary'}
                    onClick={isActive ? undefined : () => onChangeWorkspace(workspace.id)}
                    variant={isActive ? 'contained' : 'text'}
                  >
                    <img
                      alt={workspace.name}
                      className="logo-container small"
                      src={workspace.logo || coolsetIcon}
                    />
                    <span className="btn-text">
                      {workspace.name}
                      {isActive && <CheckIcon/>}
                    </span>
                  </Button>
                </li>
              );})}
          </Box>
        </List>
        {menuOptions.map((props, index) => (
          <DropdownMenuItems key={index} {...props}/>
        ))}
      </DropdownMenu>
    </>
  );
};
