import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { noop } from 'lodash';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip as ChartTooltip, Treemap } from 'recharts';
import { addDays, differenceInDays } from 'date-fns';

import { line, scopeColors } from 'shared/styles/muiTheme';
import { MultiAreaChart } from 'modules/Charts/components/MultiAreaChart/MultiAreaChart';
import { TreeMapChartContent } from 'modules/Charts/components/TreeMapChart/TreeMapChart';
import MultiChartLegend from 'modules/Charts/components/ChartLegend/MultiChartLegend';
import { Intensity } from 'modules/Emissions/enums';
import { ChevronIcon } from 'shared/icons/ChevronArrIcon';
import { ClockIcon } from 'shared/icons/ClockIcon';
import { CongratsIcon } from 'shared/icons/CongratsIcon';
import { routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { convertDaysToDateLabel } from 'helpers/date';
import { addQuery } from 'helpers/query';
import { ChartGroupBy, ChartType } from 'clients/charts/chartClient.types';
import { TransactionStatus } from 'clients/transactions/transactionClient.types';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';
import { useCharts } from 'shared/hooks/useCharts';
import { Emissions } from 'shared/components/layout/Emissions/Emissions';
import { Filters } from 'shared/components/interactive/FiltersSection/FiltersSection';
import LoadingModal from 'shared/components/layout/Modal/LoadingModal/LoadingModal';
import { ChartSection } from 'shared/components/layout/ChartSection';
import { MessageFeedback } from 'shared/components/layout/Feedback/MessageFeedback';
import { NoTransactionsFeedback } from 'shared/components/layout/Feedback/NoTransactionsFeedback';


export const Dashboard: React.FC<{ filters: Partial<Filters> }> = ({
  filters: {
    dateAggregation,
    gteDate,
    lteDate,
    scope,
    setScope=noop,
    tags,
    intensity,
  },
}) => {

  const {
    emissionFactorCategoriesColors,
    emissionFactorCategoriesIcons,
    emissionFactorCategoriesByTitle,
    isResizing,
  } = useContext(LayoutContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [_highlightedScope, setHighlightedScope] = useState<any>(undefined);

  const highlightedScope = useMemo(() => {
    if (scope) {
      if (scope !== Scope.All) {
        return _highlightedScope;
      }
      return undefined;
    }
    return _highlightedScope;
  }, [scope, _highlightedScope]);

  const diffStartDate = useMemo(() => {
    if(gteDate && lteDate) {
      return addDays(gteDate, differenceInDays(gteDate, lteDate));
    }
  }, [gteDate, lteDate]);

  useEffect(() => {
    if (scope) {
      if (scope === Scope.All) {
        setHighlightedScope(undefined);
      } else {
        setHighlightedScope(parseInt(scope));
      }
    }
  }, [scope]);

  const mappedFilters = useMemo(() => ({
    dateGte: gteDate ?? undefined,
    dateLte: lteDate ?? undefined,
    status: TransactionStatus.Classified,
    scope: scope !== Scope.All ? scope : [Scope.One, Scope.Two, Scope.Three],
    tags,
    relativeTo: intensity,
  }), [gteDate, lteDate, scope, tags, intensity]);


  const barChartParams = useMemo(() => ({
    dateAggregation: dateAggregation,
    groupBy: ChartGroupBy.scope,
    metricLabel: 'Scope',
    type: ChartType.bar,
    ...mappedFilters,
  }), [dateAggregation, mappedFilters]);

  const { data: barChartData, isLoadingCharts: isBarChartDataLoading, isFetching: isFetchingBarChartData } = useCharts(barChartParams);

  const areaChartParams = useMemo(() => ({
    dateAggregation: dateAggregation,
    type: ChartType.area,
    ...mappedFilters,
  }), [dateAggregation, mappedFilters]);

  const { data: areaChartData, isLoadingCharts: isLoadingAreaChartData, isFetching: isFetchingAreaChartData } = useCharts(areaChartParams);

  const totalEmissionsAreaChartMappedData = useMemo(() => ({
    ...areaChartData,
    metrics: areaChartData?.metrics?.map((metric: any) => ({ ...metric, label: 'Emissions' })),
  }), [areaChartData]);

  const {
    meta: {
      transaction_count: totalTransactionsCount,
      total_co2_kg: totalEmissions,
      classified_transaction_count: classifiedTransactionsCount,
      percentile_diff_periods_total_co2_kg: percentileDiffPeriodsTotalEmissions,
      percentile_diff_periods_count: percentileDiffPeriodsCount,
      delta_days: deltaDays,
    } = {
      transaction_count: 0,
      total_co2_kg: 0,
      classified_transaction_count: 0,
      percentile_diff_periods_total_co2_kg: 0,
      percentile_diff_periods_count: 0,
      delta_days: 0,
    },
  } = totalEmissionsAreaChartMappedData || { meta: {} };

  const transactionsCountChartMappedData = useMemo(() => ({
    ...areaChartData,
    metrics: areaChartData?.metrics?.map((metric: any) => ({ ...metric, key: 'transaction_count', label: 'Transactions' })),
  }), [areaChartData]);

  const { dictionary } = useContext(LocalizationContext);

  const pieChartData = useMemo(() => {
    return barChartData && barChartData?.totals?.map(({ metrics, dimensions }: any) => ({
      value: metrics?.sum_co2_kg,
      name: `Scope ${dimensions?.group}`,
      scope: dimensions?.group,
      fill: scopeColors?.[dimensions?.group as keyof typeof scopeColors],
    })).sort((prev: any, next: any) => {
      return prev?.scope > next?.scope ? 1 : -1;
    });
  }, [barChartData]);

  const treeMapParams = useMemo(() => ({
    group_by: ChartGroupBy.parentCategory,
    metricLabel: 'Category',
    type: ChartType.treeMap,
    ...mappedFilters,
  }), [mappedFilters]);

  const { data: treeMapData, isLoadingCharts: isTreeMapDataLoading, isFetching: isFetchingTreeMapData } = useCharts(treeMapParams);

  const mappedTreeMapData = useMemo(() => {
    return treeMapData && treeMapData?.values?.map(({ metrics, dimensions }: any) => ({
      name: dimensions?.group,
      category: emissionFactorCategoriesByTitle?.[dimensions?.group],
      color: emissionFactorCategoriesColors?.[dimensions?.group] || '#000',
      realValue: metrics?.sum_co2_kg,
      icon: emissionFactorCategoriesIcons?.[dimensions?.group],
      children: [{
        size: Math.abs(metrics?.sum_co2_kg),
      }],
    }));
  }, [treeMapData, emissionFactorCategoriesColors, emissionFactorCategoriesIcons, emissionFactorCategoriesByTitle]);

  const isAnyChartsLoading = isTreeMapDataLoading || isBarChartDataLoading || isLoadingAreaChartData;
  const isAnyChartsFetching = isFetchingTreeMapData || isFetchingBarChartData || isFetchingAreaChartData;

  const hasTransactions = transactionsCountChartMappedData?.meta?.count > 0 ?? false;
  const hasClassifiedTransactions = (transactionsCountChartMappedData?.meta?.count > 0 && totalEmissions !== 0) ?? false;
  const hasEmissions = totalEmissions !== 0 ?? false;

  const totalEmissionsSubTitle = useMemo(() => {
    if (intensity === Intensity.absolute) {
      return dictionary.measurements.co2e;
    }
    if (intensity === Intensity.perEmployee) {
      return dictionary.measurements.co2ePerEmployee;
    }
    if (intensity === Intensity.perRevenueUnit) {
      return dictionary.measurements.co2eUnitOfRevenue;
    }
  }, [dictionary, intensity]);

  if (!isAnyChartsLoading && !hasTransactions) {
    return (
      <Box p={8}><NoTransactionsFeedback/></Box>
    );
  }

  return (
    <Box className="dashboard-container">
      <Box className="col-container:2-no-gap">
        <ChartSection
          title={dictionary.home.dashboard.totalEmissions}
          subtitle={totalEmissionsSubTitle}
          info={dictionary.tooltips.totalEmissions}
          containerStyle={{
            '--border-right': `1px solid ${line}`,
            borderBottom: `1px solid ${line}`,
            padding: '32px 32px 16px 0',
          } as React.CSSProperties}
          total={isAnyChartsLoading ? <Skeleton variant="text" width="150px"/> : (
            hasTransactions && hasClassifiedTransactions && <Emissions kg={totalEmissions || 0} showCo2={false}/>
          )}
          actions={
            isAnyChartsLoading ? (
              <Skeleton variant="rounded" height="1.8rem" width="100px"/>
            ) : (
              !!percentileDiffPeriodsTotalEmissions && diffStartDate && gteDate && lteDate && (
                <Typography
                  variant="body1"
                  sx={{
                    color: percentileDiffPeriodsTotalEmissions > 0 ? 'error.main' : 'primary.main',
                    backgroundColor: percentileDiffPeriodsTotalEmissions > 0 ? 'error.light' : 'primary.light',
                    padding: 0.5,
                  }}
                >
                  {dictionary.charts.percentageComparison(
                    percentileDiffPeriodsTotalEmissions,
                    convertDaysToDateLabel(deltaDays, dictionary),
                  )}
                </Typography>
              )
            )
          }
        >
          {!isAnyChartsLoading && !hasClassifiedTransactions && (
            <Box className="_d-flex jc-center" position="absolute" width="100%" bottom={50}>
              <MessageFeedback icon={<ClockIcon/>} message={dictionary.home.dashboard.noAnalysis}/>
            </Box>
          )}
          <Box height={100} width="100%" mt="auto">
            <MultiAreaChart
              isLoadingCharts={isAnyChartsLoading}
              isFetchingCharts={isAnyChartsFetching}
              data={totalEmissionsAreaChartMappedData}
              dateAggregation={dateAggregation}
              showLegend={false}
              showGrid={false}
              showChart={hasClassifiedTransactions}
              showAxisX
              showTooltipOnHoverBetweenItems
            />
          </Box>
        </ChartSection>

        <ChartSection
          title={dictionary.home.dashboard.totalTransactions}
          subtitle={dictionary.home.dashboard.nrOfTransactions}
          containerStyle={{ padding: '32px 0 16px 32px', borderBottom: `1px solid ${line}` }}
          info={dictionary.tooltips.totalTransactions}
          total={isAnyChartsLoading ? <Skeleton variant="text" width="150px"/> : (
            <React.Fragment>
              {classifiedTransactionsCount !== totalTransactionsCount && `${classifiedTransactionsCount || 0} / `}
              {totalTransactionsCount || 0}
            </React.Fragment>
          )}
          actions={isAnyChartsLoading ? (
            <Skeleton variant="rounded" height="1.8rem" width="100px"/>
          ) : (
            <div className="_d-flex-dir-col-sm-gap-ali-end">
              {classifiedTransactionsCount !== totalTransactionsCount && (
                <Button
                  onClick={() => navigate(routes.measurements)}
                  sx={{ alignSelf: 'end' }}
                >
                  {dictionary.filterSection.checkTransactions}
                </Button>
              )}
              {!!percentileDiffPeriodsCount && diffStartDate && gteDate && lteDate && !isAnyChartsLoading && (
                <Typography
                  variant="body1"
                  sx={{
                    color: percentileDiffPeriodsCount > 0 ? 'error.main' : 'primary.main',
                    backgroundColor: percentileDiffPeriodsCount > 0 ? 'error.light' : 'primary.light',
                    padding: 0.5,
                  }}
                >
                  {dictionary.charts.percentageComparison(
                    percentileDiffPeriodsCount,
                    convertDaysToDateLabel(deltaDays, dictionary),
                  )}
                </Typography>
              )}
            </div>
          )}
        >
          <Box height={100} width="100%" mt="auto">
            <ResponsiveContainer width="100%" height="100%">
              <MultiAreaChart
                isLoadingCharts={isAnyChartsLoading}
                isFetchingCharts={isAnyChartsFetching}
                data={transactionsCountChartMappedData}
                dateAggregation={dateAggregation}
                showLegend={false}
                showGrid={false}
                showAxisX
                showTooltipOnHoverBetweenItems
                hideTooltipUnits
              />
            </ResponsiveContainer>
          </Box>
        </ChartSection>
      </Box>

      <Box className="col-container:2-no-gap">
        <ChartSection
          title={dictionary.home.dashboard.emissionsByScope}
          subtitle={totalEmissionsSubTitle}
          info={dictionary.tooltips.emissionsByScope}
          containerStyle={{
            '--border-right': `1px solid ${line}`,
            padding: '32px 32px 16px 0',
            borderBottom: `1px solid ${line}`,
          } as React.CSSProperties}
        >
          {isAnyChartsLoading || (hasClassifiedTransactions && hasEmissions) ? (
            <div className="_d-flex-jc-se-ali-center" style={{ width: '100%' }}>
              <div className="_d-flex" style={{ height: 200, width: 200 }}>
                {(pieChartData && !isAnyChartsLoading && !isResizing) ? (
                  <Fragment>
                    <LoadingModal open={isAnyChartsFetching}/>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={200} height={200} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                        <text x="50%" y="50%" textAnchor="middle" fontWeight={500} dominantBaseline="middle" style={{ fontSize: 15 }}>
                          <Emissions kg={totalEmissions} string showCo2={false}/>
                        </text>
                        <Pie
                          data={pieChartData}
                          dataKey="value"
                          fill="#82ca9d"
                          innerRadius="65%"
                          strokeWidth={4}
                          startAngle={90}
                          endAngle={460}
                          outerRadius="100%"
                        >
                          {pieChartData
                            .sort((a: any, b: any) => parseInt(a.scope) > parseInt(b.scope) ? 1 : -1)
                            .map(({ name, value, fill, scope: _scope }: any, i: number) => (
                              <Cell
                                key={`cell-${i}`}
                                fill={fill}
                                opacity={highlightedScope !== undefined ? (`${highlightedScope}`.indexOf(_scope) >= 0 ? 1 : 0.3) : 1}
                                onMouseEnter={() => setHighlightedScope(_scope)}
                                onMouseLeave={() => setHighlightedScope(undefined)}
                              />
                            ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Fragment>
                ) : (
                  <Box className="_d-flex-ali-center-jc-center" height={200} width="100%">
                    <Skeleton variant="circular" width={200} height={200}/>
                  </Box>
                )}
              </div>
              <div className="_d-flex dir-col jc-center md-gap">
                {isAnyChartsLoading ? (
                  <Stack>
                    <Skeleton height="70px" width="95px"/>
                    <Skeleton height="70px" width="95px"/>
                    <Skeleton height="70px" width="95px"/>
                  </Stack>
                ) : (
                  pieChartData?.map(({ name, fill, value, scope: _scope }: any, i: number) => _scope && (
                    <div
                      className="_d-flex ali-center md-gap"
                      key={i}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setScope(scope?.toString() === _scope?.toString() ? undefined : _scope);
                        setHighlightedScope(undefined);
                      }}
                      onMouseEnter={() => setHighlightedScope(_scope)}
                      onMouseLeave={() => setHighlightedScope(undefined)}
                    >
                      <Box style={{ width: '3px', height: '44px', backgroundColor: fill }}/>
                      <div>
                        <Typography variant="h4" component="span" fontWeight={400}>{name}</Typography>
                        <Typography variant="body1">
                          <Emissions kg={value} showCo2={false} showBoldValue={false}/>
                        </Typography>
                      </div>
                    </div>
                  )))}
              </div>
            </div>
          ) : (
            <Box className="_d-flex jc-center" position="absolute" width="100%" top={0} bottom={40}>
              <LoadingModal open={isBarChartDataLoading}/>
              <MessageFeedback
                icon={hasClassifiedTransactions ? <CongratsIcon/> : <ClockIcon/>}
                message={hasClassifiedTransactions ? dictionary.home.dashboard.noEmissions : dictionary.home.dashboard.noAnalysis}
              />
            </Box>
          )}
        </ChartSection>

        <ChartSection
          title={dictionary.home.dashboard.emissionsByAggregation(dateAggregation)}
          subtitle={totalEmissionsSubTitle}
          containerStyle={{
            borderBottom: `1px solid ${line}`,
            padding: '32px 0 16px 32px',
            position: 'relative',
          }}
          actions={hasClassifiedTransactions && hasEmissions && (
            <Grid item>
              <MultiChartLegend
                legendItemColors={scopeColors}
                legendItems={[Scope.One, Scope.Two, Scope.Three]}
                legendLabel={(scope: string) => `Scope ${scope}`}
                onMouseEnter={(scope) => setHighlightedScope(parseInt(scope))}
                onMouseLeave={() => setHighlightedScope(undefined)}
                onLegendClick={(_scope: string) => {
                  setScope(scope?.toString() === _scope?.toString() ? undefined : _scope);
                  setHighlightedScope(undefined);
                }}
              />
            </Grid>
          )}
        >
          {!isAnyChartsLoading && (!hasClassifiedTransactions || !hasEmissions) && (
            <Box className="_d-flex jc-center" position="absolute" width="100%" top={0} bottom={40}>
              <MessageFeedback
                icon={hasClassifiedTransactions ? <CongratsIcon/> : <ClockIcon/>}
                message={hasClassifiedTransactions ? dictionary.home.dashboard.noEmissions : dictionary.home.dashboard.noAnalysis}
              />
            </Box>
          )}
          <Box width="100%" height={250}>
            <ResponsiveContainer width="100%" height="100%">
              <MultiAreaChart
                setDimension={setScope}
                dimension={scope}
                isLoadingCharts={isAnyChartsLoading}
                isFetchingCharts={isAnyChartsFetching}
                highlightedDimension={highlightedScope}
                setHighlightedDimension={setHighlightedScope}
                data={barChartData}
                showAxisX
                showAxisY={hasClassifiedTransactions && hasEmissions}
                showChart={hasClassifiedTransactions && hasEmissions}
                showGrid={hasClassifiedTransactions && hasEmissions}
                mapNetOverviewData
                showLegend={false}
                newTooltip
                dateAggregation={dateAggregation}
              />
            </ResponsiveContainer>
          </Box>
        </ChartSection>
      </Box>

      <Box className="col-container" py={4}>
        <ChartSection
          title={dictionary.home.dashboard.topCategories(scope)}
          subtitle={totalEmissionsSubTitle}
          actions={(
            <Button
              color="info"
              onClick={() => navigate(routes.measurements)}
            >
              {dictionary.home.dashboard.viewAllEmissionFactors}
              <ChevronIcon direction="right"/>
            </Button>
          )}
        >
          <LoadingModal open={isAnyChartsFetching && !isAnyChartsLoading}/>
          <Box width="100%">
            {isAnyChartsLoading || isResizing ? (
              <Box
                className="_d-grid"
                sx={{
                  gridTemplateColumns: '2fr 1fr 1fr',
                  gridTemplateRows: '200px 200px',
                  'span': { height: 'unset' },
                }}
              >
                <Skeleton variant="rounded" sx={{ gridColumn: '1 / 2', gridRow: '1 / -1' }}/>
                <Skeleton variant="rounded" sx={{ gridColumn: '2 / -1' }}/>
                <Skeleton variant="rounded"/>
                <Skeleton variant="rounded"/>
              </Box>
            ) : (
              hasClassifiedTransactions && hasEmissions ? (
                <ResponsiveContainer width="100%" height={400}>
                  <Treemap
                    style={{ background: scopeColors[3] }}
                    data={mappedTreeMapData}
                    dataKey="size"
                    type="flat"
                    content={
                      <TreeMapChartContent
                        onClick={({ root }: any) => {
                          navigate({
                            pathname: routes.measurements,
                            search: addQuery(location.search, { category: root?.category?.slug }),
                          });
                        }}
                      />
                    }
                    isAnimationActive={false}
                  >
                    <ChartTooltip
                      content={({ active, payload, label }) => {
                        return active && payload && payload.length ? (
                          <Box
                            component={Paper}
                            sx={{
                              boxShadow: '0px 5px 15px -3px rgba(0, 0, 0, 0.1), 0px 2px 6px -4px rgba(0, 0, 0, 0.1)',
                              borderRadius: 1,
                            }}
                            p={2}
                          >
                            {payload[0].payload.root.name}<br/>
                            <Emissions kg={payload[0].payload.root.realValue as number}/>{' '}
                            {((payload[0].payload.root.realValue as number / treeMapData.meta.total_co2_kg) * 100).toFixed(2)} %
                          </Box>
                        ) : null;
                      }}
                    />
                  </Treemap>
                </ResponsiveContainer>
              ) : (
                <Box py={4}>
                  <MessageFeedback
                    icon={hasClassifiedTransactions ? <CongratsIcon/> : <ClockIcon/>}
                    message={hasClassifiedTransactions ?
                      dictionary.home.dashboard.noEmissions :
                      dictionary.home.dashboard.noAnalysis
                    }
                  />
                </Box>
              )
            )}
          </Box>
        </ChartSection>
      </Box>
    </Box>
  );
};
