import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const ChevronDownFillIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      clipRule="evenodd"
      d="M4.66112 6.27251C4.87012 6.03812 4.9375 6 5.375 6.00003L8 6L10.625 6.00005C11.0625 6.00003 11.1299 6.03812 11.3389 6.27251C11.5479 6.50689 11.5544 6.89451 11.3534 7.13827L8.37844 10.8121C8.27946 10.9321 8.14281 11 8 11C7.85719 11 7.72055 10.9321 7.62156 10.8121L4.64656 7.13827C4.4456 6.89451 4.45211 6.50689 4.66112 6.27251Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </IconContainer>
);
