// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Select = (theme: Theme) => {
  return {
    MuiSelect: {
      styleOverrides: {
      },
    },
  };
};

export default Select;
