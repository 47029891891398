import React, { FC, ReactNode } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LocalizationContext } from 'contexts';


export const AsyncConfirmationDialog: FC<AsyncConfirmationDialogProps> = (props) => {
  const { dictionary } = React.useContext(LocalizationContext);

  const {
    open = false,
    onClose = () => null,
    onConfirm = () => null,
    title = dictionary.confirmation.defaultTitle,
    content = dictionary.confirmation.defaultContent,
    confirmLabel = dictionary.confirmation.yes,
    cancelLabel = dictionary.cancel,
    isDelete = false,
    isLoading = false,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 5,
          maxWidth: 'min(650px, 90vw)',
        },
      }}
    >
      <Typography variant="h1" mb={1}>
        {title}
      </Typography>
      <Typography variant="body1" mb={5}>
        {content}
      </Typography>
      <Box className="_d-flex-ali-center-jc-end-md-gap">
        {cancelLabel && (
          <Button color="info" onClick={onClose}>{cancelLabel}</Button>
        )}
        {confirmLabel && (
          <LoadingButton
            onClick={onConfirm}
            variant="contained"
            color={isDelete ? 'error' : 'primary'}
            loading={isLoading}
          >
            {confirmLabel}
          </LoadingButton>
        )}
      </Box>
    </Dialog>
  );
};

interface AsyncConfirmationDialogProps {
  open?: boolean;
  onClose?: any;
  onConfirm?: any;
  title?: ReactNode;
  content?: ReactNode;
  confirmLabel?: ReactNode;
  cancelLabel?: ReactNode;
  isDelete?: boolean;
  isLoading?: boolean;
}
