import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const HomeIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.43395 1.83429C7.74637 1.52187 8.2529 1.52187 8.56532 1.83429L14.1653 7.43429C14.3941 7.66309 14.4626 8.00718 14.3387 8.30612C14.2149 8.60506 13.9232 8.79998 13.5996 8.79998H12.7996V13.6C12.7996 14.0418 12.4415 14.4 11.9996 14.4H10.3996C9.9578 14.4 9.59963 14.0418 9.59963 13.6V11.2C9.59963 10.7581 9.24146 10.4 8.79963 10.4H7.19963C6.7578 10.4 6.39963 10.7581 6.39963 11.2V13.6C6.39963 14.0418 6.04146 14.4 5.59963 14.4H3.99963C3.5578 14.4 3.19963 14.0418 3.19963 13.6V8.79998H2.39963C2.07606 8.79998 1.78435 8.60506 1.66053 8.30612C1.5367 8.00718 1.60515 7.66309 1.83395 7.43429L7.43395 1.83429Z"
      fill="currentColor"
    />
  </IconContainer>
);
