interface IGenerateDownload {
    blobFile: Blob,
    downloadName: string,
}

export const generateDownload = ({ blobFile, downloadName } : IGenerateDownload) => {
  const fileURL = URL.createObjectURL(blobFile);
  const a = document.createElement('a');
  a.href = fileURL;
  a.download = downloadName;
  a.click();
  window.URL.revokeObjectURL(fileURL);
};