import React, { useContext, useState } from 'react';
import { Modal, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

import { LocalizationContext } from 'contexts';
import RightArrowBtn from 'shared/components/layout/Buttons/RightArrowBtn';

import BundleCategoryIcon from './BundleCategoryIcon';
import { getBundleCategoryList } from './carbonRemovalHelpers';
import { IBundlesProps } from './CarbonRemovalTypes';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '12px',
};

const Bundle: React.FunctionComponent<IBundlesProps> = ({
  imgsrc,
  desc,
  price,
  offsetType,
  carbonPermanence,
  title,
  id,
  luneClientCompanyKey,
  iframeUrl = `https://pay.lune.co/${luneClientCompanyKey}?quantity=1&bundle_id=${id}`,
}) => {
  const [isModal, setModal] = useState(false);
  const closeHandler = () => setModal(false);
  const categoryList = getBundleCategoryList(offsetType, carbonPermanence);
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Stack
      sx={{ mt: 3, mb: 3 }}
      color="#374151"
      direction="row"
      gap={3}
    >
      <Modal
        open={isModal}
        onClose={closeHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            id="iframe"
            component="iframe"
            sx={{
              width: '1100px',
              height: '738px',
              border: 'none',
              borderRadius: '12px',
            }}
            src={iframeUrl}
            title={dictionary.carbonRemoval.bundleSection.bundleIframeTitle}
          />
        </Box>
      </Modal>
      <Box
        component="img"
        minHeight="164px"
        minWidth="280px"
        width="280px"
        height="194px"
        sx={{ objectFit: 'cover', objectPosition: '50% 0%' }}
        borderRadius="12px"
        src={imgsrc}
      />
      <Stack>
        <Typography fontSize="21px" fontWeight="500">
          {title}
        </Typography>
        <Stack mt={0.7} gap={1.5} direction="row">
          {categoryList.map((bundleCategory) => (
            <BundleCategoryIcon
              key={bundleCategory.title}
              title={bundleCategory.title}
              icon={bundleCategory.icon}
            />
          ))}
        </Stack>
        <Typography mt={1.5} fontSize={13} lineHeight="15px" color="#6B7280">
          {desc}
        </Typography>
        <RightArrowBtn
          sxProps={{ ml: 0, mt: 'auto', alignSelf: 'start' }}
          label={dictionary.carbonRemoval.bundleSection.selectProject}
          onClick={() => setModal(true)}
        />
      </Stack>
      <Typography ml="auto" sx={{ whiteSpace: 'nowrap' }} fontSize={18} fontWeight={700}>
        {price}
      </Typography>
    </Stack>
  );
};

export default Bundle;
