import { AxiosError, AxiosResponse } from 'axios';
import { PaginatedResults } from 'types/Query';
import { camelizeObject, snakizeObject } from 'helpers/object';
import { request } from 'clients/baseClient';
import { CompanyTag, CompanyTagApi, GetCompanyTagsParams } from 'clients/companyTags/companyTagClient.types';


const companyTagsApiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getCompanyTags = (params: GetCompanyTagsParams = {}): Promise<AxiosResponse<PaginatedResults<CompanyTag>, AxiosError>> => {
  return request({
    options: {
      url: `${companyTagsApiBaseUrl}/company-tags/`,
      method: 'GET',
      params: snakizeObject(params),
    },
  }).then((data: AxiosResponse<PaginatedResults<CompanyTagApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(camelizeObject<CompanyTag>),
    },
  }));
};

export const companyTagClient = {
  getCompanyTags,
};