import React from 'react';
import { Box, Typography } from '@mui/material';
import { Expenses } from 'modules/Expenses/expenses';
import { IntegrationIcon } from 'modules/Expenses/components/FileOrIntegrationName';
import { LocalizationContext } from 'contexts';


export const IntegrationSourceCell = (props: IntegrationSourceCellProps) => {
  const { dictionary } = React.useContext(LocalizationContext);

  const { integration } = props;

  return (
    <Box
      className="_d-grid"
      sx={{
        gridTemplateColumns: 'auto auto',
        gridColumnGap: 8,
        gridRowGap: 0,
      }}
    >
      <IntegrationIcon fileName={integration.name || ''} style={{ gridRow: '1 / 3', alignSelf: 'center' }}/>
      <Typography variant="body1">{integration.name}</Typography>
      <Typography variant="body2" color="text.tertiary">
        {typeof integration.transactionsCount === 'number' ? integration.transactionsCount : 'NA'}{' '}
        {dictionary.expenses.transactions}
      </Typography>
    </Box>
  );
};

interface IntegrationSourceCellProps {
    integration: Expenses.Integrations.Integration;
}