import React, { FC } from 'react';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';

import { CompanyTag } from 'clients/companyTags/companyTagClient.types';


export const TagsCell: FC<any> = ({ row, value, getTagTooltip }) => {
  const tagNames = (value || []).map((tag: CompanyTag) => tag.name);

  return (
    <Tooltip title={getTagTooltip(tagNames)}>
      <Stack direction="row" spacing={1}>
        {tagNames.slice(0, 2).map((tagName: string) => (
          <Chip
            size="small"
            variant="filled"
            key={tagName}
            label={tagName}
            sx={{
              borderRadius: '20px',
              minWidth: 20,
              height: 20,
              fontSize: 12,
            }}
          />
        ))}
        {tagNames.length > 2 && (
          <Typography>...</Typography>
        )}
      </Stack>
    </Tooltip>
  );
};