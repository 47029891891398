import React, { FC, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { routes } from 'config';
import logoNoTextImg from 'shared/img/icon.svg';
import logoImg from 'shared/img/logo.png';


type LogoProps = {
  style: React.CSSProperties;
  withBackground?: boolean;
  withoutText?: boolean;
}

export const Logo: FC<LogoProps> = ({ style, withBackground = false, withoutText = false }) => {

  const navigate = useNavigate();

  const image = useMemo(() => {
    return withoutText ? logoNoTextImg : logoImg;
  }, [withoutText]);

  if (withBackground) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: '#F2F2F2', borderRadius: 2, padding: '10px 16px' }}
      >
        <img src={image} alt="Coolset" style={{ ...style }}/>
      </Box>
    );
  }

  return (
    <img src={image} alt="Coolset" style={{ ...style, cursor: 'pointer' }} onClick={() => navigate(routes.home)}/>
  );
};
