import { useCallback, useContext } from 'react';
import { LayoutContext } from 'contexts';
import { ChartDataApi } from 'clients/charts/chartClient.types';
import { MappedPieChartData, MappedPieChartOptions } from 'views/LoggedIn/Measurements/types';


export const useMapCategoryChart = () => {
  const {
    emissionFactorCategoriesColors,
    emissionFactorCategoriesIcons,
    emissionFactorCategoriesByTitle,
  } = useContext(LayoutContext);

  const mapCategoryChartData = useCallback((
    categoryChartData: ChartDataApi,
    options: MappedPieChartOptions = {},
    totalSumCo2KgWithNegative: number = 0,
    prevCategoryChartData?: ChartDataApi,
    prevTotalSumCo2KgWithNegative: number = 0,
  ): MappedPieChartData => {

    categoryChartData?.values.forEach(({ metrics }) => {
      const sumCo2Kg = metrics?.sum_co2_kg || 0;
      if (sumCo2Kg < 0 && !options.isChild) {
        totalSumCo2KgWithNegative += sumCo2Kg * - 2;
      }
    });

    prevCategoryChartData?.values.forEach(({ metrics }) => {
      const sumCo2Kg = metrics?.sum_co2_kg || 0;
      if (sumCo2Kg < 0 && !options.isChild) {
        prevTotalSumCo2KgWithNegative += sumCo2Kg * - 2;
      }
    });

    const getValues = (data: any, totalCo2: number) => {
      const sumCo2Kg = data?.sum_co2_kg || 0;
      const value = sumCo2Kg * (options.allowNegative || sumCo2Kg > 0 ? 1 : -1);
      const co2Proportion = (value / totalCo2) || 0;
      const co2Percentage = (co2Proportion * 100).toFixed(2);
      const co2ProportionWithNegativeValues = ((value * (value > 0 ? 1 : -1)) / totalCo2) || 0;
      const co2PercentageWithNegativeValues = (co2Proportion * 100).toFixed(2);
      const transactionCount = data?.classified_transaction_count || 0;

      return {
        value,
        co2Proportion,
        co2Percentage,
        co2ProportionWithNegativeValues,
        co2PercentageWithNegativeValues,
        transactionCount,
      };
    };

    let mappedData = (categoryChartData && categoryChartData?.values.map(({ metrics, dimensions }) => {

      const prevCategoryValue = prevCategoryChartData?.values.find(data => dimensions?.group === data?.dimensions?.group);

      const values = getValues(metrics, totalSumCo2KgWithNegative);
      const previousValues = getValues(prevCategoryValue?.metrics, prevTotalSumCo2KgWithNegative);

      let percentageChange = ((values.value - previousValues.value) / (previousValues.value || 1)) * 100;

      if (previousValues.value === 0 && values.value > 0) {
        percentageChange = 100;
      }

      return {
        ...values,
        previousValues,
        percentageChange,
        name: dimensions?.group,
        slug: emissionFactorCategoriesByTitle?.[dimensions?.group]?.slug,
        fill: emissionFactorCategoriesColors?.[dimensions?.group] || '#000',
        icon: emissionFactorCategoriesIcons?.[dimensions?.group],
      };
    }));

    return mappedData;
  },
  [
    emissionFactorCategoriesByTitle,
    emissionFactorCategoriesColors,
    emissionFactorCategoriesIcons,
  ],
  );

  return {
    mapCategoryChartData,
  };
};

