import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const BoltIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      d="M9.5863 1.52579C9.64331 1.25973 9.51415 0.988619 9.27162 0.865269C9.02908 0.741919 8.73391 0.797219 8.55248 0.999998L1.75248 8.6C1.59469 8.77634 1.55529 9.02896 1.65188 9.24498C1.74846 9.461 1.96299 9.60007 2.19962 9.60007H7.45743L6.41294 14.4744C6.35593 14.7404 6.48509 15.0115 6.72762 15.1349C6.97016 15.2582 7.26533 15.2029 7.44676 15.0002L14.2468 7.40015C14.4045 7.22381 14.4439 6.97119 14.3474 6.75517C14.2508 6.53915 14.0362 6.40007 13.7996 6.40007H8.54181L9.5863 1.52579Z"
      fill="currentColor"
    />
  </IconContainer>
);
