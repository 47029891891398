import { useQuery } from 'react-query';
import { marketplaceClient } from 'modules/Marketplace/client/marketplaceClient';
import { VendorTypes } from 'modules/Marketplace/helpers/general';
import { cacheKeys } from 'config';


const BaseFilterOptions = {
  sustainabilityTypes: [],
  focusAreas: [],
  countries: [],
};

export const useMarketplaceFilterOptions = (vendorType: string = VendorTypes.SUS) => {

  const {
    data: { data: filterOptions } = {},
    isLoading,
  } = useQuery(
    [cacheKeys.marketplace.getMarketplaceFilterOptions, vendorType],
    () => marketplaceClient.getMarketplaceFilterOptions(vendorType),
  );

  return {
    filterOptions: filterOptions || BaseFilterOptions,
    isLoading,
  };
};
