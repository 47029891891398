import { Grid } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { noop } from 'lodash';


type MultiChartLegendProps = {
  legendItemColors: Record<string, string | undefined>,
  legendItems: Array<string>,
  onLegendClick?: (item: string) => void,
  onMouseEnter?: (item: string) => void,
  onMouseLeave?: (item: string) => void,
  legendLabel: string | ((item: string) => string),
};

const MultiChartLegend = ({
  legendItemColors,
  legendItems,
  onLegendClick = noop,
  onMouseEnter = noop,
  onMouseLeave = noop,
  legendLabel,
}: MultiChartLegendProps) => (
  <Stack alignItems="center" spacing={2} direction="row" flexWrap="nowrap">
    {legendItems.map((item: string) => (
      <Box key={item}>
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          spacing={1}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => onLegendClick(item)}
          onMouseEnter={() => onMouseEnter(item)}
          onMouseLeave={() => onMouseLeave(item)}
        >
          <Grid item>
            <Box
              sx={{ background: legendItemColors[item], width: 10, height: 10, borderRadius: '50%' }}
            />
          </Grid>
          <Grid item sx={{ fontSize: 12, fontWeight: 400 }}>
            {typeof legendLabel === 'function' ? legendLabel(item) : item}
          </Grid>
        </Grid>
      </Box>
    ))}
  </Stack>
);

export default MultiChartLegend;
