import { FC, Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Divider, Grid } from '@mui/material';

import { isEqual } from 'lodash';
import { LayoutContext, LocalizationContext } from 'contexts';
import {
  ActivityBasedTransactionStatus,
  TransactionManualInputApi,
} from 'clients/transactions/transactionClient.types';
import { useActivityBasedCategories } from 'shared/hooks/useActivityBasedSubCategories';
import { useEmissionFactorCategories } from 'shared/hooks/useEmissionFactorCategories';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';

import { Filters } from 'shared/components/interactive/FiltersSection/FiltersSection';
import { TransactionSection } from './TransactionSection';
import { TransactionGroup } from './TransactionGroup';
import { ManualEntryTransactionsList } from './ManualEntryTransactionsList';


export const CompletedSectionsIds = {
  GAS: 'gasTransactions',
  ELECTRIC: 'electricityTransactions',
};

export const RequiredABTransactions: FC<{ filters: Partial<Filters> }> = ({
  filters: {
    tags,
  },
}) => {
  const { dictionary } = useContext(LocalizationContext);
  const {
    emissionFactorCategories: subCategories,
    status: emissionFactorCategoriesStatus,
  } = useEmissionFactorCategories({ limit: 2000, mandatory: true });
  const { activityBasedSubCategories, status, error } = useActivityBasedCategories({ mandatory: true, tags });
  const { genericError } = useContext(LayoutContext);
  const initialCount = useMemo(() => (
    subCategories.length ? subCategories.map((subCategory) => (
      {
        ...subCategory,
        count: 1,
      }
    )) : []
  ), [subCategories]);
  const [categoryTableCount, setCategoryTableCount] = useState<{id: number, count: number}[]>(initialCount);
  const [forceExpandCompleted, setForceExpandCompleted] = useState<boolean>(false);
  const handleForceExpanded = useCallback(() => setForceExpandCompleted(false), []);

  useEffect(() => {
    if (!isEqual(initialCount, categoryTableCount)) {
      setCategoryTableCount(initialCount);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCount]);

  if ([status, emissionFactorCategoriesStatus].includes('loading') || !activityBasedSubCategories) {
    return <BodyLoading height="100vh"/>;
  }

  if (error) {
    genericError();
    return null;
  }

  return (
    <Grid container direction="column" spacing={2}>
      {subCategories.map((subCategory) => (
        <Grid item key={subCategory.id}>
          <TransactionSection title={dictionary.transaction.transactionList.transactionTitle(subCategory.title)} defaultIsExpanded hideDivider>
            {Array.from({
              length: categoryTableCount.find((category) => subCategory.id === category.id)?.count || 1,
            }).map((_, index) => (
              <Fragment key={index}>
                <ManualEntryTransactionsList
                  subCategoryName={subCategory.slug}
                  subCategoryUnit={subCategory.manual_inputs ?
                    subCategory.manual_inputs.find((manualInput: TransactionManualInputApi) => manualInput.unit)?.unit :
                    ''
                  }
                  subCategoryId={subCategory.id}
                  setForceExpandCompleted={setForceExpandCompleted}
                />
                {(index !== (categoryTableCount.find((category) => subCategory.id === category.id)?.count || 1 ) - 1) && <Divider flexItem sx={{ mt: 5, mb: 5 }}/>}
              </Fragment>
            ))}
          </TransactionSection>
        </Grid>
      ))}

      <Grid item>
        <TransactionSection
          title={dictionary.transaction.transactionList.completedTransactions}
          headerText={dictionary.transaction.transactionList.numberOfActivityBasedTransactions(ActivityBasedTransactionStatus.Completed, activityBasedSubCategories.completed.count)}
          handleForceExpanded={forceExpandCompleted ? handleForceExpanded : undefined}
        >
          {activityBasedSubCategories.completed.subCategories.map((subCategory, index: number) => (
            <Fragment key={subCategory.id}>
              <TransactionGroup
                title={subCategory.title}
                description={subCategory.description}
                status={ActivityBasedTransactionStatus.Completed}
                category={subCategory}
                tags={tags}
                renderCheckBoxSelection={false}
                id={subCategory.slug === 'gas' ? CompletedSectionsIds.GAS : CompletedSectionsIds.ELECTRIC}
              />
              {(index !== activityBasedSubCategories.completed.subCategories.length - 1) && <Divider flexItem sx={{ mt: 5, mb: 5 }}/>}
            </Fragment>
          ))}
        </TransactionSection>
      </Grid>
      {activityBasedSubCategories.open.subCategories.length > 0 && (
        <Grid item>
          <TransactionSection
            title={dictionary.transaction.transactionList.openTransactions}
            headerText={dictionary.transaction.transactionList.numberOfActivityBasedTransactions(ActivityBasedTransactionStatus.Open, activityBasedSubCategories.open.count)}
          >
            {activityBasedSubCategories.open.subCategories.map((subCategory, index: number) => (
              <Fragment key={subCategory.id}>
                <TransactionGroup
                  title={subCategory.title}
                  description={subCategory.description}
                  status={ActivityBasedTransactionStatus.Open}
                  category={subCategory}
                  tags={tags}
                  renderCheckBoxSelection={false}
                />
                {(index !== activityBasedSubCategories.completed.subCategories.length - 1) && <Divider flexItem sx={{ mt: 5, mb: 5 }}/>}
              </Fragment>
            ))}
          </TransactionSection>
        </Grid>
      )}
      {activityBasedSubCategories.skipped.subCategories.length > 0 && (
        <Grid item>
          <TransactionSection
            title={dictionary.transaction.transactionList.skippedTransactions}
            headerText={dictionary.transaction.transactionList.numberOfActivityBasedTransactions(ActivityBasedTransactionStatus.Skipped, activityBasedSubCategories.skipped.count)}
          >
            {activityBasedSubCategories.skipped.subCategories.map((subCategory, index: number) => (
              <Fragment key={subCategory.id}>
                <TransactionGroup
                  title={subCategory.title}
                  description={subCategory.description}
                  status={ActivityBasedTransactionStatus.Skipped}
                  category={subCategory}
                  tags={tags}
                  renderCheckBoxSelection={false}
                />
                {(index !== activityBasedSubCategories.completed.subCategories.length - 1) && <Divider flexItem sx={{ mt: 5, mb: 5 }}/>}
              </Fragment>
            ))}
          </TransactionSection>
        </Grid>
      )}
    </Grid>
  );
};
