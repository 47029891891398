import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';


export const CoolsetLogoLoader = () => (
  <Box className="coolset-logo-loader">
    <div/>
    <div/>
    <div/>
  </Box>
);

export const CoolsetPageLoader: React.FC<CoolsetPageLoaderProps> = (props) => {
  const { text, isOpen = true, animationSetting = {} } = props;

  const animationTime = React.useMemo<number>(() => animationSetting.animationTime || 300, [animationSetting]);
  const animationDelay = React.useMemo<number>(() => animationSetting.animationDelay || 1000, [animationSetting]);

  const [transition, setTransition] = React.useState<string>(
    animationSetting.fadeInOut ? `opacity ${animationTime}ms` : 'none',
  );
  const [canClose, setCanClose] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!canClose || !animationSetting.fadeInOut) {
      return;
    }

    if (isOpen) {
      setCanClose(false);
      setTimeout(() => {
        setTransition(`opacity ${animationTime}ms, transform 0ms linear ${animationTime}ms`);
        setCanClose(true);
      }, animationTime + animationDelay);
    }
    else if (!isOpen) {
      setTimeout(() => {
        setTransition(`opacity ${animationTime}ms`);
      }, animationTime);
    }
  }, [animationSetting, isOpen, canClose, animationTime, animationDelay]);

  const containerClass = React.useMemo<string>(() => {
    const classes = ['_d-flex-dir-col-ali-center-jc-center-md-gap', 'coolset-page-loader'];
    if (isOpen || !canClose) {
      classes.push('is-open');
    }

    return classes.join(' ');
  }, [isOpen, canClose]);

  return (
    <Box
      className={containerClass}
      sx={{ '--loader-transition': transition }}
    >
      <CoolsetLogoLoader/>
      {text && (
        <Typography variant="body1">{text}</Typography>
      )}
    </Box>
  );
};

interface CoolsetPageLoaderProps {
  isOpen?: boolean;
  text?: React.ReactNode;
  animationSetting?: {
    fadeInOut?: boolean;
    animationTime?: number;
    animationDelay?: number;
  };
}
