import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { csvParser } from 'modules/Expenses/helpers/csvParse';
import { PREVIEW_ROW_LIMIT } from 'modules/Expenses/helpers/integrationOptions';
import { LocalizationContext } from 'contexts';
import { camelizeObject, snakizeObject } from 'helpers/object';
import { IFileUpload } from 'shared/components/layout/Dropzone/dropInterfaces';


export const UploadCsvSteps: { [key: string]: string } = {
  UPLOAD_FILE: 'uploadFile',
  PREVIEW: 'preview',
  SELECT_COLS: 'selectColumns',
  UPLOAD_DONE: 'uploadDone',
};

export const useUploadCsvWizard = (props: UseUploadCsvWizardProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const { columnOptions, uploadFileStepProps } = props;

  const [wizardSteps, setWizardSteps] = useState<{ [key: string]: string }>(UploadCsvSteps);
  const [wizardStep, setWizardStep] = useState<string>(wizardSteps.UPLOAD_FILE);
  const [isUploadCsvDialogOpen, setIsUploadCsvDialogOpen] = useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState<IFileUpload | null>(null);
  const [fileText, setFileText] = useState<string | null>(null);
  const [fileColumnsPreview, setFileColumnsPreview] = useState<CsvColumn[]>([]);
  const [rowsNumPreview, setRowsNumPreview] = useState<number>(0);
  const [isFirstRowHeaders, setIsFirstRowHeaders] = useState<boolean>(true);
  const [headerIndexes, setHeaderIndexes] = useState<{ [header: string]: number }>({});
  const [loaderText, setLoaderText] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReviewConfirmOpen, setIsReviewConfirmOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<CsvUploadErrors | null>(null);
  const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(false);
  const isCanUpload = useMemo(() =>
    !isUploadDisabled && columnOptions.every((option) => option.isOptional || headerIndexes[option.key] !== undefined),
  [columnOptions, isUploadDisabled, headerIndexes],
  );

  const nextStep = useCallback(() => {
    const stepIdx = Object.keys(wizardSteps).findIndex((key) => wizardSteps[key] === wizardStep);
    setWizardStep(wizardSteps[Object.keys(wizardSteps)[stepIdx + 1]]);
  }, [wizardStep, wizardSteps]);

  const previousStep = useCallback(() => {
    const stepIdx = Object.keys(wizardSteps).findIndex((key) => wizardSteps[key] === wizardStep);
    setWizardStep(wizardSteps[Object.keys(wizardSteps)[Math.max(stepIdx - 1, 0)]]);
  }, [wizardStep, wizardSteps]);

  const handleOnClose = useCallback((stepToSet?: string) => {
    setIsUploadCsvDialogOpen(false);

    setTimeout(() => {
      if (stepToSet !== undefined) {
        setWizardStep(stepToSet);
      } else if ( [UploadCsvSteps.UPLOAD_DONE].includes(wizardStep) ) {
        setWizardStep(wizardSteps.UPLOAD_FILE);
      }
    }, 500);
  },
  [setIsUploadCsvDialogOpen, wizardStep, wizardSteps],
  );

  const handleUploadDiffFile = useCallback(() => {
    setWizardStep(wizardSteps.UPLOAD_FILE);
    setFileUploaded(null);
    setFileText(null);
    setFileColumnsPreview([]);
    setHeaderIndexes({});
    setErrors(null);
  }, [setErrors, setFileColumnsPreview, setFileText, setFileUploaded, setHeaderIndexes, wizardSteps]);

  const onHandleSendFile = useCallback( async (
    handleNextStep: () => void = nextStep,
    handleSendFile: (rows: any[]) => Promise<void> = uploadFileStepProps?.handleSendFile || (() => Promise.resolve()),
    onError?: (error: any, setErrors: React.Dispatch<React.SetStateAction<CsvUploadErrors | null>>) => void,
  ) => {
    try {
      setIsLoading(true);
      setLoaderText(dictionary.expenses.uploadIntegration.uploading);
      setErrors(null);

      const rows = csvParser.getCsvRowsWithHeaders(fileText!, snakizeObject(headerIndexes), isFirstRowHeaders);

      await handleSendFile(rows);

      if (uploadFileStepProps?.closeOnSuccess) {
        handleOnClose();
        handleUploadDiffFile();
      } else {
        handleNextStep();
      }
    } catch (error: any) {
      if (onError) {
        onError(error, setErrors);
      } else {
        setWizardStep(wizardSteps.SELECT_COLS);
        setIsUploadDisabled(true);
        const errors = error.response.status === 400 ? error.response.data : { generic: { error: dictionary.somethingWentWrong } };
        setErrors(camelizeObject(errors));
      }
    }
    finally {
      setIsLoading(false);
    }
  },
  [nextStep, uploadFileStepProps, dictionary, fileText, headerIndexes, isFirstRowHeaders, wizardSteps, handleOnClose, handleUploadDiffFile],
  );

  const handleIsFirstRowHeadersChange = useCallback(
    (value: boolean) => {
      setIsFirstRowHeaders(value);
      const [previewCols, rowsNum] = csvParser.csvToColumns(fileText!, value, PREVIEW_ROW_LIMIT);
      setFileColumnsPreview(previewCols);
      setRowsNumPreview(rowsNum);
    },
    [fileText, setFileColumnsPreview, setIsFirstRowHeaders, setRowsNumPreview],
  );

  useEffect(() => setIsUploadDisabled(false), [headerIndexes, setIsUploadDisabled]);

  useEffect(() => {
    if (wizardStep !== UploadCsvSteps.SELECT_COLS) { setIsUploadDisabled(false); }
  }, [setIsUploadDisabled, wizardStep]);

  const wizardStepActions = useMemo<{ [step: string]: WizardStepAction }>(
    () => ({
      [UploadCsvSteps.UPLOAD_FILE]: {
        forward: { title: dictionary.expenses.uploadIntegration.forwardButton, isDisabled: !fileColumnsPreview.length },
      },
      [UploadCsvSteps.PREVIEW]: {
        back: { title: dictionary.expenses.uploadIntegration.backButton },
        forward: { title: dictionary.expenses.uploadIntegration.forwardButton },
      },
      [UploadCsvSteps.SELECT_COLS]: {
        back: {
          title: errors
            ? dictionary.expenses.uploadIntegration.selectColumns.backButton
            : dictionary.expenses.uploadIntegration.backButton,
          onClick: errors ? handleUploadDiffFile : null,
        },
        forward: !!fileColumnsPreview.length
          ? {
            title: dictionary.expenses.uploadIntegration.selectColumns.forwardButton,
            onClick: () => onHandleSendFile(),
            isDisabled: !isCanUpload,
          }
          : undefined,
      },
      [UploadCsvSteps.UPLOAD_DONE]: {
        forward: { title: dictionary.expenses.uploadIntegration.uploadDone.review },
      },
    }),
    [dictionary, fileColumnsPreview.length, errors, handleUploadDiffFile, onHandleSendFile, isCanUpload],
  );

  return {
    wizardSteps,
    setWizardSteps,
    wizardStep,
    setWizardStep,
    isUploadCsvDialogOpen,
    setIsUploadCsvDialogOpen,
    fileUploaded,
    setFileUploaded,
    fileText,
    setFileText,
    fileColumnsPreview,
    setFileColumnsPreview,
    rowsNumPreview,
    setRowsNumPreview,
    isFirstRowHeaders,
    setIsFirstRowHeaders,
    headerIndexes,
    setHeaderIndexes,
    loaderText,
    setLoaderText,
    isLoading,
    setIsLoading,
    isReviewConfirmOpen,
    setIsReviewConfirmOpen,
    errors,
    setErrors,
    isUploadDisabled,
    setIsUploadDisabled,
    isCanUpload,
    previousStep,
    nextStep,
    handleOnClose,
    handleUploadDiffFile,
    onHandleSendFile,
    handleIsFirstRowHeadersChange,
    wizardStepActions,
    columnOptions,
    uploadFileStepProps,
  };
};

export interface UseUploadCsvWizardProps {
  columnOptions: CsvColumnOption[];
  uploadFileStepProps?: {
    handleSendFile?: (rows: any[]) => Promise<void>;
    templateUrl: string;
    closeOnSuccess?: boolean;
  };
};

export interface CsvColumnOption {
  key: string;
  title: string;
  isOptional?: boolean;
  helpText?: string;
}

type CsvColumn = {
  title: string;
  column: string[];
};

type CsvUploadErrors = {
  [key: string]: {
    error: string;
    rows: number[];
  };
};

export interface WizardStepAction {
  back?: StepAction;
  forward?: StepAction;
}

type StepAction = {
  onClick?: ((e: any) => void) | null;
  title: string;
  isDisabled?: boolean;
};
