import React, { } from 'react';
import { UseFormProps } from 'react-hook-form';
import { GridProps, InputBaseProps } from '@mui/material';
import * as Yup from 'yup';

import { Conditions } from 'views/LoggedIn/Employees/Employees.types';



export enum FormComponentTypes {
    textfield = 'textfield',
    slider = 'slider',
    radio = 'radio',
    header = 'header',
  }

export type SliderLabelColors = Record<string, string>;

export type Option = {
    label: string;
    value: string;
  };

export type Options = {[key: string]: any, value: string}[];

export type DefaultFormValues = {
  [key: string]: any
}

export interface FormInputObject {
    placeholder?: string;
    options?: Options;
    dependentOn?: {frontendConditions: Conditions};
    highlightedLegendColors?: SliderLabelColors;
    legendColors?: SliderLabelColors;
    legendLabel?: string;
    tooltip?: string;
    direction?: string;
    min?:string;
    max?:string;
    step?: string;
    type?: string;
    sliderCircleColor?: string;
    name: string;
    gridProps?: GridProps;
    label?: string;
    disableSwap?: boolean;
    AfterTextField?: React.ReactNode;
    component?: FormComponentTypes;
    order?: number;
    inputProps?: InputBaseProps['inputProps'];
    autoComplete?: string;
  }

export interface IFormProps {
    serverValidation?: { errorText?: string; successText?: string };
    inputList: FormInputObject[];
    formOptions?: UseFormProps;
    onSubmit: ((params: any) => Promise<unknown>) | ((params: any) => void);
    buttonLabel?: string;
    validationSchema?: Yup.AnySchema;
    containerProps?: GridProps;
    submitButton?: React.ReactNode;
    className?: string,
  }

export interface IServerValidation {
    successText?: string | undefined;
    errorText?: string | undefined;
  }

export interface IRadioButtonProps {
    onChange?: (...event: any[]) => void;
    options: Options;
    direction?: string;
    errorMessage?: string,
  }