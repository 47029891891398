import { CamelToSnakeCase, Maybe, MaybeNull } from 'types';
import { GetListParams } from 'types/Query';
import { CompanyApi } from 'clients/companies/companyClient.types';
import {
  EmissionFactor,
  EmissionFactorApi,
  Scope,
} from 'clients/emissionFactors/emissionFactorsClient.types';
import { CompanyTag, CompanyTagApi } from 'clients/companyTags/companyTagClient.types';


export enum Source {
  Manual = 'manual',
  Auto = 'auto',
}

export enum TransactionType {
  Income = 'income',
  Expense = 'expense',
}

export enum TransactionStatus {
  New = 'new',
  Classified = 'classified',
}

export enum EmissionClassificationStatus {
  ALL = 'all',
  CLASSIFIED = 'classified',
  NON_CLASSIFIED = 'non-classified',
}

export enum EmissionClassificationConfidenceStatus {
  CONFIDENT = 'confident',
  NEEDS_ATTENTION = 'needsAttention',
}

export enum EmissionClassificationConfidenceStatusPercentage {
  CONFIDENT = 0.8,
}

export enum TransactionManualInputType {
  Dropdown = 'dropdown',
  Value = 'value',
}

export enum DateAggregation {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export type TransactionEmissionClassification = {
  id: number;
  transaction: number;
  emissionFactor: MaybeNull<EmissionFactor>;
  confidence: number;
  source: Source;
  autoClassificationVersion: string;
  co2Kg: number;
}
export type TransactionEmissionClassificationApi = CamelToSnakeCase<Omit<TransactionEmissionClassification, 'emissionFactor'>> & {
  emission_factor: EmissionFactorApi;
}

export type TransactionManualInput = {
  id: number;
  label: string;
  order: number;
  type: TransactionManualInputType;
  unit: string;
  answer: MaybeNull<TransactionManualInputAnswer>;
};

export type TransactionManualInputApi = {
  id: number;
  label: string;
  order: number;
  type: TransactionManualInputType;
  unit: string;
  answer: MaybeNull<TransactionManualInputAnswerApi>;
};

export type TransactionManualInputAnswerApi = {
  manual_input_id: number;
  emission_factor_title: string;
  value: MaybeNull<string | number>;
};

export type TransactionManualInputAnswer = {
  manualInputId: number;
  emissionFactorTitle: string;
  value: string | number | null;
};

export type ManualInputPayloadApi = {
  transaction_id: number;
  manual_input_id: number;
  emission_factor_id?: number;
  value?: string | number;
};

export type ManualInputPayload = {
  transactionId: number;
  manualInputId: number;
  emissionFactorId?: number;
  value?: string | number;
};

export type ManualInputTransactionUpdatePayload = {
  transactionId: number;
};
export type ManualInputTransactionUpdatePayloadApi = CamelToSnakeCase<ManualInputTransactionUpdatePayload>

export type IntegrationSource = {
  id: number;
  type: string;
  name: string;
  created_by: string;
}

export type TransactionApi = {
  id: number;
  company: CompanyApi;
  type: TransactionType;
  vat_rate: number;
  net_amount: number;
  gross_amount: number;
  currency: string;
  vendor: string;
  vendor_name: string;
  date: string;
  end_date: MaybeNull<string>;
  ledger: string;
  assignee: string;
  category: string;
  status: TransactionStatus;
  description: string;
  transaction_data: Record<string, any>
  external_id: string;
  integration: IntegrationSource;
  emission_classification: TransactionEmissionClassificationApi;
  manual_inputs: TransactionManualInputApi[];
  tags: CompanyTagApi[];
}

export type TransactionStatistic = {
  id?: string;
  vendorName?: string;
  ledger?: string;
  count: number;
  totalAmount: number;
  currency: string;
}

export type TransactionStatisticApi = CamelToSnakeCase<TransactionStatistic>;

export type TransactionWordAnalysis = {
  id: string;
  word: string;
  count: number;
  totalAmount: number;
}

export type TransactionWordAnalysisData = {
  count: number,
  results: TransactionWordAnalysis[]
}

export type TransactionWordAnalysisApi = CamelToSnakeCase<TransactionWordAnalysis>;
export type TransactionWordAnalysisDataApi = CamelToSnakeCase<TransactionWordAnalysisData>;

export type Transaction = {
  id: number;
  type: TransactionType;
  vatRate: number;
  netAmount: number;
  grossAmount: number;
  currency: string;
  vendor: string;
  vendorName: string;
  date: Date;
  endDate: MaybeNull<Date>;
  ledger: string;
  assignee: string;
  category: string;
  status: TransactionStatus;
  description: string;
  transactionData: Record<string, any>
  externalId: string;
  integration: IntegrationSource;
  emissionClassification: MaybeNull<TransactionEmissionClassification>;
  manualInputs?: TransactionManualInput[],
  tags: CompanyTag[],
}

export type CustomFilter = {
  column: string;
  operator: string;
  value?: string;
}

export type GetTransactionsParams = GetListParams<{
  status: TransactionStatus;
  scope: Scope;
  tags?: string[];
  dateLte: Date;
  dateGte: Date;
  manualInput?: ActivityBasedTransactionStatus;
  subCategoryId?: number;
  emissionFactorId?: number;
  categoryId?: number;
  filters: CustomFilter[];
  ordering: string;
  page: number;
  pageSize: number;
  confidence: string;
}>;

export type UpdateManualInputTransactionsParams = {
  transactionIds?: ManualInputTransactionUpdatePayload[] | [];
  subCategoryId?: number;
  dateGte?: Date;
};
export type UpdateManualInputTransactionsParamsApi = Omit<CamelToSnakeCase<UpdateManualInputTransactionsParams>, 'date_gte' | 'transaction_ids'> & {
  date_gte: Maybe<string>;
}

export type RevertManualInputTransactionsParams = UpdateManualInputTransactionsParams & {
  skipped: boolean;
};
export type RevertManualInputTransactionsParamsApi = UpdateManualInputTransactionsParamsApi & {
  skipped: boolean;
}

export type GetActivityBasedSubcategoriesParams = {
  mandatory?: boolean;
  tags?: string[];
}

export type GetTransactionsParamsApi = Omit<CamelToSnakeCase<GetTransactionsParams>, 'date_lte' | 'date_gte'> & {
  date_lte: Maybe<string>;
  date_gte: Maybe<string>;
}

export type GetTransactionsStatisticsParams = GetTransactionsParams & GetListParams<{
  groupBy: string;
}>;

export type GetTransactionsExportParams = GetListParams<{
  status: TransactionStatus;
  transactionEmissionClassificationScope?: string;
  dateLte: Date;
  dateGte: Date;
  tags?: string[];
  search: string;
  ordering: string;
  filters: CustomFilter[];
}>;

export enum ActivityBasedTransactionStatus {
  Open = 'open',
  Completed = 'completed',
  Skipped = 'skipped',
}

export type TransactionSubCategoryApi = {
  id: number;
  slug: string;
  title: string;
  description: MaybeNull<string>;
  color: string;
  icon: MaybeNull<string>;
  parent: number;
}

export type TransactionSubCategory = {
  id: number;
  slug: string;
  title: string;
  description: MaybeNull<string>;
  color: string;
  icon: MaybeNull<string>;
  parent: number;
}

export type ActivityBasedSubCategoryApi = {
  count: number;
  sub_categories: TransactionSubCategoryApi[]
}

export type ActivityBasedSubCategory = {
  count: number;
  subCategories: TransactionSubCategory[]
}

export type ActivityBasedSubCategoriesApi = Record<ActivityBasedTransactionStatus, ActivityBasedSubCategoryApi>
export type ActivityBasedSubCategories = Record<ActivityBasedTransactionStatus, ActivityBasedSubCategory>

export type BulkClassifyTransaction = {
  emissionFactorId: number;
  transactionId: number;
}
export type BulkClassifyTransactionApi = CamelToSnakeCase<BulkClassifyTransaction>

export type BulkClassifyConfidenceTransactions = {
  source: string;
  transactionIds: number[];
};
export type BulkClassifyConfidenceTransactionsFiltered = GetTransactionsParams & {
  source: string;
};
