import React, { ReactElement } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import {
  CsvAnalysisType,
  CsvColumnOptions,
  UploadIntegrationCsvSteps,
  IntegrationSteps,
  CleaningSteps,
} from 'modules/Expenses/helpers/integrationOptions';
import { isEmpty } from 'lodash';
import { useUploadIntegrationWizard } from 'modules/Expenses/hooks/useUploadIntegrationWizard';
import { LocalizationContext } from 'contexts';
import { AsyncProcessType } from 'contexts/WaitingAsyncProcessContext/types';
import { PreviewDataStep } from 'shared/components/dialog/UploadCsvWizard/Steps/PreviewDataStep';
import { CoolsetPageLoader } from 'shared/components/layout/CoolsetLoader/CoolsetLoader';
import { UploadDoneStep } from 'shared/components/dialog/UploadCsvWizard/Steps/UploadDoneStep';
import { SelectColumnsStep } from 'shared/components/dialog/UploadCsvWizard/Steps/SelectColumnsStep';
import { UploadFileStep } from 'shared/components/dialog/UploadCsvWizard/Steps/UploadFileStep';
import { JournalAndLedgerAnalysisStep } from './UploadIntegrationWizardSteps/JournalAndLedgerAnalysisStep';
import { ReviewDoneStep } from './UploadIntegrationWizardSteps/ReviewDoneStep';
import { SelectIntegrationStep } from './UploadIntegrationWizardSteps/SelectIntegrationStep';
import { FileOrIntegrationName } from './FileOrIntegrationName';
import { PermissionsStep } from './UploadIntegrationWizardSteps/PermissionsStep';
import { SyncDoneStep } from './UploadIntegrationWizardSteps/SyncDoneStep';
import { AsyncLoaderStep } from './UploadIntegrationWizardSteps/AsyncLoaderStep';
import { IntroductionStep } from './UploadIntegrationWizardSteps/IntroductionStep';


export const CsvColOptionOrder = CsvColumnOptions().map((opt) => opt.key);

export const UploadIntegrationWizard = (props: UploadIntegrationWizardProps) => {
  const {
    uploadCsvWizardStore: {
      isUploadCsvDialogOpen: isOpen,
      wizardStep,
      fileUploaded,
      setFileUploaded,
      setFileText,
      fileColumnsPreview,
      setFileColumnsPreview,
      rowsNumPreview,
      setRowsNumPreview,
      isFirstRowHeaders,
      headerIndexes,
      setHeaderIndexes,
      loaderText,
      isLoading,
      isReviewConfirmOpen,
      setIsReviewConfirmOpen,
      errors,
      ledgerAndJournalCleaning,
      setLedgerAndJournalCleaning,
      setIntegrationName,
      integrationName,
      isLoadingAnalysisList,
      isNoJournals,
      isUpdateCleaning,
      analysisList,
      handleIsFirstRowHeadersChange,
      handleOnClose,
      handleReviewStepDone,
      wizardStepActions,
      nextStep,
      previousStep,
      setToUpload,
      selectIntegrationType,
      chiftIntegrationOptions,
      isLoadingOptions,
      isCheckingConnection,
      columnOptions,
      uploadFileStepProps,
    },
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);

  const isShowJustStepComponent = [
    UploadIntegrationCsvSteps.UPLOAD_DONE,
    UploadIntegrationCsvSteps.ASYNC_LOADER,
    IntegrationSteps.ASYNC_LOADER,
    IntegrationSteps.SYNC_DONE,
    CleaningSteps.REVIEW_DONE,
  ].includes(wizardStep);

  const fileName = integrationName || fileUploaded?.name;
  const isReviewErrors = wizardStep === UploadIntegrationCsvSteps.SELECT_COLS && !fileColumnsPreview.length;

  const wizardStepComponent = React.useMemo<ReactElement>(() => {
    switch (wizardStep) {
    case UploadIntegrationCsvSteps.UPLOAD_FILE:
      return (
        <UploadFileStep
          fileUploaded={fileUploaded}
          isFirstRowHeaders={isFirstRowHeaders}
          setFileText={setFileText}
          setFileColumnsPreview={setFileColumnsPreview}
          setRowsNumPreview={setRowsNumPreview}
          setFileUploaded={setFileUploaded}
          templateUrl={uploadFileStepProps?.templateUrl || ''}
        />
      );
    case UploadIntegrationCsvSteps.PREVIEW:
      return (
        <PreviewDataStep
          previewColumns={fileColumnsPreview}
          isFirstRowHeaders={isFirstRowHeaders}
          setIsFirstRowHeaders={handleIsFirstRowHeadersChange}
          rowsNum={rowsNumPreview}
        />
      );
    case UploadIntegrationCsvSteps.SELECT_COLS:
      return (
        <SelectColumnsStep
          previewColumns={fileColumnsPreview}
          headerIndexes={headerIndexes}
          setHeaderIndexes={setHeaderIndexes}
          errors={errors}
          isFirstRowHeaders={isFirstRowHeaders}
          columnOptions={columnOptions}
        />
      );
    case UploadIntegrationCsvSteps.ASYNC_LOADER:
      return (
        <AsyncLoaderStep onClose={() => handleOnClose()}/>
      );
    case UploadIntegrationCsvSteps.UPLOAD_DONE:
      return (
        <UploadDoneStep
          onClose={() => handleOnClose()}
          onNextStep={nextStep}
        />
      );
    case IntegrationSteps.INTRO:
      return <IntroductionStep weNeedList={dictionary.expenses.uploadIntegration.introSync.weNeedList}/>;
    case IntegrationSteps.SELECT_ACC:
      return (
        <SelectIntegrationStep
          switchToUploadCsv={() => {
            setIntegrationName(null);
            setToUpload(true);
          }}
          chiftIntegrationOptions={chiftIntegrationOptions}
          isLoading={isLoadingOptions}
          selectIntegrationType={selectIntegrationType}
        />);
    case IntegrationSteps.PERMISSIONS:
      return <PermissionsStep/>;
    case IntegrationSteps.ASYNC_LOADER:
      return (
        <AsyncLoaderStep
          onClose={() => handleOnClose()}
          title={isCheckingConnection ? (
            dictionary.expenses.uploadIntegration.asyncLoading.checkConnection
          ) : (
            dictionary.expenses.uploadIntegration.asyncLoading.importingTitle
          )}
          processType={AsyncProcessType.ImportingTransactions}
        />
      );
    case IntegrationSteps.SYNC_DONE:
      return (
        <SyncDoneStep
          integrationName={integrationName!}
          onClose={() => handleOnClose()}
          onNextStep={nextStep}
        />
      );
    case CleaningSteps.JOURNAL:
    case CleaningSteps.LEDGER:
      return (
        <JournalAndLedgerAnalysisStep
          analysisType={CsvAnalysisType[wizardStep]}
          analysisList={analysisList}
          ledgerAndJournalCleaning={ledgerAndJournalCleaning}
          setLedgerAndJournalCleaning={setLedgerAndJournalCleaning}
          isReviewConfirmOpen={isReviewConfirmOpen}
          setIsReviewConfirmOpen={setIsReviewConfirmOpen}
          handleReviewStepDone={handleReviewStepDone}
          isLoading={isLoadingAnalysisList}
          isNoJournals={isNoJournals}
          isUpdateCleaning={isUpdateCleaning}
        />
      );
    case CleaningSteps.REVIEW_DONE:
      return <ReviewDoneStep onClose={() => handleOnClose()}/>;
    default:
      return <>something went wrong</>;
    }
  }, [
    dictionary,
    wizardStep,
    fileUploaded,
    isFirstRowHeaders,
    setFileText,
    setFileColumnsPreview,
    setRowsNumPreview,
    setFileUploaded,
    fileColumnsPreview,
    handleIsFirstRowHeadersChange,
    rowsNumPreview,
    headerIndexes,
    setHeaderIndexes,
    errors,
    ledgerAndJournalCleaning,
    setLedgerAndJournalCleaning,
    handleOnClose,
    nextStep,
    isReviewConfirmOpen,
    setIsReviewConfirmOpen,
    handleReviewStepDone,
    isLoadingAnalysisList,
    isNoJournals,
    isUpdateCleaning,
    analysisList,
    setToUpload,
    selectIntegrationType,
    setIntegrationName,
    isLoadingOptions,
    chiftIntegrationOptions,
    integrationName,
    isCheckingConnection,
    columnOptions,
    uploadFileStepProps,
  ]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleOnClose()}
      PaperProps={{
        sx: {
          minWidth: 'min(800px, 80vw)',
          minHeight: [UploadIntegrationCsvSteps.INTRO, IntegrationSteps.INTRO].includes(wizardStep) ? 'unset' : 'min(680px, 80vh)',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          position: 'relative',
          borderRadius: 1,
          py: 5,
          '&:has(.hide-dialog-file-name) .dialog-file-name': {
            display: 'none',
          },
        },
      }}
    >
      <CoolsetPageLoader
        isOpen={isLoading}
        text={loaderText}
        animationSetting={{ fadeInOut: true }}
      />

      {!isShowJustStepComponent ? (
        <Box px={5}>
          <FileOrIntegrationName fileName={fileName}/>

          <Typography variant="h1" mb={1}>
            {isReviewErrors ? dictionary.expenses.uploadIntegration.stepTitle.error : dictionary.expenses.uploadIntegration.stepTitle[wizardStep]}
          </Typography>
          {dictionary.expenses.uploadIntegration.stepSubTitles[wizardStep] && (
            <Typography variant="body1" mb={5}>
              {isReviewErrors ? dictionary.expenses.uploadIntegration.stepSubTitles.error : dictionary.expenses.uploadIntegration.stepSubTitles[wizardStep]}
            </Typography>
          )}
        </Box>
      ) : <div/>}

      <Box overflow="auto" px={5}>
        {wizardStepComponent}
      </Box>

      {!isShowJustStepComponent && !isEmpty(wizardStepActions[wizardStep]) ? (
        <Box className="_d-flex-ali-center-jc-sb-md-gap" px={5} pt={5}>
          <Typography variant="body1">
            {/* TODO - get help link with video */}
          </Typography>
          <Box className="_d-flex-ali-center-md-gap">
            {wizardStepActions[wizardStep]?.back && (
              <Button
                onClick={wizardStepActions[wizardStep].back?.onClick || previousStep}
                color="info"
              >
                {wizardStepActions[wizardStep].back?.title}
              </Button>
            )}
            {wizardStepActions[wizardStep].forward && (
              <Button
                onClick={wizardStepActions[wizardStep].forward?.onClick || nextStep}
                disabled={wizardStepActions[wizardStep].forward?.isDisabled}
              >
                {wizardStepActions[wizardStep].forward?.title}
              </Button>
            )}
          </Box>
        </Box>
      ) : <div/>}
    </Dialog>
  );
};

interface UploadIntegrationWizardProps {
    uploadCsvWizardStore: ReturnType<typeof useUploadIntegrationWizard>;
}