import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { greyTextColor } from 'shared/styles/muiTheme';


export const SwitchLoggedOutPage: FC<{
  text: string;
  linkText: string;
  linkTo: string;
}> = ({ text, linkText, linkTo }) => {
  return (
    <Box mt={4}>
      <Typography
        align="center"
        color={greyTextColor}
        fontSize={13}
        variant="body2"
      >
        {text}
        <Typography
          sx={theme => ({ borderBottom: `2px solid ${theme.palette.primary.main}` })}
          variant="buttonText"
          ml={1}
          component={RouterLink}
          to={linkTo}
        >
          {linkText}
        </Typography>
      </Typography>
    </Box>
  );
};
