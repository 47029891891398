import React from 'react';
import { Button } from '@mui/material';
import { WizardDoneStep } from 'modules/Expenses/components/WizardDoneStep';
import { LocalizationContext } from 'contexts';


export const UploadDoneStep = (props:
  {onClose: () => void; onNextStep: () => void},
) => {
  const { onClose, onNextStep } = props;
  const { dictionary } = React.useContext(LocalizationContext);

  return (
    <WizardDoneStep
      title={dictionary.expenses.uploadIntegration.uploadDone.title}
      subTitle={dictionary.expenses.uploadIntegration.uploadDone.subTitle}
      actions={(
        <React.Fragment>
          <Button
            color="secondary"
            onClick={onClose}
          >
            {dictionary.close}
          </Button>
          <Button onClick={onNextStep}>
            {dictionary.expenses.uploadIntegration.uploadDone.review}
          </Button>
        </React.Fragment>
      )}
      stepsCompleted={1}
    />
  );
};