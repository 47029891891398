import React from 'react';
import { Button } from '@mui/material';
import { WizardDoneStep } from 'modules/Expenses/components/WizardDoneStep';
import { LocalizationContext } from 'contexts';


export const ReviewDoneStep = ({ onClose }: {onClose: () => void}) => {
  const { dictionary } = React.useContext(LocalizationContext);

  return (
    <WizardDoneStep
      title={dictionary.expenses.uploadIntegration.reviewDone.title}
      subTitle={dictionary.expenses.uploadIntegration.reviewDone.subTitle}
      actions={(
        <Button
          color="secondary"
          onClick={onClose}
        >
          {dictionary.expenses.uploadIntegration.reviewDone.finish}
        </Button>
      )}
      stepsCompleted={2}
    />
  );
};