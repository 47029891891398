import React from 'react';
import { IconContainer } from './IconContainer';


export const DocumentChartBarIcon = ({ ...props }) => (
  <IconContainer {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3.5C3 2.67157 3.67157 2 4.5 2H11.3787C11.7765 2 12.158 2.15804 12.4393 2.43934L16.5607 6.56066C16.842 6.84197 17 7.2235 17 7.62132V16.5C17 17.3284 16.3284 18 15.5 18H4.5C3.67157 18 3 17.3284 3 16.5V3.5ZM13.25 9C13.6642 9 14 9.33579 14 9.75V14.25C14 14.6642 13.6642 15 13.25 15C12.8358 15 12.5 14.6642 12.5 14.25V9.75C12.5 9.33579 12.8358 9 13.25 9ZM6.75 13C7.16421 13 7.5 13.3358 7.5 13.75V14.25C7.5 14.6642 7.16421 15 6.75 15C6.33579 15 6 14.6642 6 14.25V13.75C6 13.3358 6.33579 13 6.75 13ZM10.75 11.75C10.75 11.3358 10.4142 11 10 11C9.58579 11 9.25 11.3358 9.25 11.75V14.25C9.25 14.6642 9.58579 15 10 15C10.4142 15 10.75 14.6642 10.75 14.25V11.75Z"
      fill="currentColor"
    />
  </IconContainer>
);

export const DocumentChartBarOutlineIcon = ({ ...props }) => (
  <IconContainer viewBox="0 0 24 24" {...props}>
    <path
      d="M19.5 14.25V11.625C19.5 9.76104 17.989 8.25 16.125 8.25H14.625C14.0037 8.25 13.5 7.74632 13.5 7.125V5.625C13.5 3.76104 11.989 2.25 10.125 2.25H8.25M9 16.5V17.25M12 14.25V17.25M15 12V17.25M10.5 2.25H5.625C5.00368 2.25 4.5 2.75368 4.5 3.375V20.625C4.5 21.2463 5.00368 21.75 5.625 21.75H18.375C18.9963 21.75 19.5 21.2463 19.5 20.625V11.25C19.5 6.27944 15.4706 2.25 10.5 2.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconContainer>
);

export const DocumentIcon = ({ ...props }) => (
  <IconContainer {...props}>
    <path
      d="M3 3.5C3 2.67157 3.67157 2 4.5 2H11.3787C11.7765 2 12.158 2.15804 12.4393 2.43934L16.5607 6.56066C16.842 6.84197 17 7.2235 17 7.62132V16.5C17 17.3284 16.3284 18 15.5 18H4.5C3.67157 18 3 17.3284 3 16.5V3.5Z"
      fill="currentColor"
    />
  </IconContainer>
);

export const DocumentArrowDownIcon = ({ ...props }) => (
  <IconContainer {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 2C3.67157 2 3 2.67157 3 3.5V16.5C3 17.3284 3.67157 18 4.5 18H15.5C16.3284 18 17 17.3284 17 16.5V7.62132C17 7.2235 16.842 6.84197 16.5607 6.56066L12.4393 2.43934C12.158 2.15804 11.7765 2 11.3787 2H4.5ZM9.25 8.75C9.25 8.33579 9.58579 8 10 8C10.4142 8 10.75 8.33579 10.75 8.75V11.2955L11.6925 10.2483C11.9696 9.94039 12.4438 9.91543 12.7517 10.1925C13.0596 10.4696 13.0846 10.9438 12.8075 11.2517L10.5575 13.7517C10.4152 13.9098 10.2126 14 10 14C9.78739 14 9.58476 13.9098 9.44253 13.7517L7.19253 11.2517C6.91544 10.9438 6.94039 10.4696 7.24828 10.1925C7.55616 9.91544 8.03038 9.94039 8.30747 10.2483L9.25 11.2955V8.75Z"
      fill="currentColor"
    />
  </IconContainer>
);
