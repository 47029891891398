import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Box, Link, List, Typography } from '@mui/material';
import { routes } from 'config';
import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { useWorkspaces } from 'shared/hooks/useWorkspaces';
import { WorkspaceItem } from 'shared/components/Workspace/WorkspaceItem';
import { Logo } from 'shared/components/layout/Logo/Logo';
import { CoolsetLogoLoader } from 'shared/components/layout/CoolsetLoader/CoolsetLoader';


export const LeftWorkspace = () => {
  const { genericError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { isLoggedIn, logout, user } = useContext(UserContext);
  const { status, workspaces } = useWorkspaces();
  const message = useLocation().state as { message: string };
  const navigate = useNavigate();
  const [isLoading, setIsLoadng] = useState(false);

  const handleLogout = async () => {
    setIsLoadng(true);
    try {
      await logout();
      navigate(routes.login);
    } catch {
      genericError();
    } finally {
      setIsLoadng(false);
    }
  };

  useEffect(() => {
    if (!isLoggedIn || status === 'loading') return;
    if (workspaces.length === 0) {
      logout();
    }
  }, [isLoggedIn, logout, status, workspaces]);

  return (
    <Box className="_d-flex-dir-col-ali-center-jc-start-xxxl-gap" height="100vh" p={8} width="100vw">
      <Logo withBackground style={{ width: 120 }}/>
      <Box className="_d-flex-dir-col-ali-center-jc-start-md-gap">
        <Typography variant="h1">{message?.message || dictionary.workspace.leftWorkspace}</Typography>
        <Typography>{dictionary.workspace.leftWorkspaceSuccess}</Typography>
      </Box>
      {isLoading && <CoolsetLogoLoader/>}
      {!isLoading && isLoggedIn && workspaces.length > 0
        && (
          <Box className="_d-flex-dir-col-ali-center-jc-start-xl-gap">
            <Box className="_d-flex-dir-col-ali-center-jc-start-md-gap">
              <Typography variant="h1">{dictionary.workspace.yourWorkspaces}</Typography>
              <Typography sx={{ alignItems: 'center', display: 'flex', whiteSpace: 'pre-wrap' }}>
                {dictionary.workspace.signedInAs} <strong>{user?.email}</strong> <Link component="button" onClick={handleLogout}> {dictionary.workspace.change}</Link>
              </Typography>
            </Box>
            <List
              className="_d-flex-jc-center-f-wrap"
              sx={{
                gap: 2,
                maxHeight: '220px',
                maxWidth: '70vw',
                padding: 2,
                overflow: 'auto',
              }}
            >
              {workspaces.map(workspace => (
                <WorkspaceItem {...workspace} key={`${workspace.name}_workspace`}/>
              ))}
            </List>
            <Typography>{dictionary.workspace.cantFind} <Link onClick={handleLogout}><strong>{dictionary.workspace.differentEmail}</strong></Link></Typography>
          </Box>
        )}
      {!isLoading && !isLoggedIn && <Typography>{dictionary.workspace.closePage} <Link href="https://coolset.com">{dictionary.workspace.homepage}</Link></Typography>}
    </Box>
  );
};