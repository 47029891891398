import React, { createContext, FC, useContext, useEffect, useState } from 'react';

import { UserContext } from 'contexts/UserContext/UserContext';

import { ContextProps } from './types';


export const defaultContext: ContextProps = {
  company: null,
  selectCompany: () => {},
};


export const CompanyContext = createContext(defaultContext);

export const CompanyContextProvider: FC<React.PropsWithChildren> = ({ children }) => {

  const { user, isAdmin, isLoggedIn } = useContext(UserContext);
  const [company, setCompany] = useState(user?.company || null);

  useEffect(() => {
    if (isLoggedIn && user?.company) {
      setCompany(user?.company);
    } else {
      setCompany(null);
    }
  }, [isLoggedIn, isAdmin, user, setCompany]);

  return (
    <CompanyContext.Provider
      value={{
        company,
        selectCompany: setCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
