import React from 'react';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';


export const LoadingSkeleton = () => (
  <Box className="_d-grid-sm-gap" px={1}>
    <Skeleton variant="rounded" sx={{ width: '80%', height: 24, borderRadius: '32px' }}/>
    <Skeleton variant="rounded" sx={{ width: '60%', height: 24, borderRadius: '32px' }}/>
  </Box>
);