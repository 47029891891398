import React from 'react';
import { Box, Skeleton } from '@mui/material';


export const VendorCardsSkeleton = () => (
  <React.Fragment>
    {Array(4).fill(null).map((x, i) => (
      <CardSkeleton key={i}/>
    ))}
  </React.Fragment>
);

const CardSkeleton = () => (
  <Box className="_d-grid-lg-gap" sx={{ gridTemplateColumns: 'auto 1fr', maxWidth: 800 }}>
    <Skeleton variant="rectangular" sx={{ width: 64, height: 64, borderRadius: 4 }}/>
    <Box className="_d-grid">
      <Skeleton variant="rounded" sx={{ width: '100%', height: 24, borderRadius: '100vmax' }}/>
      <Skeleton variant="rounded" sx={{ width: '30%', height: 24, borderRadius: '100vmax' }}/>
    </Box>
  </Box>
);
