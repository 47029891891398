import React, { useContext, Fragment, useCallback, FC } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import {
  Alert,
  Button,
  Stack,
  Typography,
} from '@mui/material';

import { SxProps } from '@mui/system';
import { LightbulbIcon } from 'shared/icons/LightbulbIcon';
import { cacheKeys, routes } from 'config';
import { DialogContext, LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { transactionClient } from 'clients/transactions/transactionClient';
import { useActivityBasedCategories } from 'shared/hooks/useActivityBasedSubCategories';
import SkipTransactionsButton from 'shared/components/layout/Tooltip/SkipTransactionsButton';


interface Props {
  style?: SxProps
}

export const OpenTransactionsAlert: FC<Props> = ({ style = {} }) => {
  const queryClient = useQueryClient();
  const { isLoggedIn } = useContext(UserContext);
  const { genericInfo, genericError } = useContext(LayoutContext);
  const { asyncConfirmation } = useContext(DialogContext);
  const { dictionary } = useContext(LocalizationContext);
  const { activityBasedSubCategories, error } = useActivityBasedCategories({ mandatory: false });

  const skipAllOpenTransactions = useMutation(transactionClient.skipAllOpenTransactions, {
    mutationKey: cacheKeys.transactions.skipAllOpenTransactions,
    onSuccess: () => {
      genericInfo(dictionary.transaction.edit.transactionsSkipped);
      queryClient.invalidateQueries(cacheKeys.transactions.getTransactions);
      queryClient.invalidateQueries(cacheKeys.activityBasedSubCategories.getActivityBasedSubCategories);
    },
    onError: () => {
      genericError();
    },
  });

  const onSkip = useCallback(async () => {
    const userConfirmed = await asyncConfirmation({ title: dictionary.transaction.edit.skipAllTransactionsConfirmation });

    if (!userConfirmed) {
      return;
    }
    return skipAllOpenTransactions.mutateAsync();
  }, [asyncConfirmation, dictionary, skipAllOpenTransactions]);

  if (error || !activityBasedSubCategories) {
    return null;
  }

  return (
    <Fragment>
      {(isLoggedIn && !!activityBasedSubCategories.open.count) && (
        <Alert
          severity="info"
          icon={<LightbulbIcon/>}
          sx={{
            alignItems: 'center',
          }}
          action={
            <Stack spacing={0.5}>
              <Button component={Link} to={routes.activityBasedTransactionsOther}>
                {dictionary.alert.openTranslations.buttonCheckOpentranslations(activityBasedSubCategories.open.count)}
              </Button>
              <SkipTransactionsButton
                onClick={onSkip}
                toolTipTitle={dictionary.alert.openTranslations.tooltipSkipAllTransactions}
                btnLabel= {dictionary.alert.openTranslations.buttonSkipTransactions}
                tooltipContentBgDarker
              />
            </Stack>
          }
        >
          <Typography component="span">
            {dictionary.alert.openTranslations.bodyText(activityBasedSubCategories.open.count)}
          </Typography>
        </Alert>
      )}
    </Fragment>
  );
};
