import React from 'react';
import { IconContainer } from 'shared/icons/IconContainer';


export const ChartPieIcon = ({ ...props }) => (
  <IconContainer {...props} viewBox="0 0 16 16">
    <path
      d="M9.59961 7.20003C9.15778 7.20003 8.79961 6.84185 8.79961 6.40003V2.40003C8.79961 1.9582 9.15983 1.59402 9.59723 1.6564C12.0517 2.00641 13.9932 3.94793 14.3432 6.4024C14.4056 6.8398 14.0414 7.20003 13.5996 7.20003H9.59961Z"
      fill="currentColor"
    />
    <path
      d="M6.40198 3.2564C6.83939 3.19402 7.19961 3.5582 7.19961 4.00003V8.00003C7.19961 8.44185 7.55778 8.80003 7.99961 8.80003H11.9996C12.4414 8.80003 12.8056 9.16025 12.7432 9.59765C12.3561 12.3126 10.0216 14.4 7.19961 14.4C4.10681 14.4 1.59961 11.8928 1.59961 8.80003C1.59961 5.97803 3.68699 3.64356 6.40198 3.2564Z"
      fill="currentColor"
    />
  </IconContainer>
);
