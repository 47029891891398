import React, { FC, useCallback } from 'react';
import { Box } from '@mui/material';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { DateRange } from '@mui/x-date-pickers-pro';

import { DatePicker } from 'shared/components/interactive/DatePicker/DatePicker';


export type Props = {
  readonly item: GridFilterItem;
  readonly applyValue: (item: GridFilterItem) => void;
  readonly initialValue?: Date | null;
  readonly yearLimit?: Date | null;
};

export const DateFilter: FC<Props> = ({
  item,
  applyValue,
  initialValue,
  yearLimit,
}) => {
  const handleChange = useCallback((value: string | DateRange<string> | null) => {
    applyValue({
      ...item,
      value: value,
    });
  }, [item, applyValue]);

  return (
    <Box>
      <DatePicker onChange={handleChange} value={item.value || initialValue} yearLimit={yearLimit}/>
    </Box>
  );
};
