// ** MUI Imports
import { Theme } from '@mui/material/styles';


const Dialog = (theme: Theme) => {
  return {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
          },
        },
      },
    },
  };
};

export default Dialog;
