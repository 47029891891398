import React from 'react';
import { IconContainer } from './IconContainer';


export const ActivateIcon = ({ ...props }) => (
  <IconContainer viewBox="0 0 16 16" {...props}>
    <path
      d="M5.33337 7.33268H10.6667V8.66602H5.33337V7.33268ZM13.4 7.99935H14.6667C14.6667 6.15935 13.1734 4.66602 11.3334 4.66602H8.66671V5.93268H11.3334C12.4734 5.93268 13.4 6.85935 13.4 7.99935ZM2.60004 7.99935C2.60004 6.85935 3.52671 5.93268 4.66671 5.93268H7.33337V4.66602H4.66671C2.82671 4.66602 1.33337 6.15935 1.33337 7.99935C1.33337 9.83935 2.82671 11.3327 4.66671 11.3327H7.33337V10.066H4.66671C3.52671 10.066 2.60004 9.13935 2.60004 7.99935ZM12.6667 7.99935H11.3334V9.99935H9.33337V11.3327H11.3334V13.3327H12.6667V11.3327H14.6667V9.99935H12.6667V7.99935Z"
      fill="currentColor"
    />
  </IconContainer>
);
