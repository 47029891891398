import React from 'react';
import { IconContainer } from './IconContainer';


export const ShieldCheckIcon = ({ ...props }) => (
  <IconContainer {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66109 2.2373C9.85709 2.07455 10.1429 2.07455 10.3389 2.2373C12.2777 3.84716 14.7315 4.85821 17.4166 4.98674C17.658 4.99829 17.8641 5.17262 17.8957 5.41216C17.9645 5.93202 18 6.46237 18 7.00102C18 12.1634 14.7401 16.5645 10.1665 18.2579C10.0593 18.2976 9.94102 18.2976 9.83378 18.2579C5.26004 16.5646 2 12.1634 2 7.0009C2 6.4623 2.03548 5.93198 2.10424 5.41217C2.13593 5.17262 2.34202 4.99829 2.58337 4.98674C5.2685 4.85821 7.72231 3.84716 9.66109 2.2373ZM13.8566 8.19186C14.1002 7.85687 14.0261 7.38781 13.6911 7.14418C13.3561 6.90055 12.8871 6.97461 12.6434 7.3096L9.15969 12.0998L7.28033 10.2204C6.98744 9.92751 6.51256 9.92751 6.21967 10.2204C5.92678 10.5133 5.92678 10.9882 6.21967 11.2811L8.71967 13.7811C8.87477 13.9362 9.08999 14.0156 9.30867 13.9984C9.52734 13.9813 9.72754 13.8693 9.85655 13.6919L13.8566 8.19186Z"
      fill="currentColor"
    />
  </IconContainer>
);
