import { EnqueueSnackbar } from 'notistack';
import { Group } from 'shared/hooks/useGroups';


export type EmailStatusDefault = {
  succeeded: string[];
  failed: string[];
};

export type InviteTeamMemberDialogForm = {
  emails: string[];
  authGroup: {
    name: Group['name'];
    id: number;
    permissions: [];
  };
};

export type InviteTeamMemberDialogProps = {
  open: boolean;
  onClose: () => void;
};

export enum getResolvedEmailsStatusValues {
  allEmailsFailed = 'allEmailsFailed',
  succeededAndFailed = 'succeededAndFailed',
  allEmailsSucceeded = 'allEmailsSucceeded',
}

export type IsetEnqueuedSnackbar = {
  enqueueSnackbar: EnqueueSnackbar;
  emailsStatusList: EmailStatusDefault;
  resolvedEmailsStatus: getResolvedEmailsStatusValues;
  alert: {
    messages: {
      allEmailSent: string;
      allEmailsFailed: string;
      someEmailsSent: string;
    };
    titles: {
      succeededAndFailedError: string;
    };
  };
};
