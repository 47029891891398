import React from 'react';
import { Box } from '@mui/system';
import { Button, Link, Tooltip, Typography } from '@mui/material';
import { ArrowTopRightOnSquare } from 'shared/icons/ArrowTopRightOnSquare';
import { format } from 'date-fns';
import { Marketplace } from 'modules/Marketplace/marketplace';
import { SustainabilityTypes, getShortDescription } from 'modules/Marketplace/helpers/general';
import { LocalizationContext } from 'contexts';
import { VendorImage } from './VendorImage';
import { FilterChip } from './FilterChips';


const MaxShownLocations = 10;
const MaxShownFocusAreas = 3;

export const VendorCard = (props: VendorCardProps) => {
  const {
    vendor: {
      name,
      logo,
      url,
      source,
      detailedDescription,
      focusAreas,
      sustainabilityType,
      maturity,
      locations,
      reviewedAt,
      commitmentDescription,
    },
  } = props;

  const { dictionary } = React.useContext(LocalizationContext);
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const focusAreasParentTitles = React.useMemo(() => (
    focusAreas
      .map((ef) => ef.parent?.title)
      .filter((title, i, arr) => title && i === arr.findIndex((x) => x === title))
  ), [focusAreas]);

  const reviewedAtFormat = React.useMemo(() => (
    format(new Date(reviewedAt),'d MMM, Y')
  ), [reviewedAt]);

  const categoryChipLabelMore = `+ ${focusAreasParentTitles.length - 1} ${dictionary.marketplace.vendorList.moreButton}`;
  const categoryChipLabel = `${focusAreasParentTitles[0]} ${focusAreasParentTitles.length > 1 ? categoryChipLabelMore : ''}`;

  return (
    <Box className="_d-grid-lg-gap" sx={{ gridTemplateColumns: 'auto 1fr' }}>
      <VendorImage src={logo} alt={name}/>
      <div>
        <Typography variant="h3" mb={2}>{name}</Typography>
        <Box className="_d-flex-sm-gap-ali-center" mb={2}>
          <Tooltip
            disableHoverListener={focusAreasParentTitles.length <= 1}
            title={focusAreasParentTitles.join(', ')}
          >
            <span><FilterChip label={categoryChipLabel}/></span>
          </Tooltip>

          <FilterChip
            label={sustainabilityType}
            color={sustainabilityType === SustainabilityTypes.NEUTRAL ? 'success' : 'warning'}
          />
          {/* <InformationToolTipContent tooltipTitle="needs a title"/> */}
        </Box>
        <Typography variant="body1" mb={3}>
          {isExpanded ? detailedDescription : (
            <React.Fragment>
              {getShortDescription(detailedDescription)}
              <Link
                sx={{ ml: 1 }}
                onClick={() => setIsExpanded(true)}
              >
                {dictionary.marketplace.vendorList.moreButton}
              </Link>
            </React.Fragment>
          )}
        </Typography>

        {commitmentDescription && isExpanded && (
          <React.Fragment>
            <Typography variant="h4" mb={2}>
              {dictionary.marketplace.vendorList.vendorCommitment(name)}
            </Typography>
            <Typography variant="body1" mb={3}>
              {commitmentDescription}
            </Typography>
          </React.Fragment>
        )}

        {isExpanded && (
          <React.Fragment>
            <Typography variant="h4" mb={2}>{dictionary.marketplace.vendorList.details}</Typography>
            <Box className="_d-grid" sx={{ gridTemplateColumns: '100px 1fr', mb: 2 }}>
              <Typography variant="body1" color="text.secondary">{dictionary.marketplace.vendorList.maturity}</Typography>
              <Typography variant="body1" className="capitalize-first">{maturity}</Typography>
              <Typography variant="body1" color="text.secondary">{dictionary.marketplace.vendorList.focusArea}</Typography>
              <DetailsSection details={focusAreas.map((ef) => ef.title)} limit={MaxShownFocusAreas}/>
              <Typography variant="body1" color="text.secondary">{dictionary.marketplace.vendorList.location}</Typography>
              <DetailsSection details={locations} limit={MaxShownLocations}/>
            </Box>
            <Typography variant="body1" color="text.tertiary">{dictionary.marketplace.vendorList.lastReviewed} {reviewedAtFormat}</Typography>
          </React.Fragment>
        )}

        <Box className="_d-flex-ali-center-md-gap" mt={5}>
          <Button
            component="a"
            href={url}
            target="_blank"
          >
            {dictionary.marketplace.vendorList.discover}
            <ArrowTopRightOnSquare/>
          </Button>
          {source && (
            <Button
              component="a"
              href={source}
              target="_blank"
              color="secondary"
            >
              {dictionary.marketplace.vendorList.source}
            </Button>
          )}
          {isExpanded && (
            <Link onClick={() => setIsExpanded(false)} >
              {dictionary.marketplace.vendorList.showLessButton}
            </Link>
          )}
        </Box>

      </div>
    </Box>
  );
};

const DetailsSection = ({ details, limit }: {details: string[], limit: number}) => {

  const { dictionary } = React.useContext(LocalizationContext);
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const limitedDetails = isExpanded ? details : details.slice(0, limit);

  return (
    <Typography variant="body1">
      {limitedDetails.join(', ')}
      {details.length > limit && !isExpanded && '...'}

      {details.length > limit && (
        <Link
          sx={{ fontWeight: 600, ml: .5 }}
          color="text.primary"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {dictionary.marketplace.vendorList.moreDetailsButton(details.length - limit, !isExpanded)}
        </Link>
      )}
    </Typography>
  );
};

interface VendorCardProps {
    vendor: Marketplace.Vendors.Vendor;
}