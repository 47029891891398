import React from 'react';
import { Box, Typography } from '@mui/material';
import { InformationToolTipContent } from './Tooltip/InformationToolTipContent';


interface Props extends React.PropsWithChildren {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  info?: React.ReactNode;
  total?: React.ReactNode;
  actions?: React.ReactNode;
  containerStyle?: React.CSSProperties;
}

export const ChartSection: React.FC<Props> = ({
  title,
  subtitle,
  total,
  info,
  actions,
  children,
  containerStyle,
}) => {
  return (
    <Box
      className="_d-flex-dir-col chart-section"
      sx={{
        height: '100%',
        position: 'relative',
        ...containerStyle,
      }}
    >
      <Box className="_d-flex jc-sb">
        <Box className="_d-flex dir-col ali-start xs-gap">
          <Typography variant="h4" component="h3" fontWeight={400} className="_d-flex ali-center sm-gap">
            {title}
            {info && <InformationToolTipContent tooltipTitle={info}/>}
          </Typography>
          {subtitle && (
            <Typography variant="body2" component="span" display="block" color="text.secondary">
              {subtitle}
            </Typography>
          )}
          {total && (
            <Typography variant="h4" component="span" fontWeight={500} display="block" mt={1}>
              {total}
            </Typography>
          )}
        </Box>
        <Box>
          {actions}
        </Box>
      </Box>
      <Box mt={2} className="_d-flex" height="100%" position="relative">
        {children}
      </Box>
    </Box>
  );
};
