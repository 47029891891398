import { SyntheticEvent, useContext, useMemo, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

import { Intensity } from 'modules/Emissions/enums';
import { LocalizationContext } from 'contexts';

import { ChartSection } from 'shared/components/layout/ChartSection';
import { EmissionsByCategory } from './EmissionsByCategory';
import { EmissionsByVendor } from './EmissionsByVendor';
import { EmissionsByLedger } from './EmissionsByLedger';


export const EmissionsByType = ({
  hasClassifiedTransactions,
  hasEmissions,
  emissionsContainerBarRef,
  emissionsContainerCategoryBreakdownRef,
  filters,
  isComparingMonths,
}: any) => {
  const {
    intensity,
  } = filters;
  const { dictionary } = useContext(LocalizationContext);

  const [value, setValue] = useState('categories');

  const totalEmissionsSubTitle = useMemo(() => {
    if (intensity === Intensity.absolute) {
      return dictionary.measurements.co2e;
    }
    if (intensity === Intensity.perEmployee) {
      return dictionary.measurements.co2ePerEmployee;
    }
    if (intensity === Intensity.perRevenueUnit) {
      return dictionary.measurements.co2eUnitOfRevenue;
    }
  }, [dictionary, intensity]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <ChartSection
      title={dictionary.measurements.emissionsPerType}
      subtitle={totalEmissionsSubTitle}
    >
      <Box width="100%" className="_d-flex-dir-col">
        <Tabs value={value} onChange={handleChange} aria-label="transaction by type">
          <Tab value="categories" label="Categories"/>
          <Tab value="ledgers" label="Ledgers"/>
          <Tab value="vendors" label="Vendors"/>
          {/* <Tab value="tags" label="Tags"/> */}
        </Tabs>
        <TabContext value={value}>
          <TabPanel value="categories" sx={{ p: 0 }}>
            <EmissionsByCategory
              hasClassifiedTransactions={hasClassifiedTransactions}
              hasEmissions={hasEmissions}
              emissionsContainerBarRef={emissionsContainerBarRef}
              emissionsContainerCategoryBreakdownRef={emissionsContainerCategoryBreakdownRef}
              filters={filters}
              isComparingMonths={isComparingMonths}
            />
          </TabPanel>
          <TabPanel value="ledgers" sx={{ p: 0 }}>
            <EmissionsByLedger
              hasClassifiedTransactions={hasClassifiedTransactions}
              hasEmissions={hasEmissions}
              emissionsContainerBarRef={emissionsContainerBarRef}
              emissionsContainerCategoryBreakdownRef={emissionsContainerCategoryBreakdownRef}
              filters={filters}
              isComparingMonths={isComparingMonths}
            />
          </TabPanel>
          <TabPanel value="vendors" sx={{ p: 0 }}>
            <EmissionsByVendor
              hasClassifiedTransactions={hasClassifiedTransactions}
              hasEmissions={hasEmissions}
              emissionsContainerBarRef={emissionsContainerBarRef}
              emissionsContainerCategoryBreakdownRef={emissionsContainerCategoryBreakdownRef}
              filters={filters}
              isComparingMonths={isComparingMonths}
            />
          </TabPanel>
          {/* <TabPanel value="tags" sx={{ p: 0 }}>
            Tags
          </TabPanel> */}
        </TabContext>
      </Box>
    </ChartSection>
  );
};
