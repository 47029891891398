// ** MUI Imports
import { Theme } from '@mui/material/styles';


const List = (theme: Theme) => {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: theme.spacing(2),
          marginRight: theme.spacing(1),
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
      },
    },
    MuiListItemText: {
      styleOverrides: {
      },
    },
    MuiListSubheader: {
      styleOverrides: {
      },
    },
  };
};

export default List;
