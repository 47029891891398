import React from 'react';
import {
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

import { format } from 'date-fns';
import { Surveys } from 'modules/Surveys/survey';
import { createYupSchema, replaceValueOnStrings } from 'modules/Surveys/helpers';
import { Form, FormAnswer, Props as FormProps } from 'modules/Surveys/components/Form';
import { useSurveyCrud } from 'modules/Surveys/hooks/useSurveyCrud';
import { useSurvey } from 'modules/Surveys/hooks/useSurvey';
import { cacheKeys } from 'config';
import { LocalizationContext, UserContext } from 'contexts';
import { useDateFilters } from 'shared/hooks/useDateFilters';
import { EmployeeNumSurveyYearsEnum } from 'views/LoggedIn/Employees/Employees.types';
import ErrorPage from 'views/LoggedIn/Employees/components/ErrorPage';
import SurveySkeleton from 'views/LoggedIn/Employees/components/SurveySkeleton';


interface Props {
  name: string;
  onSubmitButtonText: FormProps['buttonLabel'];
  renderSubmit?: FormProps['renderSubmit'];
  onSubmitCompleted?: () => void;
};

export const CompanyOverviewSurvey: React.FC<Props> = ({ name, onSubmitButtonText, renderSubmit, onSubmitCompleted }) => {

  const { dictionary } = React.useContext(LocalizationContext);
  const { user } = React.useContext(UserContext);
  const { createAnswers, editAnswers, deleteAnswer } = useSurveyCrud();

  const { gteDate } = useDateFilters();
  const [year, setYear] = React.useState(format(new Date(gteDate), 'yyyy') as EmployeeNumSurveyYearsEnum);

  const params = React.useMemo(() => {
    return { year: format(new Date(year), 'yyyy-MM-dd'), name };
  }, [year, name]);

  const { survey, status } = useSurvey(params);

  const onSubmit = (answers: FormAnswer[]) => {
    const editableAnswers: Surveys.AnswerEdit[] = [];
    const creatableAnswers: Surveys.AnswerCreate[] = [];
    const deleteableAnswers: number[] = [];

    answers.forEach(answer => {
      const previousAnswer = survey?.surveyAnswers.find(it => it.question === answer.question);

      if (previousAnswer) {
        if (answer.text) {
          editableAnswers.push({
            ...answer,
            id: previousAnswer.id,
            year: params.year,
          });
        } else {
          deleteableAnswers.push(previousAnswer.id);
        }
      } else {
        if (answer.text) {
          creatableAnswers.push({
            ...answer,
            year: params.year,
          });
        }
      }
    });

    const promises: Promise<any>[] = [];

    if (editableAnswers.length > 0) {
      const editPromise = editAnswers({
        name,
        answers: editableAnswers,
      });

      promises.push(editPromise);
    }
    if (creatableAnswers.length > 0) {
      const createPromise = createAnswers({
        name,
        answers: creatableAnswers,
      });

      promises.push(createPromise);
    }
    if (deleteableAnswers.length > 0) {
      deleteableAnswers.forEach(answer => {
        const promise = deleteAnswer({
          name,
          answer,
        });

        promises.push(promise);
      });
    }

    return Promise.all(promises).then(() => {
      onSubmitCompleted && onSubmitCompleted();
    });
  };

  const replacedQuestions = React.useMemo(() => {
    return survey?.surveyQuestions && user &&
      replaceValueOnStrings(survey?.surveyQuestions, [
        {
          keyStrToBeReplaced: 'text',
          company_name: user?.company?.name,
          year,
        },
      ]);
  }, [survey, user, year]);

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Typography variant="h3" component="span" fontWeight={500}>
          {dictionary.employees.selectYear}
        </Typography>
        <Box
          sx={{
            display: 'inline-block',
            minWidth: 0,
          }}
        >
          <Tabs
            value={year}
            onChange={(e, value) => setYear(value)}
          >
            <Tab label="2020" value="2020"/>
            <Tab label="2021" value="2021"/>
            <Tab label="2022" value="2022"/>
            <Tab label="2023" value="2023"/>
          </Tabs>
        </Box>
      </Stack>
      <TabContext value={year}>
        <Box minWidth={595}>
          {status === 'error' && <ErrorPage mt={15} mr={0} queryKey={[cacheKeys.employeeCommuting.getSurvey, params]}/>}
          {(status === 'loading' || (status === 'success' && !survey?.surveyQuestions)) && (
            <SurveySkeleton/>
          )}
          {survey?.surveyQuestions && (
            <Box sx={{ overflow: 'hidden' }}>
              {Object.values(EmployeeNumSurveyYearsEnum).map(
                (enumYear) => (
                  <TabPanel sx={{ p: 0, pt: 4 }} key={enumYear} value={enumYear}>
                    <Box>
                      <Form
                        onSubmit={onSubmit}
                        renderSubmit={renderSubmit}
                        buttonLabel={onSubmitButtonText}
                        questions={replacedQuestions!}
                        answers={survey?.surveyAnswers}
                        validationSchema={createYupSchema(
                          survey.surveyQuestions.reduce((prev, curr) => ({
                            ...prev,
                            [curr.name]: curr.frontendValidation,
                          }), {}),
                        )}
                      />
                    </Box>
                  </TabPanel>
                ),
              )}
            </Box>
          )}
        </Box>
      </TabContext>
    </React.Fragment>
  );
};
