import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import { Scope } from 'clients/emissionFactors/emissionFactorsClient.types';

// Primary colours
export const primary = '#1E78FF';
export const primaryLight = '#DFECFF';
export const primaryLightest = '#F0F9FF';
export const secondary = '#333333';
export const secondaryLight = '#E7EAEF';
export const tertiary = '#2F43FF';
export const tertiaryLight = '#E7E9FF';

//**** To remove
export const primaryAlt = '#A1DDDB';
export const textColor = '#333333';
export const subtextColor = '#64748B';
export const greyTextColor = '#757575';
export const lightBackground = '#D1D1D1';
export const defaultIconPink = '#FFDAF6';
export const tealColor = '#249683';
export const lightTealColor = '#2496830f';
export const redColor = '#F50F4A';
export const lightRedColor = '#ff00000a';
export const lightGreyColor = '#FAFAFA';
export const greyNavColor = '#9CA3AF';
//****

// Text colours
export const textPrimary = '#26262B';
export const textSecondary = '#586478';
export const textTertiary = '#9CA3AF';
export const textPlaceholder = '#9CA3AF';
export const textDisabled = '#DADADA';
export const textContrast = '#FFFFFF';
export const textContrastDisabled = '#7B7B7B';

// Background colours
export const backgroundDisabled = '#F9F9F9';
export const backgroundLight = '#FFFFFF';
export const backgroundSemiLight = '#F0F3F6';
export const backgroundMedium = '#F9FAFE';
export const backgroundDark = '#010101';
export const outlineLight = '#E2E8F0';
export const outlineMedium = '#D1D5DB';
export const line = '#E2E8F0';
export const hoverOnLight = '#F0F3F6';
export const hoverOnDark = '#333333';
export const hoverOnSelected = '#D6E3F5';
export const hoverOnError = '#FB3266';

// Status colours
export const error = '#F50F4A';
export const errorLight = '#FDCFDB';
export const warning = '#E57F07';
export const warningLight = '#FFA945';
export const success = '#369987';
export const successLight = '#D7EBE7';
export const successLightest = '#EEFFFC';

export const scopeColors = {
  [Scope.One]: '#FFAE80',
  [Scope.Two]: '#9CC7FF',
  [Scope.Three]: '#1742DB',
  [Scope.Offset]: '#A5D4E7',
};
