import { integrationsClient } from 'modules/Expenses/client/integrationsClient';
import { IntegrationStatus } from 'modules/Expenses/helpers/integrationOptions';
import { AsyncProcessCsvUpload, AsyncProcessImportingTransactions } from './types';


export const checkUploadCsvProcess = async (
  process: AsyncProcessCsvUpload,
  reviewAction: {
    actionName: string,
    actionOnClick: () => void
  },
): Promise<AsyncProcessCsvUpload | undefined> => {
  try {
    const integration = await integrationsClient.getIntegration(process.data.integrationId);
    const { logs, status } = integration;

    if (status === IntegrationStatus.UPLOAD) {
      process.isActive = true;
      process.progress = Math.round((logs?.info?.progress || 0) / (logs?.info?.transactionCount || 1) * 100);
    }
    else if (status === IntegrationStatus.ERROR) {
      process.isActive = false;
      process.snackbarType = 'error';
      delete process.progress;
      process.snackbarAction = reviewAction;
      process.data.errors = logs?.error || {};
    }
    else {
      process.isActive = false;
      process.snackbarType = 'success';
      delete process.progress;
      process.snackbarAction = reviewAction;
    }

    process.timesChecked++;
    return process;
  }
  catch (error) {
    return;
  }
};

export const checkImportingTransactionsProcess = async (
  process: AsyncProcessImportingTransactions,
  reviewAction: {
    actionName: string,
    actionOnClick: () => void
  },
): Promise<AsyncProcessImportingTransactions | undefined> => {
  try {
    const integration = await integrationsClient.getIntegration(process.data.integrationId);
    const { logs, status } = integration;
    const ConnectionCheckRetryLimit = 10;

    if (
      status ===IntegrationStatus.IMPORTING_TRANSACTIONS ||
      (status === IntegrationStatus.INACTIVE && process.timesChecked < ConnectionCheckRetryLimit)
    ) {
      process.isActive = true;
      process.progress = Math.round((logs?.info?.progress || 0) / (logs?.info?.transactionCount || 1) * 100);
      process.data.isInactive = status === IntegrationStatus.INACTIVE;
    }
    else if (status === IntegrationStatus.INACTIVE) {
      process.isActive = false;
      process.snackbarType = 'error';
      delete process.progress;
    }
    else {
      process.isActive = false;
      process.snackbarType = 'success';
      delete process.progress;
      process.snackbarAction = reviewAction;
    }

    process.timesChecked++;
    return process;
  }
  catch (error) {
    return;
  }
};