// ** MUI Imports
import { Theme } from '@mui/material/styles';

// ** Overrides Imports
import MuiCard from './card';
import MuiChip from './chip';
import MuiList from './list';
import MuiMenu from './menu';
import MuiTabs from './tabs';
import MuiInput from './input';
import MuiPaper from './paper';
import MuiTable from './table';
import MuiAlerts from './alerts';
import MuiButton from './button';
import MuiDialog from './dialog';
import MuiSelect from './select';
import MuiAvatar from './avatars';
import MuiDivider from './divider';
import MuiPopover from './popover';
import MuiTooltip from './tooltip';
import MuiSwitches from './switches';
import MuiAccordion from './accordion';
import MuiPagination from './pagination';
import MuiTypography from './typography';
import MuiDateTimePicker from './dateTimePicker';
import MuiForm from './form';
import MuiTextField from './textField';
import MuiCheckbox from './checkbox';
import MuiToolbar from './toolbar';
import MuiGrid from './grid';
import MuiCssBaseline from './baseline';


const Overrides = (theme: Theme) => {
  const baseline = MuiCssBaseline(theme);
  const chip = MuiChip(theme);
  const list = MuiList(theme);
  const menu = MuiMenu(theme);
  const tabs = MuiTabs(theme);
  const cards = MuiCard(theme);
  const form = MuiForm(theme);
  const input = MuiInput(theme);
  const textField = MuiTextField(theme);
  const select = MuiSelect(theme);
  const tables = MuiTable(theme);
  const alerts = MuiAlerts(theme);
  const button = MuiButton(theme);
  const avatars = MuiAvatar(theme);
  const divider = MuiDivider(theme);
  const dialog = MuiDialog(theme);
  const popover = MuiPopover(theme);
  const tooltip = MuiTooltip(theme);
  const switches = MuiSwitches(theme);
  const typography = MuiTypography(theme);
  const paper = MuiPaper(theme);
  const accordion = MuiAccordion(theme);
  const pagination = MuiPagination(theme);
  const dateTimePicker = MuiDateTimePicker(theme);
  const toolbar = MuiToolbar(theme);
  const checkbox = MuiCheckbox(theme);
  const grid = MuiGrid(theme);

  return {
    ...baseline,
    ...chip,
    ...list,
    ...menu,
    ...tabs,
    ...cards,
    ...form,
    ...input,
    ...alerts,
    ...button,
    ...dialog,
    ...tables,
    ...avatars,
    ...divider,
    ...popover,
    ...tooltip,
    ...paper,
    ...switches,
    ...accordion,
    ...pagination,
    ...select,
    ...typography,
    ...dateTimePicker,
    ...textField,
    ...toolbar,
    ...checkbox,
    ...grid,
  };
};

export default Overrides;
