import { AxiosResponse } from 'axios';
import { snakeCase } from 'lodash';
import { PaginatedResults } from 'types/Query';
import { snakizeObject } from 'helpers/object';
import { formatToApi } from 'helpers/date';
import { request } from 'clients/baseClient';
import {
  Transaction,
  TransactionApi,
  GetTransactionsParams,
  GetTransactionsExportParams,
  ActivityBasedSubCategoriesApi,
  ActivityBasedSubCategories,
  ManualInputPayload,
  GetTransactionsStatisticsParams,
  TransactionStatisticApi,
  TransactionStatistic,
  RevertManualInputTransactionsParams,
  UpdateManualInputTransactionsParams,
  GetActivityBasedSubcategoriesParams, BulkClassifyTransaction, TransactionWordAnalysisData, TransactionWordAnalysisDataApi, BulkClassifyConfidenceTransactions, BulkClassifyConfidenceTransactionsFiltered,
} from './transactionClient.types';
import {
  getFileExtension,
  mapActivityBasedSubCategories,
  mapManualInputPayload,
  mapTransactionData,
  mapTransactionParams,
  mapTransactionStatistics,
  mapTransactionWordAnalysis,
  mapSkipManualInputTransactionParams,
  mapRevertManualInputTransactionParams,
  mapManualInputTransaction, mapBulkClassify,
} from './transactionClient.formatter';


const transactionsApiBaseUrl = process.env.REACT_APP_GREENCAST_API_URL;

const getTransaction = (params: { id: number | string }): Promise<AxiosResponse<Transaction>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/${params.id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<TransactionApi>) => ({
    ...data,
    data: mapTransactionData(data.data),
  }));
};

const getTransactions = (params: GetTransactionsParams): Promise<AxiosResponse<PaginatedResults<Transaction>>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/filter/`,
      method: 'POST',
      params: mapTransactionParams(params),
      data: {
        filters: params?.filters || [],
      },
    },
  }).then((data: AxiosResponse<PaginatedResults<TransactionApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(result => mapTransactionData(result)),
    },
  }));
};

const getTransactionsStatistics = (params?: GetTransactionsStatisticsParams): Promise<AxiosResponse<PaginatedResults<TransactionStatistic>>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/statistics/`,
      method: 'POST',
      params: {
        confidence: params?.confidence && snakeCase(params.confidence),
        tags: params?.tags,
        status: params?.status,
        ordering: params?.ordering,
        date_lte: params?.dateLte && formatToApi(params.dateLte),
        date_gte: params?.dateGte && formatToApi(params.dateGte),
        search: params?.search,
        sub_category_id: params?.subCategoryId,
        category_id: params?.categoryId,
        group_by: params?.groupBy || 'ledger',
        limit: params?.limit,
        offset: params?.offset,
      },
      data: {
        filters: params?.filters || [],
      },
    },
  }).then((data: AxiosResponse<PaginatedResults<TransactionStatisticApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapTransactionStatistics),
    },
  }));
};

const getTransactionsWordAnalysis = (params?: GetTransactionsParams): Promise<AxiosResponse<TransactionWordAnalysisData>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/text-analysis/`,
      method: 'POST',
      params: mapTransactionParams(params),
      data: {
        filters: params?.filters || [],
      },
    },
  }).then((data: AxiosResponse<TransactionWordAnalysisDataApi>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapTransactionWordAnalysis),
    },
  }));
};

const getTransactionsExport = (params: GetTransactionsExportParams): Promise<AxiosResponse<Blob>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/xls-export/`,
      method: 'POST',
      data: {
        filters: params?.filters || [],
      },
      params: {
        status: params.status,
        date_lte: params.dateLte && formatToApi(params.dateLte),
        date_gte: params.dateGte && formatToApi(params.dateGte),
        tags: params.tags,
        scope: params.transactionEmissionClassificationScope,
        search: params.search,
      },
      responseType: 'blob',
    },
  });
};

const bulkClassifyTransactions = (data: BulkClassifyTransaction[]) => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/classify/bulk/`,
      method: 'POST',
      data: mapBulkClassify(data),
    },
  });
};

const bulkClassifyFilteredTransactions = (params?: GetTransactionsParams) => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/bulk-classify-filtered/`,
      method: 'POST',
      params: mapTransactionParams(params),
      data: {
        filters: params?.filters || [],
        emission_factor_id: params?.emissionFactorId,
      },
    },
  });
};

const bulkClassifyConfidenceTransactions = (data: BulkClassifyConfidenceTransactions) => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/classify/bulk-confidence/`,
      method: 'PUT',
      data: snakizeObject(data),
    },
  });
};

const bulkClassifyConfidenceFilteredTransactions = (data: BulkClassifyConfidenceTransactionsFiltered) => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/bulk-classify-confidence-filtered/`,
      method: 'POST',
      data: {
        filters: data?.filters || [],
        source: data?.source,
      },
    },
  });
};

const uploadTransactionsFromCsv = (data: any) => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/csv-upload-async/`,
      method: 'POST',
      data: {
        default_currency: data.defaultCurrency,
        file_name: data.fileName,
        data: data.data,
      },
    },
  });
};

const skipManualInputTransactions = (params: UpdateManualInputTransactionsParams): Promise<AxiosResponse<void>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/manual-input-transactions/skip/`,
      method: 'PATCH',
      params: mapSkipManualInputTransactionParams(params),
      data: {
        transactions_ids: params?.transactionIds ? params?.transactionIds?.map((transaction) => snakizeObject(transaction)) : [],
      },
    },
  });
};

const saveManualInputAnswers = (data: ManualInputPayload[]): Promise<AxiosResponse<void>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/manual-input-answer/create-update/bulk/`,
      method: 'PUT',
      data: data.map(mapManualInputPayload),
    },
  });
};

const saveManualInputTransaction = (data: any): Promise<AxiosResponse<void>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/manual-input-transactions-with-tags/`,
      method: 'POST',
      data: mapManualInputTransaction(data),
    },
  });
};

const skipAllOpenTransactions = (): Promise<AxiosResponse<void>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/manual-input-transactions/skip-all/`,
      method: 'PATCH',
    },
  });
};

const revertManualInputTransactions = (params: RevertManualInputTransactionsParams): Promise<AxiosResponse<void>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/manual-input-transactions/revert/`,
      method: 'PATCH',
      params: mapRevertManualInputTransactionParams(params),
      data: {
        transactions_ids: params?.transactionIds ? params?.transactionIds?.map((transaction) => snakizeObject(transaction)) : [],
      },
    },
  });
};

const uploadTransactionsFile = (file: File) => {
  const data = new FormData();
  data.append('file', file, `transactions.${getFileExtension(file.name)}`);

  return request({
    options: {
      url: `${transactionsApiBaseUrl}/transactions/transactions-file-upload/`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  });
};

const getActivityBasedSubCateogries = (params: GetActivityBasedSubcategoriesParams): Promise<AxiosResponse<ActivityBasedSubCategories>> => {
  return request({
    options: {
      url: `${transactionsApiBaseUrl}/activity-based-transactions/sub-categories/`,
      params: snakizeObject(params),
      method: 'GET',
    },
  }).then((data: AxiosResponse<ActivityBasedSubCategoriesApi>) => ({
    ...data,
    data: mapActivityBasedSubCategories(data.data),
  }));
};

export const transactionClient = {
  getTransaction,
  getTransactions,
  getTransactionsStatistics,
  getTransactionsWordAnalysis,
  getTransactionsExport,
  bulkClassifyTransactions,
  bulkClassifyFilteredTransactions,
  bulkClassifyConfidenceTransactions,
  bulkClassifyConfidenceFilteredTransactions,
  uploadTransactionsFromCsv,
  uploadTransactionsFile,
  getActivityBasedSubCateogries,
  skipManualInputTransactions,
  saveManualInputAnswers,
  saveManualInputTransaction,
  skipAllOpenTransactions,
  revertManualInputTransactions,
};
